import * as React from "react";

function DeliverIcon(props) {
  return (
    <svg width="30px" height="30px" viewBox="0 0 20 20" fill="none" {...props}>
      <g
        clipPath="url(#prefix__clip0_7739_66362)"
        fill={props?.fill || "#57585C"}
      >
        <path d="M5.417 15.833H3.75a.417.417 0 010-.833h1.667a.417.417 0 010 .833zM18.958 15.833h-1.041a.417.417 0 010-.833h.695l.562-2.993c-.007-1.532-1.316-2.84-2.924-2.84h-2.736L12.188 15h2.395a.417.417 0 010 .833h-2.916a.417.417 0 01-.407-.508l1.515-6.667a.416.416 0 01.407-.325h3.068a3.755 3.755 0 013.75 3.75l-.633 3.41a.416.416 0 01-.409.34z" />
        <path d="M16.25 17.5a2.086 2.086 0 01-2.083-2.083c0-1.15.935-2.084 2.083-2.084 1.148 0 2.083.934 2.083 2.084 0 1.149-.935 2.083-2.083 2.083zm0-3.333c-.69 0-1.25.56-1.25 1.25 0 .689.56 1.25 1.25 1.25s1.25-.561 1.25-1.25c0-.69-.56-1.25-1.25-1.25zM7.083 17.5A2.086 2.086 0 015 15.417c0-1.15.935-2.084 2.083-2.084 1.149 0 2.084.934 2.084 2.084 0 1.149-.935 2.083-2.084 2.083zm0-3.333c-.689 0-1.25.56-1.25 1.25 0 .689.561 1.25 1.25 1.25.69 0 1.25-.561 1.25-1.25 0-.69-.56-1.25-1.25-1.25zM5.417 8.333H2.083a.417.417 0 010-.833h3.334a.417.417 0 010 .833zM5.417 10.833H1.25a.417.417 0 010-.833h4.167a.417.417 0 010 .833zM5.417 13.333h-5a.417.417 0 010-.833h5a.417.417 0 010 .833z" />
        <path d="M11.667 15.833H8.75a.417.417 0 010-.833h2.584l1.893-8.333H3.75a.417.417 0 010-.834h10a.417.417 0 01.407.509l-2.084 9.166a.417.417 0 01-.406.325z" />
      </g>
    </svg>
  );
}

export default DeliverIcon;
