import * as React from "react";

function AdminSideTeamIcon(props) {
  return (
    <svg
      width={props?.size || "18px"}
      height={props?.size || "18px"}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_5559_10721)">
        <g
          clipPath="url(#prefix__clip1_5559_10721)"
          fill="#fff"
          stroke="#221353"
          strokeWidth={0.2}
        >
          <path d="M9.999 11.193a3.232 3.232 0 113.231-3.231A3.237 3.237 0 0110 11.193zm0-5.33a2.1 2.1 0 100 4.2 2.1 2.1 0 000-4.2zM16.67 7.862h-.001A1.431 1.431 0 1018.1 9.294h0a1.438 1.438 0 00-1.43-1.432zm-.001 4a2.57 2.57 0 110-5.139 2.57 2.57 0 010 5.14zM3.331 7.862h0a1.431 1.431 0 101.431 1.432h0a1.438 1.438 0 00-1.43-1.432zm0 4a2.57 2.57 0 110-5.138 2.57 2.57 0 010 5.139zM14.015 16.406l.015.005.018.006a.568.568 0 00.49-.395l.095.03-.095-.03a.567.567 0 00.023-.225A3.537 3.537 0 0011.03 12.6h0-2.063 0a3.538 3.538 0 00-3.531 3.155.569.569 0 001.126.111 2.412 2.412 0 012.405-2.172m5.048 2.712l-5.048-2.612m5.048 2.612h-.016a.569.569 0 01-.563-.531h0v-.004a2.413 2.413 0 00-2.406-2.177m2.985 2.712l-2.985-2.612m-2.063-.1s0 0 0 0v.1m0-.1h0v.1m0-.1h2.063m-2.063.1h2.063m0-.1s0 0 0 0v.1m0-.1h0v.1M18.768 15.425h0v-.003a1.412 1.412 0 00-1.419-1.278h-1.374 0c-.141 0-.28.02-.416.06l-.01.003-.01.006a.57.57 0 01-.84-.582.568.568 0 01.515-.481l.01-.001.01-.003c.24-.071.49-.108.741-.108h1.375a2.544 2.544 0 012.55 2.297.568.568 0 01-.528.621h-.04a.569.569 0 01-.564-.531zM4.018 12.938c.26 0 .519.038.769.112m-.77-.113H2.644a2.644 2.644 0 00-2.644 2.388m4.019-2.387v.1h0m0-.1v.1s0 0 0 0m.769.012L.099 15.335l-.1-.01m4.788-2.275a.67.67 0 11-.382 1.25l.382-1.25zm-4.788 2.275l.1.01s0 0 0 0m-.1-.01l.1.01m0 0a.569.569 0 00.528.621M.1 15.335l.525.721M.1 15.335a2.544 2.544 0 012.543-2.298h1.376m-3.92 2.298l3.92-1.091H2.643M.627 15.956H.624v.1m.003-.1H.63l-.006.1m.003-.1h.04m-.043.1h.044m-.001-.1s0 0 0 0v.1m0-.1a.569.569 0 00.557-.531v-.003h0a1.412 1.412 0 011.42-1.278M.666 15.956h0v.1m0 0l1.976-1.912m0 0h0v.1m0-.1L1.324 15.43a1.312 1.312 0 011.319-1.187m0-.1s0 0 0 0v.1m0-.1h1.375c.14 0 .28.02.415.06l.011.003.01.006a.568.568 0 10.324-1.063l-.01-.001-.01-.003a2.641 2.641 0 00-.74-.109" />
        </g>
      </g>
      <defs>
        <clipPath id="prefix__clip0_5559_10721">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
        <clipPath id="prefix__clip1_5559_10721">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AdminSideTeamIcon;
