import * as React from "react";

function LikeIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M13.516 0a.39.39 0 00-.391.39v1.797a.39.39 0 00.781 0V.391a.39.39 0 00-.39-.391zM4.531 12.188a.39.39 0 100 .781.39.39 0 000-.781z"
        fill={props?.fill || "#24B300"}
      />
      <path
        d="M18.984 11.406c0-.861-.7-1.562-1.562-1.562h-2.916c.451-1.157.634-2.518.599-3.73a4.438 4.438 0 00-.028-.448 1.565 1.565 0 00-1.561-1.525c-.862 0-1.563.7-1.563 1.562v.39c0 2.471-3.172 3.86-5.566 4.455a1.173 1.173 0 00-1.074-.704H1.405a.39.39 0 00-.39.39v9.375c0 .216.175.391.39.391h3.907c.476 0 .887-.286 1.07-.696l.552.144c1.364.356 2.115.552 3.942.552h4.201a1.564 1.564 0 001.298-2.431 1.563 1.563 0 00.784-2.345 1.563 1.563 0 00.779-2.343 1.563 1.563 0 001.045-1.475zm-13.28 7.422c0 .216-.176.39-.391.39H1.796v-8.593h3.516c.215 0 .39.175.39.39v7.813zm11.718-6.64h-1.953a.39.39 0 100 .78h1.172a.782.782 0 010 1.563h-1.953a.39.39 0 000 .781h1.171a.781.781 0 110 1.563h-1.953a.39.39 0 000 .781h1.172a.782.782 0 010 1.563h-4.2c-1.727 0-2.402-.176-3.746-.527-.196-.052-.412-.108-.648-.168v-7.195c2.827-.673 6.25-2.317 6.25-5.235v-.39a.782.782 0 011.563-.002v.001c0 .17.212 2.253-.638 4.14h-1.315a.39.39 0 100 .782h5.078a.782.782 0 010 1.563z"
        fill={props?.fill || "#24B300"}
      />
      <path
        d="M4.531 13.75a.39.39 0 00-.39.39v3.126a.39.39 0 00.78 0V14.14a.39.39 0 00-.39-.391zM10.39 4.922H8.829a.39.39 0 000 .781h1.563a.39.39 0 000-.781zM18.203 4.922h-1.562a.39.39 0 000 .781h1.562a.39.39 0 000-.781zM17.105 1.722a.39.39 0 00-.552 0l-1.105 1.104a.39.39 0 10.553.553l1.104-1.105a.39.39 0 000-.552zM11.582 2.826l-1.105-1.104a.39.39 0 00-.552.552L11.03 3.38a.39.39 0 00.552-.553z"
        fill={props?.fill || "#24B300"}
      />
    </svg>
  );
}

export default LikeIcon;
