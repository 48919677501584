import * as React from "react";

function PendingMailAlert(props) {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      {...props}
    >
      <rect width="43" height="43" rx="21.5" fill="white" />
      <path
        d="M9.89877 12.5037L18.822 19.7633C18.8516 19.7876 18.8839 19.8088 18.9177 19.8257C19.0368 19.8865 19.1626 19.9172 19.2927 19.9172C19.4303 19.9172 19.5678 19.8812 19.7688 19.7596L28.6852 12.5042C28.8174 12.3968 28.891 12.2339 28.8846 12.0641C28.8783 11.8943 28.7925 11.7371 28.6529 11.6398C28.2874 11.3849 27.8594 11.25 27.4167 11.25H11.1667C10.724 11.25 10.2966 11.3844 9.93107 11.6393C9.79142 11.7361 9.7057 11.8932 9.69935 12.0636C9.69301 12.2334 9.76654 12.3963 9.89877 12.5037Z"
        fill="#DFDFDF"
      />
      <path
        d="M22 24.7915C22 20.9089 25.159 17.7499 29.0417 17.7499C29.3411 17.7499 29.5834 17.5076 29.5834 17.2082V14.3089C29.5834 14.1 29.4633 13.9095 29.2745 13.8196C29.0856 13.7318 28.8624 13.7577 28.7 13.889L20.3893 20.6518L20.2941 20.7148C19.6693 21.0983 18.9018 21.1179 18.1967 20.6524L9.88339 13.8884C9.72206 13.7566 9.49882 13.7302 9.3089 13.819C9.1201 13.909 9 14.0995 9 14.3084V24.2499C9 25.4448 9.9717 26.4165 11.1667 26.4165H21.5446C21.7017 26.4165 21.8509 26.3483 21.954 26.2298C22.0567 26.1114 22.1032 25.9537 22.081 25.7982C22.0259 25.4131 22 25.093 22 24.7915Z"
        fill="#DFDFDF"
      />
      <path
        d="M29.0416 18.8335C25.7561 18.8335 23.0833 21.5064 23.0833 24.7918C23.0833 28.0773 25.7561 30.7502 29.0416 30.7502C32.327 30.7502 34.9999 28.0773 34.9999 24.7919C34.9999 21.5064 32.327 18.8335 29.0416 18.8335ZM31.0495 26.7998C30.9437 26.9056 30.8051 26.9585 30.6665 26.9585C30.5279 26.9585 30.3894 26.9056 30.2835 26.7998L28.6585 25.1748C28.557 25.0732 28.4998 24.9357 28.4998 24.7918V21.5418C28.4998 21.2424 28.7421 21.0001 29.0415 21.0001C29.3409 21.0001 29.5832 21.2424 29.5832 21.5418V24.5675L31.0495 26.0338C31.2611 26.2455 31.2611 26.5883 31.0495 26.7998Z"
        fill="#E40606"
      />
      <rect
        x="0.5"
        y="0.5"
        width="42"
        height="42"
        rx="21"
        stroke="black"
        stroke-opacity="0.1"
      />
    </svg>
  );
}

export default PendingMailAlert;
