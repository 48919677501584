import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import Select from 'react-select';
import "./PaginationDesign.css"

const Pagination = ({ currentPage, totalPages, onPageChange, onItemsPerPageChange, itemPerPageOptions }) => {
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const totalPagesToShow = 5;

        if (totalPages <= totalPagesToShow) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            if (currentPage <= totalPagesToShow - Math.floor(totalPagesToShow / 2)) {
                for (let i = 1; i <= totalPagesToShow - 2; i++) {
                    pageNumbers.push(i);
                }
                pageNumbers.push("...");
                pageNumbers.push(totalPages);
            } else if (currentPage >= totalPages - Math.floor(totalPagesToShow / 2)) {
                pageNumbers.push(1);
                pageNumbers.push("...");
                for (let i = totalPages - (totalPagesToShow - 3); i <= totalPages; i++) {
                    pageNumbers.push(i);
                }
            } else {
                pageNumbers.push(1);
                pageNumbers.push("...");
                for (let i = currentPage - Math.floor(totalPagesToShow / 2); i <= currentPage + Math.floor(totalPagesToShow / 2) - 2; i++) {
                    pageNumbers.push(i);
                }
                pageNumbers.push("...");
                pageNumbers.push(totalPages);
            }
        }

        return pageNumbers.map((number, index) => (
            <button key={index} className={(number === currentPage ? 'round-effect pageActive' : 'round-effect')} onClick={() => onPageChange(number)} disabled={number === "..." || currentPage === number}>
                {number}
            </button>
        ));
    };

    return (
        <div>
            <Flex justifyContent={"space-between"} marginTop={3} borderTop={"1px solid rgba(0,0,0,0.1)"} padding={"15px 0 0 0"}>
                <Flex className="paginate-ctn">
                    <button onClick={() => onPageChange(currentPage - 1)} className={`prevNext ${currentPage === 1 ? 'disabled' : ''}`} disabled={currentPage === 1}>
                        {/* {"<"} */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.10158 0.502403C7.38639 0.747997 7.41205 1.16938 7.15888 1.44456L2.04647 6.00166L7.15888 11.5587C7.41205 11.8339 7.38639 12.2553 7.10158 12.5C6.81677 12.7446 6.38065 12.7198 6.12748 12.4446L0.996944 6.8875C0.532194 6.38232 0.532194 5.62104 0.996944 5.11586L6.12748 0.558767C6.38065 0.283579 6.81677 0.258792 7.10158 0.502403Z" fill="#8A8A8A" />
                        </svg>
                    </button>
                    {renderPageNumbers()}
                    <button onClick={() => onPageChange(currentPage + 1)} className={`prevNext ${currentPage === totalPages ? 'disabled' : ''}`} disabled={currentPage === totalPages}>
                        {/* {">"} */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.89842 13.4976C0.613607 13.253 0.587952 12.8316 0.84112 12.5564L5.95353 6.99934L0.841118 1.44225C0.58795 1.16706 0.613605 0.745681 0.898418 0.501069C1.18323 0.256457 1.61935 0.281244 1.87252 0.556433L6.98493 6.11352C7.44968 6.6187 7.44968 7.37998 6.98493 7.88516L1.87252 13.4423C1.61935 13.7174 1.18323 13.7422 0.89842 13.4976Z" fill="#8A8A8A"/>
                        </svg>
                    </button>
                </Flex>
                <Flex> 
                    <Select
                        placeholder={`Per page : ${itemPerPageOptions[0]}`}
                        isSearchable={false}
                        name="itemPerPage"
                        options={itemPerPageOptions}
                        defaultValue={itemPerPageOptions[0]}
                        onChange={(selectedOption) => onItemsPerPageChange(selectedOption.value)}
                        styles={{
                            control: (provided, state) => 
                            ({
                                ...provided,
                                width: "150px",
                                height: "36px",
                                borderRadius: "6px",
                                fontSize: "12px",
                                border: "1px solid rgba(0, 0, 0, 0.10)",
                                background: "#FFF",
                            }),
                        }}
                    />
                </Flex>
            </Flex>
        </div>
    );
};

export default Pagination;
