import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Tag } from "@evabot/eva-react-core-library/dist/molecules/Tag/Tag";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import React, { FC } from "react";
import { AccordionHeadingLabel } from "../../components/Accordion/AccordionHeadingLabel";
import { AccordionHeadingValue } from "../../components/Accordion/AccordionHeadingValue";

export interface StakeHolderAccordionHeaderProps {}

export const StakeHolderAccordionHeader: FC<
  StakeHolderAccordionHeaderProps
> = () => {
  return (
    <Flex justifyContent={"space-between"} width="100%">
      <Grid gridGap={"3px"}>
        <AccordionHeadingLabel value={"Opportunity name"} />
        <Box ml="-5px">
          <Tag color="#C95E22" bgColor="#FFEADE" text="South region" />
        </Box>
      </Grid>
      <Grid gridGap={"3px"}>
        <AccordionHeadingLabel value={"Company name"} />
        <AccordionHeadingValue value={"Compass Press"} />
      </Grid>
      <Grid gridGap={"3px"}>
        <AccordionHeadingLabel value={"Value"} />
        <AccordionHeadingValue value={"$450K"} />
      </Grid>
      <Grid gridGap={"3px"}>
        <AccordionHeadingLabel value={"Opportunity stage"} />
        <AccordionHeadingValue value={"Verbal"} />
      </Grid>
      <Grid>
        <Flex>
          <Text variant="title" color="basicColors.basicGreen">
            5
          </Text>
          <Text variant="title" color="textColor.deactivate">
            /
          </Text>
          <Text variant="title" color="textColor.secondary">
            5
          </Text>
        </Flex>
        <Text variant="bodyRegular" color="textColor.primary">
          Stakeholders selected
        </Text>
      </Grid>
    </Flex>
  );
};
