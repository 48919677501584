import { Avatar } from "@evabot/eva-react-core-library/dist/atoms/Avatar/Avatar";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import ProfileEditIcon from "assets/icons/ProfileEditIcon";
import { Form, Formik } from "formik";
import { useAppDispatch, useAppSelector } from "hooks";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  aeReturnProfileData,
  aeReturnProfileDataLoading,
  aeReturnProfileReducerType,
} from "./store/aeReturnProfileReducer";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { axiosHelper, axiosHelperCore } from "utils";

interface ProfileSectionProps {
  onSubmit?: () => void;
  initialValue?: aeReturnProfileReducerType;
  setShowSnackBar: (e: any) => void;
}

const ProfileSchema = yup.object().shape({
  firstName: yup.string().required("aereturn.profile.firstname.valid"),
  lastName: yup.string().required("aereturn.profile.lastname.valid"),
  email: yup
    .string()
    .trim()
    .email("aereturn.profile.email.invalid")
    .required("aereturn.profile.email.valid"),
  // designation: yup.string().required("aereturn.profile.designation.valid"),
  // comapnyName: yup.string().required("aereturn.profile.comapnyname.valid"),
  // phoneNo: yup
  //   .string()
  //   .required("aereturn.profile.phoneno.valid")
  //   .min(10, "aereturn.profile.phoneno.min"),
});

const ProfileSection: React.FC<ProfileSectionProps> = ({
  onSubmit,
  initialValue,
  setShowSnackBar,
}) => {
  const loading = useAppSelector((state) => state.profileState.loading);
  const dispatch = useAppDispatch();
  const loggedInMember = useAppSelector(
    (state) => state.profileState.profileData
  );

  const onSubmitHandler = useCallback(
    async (values: any) => {
      //@ts-ignore
      dispatch(aeReturnProfileDataLoading(true));
      await axiosHelperCore({
        url: "rapport/user/updateMemberInfoForProfile",
        method: "POST",
        JSONData: {
          ...values,
          imageFile: "",
        },
        urlOverride: false,
      })
        .then((result) => {
          if (result?.data?.success === true) {
            setShowSnackBar(true);
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
      const result = await axiosHelperCore({
        url: `rapport/user/getMemberInfoForProfile`,
        method: "POST",
      });
      const data = result.data.data;
      const formData = {
        userId: data?.userId,
        firstName: data?.identityPersonal?.firstName,
        lastName: data?.identityPersonal?.lastName,
        email: data?.identityContact?.email,
        role: data?.access?.type,
        designation: result.data?.designation,
        comapnyName: data?.identityBusiness?.name,
        phoneNo: data?.identityContact?.mobile,
        imageFile: data?.identityPersonal?.image,
        refreshTokenType: loggedInMember?.refreshTokenType,
        refreshToken: loggedInMember?.refreshToken,
        expiredOn: loggedInMember?.expiredOn,
        tokenNumber: loggedInMember?.tokenNumber,
        organizationId: loggedInMember?.organizationId,
        isExaSummary: result.data?.designation,
      };
      dispatch(aeReturnProfileData(formData));
      //@ts-ignore
      dispatch(aeReturnProfileDataLoading(false));
    },
    [dispatch]
  );

  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    designation: "",
    comapnyName: "",
    phoneNo: "",
    imageFile: "",
  };

  return (
    <Box overflowY={"scroll"} height={"100%"}>
      {loading ? (
        <Icon name="loadingIcon" />
      ) : (
        <Flex justifyContent={"center"}>
          <Box mt="27px" mb={"27px"}>
            <Text
              color="textColor.primary"
              variant="labelRegular"
              mb="10px"
              height={"max-content"}
            >
              My profile
            </Text>
            {loading ? (
              <Icon name="loadingIcon" />
            ) : (
              <Flex
                flexDirection={"column"}
                width={"830px"}
                background={"white"}
                border="1px solid rgba(0, 0, 0, 0.1)"
                borderRadius={"12px"}
                boxShadow={"0px 4px 15px rgba(0, 0, 0, 0.05)"}
              >
                <Flex
                  p="24px 32px"
                  borderBottom={"1px solid rgba(0, 0, 0, 0.1)"}
                >
                  <Text variant="ctaLargeMedium" ml="10px">
                    Account Information
                  </Text>
                </Flex>

                <Grid pt="24px" pb="32px">
                  <Formik
                    initialValues={initialValue ? initialValue : defaultValues}
                    onSubmit={onSubmitHandler}
                    enableReinitialize
                    validationSchema={ProfileSchema}
                  >
                    {({
                      values,
                      initialValues,
                      dirty,
                      isValid,
                      submitForm,
                      setFieldValue,
                    }) => {
                      return (
                        <Form>
                          <Flex>
                            <Box ml="32px" position={"relative"}>
                              <Avatar
                                width="62px"
                                height="62px"
                                borderRadius="50%"
                                imageUrl={
                                  values?.imageFile
                                    ? values?.imageFile
                                    : "https://www.newsbtc.com/wp-content/uploads/2020/06/mesut-kaya-LcCdl__-kO0-unsplash-scaled.jpg"
                                }
                              />
                              <label>
                                <input
                                  accept=".JPG"
                                  style={{ display: "none" }}
                                  multiple
                                  type="file"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "imageFile",
                                      URL.createObjectURL(e?.target?.files[0])
                                    );
                                  }}
                                />
                                <Flex
                                  justifyContent={"center"}
                                  alignItems="center"
                                  width={"24px"}
                                  height={"24px"}
                                  position="absolute"
                                  background={"white"}
                                  boxShadow={"0px 2px 4px rgba(0, 0, 0, 0.1)"}
                                  borderRadius="50%"
                                  top="40px"
                                  left={"55px"}
                                  cursor="pointer"
                                >
                                  <ProfileEditIcon />
                                </Flex>
                              </label>
                            </Box>

                            <Grid ml="25px">
                              <Flex mb="5px">
                                <Box width={"300px"}>
                                  <FormikInput
                                    width={"100%"}
                                    label="aereturn.profile.firstname"
                                    name="firstName"
                                    placeholder="aereturn.profile.firstname"
                                  />
                                </Box>
                                <Box ml="40px" width={"300px"}>
                                  <FormikInput
                                    width={"100%"}
                                    label="aereturn.profile.lastname"
                                    name="lastName"
                                    placeholder="aereturn.profile.lastname"
                                  />
                                </Box>
                              </Flex>
                              <Grid width={"300px"} gridGap="5px">
                                <FormikInput
                                  disabled={initialValues?.email?.length !== 0}
                                  width={"100%"}
                                  label="aereturn.profile.email"
                                  name="email"
                                  placeholder="aereturn.profile.email"
                                />
                                <FormikInput
                                  width={"100%"}
                                  label="aereturn.profile.designation"
                                  name="designation"
                                  placeholder="aereturn.profile.designation"
                                />
                                <FormikInput
                                  width={"100%"}
                                  label="aereturn.profile.comapnyname"
                                  name="comapnyName"
                                  placeholder="aereturn.profile.comapnyname"
                                />
                                <FormikInput
                                  width={"100%"}
                                  label="aereturn.profile.phoneno"
                                  name="phoneNo"
                                  placeholder="aereturn.profile.phoneno"
                                />
                              </Grid>
                              <Box width={"200px"} mt="10px">
                                <Button
                                  fullSize
                                  variant="primary"
                                  borderRadius={"12px"}
                                  fontSize="14px"
                                  onClick={submitForm}
                                  disabled={!dirty || !isValid}
                                >
                                  Update my information
                                </Button>
                              </Box>
                            </Grid>
                          </Flex>
                        </Form>
                      );
                    }}
                  </Formik>
                </Grid>
              </Flex>
            )}
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default ProfileSection;
