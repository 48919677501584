import Button from 'components/Elements/Button';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { useSalesforceFields, useSaveSalesforceSettings, useSavedSalesforceFields } from 'utility/salesForce';
const SalesforceForm = ({ setShowForm, setSalesforceText }) => {
    const contactFieldsData = useSalesforceFields("Contact");
    const leadFieldsData = useSalesforceFields("Lead");
    const { salesforceFields, changeSalesforceFields } = useSavedSalesforceFields({ dataSource: "", tagField: null, callPrepField: null });
    const fieldOptions = salesforceFields.dataSource === "Contact" ? contactFieldsData.map(f => ({ label: f.label, value: f.name })) : leadFieldsData.map(f => ({ label: f.label, value: f.name }));
    const { saveFormdata } = useSaveSalesforceSettings();
    const [showMessage, setShowMessage] = useState(false);

    const handleDataSourceChange = (e) => {
        changeSalesforceFields({ tagField: null, callPrepField: null, dataSource: e.target.value });
    }

    useEffect(() => {
        const salesforceText = salesforceFields?.tagField ? "Connected" : "Authenticated";
        setSalesforceText(salesforceText);
    }, [salesforceFields])

    const saveData = () => {
        const formData = { salesforceFields };
        saveFormdata(formData);
        setShowMessage(true);
        setShowForm(false);
        setTimeout(() => {
            setShowMessage(false);
        }, 4000);
    }

    return (

        <div className='text-left w-11/12'>
            <div className='text-sm text-[#57585C]'>Salesforce Object to be tracked<sup className='text-red-500'>*</sup></div>
            <div className="flex text-sm gap-6 mt-2">
                <div className="flex items-center ">
                    <input type="radio" id="salesforce-contacts" onClick={handleDataSourceChange} checked={salesforceFields.dataSource === "Contact"} value="Contact" name="salesforce-objects" className="form-radio h-4 w-4 text-blue-500" />
                    <label className="ml-2 text-gray-700">Contacts</label>
                </div>
                <div className="flex items-center">
                    <input type="radio" id="salesforce-leads" onClick={handleDataSourceChange} checked={salesforceFields.dataSource === "Lead"} value="Lead" name="salesforce-objects" className="form-radio h-4 w-4 text-blue-500" />
                    <label className="ml-2 text-gray-700">Leads</label>
                </div>
            </div>
            <div className='text-sm text-[#57585C] mt-4 mb-1'>Field name that contains tags<sup className='text-red-500'>*</sup></div>
            <div><Select
                className="text-sm"
                menuPlacement='top'
                placeholder="Select"
                value={salesforceFields?.tagField}
                name="field1"
                options={fieldOptions.filter(item => item.value !== salesforceFields?.callPrepField?.value)}
                onChange={(selectedField) => {
                    changeSalesforceFields({ ...salesforceFields, tagField: selectedField });
                }} />
            </div>
            <div className='text-sm text-[#57585C] mt-4 mb-1'>Field name to save the call summary</div>
            <div>
                <Select className="text-sm"
                    menuPlacement='top'
                    placeholder="Select"
                    name="field2"
                    value={salesforceFields?.callPrepField}
                    options={fieldOptions.filter(item => item.value !== salesforceFields?.tagField?.value)}
                    onChange={(selectedField) => {
                        changeSalesforceFields({ ...salesforceFields, callPrepField: selectedField });
                    }} />
            </div>
            {showMessage && <div className='m-3 text-[#38C088] text-sm'>
                Settings successfully saved!
            </div>}
            <div className='mt-5 text-center flex flex-wrap space-x-2 justify-center'>
                <Button variant={"salesforce"} text={salesforceFields?.tagField ? "Save" : "Complete Connection"} onClick={saveData} disabled={!(salesforceFields?.tagField && salesforceFields?.dataSource)} />
                {salesforceFields?.tagField && (<Button variant={"secondary"} text={"Cancel"} onClick={() => { setShowForm(false) }} />)}
            </div>
        </div>

    )
}

export default SalesforceForm;