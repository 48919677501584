import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";

interface AeReturnActivityTagProps {
  tagBgColor: string;
  tagColor: string;
  tagText: string;
  smallTag?: boolean;
}

const AeReturnActivityTag: React.FC<AeReturnActivityTagProps> = ({
  tagBgColor,
  tagColor,
  tagText,
  smallTag,
}) => {
  return (
    <Flex
      fontSize={smallTag ? "9px" : "12px"}
      fontWeight={400}
      p="4px 8px"
      borderRadius={"6px"}
      backgroundColor={tagBgColor}
      color={tagColor}
      width="max-content"
      height={"max-content"}
      my="8px"
      textTransform="capitalize"
    >
      {tagText}
    </Flex>
  );
};

export default AeReturnActivityTag;
