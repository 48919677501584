import apiService from "./apiService";

class reportService {

    async getProspectReportSuperAdmin(obj) {
        return apiService.post("rapport/report/getProspectReport",obj);
    }

    async getProspectReportAdmin(obj) {
        return apiService.post("rapport/report/getProspectReport2",obj);
    }

    async getCompanyScoreReport(obj) {
        return apiService.post("rapport/report/getCompanyScoreReport", obj);
    }

    async getProspectReportCountAsPerDate() {
        return apiService.post("rapport/report/getProspectReportCountAsPerDate",{});
    }



}
export default new reportService();