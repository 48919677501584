import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { ToggleButton } from "@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useCallback, useState } from "react";
import { axiosHelper } from "utils";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { AeReturnStakeModal } from "./AeReturnStakeTable/AeReturnStakeModal";
import {
  setStakeholderDataChange,
  setStakeholdersData,
  setStakeholdersLoadingData,
} from "store/globalStateReducer";
import { useDispatch } from "react-redux";
import { useAppSelector } from "hooks";

const ListOpportunityValidationSchema = yup.object().shape({});

interface AeReturnMobileViewRowProps {
  user: any;
  
  index: number;
}

const AeReturnMobileViewRow: React.FC<
AeReturnMobileViewRowProps
> = ({ user,  index }) => {
  const dispatch = useDispatch();
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [hide, setHide] = useState(true);
 

  const onSubmitHandler = useCallback(
    async (values: any) => {
      console.log("called , userrr", values);
      setHide(true);
      //@ts-ignore
      dispatch(setStakeholdersLoadingData(true));
      const result = await axiosHelper({
        //@ts-ignore
        url: `salesforce/updateStakeholderNudgeEnabledFlag/${user?.stakeholderId}/${values?.enableNudge}`,
        method: "PATCH",
      });
      if (result.data.success) {
        setShowSnackBar(true);
      }

      const resultStakeholder = await axiosHelper({
        url: `salesforce/getAllStakeholdersForAE`,
      });
      //@ts-ignore
      dispatch(setStakeholdersData([...resultStakeholder.data?.allData]));
      //@ts-ignore
      dispatch(setStakeholdersLoadingData(false));
      //@ts-ignore
      dispatch(setStakeholderDataChange(true));
    },
    [dispatch, user?.stakeholderId]
  );

  const defaultValue = {
    enableNudge: user?.isNudgesEnabled,
  };

  return (
    <Formik
    initialValues={defaultValue}
    onSubmit={onSubmitHandler}
    enableReinitialize
    validationSchema={ListOpportunityValidationSchema}
  >
    {({ values, setFieldValue, submitForm, initialValues }) => {
      return (
        <Form>
          {
            <AeReturnStakeModal
              mobile={true}
              callbackMethod={() => {
                setFieldValue("enableNudge", !values?.enableNudge);
                console.log("called , userrr inside", values);
                submitForm();
              }}
              userData={user}
              width="670px"
              height="max-content"
              hideModal={hide}
              setHideModal={() => {
                setHide(true);
              }}
              children={"hiiiii"}
            ></AeReturnStakeModal>
          }
          <SnackBar
            iconName={"paperPlane"}
            setShowSnackBar={setShowSnackBar}
            show={showSnackBar}
            message={`Nudges ${
              values?.enableNudge ? "enabled" : "disabled"
            } for ${user?.identity?.firstname}`}
            timeout={2000}
            anchor={"top-right"}
            type={"success"}
            bgColorr="#24B300"
          />
           <Box  background={ (index  % 2 != 0) ? "#F9F9F9":"#fff" } borderRadius={ (index  % 2 != 0) ? "8px":  "0px" } height={"65px"} padding={"16px 12px"} marginLeft={"8px"} marginRight={"8px"} >
                        <Flex flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} >
                           <Flex flexDirection={"column"} gridRowGap={"4px"}  >
                           <Text
                           fontSize={"14px"}
                           fontWeight={"400"}
                           color={"#050505"}
                           lineHeight={"140%"}
                      >
                        {user?.identity?.firstname} {user?.identity?.lastname}
                      </Text>
                      <Flex alignItems={"center"}>
                      <Icon name={"mail"} fill={"#9F9F9F"} />
                      <Text
                        fontWeight={400}
                        fontSize={"12px"}
                        lineHeight={"140%"}
                        ml="7px"
                      >
                        {user?.identity?.email || "No Email Found"}
                      </Text>
                    </Flex>
                           </Flex>
                           <ToggleButton
                      name="enableNudge"
                      variant="secondary"
                      onChange={() => {
                        setHide(!hide);
                      }}
                      value={values?.enableNudge}
                    />
                        </Flex>
                    </Box>
        </Form>
      );
    }}
  </Formik>
  )



};

export default AeReturnMobileViewRow;