import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

const initialState = {
  prospectReportData: [],
};

export interface prospectReportReducerType {}
const prospectReportReducerSlice = createSlice({
  name: "prospectReportState",
  initialState: initialState,
  reducers: {
    setprospectReportData: (
      state,
      action: PayloadAction<prospectReportReducerType>
    ) => {
      //@ts-ignore
      state.prospectReportData = action.payload;
    },
  },
});

export const {
  setprospectReportData,
} = prospectReportReducerSlice.actions;

export const prospectReportState = (state: RootState) => state.prospectReportState;

export default prospectReportReducerSlice.reducer;
