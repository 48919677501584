import * as React from "react";

function LeftIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M17.77 3.77L16 2L6 12L16 22L17.77 20.23L9.54 12L17.77 3.77Z"
        style={props?.style}
        fill={props?.fill}
      />
    </svg>
  );
}

export default LeftIcon;
