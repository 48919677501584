import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { ToggleButton } from "@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useCallback, useState } from "react";
import { axiosHelper } from "utils";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { AeReturnStakeModal } from "./AeReturnStakeModal";
import {
  setStakeholderDataChange,
  setStakeholdersData,
  setStakeholdersLoadingData,
  setstakeholderSnackbar,
} from "store/globalStateReducer";
import { useDispatch } from "react-redux";
import { useAppSelector } from "hooks";
import { getDomainFromEmail } from "utils/getDomainFromEmail";
import { getDateString } from "utils/constants";
import { useNavigate } from "react-router-dom";

const ListOpportunityValidationSchema = yup.object().shape({});

interface AeReturnStakeTableBodyFieldProps {
  setOpenSearchModal: (e: any) => void;
  setSearchQuery: (e: any) => void;
  user: any;
  page: number;
  index: number;
}

const AeReturnStakeTableBodyField: React.FC<
  AeReturnStakeTableBodyFieldProps
> = ({ user, page, index, setOpenSearchModal, setSearchQuery }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hide, setHide] = useState(true);
  const stakeholderPerPage = useAppSelector(
    (state) => state.globalState.stakeholderPerPage
  );

  const onSubmitHandler = useCallback(
    async (values: any) => {
      setSearchQuery(null);
      setHide(true);
      //@ts-ignore
      dispatch(setStakeholdersLoadingData(true));
      const result = await axiosHelper({
        //@ts-ignore
        url: `salesforce/updateStakeholderNudgeEnabledFlag/${user?.stakeholderId}/${values?.enableNudge}`,
        method: "PATCH",
      });
      if (result.data.success) {
        dispatch(
          setstakeholderSnackbar({
            //@ts-ignore
            show: true,
            message: `Nudges ${
              values?.enableNudge ? "enabled" : "disabled"
            } for ${user?.identity?.firstname}`,
          })
        );
      }

      const resultStakeholder = await axiosHelper({
        url: `salesforce/getAllStakeholdersForAE`,
      });
      //@ts-ignore
      dispatch(setStakeholdersData([...resultStakeholder.data?.allData]));
      //@ts-ignore
      dispatch(setStakeholdersLoadingData(false));
      //@ts-ignore
      dispatch(setStakeholderDataChange(true));
    },
    [dispatch, user?.stakeholderId]
  );

  const defaultValue = {
    enableNudge: user?.isNudgesEnabled,
  };

  return (
    <Flex
      flexDirection={"column"}
      justifyContent="center"
      fontSize="14px"
      fontWeight={400}
      color="#050505"
    >
      <Formik
        initialValues={defaultValue}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validationSchema={ListOpportunityValidationSchema}
      >
        {({ values, setFieldValue, submitForm, initialValues }) => {
          return (
            <Form>
              {
                <AeReturnStakeModal
                  callbackMethod={() => {
                    setFieldValue("enableNudge", !values?.enableNudge);
                    submitForm();
                  }}
                  userData={user}
                  width="max-content"
                  height="max-content"
                  hideModal={hide}
                  setHideModal={() => {
                    setHide(true);
                  }}
                  children={"hiiiii"}
                ></AeReturnStakeModal>
              }

              <tr className={(index + 1) % 2 === 0 ? "demo" : ""}>
                <Flex alignItems={"center"} width={"5%"}>
                  <td>
                    {page === 0
                      ? index + 1
                      : page * stakeholderPerPage + (index + 1)}
                  </td>
                </Flex>
                <Flex width={"15%"}>
                  <td>
                    <Grid alignItems={"center"} height={"100%"}>
                      <Text
                        variant="bodyRegular"
                        color="#0094E3"
                        fontWeight={500}
                        cursor="pointer"
                        width={"fit-content"}
                        onClick={() => {
                          navigate(
                            `/aeReturnDashboard/stakeholder/${user.stakeholderId}`,
                            { replace: true }
                          );
                        }}
                      >
                        {user?.identity?.firstname} {user?.identity?.lastname}
                      </Text>
                      {user?.identity?.position && (
                        <Text
                          color="#9F9F9F"
                          fontSize={"12px"}
                          fontWeight={400}
                          textAlign="start"
                        >
                          {user?.identity?.position}
                        </Text>
                      )}
                    </Grid>
                  </td>
                </Flex>

                <Flex alignItems={"center"} width="20%">
                  <td>
                    <Flex alignItems={"center"}>
                      <Icon name={"mail"} fill={"#9F9F9F"} />
                      <Text
                        variant="bodyRegular"
                        color="textColor.primary"
                        fontWeight={500}
                        ml="7px"
                        wordBreak="break-word"
                      >
                        {user?.identity?.email || "No Email Found"}
                      </Text>
                    </Flex>
                  </td>
                </Flex>

                <Flex alignItems={"center"} width="15%">
                  <td>
                    {" "}
                    <Text
                      textAlign={"center"}
                      fontWeight={500}
                      wordBreak="break-word"
                    >
                      {getDomainFromEmail(user?.identity?.email) || "-"}
                    </Text>
                  </td>
                </Flex>

                <Flex
                  alignItems={"center"}
                  width="10%"
                  justifyContent={"center"}
                >
                  <td>
                    <Flex justifyContent={"center"}>
                      <Text fontWeight={500}>
                        {getDateString(user?.createdAt, true)}
                      </Text>
                    </Flex>
                  </td>
                </Flex>
                <Flex width="10%" justifyContent={"center"}>
                  <td>
                    <Flex justifyContent={"center"}>
                      <ToggleButton
                        name="enableNudge"
                        variant="tertiary"
                        onChange={() => {
                          setOpenSearchModal(false);
                          // setSearchQuery(null);
                          setHide(false);
                        }}
                        value={values?.enableNudge}
                        smallSize
                      />
                    </Flex>
                  </td>
                </Flex>
              </tr>
            </Form>
          );
        }}
      </Formik>
    </Flex>
  );
};

export default AeReturnStakeTableBodyField;
