import {  useState } from "react";
import { AeReturnJourneyLayout } from "@evabot/eva-react-core-library/dist/templates/AeReturnJourneyLayout/AeReturnJourneyLayout";
import { AeSidebarSection } from "components/AeSidebar/AeSidebarSection";
import { AeSidebarSectionCollapse } from "components/AeSidebar/AeSidebarSectionCollapse";
import ProfileModalData from "containers/AEReturnDashboard/ProfileModalData";
import { useAppSelector } from "hooks";
import FrameworkAndContextRightSection from "./FrameworkAndContextRightSection";

function FrameworkAndContext() {
    const profileData = useAppSelector((state) => state.profileState.profileData);
    const [openModal, setOpenModal] = useState(false);
    const [value, setValue] = useState(null);

    return (
        <>
            <AeReturnJourneyLayout
                as="div"
                left={20}
                right={82}
                leftChildren={<AeSidebarSection activeTab="frameworkandcontext" />}
                leftCollapsedChildren={
                    <AeSidebarSectionCollapse activeTab="frameworkandcontext" />
                }
                rightChildren={
                    <FrameworkAndContextRightSection />
                }
                profileModalChild={<ProfileModalData />}
                imageUrl={profileData?.imageFile}
                value={value}
                setValue={setValue}
                openSearchModal={openModal}
                setOpenSearchModal={setOpenModal}
                pageHeading="Custom Frameworks/Contexts"
            />
        </>
    );
}

export default FrameworkAndContext;
