import apiService from "./apiService";

class emailTemplateService {

    async addEmailTemplate(obj) {
        return apiService.post("rapport/emailTemplate/addEmailTemplate",obj);
    }

    async getAllEmailTemplateList() {
        return apiService.post("rapport/emailTemplate/getAllEmailTemplateList", {});
    }

    async editEmailTemplate(obj) {
        return apiService.post("rapport/emailTemplate/editEmailTemplate",obj);
    }
    async sendEmail(obj) {
        return apiService.post("rapport/emailTemplate/sendEmail",obj);
    }

}
export default new emailTemplateService();