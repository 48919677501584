import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { useAppSelector } from "hooks";
import ReactDOM from "react-dom";
import {
  convertDate,
  getDatesBetweenDates,
  // getDatesBetweenDatesNew,
} from "utils/constants";
import { format } from "date-fns";
import { useEffect, useRef } from "react";

interface AeReturnStakeTableHeaderProps { }

const AeReturnAnalyticsTableHeader: React.FC<
  AeReturnStakeTableHeaderProps
> = () => {
  const dateValues = useAppSelector(
    (state) => state?.analyticsState?.dateRange
  );

  const dateRange = getDatesBetweenDates(
    convertDate(dateValues[0]),
    convertDate(dateValues[1])
  );

  const headerRef = useRef();

  useEffect(() => {
    ReactDOM.findDOMNode(headerRef.current).parentElement.style.border = "none";
  }, [])

  // const db = getDatesBetweenDatesNew(dateValues[0], dateValues[1]);

  return (
    <Flex
      ref={headerRef}
      padding={"0px 15px"}
      fontSize="12px"
      fontWeight={500}
      position="sticky"
      backgroundColor={"#F8F8F8"}
      justifyContent={"center"}
      alignItems={"center"}
      className="table-data-tm"
    >
      <Flex
        width="20%"
        justifyContent={"center"}
        alignItems={"center"}
      >
        {" "}
        Rep name{" "}
      </Flex>
      <Box width={"80%"} mr="18px">
        <Flex
          justifyContent={"space-between"}
          padding={"10px"}
          alignItems={"center"}
          justifyItems={"center"}
        >
          <Flex width={"20%"}>Activity Type</Flex>
          <Flex width={"80%"} justifyContent={"space-between"}>
            {dateRange?.map((item) => {
              return (
                <>
                  <Flex
                    width="100%"
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <Flex> {item?.dateString?.split(" ")[0]}</Flex>
                    <Flex>
                      {item.date !== "total" ? (
                        <Flex color={"#9F9F9F !important"} marginTop={"5px"}>
                          {item?.dateString?.split(" ")[1]}{" "}
                          {item?.dateString?.split(" ")[2]}
                        </Flex>
                      ) : (
                        "Total"
                      )}
                    </Flex>
                  </Flex>
                </>
              );
            })}
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default AeReturnAnalyticsTableHeader;
