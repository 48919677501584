
function AccountReportTableHeader({ memberData, accountData, dateRange, tableRef }) {
    console.log("dateRangedateRangedateRangedateRangedateRange", dateRange);

    if (memberData.length == 0 || Object.keys(accountData).length == 0 || dateRange.length == 0)
        return;

    return (
        <div className="table-container" style={{ borderRadius: "10px" }}>
            <table ref={tableRef}>
                <thead>
                    <tr>
                        <th style={{ width: "200px" }} className="fixed-header fixed-column">Member Name</th>
                        {dateRange?.map((m, index) => (<th className="fixed-header" key={"th_" + index}><span style={{color:"grey"}}>{m.day}</span><br /><div>{m.displayDate}</div></th>))}
                    </tr>
                </thead>
                <tbody>
                    {memberData?.map((m, index) =>
                    (<tr>
                        <td style={{ backgroundColor: (index + 1) % 2 == 0 ? "rgb(248, 248, 248)" : "#fff" }} className="fixed-column"><div>{m.firstName + " " + m.lastName}</div></td>
                        {
                            dateRange?.map(d => (<td style={{ backgroundColor: (index + 1) % 2 == 0 ? "rgb(248, 248, 248)" : "#fff" }}>{d?.isBlank ? "" : (accountData[m.userId]?.[d.date]|| 0)} </td>))
                        }

                    </tr>))}

                </tbody>
            </table>
        </div>
    );
}
export default AccountReportTableHeader;