import * as React from 'react';

function DeloitteLogo() {
    return (
        <svg width="56" height="12" viewBox="0 0 56 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M52.3979 9.75951C52.3979 8.95582 53.0491 8.30469 53.8528 8.30469C54.6565 8.30469 55.3076 8.95582 55.3076 9.75951C55.3076 10.5632 54.6565 11.2143 53.8528 11.2143C53.0491 11.2143 52.3979 10.5632 52.3979 9.75951Z" fill="white" />
            <path d="M8.79077 5.81566C8.79077 7.49373 8.34056 8.78483 7.43641 9.6927C6.53598 10.6006 5.2672 11.0545 3.63378 11.0545H0.459961V1.00098H3.85703C5.43091 1.00098 6.64761 1.41398 7.50711 2.23999C8.36288 3.06601 8.79077 4.25665 8.79077 5.81566ZM6.0374 5.9124C6.0374 4.99337 5.8588 4.30874 5.50161 3.86225C5.14441 3.41576 4.6049 3.19623 3.87935 3.19623H3.10915V8.82576H3.70075C4.50816 8.82576 5.09604 8.58763 5.47184 8.10765C5.84764 7.62767 6.0374 6.89468 6.0374 5.9124Z" fill="white" />
            <path d="M20.1128 0.959961H17.5864V11.0544H20.1128V0.959961Z" fill="white" />
            <path d="M28.4065 7.28898C28.4065 8.51311 28.0865 9.47307 27.4391 10.1577C26.7954 10.8423 25.895 11.1883 24.7341 11.1883C23.5732 11.1883 22.7398 10.8386 22.0812 10.1354C21.4226 9.43215 21.0952 8.48707 21.0952 7.28898C21.0952 6.09089 21.4152 5.11605 22.0626 4.43887C22.7063 3.76169 23.6105 3.4231 24.7751 3.4231C25.4932 3.4231 26.1294 3.57937 26.6801 3.89191C27.2308 4.20446 27.6549 4.65467 27.9563 5.23883C28.254 5.82299 28.4065 6.50762 28.4065 7.28526V7.28898ZM23.6625 7.28526C23.6625 7.92895 23.7444 8.42009 23.9156 8.75868C24.083 9.09727 24.3658 9.26471 24.7639 9.26471C25.162 9.26471 25.4336 9.09727 25.5936 8.75868C25.7536 8.42009 25.8355 7.92895 25.8355 7.28526C25.8355 6.64156 25.7536 6.16158 25.5899 5.83788C25.4262 5.51045 25.1471 5.34674 24.749 5.34674C24.3509 5.34674 24.083 5.51045 23.9156 5.83416C23.7481 6.15786 23.6625 6.64156 23.6625 7.28526Z" fill="white" />
            <path d="M31.9341 3.55713H29.4077V11.0545H31.9341V3.55713Z" fill="white" />
            <path d="M31.9341 0.959961H29.4077V2.64919H31.9341V0.959961Z" fill="white" />
            <path d="M37.0537 9.14185C37.396 9.14185 37.8015 9.05627 38.2741 8.8814V10.7678C37.9318 10.9167 37.608 11.0246 37.3029 11.0878C36.9941 11.1511 36.6332 11.1846 36.2202 11.1846C35.3719 11.1846 34.7617 10.9725 34.3859 10.5446C34.0101 10.1204 33.824 9.46556 33.824 8.58373V5.49549H32.9385V3.55697H33.824V1.64077L36.369 1.198V3.55697H37.9801V5.49549H36.369V8.41258C36.369 8.89628 36.596 9.14185 37.0537 9.14185Z" fill="white" />
            <path d="M42.9028 9.14204C43.2451 9.14204 43.6506 9.05646 44.1232 8.88158V10.768C43.7846 10.9168 43.4572 11.0247 43.1521 11.088C42.8432 11.1513 42.4823 11.1847 42.0693 11.1847C41.221 11.1847 40.6108 10.9727 40.235 10.5448C39.8592 10.1206 39.6731 9.46574 39.6731 8.58392V5.49568H38.7876V3.55715H39.6731V1.61491L42.2182 1.2019V3.55715H43.8292V5.49568H42.2182V8.41276C42.2182 8.89646 42.4451 9.14204 42.9028 9.14204Z" fill="white" />
            <path d="M50.8766 4.30864C50.2775 3.72076 49.4255 3.4231 48.3204 3.4231C47.1595 3.4231 46.2702 3.76169 45.6452 4.43887C45.0201 5.11605 44.7075 6.08717 44.7075 7.35223C44.7075 8.6173 45.0461 9.52516 45.7196 10.1875C46.393 10.8498 47.3418 11.1846 48.5585 11.1846C49.1427 11.1846 49.6487 11.1437 50.0692 11.0656C50.4896 10.9874 50.8952 10.846 51.2896 10.6377L50.9026 8.94844C50.6161 9.06379 50.3445 9.15681 50.0878 9.21634C49.7157 9.30192 49.3064 9.34657 48.8636 9.34657C48.3762 9.34657 47.993 9.2275 47.7102 8.98937C47.4274 8.75124 47.2786 8.42381 47.26 8.00337H51.777V6.85365C51.777 5.74486 51.4756 4.89652 50.8766 4.30864ZM47.3121 6.37739C47.3455 5.9681 47.4646 5.66672 47.6655 5.47324C47.8665 5.27976 48.1158 5.18674 48.4134 5.18674C48.7371 5.18674 48.9938 5.29464 49.1836 5.51045C49.3771 5.72625 49.4775 6.01647 49.485 6.37739H47.3158H47.3121Z" fill="white" />
            <path d="M15.7081 4.30864C15.1053 3.72076 14.2533 3.4231 13.1519 3.4231C11.9948 3.4231 11.1018 3.76169 10.4767 4.43887C9.85161 5.11605 9.53906 6.08717 9.53906 7.35223C9.53906 8.6173 9.87765 9.52516 10.5511 10.1875C11.2246 10.8498 12.1734 11.1846 13.3901 11.1846C13.9742 11.1846 14.4802 11.1437 14.9007 11.0656C15.3211 10.9874 15.7267 10.846 16.1211 10.6377L15.7341 8.94844C15.4476 9.06379 15.176 9.15681 14.9193 9.21634C14.5472 9.30192 14.1379 9.34657 13.6952 9.34657C13.2077 9.34657 12.8245 9.2275 12.5417 8.98937C12.2589 8.75124 12.1101 8.42381 12.0915 8.00337H16.6085V6.85365C16.6085 5.74486 16.3071 4.89652 15.7081 4.30864ZM12.1436 6.37739C12.1771 5.9681 12.2962 5.66672 12.4971 5.47324C12.698 5.27976 12.9473 5.18674 13.245 5.18674C13.5687 5.18674 13.8254 5.29464 14.0152 5.51045C14.2049 5.72625 14.3054 6.01647 14.3165 6.37739H12.1473H12.1436Z" fill="white" />
            <path d="M52.3979 9.75951C52.3979 8.95582 53.0491 8.30469 53.8528 8.30469C54.6565 8.30469 55.3076 8.95582 55.3076 9.75951C55.3076 10.5632 54.6565 11.2143 53.8528 11.2143C53.0491 11.2143 52.3979 10.5632 52.3979 9.75951Z" fill="white" />
            <path d="M8.79077 5.81566C8.79077 7.49373 8.34056 8.78483 7.43641 9.6927C6.53598 10.6006 5.2672 11.0545 3.63378 11.0545H0.459961V1.00098H3.85703C5.43091 1.00098 6.64761 1.41398 7.50711 2.23999C8.36288 3.06601 8.79077 4.25665 8.79077 5.81566ZM6.0374 5.9124C6.0374 4.99337 5.8588 4.30874 5.50161 3.86225C5.14441 3.41576 4.6049 3.19623 3.87935 3.19623H3.10915V8.82576H3.70075C4.50816 8.82576 5.09604 8.58763 5.47184 8.10765C5.84764 7.62767 6.0374 6.89468 6.0374 5.9124Z" fill="white" />
            <path d="M20.1128 0.959961H17.5864V11.0544H20.1128V0.959961Z" fill="white" />
            <path d="M28.4065 7.28898C28.4065 8.51311 28.0865 9.47307 27.4391 10.1577C26.7954 10.8423 25.895 11.1883 24.7341 11.1883C23.5732 11.1883 22.7398 10.8386 22.0812 10.1354C21.4226 9.43215 21.0952 8.48707 21.0952 7.28898C21.0952 6.09089 21.4152 5.11605 22.0626 4.43887C22.7063 3.76169 23.6105 3.4231 24.7751 3.4231C25.4932 3.4231 26.1294 3.57937 26.6801 3.89191C27.2308 4.20446 27.6549 4.65467 27.9563 5.23883C28.254 5.82299 28.4065 6.50762 28.4065 7.28526V7.28898ZM23.6625 7.28526C23.6625 7.92895 23.7444 8.42009 23.9156 8.75868C24.083 9.09727 24.3658 9.26471 24.7639 9.26471C25.162 9.26471 25.4336 9.09727 25.5936 8.75868C25.7536 8.42009 25.8355 7.92895 25.8355 7.28526C25.8355 6.64156 25.7536 6.16158 25.5899 5.83788C25.4262 5.51045 25.1471 5.34674 24.749 5.34674C24.3509 5.34674 24.083 5.51045 23.9156 5.83416C23.7481 6.15786 23.6625 6.64156 23.6625 7.28526Z" fill="white" />
            <path d="M31.9341 3.55713H29.4077V11.0545H31.9341V3.55713Z" fill="white" />
            <path d="M31.9341 0.959961H29.4077V2.64919H31.9341V0.959961Z" fill="white" />
            <path d="M37.0537 9.14185C37.396 9.14185 37.8015 9.05627 38.2741 8.8814V10.7678C37.9318 10.9167 37.608 11.0246 37.3029 11.0878C36.9941 11.1511 36.6332 11.1846 36.2202 11.1846C35.3719 11.1846 34.7617 10.9725 34.3859 10.5446C34.0101 10.1204 33.824 9.46556 33.824 8.58373V5.49549H32.9385V3.55697H33.824V1.64077L36.369 1.198V3.55697H37.9801V5.49549H36.369V8.41258C36.369 8.89628 36.596 9.14185 37.0537 9.14185Z" fill="white" />
            <path d="M42.9028 9.14204C43.2451 9.14204 43.6506 9.05646 44.1232 8.88158V10.768C43.7846 10.9168 43.4572 11.0247 43.1521 11.088C42.8432 11.1513 42.4823 11.1847 42.0693 11.1847C41.221 11.1847 40.6108 10.9727 40.235 10.5448C39.8592 10.1206 39.6731 9.46574 39.6731 8.58392V5.49568H38.7876V3.55715H39.6731V1.61491L42.2182 1.2019V3.55715H43.8292V5.49568H42.2182V8.41276C42.2182 8.89646 42.4451 9.14204 42.9028 9.14204Z" fill="white" />
            <path d="M50.8766 4.30864C50.2775 3.72076 49.4255 3.4231 48.3204 3.4231C47.1595 3.4231 46.2702 3.76169 45.6452 4.43887C45.0201 5.11605 44.7075 6.08717 44.7075 7.35223C44.7075 8.6173 45.0461 9.52516 45.7196 10.1875C46.393 10.8498 47.3418 11.1846 48.5585 11.1846C49.1427 11.1846 49.6487 11.1437 50.0692 11.0656C50.4896 10.9874 50.8952 10.846 51.2896 10.6377L50.9026 8.94844C50.6161 9.06379 50.3445 9.15681 50.0878 9.21634C49.7157 9.30192 49.3064 9.34657 48.8636 9.34657C48.3762 9.34657 47.993 9.2275 47.7102 8.98937C47.4274 8.75124 47.2786 8.42381 47.26 8.00337H51.777V6.85365C51.777 5.74486 51.4756 4.89652 50.8766 4.30864ZM47.3121 6.37739C47.3455 5.9681 47.4646 5.66672 47.6655 5.47324C47.8665 5.27976 48.1158 5.18674 48.4134 5.18674C48.7371 5.18674 48.9938 5.29464 49.1836 5.51045C49.3771 5.72625 49.4775 6.01647 49.485 6.37739H47.3158H47.3121Z" fill="white" />
            <path d="M15.7081 4.30864C15.1053 3.72076 14.2533 3.4231 13.1519 3.4231C11.9948 3.4231 11.1018 3.76169 10.4767 4.43887C9.85161 5.11605 9.53906 6.08717 9.53906 7.35223C9.53906 8.6173 9.87765 9.52516 10.5511 10.1875C11.2246 10.8498 12.1734 11.1846 13.3901 11.1846C13.9742 11.1846 14.4802 11.1437 14.9007 11.0656C15.3211 10.9874 15.7267 10.846 16.1211 10.6377L15.7341 8.94844C15.4476 9.06379 15.176 9.15681 14.9193 9.21634C14.5472 9.30192 14.1379 9.34657 13.6952 9.34657C13.2077 9.34657 12.8245 9.2275 12.5417 8.98937C12.2589 8.75124 12.1101 8.42381 12.0915 8.00337H16.6085V6.85365C16.6085 5.74486 16.3071 4.89652 15.7081 4.30864ZM12.1436 6.37739C12.1771 5.9681 12.2962 5.66672 12.4971 5.47324C12.698 5.27976 12.9473 5.18674 13.245 5.18674C13.5687 5.18674 13.8254 5.29464 14.0152 5.51045C14.2049 5.72625 14.3054 6.01647 14.3165 6.37739H12.1473H12.1436Z" fill="white" />
        </svg>

    )
}

const DeloitteLogoIcon = React.memo(DeloitteLogo);
export default DeloitteLogoIcon;