import { FC, useState, useEffect } from "react";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { BoxProps } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import AeBackLogoEmail from "./AeBackLogoEmail.png";
import MailLogo from "./MailLogo.png";
import AeBackRibbon from "./AeBackRibbon.png";
import { useAppSelector } from "hooks";
import { axiosHelper, axiosHelperCore } from "utils";
import { useLocation, useNavigate } from "react-router-dom";
import EvaLogo from "./EvaLogo";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
export interface AeEmailTemplateProps extends BoxProps {}

const AeEmailTemplate: FC<AeEmailTemplateProps> = () => {
  const { state } = useLocation();
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  const navigate = useNavigate();
  const email = useAppSelector(
    (state) => state.aeFormState.forgotPasswordData.emailAddress
  );

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const clickSubmit = async () => {
    const result = await axiosHelperCore({
      url: "rapport/user/evaGenerateForgotPasswordLink",
      method: "POST",
      JSONData: {
        email: email,
      },
      urlOverride: false,
    });
    if (result.data.success) {
      setSnackbarMessage("Another password reset link is sent to your email!");
      setShowSnackBar(true);
    }
  };

  return (
    <>
      <SnackBar
        iconName={"info"}
        message={"Great!"}
        setShowSnackBar={setShowSnackBar}
        show={showSnackBar}
        subHeading={snackbarMessage}
        timeout={4000}
        anchor={"top-right"}
        type="success"
      />
      {screenSize.dynamicWidth > 576 ? (
        <Grid
          background={"linear-gradient(159deg, #16124C 7.63%, #854ECD 114.43%)"}
          height="100vh"
          position="relative"
          justifyContent={"center"}
          alignItems={"center"}
          // zIndex={"0"}
        >
          <img
            src={AeBackLogoEmail}
            alt=""
            style={{ position: "absolute", bottom: "0" }}
          />
          <img
            src={AeBackRibbon}
            alt=""
            style={{
              position: "absolute",
              bottom: "0",
              width: "100%",
            }}
          />
          <Grid>
            <Grid
              borderRadius="12px"
              width={"600px"}
              style={{
                background: "rgba(255, 255, 255, 0.05)",
              }}
              padding="85px 70px"
              position="relative"
            >
              <Grid gridGap={"10px"}>
                <EvaLogo />
                <Text color="white" variant="heading1" mt="40px">
                  Check your email
                </Text>
                <Text color="white" variant="title" mt="14px">
                  {email}
                </Text>
                <Text color="white" variant="title" mt="24px">
                  We sent a password reset link to
                </Text>
                <Text color="white" variant="title" mt={"-5px"} mb="10px">
                  above email address
                </Text>
                {state !== "reset" && (
                  <Button
                    padding="12px 32px"
                    iconName="arrowRight"
                    iconAlign="right"
                    borderRadius={"118px"}
                    onClick={() => {
                      navigate("/aeLogin1");
                    }}
                  >
                    Got it, thanks
                  </Button>
                )}
                <Text color="white" variant="title" mt="50px">
                  Didn't get the email?
                </Text>
                <Text
                  cursor="pointer"
                  color="#FFC200"
                  variant="title"
                  mt={"-5px"}
                  mb="10px"
                  onClick={clickSubmit}
                >
                  Send me another email
                </Text>
              </Grid>
            </Grid>
            <img
              src={MailLogo}
              alt=""
              style={{ position: "absolute", bottom: "-50px", right: "-60px" }}
            />
          </Grid>
        </Grid>
      ) : (
        <Flex
          width={"100%"}
          flexDirection={"column"}
          mt={"11px"}
          padding={"16px"}
        >
          <Icon name="evaLogo" fill={"black"} />
          <Flex
            flexDirection={"column"}
            width={"100%"}
            mt={"90px"}
            gridRowGap={"24px"}
          >
            <Flex flexDirection={"column"} gridRowGap={"8px"}>
              <Text
                color={"#050505"}
                fontSize={"20px"}
                fontWeight={"300"}
                lineHeight={"140%"}
              >
                {" "}
                Check your email{" "}
              </Text>
              <Text
                color={"#57585C"}
                fontSize={"14px"}
                fontWeight={"400"}
                lineHeight={"140%"}
              >
                {" "}
                {email}{" "}
              </Text>
            </Flex>

            <Flex flexDirection={"column"} gridRowGap={"16px"}>
              <Text
                color={"#050505"}
                fontSize={"14px"}
                fontWeight={"400"}
                lineHeight={"140%"}
              >
                {" "}
                We sent a password reset link to above email address{" "}
              </Text>
              <Button
                padding="12px 32px"
                iconName="arrowRight"
                iconAlign="right"
                borderRadius={"118px"}
                onClick={() => {
                  navigate("/aeLogin1");
                }}
              >
                Got it, thanks
              </Button>
            </Flex>
            <Flex flexDirection={"column"} gridRowGap={"4px"}>
              <Text
                color={"#050505"}
                fontSize={"16px"}
                fontWeight={"400"}
                lineHeight={"160%"}
              >
                {" "}
                Didn’t get the email?{" "}
              </Text>
              <Text
                cursor="pointer"
                color="#0094E3"
                fontSize={"12px"}
                fontWeight={"400"}
                lineHeight={"140%"}
                onClick={clickSubmit}
              >
                Send me another email
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default AeEmailTemplate;
