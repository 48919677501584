import { FC } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import OpportunityTable from "./OpportunityTable"

interface AEListOpportunityAccordionSubSection { }

const AEListOpportunityAccordianSubSectionOption2: FC<AEListOpportunityAccordionSubSection> = () => {
  return <Box>
   <OpportunityTable/>
  </Box>;
}

export default AEListOpportunityAccordianSubSectionOption2