import { FC, useCallback, useEffect, useState } from 'react';
import { Flex } from '@evabot/eva-react-core-library/dist/atoms/Flex/Flex';
import { Text } from '@evabot/eva-react-core-library/dist/atoms/Text/Text';
import { Box } from '@evabot/eva-react-core-library/dist/atoms/Box/Box';
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { Button } from '@evabot/eva-react-core-library/dist/atoms/Button/Button';
import styled from 'styled-components';
import WarningIcon from 'assets/icons/WarningIcon';
import { CORE_API_URL } from 'utils';
import { debounce } from 'lodash';
import userService from 'services/userService';

interface AESignupRightSectionProps {
  setShowSnackBar: (e) => any;
  showSnackBar: boolean;
  snackbarMessage: string;
  showSsoLayout?: boolean;
}

const AESignupRightSection: FC<AESignupRightSectionProps> = ({ setShowSnackBar, showSnackBar, snackbarMessage, showSsoLayout }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorFound, setErrorFound] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loginUrl, setLoginUrl] = useState("");
  const [emailAddress, setEmailAddress] = useState('');

  const [screenSize, setScreenSize] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const updateDimension = () => {
    setScreenSize({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, []);

  const setSsoEmailCookie = (email: string) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + (30 * 24 * 60 * 60 * 1000));
    const cookie = `ssologinemail=${email};expires=${expires.toUTCString()};path=/;`;
    document.cookie = cookie;
  }

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmailAddress(emailValue);

    if (validateEmail(emailValue)) {
      setErrorFound(false);
      setErrorMessage('');
      setIsLoading(true);
      debouncedCheckEmailAccess(emailValue);
    } else {
      setLoginUrl('');
      setErrorFound(true);
      setErrorMessage('Invalid email address');
      setIsLoading(false);
    }
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const checkEmailAccess = async (email) => {
    try {
      const response = await userService.checkEmailAccess({ email });
      if (response.message === 'success' && response.status === "200") {
        setLoginUrl(`${CORE_API_URL}workos/getAuth/${email}`);
        setSsoEmailCookie(email);
        setErrorFound(false);
        setErrorMessage('');
      } else {
        setLoginUrl('');
        setErrorFound(true);
        setErrorMessage(response.message);
      }
    } catch (error) {
      setLoginUrl('');
      setErrorFound(true);
      setErrorMessage('An error occurred while checking email access');
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedCheckEmailAccess = useCallback(debounce(checkEmailAccess, 1000), []);

  useEffect(() => {
    return () => {
      debouncedCheckEmailAccess.cancel();
    };
  }, [debouncedCheckEmailAccess]);

  return (
    <>
      <SnackBar
        iconName={"info"}
        message={"Login Error"}
        setShowSnackBar={setShowSnackBar}
        show={showSnackBar}
        subHeading={snackbarMessage}
        timeout={6000}
        anchor={"top-right"}
        type='error'
      />
      <Flex height={"100%"} justifyContent={"space-between"} margin="0px 80px" flexDirection={"column"}>
        {!showSsoLayout && (
          <Flex flexDirection={"column"} gridRowGap={"0px"} justifyContent={"center"} width={"100%"} marginTop={"110px"}>
            <Flex flexDirection={"column"} gridRowGap={"8px"} textAlign={"center"} width={"70%"} margin={"0 auto"}>
              <Text color={"#050505"} fontSize={"24px"} lineHeight={"140%"} fontWeight={"600"}>
                Treat stakeholders as a person, not a persona
                <Text color={"#57585C"} fontSize={"14px"} fontWeight={"400"} lineHeight={"160%"}>
                  Unlock your creativity to stay on top of your stakeholders mind, build deeper visibility into deals, and accelerate decisions.
                  <Box width={"100%"} height={"auto"}>
                    <img src={"https://s3.amazonaws.com/eva-bot/img/a639edfd-c0cb-4341-b0be-d2d0d19963cc.gif"} style={{ width: "100%", height: "100%" }} loading='lazy' />
                  </Box>
                </Text>
              </Text>
            </Flex>
          </Flex>
        )}

        {showSsoLayout && (
          <Flex flexDirection={"column"} gridRowGap={"8px"} margin={"0 auto"} marginTop={"110px"}>
            <Box width={"100%"} mb={"35px"} display={"flex"} justifyContent={"center"}>
              <svg xmlns="http://www.w3.org/2000/svg" width="153" height="37" viewBox="0 0 153 37" fill="none">
                <path d="M39.5807 8.02402C39.3804 3.38861 36.4842 -0.490458 31.1937 0.0505344C25.9031 0.591527 20.1189 16.8801 20.0123 17.1872L19.9839 17.5331L19.9518 17.1872C19.8452 16.8801 14.0145 0.595422 8.77284 0.062214C3.47906 -0.478779 0.581341 3.41975 0.382604 8.02446C-1.27781 20.8098 7.61047 32.8902 18.3579 36.2737C19.4268 36.5288 20.5345 36.5288 21.6034 36.2737C32.3921 32.8643 41.2235 20.7925 39.5807 8.02402ZM6.5699 14.5012C5.2721 6.74267 7.76794 6.42006 8.46832 7.27934C14.0606 14.163 15.9558 27.7579 15.9558 27.7579C11.5616 25.7077 7.76834 19.9981 6.5703 14.4995L6.5699 14.5012ZM33.3942 14.5012C32.1962 19.9998 28.417 25.7094 24.0083 27.7584C24.0083 27.7584 25.9031 14.1795 31.4958 7.27977C32.1962 6.42007 34.692 6.74268 33.4014 14.5017L33.3942 14.5012Z" fill="#E8015F"/>
                <path d="M60.9244 28.0457C56.3479 28.0457 53.0222 24.6095 53.0222 19.2709C53.0222 13.9016 56.2625 10.4961 60.9244 10.4961C65.4725 10.4961 68.6848 13.8406 68.6848 18.9024C68.6894 19.4573 68.6513 20.0116 68.571 20.5596H57.0587C57.259 23.0751 58.8493 24.4866 60.8395 24.4866C61.4965 24.5298 62.1502 24.3563 62.7137 23.9892C63.2772 23.6221 63.7241 23.0788 63.9948 22.4312H68.2869C67.4343 25.5604 64.8186 28.0457 60.9244 28.0457ZM57.0855 17.6756H64.5634C64.5065 15.4359 62.8577 14.0249 60.8106 14.0249C58.9062 14.0249 57.3996 15.3438 57.0855 17.6756Z" fill="black"/>
                <path d="M74.1691 10.7734L78.149 23.8126L82.129 10.7734H86.3641L80.5367 27.7686H75.7037L69.905 10.7734H74.1691Z" fill="black"/>
                <path d="M94.7777 10.4999C95.7823 10.4635 96.7801 10.69 97.686 11.1602C98.5919 11.6303 99.3788 12.33 99.9798 13.1996V10.7779H103.987V27.7731H99.9798V25.2866C99.3834 26.1732 98.5948 26.8885 97.683 27.3699C96.7711 27.8514 95.7639 28.0843 94.7493 28.0482C90.7693 28.0482 87.5859 24.5194 87.5859 19.212C87.5859 13.9045 90.7697 10.4999 94.7777 10.4999ZM95.8011 14.2738C93.6691 14.2738 91.6508 15.992 91.6508 19.2133C91.6508 22.4346 93.6691 24.2755 95.8011 24.2755C97.99 24.2755 99.9798 22.496 99.9798 19.2747C99.9798 16.0534 97.99 14.2738 95.8011 14.2738Z" fill="black"/>
                <path d="M116.322 10.773C120.586 10.773 123.713 14.1175 123.713 19.3636C123.713 24.6096 120.557 28.0156 116.322 28.0156C113.224 28.0156 111.092 26.2667 110.152 24.0881V27.7699H108.188V5.06641H110.152V14.7295C111.12 12.524 113.28 10.773 116.322 10.773ZM115.924 12.6446C112.769 12.6446 110.154 15.2216 110.154 19.3943C110.154 23.567 112.769 26.1439 115.924 26.1439C119.193 26.1439 121.694 23.5972 121.694 19.3631C121.695 15.0992 119.193 12.6446 115.924 12.6446Z" fill="black"/>
                <path d="M134.172 28.016C129.795 28.016 126.469 24.7022 126.469 19.3947C126.469 14.0566 129.88 10.7734 134.229 10.7734C138.606 10.7734 141.989 14.0566 141.989 19.3947C141.989 24.7017 138.552 28.016 134.172 28.016ZM134.172 26.1448C137.1 26.1448 139.942 23.9968 139.942 19.3947C139.942 14.7926 137.156 12.6451 134.2 12.6451C131.243 12.6451 128.486 14.7926 128.486 19.3947C128.486 23.9968 131.216 26.1444 134.172 26.1444V26.1448Z" fill="black"/>
                <path d="M146.195 12.8296H144.035V11.0198H146.195V6.81641H148.213V11.0198H152.477V12.8296H148.214V23.23C148.214 25.2854 148.868 25.9302 150.658 25.9302H152.477V27.7707H150.345C147.644 27.7707 146.194 26.5741 146.194 23.23L146.195 12.8296Z" fill="black"/>
              </svg>
            </Box>
            <Box background={"#fff"} borderRadius={"6px"} boxShadow={"0px 0px 16.517px 0px rgba(0, 0, 0, 0.25)"} padding={"30px 72px 30px 36px"} width={"450px"} ml={"50px"}>
              <Text color={"#050505"} fontSize={"26px"} fontWeight={"600"} lineHeight={"100%"} mb={4} textAlign={"center"}>Log In with SSO</Text>
              <Text color={"#050505"} variant="bodyRegular" mb={1}>Email</Text>
              <Input 
                width={screenSize.dynamicWidth > 576 ? "100%" : ""}
                placeholder="Enter your email"
                onChange={handleEmailChange}
                value={emailAddress}
              />
              {errorFound && (
                <ContainerInner1>
                  <WarningIcon width={55} />
                  {errorMessage}
                </ContainerInner1>
              )}
              <a href={loginUrl} style={{ display: 'inline-block', width: '100%' }}>
                <Button
                  fullSize
                  loading={isLoading}
                  variant="primary"
                  borderRadius={screenSize.dynamicWidth > 576 ? "118px" : "8px"}
                  fontSize={screenSize.dynamicWidth > 576 ? "14px" : "16px"}
                  type="submit"
                  disabled={!validateEmail(emailAddress) || !loginUrl}
                  iconName="arrowRight"
                  iconAlign="right"
                >
                  Log In
                </Button>
              </a>
            </Box>
          </Flex>
        )}
        <Flex marginBottom={"16px"} justifyContent={"center"} gridColumnGap={"16px"} alignItems={"center"} flexDirection={"row"}>
          <Text color={"#57585C"} fontSize={"12px"} fontWeight={"400"} lineHeight={"140%"}>Copyright © 2024 Evabot, Inc. All rights reserved.</Text>
          <Text color={"#57585C"} fontSize={"12px"} fontWeight={"400"} lineHeight={"140%"}>|</Text>
          <a href="https://www.evabot.com/terms-of-service" style={{ textDecoration: "none", color: "#57585C", fontSize: "12px", fontWeight: "400", lineHeight: "140%" }}>Terms of services</a>
          <Text color={"#57585C"} fontSize={"12px"} fontWeight={"400"} lineHeight={"140%"}>|</Text>
          <a href="https://www.evabot.com/privacy-policy" style={{ textDecoration: "none", color: "#57585C", fontSize: "12px", fontWeight: "400", lineHeight: "140%"}}>Privacy policy</a>
        </Flex>
      </Flex>
    </>
  );
};

export default AESignupRightSection;

const ContainerInner1 = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  padding: '5px 12px',
  borderRadius: '3px',
  border: '1px solid #F7A300',
  background: '#FFF5D5',
  marginBottom: "10px"
});

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
`;
