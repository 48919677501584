import { FC, useState } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { VideoDemo } from "@evabot/eva-react-core-library/dist/molecules/VideoDemo/VideoDemo";
import { Header } from "@evabot/eva-react-core-library/dist/molecules/Header/Header";
import { AECard } from "@evabot/eva-react-core-library/dist/molecules/AECard/AECard";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import Ribbon from "./../../assets/images/EvaBgImg.png";
import { useNavigate } from "react-router-dom";
interface OverviewProps {
    data: any;
    optionType : any;
}

export const AEOverviewLayout: FC<OverviewProps> = ({ data , optionType }) => {
    const navigate = useNavigate();
    const [toggle, setToggle] = useState<boolean>(false);
    const handleToggle = () => {
        setToggle(!toggle)
    }
    return (
        <Box>
            <Header>
                <Flex alignItems="center" gap="10px">
                    <VideoDemo locale="seedemo" setToggle={handleToggle} />
                </Flex>
            </Header>
            <Box
                height="100vh"
                overflow="auto"
                style={{
                    backgroundImage: `url(${Ribbon}),linear-gradient(360deg, rgba(208, 218, 251, 0.7) 42.89%, rgba(208, 218, 251, 0.7) 102.28%)`,
                    backgroundPosition: '',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>
                <Flex justifyContent="center" >
                    <Text
                        id={data().description}
                        fontSize="32px"
                        fontWeight="500"
                        width="650px"
                        textAlign="center"
                        padding="5px 40px"
                        m="30px 0px"
                    />
                </Flex>
                <Flex justifyContent="center" gap="10px" flexWrap="wrap">
                    {data().cards.map((ele, i) => {
                        return (
                            <AECard key={i}
                                description={ele.label}
                                image={ele.image}
                                width="350px"
                                imageHeight="300px"
                            >
                                <Text fontSize="20px"
                                    id={ele.title}
                                    fontWeight="500"
                                    color="#57585C" />
                            </AECard>
                        )
                    })}
                </Flex>
                <Flex justifyContent="center" m="20px 0px 50px 0px">
                    <Button
                        onClick={ () => {
                         if ( optionType == 2 ) {
                          navigate("/aeListOpportunity2");
                         } else if ( optionType == 1 ) {
                            navigate("/aeListOpportunity1");
                         }
                        } }
                        title="continue"
                        size="m"
                        padding="12px"
                        variant="primary"
                        iconName="arrowRight"
                        iconAlign="right"
                        paddingBottom="12px"
                        paddingLeft="32px"
                        paddingRight="32px"
                        paddingTop="12px"
                    />
                </Flex>
            </Box>
        </Box>
    )
}

