import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import AeReturnOppoTable from "./AeReturnOppoTable/AeReturnOppoTable";
import { userData } from "./AeReturnOppoTable/data";

interface AeReturnOppoRightSectionProps {}

const AeReturnOppoRightSection: React.FC<
  AeReturnOppoRightSectionProps
> = () => {
  return (
    <Flex flexDirection={"column"} margin="25px 32px">
      <Text fontSize={"24px"} fontWeight={400} color="#050505">
        Opportunities{" "}
        <Text as="span" color="#9F9F9F">
          {`(${userData?.length})`}
        </Text>
      </Text>
      <AeReturnOppoTable />
    </Flex>
  );
};

export default AeReturnOppoRightSection;
