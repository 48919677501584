import * as React from "react";

function ProspectReportIcon(props) {
    return (
        
<svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.57361 7.97925C10.5257 7.97925 12.1082 6.39678 12.1082 4.4447C12.1082 2.49263 10.5257 0.910156 8.57361 0.910156C6.62153 0.910156 5.03906 2.49263 5.03906 4.4447C5.03906 6.39678 6.62153 7.97925 8.57361 7.97925Z" stroke="white" stroke-width="1.00987" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1 18.4827V18.4827C1 18.8173 1.27128 19.0886 1.60592 19.0886H15.5421C15.8768 19.0886 16.1481 18.8173 16.1481 18.4827V18.4827C16.1481 16.2204 16.1481 15.0892 15.7078 14.2251C15.3205 13.4651 14.7025 12.8471 13.9424 12.4598C13.0784 12.0195 11.9472 12.0195 9.68488 12.0195H7.46317C5.20086 12.0195 4.0697 12.0195 3.2056 12.4598C2.44552 12.8471 1.82755 13.4651 1.44028 14.2251C1 15.0892 1 16.2204 1 18.4827Z" stroke="white" stroke-width="1.00987" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


    );
}

export default ProspectReportIcon;
