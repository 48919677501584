import React, { useState } from 'react';
import { ErrorMessage, Formik, Form } from "formik";
import * as yup from 'yup';
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { FormikInput } from '@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput';
import { useDispatch } from "react-redux";
import { setEditFormVisibility } from '../store/emailTemplateReducer';
import { useAppSelector } from "hooks";
import emailTemplateService from 'services/emailTemplateService';
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';
import { FormikTextArea } from '@evabot/eva-react-core-library/dist/atoms/FormikTextArea/FormikTextArea';
import { Box } from '@evabot/eva-react-core-library/dist/atoms/Box/Box';


const EditTemplateForm = ({ templateData }) => {

    console.log("templateDatatemplateData",templateData.templateId);

    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');

    const dispatch = useDispatch();
    const formData = useAppSelector(
        (state) => state.emailTemplateState.editFormData
    );


    console.log("formDataformData",formData);

    const [htmlCode, setHtmlCode] = useState(formData?.html);
    const handleHTMLInputChange = (e) => {
        setHtmlCode(e.target.value);
    };

    const validationSchema = yup.object({
        templateName: yup.string()
            .required('Template Name is required')
            .test(
                'uniqueTemplateName',
                'Template Name should be unique',
                value => !templateData.some(template => template.templateName === value && template.templateId!=formData.templateId)
            ),
        to: yup.string().required('To is required'),
        subject: yup.string().required('Subject is required'),
        html: yup.string().required('HTML content is required'),
    });

    const handleUpdateTemplate = (values) => {
        emailTemplateService.editEmailTemplate(values).then((response) => {
            if (response?.success === true) {
                setShowSnackBar(true);
                setSnackbarMessage('Template has been updated');
                setSnackbarType("success");
                dispatch(setEditFormVisibility(false));
            }
            else {
                setShowSnackBar(true);
                setSnackbarMessage('Error updating template');
                setSnackbarType("error");
            }
        })
    }

    const sendTestEmail = (values) => {
        emailTemplateService.sendEmail({ templateId: values.templateId }).then(
            () => {
                setShowSnackBar(true);
                setSnackbarMessage('Email sent!');
                setSnackbarType("success");
            })
    }

    return (
        <>
            <Box padding={"40px"} backgroundColor="white" border="1px solid #ddd" boxShadow="0px 0px 8px rgba(0,0,0,0.1)" borderRadius="4px">
                <SnackBar
                    iconName={"info"}
                    message={snackbarMessage}
                    setShowSnackBar={setShowSnackBar}
                    show={showSnackBar}
                    timeout={4000}
                    anchor={"top-right"}
                    type={snackbarType}
                />
                <Text fontSize="24px" fontWeight="700" marginBottom={4}> Edit Template </Text>
                <Formik
                    enableReinitialize
                    initialValues={{
                        templateId: formData?.templateId,
                        templateName: formData?.templateName || "",
                        to: formData?.to || "",
                        cc: formData?.cc || "",
                        subject: formData?.subject || "",
                        html: formData?.html || "",
                    }}
                    onSubmit={(values, actions) => {
                        const isUnique = !templateData.some(template => template.templateName === values.templateName && template.templateId!=formData.templateId);
                        if (!isUnique) {
                            actions.setFieldError("templateName", "Template Name should be unique");
                            return;
                        }
                        handleUpdateTemplate(values);
                    }}
                    validationSchema={validationSchema}
                >
                    {({ setFieldValue, values }) => (
                        <Form>
                            <Flex flexDirection="column">
                                <Flex flexDirection="column" paddingBottom={"10px"}>
                                    <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"6px"}>Template Name</Text>
                                    <FormikInput
                                        name="templateName"
                                        placeholder=""
                                        width={"70%"}
                                    />
                                </Flex>
                                <Flex flexDirection="column" paddingBottom={"10px"}>
                                    <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"6px"}>To</Text>
                                    <FormikInput
                                        name="to"
                                        placeholder=""
                                        width={"70%"}
                                    />
                                </Flex>
                                <Flex flexDirection="column" paddingBottom={"10px"}>
                                    <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"6px"}>CC</Text>
                                    <FormikInput
                                        name="cc"
                                        placeholder=""
                                        width={"70%"}
                                    />
                                </Flex>
                                <Flex flexDirection="column" paddingBottom={"10px"}>
                                    <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"6px"}>Subject</Text>
                                    <FormikInput
                                        name="subject"
                                        placeholder=""
                                        width={"70%"}
                                    />
                                </Flex>
                                <Flex flexDirection="column" paddingBottom={"10px"}>
                                    <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"6px"}>HTML</Text>
                                    <FormikTextArea
                                        name="html"
                                        placeholder=""
                                        value={htmlCode}
                                        onChange={handleHTMLInputChange}
                                        width={"75%"}
                                    />
                                </Flex>
                                <Flex flexDirection="column" paddingBottom={"10px"}>
                                    <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"6px"}>Preview</Text>
                                    <div
                                        style={{
                                            width: "75%",
                                            border: "1px solid #ddd",
                                            padding: "20px",
                                            minHeight: "150px",
                                            backgroundColor: "#f8f8f8",
                                            position: "relative",
                                        }}
                                    >
                                        {htmlCode ? (
                                            <div dangerouslySetInnerHTML={{ __html: htmlCode }} />
                                        ) : (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)",
                                                    color: "#555",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Your HTML Preview
                                            </div>
                                        )}
                                    </div>
                                </Flex>
                                <Flex paddingTop={"5px"} width={"80%"}>
                                    <Flex justifyContent={"space-between"} width={"99%"}>
                                        <Flex>
                                            <Flex>
                                                <Button iconName='edit' type="submit" padding={"10px"}>Save</Button>
                                            </Flex>
                                            <Flex>
                                                <Button variant="secondary" type="button" onClick={() => dispatch(setEditFormVisibility(false))} marginLeft={2}>
                                                    Cancel
                                                </Button>
                                            </Flex>
                                        </Flex>
                                        {/* <Flex>
                                            <Button onClick={()=>sendTestEmail(values)} variant="secondary" type="button" marginLeft={2}>
                                                Test Email
                                            </Button>
                                        </Flex> */}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default EditTemplateForm;
