import { axiosHelper } from "utils";

export const insertActivityIntoActivityHistory = async (
  nudge: any,
  order: any,
  member: any,
  interactionType: string
) => {
  try {
    await axiosHelper({
      url: "activity/insertActivityIntoHistory",
      method: "POST",
      JSONData: {
        memberName: `${member.firstName} ${member.lastName}`,
        context: nudge ? nudge.context: '',
        orderId: order ? order.id : '',
        orderPrice: order ? order.price : '',
        orderStatus: order ? order.status : '',
        orderType: order ? order.type : '',
        orderSchedule: order ? order.date : '',
        nudgeId: nudge ? nudge.nudgeId: '',
        nudgeType: nudge ? nudge.nudgeType: '',
        nudgeCategoryType: nudge ? nudge.nudgeHeadlinePayload?.type: '',
        interactionType: interactionType,
        stakeholderId: nudge ? nudge.stakeholderId: '',
        stakeholderName: nudge ? nudge.stakeholderName: '',
        opportunityId: nudge ? nudge.opportunityId: '',
        crawlerData: nudge ? nudge.crawlerData: {},
        nudgeHeadlinePayload: nudge ? nudge.nudgeHeadlinePayload: {},
      },
    });
  } catch (err) {}
};
