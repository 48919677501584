import React ,  { useState, useEffect } from "react";

import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { format } from "date-fns";
import {
  capitalizeString,
} from "utils/preferenceMapper";

// CarouselComponent.tsx

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./Carousel.css";
import LeftIcon from "assets/icons/LeftIcon";
import RightIcon from "assets/icons/RightIcon";

interface CarouselItemProps {
  entry: any;
  active: boolean;
  id : any;
  getWeatherIcon: any;
  currentSlide: any;
  
}

const CarouselItem: React.FC<CarouselItemProps> = ({ entry, active , id , getWeatherIcon , currentSlide  }) => {
  return (
    <div 
    className={`carousel-item active`}
    
    >
     
         <Flex
                            flexDirection={"column"}
                            gridRowGap={"6px"}
                            alignItems={"center"}
                          >
                            {getWeatherIcon(entry.main)}
                            <Flex flexDirection={"row"} alignItems={"center"}>
                              <Text
                                color={"#050505"}
                                fontWeight={"400"}
                                fontSize={"12px"}
                                lineHeight={"15px"}
                              >
                                {" "}
                                {parseInt(entry.temp_max)}°{" "}
                              </Text>
                              <Text
                                color={"#6B7280"}
                                lineHeight={"15px"}
                                fontSize={"12px"}
                                fontWeight={"400"}
                              >
                                {" "}
                                / {parseInt(entry.temp_min)}°{" "}
                              </Text>
                            </Flex>
                            <Text
                              fontSize={"11px"}
                              fontWeight={"400"}
                              color={"#9F9F9F"}
                              lineHeight={"140%"}
                            >
                              {" "}
                              {capitalizeString(
                                entry?.description
                              )}{" "}
                            </Text>
                            { format(entry.dt * 1000 , "do MMMM") == format( new Date(),"do MMMM" )   ? (
                              <Box
                                borderRadius={"4px"}
                                bg={"#0094E3"}
                                width={"42px"}
                                height={"21px"}
                              >
                                <Flex
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  width={"100%"}
                                  height={"100%"}
                                >
                                  <Text
                                    color={"#fff"}
                                    fontSize={"12px"}
                                    fontWeight={"400"}
                                    lineHeight={"140%"}
                                  >
                                    Now
                                  </Text>
                                </Flex>
                              </Box>
                            ) : (
                              <Text
                                fontSize={"12px"}
                                fontWeight={"400"}
                                color={"#111827"}
                                lineHeight={"140%"}
                              >
                                {" "}
                                {/* {format(entry.dt * 1000, "iii")}{" "} */}
                                { Math.floor(Date.now() / 1000) > entry.dt ? format(entry.dt * 1000 , "do MMMM")  :  Math.floor(  (Date.now() + 7 * 24 * 60 * 60 * 1000 ) / 1000) > entry.dt ?  format(entry.dt * 1000, "iii") : format(entry.dt * 1000 , "do MMMM") }
                              </Text>
                            )}
                          </Flex>
   
    </div>
  );
};

interface CarouselComponentProps {
  items: any[];
  getWeatherIcon: any;
  startIndex : number;
  perPage: any;
}

const CarouselComponent: React.FC<CarouselComponentProps> = ({ items , getWeatherIcon , startIndex , perPage }) => {
  
  const [currentSlide, setCurrentSlide] = useState(startIndex);
  const itemsPerPage = perPage;

  const handlePrevClick = () => {
    setCurrentSlide(Math.max(currentSlide - itemsPerPage, 0));
  };

  const handleNextClick = () => {
    setCurrentSlide(Math.min(currentSlide + itemsPerPage, items.length - itemsPerPage));
  };

  
  const renderItems = () => {
    const renderItemArray  = [];
    // @ts-ignore
    for (let i = currentSlide; i < currentSlide + itemsPerPage && i < items.length; i++) {
      renderItemArray.push(<CarouselItem id={i} entry={items[i]} active={i === currentSlide} getWeatherIcon={getWeatherIcon} currentSlide={currentSlide} />);
    }
    return renderItemArray;
  };
   
  

  return (
    <Box position={"relative"} paddingLeft={"50px"} paddingRight={"50px"} >
      <button style={{ position : "absolute" , left: 0 , top:  40 , background : "transparent" , border : "none" , cursor : "pointer" }} onClick={handlePrevClick} disabled={currentSlide === 0}>
       
        <LeftIcon fill={ !(currentSlide === 0) ?  "#050505" : "#DFDFDF"} />
      </button>
      { // @ts-ignore 
        <Carousel   selectedItem={currentSlide} showThumbs={false} showStatus={false} showIndicators={false} showArrows={false}  onChange={setCurrentSlide} swipeable={false}>
   
   <Flex justifyContent={"space-around"} >
   {renderItems()}
     </Flex>  
   
 
 </Carousel>
       }
      
      <button style={{ position : "absolute" , right: 0 , top:  40 , background : "transparent" , border : "none" , cursor : "pointer" }} onClick={handleNextClick} disabled={currentSlide === items.length - itemsPerPage}>
      <RightIcon fill={ !( currentSlide === items.length - itemsPerPage )  ?  "#050505" : "#DFDFDF" }  />
      </button>
    </Box>
  );
};

export default CarouselComponent;



