import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";

function CompanyScoreReportTableHeader() {

  return (
    <>
      <Flex
        padding={"10px 15px"}
        fontSize="12px"
        fontWeight={500}
        position="sticky"
        backgroundColor={"#F8F8F8"}
        alignItems={"center"}
        borderTopLeftRadius={"8px"}
        borderTopRightRadius={"8px"}
        className="table-data-account"
      >
        <Flex width="2%" margin="0 0 0 16px">
          #
        </Flex>
        <Flex width="2%">
        </Flex>
        <Flex width="20%">
          Account Name
        </Flex>
        <Flex width="2%">
        </Flex>
        <Flex width="20%">
          Owner
        </Flex>
        <Flex width="35%">
          Account Analysis
        </Flex>
        <Flex width="10%" justifyContent={"flex-end"}>
        </Flex>
        <Flex width="10%" margin="0 16px 0 0">
          Account Score
        </Flex>
      
      </Flex>
    </>


  );
}

export default CompanyScoreReportTableHeader;
