import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Image } from "@evabot/eva-react-core-library/dist/atoms/Image/Image";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import outreachImage from "./../../assets/images/outreach.png";
import salesloftImage from "./../../assets/images/salesloft.png";
import salesforceImage from "./../../assets/images/salesforce.jpg";
import hubspotImage from "./../../assets/images/HubSpot-Logo.png";
import userService from "services/userService";
import { CORE_API_URL } from "utils";
import { useAppSelector } from "hooks";
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { useState } from "react";
import SalesforceForm from "../../components/Integration/SalesforceForm";
import HubspotForm from "components/Integration/HubspotForm";


const outReachUrl = CORE_API_URL + "outreach/getAuth";
const hubspotUrl = CORE_API_URL + "hubspot/getAuth";
const salesloftUrl = CORE_API_URL + "salesloft/getSalesloftAuth";
const salesforceUrl = CORE_API_URL + "salesforce/getSalesforceAuth";

const IntegrationContent = ({ userData, refreshTokenDeleted }) => {

    const profileData = useAppSelector((state) => state.profileState.profileData);
    const [hideModal, setHideModal] = useState(true);
    const [showSalesforceForm, setShowSalesforceForm] = useState(false);
    const [showHubspotForm, setShowHubspotForm] = useState(false);
    const [salesforceText, setSalesforceText] = useState("Connected");
    const [hubspotText, setHubspotText] = useState("");

    const disconnectIntegration = () => {
        userService.deleteRefreshToken(userData.userId, userData.refreshTokenType).then(
            (data) => {
                refreshTokenDeleted();
                setHideModal(true);
            }
        )
    }

    const dateFormat = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate;
    }

    const refreshTokenReconnect = () => {

    }


    return (
        <>

            <ModalWrapper
                width="max-content"
                height="max-content"
                hideModal={hideModal}
                setHideModal={() => {
                    setHideModal(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="48px"
            >
                <Flex
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Text display={"flex"} mt="24px" fontSize={"24px"} fontWeight={300}>
                        {" "}
                        Are you sure you want to disconnect&nbsp;<Text textTransform={"capitalize"}> {userData.refreshTokenType}</Text>?
                    </Text>

                    <Text color={"rgb(87, 88, 92)"} textAlign={"center"} lineHeight={"1.5"} mt="24px" mb={"24px"} fontSize={"16px"} fontWeight={300}>
                        {" "}
                        All the prospect tags saved previously will be fully deleted from our system.<br></br> You can always connect the app and save the tags manually again.
                    </Text>

                    <Flex mt="15px">
                        <Flex

                            border="1px solid #0094E3"
                            background={"#fff"}
                            color={"#0094E3"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => disconnectIntegration()}

                        >
                            Yes
                        </Flex>
                        <Flex
                            ml="16px"
                            background={"#DD015B"}
                            color={"#fff"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => setHideModal(true)}

                        >
                            Cancel
                        </Flex>

                    </Flex>
                </Flex>
            </ModalWrapper>

            <Flex flexDirection={"column"} justifyContent={"center"} alignItems={"center"} margin={"8px"}>
                <Text fontSize={"24px"} fontWeight={"400"} color={"#050505"} marginBottom={"4px"}>Connect your preferred App</Text>
                <Text fontSize={"16px"} fontWeight={"400"} color={"#57585C"} marginBottom={"32px"}>
                    {
                        profileData.role === "sdr" ? "Kindly request the admin to connect your preferred app before initiating the automation for creating prospect profiles." : "Please connect your preferred app before initiating the automation for creating prospect profiles"
                    }

                </Text>

                {
                    profileData.role !== "sdr" &&
                    (
                        <Flex justifyContent={"center"} alignItems={"center"} gap="30px" className="integration-panel">
                            <Flex borderRadius={"12px"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} backgroundColor={"#fff"} width={"250px"} height={"600px"} border={"1px solid var(--borders-border-3, rgba(0, 0, 0, 0.10));"}>
                                {userData.refreshTokenType === "salesforce" && (<div className='absolute top-0 right-0 p-2 flex'>
                                    <div className='text-[12px] text-gray-400 self-center mr-1'>{salesforceText}</div>
                                    <div className='tick-icon bg-green-500 text-white flex items-center justify-center rounded-full w-5 h-5'>
                                        <svg className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                    </div>
                                </div>)}
                                <Box marginBottom={"40px"} marginTop={"77px"}>
                                    <Image width={"200px"} src={salesforceImage}></Image>
                                </Box>
                                <Box width={"100%"} textAlign={"center"} marginBottom={"92px"} display={"flex"} justifyContent={"center"} alignContent={"center"}>
                                    {userData.refreshTokenType === "salesforce" && (
                                        <div style={{ display: "flex", justifyContent: "center" }}>{showSalesforceForm ?
                                            <SalesforceForm setShowForm={setShowSalesforceForm} setSalesforceText={setSalesforceText} /> :
                                            (<Flex flexDirection={"column"} gap="10px" justifyContent={"space-around"} alignItems={"center"}>
                                                <Flex>
                                                    <Button onClick={() => setShowSalesforceForm(true)} variant="secondary" borderRadius={"12px"} minWidth={"230px"} fontSize="14px" backgroundColor={"#fff !important"} color={"#0094E3 !important"}>
                                                        Edit Connection Details
                                                    </Button>
                                                </Flex>
                                                <Flex>
                                                    <a className="primary-anchor" onClick={() => refreshTokenReconnect()} style={{ border: "1px solid #0094E3", background: "#fff", color: "#0094E3", minWidth: "230px" }} href={salesforceUrl}>
                                                        Refresh Connection
                                                    </a>
                                                </Flex>
                                                <Flex>
                                                    <Button onClick={() => setHideModal(false)} borderColor={"#E40606"} backgroundColor={"#FFF2F2 !important"} color={"#E40606"} variant="primary" borderRadius={"12px"} minWidth={"230px"} fontSize="14px">
                                                        Disconnect
                                                    </Button>
                                                </Flex>

                                            </Flex>)
                                        }
                                        </div>
                                    )}
                                    {!userData.refreshTokenType && (
                                        <a className="primary-anchor" href={salesforceUrl}>
                                            Connect
                                        </a>
                                    )}
                                    {(userData?.refreshTokenType === "outreach" || userData?.refreshTokenType === "salesloft" || userData?.refreshTokenType === "hubspot") && (
                                        <Button disabled variant="primary" borderRadius={"12px"} fontSize="14px">
                                            Connect
                                        </Button>
                                    )}
                                </Box>

                            </Flex>
                            <Flex borderRadius={"12px"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} backgroundColor={"#fff"} width={"250px"} height={"600px"} border={"1px solid var(--borders-border-3, rgba(0, 0, 0, 0.10));"}>
                                <Box marginBottom={"40px"} marginTop={"77px"}>
                                    <Image width={"200px"} src={outreachImage}></Image>
                                </Box>
                                <Box textAlign={"center"} marginBottom={"92px"}>
                                    {userData.refreshTokenType === "outreach" && (
                                        <>

                                            <Flex flexDirection={"column"} gap="10px" justifyContent={"space-around"} alignItems={"center"}>
                                                <Flex>
                                                    <Button onClick={() => setHideModal(false)} variant="secondary" borderRadius={"12px"} fontSize="14px">
                                                        Disconnect
                                                    </Button>
                                                </Flex>
                                                <Flex>
                                                    <a className="primary-anchor" onClick={() => refreshTokenReconnect()} style={{ border: "1px solid #0094E3", background: "#fff", color: "#0094E3" }} href={outReachUrl}>
                                                        Refresh Connection
                                                    </a>
                                                </Flex>

                                                {profileData?.refreshToken == "" && profileData?.expiredOn != "" && (<Text fontSize={"14px"} color={"red"} marginTop={"8px"}>Connection Expired On: {dateFormat(profileData.expiredOn)}</Text>)}
                                            </Flex>
                                        </>
                                    )}
                                    {!userData.refreshTokenType && (
                                        <a className="primary-anchor" href={outReachUrl}>
                                            Connect
                                        </a>
                                    )}
                                    {(userData?.refreshTokenType === "salesloft" || userData?.refreshTokenType === "salesforce" || userData?.refreshTokenType === "hubspot") && (
                                        <Button disabled variant="primary" borderRadius={"12px"} fontSize="14px">
                                            Connect
                                        </Button>
                                    )}
                                </Box>

                            </Flex>
                            <Flex borderRadius={"12px"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} backgroundColor={"#fff"} width={"250px"} height={"600px"} border={"1px solid var(--borders-border-3, rgba(0, 0, 0, 0.10));"}>
                                <Box marginBottom={"40px"} marginTop={"77px"}>
                                    <Image width={"200px"} src={salesloftImage}></Image>
                                </Box>
                                <Box textAlign={"center"} marginBottom={"92px"}>
                                    {userData.refreshTokenType === "salesloft" && (
                                        <Flex flexDirection={"column"} gap="10px" justifyContent={"space-around"} alignItems={"center"}>
                                            <Flex>
                                                <Button onClick={() => setHideModal(false)} variant="secondary" borderRadius={"12px"} fontSize="14px">
                                                    Disconnect
                                                </Button>
                                            </Flex>
                                            <Flex>
                                                <a className="primary-anchor" onClick={() => refreshTokenReconnect()} style={{ border: "1px solid #0094E3", background: "#fff", color: "#0094E3" }} href={salesloftUrl}>
                                                    Refresh Connection
                                                </a>
                                            </Flex>
                                        </Flex>
                                    )}
                                    {!userData.refreshTokenType && (
                                        <a className="primary-anchor" href={salesloftUrl}>
                                            Connect
                                        </a>
                                    )}
                                    {(userData?.refreshTokenType === "salesforce" || userData?.refreshTokenType === "outreach" || userData?.refreshTokenType === "hubspot") && (
                                        <Button disabled variant="primary" borderRadius={"12px"} fontSize="14px">
                                            Connect
                                        </Button>
                                    )}
                                </Box>

                            </Flex>
                            <Flex borderRadius={"12px"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} backgroundColor={"#fff"} width={"250px"} height={"600px"} border={"1px solid var(--borders-border-3, rgba(0, 0, 0, 0.10));"}>
                                {userData.refreshTokenType === "hubspot" && (<div className='absolute top-0 right-0 p-2 flex'>
                                    {hubspotText == "connected" ? (<>
                                        <div className='text-[12px] text-gray-400 self-center mr-1'>{hubspotText}</div>
                                        <div className='tick-icon bg-green-500 text-white flex items-center justify-center rounded-full w-5 h-5'>
                                            <svg className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                            </svg>
                                        </div></>) : <div className='text-[12px] text-[red] italic self-center mr-1'>{hubspotText}</div>}
                                </div>)}
                                <Box marginBottom={ showHubspotForm ?"0" :"0"} marginTop={"77px"}>
                                    <Image width={ showHubspotForm? "50px":"150px"} src={hubspotImage}></Image>
                                </Box>
                                <Box textAlign={"center"} marginBottom={"92px"}>
                                    {showHubspotForm && <div className="text-xs text-gray-500 mb-4 text-left px-2">*List Name, First Name, Last Name and Email fields are mandatory</div>}
                                    {userData.refreshTokenType === "hubspot" && (
                                        <div style={{ display: "flex", justifyContent: "center", justifyItems:"center" }}>{(showHubspotForm || hubspotText == "") ?
                                            <HubspotForm setShowHubspotForm={setShowHubspotForm} setHubspotText={setHubspotText} /> :
                                            (<Flex flexDirection={"column"} gap="10px" justifyContent={"space-around"} alignItems={"center"}>
                                                <Flex>
                                                    <Button onClick={() => setShowHubspotForm(true)} variant="secondary" borderRadius={"12px"} minWidth={"230px"} fontSize="14px" backgroundColor={"#fff !important"} color={"#0094E3 !important"}>
                                                        Edit Connection Details
                                                    </Button>
                                                </Flex>

                                                <Flex>
                                                    <a className="primary-anchor" onClick={() => refreshTokenReconnect()} style={{ border: "1px solid #0094E3", background: "#fff", color: "#0094E3" }} href={hubspotUrl}>
                                                        Refresh Connection
                                                    </a>
                                                </Flex>
                                                <Flex>
                                                    <Button onClick={() => setHideModal(false)} variant="secondary" borderRadius={"12px"} fontSize="14px">
                                                        Disconnect
                                                    </Button>
                                                </Flex>

                                                {profileData?.refreshToken == "" && profileData?.expiredOn != "" && (<Text fontSize={"14px"} color={"red"} marginTop={"8px"}>Connection Expired On: {dateFormat(profileData.expiredOn)}</Text>)}
                                            </Flex>)}
                                        </div>
                                    )}
                                    {!userData.refreshTokenType && (
                                        <a className="primary-anchor" href={hubspotUrl}>
                                            Connect
                                        </a>
                                    )}
                                    {(userData?.refreshTokenType === "salesloft" || userData?.refreshTokenType === "salesforce" || userData?.refreshTokenType === "outreach") && (
                                        <Button disabled variant="primary" borderRadius={"12px"} fontSize="14px">
                                            Connect
                                        </Button>
                                    )}
                                </Box>

                            </Flex>
                        </Flex>
                    )
                }

            </Flex>
        </>
    );
};

export default IntegrationContent;
