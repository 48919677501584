import React, { useState } from 'react';
import { Formik, Form } from "formik";
import * as yup from 'yup';
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { FormikInput } from '@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput';
import { Box } from '@evabot/eva-react-core-library/dist/atoms/Box/Box';
import { FormikTextArea } from '@evabot/eva-react-core-library/dist/atoms/FormikTextArea/FormikTextArea';

const AddTemplateForm = ({ onClose, handleTemplate, templateData }) => {

    const [htmlCode, setHtmlCode] = useState('');
    const [isTestEmail, setTestEmail] = useState(false);
    const handleHTMLInputChange = (e) => {
        setHtmlCode(e.target.value);
    };

    const validationSchema = yup.object({
        templateName: yup.string()
            .required('Template Name is required')
            .test(
                'uniqueTemplateName',
                'Template Name should be unique',
                value => !templateData.some(template => template.templateName === value)
            ),
        to: yup.string().required('To is required'),
        subject: yup.string().required('Subject is required'),
        html: yup.string().required('HTML content is required'),
    });

    return (
        <>
            <Box padding={"40px"} backgroundColor="white" border="1px solid #ddd" boxShadow="0px 0px 8px rgba(0,0,0,0.1)" borderRadius="4px">
                <Text fontSize="24px" fontWeight="700" marginBottom={4}> Add Template </Text>

                <Formik
                    enableReinitialize
                    initialValues={{
                        templateName: "",
                        to: "",
                        cc: "",
                        subject: "",
                        html: "",
                        preview: ""
                    }}
                    onSubmit={(values, actions) => {
                        const isUnique = !templateData.some(template => template.templateName?.trim().toLowerCase() === values.templateName?.trim().toLowerCase());
                        if (!isUnique) {
                            actions.setFieldError("templateName", "Template Name should be unique");
                            return;
                        }
                        handleTemplate(values,isTestEmail);
                    }}
                    validationSchema={validationSchema}
                >
                    {({ setFieldValue, values }) => (
                        <Form>
                            <Flex flexDirection="column">
                                <Flex flexDirection="column" paddingBottom={"4px"}>
                                    <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"6px"}>Template Name</Text>
                                    <FormikInput
                                        name="templateName"
                                        placeholder=""
                                        width={"70%"}
                                    />
                                </Flex>
                                <Flex flexDirection="column" paddingBottom={"10px"}>
                                    <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"6px"}>To</Text>
                                    <FormikInput
                                        name="to"
                                        placeholder=""
                                        width={"70%"}
                                    />
                                </Flex>
                                <Flex flexDirection="column" paddingBottom={"10px"}>
                                    <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"6px"}>CC</Text>
                                    <FormikInput
                                        name="cc"
                                        placeholder=""
                                        width={"70%"}
                                    />
                                </Flex>
                                <Flex flexDirection="column" paddingBottom={"10px"}>
                                    <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"6px"}>Subject</Text>
                                    <FormikInput
                                        name="subject"
                                        placeholder=""
                                        width={"70%"}
                                    />
                                </Flex>
                                <Flex flexDirection="column" paddingBottom={"10px"}>
                                    <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"6px"}>HTML</Text>
                                    <FormikTextArea
                                        name="html"
                                        placeholder=""
                                        value={htmlCode}
                                        onChange={handleHTMLInputChange}
                                        width={"75%"}
                                    />
                                </Flex>
                                <Flex flexDirection="column" paddingBottom={"10px"}>
                                    <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"6px"}>Preview</Text>
                                    <div
                                        style={{
                                            width: "75%",
                                            border: "1px solid #ddd",
                                            padding: "20px",
                                            minHeight: "150px",
                                            backgroundColor: "#f8f8f8",
                                            position: "relative",
                                        }}
                                    >
                                        {htmlCode ? (
                                            <div dangerouslySetInnerHTML={{ __html: htmlCode }} />
                                        ) : (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)",
                                                    color: "#555",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Your HTML Preview
                                            </div>
                                        )}
                                    </div>
                                </Flex>
                                <Flex paddingTop={"5px"} width={"79%"} justifyContent={"space-between"}>
                                    <Flex>
                                        <Flex justifyContent={"left"}>
                                            <Button onClick={()=>{setTestEmail(false)}} iconName='add' type="submit" padding={"10px"}>Add</Button>
                                        </Flex>
                                        <Flex justifyContent={"right"}>
                                            <Button variant="secondary" type="button" onClick={onClose} marginLeft={2}>
                                                Cancel
                                            </Button>
                                        </Flex>
                                    </Flex>
                                    {/* <Flex>
                                        <Button onClick={()=>{setTestEmail(true)}} variant="secondary"  type="submit"  marginLeft={2}>
                                            Test Email
                                        </Button>
                                    </Flex> */}
                                </Flex>
                            </Flex>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default AddTemplateForm;
