import { FC, useEffect, useState } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Info } from "@evabot/eva-react-core-library/dist/molecules/Info/Info";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { useNavigate } from "react-router-dom";
import { EmailTemplateCard } from "./EmailTemplateCard";
import { MessageModal } from "@evabot/eva-react-core-library/dist/molecules/MessageModal/MessageModal";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { useParams } from "react-router-dom";
import { Anchor } from "@evabot/eva-react-core-library/dist/atoms/Anchor/Anchor";
import { axiosHelper  } from "../../utils";
import { setSlateElements , setSelectedStage , setHtmlString , setNudgeMode , setSelectedTemplate } from "../../containers/SlateEdtitor/store/SlateEditorReducer";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { jsx } from 'slate-hyperscript';
import { setGlobalProgress } from "store/globalStateReducer";
export interface EmailTemplateLayoutProps {}

const deserialize = (el, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent)
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null
  }

  const nodeAttributes = { ...markAttributes }

  // define attributes for text nodes
  switch (el.nodeName) {
    case 'strong':
      // @ts-ignore
      nodeAttributes.bold = true
  }

  const children = Array.from(el.childNodes)
    .map(node => deserialize(node, nodeAttributes))
    .flat()

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''))
  }
  console.log("el nodename",el.nodeName  );
  switch (el.nodeName) {
    
    case 'BODY':
      return jsx('fragment', {}, children)
    // case 'BR':
    //   return '\n'
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'blockquote' }, children)
    case 'P':
      return jsx('element', { type: 'paragraph' }, children)
    case 'H1':
      return jsx('element',{ type : "heading-one" } ,children)
    case 'H2':
      return jsx('element',{ type : "heading-two" } , children )
    case 'H3':
      return jsx('element',{ type : "heading-three" }, children )
    case 'H4':
      return jsx('element',{ type : "heading-four" }, children )
    case 'OL':
      return jsx('element',{ type : "numbered-list" },children)
    case 'UL':
      return jsx('element',{ type : "bulleted-list" },children)
    case 'LI':
      return jsx('element',{ type : "list-item" },children);               
    case 'A':
      return jsx(
        'element',
        { type: 'link', url: el.getAttribute('href') },
        children
      )
    default:
      return children
  }
}

export const EmailTemplateLayout: FC<EmailTemplateLayoutProps> = () => {
  const dispatch = useAppDispatch();
  const [stonlyId,setStonlyId]=useState(null);
  const globalProgress = useAppSelector((state) => state.globalState.progress);
  const journey = useAppSelector((state) => state.globalState.journey);
  const [ templates , setTemplates ] = useState([]);
  const { badge } = useParams();
  const navigate = useNavigate();
  const [hide, setHide] = useState(true);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const modalHandler = () => {
    setHide(true);
  };

  useEffect(() => {
    const items =localStorage.getItem('jwt_token');
    if (items) {
      setStonlyId(items);
      console.log("stonlyId :",items)
    }
  }, []);

  useEffect( () => {
    axiosHelper({
      url:'salesforce/getTemplates', 
      method:'GET', 
      JSONData:{}, 
      urlOverride: false 
    }).then( result => {
     console.log("dataaa",result.data.data.templates);
     setTemplates(result.data.data.templates.filter( temp => temp.category === "default" ));
    } ).catch( err => {
      console.log("error",err);
    } ) 
  } ,[]);
  useEffect(() => {
    if (journey?.journeyPath === "2" && badge) {
      setShowSnackBar(true);
    }
  }, [badge, journey?.journeyPath]);

  return (
    <>
      <SnackBar
        iconName={"paperPlane"}
        subHeading={"Email sent successfully updated."}
        setShowSnackBar={setShowSnackBar}
        show={showSnackBar}
        message="Great!"
        timeout={3000}
        anchor={"top-right"}
        type={"success"}
        bgColorr="#24B300"
      />
      <MessageModal
        width="440px"
        height="300px"
        hideModal={hide}
        setHideModal={modalHandler}
        title="ThankYou emails successfully sent!"
        message="The first emails where sent to 6 stakeholders belonging to 4 opportunities"
        type="success"
        action={true}
        modalButtonHandler={() => navigate("/reminderTemplate/email")}
        buttonText="Continue"
      >
        {}
      </MessageModal>

      <Box background={"#F7F7F7"} padding={"32px 42px"} height={"90vh"} overflow={"auto"} >
        <Flex
          flexDirection={"row"}
          justifyContent={"space-between"}
          marginBottom={"20px"}
        >
          <Flex flexDirection={"column"} gridRowGap={"10px"}>
            {
              //@ts-ignore
              <Text
                fontSize={"24px"}
                color={"#050505"}
                fontWeight={"400"}
                id="emailtemplate.heading"
              />
            }
            <Info locale="emailtemplate.chatgpt.info.message" color="#1FA1FF" />
          </Flex>
          <Flex alignItems="baseline">
          <Text fontSize="12px" fontWeight="400">
            Having troubles?
          </Text>
          <Anchor text="Get Help" link="" id={stonlyId}/>
        </Flex>
          {/* <Text fontSize={"12px"} fontWeight={"400"}>
            Having troubles? Get help
          </Text> */}
        </Flex>
        <Flex
          flexDirection={"column"}
          gridRowGap={"20px"}
          justifyContent={"space-around"}
          alignItems={"stretch"}
        >
          { templates.map( template => {
            return (
              <EmailTemplateCard
              onClick={ () => {
                let body = template.email.body;
        //         let paragraphsArray = [];
        //         paragraphsArray.push( {
        //           type: "paragraph",
        //           children: [
        //             {
        //               text: template.email.subject,
        //             },
        //           ]
        //         } )
        //         let   bodyparagraphsArray = body.split("/n").map( txt => {
        //           // console.log("text",txt);
        //           return txt.substring(txt.indexOf("p>") + 2, txt.indexOf("</p>"))
        //         } ).map( d => {
        //           return {
        //             type: "paragraph",
        // children: [
        //   {
        //     text: d,
        //   },
        // ],
        //           }
        //         } );
        //         paragraphsArray.push(...bodyparagraphsArray);
        //         console.log("paragraphs",paragraphsArray);
        //         // @ts-ignore
        //         dispatch( setSlateElements(paragraphsArray) );
                // localStorage.setItem("slatehtml",body);
                 
                 console.log("body",template.email.body.replace(/\/n/g, ''));
                 let htmlstr = `<p>${template.email.subject}</p>`.concat(template.email.body);
                 console.log("htmlstr",htmlstr);
                 let desealized = deserialize( new DOMParser().parseFromString(   htmlstr.replace(/\/n/g, ''), 'text/html').body );
                //  let desealized =  deserialize(new DOMParser().parseFromString(   template.email.body.replace(/\/n/g, ''), 'text/html').body);
                 console.log("desealized",desealized);
                 console.log("....", JSON.stringify(desealized));
                 localStorage.setItem("content", JSON.stringify(desealized) );
                 console.log("parsed", JSON.parse( JSON.stringify(desealized) ) );
                 dispatch( setSelectedTemplate(template) );
                 localStorage.setItem("template",JSON.stringify(template));
                dispatch( setSelectedStage(template.stage) );
                localStorage.setItem("stage",template.stage);
                dispatch( setNudgeMode(false) );
                localStorage.setItem("type","email");
                setTimeout( () => {
                  navigate("/slateEditor");
                } ,500);
              } }
            badgeType={ template.stage }
            textContent={template.email.body.replace(/<[^>]+>/g, '').replace(/\/n/g, '') }
            mailHeader={template.email.subject}
            textLength={400}
            isBadge={badge && badge === "early"}
            
          />
            )
          } ) }
          {/* <EmailTemplateCard
            badgeType="early"
            textContent={textContent}
            mailHeader={"Email subject 1"}
            textLength={400}
            isBadge={badge && badge === "early"}
            
          />
          <EmailTemplateCard
            badgeType="mid"
            textContent={textContent}
            mailHeader={"Email subject 2"}
            isBadge={badge && badge === "mid"}
          />
          <EmailTemplateCard
            badgeType="late"
            textContent={textContent}
            mailHeader={"Email subject 3"}
            isBadge={badge && badge === "late"}
            textLength={380}
          /> */}
        </Flex>
        <Flex
          flexDirection={"row"}
          justifyContent={"flex-end"}
          gridColumnGap={"16px"}
          mt={"54px"}
        >
          <Button
            iconAlign="left"
            iconName="arrowLeft"
            variant="secondary"
            onClick={() => {
              if (
                journey?.journeyName === "admin" &&
                journey?.journeyPath === "2"
              ) {
                navigate("/stakeHolder");
              } else navigate("/budgetSetupLayout");
            }}
          >
            Previous step
          </Button>
          <Button
            iconAlign="right"
            iconName="arrowRight"
            onClick={() => {
              if (
                journey?.journeyName === "admin" &&
                journey?.journeyPath === "2"
              ) {
                setHide(!hide);
              } else {
                if(globalProgress<80) {
                  dispatch(setGlobalProgress(80));
                }
                navigate("/inviteUsersLayout/:email");
              }
            }}
          >
            Next 
          </Button>
        </Flex>
      </Box>
    </>
  );
};
