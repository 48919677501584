import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from 'store';

const initialState = {
  importOpportunities : [] ,
  filteredOpps : null ,
  filteredValues : null 
}

interface Opportunity {
    
        attributes : {
            type : string ,
            url : string 
        },
        Id : string ,
        Name : string ,
        Amount : Number ,
        OwnerId : String ,
        CreatedDate : String ,
        CloseDate : String ,
        StageName : String 
    
   
}

const OpportunitySelectionReducerSlice = createSlice({
    name: 'opportunitySelection',
    initialState: initialState,
    reducers: {
        setImportOpportunities: (state, action: PayloadAction<Opportunity[]>) => {
            state.importOpportunities = action.payload
        } ,
        setFilteredValues : (state , action:any) => {
          state.filteredValues = action.payload;
        },
        setFilteredOpps : ( state , action:any ) => {
          state.filteredOpps = action.payload;
        }

    }
})

export const { setImportOpportunities , setFilteredOpps , setFilteredValues } = OpportunitySelectionReducerSlice.actions;

export const selectopportunitySelection = (state: RootState) => state.opportunitySelection;

export default OpportunitySelectionReducerSlice.reducer;
