import { FC, useCallback, useEffect, useState } from "react";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Pagination } from "@evabot/eva-react-core-library/dist/atoms/Pagination/Pagination";
import { PaginationTable } from "@evabot/eva-react-core-library/dist/atoms/Table/PaginationTable";
import "../../AeReturnOpportunities/AeReturnOppoTable/pagination.css";
import AeReturnStakeTableBody from "./AeReturnStakeTableBody";
import AeReturnStakeTableHeader from "./AeReturnStakeTableHeader";
import { useAppSelector } from "hooks";
import SortByDropArrow from "./SortByDropArrow";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import SortByArrow from "./SortByArrow";
import { useDispatch } from "react-redux";
import {
  setStakeholdersLoadingData,
  setStakeholderPerPage,
  setstakeholderpageNo,
} from "store/globalStateReducer";

interface AeReturnStakeTableProps {
  setOpenSearchModal: (e: any) => void;
  setSearchQuery: (e: any) => void;
  allStakeholders: any;
}

const AeReturnStakeTable: FC<AeReturnStakeTableProps> = ({
  allStakeholders,
  setOpenSearchModal,
  setSearchQuery,
}) => {
  const stakeholders = useAppSelector(
    (state) => state.globalState.stakeholders
  );
  const stakeholderPerPage = useAppSelector(
    (state) => state.globalState.stakeholderPerPage
  );
  const stakeholderPageNo = useAppSelector(
    (state) => state.globalState.stakeholderPageNo
  );
  const [opportunities, setOpportunities] = useState(stakeholders);
  const [page, setpage] = useState(stakeholderPageNo);
  const [perPage, setPerPage] = useState(stakeholderPerPage);
  const [openPerPageModal, setOpenPerPageModal] = useState(false);

  const dispatch = useDispatch();

  const perPageChange = useCallback(
    (pageValue) => {
      //@ts-ignore
      dispatch(setStakeholdersLoadingData(true));
      setPerPage(pageValue);
      //@ts-ignore
      dispatch(setStakeholderPerPage(pageValue));
      setTimeout(() => {
        //@ts-ignore
        dispatch(setStakeholdersLoadingData(false));
      }, 1000);
    },
    [dispatch]
  );

  useEffect(() => {
    //@ts-ignore
    dispatch(setstakeholderpageNo(page));
  }, [dispatch, page]);

  const tableFooter = () => {
    return (
      <Flex justifyContent={"space-between"} alignItems="center">
        <Pagination
          userData={allStakeholders}
          perPage={perPage}
          setCalcData={setOpportunities}
          setPage={setpage}
          page={page}
        />
        <Flex
          mr="50px"
          color="#050505"
          fontWeight={500}
          fontSize="14px"
          alignItems={"center"}
        >
          <Text mr="10px" color="#57585C">
            Per Page :
          </Text>
          <Grid
            onMouseEnter={() => setOpenPerPageModal(true)}
            onMouseLeave={() => setOpenPerPageModal(false)}
          >
            {/* {!openPerPageModal ? ( */}
            <Flex
              padding={"8px"}
              borderRadius="6px"
              backgroundColor={"#fff"}
              alignItems="center"
              cursor="pointer"
              // onClick={() => setOpenPerPageModal(true)}

              position={"relative"}
              border={"1px solid rgba(0, 0, 0, 0.1)"}
              color="#57585C"
              fontSize={"12px"}
              fontWeight={300}
            >
              <Box mr="10px">
                <Text as="span"> {perPage} </Text>
              </Box>

              <SortByArrow size="16px" />
            </Flex>
            {/* ) : ( */}
            <Flex
              style={{ display: openPerPageModal ? "flex" : "none" }}
              flexDirection={"column"}
              padding={"20px 24px"}
              borderRadius="8px"
              backgroundColor={"#fff"}
              cursor={"pointer"}
              color="#0C1116"
              fontSize={"14px"}
              fontWeight={500}
              gridGap={"24px"}
              boxShadow={"0px 4px 15px rgba(0, 0, 0, 0.07)"}
              position={"absolute"}
              zIndex={"1000"}
              width={"35px"}
              bottom="0px"
              right="-22px"
            >
              <Flex justifyContent={"space-between"}>
                <Text color="#0094e3">{perPage}</Text>
                <Flex onClick={() => setOpenPerPageModal(false)}>
                  <SortByDropArrow />
                </Flex>
              </Flex>
              {perPage !== 5 && (
                <Flex
                  onClick={() => {
                    perPageChange(5);
                    //@ts-ignore
                    dispatch(setstakeholderpageNo(0));
                  }}
                >
                  5
                </Flex>
              )}
              {perPage !== 10 && (
                <Flex
                  onClick={() => {
                    perPageChange(10);
                    //@ts-ignore
                    dispatch(setstakeholderpageNo(0));
                  }}
                >
                  10
                </Flex>
              )}
              {perPage !== 25 && (
                <Flex
                  onClick={() => {
                    perPageChange(25);
                    //@ts-ignore
                    dispatch(setstakeholderpageNo(0));
                  }}
                >
                  25
                </Flex>
              )}
              {perPage !== 50 && (
                <Flex
                  onClick={() => {
                    perPageChange(50);
                    //@ts-ignore
                    dispatch(setstakeholderpageNo(0));
                  }}
                >
                  50
                </Flex>
              )}
            </Flex>
            {/* )} */}
          </Grid>
        </Flex>
      </Flex>
    );
  };

  return (
    <Grid width="98%" mt="15px">
      {opportunities && (
        <PaginationTable
          bodyHeight="65vh"
          rows={() => (
            <AeReturnStakeTableBody
              setOpenSearchModal={setOpenSearchModal}
              setSearchQuery={setSearchQuery}
              opportunitiesData={opportunities}
              page={page}
            />
          )}
          footer={tableFooter}
          headerSection={() => <AeReturnStakeTableHeader />}
        />
      )}
    </Grid>
  );
};

export default AeReturnStakeTable;
