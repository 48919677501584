/* global chrome */
import { FC, useEffect, useState } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import DeloitteLogoIcon from "assets/icons/DeloitteLogo";
// import AmerifirstLogoIcon from "assets/icons/AmerifirstLogo";
import ZendeskLogoIcon from "assets/icons/ZendeskLogo";
import MetaLogoIcon from "assets/icons/MetaIcon";
import SnapLogoIcon from "assets/icons/SnapIcon";
// import SalesforceButton from "components/SalesforceButton/SalesforceButton";
import GoogleButton from "components/GoogleButton/Googlebutton";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import AELoginForm from "./AELoginForm";
import { axiosHelper } from "utils";
import { useLocation } from "react-router-dom";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { backgroundColor } from "styled-system";
import { getCookie } from "utils/cookie";
import { useDispatch } from "react-redux";
import { setLoggedInState, setMenuItems } from "store/globalStateReducer";
// declare var ChiliPiper: any;

interface AESignupLeftSectionO1Props {
  handleSnackbarError: (e) => any
  handleSsoScreen: (e) => any
}

const AESignupLeftSectionO1: FC<AESignupLeftSectionO1Props> = ({handleSnackbarError, handleSsoScreen}) => {
  const journey = useAppSelector((state) => state.globalState.journey);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    if(location.state === 'GOOGLE_UNAUTHORIZED') {
      handleSnackbarError('You are not signed up. Please sign up to the Eva Dashboard.');
      navigate(".", { replace: true }); // <-- redirect to current path w/o state
    }
  }, [navigate, location.state, handleSnackbarError]);

  // const triggerSalesforceCall = async () => {
  //   window.open(
  //     `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${
  //       process.env.REACT_APP_SALESFORCE_CLIENT_ID
  //     }&redirect_uri=${
  //       process.env.REACT_APP_SALESFORCE_REDIRECT_URL
  //     }&state=${encodeURIComponent(JSON.stringify(journey))}`,
  //     "_self"
  //   );
  // };

  const triggerGoogleCall = async () => {
    const result = await axiosHelper({
      url: `google/auth/${encodeURIComponent(JSON.stringify(journey))}`
    });
    if(result.data.success) {
      window.open(
        `${result.data.url}`,
        "_self"
      );
    }
  }

  const triggerSSOCall = async() => {
    handleSsoScreen(true);
  } 

  

  // const bookDemo = async () => {
  //   //@ts-ignore
  //   // ChiliPiper.submit("evabot", "rapport-intelligence-router", { map: true, domElement: "#root", lead: { firstName: 'Abhishek', email: 'abhishek@evabot.ai' } });
  //   window.open(
  //     "https://evabot.chilipiper.com/book/rapport-intelligence-queue",
  //     "targetWindow",
  //     `toolbar=no,
  //    location=no,
  //    status=no,
  //    menubar=no,
  //    scrollbars=yes,
  //    resizable=yes,
  //    width=800,
  //    height=550`
  //   );
  // };

  return (
    <>
      <Flex flexDirection="column" alignItems={"center"}>
        <Grid>
          <Text
            marginBottom={24}
            fontSize="24px"
            fontWeight={400}
            color={"white"}
            textAlign="center"
          >
            Get Started
          </Text>

          {/* <Box width="320px" mb="22px">
            <SalesforceButton onClick={triggerSalesforceCall} />
          </Box> */}
          <Box width="320px" mb="22px">
            <GoogleButton onClick={triggerGoogleCall} />
          </Box>
          <Box width="320px" mb="22px">
            <Button fullSize backgroundColor={"#fff !important"} border={"none"} color={"#000"} onClick={triggerSSOCall}>Log In with SSO</Button>
          </Box>

          <Text color="#818181" variant="bodyRegular" mb="14px" mt="24px">
            Or use your email to continue
          </Text>
          <AELoginForm handleSnackbarError={handleSnackbarError}/>
        </Grid>
      </Flex>
      <Flex flexDirection={"column"}>
        <Flex justifyContent={"center"}>
          {
            //@ts-ignore
            <Text
              id="trustedbyteams"
              fontSize={12}
              color={"white"}
              height={"auto"}
            />
          }
        </Flex>
        <Flex justifyContent={"space-evenly"} mt={34} mb={50}>
          <DeloitteLogoIcon />
          <ZendeskLogoIcon />
          <MetaLogoIcon />
          <SnapLogoIcon />
          {/* <AmerifirstLogoIcon /> */}
        </Flex>
      </Flex>
    </>
  );
};

export default AESignupLeftSectionO1;
