import * as React from "react";

function AeSideHelpIcon(props) {
  return (
    <svg
      width={props?.size || "18px"}
      height={props?.size || "18px"}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_5890_15800)">
        <path
          d="M15.893 4.107A8.28 8.28 0 0010 1.666a8.279 8.279 0 00-5.892 2.44A8.279 8.279 0 001.667 10c0 2.226.867 4.319 2.44 5.893A8.279 8.279 0 0010 18.332a8.279 8.279 0 005.893-2.44 8.279 8.279 0 002.44-5.893 8.28 8.28 0 00-2.44-5.892zM10 2.643c1.853 0 3.548.688 4.843 1.823L12.57 6.74A4.133 4.133 0 0010 5.846c-.97 0-1.862.334-2.57.893L5.158 4.466A7.326 7.326 0 0110 2.643zm3.177 7.356A3.18 3.18 0 0110 13.176 3.18 3.18 0 016.823 10 3.18 3.18 0 0110 6.822 3.18 3.18 0 0113.177 10zM2.644 10c0-1.853.688-3.547 1.823-4.842L6.74 7.429A4.133 4.133 0 005.847 10c0 .97.334 1.863.893 2.57l-2.273 2.273A7.326 7.326 0 012.644 10zM10 17.356a7.326 7.326 0 01-4.842-1.824L7.43 13.26c.708.559 1.6.893 2.57.893.97 0 1.863-.334 2.57-.893l2.273 2.273A7.325 7.325 0 0110 17.355zm5.534-2.514l-2.273-2.273c.559-.707.893-1.6.893-2.57 0-.97-.334-1.862-.893-2.57l2.273-2.272a7.326 7.326 0 011.823 4.842 7.326 7.326 0 01-1.823 4.843z"
          fill={props?.fill || "#fff"}
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_5890_15800">
          <path fill={props?.fill || "#fff"} d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AeSideHelpIcon;
