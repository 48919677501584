import React, { FC } from "react";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
interface GoogleButtonProps {
  onClick?: () => void;
}

const GoogleButton: FC<GoogleButtonProps> = ({ onClick }) => {
  return (
    <Button
      fullSize
      id="button"
      as={"button"}
      size="s"
      variant="google"
      padding={"20px 48px"}
      title={"google.signin"}
      iconName="googleIcon"
      onClick={onClick}
      iconAlign="left"
    ></Button>
  );
};

export default GoogleButton;
