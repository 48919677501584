import * as React from "react";

function AeSideAnalyticsIcon(props) {
  return (
    <svg
      width={props?.size || "18px"}
      height={props?.size || "18px"}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_5890_15841)" fill="#fff">
        <path d="M9.156 17.5A8.375 8.375 0 1117.5 9.156a.475.475 0 01-.469.469H9.625v7.431a.475.475 0 01-.469.444zm0-15.806a7.437 7.437 0 00-.469 14.856V9.156a.462.462 0 01.47-.469h7.418A7.444 7.444 0 009.156 1.72v-.025z" />
        <path d="M10.844 19.219a.475.475 0 01-.469-.469v-7.906a.475.475 0 01.469-.469h7.906a.475.475 0 01.469.469 8.381 8.381 0 01-8.375 8.375zm.469-7.907v6.957a7.456 7.456 0 006.956-6.956h-6.956z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_5890_15841">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AeSideAnalyticsIcon;
