import * as React from "react";

function AccordionArrowDown(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 10 7" fill="none" {...props}>
      <path
        d="M9.4 2.6L5.933 6.067a1.384 1.384 0 01-.433.3c-.156.066-.322.1-.5.1s-.344-.034-.5-.1a1.384 1.384 0 01-.433-.3L.6 2.6C.178 2.178.084 1.695.317 1.15.55.607.967.334 1.567.334h6.866c.6 0 1.017.273 1.25.818.233.544.14 1.027-.283 1.449z"
        fill={props?.fill || "#57585C"}
      />
    </svg>
  );
}

export default AccordionArrowDown;
