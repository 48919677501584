import { FC, useState } from "react";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Pagination } from "@evabot/eva-react-core-library/dist/atoms/Pagination/Pagination";
import { PaginationTable } from "@evabot/eva-react-core-library/dist/atoms/Table/PaginationTable";
import { userData } from "./data";
import "./pagination.css";
import AeReturnOppoTableBody from "./AeReturnOppoTableBody";
import AeReturnOppoTableHeader from "./AeReturnOppoTableHeader";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";

interface AeReturnOppoTableProps {}

const AeReturnOppoTable: FC<AeReturnOppoTableProps> = () => {
  const [opportunities, setOpportunities] = useState([]);

  const tableFooter = () => {
    return (
      <Flex justifyContent={"space-between"} alignItems="center">
        <Pagination
          userData={userData}
          perPage={7}
          setCalcData={setOpportunities}
        />
        <Flex gridGap={"80px"} mr="100px" fontSize={"20px"} fontWeight={400}>
          <Text>$9,000</Text>
          <Text>$9,000</Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <Grid width="98%" my="15px">
      {opportunities && (
        <PaginationTable
          bodyHeight="max-content"
          rows={() => (
            <AeReturnOppoTableBody opportunitiesData={opportunities} />
          )}
          footer={tableFooter}
          headerSection={() => <AeReturnOppoTableHeader />}
        />
      )}
    </Grid>
  );
};

export default AeReturnOppoTable;
