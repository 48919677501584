import * as React from "react";

function EvaLogo(props) {
  return (
    <svg
      width="150px"
      height="40px"
      viewBox="0 0 148 33"
      fill="none"
      {...props}
    >
      <path
        d="M38.03 7.262c-.194-4.195-2.99-7.706-8.096-7.216-5.107.49-10.69 15.23-10.793 15.508l-.027.313-.031-.313C18.98 15.276 13.352.54 8.293.056 3.182-.434.384 3.095.192 7.262c-1.602 11.57 6.977 22.503 17.351 25.565 1.032.231 2.101.231 3.133 0 10.414-3.085 18.938-14.01 17.353-25.565zM6.166 13.124C4.913 6.101 7.322 5.81 7.998 6.588c5.398 6.23 7.228 18.533 7.228 18.533-4.242-1.856-7.903-7.023-9.06-12v.002zm25.892 0c-1.156 4.976-4.804 10.143-9.06 11.997 0 0 1.83-12.289 7.228-18.533.676-.778 3.085-.486 1.84 6.536h-.008z"
        fill="#E8015F"
      />
      <path
        d="M58.63 25.383c-4.418 0-7.628-3.11-7.628-7.94 0-4.86 3.128-7.942 7.628-7.942 4.39 0 7.49 3.027 7.49 7.608a9.182 9.182 0 01-.11 1.5H54.898c.194 2.276 1.729 3.553 3.65 3.553a3.103 3.103 0 001.809-.45 3.158 3.158 0 001.236-1.41h4.143c-.823 2.832-3.348 5.081-7.106 5.081zm-3.706-9.385h7.218c-.055-2.026-1.646-3.303-3.622-3.303-1.839 0-3.293 1.193-3.596 3.303zM71.415 9.751l3.841 11.8 3.842-11.8h4.088l-5.625 15.38h-4.665l-5.597-15.38h4.116zM91.307 9.501a5.953 5.953 0 012.807.598 6.028 6.028 0 012.214 1.845V9.753h3.867v15.38h-3.867v-2.25a6.012 6.012 0 01-2.217 1.886 5.936 5.936 0 01-2.832.613c-3.842 0-6.914-3.193-6.914-7.996 0-4.804 3.073-7.885 6.942-7.885zm.987 3.416c-2.058 0-4.006 1.555-4.006 4.47s1.948 4.581 4.006 4.581c2.113 0 4.034-1.61 4.034-4.526 0-2.915-1.92-4.525-4.034-4.525zM112.103 9.751c4.115 0 7.134 3.027 7.134 7.775 0 4.747-3.046 7.83-7.134 7.83-2.991 0-5.049-1.583-5.956-3.555v3.332h-1.896V4.587h1.896v8.745c.934-1.996 3.019-3.58 5.956-3.58zm-.385 1.694c-3.045 0-5.569 2.332-5.569 6.108 0 3.777 2.524 6.109 5.569 6.109 3.156 0 5.57-2.305 5.57-6.137 0-3.858-2.414-6.08-5.57-6.08zM129.332 25.356c-4.225 0-7.435-2.999-7.435-7.802 0-4.831 3.292-7.802 7.49-7.802 4.226 0 7.491 2.97 7.491 7.802 0 4.803-3.318 7.802-7.546 7.802zm0-1.693c2.827 0 5.57-1.944 5.57-6.11 0-4.164-2.689-6.108-5.543-6.108s-5.515 1.944-5.515 6.109 2.635 6.108 5.488 6.108zM140.938 11.612h-2.085V9.974h2.085V6.17h1.948v3.804h4.116v1.638h-4.116v9.412c0 1.86.632 2.444 2.36 2.444h1.756v1.665h-2.059c-2.607 0-4.006-1.082-4.006-4.109l.001-9.412z"
        fill="#fff"
      />
    </svg>
  );
}

export default EvaLogo;
