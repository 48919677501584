import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { useNavigate } from "react-router-dom";
import { AeSidebarTag } from "./AeSidebarTag";

export interface AeSidebarAdminSectionProps {
  activeTab:
    | "home"
    | "nudges"
    | "opportunities"
    | "stakeholders"
    | "teamMembers"
    | "myPlan"
    | "fieldMapping"
    | "emailTemplate"
    | "history"
    | "analytics"
    | "integration"
    | "stakeholdertag"
    | "prospectreport"
    | "companyscorereport"
    | "teammanagement"
    | "memberlisting"
    | "frameworkandcontext"
    | "prospectprocess"
    | "emailtemplate"
    | "automatedemail"

}

export const AeSidebarAdminSection: React.FC<AeSidebarAdminSectionProps> = ({
  activeTab,
}) => {
  const navigate = useNavigate();
  return (
    <Flex
      flexDirection={"column"}
      m="50px 24px 24px 30px"
      color="white"
      justifyContent={"space-between"}
      height="100%"
    >
      <Grid gridRowGap={"20px"}>
        <AeSidebarTag
          active={activeTab === "nudges"}
          text={"Nudges"}
          iconName={"aeSideHomeIcon"}
          onTagClick={() => navigate("/aeReturnDashboard")}
        />
        <AeSidebarTag
          active={activeTab === "opportunities"}
          text={"Opportunities"}
          iconName={"aeSideOppoIcon"}
          onTagClick={() => navigate("/aeReturnProfile")}
        />
        <AeSidebarTag
          active={activeTab === "stakeholders"}
          text={"Stakeholders"}
          iconName={"aeSideStakeIcon"}
        />
        <AeSidebarTag
          active={activeTab === "teamMembers"}
          text={"Team members"}
          iconName={"adminSideTeamIcon"}
        />
       <Flex border="1px solid rgba(255, 255, 255, 0.1)" /> 
        <Text fontSize={"12px"} fontWeight={400} color={"#9F9F9F"} ml="10px">
          Settings
        </Text>
        <AeSidebarTag
          active={activeTab === "myPlan"}
          text={"My plan"}
          iconName={"adminSidePlanIcon"}
        />
        <AeSidebarTag
          active={activeTab === "fieldMapping"}
          text={"Field mapping"}
          iconName={"adminSideFieldIcon"}
        />
        <AeSidebarTag
          active={activeTab === "emailTemplate"}
          text={"Email templates"}
          iconName={"aeSideEmailIcon"}
        />
        <Flex border="1px solid rgba(255, 255, 255, 0.1)" />
        <Flex
          padding={"3px 8px"}
          background="#1CB43D"
          borderRadius={"8px"}
          width="max-content"
          fontSize={"11px"}
          fontWeight={400}
          ml="10px"
        >
          Coming soon
        </Flex>
        <AeSidebarTag
          active={activeTab === "history"}
          text={"Engagement history"}
          iconName={"aeSideHistoryIcon"}
        />
        <AeSidebarTag
          active={activeTab === "analytics"}
          text={"Analytics"}
          iconName={"aeSideAnalyticsIcon"}
        />
      </Grid>
      {/* <Grid gridRowGap={"25px"}>
        <Flex border="1px solid rgba(255, 255, 255, 0.1)" />
        <AeSidebarTag text={"Help center"} iconName={"aeSideHelpIcon"} />
      </Grid> */}
    </Flex>
  );
};
