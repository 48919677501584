import React, { useCallback, FC, useEffect, useState } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { ExpandableCard } from "@evabot/eva-react-core-library/dist/molecules/ExpandableCard/ExpandableCard";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { useAppDispatch } from "../../hooks";
import {
  FieldMappingOpportunityStageState,
  setFieldMappingOpportunityStageDefault,
  setFieldMappingOpportunityStage,
} from "./store/fieldMappingReducer";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Dropdown } from "@evabot/eva-react-core-library/dist/atoms/Dropdown/Dropdown";
import { setInitialValueMultiple, valueChangeMultiple } from "utils/constants";

const stageSchema = yup.object().shape({
  early: yup
    .array()
    .required("fieldmapping.evaStage.early.valid")
    .min(1, "fieldmapping.evaStage.early.valid"),
  mid: yup
    .array()
    .required("fieldmapping.evaStage.mid.valid")
    .min(1, "fieldmapping.evaStage.mid.valid"),
  late: yup
    .array()
    .required("fieldmapping.evaStage.late.valid")
    .min(1, "fieldmapping.evaStage.late.valid"),
});

interface FieldMappingStageProps {
  stageExpandedFlag: boolean;
  isStageCompleteFlag: boolean;
  toggleIsStageCompleteFlag: (e: any) => void;
  toggleStageExpandedFlag: (e: any) => void;
  onSubmit?: () => void;
  allOpportunityFieldsData: any;
  initialValue?: FieldMappingOpportunityStageState;
}

const defaultValues = {
  early: [],
  mid: [],
  late: [],
};

const FieldMappingOpportunityStageSection: FC<FieldMappingStageProps> = ({
  stageExpandedFlag,
  onSubmit,
  toggleStageExpandedFlag,
  allOpportunityFieldsData,
  isStageCompleteFlag,
  toggleIsStageCompleteFlag,
  initialValue,
}) => {
  const dispatch = useAppDispatch();
  const stageFieldLabels = allOpportunityFieldsData
    ?.filter((x) => x.name === "StageName")[0]
    ?.picklistValues?.map((x) => {
      return {
        label: x.label,
        value: x.value,
      };
    });

  const state = stageFieldLabels?.map((e) => e.label);
  const [StateValues, setStateValues] = useState(state);

  useEffect(() => {
    if (initialValue) {
      setInitialValueMultiple(initialValue, StateValues, setStateValues);
    }
  }, []);

  const handleFieldChange = useCallback(
    (stateValue, prevValue) => {
      valueChangeMultiple(stateValue, prevValue, StateValues, setStateValues);
    },
    [StateValues]
  );

  const onSubmitHandler = useCallback(
    async (values: any) => {
      dispatch(setFieldMappingOpportunityStage(values));
      dispatch(
        setFieldMappingOpportunityStageDefault({
          early: values?.early,
          mid: values?.mid,
          late: values?.late,
        })
      );
      toggleIsStageCompleteFlag(true);
      // toggleStageExpandedFlag(!stageExpandedFlag);
      onSubmit();
    },
    [dispatch, toggleIsStageCompleteFlag, onSubmit]
  );
  return (
    <>
      {
        //@ts-ignore
        <ExpandableCard
          headerText={"Opportunity Stage"}
          expanded={stageExpandedFlag}
          loaded={isStageCompleteFlag}
          toggleCard={() => {
            toggleStageExpandedFlag(!stageExpandedFlag);
          }}
        >
          <Flex flexDirection="column">
            <Flex>
              <Formik
                initialValues={initialValue ? initialValue : defaultValues}
                onSubmit={onSubmitHandler}
                enableReinitialize
                validationSchema={stageSchema}
              >
                {({
                  values,
                  errors,
                  dirty,
                  isValid,
                  setFieldValue,
                  submitForm,
                  initialValues,
                }) => {
                  return (
                    <>
                      {
                        //@ts-ignore
                        <Form>
                          <Flex alignItems="center" marginBottom={"-10px"}>
                            <Box width={"25vw"}>
                              <Text
                                id="fieldmapping.opportunity.fieldsineva"
                                variant="bodyRegular"
                                fontWeight={"500"}
                                marginBottom="12px"
                                color="textColor.primary"
                              />

                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="contactIDField"
                                placeholder="fieldmapping.opportunitystage.early"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-25px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Text
                                id="fieldmapping.opportunity.fieldsinsalesforce"
                                variant="bodyRegular"
                                fontWeight={"500"}
                                marginBottom="2px"
                                color="textColor.primary"
                                as={"span"}
                              />
                              <Text
                                variant="bodyRegular"
                                fontWeight={"500"}
                                marginBottom="12px"
                                ml="5px"
                                color="textColor.secondary"
                                as={"span"}
                              >
                                {"(select all that apply)"}
                              </Text>
                              <Dropdown
                                isMultiple
                                checkboxLabel
                                padding={"7.5px 0px !important"}
                                options={
                                  values?.early?.length > 0
                                    ? [...StateValues, ...values?.early].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v: string[]) => v.length > 0
                                    ).length
                                  ) {
                                    if (
                                      [...initialValues?.early]
                                        .sort()
                                        .join() ===
                                      [...valueField].sort().join()
                                    ) {
                                      toggleIsStageCompleteFlag(true);
                                    } else {
                                      toggleIsStageCompleteFlag(false);
                                    }
                                  }
                                  let uncheckvalue = "";
                                  if (
                                    values?.early?.length > valueField?.length
                                  ) {
                                    values?.early?.map((v) => {
                                      if (!valueField?.includes(v)) {
                                        uncheckvalue = v;
                                      }
                                      return values?.early;
                                    });
                                  }
                                  if (!(e.key === "Backspace")) {
                                    handleFieldChange(
                                      e?.target?.innerText?.length > 0
                                        ? e?.target?.innerText
                                        : (e?.target?.innerText === undefined ||
                                            e?.target?.innerText === "") &&
                                          valueField?.length === 0
                                        ? e.target?.innerText
                                        : values?.early?.length >
                                          valueField?.length
                                        ? uncheckvalue
                                        : valueField[valueField?.length - 1],
                                      values?.early
                                    );
                                  } else {
                                    let popVal = values?.early.pop();
                                    setStateValues([...StateValues, popVal]);
                                    e.preventDefault();
                                  }
                                }}
                                name="early"
                                value={"early"}
                                placeholder="fieldmapping.opportunitystage.early"
                                variantType="primary"
                                defaultValue={values?.early}
                              />
                            </Box>
                          </Flex>
                          <Flex alignItems="center" marginBottom={"-10px"}>
                            <Box width={"25vw"}>
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                placeholder="fieldmapping.opportunitystage.mid"
                                name="nameField"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-25px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Dropdown
                                isMultiple
                                checkboxLabel
                                padding={"7.5px 0px !important"}
                                options={
                                  values?.mid?.length > 0
                                    ? [...StateValues, ...values?.mid].sort()
                                    : StateValues
                                }
                                // onKeyDown={(e) => {
                                //   if (e.key === "Backspace") {
                                //     e.preventDefault();
                                //   }
                                // }}
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v: string[]) => v.length > 0
                                    ).length
                                  ) {
                                    if (
                                      [...initialValues?.mid].sort().join() ===
                                      [...valueField].sort().join()
                                    ) {
                                      toggleIsStageCompleteFlag(true);
                                    } else {
                                      toggleIsStageCompleteFlag(false);
                                    }
                                  }
                                  let uncheckvalue = "";
                                  if (
                                    values?.mid?.length > valueField?.length
                                  ) {
                                    values?.mid?.map((v) => {
                                      if (!valueField?.includes(v)) {
                                        uncheckvalue = v;
                                      }
                                      return values?.mid;
                                    });
                                  }
                                  if (!(e.key === "Backspace")) {
                                    handleFieldChange(
                                      e?.target?.innerText?.length > 0
                                        ? e?.target?.innerText
                                        : (e?.target?.innerText === undefined ||
                                            e?.target?.innerText === "") &&
                                          valueField?.length === 0
                                        ? e.target?.innerText
                                        : values?.mid?.length >
                                          valueField?.length
                                        ? uncheckvalue
                                        : valueField[valueField?.length - 1],
                                      values?.mid
                                    );
                                  } else {
                                    let popVal = values?.mid.pop();
                                    setStateValues([...StateValues, popVal]);
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="fieldmapping.opportunitystage.mid"
                                name="mid"
                                value={"mid"}
                                variantType="primary"
                                defaultValue={values?.mid}
                              />
                            </Box>
                          </Flex>
                          <Flex alignItems="center" marginBottom={"-10px"}>
                            <Box width={"25vw"}>
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                placeholder="fieldmapping.opportunitystage.late"
                                name="emailAddressField"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-25px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Dropdown
                                isMultiple
                                checkboxLabel
                                padding={"7.5px 0px !important"}
                                options={
                                  values?.late?.length > 0
                                    ? [...StateValues, ...values?.late].sort()
                                    : StateValues
                                }
                                // onKeyDown={(e) => {
                                //   if (e.key === "Backspace") {
                                //     e.preventDefault();
                                //   }
                                // }}
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v: string[]) => v.length > 0
                                    ).length
                                  ) {
                                    if (
                                      [...initialValues?.late].sort().join() ===
                                      [...valueField].sort().join()
                                    ) {
                                      toggleIsStageCompleteFlag(true);
                                    } else {
                                      toggleIsStageCompleteFlag(false);
                                    }
                                  }
                                  let uncheckvalue = "";
                                  if (
                                    values?.late?.length > valueField?.length
                                  ) {
                                    values?.late?.map((v) => {
                                      if (!valueField?.includes(v)) {
                                        uncheckvalue = v;
                                      }
                                      return values?.late;
                                    });
                                  }
                                  if (!(e.key === "Backspace")) {
                                    handleFieldChange(
                                      e?.target?.innerText?.length > 0
                                        ? e?.target?.innerText
                                        : (e?.target?.innerText === undefined ||
                                            e?.target?.innerText === "") &&
                                          valueField?.length === 0
                                        ? e.target?.innerText
                                        : values?.late?.length >
                                          valueField?.length
                                        ? uncheckvalue
                                        : valueField[valueField?.length - 1],
                                      values?.late
                                    );
                                  } else {
                                    let popVal = values?.late.pop();
                                    setStateValues([...StateValues, popVal]);
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="fieldmapping.opportunitystage.late"
                                name="late"
                                value={"late"}
                                variantType="primary"
                                defaultValue={values?.late}
                              />
                            </Box>
                          </Flex>
                          <Flex marginTop={15} alignItems="center">
                            {/* {
                              //@ts-ignore
                              <Text
                                id="addnewfield"
                                cursor="pointer"
                                marginRight={16}
                                color={"#DD015B"}
                              />
                            } */}
                            <Button
                              title="saveandcontinue"
                              onClick={submitForm}
                              disabled={!dirty || !isValid}
                            />
                          </Flex>
                        </Form>
                      }
                    </>
                  );
                }}
              </Formik>
            </Flex>
          </Flex>
        </ExpandableCard>
      }
    </>
  );
};

export default FieldMappingOpportunityStageSection;
