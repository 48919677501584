import React, { useState, useCallback, FC, useEffect } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Dropdown } from "@evabot/eva-react-core-library/dist/atoms/Dropdown/Dropdown";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { ExpandableCard } from "@evabot/eva-react-core-library/dist/molecules/ExpandableCard/ExpandableCard";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  FieldMappingOpportunityState,
  setFieldMappingOpportunity,
  setFieldMappingOpportunityDefault,
  setAddField,
  setEditField,
} from "./store/fieldMappingReducer";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { fieldMappingSalesforceNameMapper } from "utils";
import { setInitialValue, valueChange } from "utils/constants";
import DeleteIcon from "assets/icons/DeleteIcon";

interface FieldMappingOpportunitySectionProps {
  onSubmit?: () => void;
  allSalesforceFields: any;
  isOpportunityCompleteFlag: boolean;
  toggleIsOpportunityCompleteFlag: (e: any) => void;
  initialValue?: FieldMappingOpportunityState;
}

const opportunitySchema = yup.object().shape({
  opportunityName: yup.string().required("fieldmapping.opportunity.name.valid"),
  opportunityValue: yup
    .string()
    .required("fieldmapping.opportunity.value.valid"),
  opportunityStage: yup
    .string()
    .required("fieldmapping.opportunity.stage.valid"),
  opportunityID: yup.string().required("fieldmapping.opportunity.id.valid"),
  assignedDate: yup.string().required("fieldmapping.opportunity.date.valid"),
  assignedTo: yup
    .string()
    .required("fieldmapping.opportunity.assignedPerson.valid"),
  expectedCloseDate: yup
    .string()
    .required("fieldmapping.opportunity.closedDate.valid"),
});

const FieldMappingOpportunitySection: FC<
  FieldMappingOpportunitySectionProps
> = ({
  onSubmit,
  allSalesforceFields,
  isOpportunityCompleteFlag,
  toggleIsOpportunityCompleteFlag,
  initialValue,
}) => {
  const addFieldData = useAppSelector(
    (state) => state.fieldMapping.addNewFields
  );
  console.log(addFieldData, "addd state data");
  const [opportunitiesExpandedFlag, toggleOpportunitiesExpandedFlag] =
    useState(true);
  const dispatch = useAppDispatch();

  const state = allSalesforceFields.map((e) => e.label);
  const [StateValues, setStateValues] = useState(state);
  const [showAddField, setShowAddField] = useState(false);

  const defaultValues = {
    opportunityName: "",
    opportunityValue: "",
    opportunityStage: "",
    opportunityID: "",
    assignedDate: "",
    assignedTo: "",
    expectedCloseDate: "",
    addFieldName: "",
    addFieldValue: "",
  };

  useEffect(() => {
    if (initialValue) {
      setInitialValue(initialValue, StateValues, setStateValues);
    }
  }, []);

  const handleFieldChange = useCallback(
    (stateValue, prevValue) => {
      console.log("check target change");
      valueChange(stateValue, prevValue, StateValues, setStateValues);
    },
    [StateValues]
  );

  const editFieldValue = useCallback(
    (index, formData) => {
      const editArray = [...addFieldData];
      console.log(editArray, "addd edit before");
      editArray[index] = formData;
      console.log(editArray, "addd edit");
      // @ts-ignore
      dispatch(setEditField(editArray));
    },
    [addFieldData, dispatch]
  );

  const deleteFieldValue = useCallback(
    (indexValue) => {
      const newAddFieldArray = [...addFieldData];
      const final = newAddFieldArray.splice(Number(indexValue), 1);
      const filterArray = addFieldData?.filter(
        (data, index) => index !== indexValue
      );
      console.log(filterArray, "addd delete", newAddFieldArray);
      // @ts-ignore
      dispatch(setEditField(filterArray));
    },
    [addFieldData, dispatch]
  );

  const onSubmitHandler = useCallback(
    async (values: any) => {
      console.log(values, "demo values");
      // const demo = values;
      // console.log(demo, "demo be");
      // delete demo.addFieldName;
      // delete demo.addFieldValue;
      // console.log(demo, "demo aftre");
      let finalObj = fieldMappingSalesforceNameMapper(
        values,
        allSalesforceFields
      );
      // @ts-ignore
      dispatch(setFieldMappingOpportunity(finalObj));
      dispatch(setFieldMappingOpportunityDefault(values));
      toggleIsOpportunityCompleteFlag(true);
      // toggleOpportunitiesExpandedFlag(!opportunitiesExpandedFlag);
      onSubmit();
    },
    [dispatch, toggleIsOpportunityCompleteFlag, allSalesforceFields, onSubmit]
  );

  const onSubmitSubForm = useCallback(
    async (values: any) => {
      dispatch(setAddField(values));
    },
    [dispatch]
  );

  return (
    <>
      {
        //@ts-ignore
        <ExpandableCard
          headerText={"Opportunities"}
          expanded={opportunitiesExpandedFlag}
          loaded={isOpportunityCompleteFlag}
          toggleCard={() => {
            toggleOpportunitiesExpandedFlag(!opportunitiesExpandedFlag);
          }}
        >
          <Flex flexDirection="column">
            <Flex>
              <Formik
                initialValues={
                  initialValue
                    ? { ...defaultValues, ...initialValue }
                    : defaultValues
                }
                onSubmit={onSubmitHandler}
                enableReinitialize
                validationSchema={opportunitySchema}
              >
                {({
                  values,
                  dirty,
                  isValid,
                  submitForm,
                  setFieldValue,
                  errors,
                  setValues,
                  initialValues,
                }) => {
                  return (
                    <Form>
                      <>{console.log(values, "addd form values", errors)}</>
                      <Flex alignItems="center" marginBottom={"-14px"}>
                        <Box width={"25vw"}>
                          <Text
                            id="fieldmapping.opportunity.fieldsineva"
                            variant="bodyRegular"
                            fontWeight={"500"}
                            marginBottom="12px"
                            color="textColor.primary"
                          />
                          <FormikInput
                            width={"100%"}
                            disabled
                            labelHide
                            name="opportunityNameField"
                            placeholder="fieldmapping.opportunity.opportunityname"
                          />
                        </Box>
                        <Flex
                          marginLeft={"45px"}
                          marginRight={"20px"}
                          marginTop={"-10px"}
                        >
                          <Icon name="arrowRight" fill="#9F9F9F" />
                        </Flex>
                        <Box width={"25vw"}>
                          <Text
                            id="fieldmapping.opportunity.fieldsinsalesforce"
                            variant="bodyRegular"
                            fontWeight={"500"}
                            marginBottom="2px"
                            color="textColor.primary"
                          />
                          <Dropdown
                            padding={"7.5px 0px !important"}
                            options={
                              values?.opportunityName?.length > 0
                                ? [
                                    ...StateValues,
                                    values?.opportunityName,
                                  ].sort()
                                : StateValues
                            }
                            name="opportunityName"
                            placeholder="fieldmapping.opportunity.opportunityname"
                            variantType="primary"
                            //@ts-ignore
                            onChange={(e, valueField) => {
                              if (
                                Object.values(initialValues)?.filter(
                                  (v) => v !== ""
                                ).length
                              ) {
                                if (
                                  initialValues?.opportunityName ===
                                  e?.target?.innerHTML
                                ) {
                                  toggleIsOpportunityCompleteFlag(true);
                                } else {
                                  toggleIsOpportunityCompleteFlag(false);
                                }
                              }
                              handleFieldChange(
                                valueField,
                                values?.opportunityName
                              );
                              // setValues({
                              //   ...totalValues,
                              //   opportunityName: e?.target?.innerHTML,
                              // });
                            }}
                            defaultValue={values?.opportunityName}
                          />
                        </Box>
                      </Flex>
                      <Flex alignItems="center" marginBottom={"-14px"}>
                        <Box width={"25vw"}>
                          <FormikInput
                            width={"100%"}
                            disabled
                            labelHide
                            name="opportunityValueField"
                            placeholder="fieldmapping.opportunity.opportunityvalue"
                          />
                        </Box>
                        <Flex
                          marginLeft={"45px"}
                          marginRight={"20px"}
                          marginTop={"-25px"}
                        >
                          <Icon name="arrowRight" fill="#9F9F9F" />
                        </Flex>
                        <Box width={"25vw"}>
                          <Dropdown
                            padding={"7.5px 0px !important"}
                            options={
                              values?.opportunityValue?.length > 0
                                ? [
                                    ...StateValues,
                                    values?.opportunityValue,
                                  ].sort()
                                : StateValues
                            }
                            name="opportunityValue"
                            placeholder="fieldmapping.opportunity.opportunityvalue"
                            variantType="primary"
                            //@ts-ignore
                            onChange={(e, valueField) => {
                              if (
                                Object.values(initialValues)?.filter(
                                  (v) => v !== ""
                                ).length
                              ) {
                                if (
                                  initialValues?.opportunityValue ===
                                  e?.target?.innerHTML
                                ) {
                                  toggleIsOpportunityCompleteFlag(true);
                                } else {
                                  toggleIsOpportunityCompleteFlag(false);
                                }
                              }
                              handleFieldChange(
                                valueField,
                                values?.opportunityValue
                              );
                            }}
                            defaultValue={values?.opportunityValue}
                          />
                        </Box>
                      </Flex>
                      <Flex alignItems="center" marginBottom={"-14px"}>
                        <Box width={"25vw"}>
                          <FormikInput
                            width={"100%"}
                            disabled
                            labelHide
                            name="opportunityStageField"
                            placeholder="fieldmapping.opportunity.opportunitystage"
                          />
                        </Box>
                        <Flex
                          marginLeft={"45px"}
                          marginRight={"20px"}
                          marginTop={"-25px"}
                        >
                          <Icon name="arrowRight" fill="#9F9F9F" />
                        </Flex>
                        <Box width={"25vw"}>
                          <Dropdown
                            padding={"7.5px 0px !important"}
                            options={
                              values?.opportunityStage?.length > 0
                                ? [
                                    ...StateValues,
                                    values?.opportunityStage,
                                  ].sort()
                                : StateValues
                            }
                            name="opportunityStage"
                            placeholder="fieldmapping.opportunity.opportunitystage"
                            variantType="primary"
                            //@ts-ignore
                            onChange={(e, valueField) => {
                              if (
                                Object.values(initialValues)?.filter(
                                  (v) => v !== ""
                                ).length
                              ) {
                                if (
                                  initialValues?.opportunityStage ===
                                  e?.target?.innerHTML
                                ) {
                                  toggleIsOpportunityCompleteFlag(true);
                                } else {
                                  toggleIsOpportunityCompleteFlag(false);
                                }
                              }
                              handleFieldChange(
                                valueField,
                                values?.opportunityStage
                              );
                            }}
                            defaultValue={values?.opportunityStage}
                          />
                        </Box>
                      </Flex>
                      <Flex alignItems="center" marginBottom={"-14px"}>
                        <Box width={"25vw"}>
                          <FormikInput
                            width={"100%"}
                            disabled
                            labelHide
                            name="opportunityIDField"
                            placeholder="fieldmapping.opportunity.opportunityid"
                          />
                        </Box>
                        <Flex
                          marginLeft={"45px"}
                          marginRight={"20px"}
                          marginTop={"-25px"}
                        >
                          <Icon name="arrowRight" fill="#9F9F9F" />
                        </Flex>
                        <Box width={"25vw"}>
                          <Dropdown
                            padding={"7.5px 0px !important"}
                            options={
                              values?.opportunityID?.length > 0
                                ? [...StateValues, values?.opportunityID].sort()
                                : StateValues
                            }
                            name="opportunityID"
                            placeholder="fieldmapping.opportunity.opportunityid"
                            variantType="primary"
                            //@ts-ignore
                            onChange={(e, valueField) => {
                              if (
                                Object.values(initialValues)?.filter(
                                  (v) => v !== ""
                                ).length
                              ) {
                                if (
                                  initialValues?.opportunityID ===
                                  e?.target?.innerHTML
                                ) {
                                  toggleIsOpportunityCompleteFlag(true);
                                } else {
                                  toggleIsOpportunityCompleteFlag(false);
                                }
                              }
                              handleFieldChange(
                                valueField,
                                values?.opportunityID
                              );
                            }}
                            defaultValue={values?.opportunityID}
                          />
                        </Box>
                      </Flex>
                      <Flex alignItems="center" marginBottom={"-14px"}>
                        <Box width={"25vw"}>
                          <FormikInput
                            width={"100%"}
                            disabled
                            labelHide
                            name="assignedDateField"
                            placeholder="fieldmapping.opportunity.assigneddate"
                          />
                        </Box>
                        <Flex
                          marginLeft={"45px"}
                          marginRight={"20px"}
                          marginTop={"-25px"}
                        >
                          <Icon name="arrowRight" fill="#9F9F9F" />
                        </Flex>
                        <Box width={"25vw"}>
                          <Dropdown
                            padding={"7.5px 0px !important"}
                            options={
                              values?.assignedDate?.length > 0
                                ? [...StateValues, values?.assignedDate].sort()
                                : StateValues
                            }
                            name="assignedDate"
                            placeholder="fieldmapping.opportunity.assigneddate"
                            variantType="primary"
                            //@ts-ignore
                            onChange={(e, valueField) => {
                              if (
                                Object.values(initialValues)?.filter(
                                  (v) => v !== ""
                                ).length
                              ) {
                                if (
                                  initialValues?.assignedDate ===
                                  e?.target?.innerHTML
                                ) {
                                  toggleIsOpportunityCompleteFlag(true);
                                } else {
                                  toggleIsOpportunityCompleteFlag(false);
                                }
                              }
                              handleFieldChange(
                                valueField,
                                values?.assignedDate
                              );
                            }}
                            defaultValue={values?.assignedDate}
                          />
                        </Box>
                      </Flex>
                      <Flex alignItems="center" marginBottom={"-14px"}>
                        <Box width={"25vw"}>
                          <FormikInput
                            width={"100%"}
                            disabled
                            labelHide
                            name="assignedToField"
                            placeholder="fieldmapping.opportunity.assignedto.ae"
                          />
                        </Box>
                        <Flex
                          marginLeft={"45px"}
                          marginRight={"20px"}
                          marginTop={"-25px"}
                        >
                          <Icon name="arrowRight" fill="#9F9F9F" />
                        </Flex>
                        <Box width={"25vw"}>
                          <Dropdown
                            padding={"7.5px 0px !important"}
                            options={
                              values?.assignedTo?.length > 0
                                ? [...StateValues, values?.assignedTo].sort()
                                : StateValues
                            }
                            name="assignedTo"
                            placeholder="fieldmapping.opportunity.assignedto.ae"
                            variantType="primary"
                            //@ts-ignore
                            onChange={(e, valueField) => {
                              if (
                                Object.values(initialValues)?.filter(
                                  (v) => v !== ""
                                ).length
                              ) {
                                if (
                                  initialValues?.assignedTo ===
                                  e?.target?.innerHTML
                                ) {
                                  toggleIsOpportunityCompleteFlag(true);
                                } else {
                                  toggleIsOpportunityCompleteFlag(false);
                                }
                              }
                              handleFieldChange(valueField, values?.assignedTo);
                            }}
                            defaultValue={values?.assignedTo}
                          />
                        </Box>
                      </Flex>
                      <Flex alignItems="center" marginBottom={"-14px"}>
                        <Box width={"25vw"}>
                          <FormikInput
                            width={"100%"}
                            disabled
                            labelHide
                            name="expectedCloseDateField"
                            placeholder="fieldmapping.opportunity.expectedclosedate"
                          />
                        </Box>
                        <Flex
                          marginLeft={"45px"}
                          marginRight={"20px"}
                          marginTop={"-25px"}
                        >
                          <Icon name="arrowRight" fill="#9F9F9F" />
                        </Flex>
                        <Box width={"25vw"}>
                          <Dropdown
                            padding={"7.5px 0px !important"}
                            options={
                              values?.expectedCloseDate?.length > 0
                                ? [
                                    ...StateValues,
                                    values?.expectedCloseDate,
                                  ].sort()
                                : StateValues
                            }
                            name="expectedCloseDate"
                            placeholder="fieldmapping.opportunity.expectedclosedate"
                            variantType="primary"
                            //@ts-ignore
                            onChange={(e, valueField) => {
                              if (
                                Object.values(initialValues)?.filter(
                                  (v) => v !== ""
                                ).length
                              ) {
                                if (
                                  initialValues?.expectedCloseDate ===
                                  e?.target?.innerHTML
                                ) {
                                  toggleIsOpportunityCompleteFlag(true);
                                } else {
                                  toggleIsOpportunityCompleteFlag(false);
                                }
                              }
                              handleFieldChange(
                                valueField,
                                values?.expectedCloseDate
                              );
                            }}
                            defaultValue={values?.expectedCloseDate}
                          />
                        </Box>
                      </Flex>
                      {addFieldData?.length > 0 &&
                        addFieldData &&
                        addFieldData?.map((fieldData, index) => {
                          console.log(fieldData, "addd fielddata", index);
                          return (
                            <Flex alignItems="center" marginBottom={"-14px"}>
                              <Box width={"25vw"}>
                                <FormikInput
                                  width={"100%"}
                                  disabled
                                  labelHide
                                  name="index"
                                  placeholder={`${fieldData?.addFieldName}`}
                                />
                              </Box>
                              <Flex
                                marginLeft={"45px"}
                                marginRight={"20px"}
                                marginTop={"-25px"}
                              >
                                <Icon name="arrowRight" fill="#9F9F9F" />
                              </Flex>
                              <Box width={"25vw"}>
                                <Dropdown
                                  padding={"7.5px 0px !important"}
                                  options={
                                    fieldData?.addFieldValue?.length > 0
                                      ? [
                                          ...StateValues,
                                          fieldData?.addFieldValue,
                                        ].sort()
                                      : StateValues
                                  }
                                  name="addFieldValue"
                                  placeholder="searchHere"
                                  variantType="primary"
                                  onChange={(e) => {
                                    const check =
                                      e.target.innerHTML.includes("</path>");
                                    handleFieldChange(
                                      check ? "" : e?.target?.innerHTML,
                                      fieldData?.addFieldValue
                                    );
                                    editFieldValue(index, {
                                      addFieldName: fieldData?.addFieldName,
                                      addFieldValue: check
                                        ? ""
                                        : e?.target?.innerHTML,
                                    });
                                    setFieldValue("addFieldName", "");
                                    setFieldValue("addFieldValue", "");
                                  }}
                                  defaultValue={fieldData?.addFieldValue}
                                />
                              </Box>
                              <Box
                                ml="16px"
                                marginTop={"-25px"}
                                cursor="pointer"
                                onClick={() => {
                                  deleteFieldValue(index);
                                  setFieldValue("addFieldName", "");
                                  setFieldValue("addFieldValue", "");
                                }}
                              >
                                <DeleteIcon />
                              </Box>
                            </Flex>
                          );
                        })}
                      {showAddField && (
                        <Flex alignItems="center" marginBottom={"-14px"}>
                          <Box width={"25vw"}>
                            <FormikInput
                              width={"100%"}
                              labelHide
                              name="addFieldName"
                              placeholder="fieldmapping.opportunity.expectedclosedate"
                            />
                          </Box>
                          <Flex
                            marginLeft={"45px"}
                            marginRight={"20px"}
                            marginTop={"-25px"}
                          >
                            <Icon name="arrowRight" fill="#9F9F9F" />
                          </Flex>
                          <Box width={"25vw"}>
                            <Dropdown
                              padding={"7.5px 0px !important"}
                              options={
                                values?.addFieldValue?.length > 0
                                  ? [
                                      ...StateValues,
                                      values?.addFieldValue,
                                    ].sort()
                                  : StateValues
                              }
                              name="addFieldValue"
                              placeholder="fieldmapping.opportunity.expectedclosedate"
                              variantType="primary"
                              onChange={(e) => {
                                handleFieldChange(
                                  e?.target?.innerHTML,
                                  values?.addFieldValue
                                );
                              }}
                              defaultValue={values?.addFieldValue}
                            />
                          </Box>
                          <Text
                            color="#DD015B"
                            variant="bodyRegular"
                            mx="16px"
                            fontWeight={"bold"}
                            marginTop={"-25px"}
                            cursor="pointer"
                            onClick={() => {
                              setFieldValue("addFieldName", "");
                              setFieldValue("addFieldValue", "");
                              setShowAddField(false);
                            }}
                          >
                            Cancel
                          </Text>
                          <Button
                            padding="12px 32px"
                            variant="secondary"
                            borderRadius={"12px"}
                            marginTop={"-25px"}
                            disabled={
                              !Boolean(
                                values?.addFieldName?.length > 0 &&
                                  values?.addFieldValue?.length > 0
                              )
                            }
                            onClick={() => {
                              onSubmitSubForm({
                                addFieldName: values?.addFieldName,
                                addFieldValue: values?.addFieldValue,
                              });
                              setFieldValue("addFieldName", "");
                              setFieldValue("addFieldValue", "");
                              setShowAddField(false);
                            }}
                          >
                            Add
                          </Button>
                        </Flex>
                      )}

                      <Flex marginTop={15} alignItems="center">
                        {/* {
                          //@ts-ignore
                          <Text
                            id="addnewfield"
                            cursor="pointer"
                            marginRight={16}
                            color={"#DD015B"}
                            onClick={() => setShowAddField(true)}
                          />
                        } */}

                        <Button
                          title="saveandcontinue"
                          onClick={submitForm}
                          disabled={
                            showAddField
                              ? !Boolean(
                                  values?.addFieldName?.length > 0 &&
                                    values?.addFieldValue?.length > 0
                                ) ||
                                !dirty ||
                                !isValid
                              : !dirty || !isValid
                          }
                        />
                      </Flex>
                    </Form>
                  );
                }}
              </Formik>
            </Flex>
          </Flex>
        </ExpandableCard>
      }
    </>
  );
};

export default FieldMappingOpportunitySection;
