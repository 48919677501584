import React, { useCallback, FC, useEffect, useState } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { ExpandableCard } from "@evabot/eva-react-core-library/dist/molecules/ExpandableCard/ExpandableCard";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { useAppDispatch } from "../../hooks";
import {
  FieldMappingAccountState,
  setFieldMappingAccounts,
  setFieldMappingAccountsDefault,
} from "./store/fieldMappingReducer";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Dropdown } from "@evabot/eva-react-core-library/dist/atoms/Dropdown/Dropdown";
import { fieldMappingSalesforceNameMapper } from "utils";
import { setInitialValue, valueChange } from "utils/constants";

interface FieldMappingAccountsSectionProps {
  accountsExpandedFlag: boolean;
  isAccountsCompleteFlag: boolean;
  toggleAccountsExpandedFlag: (e: any) => void;
  toggleIsAccountsCompleteFlag: (e: any) => void;
  onSubmit?: () => void;
  allSalesforceFields: any;
  initialValue?: FieldMappingAccountState;
}

const accountsSchema = yup.object().shape({
  accountID: yup.string().required("fieldmapping.accounts.id.valid"),
  companyName: yup.string().required("fieldmapping.accounts.name.valid"),
  industry: yup.string().required("fieldmapping.accounts.industry.valid"),
  website: yup.string().required("fieldmapping.accounts.website.valid"),
  phone: yup.string().required("fieldmapping.accounts.phone.valid"),
});

const defaultValues = {
  companyName: "",
  industry: "",
  website: "",
  phone: "",
  accountID: "",
};

const FieldMappingAccountsSection: FC<FieldMappingAccountsSectionProps> = ({
  accountsExpandedFlag,
  toggleAccountsExpandedFlag,
  onSubmit,
  isAccountsCompleteFlag,
  toggleIsAccountsCompleteFlag,
  allSalesforceFields,
  initialValue,
}) => {
  const salesforceFieldLabels = allSalesforceFields.map((x) => {
    return {
      name: x.name,
      label: x.label,
    };
  });

  const state = salesforceFieldLabels.map((e) => e.label);
  const [StateValues, setStateValues] = useState(state);

  useEffect(() => {
    if (initialValue) {
      setInitialValue(initialValue, StateValues, setStateValues);
    }
  }, []);

  const handleFieldChange = useCallback(
    (stateValue, prevValue) => {
      valueChange(stateValue, prevValue, StateValues, setStateValues);
    },
    [StateValues]
  );

  const dispatch = useAppDispatch();

  const onSubmitHandler = useCallback(
    async (values: any) => {
      dispatch(
        setFieldMappingAccounts(
          // @ts-ignore
          fieldMappingSalesforceNameMapper(values, allSalesforceFields)
        )
      );
      dispatch(setFieldMappingAccountsDefault(values));
      toggleIsAccountsCompleteFlag(true);
      // toggleAccountsExpandedFlag(!accountsExpandedFlag);
      onSubmit();
    },
    [dispatch, toggleIsAccountsCompleteFlag, onSubmit, allSalesforceFields]
  );

  return (
    <>
      {
        //@ts-ignore
        <ExpandableCard
          headerText={"Accounts"}
          expanded={accountsExpandedFlag}
          loaded={isAccountsCompleteFlag}
          toggleCard={() => {
            toggleAccountsExpandedFlag(!accountsExpandedFlag);
          }}
        >
          <Flex flexDirection="column">
            <Flex>
              <Formik
                initialValues={initialValue ? initialValue : defaultValues}
                onSubmit={onSubmitHandler}
                enableReinitialize
                validationSchema={accountsSchema}
              >
                {({
                  values,
                  errors,
                  dirty,
                  isValid,
                  setFieldValue,
                  submitForm,
                  initialValues,
                }) => {
                  return (
                    <>
                      {
                        //@ts-ignore
                        <Form>
                          <Flex alignItems="center" marginBottom={"-14px"}>
                            <Box width={"25vw"}>
                              {" "}
                              <Text
                                id="fieldmapping.opportunity.fieldsineva"
                                variant="bodyRegular"
                                fontWeight={"500"}
                                marginBottom="12px"
                                color="textColor.primary"
                              />
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="accountIDField"
                                placeholder="fieldmapping.accounts.accountid"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-10px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Text
                                id="fieldmapping.opportunity.fieldsinsalesforce"
                                variant="bodyRegular"
                                fontWeight={"500"}
                                marginBottom="2px"
                                color="textColor.primary"
                              />
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="accountID"
                                placeholder="fieldmapping.accounts.accountid"
                                variantType="primary"
                                options={
                                  values?.accountID?.length > 0
                                    ? [...StateValues, values?.accountID].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.accountID ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsAccountsCompleteFlag(true);
                                    } else {
                                      toggleIsAccountsCompleteFlag(false);
                                    }
                                  }
                                  console.log(
                                    "apppd",
                                    e.target.value,
                                    "val...",
                                    e.target.innerHTML,
                                    "html....",
                                    e.target.innerText,
                                    "text...",
                                    e?.target?.defaultValue,
                                    "default....",
                                    valueField
                                  );

                                  handleFieldChange(
                                    valueField,
                                    values?.accountID
                                  );
                                }}
                                defaultValue={values?.accountID}
                              />
                            </Box>
                          </Flex>
                          <Flex alignItems="center" marginBottom={"-14px"}>
                            <Box width={"25vw"}>
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="companyNameField"
                                placeholder="fieldmapping.accounts.companyname"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-25px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="companyName"
                                placeholder="fieldmapping.accounts.companyname"
                                variantType="primary"
                                options={
                                  values?.companyName?.length > 0
                                    ? [
                                        ...StateValues,
                                        values?.companyName,
                                      ].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.companyName ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsAccountsCompleteFlag(true);
                                    } else {
                                      toggleIsAccountsCompleteFlag(false);
                                    }
                                  }
                                  handleFieldChange(
                                    valueField,
                                    values?.companyName
                                  );
                                }}
                                defaultValue={values?.companyName}
                              />
                            </Box>
                          </Flex>
                          <Flex alignItems="center" marginBottom={"-14px"}>
                            <Box width={"25vw"}>
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="industryField"
                                placeholder="fieldmapping.accounts.industry"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-25px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="industry"
                                placeholder="fieldmapping.accounts.industry"
                                variantType="primary"
                                options={
                                  values?.industry?.length > 0
                                    ? [...StateValues, values?.industry].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.industry ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsAccountsCompleteFlag(true);
                                    } else {
                                      toggleIsAccountsCompleteFlag(false);
                                    }
                                  }
                                  handleFieldChange(
                                    valueField,
                                    values?.industry
                                  );
                                }}
                                defaultValue={values?.industry}
                              />
                            </Box>
                          </Flex>
                          <Flex alignItems="center" marginBottom={"-14px"}>
                            <Box width={"25vw"}>
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="websiteField"
                                placeholder="fieldmapping.accounts.website"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-25px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="website"
                                placeholder="fieldmapping.accounts.website"
                                variantType="primary"
                                options={
                                  values?.website?.length > 0
                                    ? [...StateValues, values?.website].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.website ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsAccountsCompleteFlag(true);
                                    } else {
                                      toggleIsAccountsCompleteFlag(false);
                                    }
                                  }
                                  handleFieldChange(
                                    valueField,
                                    values?.website
                                  );
                                }}
                                defaultValue={values?.website}
                              />
                            </Box>
                          </Flex>
                          <Flex alignItems="center" marginBottom={"-14px"}>
                            <Box width={"25vw"}>
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="phoneField"
                                placeholder="fieldmapping.accounts.phone"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-25px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="phone"
                                placeholder="fieldmapping.accounts.phone"
                                variantType="primary"
                                options={
                                  values?.phone?.length > 0
                                    ? [...StateValues, values?.phone].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.phone ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsAccountsCompleteFlag(true);
                                    } else {
                                      toggleIsAccountsCompleteFlag(false);
                                    }
                                  }
                                  handleFieldChange(valueField, values?.phone);
                                }}
                                defaultValue={values?.phone}
                              />
                            </Box>
                          </Flex>
                          <Flex marginTop={15} alignItems="center">
                            {/* {
                              //@ts-ignore
                              <Text
                                id="addnewfield"
                                cursor="pointer"
                                marginRight={16}
                                color={"#DD015B"}
                              />
                            } */}
                            <Button
                              title="saveandcontinue"
                              onClick={submitForm}
                              disabled={!dirty || !isValid}
                            />
                          </Flex>
                        </Form>
                      }
                    </>
                  );
                }}
              </Formik>
            </Flex>
          </Flex>
        </ExpandableCard>
      }
    </>
  );
};

export default FieldMappingAccountsSection;
