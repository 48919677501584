// Update your reducer file (e.g., memberListingReducer.ts)

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

const initialState = {
  memberListingData: [],
  tabViewState: {
    isOrgId : "",
    tabValue: "1",
    isTabViewMemberData : {
      userEmail : '',
      userId:'',
      pitch:'',
      persona:'',
      integration : [],
    },
    isTagViewVisible: false, // New state to control the visibility of TagView
  },
};

interface TabViewMemberData {
  userEmail?: string;
  integration?: any[];
}

export interface memberListingReducerType {}

const memberListingReducerSlice = createSlice({
  name: "memberListingState",
  initialState: initialState,
  reducers: {
    setMemberListingData: (
      state,
      action: PayloadAction<memberListingReducerType>
    ) => {
      //@ts-ignore
      state.memberListingData = action.payload;
    },
    setTabValue: (state, action: PayloadAction<string>) => {
      state.tabViewState.tabValue = action.payload;
    },
    setTagViewVisibility: (state, action: PayloadAction<boolean>) => {
      state.tabViewState.isTagViewVisible = action.payload;
    },
    setOrgId: (state, action: PayloadAction<string>) => {
      state.tabViewState.isOrgId = action.payload;
    },
    setTabViewMemberData: (state, action: PayloadAction<TabViewMemberData>) => {
      state.tabViewState.isTabViewMemberData = {
        ...state.tabViewState.isTabViewMemberData,
        ...action.payload
      };
    },
  },
});

export const {
  setMemberListingData,
  setTabValue,
  setTagViewVisibility,
  setOrgId,
  setTabViewMemberData,
} = memberListingReducerSlice.actions;

export const memberListingState = (state: RootState) => state.memberListingState;

export default memberListingReducerSlice.reducer;
