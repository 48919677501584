import { FC, useCallback, useEffect, useState } from "react";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Pagination } from "@evabot/eva-react-core-library/dist/atoms/Pagination/Pagination";
import { PaginationTable } from "@evabot/eva-react-core-library/dist/atoms/Table/PaginationTable";
// AeReturnOpportunities/AeReturnOppoTable/pagination.css
import "../AeReturnOpportunities/AeReturnOppoTable/pagination.css";
import AeReturnAnalyticsTableBody from "./AeReturnAnalyticsTableBody";
import AeReturnAnalyticsTableHeader from "./AeReturnAnalyticsTableHeader";
import { useAppSelector } from "hooks";
import SortByDropArrow from "./SortByDropArrow";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import SortByArrow from "./SortByArrow";
import { useDispatch } from "react-redux";
import {
  setAnalyticsPageNo,
  setAnalyticsPerPage,
  setAeReturnAnalyticsDataLoading,
} from "./store/aeReturnAnalyticsReducer";

interface AeReturnStakeTableProps {
  analyticsData: any;
}

const AeReturnAnalyticsTable: FC<AeReturnStakeTableProps> = ({
  analyticsData,
}) => {
  const allAnalyticsData = useAppSelector(
    (state) => state.analyticsState.analyticsData
  );
  const analyticsPerPage = useAppSelector(
    (state) => state.analyticsState.analyticsPerPage
  );
  const analyticsPageNo = useAppSelector(
    (state) => state.analyticsState.analyticsPageNo
  );
  const [opportunities, setOpportunities] = useState(allAnalyticsData);
  const [page, setpage] = useState(analyticsPageNo);
  const [perPage, setPerPage] = useState(analyticsPerPage);
  const [openPerPageModal, setOpenPerPageModal] = useState(false);

  const dispatch = useDispatch();

  const perPageChange = useCallback(
    (pageValue) => {
      //@ts-ignore
      dispatch(setAeReturnAnalyticsDataLoading(true));
      setPerPage(pageValue);
      //@ts-ignore
      dispatch(setAnalyticsPerPage(pageValue));
      setTimeout(() => {
        //@ts-ignore
        dispatch(setAeReturnAnalyticsDataLoading(false));
      }, 1000);
    },
    [dispatch]
  );

  useEffect(() => {
    //@ts-ignore
    dispatch(setAnalyticsPageNo(page));
  }, [dispatch, page]);

  const tableFooter = () => {
    return (
      <Flex justifyContent={"space-between"} alignItems="center">
        
        <Pagination
          userData={analyticsData}
          perPage={perPage}
          setCalcData={setOpportunities}
          setPage={setpage}
          page={page}
        
        />
        <Flex
          mr="50px"
          color="#050505"
          fontWeight={500}
          fontSize="14px"
          alignItems={"center"}
        >
          <Text mr="10px" color="#57585C">
            Per Page :
          </Text>
          <Grid
            onMouseEnter={() => setOpenPerPageModal(true)}
            onMouseLeave={() => setOpenPerPageModal(false)}
          >
            <Flex
              padding={"8px"}
              borderRadius="6px"
              backgroundColor={"#fff"}
              alignItems="center"
              cursor="pointer"
              position={"relative"}
              border={"1px solid rgba(0, 0, 0, 0.1)"}
              color="#57585C"
              fontSize={"12px"}
              fontWeight={300}
            >
              <Box mr="10px">
                <Text as="span"> {perPage} </Text>
              </Box>

              <SortByArrow size="16px" />
            </Flex>
            <Flex
              style={{ display: openPerPageModal ? "flex" : "none" }}
              flexDirection={"column"}
              padding={"20px 24px"}
              borderRadius="8px"
              backgroundColor={"#fff"}
              cursor={"pointer"}
              color="#0C1116"
              fontSize={"14px"}
              fontWeight={500}
              gridGap={"24px"}
              boxShadow={"0px 4px 15px rgba(0, 0, 0, 0.07)"}
              position={"absolute"}
              zIndex={"1000"}
              width={"auto"}
              bottom="0px"
              right="-22px"
            >
              <Flex justifyContent={"space-between"}>
                <Text color="#0094e3">{perPage}</Text>
                <Flex onClick={() => setOpenPerPageModal(false)}>
                  <SortByDropArrow />
                </Flex>
              </Flex>
              {perPage !== 5 && (
                <Flex
                  onClick={() => {
                    perPageChange(5);
                    //@ts-ignore
                    dispatch(setAnalyticsPageNo(0));
                  }}
                >
                  5
                </Flex>
              )}
              {perPage !== 10 && (
                <Flex
                  onClick={() => {
                    perPageChange(10);
                    //@ts-ignore
                    dispatch(setAnalyticsPageNo(0));
                  }}
                >
                  10
                </Flex>
              )}
              {perPage !== 25 && (
                <Flex
                  onClick={() => {
                    perPageChange(25);
                    //@ts-ignore
                    dispatch(setAnalyticsPageNo(0));
                  }}
                >
                  25
                </Flex>
              )}
              {perPage !== 50 && (
                <Flex
                  onClick={() => {
                    perPageChange(50);
                    //@ts-ignore
                    dispatch(setAnalyticsPageNo(0));
                  }}
                >
                  50
                </Flex>
              )}
            </Flex>
            {/* )} */}
          </Grid>
        </Flex>
      </Flex>
    );
  };

  return (
    <Grid mt="15px">
      {opportunities && (
        <PaginationTable
          bodyHeight=""
          display={"flex"}
          rows={() => (
            <AeReturnAnalyticsTableBody
              opportunitiesData={opportunities}
              page={page}
            />
          )}
          footer={tableFooter}
          headerSection={() => <AeReturnAnalyticsTableHeader />}
        />
      )}
    </Grid>
  );
};

export default AeReturnAnalyticsTable;
