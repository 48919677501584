import apiService from "./apiService";

class StakeHolderTagService {

    saveTag(payload: Object) {
        const apiResponse = apiService.post("rapport/tag/saveTag", payload);
        return apiResponse;
    }

    getTagList(obj: any) {
        const apiResponse = apiService.post("rapport/tag/getTagList", obj);
        return apiResponse;
    }

    deleteTag(tagId: String) {
        const apiResponse = apiService.post("rapport/tag/deleteTag", { tagId: tagId });
        return apiResponse;
    }

    getTagListByStatus(tagId: String, status: String) {
        const apiResponse = apiService.post("rapport/tag/getTagListByStatus", { tagId: tagId, status: status });
        return apiResponse;
    }

    getTagListByTagId(tagId: String) {
        const apiResponse = apiService.post("rapport/tag/getTagListByTagId", { tagId: tagId});
        return apiResponse;
    }

    getTagListByType(obj: any) {
        const apiResponse = apiService.post("rapport/tag/getTagListByType", obj);
        return apiResponse;
    }


}

const stakeHolderTagService = new StakeHolderTagService();
export default stakeHolderTagService;