import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { StateValues } from "containers/FieldMapping/FieldMappingOpportunitySection";
import { RootState } from 'store';

const initialState = {
  slateElements : [
    {
        type: "paragraph",
        children: [
          {
            text: "Subject",
          },
        ],
      },
      {
        type: "paragraph",
        children: [
          {
            text: "Body",
          },
        ],
      },
      {
        type: "paragraph",
        children: [
          {
            text: "Regards",
          },
        ],
      }
  ] ,
  editor : null ,
  selectedStage:  "",
  selectedCategory : "",
  htmlString : "",
  nudgeMode : true ,
  selectedNudge : null ,
  selectedTemplate:  null 
}



const SlateEditorReducerSlice = createSlice({
    name: 'slate',
    initialState: initialState,
    reducers: {
        setSelectedTemplate:  ( state , action : PayloadAction<[]> ) => {
          state.selectedTemplate = action.payload;
        },
        setSlateElements: (state, action: PayloadAction<[]>) => {
            state.slateElements = action.payload
        },
        setSelectedNudge : (state, action: PayloadAction<[]>) => {
          state.selectedNudge = action.payload;
        },
        setEditor : (state, action) => {
           state.editor = action.payload;
        },
        setNudgeMode : (state , action) => {
          state.nudgeMode = action.payload;
        },
        setSelectedStage : (state , action ) => {
         state.selectedStage = action.payload;
        },
        setSelectedCategory : (state , action) => {
         state.selectedCategory = action.payload;
        },
        setHtmlString : (state , action) => {
            state.htmlString = action.payload;
        }
    }
})

export const { setSlateElements , setEditor , setSelectedStage , setSelectedCategory , setHtmlString , setNudgeMode , setSelectedNudge , setSelectedTemplate } = SlateEditorReducerSlice.actions;

export const selectslateeditor = (state: RootState) => state.slate;

export default SlateEditorReducerSlice.reducer;