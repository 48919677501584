import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import AeReturnStakeTableBodyField from "./AeReturnStakeTableBodyField";

interface AeReturnStakeTableBodyProps {
  setOpenSearchModal: (e: any) => void;
  setSearchQuery: (e: any) => void;
  opportunitiesData: any[];
  page: number;
}

const AeReturnStakeTableBody: React.FC<AeReturnStakeTableBodyProps> = ({
  setOpenSearchModal,
  setSearchQuery,
  opportunitiesData,
  page,
}) => {
  return (
    <Grid>
      {opportunitiesData &&
        opportunitiesData?.map((user: any, index) => {
          return (
            <AeReturnStakeTableBodyField
              setOpenSearchModal={setOpenSearchModal}
              user={user}
              index={index}
              page={page}
              setSearchQuery={setSearchQuery}
            />
          );
        })}
    </Grid>
  );
};

export default AeReturnStakeTableBody;
