import React, { FC, useCallback, useState, useMemo } from "react";
import imageExtensions from "image-extensions";
import isUrl from "is-url";
import isHotkey from "is-hotkey";
import {
  createEditor,
  BaseEditor,
  Descendant,
  Editor,
  Transforms,
  Element as SlateElement,
  Text as TextSlate  
} from "slate";
import escapeHtml from 'escape-html';
import { jsx } from 'slate-hyperscript';
import { withHistory } from "slate-history";
import {
  Slate,
  Editable,
  withReact,
  useSlateStatic,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  useSlate,
  useSelected,
  useFocused,
} from "slate-react";

import { css } from "@emotion/css";
import { Button, Icon, Toolbar } from "./SlateToolBarComponents";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { TextArea } from "@evabot/eva-react-core-library/dist/atoms/TextArea/TextArea";
import { Button as CoreBtn } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Icon as Ic } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Button as Btn } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { SelectComponent } from "@evabot/eva-react-core-library/dist/atoms/SelectComponent/SelectComponent";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks";
import { axiosHelper  } from "../../utils";
import { useAppDispatch } from "../../hooks";
import { setSlateElements , setEditor , setSelectedStage} from "./store/SlateEditorReducer";
import { capitalizeFirstLetter } from "../../utils";

// const SlateEditor: FC = () => {
// return (
// <>
// {//@ts-ignore
// <SignupLayout
// left={25} right={75}
// leftChildren={<AdminLeftSectionNav selectedTab={1}/>}
// rightChildren={<BasicEditorTS />} />}
// </>
// )
// }

const Occasions = ["Referral", "Birthday"];
const Tones = ["Joyful", "Professional"];
const Stages = ["Early", "Mid", "Late"];
// const dispatch = useAppDispatch();
// @ts-ignore

// dispatch( setEditor(editor) );
// selectOccasion: yup
//     .array()
//     .required("required.occasionfield")
//     .min(1, "required.occasionfield"),
const AddCustomerValidationSchema = yup.object().shape({
  
  selectTone: yup
    .array()
    .required("required.tonefield")
    .min(1, "required.tonefield"),
  selectevaStage: yup
    .array()
    .required("required.stagefield")
    .min(1, "required.stagefield"),
});

const CustomerValidationSchema = yup.object().shape({
  selectTone: yup
    .array()
    .required("required.tonefield")
    .min(1, "required.tonefield")
  
});

const deserialize = (el, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent)
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null
  }

  const nodeAttributes = { ...markAttributes }

  // define attributes for text nodes
  switch (el.nodeName) {
    case 'strong':
      // @ts-ignore
      nodeAttributes.bold = true
  }

  const children = Array.from(el.childNodes)
    .map(node => deserialize(node, nodeAttributes))
    .flat()

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''))
  }
  console.log("el nodename",el.nodeName  );
  switch (el.nodeName) {
    
    case 'BODY':
      return jsx('fragment', {}, children)
    // case 'BR':
    //   return '\n'
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'blockquote' }, children)
    case 'P':
      return jsx('element', { type: 'paragraph' }, children)
    case 'H1':
      return jsx('element',{ type : "heading-one" } ,children)
    case 'H2':
      return jsx('element',{ type : "heading-two" } , children )
    case 'H3':
      return jsx('element',{ type : "heading-three" }, children )
    case 'H4':
      return jsx('element',{ type : "heading-four" }, children )
    case 'OL':
      return jsx('element',{ type : "numbered-list" },children)
    case 'UL':
      return jsx('element',{ type : "bulleted-list" },children)
    case 'LI':
      return jsx('element',{ type : "list-item" },children);               
    case 'A':
      return jsx(
        'element',
        { type: 'link', url: el.getAttribute('href') },
        children
      )
    default:
      return children
  }
}

// const deserialize = (el, markAttributes = {}) => {
//   if (el.nodeType === Node.TEXT_NODE) {
//     return jsx('text', markAttributes, el.textContent)
//   } else if (el.nodeType !== Node.ELEMENT_NODE) {
//     return null
//   }

//   const nodeAttributes = { ...markAttributes }

//   // define attributes for text nodes
//   switch (el.nodeName) {
//     case 'strong':
//       // @ts-ignore
//       nodeAttributes.bold = true
//   }

//   const children = Array.from(el.childNodes)
//     .map(node => deserialize(node, nodeAttributes))
//     .flat()

//   if (children.length === 0) {
//     children.push(jsx('text', nodeAttributes, ''))
//   }
//   console.log("el nodename",el.nodeName  );
//   switch (el.nodeName) {
    
//     case 'BODY':
//       return jsx('fragment', {}, children)
//     case 'BR':
//       return '\n'
//     case 'BLOCKQUOTE':
//       return jsx('element', { type: 'blockquote' }, children)
//     case 'P':
//       return jsx('element', { type: 'paragraph' }, children)
//     case 'H1':
//       return jsx('element',{ type : "heading-one" } ,children)
//     case 'H2':
//       return jsx('element',{ type : "heading-two" } , children )
//     case 'H3':
//       return jsx('element',{ type : "heading-three" }, children )
//     case 'H4':
//       return jsx('element',{ type : "heading-four" }, children )
//     case 'OL':
//       return jsx('element',{ type : "numbered-list" },children)
//     case 'UL':
//       return jsx('element',{ type : "bulleted-list" },children)
//     case 'LI':
//       return jsx('element',{ type : "list-item" },children);               
//     case 'A':
//       return jsx(
//         'element',
//         { type: 'link', url: el.getAttribute('href') },
//         children
//       )
//     default:
//       return children
//   }
// }

const serialize = node => {
  if (TextSlate.isText(node)) {
    let string = escapeHtml(node.text)
    // @ts-ignore
    if (node.bold) {
      string = `<strong>${string}</strong>`
    }
    return string
  }

  const children = node.children.map(n => serialize(n)).join('')

  switch (node.type) {
    case 'block-quote':
      return `<blockquote><p>${children}</p></blockquote>`
    case 'paragraph':
      return `<p>${children}</p>`
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`
    case 'block-quote':
      return `<blockquote >${children}</blockquote>`
    case 'bulleted-list':
      return `<ul> ${children} </ul>`;
    case 'list-item':
      return `<li>${children}</li>`
    case 'numbered-list':
      return `<ol>${children}</ol>`
    case "heading-one":
      return `<h1> ${children} </h1>`
    case "heading-two":
      return `<h2> ${children} </h2>`
    case "heading-three":
      return `<h3> ${children} </h3>`
    case "heading-four":
      return `<h4> ${children} </h4>`;  

    default:
      return children
  }
}

const SlateEditor: FC = () => {
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const slateState = useAppSelector((state) => state.slate);
  const globalData = useAppSelector((state) => state.globalState);
  const [ emailGenerationLoading , setEmailGenerationLoading ] = useState(false);
  const onSubmitHandler = useCallback(async (values: any) => {
    console.log(values, "values");
  }, []);
  return (
    <>
      <Formik
        initialValues={{
          selectOccasion: [],
          selectTone: [],
          selectevaStage: localStorage.getItem("stage") !== '' ? [capitalizeFirstLetter(localStorage.getItem("stage"))] : [],
        }}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validationSchema={  slateState?.nudgeMode ? CustomerValidationSchema  : AddCustomerValidationSchema  }
      >
        {({ values, errors, dirty, isValid, setFieldValue, initialValues }) => {
          console.log(errors, "error", values, initialValues);
          return (
            <Form>
              {/* { slateState.nudgeMode && (
                  <Flex flexDirection={"row"} width={"100vw"} height={"100vh"}>
                  <Flex
                    maxWidth={"25%"}
                    flexGrow={"1"}
                    flexDirection={"column"}
                    paddingTop={"40px"}
                    paddingLeft={"60px"}
                    paddingRight={"60px"}
                    //  gridRowGap={"30px"}
                    height={"100vh"}
                  >
                    <Flex
                      flexDirection={"row"}
                      gridColumnGap={"5px"}
                      justifyContent={"flex-start"}
                    >
                   
                      <CoreBtn
                        iconAlign="left"
                        iconName="arrowLeft"
                        variant="reviewConfirm"
                        color="black"
                        paddingLeft="10px"
                        onClick={() => {
                          navigate("/aeReturnDashboard");
                        }}
                      >
                        {" "}
                        Go back{" "}
                      </CoreBtn>
                    </Flex>
                    <Flex
                      background={"#F2FDFF"}
                      padding={"8px"}
                      height={"92px"}
                      alignItems={"center"}
                      flexDirection={"row"}
                      mb={"16px"}
                      gap="10px"
                    >
                      <Ic name={"lightBulb"} />
                      <Text
                        fontWeight={"400"}
                        fontSize={"12px"}
                        color={"#050505"}
                      >
                        {" "}
                        The email templates are auto generated using AI and you
                        can test the content generation by changing the below
                        fields.{" "}
                      </Text>
                    </Flex>
                    <Flex flexDirection={"column"}>
                      
  
                      <SelectComponent
                        name="selectTone"
                        placeholder="selectTone"
                        label="selectTone"
                        infoIcon
                        options={Tones}
                      />
  
                     
  
                      <Box
                        background={"#EBF9FF"}
                        borderRadius={"8px"}
                        padding={"8px 16px"}
                        mb={"8px"}
                      >
                        <Text
                          fontSize={"12px"}
                          fontWeight={"400"}
                          color={"#050505"}
                        >
                          {" "}
                          Your signature will be picked up from salesforce{" "}
                        </Text>
                      </Box>
                      <Flex
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        gridColumnGap={"20px"}
                      >
                        <Btn
                          variant="secondary"
                          disabled={
                            
                            values["selectTone"].length == 0 
                            
                          }
                          loading={emailGenerationLoading}
                          onClick={ () => {
                            setEmailGenerationLoading(true);
                            axiosHelper({
                              url:'salesforce/getGPTEmail', 
                              method:'POST', 
                              JSONData:{
                                stage : [], 
                                occasion : [],
                                tone : values["selectTone"][0].toLowerCase()
                              }, 
                              urlOverride: false 
                            }).then( result => {
                               console.log("gpt email",result.data.data);
                               let elements = [{
                                type: "paragraph",
                                children: [
                                  {
                                    text: result.data.data.subject,
                                  },
                                ],
                              } , {
                                type: "paragraph",
                                children: [
                                  {
                                    text: result.data.data.greeting,
                                  },
                                ]
                              } , {
                                type: "paragraph",
                                children: [
                                  {
                                    text: result.data.data.body,
                                  },
                                ]
                              } , {
                                type: "paragraph",
                                children: [
                                  {
                                    text: result.data.data.signOff,
                                  },
                                ]
                              } ];
                              //@ts-ignore
                              dispatch( setSlateElements(elements) );
                               localStorage.setItem("content", JSON.stringify(elements) );
                              // dispatch(setSelectedStage( values["selectevaStage"][0].toLowerCase( ) ));
                              editor.children = elements;
                              setEmailGenerationLoading(false);
  
                            } ).catch( err => {
                              console.log("error",err);
                            } ) 
                          } }
                        >
                          {" "}
                          Generate email content{" "}
                        </Btn>
                        <Text
                          fontSize={"12px"}
                          fontWeight={"500"}
                          color={"#DD015B"}
                          cursor={"pointer"}
                          onClick={ () => {
                            console.log("yeaaa",slateState.htmlString);
                            let htmlString = slateState.htmlString;
                            let desealized = deserialize(new DOMParser().parseFromString(htmlString.replace(/\/n/g, ''), 'text/html').body);
                            console.log("desealized", desealized);
            
                            localStorage.setItem("content", JSON.stringify(desealized));
                            editor.children.map(item => {
                              Transforms.delete(editor, { at: [0] })
                            });
                            Transforms.insertNodes(editor , desealized);
                          } }
                        >
                          {" "}
                          Show original copy{" "}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex maxWidth={"75%"} flexGrow={"2"} 
                  // background={"#EAEDF7"}
                  >
                    <BasicEditorTS stage={values?.selectevaStage} slateElements={slateState.slateElements} />
                  </Flex>
                </Flex>
              ) } */}
              {/* { globalData.journey.journeyPath == "1" && !slateState.nudgeMode  && ( */}
              { localStorage.getItem("type") == "email" && ( 
                <Flex flexDirection={"row"} width={"100vw"} height={"100vh"}>
                <Flex
                  maxWidth={"25%"}
                  flexGrow={"1"}
                  flexDirection={"column"}
                  paddingTop={"40px"}
                  paddingLeft={"60px"}
                  paddingRight={"60px"}
                  //  gridRowGap={"30px"}
                  height={"100vh"}
                >
                  <Flex
                    flexDirection={"row"}
                    gridColumnGap={"5px"}
                    justifyContent={"flex-start"}
                  >
                    {/* <Ic
 name={"arrowLeft"}
 fill={"#050505"}
 style={{ width: "28px", height: "18px" }}
 />
 <Text fontSize={"12px"} fontWeight={"400"} color={"#000"}>
 {" "}
 Go back{" "}
 </Text> */}
                    <CoreBtn
                      iconAlign="left"
                      iconName="arrowLeft"
                      variant="reviewConfirm"
                      color="black"
                      paddingLeft="10px"
                      onClick={() => {
                        navigate("/emailTemplateSetup");
                      }}
                    >
                      {" "}
                      Go back{" "}
                    </CoreBtn>
                  </Flex>
                  <Flex
                    background={"#F2FDFF"}
                    padding={"8px"}
                    height={"92px"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    mb={"16px"}
                    gap="10px"
                  >
                    <Ic name={"lightBulb"} />
                    <Text
                      fontWeight={"400"}
                      fontSize={"12px"}
                      color={"#050505"}
                    >
                      {" "}
                      The email templates are auto generated using AI and you
                      can test the content generation by changing the below
                      fields.{" "}
                    </Text>
                  </Flex>
                  <Flex flexDirection={"column"}>
                    {/* <SelectComponent
                      fontFamily={"Inter"}
                      name="selectOccasion"
                      placeholder="selectOccasion"
                      label="occasion"
                      infoIcon
                      options={Occasions}
                    /> */}

                    <SelectComponent
                      name="selectTone"
                      placeholder="selectTone"
                      label="selectTone"
                      infoIcon
                      options={Tones}
                    />

                    <SelectComponent
                      name="selectevaStage"
                      placeholder="evaStage"
                      label="evaStage"
                      infoIcon
                      options={Stages}
                    />

                    <Box
                      background={"#EBF9FF"}
                      borderRadius={"8px"}
                      padding={"8px 16px"}
                      mb={"8px"}
                    >
                      <Text
                        fontSize={"12px"}
                        fontWeight={"400"}
                        color={"#050505"}
                      >
                        {" "}
                        Your signature will be picked up from salesforce{" "}
                      </Text>
                    </Box>
                    <Flex
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      gridColumnGap={"20px"}
                    >
                      <Btn
                        variant="secondary"
                        disabled={
                          // values["selectOccasion"].length == 0 ||
                          values["selectTone"].length == 0 ||
                          values["selectevaStage"].length == 0
                        }
                        loading={emailGenerationLoading}
                        onClick={ () => {
                          setEmailGenerationLoading(true);
                          axiosHelper({
                            url:'salesforce/getGPTEmail', 
                            method:'POST', 
                            JSONData:{
                              stage : values["selectevaStage"][0].toLowerCase(), 
                              occasion : "",
                              tone : values["selectTone"][0].toLowerCase()
                            }, 
                            urlOverride: false 
                          }).then( result => {
                             console.log("gpt email",result.data.data);
                             let elements = [{
                              type: "paragraph",
                              children: [
                                {
                                  text: result.data.data.subject,
                                },
                              ],
                            } , {
                              type: "paragraph",
                              children: [
                                {
                                  text: result.data.data.greeting,
                                },
                              ]
                            } , {
                              type: "paragraph",
                              children: [
                                {
                                  text: result.data.data.body,
                                },
                              ]
                            } , {
                              type: "paragraph",
                              children: [
                                {
                                  text: result.data.data.signOff,
                                },
                              ]
                            } ];
                            //@ts-ignore
                            dispatch( setSlateElements(elements) );
                            localStorage.setItem("content", JSON.stringify(elements) );
                            dispatch(setSelectedStage( values["selectevaStage"][0].toLowerCase( ) ));
                            editor.children.map(item => {
                              Transforms.delete(editor, { at: [0] })
                            });
                            Transforms.insertNodes(editor , elements);
                            // editor.children = elements;
                            setEmailGenerationLoading(false);

                          } ).catch( err => {
                            console.log("error",err);
                          } ) 
                        } }
                      >
                        {" "}
                        Generate email content{" "}
                      </Btn>
                      <Text
                        fontSize={"12px"}
                        fontWeight={"500"}
                        color={"#DD015B"}
                        cursor={"pointer"}
                        onClick={ () => {
                          console.log("clickeddd");
                          // let template = slateState.selectedTemplate;
                          let template = JSON.parse( localStorage.getItem("template") );
                          let htmlstr = `<p>${template.email.subject}</p>`.concat(template.email.body);
                          console.log("htmlstr",htmlstr);
                          let desealized = deserialize( new DOMParser().parseFromString(   htmlstr.replace(/\/n/g, ''), 'text/html').body );
                         //  let desealized =  deserialize(new DOMParser().parseFromString(   template.email.body.replace(/\/n/g, ''), 'text/html').body);
                          console.log("desealized",desealized);
                          console.log("....", JSON.stringify(desealized));
                          localStorage.setItem("content", JSON.stringify(desealized) );
                          // editor.insertData(desealized);
                          // Transforms.unsetNodes(editor, );
                          // Transforms.setNodes(editor , desealized,{ mode : "all" });
                          // Transforms.removeNodes(editor , { at :  });
                          // Transforms.insertNodes(editor , desealized);
                          editor.children.map(item => {
                            Transforms.delete(editor, { at: [0] })
                          });
                          Transforms.insertNodes(editor , desealized);
                          // editor.children = desealized;
                        } }
                      >
                        {" "}
                        Show original copy{" "}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex maxWidth={"75%"} flexGrow={"2"} 
                // background={"#EAEDF7"}
                >
                  <BasicEditorTS stage={values?.selectevaStage} slateElements={slateState.slateElements} />
                </Flex>
              </Flex>
              ) }
              {/* { globalData.journey.journeyPath == "2" && !slateState.nudgeMode  && ( */}
              { localStorage.getItem("type") == "reminder" && (
                <Flex width={"100%"} margin={"0 auto"} height={"100vh"} >
                    
                     <BasicEditorTS stage={values?.selectevaStage} slateElements={slateState.slateElements} />
                    
                </Flex>
              ) }
              
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

// const SigContainer = {
// /* width: 80%;
// height: 80%;
// margin: 0 auto; */
// backgroundColor: "#fff"
// }
const HOTKEYS: any = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];
//@ts-ignore
const withImages = (editor) => {
  const { insertData, isVoid } = editor;
  //@ts-ignore
  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };
  //@ts-ignore
  editor.insertData = (data) => {
    const text = data.getData("text/plain");
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");

        if (mime === "image") {
          reader.addEventListener("load", () => {
            const url = reader.result;
            insertImage(editor, url);
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const editor = withImages(withReact(withHistory(createEditor())));

const isMarkActive = (editor: any, format: any) => {
  const marks: any = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};
const isBlockActive = (editor: Editor, format: string, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        //@ts-ignore
        n[blockType] === format,
    })
  );

  return !!match;
};
const toggleBlock = (editor: Editor, format: string) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: Partial<SlateElement>;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor: any, format: any) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const Element = ({ attributes, children, element }: RenderElementProps) => {
  const style = { textAlign: element.align };
  const propss = { attributes, children, element };
  switch (element.type) {
    case "image":
      //@ts-ignore
      return <Image {...propss} />;
    case "block-quote":
      return (
        //@ts-ignore
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        //@ts-ignore
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        //@ts-ignore
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        //@ts-ignore
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "heading-three":
      return (
        //@ts-ignore
        <h3 style={style} {...attributes}>
          {children}
        </h3>
      );
    case "heading-four":
      return (
        //@ts-ignore
        <h4 style={style} {...attributes}>
          {children}
        </h4>
      );
    case "list-item":
      return (
        //@ts-ignore
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        //@ts-ignore
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        //@ts-ignore
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  //@ts-ignore
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }
  //@ts-ignore
  if (leaf.code) {
    children = <code>{children}</code>;
  }
  //@ts-ignore
  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  //@ts-ignore
  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};
//@ts-ignore
const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
      )}
      //@ts-ignore
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};
//@ts-ignore
const UndoRedoButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      //@ts-ignore
      onMouseDown={(event) => {
        event.preventDefault();
        if (format === "undo") {
          //@ts-ignore
          editor.undo();
        } else {
          //@ts-ignore
          editor.redo();
        }
        // toggleMark(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};
//@ts-ignore
const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      //@ts-ignore
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};
//@ts-ignore
const isImageUrl = (url) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split(".").pop();
  //@ts-ignore
  return imageExtensions.includes(ext);
};
//@ts-ignore
const insertImage = (editor, url) => {
  const text = { text: "" };
  const image: ImageElement = { type: "image", url, children: [text] };
  Transforms.insertNodes(editor, image);
};
const InsertImageButton = () => {
  const editor = useSlateStatic();
  return (
    <Button
      //@ts-ignore
      onMouseDown={(event) => {
        event.preventDefault();
        const url = window.prompt("Enter the URL of the image:");
        if (url && !isImageUrl(url)) {
          alert("URL is not an image");
          return;
        }
        url && insertImage(editor, url);
      }}
    >
      <Icon>image</Icon>
    </Button>
  );
};

//@ts-ignore
// const UndoRedo = (type) => {
// const editor = useSlate();
// if ( type == "undo" ) {
// //@ts-ignore
// editor.undo();
// } else {
// //@ts-ignore
// editor.redo();
// }
// }
//@ts-ignore
const Image = ({ attributes, children, element }) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  const selected = useSelected();
  const focused = useFocused();
  return (
    <div {...attributes}>
      {children}
      <div
        contentEditable={false}
        className={css`
 position: relative;
 `}
      >
        <img
          src={element.url}
          alt="element url"
          className={css`
 display: block;
 max-width: 100%;
 max-height: 20em;
 box-shadow: ${selected && focused ? "0 0 0 3px #B4D5FF" : "none"};
 `}
        />
        <Button
          active
          onClick={() => Transforms.removeNodes(editor, { at: path })}
          className={css`
 display: ${selected && focused ? "inline" : "none"};
 position: absolute;
 top: 0.5em;
 left: 0.5em;
 background-color: white;
 `}
        >
          <Icon>delete</Icon>
        </Button>
      </div>
    </div>
  );
};
const getTemplateElement = (element) => {

}
const updateReminderTemplate = ( category ,  setShowSnackBar) => {
  // let children = editor.children;
  // console.log("children",children);
  // let htmlString = ``;
  // let subject = children.filter( ch => ch.type == "paragraph" )[0].children.map( txt => txt.text ).join(" ");
  // console.log("subject",subject);
  // let regards = children.filter( ch => ch.type == "paragraph" )[ children.filter( ch => ch.type == "paragraph" ).length - 1 ].children.map( txt => txt.text ).join(" ");
  // console.log("regards",regards);
  // let bodyElements = children.slice( 1 , children.length  );
  // console.log("body elements",bodyElements);
  // let html = bodyElements.filter( elem => elem.type == "paragraph" ).map( elem => {
  //   console.log("elem",elem);
  //   return `<p> ${elem.children.map( txt => txt.text ).join(" ") } </p>`
  // } ).join("/n");
  // console.log("html",html);
  console.log("contenttt", JSON.parse(localStorage.getItem("content")) );
  let editorContent = JSON.parse(localStorage.getItem("content"));
  let searlization = editorContent.map( node => serialize(node) ).join("/n");
  console.log("searlization",searlization);
  let subject = "";
  if ( editorContent.length > 0 ) {
    subject= editorContent[0].children.map( txt => txt.text ).join(" ");
  }
  axiosHelper({
    url:'salesforce/updateTemplates', 
    method:'PUT', 
    JSONData:{ templates : [  {
      name : "",
      category : category,
      stage : "reminder" ,
      email : {
        subject : subject ,
        body : searlization ,
        to : "",
        cc : ""
      }
    }]}, 
    urlOverride: false 
  }).then( result => {
     console.log("gpt email",result.data.data);
       setShowSnackBar(true);
  

  } ).catch( err => {
    console.log("error",err);
  } ) 
}
const updateTemplate = (stage , setShowSnackBar) => {
  console.log("stagee",stage);
  console.log("contenttt", JSON.parse(localStorage.getItem("content")) );
  let editorContent = JSON.parse(localStorage.getItem("content"));
  let searlizeEditorContent = [...editorContent];
  editorContent.shift();
  let searlization = editorContent.map( node => serialize(node) ).join("/n");
  console.log("searlization",searlization);
  // let desearilization = deserialize(new DOMParser().parseFromString(searlization, 'text/html').body);
  //  console.log("desearilization",desearilization  )
  // console.log("edotttt",editor);
  // let children = editor.children;
  // console.log("children",children);
  // let htmlString = ``;
  // let subject = children.filter( ch => ch.type == "paragraph" )[0].children.map( txt => txt.text ).join(" ");
  // console.log("subject",subject);
  // let regards = children.filter( ch => ch.type == "paragraph" )[ children.filter( ch => ch.type == "paragraph" ).length - 1 ].children.map( txt => txt.text ).join(" ");
  // console.log("regards",regards);
  // let bodyElements = children.slice( 1 , children.length  );
  // console.log("body elements",bodyElements);
  // let html = bodyElements.filter( elem => elem.type == "paragraph" ).map( elem => {
  //   console.log("elem",elem);
  //   return `<p> ${elem.children.map( txt => txt.text ).join(" ") } </p>`
  // } ).join("/n");
  // console.log("html",html);
  // console.log("stage",stage);
  let subject = "";
  if ( searlizeEditorContent.length > 0 ) {
    subject= searlizeEditorContent[0].children.map( txt => txt.text ).join(" ");
  }
  axiosHelper({
    url:'salesforce/updateTemplates', 
    method:'PUT', 
    JSONData:{ templates : [  {
      name : "",
      category : "default",
      stage : stage ,
      email : {
        subject : subject ,
        body : searlization ,
        to : "",
        cc : ""
      }
    }]}, 
    urlOverride: false 
  }).then( result => {
     console.log("gpt email",result.data.data);
       setShowSnackBar(true);
  

  } ).catch( err => {
    console.log("error",err);
  } ) 

}
const BasicEditorTS = ({ stage , slateElements }) => {
  const initialValue = useMemo(
    () =>
      JSON.parse(localStorage.getItem('content')) || [
        {
          type: 'paragraph',
          children: [{ text: 'A line of text in a paragraph.' }],
        },
      ],
    []
  );
  // const slateState = useAppSelector((state) => state.slate);
  // console.log("slate State",slateState);
  const globalData = useAppSelector((state) => state.globalState);
  const slateState = useAppSelector((state) => state.slate);
  // console.log("global data", globalData.journey.journeyPath);
  const level = stage ? stage[0]?.toString()?.toLowerCase() : "";
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const [textareavalue, setTextAreaValue] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [ testMailLoading , setTestMailLoading ] = useState(false);
  const [sendMailloading , setSendMailloading] = useState(false);
  // console.log("editorr",editor);
  
  const navigate = useNavigate();
  //@ts-ignore
  const handleChange = (event) => {
    setTextAreaValue(event.target.value);
  };
  return (
    <>
      <SnackBar
        iconName={"paperPlane"}
        subHeading={"Email sent successfully updated."}
        setShowSnackBar={setShowSnackBar}
        show={showSnackBar}
        message="Great!"
        timeout={3000}
        anchor={"top-right"}
        type={"success"}
        bgColorr="#F44545"
      />
      <Box width="100%">
        {/* { globalData.journey.journeyPath == "2" && !slateState.nudgeMode && ( */}
        { localStorage.getItem("type") == "reminder" && ( 
          // @ts-ignore
          <Flex justifyContent={"center"} flexDirection={"column"} alignItems={"center"} height={"100%"} width={"100%"} background={"#EAEDF7"} >
           
           <Slate editor={editor}
        
        onChange={ (value) => {
        console.log("value",value);
        } }
        //@ts-ignore
        value={slateElements}  >
          <Toolbar style={{  
            
            // margin: "10px 0px 0px 0px"  ,
            background  :"#fff",
            width:  "100%",
            textAlign : "center"
            // margin:  "0 auto"
            }}>
            <MarkButton format="bold" icon="format_bold" />
            <MarkButton format="italic" icon="format_italic" />
            <MarkButton format="underline" icon="format_underlined" />
            <MarkButton format="code" icon="code" />
            <BlockButton format="heading-one" icon="looks_one" />
            <BlockButton format="heading-two" icon="looks_two" />
            <BlockButton format="heading-three" icon="looks_3" />
            <BlockButton format="heading-four" icon="looks_4" />
            <BlockButton format="block-quote" icon="format_quote" />
            <BlockButton format="numbered-list" icon="format_list_numbered" />
            <BlockButton format="bulleted-list" icon="format_list_bulleted" />
            <BlockButton format="left" icon="format_align_left" />
            <BlockButton format="center" icon="format_align_center" />
            <BlockButton format="right" icon="format_align_right" />
            <BlockButton format="justify" icon="format_align_justify" />
            <UndoRedoButton format="undo" icon="undo" />
            <UndoRedoButton format="redo" icon="redo" />
            <InsertImageButton />
          </Toolbar>{" "}
          <Flex justifyContent={"flex-start"} alignSelf={"flex-start"} marginLeft={"32%"} flexDirection={"column"} gridRowGap={"10px"}  >
          <CoreBtn
                       background={"transparent !important"}
                      iconAlign="left"
                      iconName="arrowLeft"
                      // variant="confirm"
                      variant="reviewConfirm"
                      color="black"
                      paddingLeft="10px"
                      // alignSelf={"flex-start"}
                      // marginLeft={"32%"}
                      onClick={() => {
                        navigate("/remindertemplate");
                      }}
                    >
                      {" "}
                      Go back{" "}
                    </CoreBtn>
          <Flex flexDirection={"row"} gridColumnGap={"14px"} alignItems={"center"}   >
          <Ic name="mail" stroke="#050505" />
          <Text color={"#57585C"} fontSize={"14x"} fontWeight={"400"} > Email subject </Text>
          </Flex>          
          </Flex>
          


          <Flex
          
            // width={"80%"}
            height={"100%"}
            margin={"0 auto"}
            flexDirection={"column"}
            gridRowGap={"16px"}
            paddingLeft="90px"
            background={"#EAEDF7"}
          >
            <Editable
              style={{ height: "60%" , width : "50%" , margin:  "0 auto"   }}
              // style={{ width : "80%" , margin : "0 auto" }}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              spellCheck
              autoFocus
              onKeyDown={(event) => {
                for (const hotkey in HOTKEYS) {
                  if (isHotkey(hotkey, event as any)) {
                    event.preventDefault();
                    const mark = HOTKEYS[hotkey];
                    toggleMark(editor, mark);
                  }
                }
              }}
            />
            {/* {globalData.journey.journeyPath == "1" && ( */}
              <Flex flexDirection={"column"} gridRowGap={"4px"} style={{ height: "60%" , width : "50%" , margin:  "0 auto" }} >
                <Text color={"#050505"} fontSize={"16px"} fontWeight={"500"}>
                  {" "}
                  Signature{" "}
                </Text>
                <TextArea
                  value={textareavalue}
                  onChange={handleChange}
                  width={"385px"}
                  height={"120px"}
                />


              </Flex>
            {/* )} */}

            <Flex
              flexDirection={"row"}
              gridColumnGap={"16px"}
              alignItems={"center"}
              style={{ height: "60%" , width : "50%" , margin:  "0 auto" }}
            >
              <CoreBtn
                onClick={() => {
                  updateReminderTemplate( localStorage.getItem("category") , setShowSnackBar);
                  // console.log("editore",editor);
                  // let children = editor.children;
                  // console.log("children",children);
                  // navigate(`/emailTemplateSetup/${level}`);
                  // setShowSnackBar(true);
                }}
              >
                {" "}
                Use this template{" "}
              </CoreBtn>
              <CoreBtn
                variant="secondary"
                onClick={() => setShowSnackBar(true)}
              >
                {" "}
                Send test email{" "}
              </CoreBtn>
{/* { globalData.journey.journeyPath == "2" && !slateState.nudgeMode && ( */}
{ localStorage.getItem("type") == "reminder" && ( 
 <CoreBtn variant="reviewConfirm"  >
 Show original copy
 </CoreBtn>
) }
             
            
            </Flex>
          </Flex>
        </Slate>
         </Flex>
        ) }
        
       
        {/* { globalData.journey.journeyPath == "1" && !slateState.nudgeMode && ( */}
        { localStorage.getItem("type") == "email" && ( 
             <Slate editor={editor}
        
            //  onChange={ (value) => {
            //  console.log("value",value);
            //  } }
             //@ts-ignore
            //  value={slateElements} 
            value={initialValue}
            onChange={value => {
              const isAstChange = editor.operations.some(
                op => 'set_selection' !== op.type
              )
              if (isAstChange) {
                // Save the value to Local Storage.
                const content = JSON.stringify(value)
                localStorage.setItem('content', content)
              }
            }}
              >
               <Toolbar style={{  
                 
                 margin: "10px 0px 0px 0px"  ,
              
                 // margin:  "0 auto"
                 }}>
                 <MarkButton format="bold" icon="format_bold" />
                 <MarkButton format="italic" icon="format_italic" />
                 <MarkButton format="underline" icon="format_underlined" />
                 <MarkButton format="code" icon="code" />
                 <BlockButton format="heading-one" icon="looks_one" />
                 <BlockButton format="heading-two" icon="looks_two" />
                 <BlockButton format="heading-three" icon="looks_3" />
                 <BlockButton format="heading-four" icon="looks_4" />
                 <BlockButton format="block-quote" icon="format_quote" />
                 <BlockButton format="numbered-list" icon="format_list_numbered" />
                 <BlockButton format="bulleted-list" icon="format_list_bulleted" />
                 <BlockButton format="left" icon="format_align_left" />
                 <BlockButton format="center" icon="format_align_center" />
                 <BlockButton format="right" icon="format_align_right" />
                 <BlockButton format="justify" icon="format_align_justify" />
                 <UndoRedoButton format="undo" icon="undo" />
                 <UndoRedoButton format="redo" icon="redo" />
                 <InsertImageButton />
               </Toolbar>{" "}
               <Flex
               
                 // width={"80%"}
                 height={"100%"}
                 margin={"0 auto"}
                 flexDirection={"column"}
                 gridRowGap={"16px"}
                 paddingLeft="90px"
                 background={"#EAEDF7"}
               >
                 <Flex flexDirection={"row"} gridColumnGap={"14px"} alignItems={"center"} marginTop={"18px"}   >
          <Ic name="mail" stroke="#050505" />
          <Text color={"#57585C"} fontSize={"14x"} fontWeight={"400"} > Email subject </Text>
          </Flex>
                 <Editable
                   style={{ height: "50%"  }}
                   // style={{ width : "80%" , margin : "0 auto" }}
                   renderElement={renderElement}
                   renderLeaf={renderLeaf}
                   spellCheck
                   autoFocus
                   onKeyDown={(event) => {
                     for (const hotkey in HOTKEYS) {
                       if (isHotkey(hotkey, event as any)) {
                         event.preventDefault();
                         const mark = HOTKEYS[hotkey];
                         toggleMark(editor, mark);
                       }
                     }
                   }}
                 />
                 {/* {globalData.journey.journeyPath == "1" && ( */}
                   {/* <Flex flexDirection={"column"} gridRowGap={"4px"}>
                     <Text color={"#050505"} fontSize={"16px"} fontWeight={"500"}>
                       {" "}
                       Signature{" "}
                     </Text>
                     <TextArea
                       value={textareavalue}
                       onChange={handleChange}
                       width={"385px"}
                       height={"120px"}
                     />
     
     
                   </Flex> */}
                 {/* )} */}
     
                 <Flex
                   flexDirection={"row"}
                   gridColumnGap={"16px"}
                   alignItems={"center"}
                 >
                   <CoreBtn
                     onClick={() => {
                       updateTemplate( localStorage.getItem("stage")  , setShowSnackBar);
                       // console.log("editore",editor);
                       // let children = editor.children;
                       // console.log("children",children);
                       // navigate(`/emailTemplateSetup/${level}`);
                       // setShowSnackBar(true);
                     }}
                   >
                     {" "}
                     Use this template{" "}
                   </CoreBtn>
                   <CoreBtn
                     variant="secondary"
                     onClick={() => setShowSnackBar(true)}
                   >
                     {" "}
                     Send test email{" "}
                   </CoreBtn>
   
                  
                 
                 </Flex>
               </Flex>
             </Slate>
        ) }
       
      </Box>
    </>
  );
};

const initialDocument: Descendant[] = [
  {
    type: "paragraph",
    children: [
      {
        text: "Subject",
      },
    ],
  },
  {
    type: "paragraph",
    children: [
      {
        text: "Body",
      },
    ],
  },
  {
    type: "paragraph",
    children: [
      {
        text: "Regards",
      },
    ],
  },
];

// type CustomElement = {
// type: 'paragraph';
// children: CustomText[];
// };
type CustomElement = {
  type: string;
  children: CustomText[];
  align?: string;
};

type CustomText = {
  text: string;
};
type EmptyText = {
  text: string;
};
type ImageElement = {
  type: "image";
  url: string;
  children: EmptyText[];
};

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

// export default BasicEditorTS;

export default SlateEditor;