import { FC, useEffect, useState } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Info } from "@evabot/eva-react-core-library/dist/molecules/Info/Info";
import { Anchor } from "@evabot/eva-react-core-library/dist/atoms/Anchor/Anchor";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import Image from "../../assets/images/ImageDef.jpeg";
import { Table } from "@evabot/eva-react-core-library/dist/atoms/Table/Table";
import { ToolTip } from "@evabot/eva-react-core-library/dist/molecules/ToolTip/ToolTip";
import { Avatar } from "@evabot/eva-react-core-library/dist/atoms/Avatar/Avatar";
import { useNavigate, useParams } from "react-router-dom";
import { MessageModal } from "@evabot/eva-react-core-library/dist/molecules/MessageModal/MessageModal";
import { useAppSelector, useAppDispatch } from "hooks";
import { setGlobalProgress } from "store/globalStateReducer";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { axiosHelper } from "../../utils";

interface InviteUserProps { }

export const InviteUsersLayout: FC<InviteUserProps> = () => {
  const [stonlyId,setStonlyId]=useState(null);
  const dispatch = useAppDispatch();
  const globalProgress = useAppSelector(state => state.globalState.progress);
  const journey = useAppSelector((state) => state.globalState.journey);
  const navigate = useNavigate();
  const [hide, setHide] = useState(true);
  const [inviteUsersData, setInviteUsersData] = useState([]);
  const [userName,setUserName]=useState([]);

  useEffect(() => {
    const items =localStorage.getItem('jwt_token');
    if (items) {
      setStonlyId(items);
      console.log("stonlyId :",items)
    }
  }, []);

  useEffect(() => {
    axiosHelper({
      url: 'salesforce/getUsersToInvite',
      method: 'GET',
      JSONData: {},
      urlOverride: false
    }).then(res => {
      let inviteData=res.data.data;
      console.log(inviteData);
      setInviteUsersData(inviteData);
      inviteData.forEach((item)=>{
          let local=item.Opportunity.reduce((res,i)=>{
            res.push(i.name)
            return res;
          },[])
          userName.push(local)
      })
      console.log(userName)
    }).catch(err => {
      console.log("error inside invite users", err);
    })
  }, []);

  const modalHandler = () => {
    setHide(true);
  };

  const tableTitle: string[] = [
    "User name",
    "Email",
    "# Opportunities mapped",
    "Total opportunity value",
  ];

  const inviteAllUsers = async () => {
    const result = await axiosHelper({
      url: `salesforce/inviteUser`,
      JSONData: inviteUsersData,
      method: 'POST',
      urlOverride: false
    });
    if(result.data.success) {
      if(globalProgress<100) {
        dispatch(setGlobalProgress(100));
      }
      setHide(false);
    }
    console.log(result.data);
  }
  
  const tableBody = () => {
    return inviteUsersData && inviteUsersData.map((d,index) => {
      return (
        <tr>
          <td>
            <Flex gap="10px" alignItems="center">
              <Avatar
                width="50px"
                height="50px"
                imageUrl={ Image}
                borderRadius="20px"
              />
              <Flex flexDirection="column" gap="3px">
                <Text fontWeight="500" color="#050505" fontSize="14px">
                  {d.Name}
                </Text>
                <Text fontWeight="400" color="#9F9F9F" fontSize="12px">
                  {"VP"}
                </Text>
              </Flex>
            </Flex>
          </td>
          <td style={{ display: "flex", justifyContent: "center" }}>
            <Flex alignItems="center" gap="5px">
              <Icon name={"mail"} stroke="#9F9F9F" />
              <Text fontWeight="400" color="#050505" fontSize="14px">
                {d.Email}
              </Text>
            </Flex>
          </td>
          <td style={{ display: "flex", justifyContent: "flex-start"}}>
            <Flex alignItems="center">
              <ToolTip
                color="black"
                bgColor="#fff"
                direction="right-end"
                borderRadius="12px"
                boxShadow="0px 0px 20px rgba(0, 0, 0, 0.07)"
                title={
                  <>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "flex-start",
                        // width: "220px",
                        borderRadius: "12px",
                        padding: "20px 25px",
                      }}
                    >
                      {userName[index].map((item, i) => {
                        return (
                          <Box
                            borderBottom="1px solid rgba(0, 0, 0, 0.1)"
                            key={i}
                            margin="5px 0px"
                            padding="5px 0px"
                            width="max-content"
                          >
                            <Text fontWeight="500" fontSize="14px">
                              {item}
                            </Text>
                          </Box>
                        );
                      })}
                    </Box>
                  </>
                }
              >
                <Text
                  fontWeight="400"
                  color="#050505"
                  fontSize="14px"
                  textAlign="justify"
                >
                  {d.Opportunity.length}
                </Text>
              </ToolTip>
            </Flex>
          </td>
          <td style={{ display: "flex", justifyContent: "flex-start" }}>
            <Flex alignItems="center">
              <Text
                fontWeight="400"
                color="#050505"
                fontSize="14px"
                textAlign="justify"
              >
                {"$"}{d.TotalValue}
              </Text>
            </Flex>
          </td>
        </tr>
      );
    });
  };
  const tableFooter = () => {
    return <Box background="#FFFFFF" padding="22px" />;
  };
  const [showSnackBar, setShowSnackBar] = useState(false);
  const { email } = useParams();

  useEffect(() => {
    if (email) {
      setShowSnackBar(true);
    }
  }, [email]);

  return (
    <Box
      
      padding="32px 42px"
      overflow="auto"
      height="100vh"
    >
      <MessageModal
        width="440px"
        height="300px"
        hideModal={hide}
        setHideModal={modalHandler}
        title="Invitation successfully sent!"
        message={`Invitation emails are sent to ${inviteUsersData.length} users. All they have to do is click on the link shared in the email and sign in.`}
        type="success"
        action={true}
        modalButtonHandler={() => navigate("/admin1")}
      >
        { }
      </MessageModal>
      <SnackBar
        iconName={"paperPlane"}
        subHeading={"Email sent successfully updated."}
        setShowSnackBar={setShowSnackBar}
        show={showSnackBar}
        message="Great!"
        timeout={3000}
        anchor={"top-right"}
        type={"success"}
        bgColorr="#24B300"
      />
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        marginBottom="20px"
      >
        <Flex flexDirection="column" gap="10px">
          <Flex alignItems="baseline" gap="10px">
            <Text
              id="invite.users.title"
              fontSize="24px"
              color="#050505"
              fontWeight="400"
            />
            <Text

              fontSize="14px"
              color="#050505"
              fontWeight="200"
            >{"( Total - "}{inviteUsersData.length}{" users selected)"}</Text>
          </Flex>
          <Info locale="invite.users.infomessage" color="#1FA1FF" />
        </Flex>
        <Flex alignItems="baseline">
          <Text fontSize="12px" fontWeight="400">
            Having troubles?
          </Text>
          <Anchor text="Get Help" link="" id={stonlyId}/>
        </Flex>
      </Flex>
      <Table
        bodyHeight="400px"
        // bodyHeight={inviteUsersData?.length > 3 ? "300px" : " max-content"}
        caption=""
        width="100%"
        titles={tableTitle}
        borderLess
        rows={tableBody}
        footer={tableFooter}
      />
      <Flex flexDirection="row" justifyContent="flex-end" gap="16px" mt="54px">
        <Button
          iconAlign="left"
          iconName="arrowLeft"
          variant="secondary"
          title="previous.step"
          onClick={() => {
            if (
              journey?.journeyName === "admin" &&
              journey?.journeyPath === "2"
            ) {
              navigate("/reminderTemplate");
            } else navigate("/emailTemplateSetup");
          }}
        />
        <Button
          iconAlign="right"
          iconName="arrowRight"
          title="invite.users.button"
          onClick={() => {
            inviteAllUsers();
            // setHide(!hide)
          }}
        />
      </Flex>
    </Box>
  );
};
