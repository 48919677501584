import * as React from "react";

function CompanyDomainIcon(props) {
  return (
    <svg
      width={props?.size || "16px"}
      height={props?.size || "16px"}
      viewBox="0 0 16 17"
      fill="none"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_1_2891)" fill="#000">
        <path d="M9.333 6.56l5.874 1.167c.473.1.793.5.793.973v6.8c0 .553-.447 1-1 1H9a.33.33 0 00.333-.333v-.334H15c.18 0 .333-.146.333-.333V8.7a.335.335 0 00-.26-.327L9.333 7.24v-.68z" />
        <path d="M13 11.833a.33.33 0 01.333.334.33.33 0 01-.333.333h-1.333a.33.33 0 01-.334-.333.33.33 0 01.334-.334H13zM13 13.833a.33.33 0 01.333.334.33.33 0 01-.333.333h-1.333a.33.33 0 01-.334-.333.33.33 0 01.334-.334H13zM9.333 16.167A.33.33 0 019 16.5a.33.33 0 01-.333-.333V6.833c0-.1.046-.193.12-.26a.35.35 0 01.28-.066l.266.053v9.607z" />
        <path d="M8.667 15.833v.334A.33.33 0 009 16.5H6.333a.33.33 0 00.334-.333v-.334h2zM6 12.833a.334.334 0 00-.333-.333h-2a.33.33 0 00-.334.333v3h-.666v-3c0-.553.446-1 1-1h2c.553 0 1 .447 1 1v3H6v-3z" />
        <path d="M3.333 15.833H6.667v.334a.33.33 0 01-.334.333H3a.33.33 0 01-.333-.333v-.334h.666z" />
        <path d="M1.167.633l7.326 1.114c.487.08.84.493.84.986V6.56l-.266-.053a.35.35 0 00-.28.066.354.354 0 00-.12.26v-4.1a.337.337 0 00-.28-.333L1.06 1.293c-.02-.006-.04-.006-.06-.006a.314.314 0 00-.213.08.319.319 0 00-.12.253V15.5c0 .187.153.333.333.333h1.667v.334A.33.33 0 003 16.5H1c-.553 0-1-.447-1-1V1.62c0-.293.127-.573.353-.76a1 1 0 01.814-.227z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1_2891">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CompanyDomainIcon;
