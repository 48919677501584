import { FC, useState } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { VideoDemo } from "@evabot/eva-react-core-library/dist/molecules/VideoDemo/VideoDemo";
import { Header } from "@evabot/eva-react-core-library/dist/molecules/Header/Header";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { AccordionCard } from "../../components/Accordion/AccordionCard";
import AEListOpportunityAccordianSubSection1 from "./AEListOpportunityAccordianSubSectionOption1";
import { ProgressCard } from "@evabot/eva-react-core-library/dist/molecules/ProgressCard/ProgressCard";
import { AccordionHeader } from "components/Accordion/AccordianHeader";
import Ae1 from "assets/images/Ae1.png"


interface OpportunityProps { }

const AEListOpportunityOption1: FC<OpportunityProps> = () => {
    const [toggle, setToggle] = useState<boolean>(false);
    const handleToggle = () => {
        setToggle(!toggle)
    }
    return (
        <Box>
            <Header>
                <Flex alignItems="center" gap="10px">
                    <VideoDemo locale="seedemo" setToggle={handleToggle} />
                </Flex>
            </Header>
            <Box
                height="100vh"
                overflow="auto"
                backgroundColor="#EAEDF7"
                padding="20px 40px"
            >
                <ProgressCard
                    subText={"Total 3/12 email sent"}
                    containerWidth="88vw"
                    containerBg="#8D68F6"
                    iconName="mailPlane"
                    percentageComplete={8}
                />
                <Flex flexDirection="column" gap="10px" margin="25px 0px 15px 0px">
                    <Flex gap="15px">
                        <Box
                            backgroundColor="#16124C"
                            borderRadius="8px"
                            padding="10px 15px"
                        >
                            <Text
                                fontWeight="500"
                                fontSize="16px"
                                color="#fff"
                            >Number of opportunities: 3</Text>
                        </Box>
                        <Box
                            backgroundColor="#1B9788"
                            borderRadius="8px"
                            padding="10px 15px"
                        >
                            <Text
                                fontWeight="500"
                                fontSize="16px"
                                color="#fff"
                            >Number of stakeholders: 12</Text>
                        </Box>
                    </Flex>
                    <Text
                        fontWeight="400"
                        fontSize="12px"
                    >
                        Your admin has approved the following opportunities. Let's begin by sending the first email to the important stakeholders.</Text>
                </Flex>
                <Flex flexDirection="column" gap="20px">
                    <AccordionCard
                        headerSection={() =>
                            <AccordionHeader
                                opportunityName="South region"
                                headerImage={Ae1}
                                companyName="Compass Press"
                                value="$450K"
                                opportunityStage="Verbal"
                                emailSended={1}
                                totalStakeholders="5"
                                currentEmailStatus="pending"
                                type="AE2"
                                emailIconVisible={true}
                            />}
                        subSection={() => <AEListOpportunityAccordianSubSection1 />}
                    />
                </Flex>
            </Box>
        </Box>
    )
}

export default AEListOpportunityOption1;