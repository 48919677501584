import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { useAppSelector } from "hooks";
import { useState } from "react";
import { useDispatch } from "react-redux";
import stakeHolderTagService from "services/stakeholderTagService";
import { setStakeholderTagData } from "./store/stakeholdertagReducer";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";

function StakeholderTagTableBodyField({ data, index }) {

    const dispatch = useDispatch();

    const allStakeholderTagData = useAppSelector(
        (state) => state.stakeholderTagState?.stakeholderTagData
    );

    const deleteTag = () => {
        const updatedStakeholderTagData = allStakeholderTagData.filter(
            (tag) => tag.tagId !== data.tagId
        );
        stakeHolderTagService.deleteTag(data.tagId).then((response) => {
            dispatch(setStakeholderTagData(updatedStakeholderTagData));
            setHide(true);
        });

    };

    const getTagListByStatus = (status: any) => {
        setListStatus(status);
        stakeHolderTagService.getTagListByStatus(data.tagId, status).then((response) => {
            console.log("response", response);
            setTagStatusList(response.data);
            setTagListHide(false);
            //dispatch(setStakeholderTagData(updatedStakeholderTagData));
            //setHide(true);
        });
    }

    const dateTag: any = new Date(data?.requestedOn);
    const dateProcessed: any = data?.processedAt ? new Date(data?.processedAt) : "";
    const [hide, setHide] = useState(true);
    const [tagListhide, setTagListHide] = useState(true);
    const [tagStatusList, setTagStatusList] = useState([]);
    const dateOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    };
    const [listStatus,setListStatus]=useState("");


    return (
        <>
            <ModalWrapper
                width="max-content"
                height="max-content"
                hideModal={hide}
                setHideModal={() => {
                    setHide(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="48px"
            >
                <Flex
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Text mt="24px" fontSize={"24px"} fontWeight={300}>
                        {" "}
                        Are you sure you want to remove this Tag ?
                    </Text>
                    <Flex mt="15px">
                        <Flex
                            background={"#DD015B"}
                            color={"#fff"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => deleteTag()}
                        >
                            Yes
                        </Flex>
                        <Flex 
                            ml="16px"
                            border="1px solid #0094E3"
                            background={"#fff"}
                            color={"#0094E3"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => setHide(true)}
                        >
                            Cancel
                        </Flex>
                    </Flex>
                </Flex>
            </ModalWrapper>

            <ModalWrapper
                width="400px"
                height="max-content"
                hideModal={tagListhide}
                setHideModal={() => {
                    setTagListHide(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="16px"
            >
                <Flex
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                    justifyContent={"flex-start"}

                >
                    <Text textTransform={"capitalize"} marginBottom={"16px"} fontSize={"16px"} fontWeight={300}>
                        {" "}
                       {listStatus} Prospect List
                    </Text>
                    <Flex
                        padding={"10px 15px"}
                        fontSize="12px"
                        fontWeight={500}
                        position="sticky"
                        backgroundColor={"#F8F8F8"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        width="400px"
                    >
                        <Flex width="20%" justifyContent={"center"} alignItems={"center"}>
                            #
                        </Flex>
                        <Flex width="80%" justifyContent="left" alignItems="flex-start">
                            Prospect Email
                        </Flex>
                    </Flex>
                    <Box overflow={"scroll"} height={"500px"}>
                        {tagStatusList.map((tag, index) => (
                            <Flex
                                padding="15px"
                                fontSize="12px"
                                fontWeight={500}
                                position="sticky"
                                top="0" /* Add a 'top' value to specify when the element becomes sticky */
                                backgroundColor="#fff"
                                justifyContent="center"
                                alignItems="center"
                                width="400px"
                            >
                                <Flex width="20%" justifyContent="center" alignItems="center">
                                    {index + 1}
                                </Flex>
                                <Flex width="80%" justifyContent="left" alignItems="flex-start">
                                    {tag.prospectEmail}
                                </Flex>

                            </Flex>
                        ))}
                    </Box>
                </Flex>
            </ModalWrapper>
            <Flex
                padding={"15px"}
                fontSize="12px"
                fontWeight={500}
                position="sticky"
                backgroundColor={"#Fff"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <Flex width="5%" justifyContent={"center"} alignItems={"center"}>
                    {index + 1}
                </Flex>
                <Flex width="10%" justifyContent={"center"} alignItems={"center"}>
                    {data?.tag || "-"}
                </Flex>
                <Flex width="20%" justifyContent={"center"} alignItems={"center"}>
                    {data?.ownerName || "-"}
                </Flex>
                <Flex width="10%" justifyContent={"center"} alignItems={"center"}>
                    {data?.status || "-"}
                </Flex>
               
                <Flex width="15%" justifyContent={"center"} alignItems={"center"}>
                    {data?.totalProcess ? (<Text cursor="pointer" textDecoration="underline" color={"blue"} onClick={() => getTagListByStatus("processed")}>{data.totalProcess}</Text>) : (
                        <Text>-</Text>
                    )}
                </Flex>
                <Flex width="15%" justifyContent={"center"} alignItems={"center"}>
                    {data?.totalError ? (<Text cursor="pointer" textDecoration="underline" color={"blue"} onClick={() => getTagListByStatus("error")}>{data.totalError}</Text>) : (
                        <Text>-</Text>
                    )}
                </Flex>
                <Flex width="15%" justifyContent={"center"} alignItems={"center"}>
                    {dateProcessed ? dateProcessed.toLocaleDateString('en-US', dateOptions) : "-"}
                </Flex>
                <Flex width="15%" justifyContent={"center"} alignItems={"center"}>
                    {dateTag.toLocaleDateString('en-US', dateOptions) || "-"}
                </Flex>
                {data?.status === "queued" ? (<Flex
                    width="15%"
                    justifyContent={"center"}
                    alignItems={"center"}
                    cursor="pointer"
                    color="#9F9F9F"
                    onClick={() => setHide(false)}
                >
                    Remove
                </Flex>) : <Flex
                    width="15%"
                    justifyContent={"center"}
                    alignItems={"center"}
                    cursor="pointer"
                    color="#9F9F9F"
                >

                </Flex>}
            </Flex>
        </>
    );
}

export default StakeholderTagTableBodyField;
