import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import React, { useEffect, useState } from "react";

function FrameworkAndContextTableHeader() {

  return (
    <>
      <Flex
        padding={"10px 15px"}
        fontSize="12px"
        fontWeight={500}
        position="sticky"
        backgroundColor={"#F8F8F8"}
        alignItems={"center"}
        borderTopLeftRadius={"8px"}
        borderTopRightRadius={"8px"} 
        className="table-data-framework-context"
      >
        <Flex padding={"0 5px"} width="5%" margin="0 0 0 16px">
          #
        </Flex>
        <Flex padding={"0 5px"} width="15%" >
          Name
        </Flex>
        <Flex padding={"0 5px"} width="10%">
          Type
        </Flex>
        <Flex padding={"0 5px"} width="5%">
        </Flex>
        <Flex padding={"0 5px"} width="25%">
          Description
        </Flex>
        <Flex padding={"0 5px"} width="5%">
        </Flex>
        <Flex padding={"0 5px"} width="10%" >
          Shared
        </Flex>
        <Flex padding={"0 5px"} width="15%" >
          Created By
        </Flex>
        <Flex padding={"0 5px"} width="10%" margin="0 16px 0 0" >
          Actions
        </Flex>
      </Flex>
    </>
  );
}

export default FrameworkAndContextTableHeader;
