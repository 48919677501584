import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
// import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";

interface AeReturnStakeTableHeaderProps {}

const AeReturnStakeTableHeader: React.FC<
  AeReturnStakeTableHeaderProps
> = () => {
  return (
    <Grid
      margin={"0px 15px"}
      fontSize="12px"
      fontWeight={500}
      position="sticky"
      color="#57585C"
      mr="35px"
    >
      <tr>
        <Box width={"5%"}>
          <td>#</td>
        </Box>
        <Box width={"15%"}>
          <td>Stakeholder name</td>
        </Box>
        <Box width="20%">
          <td>Email</td>
        </Box>
        <Flex width="15%" justifyContent={"center"}>
          <td>
            <Flex justifyContent={"center"}>Company domain</Flex>
          </td>
        </Flex>
        <Flex width="10%" justifyContent={"center"}>
          <td>
            <Flex justifyContent={"center"}>Profile creation date</Flex>
          </td>
        </Flex>
        <Flex width="10%" justifyContent={"center"}>
          <td>
            <Flex justifyContent={"center"}>Enable/disable nudges</Flex>
          </td>
        </Flex>
      </tr>
    </Grid>
  );
};

export default AeReturnStakeTableHeader;
