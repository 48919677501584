import { FC } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import SendOpportunityTableOption1 from "./SendOpportunityTableOption1"
import SendOpportunityFooter from "./SendOpportunityFooter";

interface AEListOpportunityAccordionSubSection { }

const SendOpportunityOption1AccordionSubHeader: FC<AEListOpportunityAccordionSubSection> = () => {
  return <Box>
   <SendOpportunityTableOption1/>
   <SendOpportunityFooter/>
  </Box>;
}

export default SendOpportunityOption1AccordionSubHeader