import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { Tag } from "@evabot/eva-react-core-library/dist/molecules/Tag/Tag";
import LinkedInIcon from "assets/icons/LinkedInIcon";
import { useAppSelector } from "hooks";
import { useState } from "react";
import { axiosHelperCore } from "utils";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";

function ProspectReportTableBodyField({ ProspectData, index, reFetch }) {

    const profileData = useAppSelector(
        (state) => state.profileState.profileData
    );

    const isSuperAdminView = localStorage.getItem("superadminview");

    const DateString = ProspectData?.processedAt;
    const date = new Date(DateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
    const formattedDate = `${day}/${month}/${year}`;
    const [isShowMoreText, setIsShowMoreText] = useState(false);
    const [hideDeleteModel, setHideDeleteModel] = useState(true);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');

    const isDummySubdomain = (url = '') => {
        if (typeof url !== 'string') {
            return false;
        }
        const regex = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/;
        const match = url.match(regex);
        const domainParts = match ? match[1].split('.') : null;
        const subdomains = domainParts ? domainParts.slice(0, -2) : [];
        const dummySubdomains = ['dummy'];
        return subdomains.some(sub => dummySubdomains.includes(sub.toLowerCase()));
    };

    const capitalizeFirstLetter = (string) => {
        return string && string[0].toUpperCase() + string.slice(1);
    };

    const deleteProspect = async () => {
        const response: any = await axiosHelperCore({
            url: "rapport/user/deleteProspect",
            method: "POST",
            JSONData: {
                userId: ProspectData.userId,
                stakeholderEmail: ProspectData.email,
                stakeholderId: ProspectData.stakeholderId,
            },
            urlOverride: false,
        });

        console.log("responseresponseresponse", response);

        if (response?.data?.success) {
            setSnackbarMessage('Prospect has been removed');
            setSnackbarType('success');
            setShowSnackBar(true);
            reFetch();
        } else {
            setSnackbarMessage('Error removing prospect');
            setSnackbarType('error');
            setShowSnackBar(true);
        }
        setHideDeleteModel(true);

    }

    return (
        <>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            <Flex
                padding={"15px"}
                fontSize="12px"
                fontWeight={500}
                position="sticky"
                backgroundColor={index % 2 != 0 ? "#FFF" : "rgb(249, 249, 249)"}
                alignItems={"center"}
                borderRadius={index % 2 == 0 && "12px"}
                margin="0 16px"
            >
                <Flex padding={"0 5px"} width="5%" alignItems={"center"} >
                    {index}
                </Flex>

                <Flex padding={"0 5px"} width="20%" alignItems={"center"}>
                    <Flex flexDirection={"column"} gap={"5px"} padding={"0 5px"} alignItems={"flex-start"}>
                        <Flex gap="10px">
                            <Text wordBreak={"break-word"} textAlign={"left"}>{ProspectData?.name || "-"}</Text>
                            {
                                ProspectData?.name && ProspectData?.name?.trim() !== "" && !isDummySubdomain(ProspectData?.linkedInUrl) &&
                                <div> <a target="_blank" href={ProspectData?.linkedInUrl}>{LinkedInIcon()}</a></div>
                            }
                        </Flex>
                        <Flex>
                            <Text wordBreak={"break-word"}> {ProspectData?.email || "-"}</Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex width={"5%"}></Flex>

                <Flex padding={"0 5px"} width="15%" alignItems={"center"} >
                    {ProspectData?.ownerName || "-"}
                </Flex>
                {
                    (profileData.role === "superadmin" && isSuperAdminView) && (
                        <>
                            <Flex flexDirection={"column"} padding={"0 5px"} width="10%" alignItems={"flex-start"} justifyContent={"center"}>
                                <Flex>SearchTerm: <Text mb={"6px"} fontWeight={"800"}>{ProspectData?.searchTermStatus}</Text></Flex>
                                <Flex>RelationShip: <Text mb={"6px"} fontWeight={"800"}>{ProspectData?.realtionshipStatus}</Text></Flex>
                                <Flex>Date:<Text fontWeight={"800"} mb={"6px"}>{formattedDate}</Text></Flex>
                            </Flex>
                        </>
                    )
                }

                {
                    (profileData.role !== "superadmin" || (profileData.role === "superadmin" && !isSuperAdminView)) && (
                        <Flex padding={"0 5px"} width="35%" alignItems={"center"} justifyContent={"center"}>
                            <Text mb={"6px"}>{formattedDate}</Text>
                        </Flex>
                    )
                }
                <Flex width={"5%"}></Flex>
                {profileData.isExaSummary && <Flex padding={"0 5px"} width={"15%"} alignItems={"center"} justifyContent={"center"}>
                    <Box width={"95%"} >
                        {ProspectData?.exaPersonSummary?.length > 200 ? (
                            <>
                                {isShowMoreText ? ProspectData?.exaPersonSummary : ProspectData?.exaPersonSummary?.substring(0, 150) + "..."}
                                <br></br>
                                <a href="#" onClick={(event) => {
                                    event.preventDefault();
                                    setIsShowMoreText(!isShowMoreText);
                                }}
                                    style={{ color: '#0094E3' }}
                                >
                                    {!isShowMoreText ? "view more" : "view less"}
                                </a>
                            </>
                        ) : (
                            <div style={{ textAlign: "center" }}>{ProspectData?.exaPersonSummary || "-"}</div>
                        )}
                    </Box>

                </Flex>}

                <Flex padding={"0 5px"} width="10%" alignItems={"center"} justifyContent={"center"}>
                    <Text>
                        {ProspectData?.status === 'processed' && <Tag
                            color="#60C678"
                            bgColor="rgba(239, 249, 241, 1)"
                            text="Enriched"
                        />}

                        {ProspectData?.status === 'error' && <Tag
                            color="#EF4748"
                            bgColor="rgba(249, 239, 238, 1)"
                            text="Private LinkedIn"
                        />}

                        {ProspectData?.status !== 'processed' && ProspectData?.status !== 'error' && <Tag
                            color="#0094E3"
                            bgColor="rgba(220, 249, 255, 1)"
                            text={capitalizeFirstLetter(ProspectData?.status)}
                        />}
                        {ProspectData?.status === ' ' && "-"}
                    </Text>
                </Flex>
                <Flex width={"10%"}>
                    <Button onClick={() => { setHideDeleteModel(false) }} size="s" variant="primary" borderRadius={"15px"} marginLeft={"10px"}>
                        Delete
                    </Button>
                </Flex>
            </Flex>
            <ModalWrapper
                width="max-content"
                height="max-content"
                hideModal={hideDeleteModel}
                setHideModal={() => {
                    setHideDeleteModel(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="40px"
            >
                <Flex
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Text fontSize={"20px"} fontWeight={300}>
                        {" "}
                        Are you sure you want to remove this prospect ?
                    </Text>
                    <Flex mt="15px">
                        <Flex
                            background={"#DD015B"}
                            color={"#fff"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={deleteProspect}
                        >
                            Yes
                        </Flex>
                        <Flex
                            ml="16px"
                            border="1px solid #0094E3"
                            background={"#fff"}
                            color={"#0094E3"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => setHideDeleteModel(true)}
                        >
                            Cancel
                        </Flex>
                    </Flex>
                </Flex>
            </ModalWrapper>
        </>
    );
}

export default ProspectReportTableBodyField;


