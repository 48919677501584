import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

const initialState = {
  editFormVisible : false,
  editFormData : null,
};

export interface EmailTemplateReducerType {}
const emailTemplateReducerSlice = createSlice({
  name: "emailTemplateState",
  initialState: initialState,
  reducers: {
    setEditFormVisibility: (state, action: PayloadAction<boolean>) => {
      state.editFormVisible = action.payload;
    },
    setEditFormData: (state, action: PayloadAction<any>) => {
      state.editFormData = action.payload;
    },
  },
});

export const {
  setEditFormVisibility,
  setEditFormData,
} = emailTemplateReducerSlice.actions;

export const emailTemplateState = (state: RootState) => state.emailTemplateState;

export default emailTemplateReducerSlice.reducer;
