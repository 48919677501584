import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import React, { FC } from "react";

export interface AccordionHeadingLabelProps {
  value: string;
}

export const AccordionHeadingLabel: FC<AccordionHeadingLabelProps> = ({
  value,
}) => {
  return (
    <Text variant={"bodyRegular"} color="textColor.secondary">
      {value}
    </Text>
  );
};
