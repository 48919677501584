import React, { useEffect, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Flex } from '@evabot/eva-react-core-library/dist/atoms/Flex/Flex';
import { Button } from '@evabot/eva-react-core-library/dist/atoms/Button/Button';
import { Box } from '@evabot/eva-react-core-library/dist/atoms/Box/Box';
import { ToggleButton } from '@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton';
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Tag } from '@evabot/eva-react-core-library/dist/molecules/Tag/Tag';
import userService from 'services/userService';
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Backdrop } from "@mui/material";
import { ModalWrapper } from '@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper';
import * as yup from 'yup';
import { FormikTextArea } from '@evabot/eva-react-core-library/dist/atoms/FormikTextArea/FormikTextArea';
import EditButton from './component/EditButton';
import DeleteButton from './component/DeleteButton';

const showMoreLessLinkStyle = {
    color: "rgb(0, 148, 227)",
    cursor: "pointer",
    textDecoration: "underline",
    marginLeft: "10px",
};

const AddFrameworkAndContextSchema = yup.object().shape({
    name: yup.string().required("Name is required."),
    content: yup.string().required("Content is required."),
});

function FrameworkAndContextTableBodyField({ index, item, reFetch }) {
    const [hideModal, setHideModal] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [hide, setHide] = useState(true);

    const [showMore, setShowMore] = useState(false);
    const maxLength = 50;

    const content = item.type === "framework" ? item?.frameworkValue : item?.contextValue;
    const displayContent = showMore ? content : content?.slice(0, maxLength);

    const openEditFormpopup = () => {
        setHideModal(false);
    }

    const handlePublicPrivateStatus = async (updatedStatus, id, type, userId) => {

        setLoading(true);
        let dataObj = {};
        if (type === "framework") {
            dataObj = { "userId": userId, "frameworkId": id, "contextId": null, "isPublic": updatedStatus, "type": type };
        } else {
            dataObj = { "userId": userId, "frameworkId": null, "contextId": id, "isPublic": updatedStatus, "type": type };
        }

        try {
            const updateMember = await userService.updateFrameworkOrContextStatus(dataObj);
            if (updateMember?.success) {
                reFetch();
                setLoading(false);
                setSnackbarMessage('Status Changed');
                setShowSnackBar(true);
                setSnackbarType('success');
            } else {
                setSnackbarMessage('Error Changing Status');
                setShowSnackBar(true);
                setSnackbarType('error');
                setLoading(false);
            }
        } catch (error) {
            console.error("Error updating member", error);
            setLoading(false);
        }

    };

    const handleUpdateContextAndFramework = async(values) => {
        const obj = {"id" : values.id, "userId" : values.userId, "name" : values.name , "action" : "update"};
        if(values.type === "framework" ){
            obj["framework"] = values.content;
            try {
                const updateFramework = await userService.updateOrDeleteFramework(obj);
                updatingMessage(updateFramework);
            } catch (error) {
                console.error("Error updating member", error);
                setLoading(false);
            }
        }else{
            obj["context"] = values.content;
            try {
                const updateContext = await userService.updateOrDeleteContext(obj);
                updatingMessage(updateContext);
            } catch (error) {
                console.error("Error updating member", error);
                setLoading(false);
            }
        }
    }

    const deleteData = async(values) => {
        console.log(values);
        const obj = {"id" : values.objectId, "userId" : values.userId, "name" : values.name , "action" : "delete"};
        if(values.type === 'framework'){
            obj["framework"] = values.content;
            const updateFramework = await userService.updateOrDeleteFramework(obj);
            updatingMessage(updateFramework, true);
        }else{
            obj["context"] = values.content;
            const updateContext = await userService.updateOrDeleteContext(obj);
            updatingMessage(updateContext, true);
        }
    }

    const updatingMessage = (msg, isDeleted = false) => {
        if (msg?.success) {
            reFetch();
            setLoading(false);
            setSnackbarMessage(isDeleted ? 'Remove Framework/Context' : 'Framework/Context Updated');
            setShowSnackBar(true);
            setSnackbarType('success');
            setHideModal(true);
        } else {
            setSnackbarMessage(isDeleted ? 'Error Deleting Framework/Context' : 'Error Updating Framework/Context');
            setShowSnackBar(true);
            setSnackbarType('error');
            setLoading(false);
        }
        setHide(true);
    }

    return (
        <>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            {loading && <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                open={true}
            >
                <Icon name="loadingIcon" />
            </Backdrop>}
            <Flex
                padding={"15px"}
                fontSize="12px"
                fontWeight={500}
                position="sticky"
                backgroundColor={index % 2 == 0 ? "#FFF" : "rgb(249, 249, 249)"}
                alignItems={"center"}
                borderRadius={index % 2 != 0 && "12px"}
                margin="0 16px"
            >
                <Flex padding={"0 5px"} textWrap="balance" width="5%" >
                    {index + 1}
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="15%" >
                    {item?.type === "framework" ? item?.frameworkName : item?.contextName}
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="10%" flexDirection={"column"}>
                    <Tag
                        color={item.type == "framework" ? "#60C678" : "#0094E3"}
                        bgColor={item.type == "framework" ? "#EFF9F1" : "#DCF9FF"}
                        text={`${item.type} : ${item.linkedinOremail || 'N/A'}`}
                    />
                    {/* {item.linkedinOremail &&
                    <Tag
                        color={item.linkedinOremail == "email" ? "#DD015B" : "#B027E1"}
                        bgColor={item.linkedinOremail == "email" ? "rgba(221, 1, 91, 0.1)" : "rgba(176, 39, 225, 0.1)"}
                        text={item.linkedinOremail}
                    />} */}
                </Flex>
                <Flex padding={"0 5px"} width="5%">
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="25%">
                    <Text>
                        {displayContent}
                        {content?.length > maxLength && !showMore && (
                            '...'
                        )}
                        {content?.length > maxLength && (
                            <>
                                {showMore && (
                                    <span  onClick={() => setShowMore(!showMore)} style={showMoreLessLinkStyle}>
                                        view less
                                    </span>
                                )}
                                {!showMore && (
                                    <span onClick={() => setShowMore(!showMore)} style={showMoreLessLinkStyle}>
                                        view more
                                    </span>
                                )}
                            </>
                        )}
                    </Text>
                </Flex>
                <Flex padding={"0 5px"} width="5%">
                </Flex>
                <Flex padding={"0 5px"} width="10%" >
                    <ToggleButton
                        variant='tertiary'
                        value={item?.isPublic}
                        name='isActive'
                        onChange={(value) => handlePublicPrivateStatus(value, item?.id, item?.type, item.userId)}
                    />
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="15%" flexDirection={"column"}>
                    <Flex style={{ "textTransform": "capitalize" }} mb={"5px"}>
                        {item.firstName} {item.lastName}
                    </Flex>
                    <Flex>
                        {item.email}
                    </Flex>
                </Flex>
                <Flex padding={"0 5px"} width="10%" gap='10px'>
                    {/* <Button size='s' onClick={openEditFormpopup} variant="secondary">
                        Edit
                    </Button> */}
                    <EditButton onClick={openEditFormpopup}/>
                    <DeleteButton onClick={() => setHide(false)}/>

                </Flex>
            </Flex>


            <ModalWrapper
                width="max-content"
                height="max-content"

                hideModal={hideModal}
                setHideModal={() => {
                    setHideModal(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="24px"
            >

                <>
                    <SnackBar
                        iconName={"info"}
                        message={snackbarMessage}
                        setShowSnackBar={setShowSnackBar}
                        show={showSnackBar}
                        timeout={4000}
                        anchor={"top-right"}
                        type={snackbarType}
                    />
                    <Text fontWeight="400" fontSize="24px" marginBottom={4}>Update Framework/Context</Text>
                    <Formik
                        key={isModalOpen.toString()}
                        initialValues={{
                            name: item?.frameworkName || item?.contextName || '',
                            content: content || '',
                            userId : item?.userId,
                            id : item?.objectId,
                            type : item?.type,
                        }}
                        onSubmit={(values) => {
                            console.log('Submitting edited data:', values);
                            handleUpdateContextAndFramework(values);
                        }}
                        validationSchema={AddFrameworkAndContextSchema}

                    >
                        {({ setFieldValue, values }) => (
                            <Form>
                                <Flex flexDirection="column">
                                    <Flex padding={"5px 10px"} flexDirection="column">
                                        <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Name</Text>
                                        <FormikInput
                                            name="name"
                                            // placeholder="Name"
                                        />
                                    </Flex>
                                    <Flex padding={"5px 10px"} flexDirection="column">
                                        <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Content</Text>
                                        <FormikTextArea
                                            name="content"
                                            // placeholder="Content"
                                            width={"90%"}
                                        />
                                    </Flex>
                                    <Flex marginRight={1} marginTop={3} padding={"5px 10px"}>
                                        <Flex justifyContent={"left"}>
                                            <Button type="submit">Update</Button>
                                        </Flex>
                                        <Flex justifyContent={"right"}>
                                            <Button variant="secondary" type="button" onClick={() => { setHideModal(true); }} marginLeft={2}>
                                                Cancel
                                            </Button>
                                        </Flex>
                                    </Flex>

                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </>
            </ModalWrapper>

            <ModalWrapper
                width="max-content"
                height="max-content"
                hideModal={hide}
                setHideModal={() => {
                    setHide(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="40px"
            >
                <Flex
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Text fontSize={"20px"} fontWeight={300}>
                        {" "}
                        Are you sure you want to remove this {item.type} ?
                    </Text>
                    <Flex mt="15px">
                        <Flex
                            background={"#DD015B"}
                            color={"#fff"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => deleteData(item)}
                        >
                            Yes
                        </Flex>
                        <Flex 
                            ml="16px"
                            border="1px solid #0094E3"
                            background={"#fff"}
                            color={"#0094E3"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => setHide(true)}
                        >
                            Cancel
                        </Flex>
                    </Flex>
                </Flex>
            </ModalWrapper>
        </>
    );
}

export default FrameworkAndContextTableBodyField;
