import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

const initialState = {
  selectedNudge: {},
  birthdayNudge: {
    category: "",
    addInstruction: "",
    notes: "",
  },
  sportsNudge: {
    category: "",
    addInstruction: "",
    notes: "",
  },
};

interface selectedNudgeReducerSliceType {
  nudge: any;
}

interface nudgeType {
  category: string;
  addInstruction: string;
  notes: string;
}

const selectedNudgeReducerSlice = createSlice({
  name: "selectedNudge",
  initialState: initialState,
  reducers: {
    setSelectedNudgeForUnlike: (
      state,
      action: PayloadAction<selectedNudgeReducerSliceType>
    ) => {
      state.selectedNudge = action.payload;
    },
    setBirthdayNudge: (state, action: PayloadAction<nudgeType>) => {
      state.birthdayNudge = action.payload;
    },
    setSportsNudge: (state, action: PayloadAction<nudgeType>) => {
      state.sportsNudge = action.payload;
    },
  },
});

export const { setSelectedNudgeForUnlike, setBirthdayNudge, setSportsNudge } =
  selectedNudgeReducerSlice.actions;

export const aeReturnUnlikeModalState = (state: RootState) => state.globalState;

export default selectedNudgeReducerSlice.reducer;
