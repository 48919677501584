import React, { useCallback, FC, useEffect, useState } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { ExpandableCard } from "@evabot/eva-react-core-library/dist/molecules/ExpandableCard/ExpandableCard";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { useAppDispatch } from "../../hooks";
import {
  setFieldMappingActivity,
  setFieldMappingActivityDefault,
  FieldMappingActivityState,
} from "./store/fieldMappingReducer";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Dropdown } from "@evabot/eva-react-core-library/dist/atoms/Dropdown/Dropdown";
import { fieldMappingSalesforceNameMapper } from "utils";
import { setInitialValue, valueChange } from "utils/constants";

const activitySchema = yup.object().shape({
  whoId: yup.string().required("fieldmapping.activties.whoId.valid"),
  whatId: yup.string().required("fieldmapping.activties.whatId.valid"),
  activityName: yup.string().required("fieldmapping.activties.name.valid"),
  activityDescription: yup
    .string()
    .required("fieldmapping.activties.description.valid"),
  activityType: yup.string().required("fieldmapping.activties.type.valid"),
});

interface FieldMappingActivitySectionProps {
  activityExpandedFlag: boolean;
  isActivityCompleteFlag: boolean;
  toggleIsActivityCompleteFlag: (e: any) => void;
  toggleActivityExpandedFlag: (e: any) => void;
  onSubmit?: () => void;
  allActivityFieldsData: any;
  initialValue?: FieldMappingActivityState;
}

const defaultValues = {
  whoId: "",
  whatId: "",
  activityName: "",
  activityDescription: "",
  activityType: "",
};

const FieldMappingActivitySection: FC<FieldMappingActivitySectionProps> = ({
  activityExpandedFlag,
  toggleActivityExpandedFlag,
  onSubmit,
  allActivityFieldsData,
  isActivityCompleteFlag,
  toggleIsActivityCompleteFlag,
  initialValue,
}) => {
  const activityforceFieldLabels = allActivityFieldsData.map((x) => {
    return {
      name: x.name,
      label: x.label,
    };
  });

  const state = activityforceFieldLabels.map((e) => e.label);
  const [StateValues, setStateValues] = useState(state);

  useEffect(() => {
    if (initialValue) {
      setInitialValue(initialValue, StateValues, setStateValues);
    }
  }, []);

  const handleFieldChange = useCallback(
    (stateValue, prevValue) => {
      valueChange(stateValue, prevValue, StateValues, setStateValues);
    },
    [StateValues]
  );

  const dispatch = useAppDispatch();

  const onSubmitHandler = useCallback(
    async (values: any) => {
      // @ts-ignore
      dispatch(
        setFieldMappingActivity(
          // @ts-ignore
          fieldMappingSalesforceNameMapper(values, allActivityFieldsData)
        )
      );
      dispatch(setFieldMappingActivityDefault(values));
      toggleIsActivityCompleteFlag(true);
      // toggleActivityExpandedFlag(!activityExpandedFlag);
      onSubmit();
    },
    [dispatch, onSubmit, toggleIsActivityCompleteFlag, allActivityFieldsData]
  );

  return (
    <>
      {
        //@ts-ignore
        <ExpandableCard
          headerText={"Activity"}
          expanded={activityExpandedFlag}
          loaded={isActivityCompleteFlag}
          toggleCard={() => {
            toggleActivityExpandedFlag(!activityExpandedFlag);
          }}
        >
          <Flex flexDirection="column">
            <Flex>
              <Formik
                initialValues={initialValue ? initialValue : defaultValues}
                onSubmit={onSubmitHandler}
                enableReinitialize
                validationSchema={activitySchema}
              >
                {({
                  values,
                  errors,
                  dirty,
                  isValid,
                  setFieldValue,
                  submitForm,
                  initialValues,
                }) => {
                  return (
                    <>
                      {
                        //@ts-ignore
                        <Form>
                          <Flex alignItems="center" marginBottom={"-10px"}>
                            <Box width={"25vw"}>
                              <Text
                                id="fieldmapping.opportunity.fieldsineva"
                                variant="bodyRegular"
                                fontWeight={"500"}
                                marginBottom="12px"
                                color="textColor.primary"
                              />
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="whoIDField"
                                placeholder="fieldmapping.activity.whoId"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-10px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Text
                                id="fieldmapping.opportunity.fieldsinsalesforce"
                                variant="bodyRegular"
                                fontWeight={"500"}
                                marginBottom="2px"
                                color="textColor.primary"
                              />
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="whoId"
                                placeholder="fieldmapping.activity.whoId"
                                variantType="primary"
                                options={
                                  values?.whoId?.length > 0
                                    ? [...StateValues, values?.whoId].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.whoId ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsActivityCompleteFlag(true);
                                    } else {
                                      toggleIsActivityCompleteFlag(false);
                                    }
                                  }
                                  handleFieldChange(valueField, values?.whoId);
                                }}
                                defaultValue={values?.whoId}
                              />
                            </Box>
                          </Flex>
                          <Flex alignItems="center" marginBottom={"-10px"}>
                            <Box width={"25vw"}>
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="whatIDField"
                                placeholder="fieldmapping.activity.whatId"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-25px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="whatId"
                                placeholder="fieldmapping.activity.whatId"
                                variantType="primary"
                                options={
                                  values?.whatId?.length > 0
                                    ? [...StateValues, values?.whatId].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.whatId ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsActivityCompleteFlag(true);
                                    } else {
                                      toggleIsActivityCompleteFlag(false);
                                    }
                                  }
                                  handleFieldChange(valueField, values?.whatId);
                                }}
                                defaultValue={values?.whatId}
                              />
                            </Box>
                          </Flex>
                          <Flex alignItems="center" marginBottom={"-10px"}>
                            <Box width={"25vw"}>
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="activityNameField"
                                placeholder="fieldmapping.activity.name"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-25px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="activityName"
                                placeholder="fieldmapping.activity.name"
                                variantType="primary"
                                options={
                                  values?.activityName?.length > 0
                                    ? [
                                        ...StateValues,
                                        values?.activityName,
                                      ].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.activityName ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsActivityCompleteFlag(true);
                                    } else {
                                      toggleIsActivityCompleteFlag(false);
                                    }
                                  }
                                  handleFieldChange(
                                    valueField,
                                    values?.activityName
                                  );
                                }}
                                defaultValue={values?.activityName}
                              />
                            </Box>
                          </Flex>
                          <Flex alignItems="center" marginBottom={"-10px"}>
                            <Box width={"25vw"}>
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="activityDescriptionField"
                                placeholder="fieldmapping.activity.description"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-25px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="activityDescription"
                                placeholder="fieldmapping.activity.description"
                                variantType="primary"
                                options={
                                  values?.activityDescription?.length > 0
                                    ? [
                                        ...StateValues,
                                        values?.activityDescription,
                                      ].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.activityDescription ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsActivityCompleteFlag(true);
                                    } else {
                                      toggleIsActivityCompleteFlag(false);
                                    }
                                  }
                                  handleFieldChange(
                                    valueField,
                                    values?.activityDescription
                                  );
                                }}
                                defaultValue={values?.activityDescription}
                              />
                            </Box>
                          </Flex>
                          <Flex alignItems="center" marginBottom={"-10px"}>
                            <Box width={"25vw"}>
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="activityTypeField"
                                placeholder="fieldmapping.activity.type"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-25px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="activityType"
                                placeholder="fieldmapping.activity.type"
                                variantType="primary"
                                options={
                                  values?.activityType?.length > 0
                                    ? [
                                        ...StateValues,
                                        values?.activityType,
                                      ].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.activityType ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsActivityCompleteFlag(true);
                                    } else {
                                      toggleIsActivityCompleteFlag(false);
                                    }
                                  }
                                  handleFieldChange(
                                    valueField,
                                    values?.activityType
                                  );
                                }}
                                defaultValue={values?.activityType}
                              />
                            </Box>
                          </Flex>
                          <Flex marginTop={15} alignItems="center">
                            {/* {
                              //@ts-ignore
                              <Text
                                id="addnewfield"
                                cursor="pointer"
                                marginRight={16}
                                color={"#DD015B"}
                              />
                            } */}
                            <Button
                              title="saveandcontinue"
                              onClick={submitForm}
                              disabled={!dirty || !isValid}
                            />
                          </Flex>
                        </Form>
                      }
                    </>
                  );
                }}
              </Formik>
            </Flex>
          </Flex>
        </ExpandableCard>
      }
    </>
  );
};

export default FieldMappingActivitySection;
