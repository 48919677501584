import AccessDeniedModal from "components/AccessDeniedModal/AccessDeniedModal";
import { useLocation } from "react-router-dom";


const RouteAccess = ({ userMenuAccess, name, children }) => {
    const authorizationToken = localStorage.getItem("Authorization");
    const location = useLocation();

    if (!authorizationToken) {
      localStorage.setItem("navigationPath",location.pathname);
      window.location.href = "/aeLogin1";
    } else if (userMenuAccess.length > 0 && name  && !userMenuAccess.some(m => m?.dbValue?.toLowerCase() === name.toLowerCase())) {
      return <AccessDeniedModal />;
    }
    
    return <>{children}</>;


};

export default RouteAccess;

