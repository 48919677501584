import { useEffect } from "react";
import { AeReturnJourneyLayout } from "@evabot/eva-react-core-library/dist/templates/AeReturnJourneyLayout/AeReturnJourneyLayout";
import { AeSidebarSection } from "components/AeSidebar/AeSidebarSection";
import { AeSidebarSectionCollapse } from "components/AeSidebar/AeSidebarSectionCollapse";
import ProfileModalData from "containers/AEReturnDashboard/ProfileModalData";
import { useAppSelector, useAppDispatch } from "hooks";
import { setGlobalStateRole } from "store/globalStateReducer";
import AeReturnActHistoryRightSection from "./AeReturnActHistoryRightSection";

function AeReturnActivityHistory() {
  const dispatch = useAppDispatch();
  const profileData = useAppSelector((state) => state.profileState.profileData);

  useEffect(() => {
    dispatch(setGlobalStateRole({ journeyName: "ae", journeyPath: "1" }));
  }, []);

  return (
    <>
      <AeReturnJourneyLayout
        as="div"
        left={20}
        right={82}
        leftChildren={<AeSidebarSection activeTab="history" />}
        leftCollapsedChildren={<AeSidebarSectionCollapse activeTab="history" />}
        rightChildren={<AeReturnActHistoryRightSection />}
        profileModalChild={<ProfileModalData />}
        imageUrl={profileData?.imageFile}
      />
    </>
  );
}

export default AeReturnActivityHistory;
