import React, { FC } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";

interface NavSelectedNumberProps {
  current: number;
  selected: number;
}

const NavSelectedNumber: FC<NavSelectedNumberProps> = ({
  current,
  selected,
}) => {
  return (
    <>
      {(selected === 4.1 || selected === 4.2) && current === 4 ? (
        <></>
      ) : (
        current < selected && <Icon fill="#24B300" name="successTick" />
      )}
      {(((selected === 4.1 || selected === 4.2) && current === 4) ||
        current === selected) && (
        <Flex
          alignItems="center"
          justifyContent="center"
          background="white"
          borderRadius="50%"
          border="1px solid rgba(0, 0, 0, 0.1);"
          width="24px"
          height="24px"
        >
          {
            //@ts-ignore
            <Text color={"#DD015B"}>{current}</Text>
          }
        </Flex>
      )}
      {current > selected && (
        <Flex
          alignItems="center"
          justifyContent="center"
          background="#392678"
          borderRadius="50%"
          border="1px solid #392678;"
          width="24px"
          height="24px"
        >
          {
            //@ts-ignore
            <Text color={"#9F9F9F"}>{current}</Text>
          }
        </Flex>
      )}
    </>
  );
};

export default NavSelectedNumber;
