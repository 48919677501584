import { FC, useCallback, useState } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { useNavigate } from "react-router-dom";
import { aeForgotPasswordData } from "./store/AeFormReducer";
import { axiosHelper, axiosHelperCore } from "utils";
import { useAppDispatch } from "hooks";

const AeForgotPasswordRightSectionSchema = yup.object().shape({
  emailAddress: yup
    .string()
    .trim()
    .email("aereturn.profile.email.invalid")
    .required("aereturn.profile.email.valid"),
});

const defaultValues = {
  emailAddress: "",
};

const AeForgotMobile: FC = () => {
  const dispatch = useAppDispatch();

  const [isLoading, toggleIsLoading] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSnackbarError = (message) => {
    setShowSnackBar(true);
    setSnackbarMessage(message);
  }

  const onSubmitHandler = useCallback(
    async (values: any) => {
      dispatch(aeForgotPasswordData(values));
      console.log(values);
      toggleIsLoading(true);
      try {
        const result = await axiosHelperCore({
          url: 'rapport/user/evaGenerateForgotPasswordLink',
          method: 'POST',
          JSONData: {
            email: values.emailAddress
          },
          urlOverride: false
        });
        toggleIsLoading(false);
        if(result.data.success) {
          navigate('/checkEmail');
        }
        else
        {
          handleSnackbarError(result.data?.error);
        }
      }
      catch(err) {
        toggleIsLoading(false);
        console.log(err.response?.data);
        handleSnackbarError(err.response?.data)
      }
    },
    [dispatch]
  );

  const navigate = useNavigate();

  return (
    <>
    <SnackBar
        iconName={"info"}
        message={"Login Error"}
        setShowSnackBar={setShowSnackBar}
        show={showSnackBar}
        subHeading={snackbarMessage}
        timeout={4000}
        anchor={"top-right"}
        type='error'
      />
      <Flex width={"100%"}  flexDirection={"column"} mt={"11px"} padding={"16px"} >

        <Icon name="evaLogo" fill={"black"} />
        <Flex flexDirection={"column"} width={"100%"} mt={"90px"}>
        
<Text color={"#050505"} fontWeight={"300"} fontSize={"20px"} lineHeight={"140%"} > Reset your password </Text>
<Text mt={"8px"} color={"#57585C"} fontWeight={"400"} fontSize={"14px"} lineHeight={"140%"} >
        Please enter your registered
      </Text>
      <Text color={"#57585C"} fontWeight={"400"} fontSize={"14px"} lineHeight={"140%"} >
        {" "}
        email address
      </Text> 
      <Formik
        initialValues={defaultValues}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validationSchema={AeForgotPasswordRightSectionSchema}
      >
        {({ dirty, isValid, submitForm }) => {
          return (
            <Form style={{ width : "100%"  }} >
              <Flex flexDirection={"column"} width={"100%"} gridRowGap="5px">
                <Text  mt={"32px"} color={"#050505"} fontWeight={"400"} fontSize={"14px"} lineHeight={"140%"}>
                  Email address
                </Text>
                <FormikInput
                  // width={"95%"}
                  name="emailAddress"
                  placeholder="aereturn.login.email.placeholder"
                />
                <Button
                      mt={"16px"}
                      fullSize
                      loading={isLoading}
                      padding="13px 31px"
                      iconName="arrowRight"
                      iconAlign="right"
                      borderRadius={"118px"}
                      onClick={submitForm}
                      disabled={!dirty || !isValid}
                    >
                      Submit
                    </Button>
                    <Flex mt={"32px"} width={"100%"} justifyContent={"center"} cursor="pointer" onClick={() => navigate("/aeLogin1")}>
                    <Icon name="arrowLeft" />{" "}
                    <Text ml="10px" color={"#0E0B30"} variant="subTitle">
                      Back to login
                    </Text>
                  </Flex>
                
              </Flex>
            </Form>
          );
        }}
      </Formik>      
        </Flex>
      </Flex>
    </>
  );
};

export default AeForgotMobile;
