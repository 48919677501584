import { useEffect, useRef, useState } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { Backdrop } from "@mui/material";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import CompanyScoreReportTableHeader from "./CompanyScoreReportTableHeader";
import CompanyScoreReportTableBody from "./CompanyScoreReportTableBody";
import reportService from "services/reportService";
import { setcompanyScoreReportData } from "./store/companyReportReducer";
import { useDispatch } from "react-redux";
import Select from 'react-select';
import userService from "services/userService";
import { useAppSelector } from "hooks";
import SearchBar from "components/TailwindComponents/SearchBar";
import Pagination from "components/Pagination/Pagination";
import * as XLSX from "xlsx";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";

function CompanyScoreReportTable() {

    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [loading, setLoading] = useState(false);
    const [companyData, setCompanyData] = useState([]);
    const [adminList, setAdminList] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const dispatch = useDispatch();
    const filterRef = useRef({});

    const loggedInMemberDetails = useAppSelector((state) => state.profileState.profileData);
    const companyScoreReportData = useAppSelector((state) => state.companyScoreState.companyScoreReportData);

    const loggedInMemberOrgId = loggedInMemberDetails.organizationId;
    const loggedInMemberRole = loggedInMemberDetails.role;

    const isSuperAdminView = localStorage.getItem("superadminview");

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [totalDataCount, setTotalDataCount] = useState(0);
    const [searchData, setSearchData] = useState({});
    const [memberList, setMemberList] = useState([]);

    const itemPerPageOptions = [
        { value: 50, label: "Per page: 50" },
        { value: 100, label: "Per page: 100" }
    ];

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleItemsPerPageChange = (newItemsPerPage) => {
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(totalDataCount / itemsPerPage);


    const getCompanyScoreReport = async (user = "", userId = "") => {
        setLoading(true);
        filterRef.current = { user: user, userId: userId };
        try {
            const obj = { user: user, currentPage, itemsPerPage, searchData, userId: userId }
            reportService.getCompanyScoreReport(obj)
                .then((response) => {
                    dispatch(setcompanyScoreReportData(response?.data));
                    setCompanyData(response?.data);
                    setTotalDataCount(response?.totalCount);
                    setLoading(false);
                })
        } catch (error) {
            console.error("Error fetching list");
            setLoading(false);
        }

    };

    const fetchMemberList = async () => {
        try {
            userService.getAllMember()
                .then((response) => {
                    const filteredMembers = response.data.filter(({ organizationId }) =>
                        (loggedInMemberRole === 'superadmin' && isSuperAdminView === 'yes') || (loggedInMemberRole === 'superadmin' && organizationId === loggedInMemberOrgId && isSuperAdminView !== 'yes') ||
                        (loggedInMemberRole === 'admin' && organizationId === loggedInMemberOrgId)
                    );

                    const fetchmemberList = filteredMembers.map(({ organizationId, firstName, lastName, email, userId, userType }) => ({
                        value: userId,
                        label: `${firstName} ${lastName} <${email}>`
                    })
                    );

                    if (filteredMembers.length > 0) {
                        fetchmemberList.unshift({ value: loggedInMemberDetails.organizationId + "$$$" + loggedInMemberDetails.email, label: "All Members" });
                    }

                    if (loggedInMemberRole === "admin") {
                        setAdminList(fetchmemberList);
                    }

                    setMemberList(fetchmemberList);
                });
        } catch (error) {
            console.error("Error fetching company list", error);
        }
    };

    const fetchAdminMemberList = async () => {
        try {
            userService.fetchAdminMemberList()
                .then((response) => {
                    const adminMemberList = response?.data
                        .filter(item => item.organizationId)
                        .map(({ organizationId, firstName, lastName, email, userId }) => ({
                            value: organizationId + "$$$" + email,
                            userId: userId,
                            label: `${firstName} ${lastName} <${email}>`
                        }));
                    if ((loggedInMemberRole === "superadmin" && isSuperAdminView)) {
                        setAdminList(adminMemberList);
                    }
                    // else {
                    //     setAdminList([
                    //         {
                    //             value: loggedInMemberOrgId,
                    //             label: "All Member"
                    //         },
                    //         {
                    //             value: loggedInMemberDetails?.userId,
                    //             label: `${loggedInMemberDetails?.firstName} ${loggedInMemberDetails?.lastName} < ${loggedInMemberDetails?.email} > `
                    //         }
                    //     ])
                    // }
                })
        }
        catch (error) {
            console.error("Error fetching company list", error);
        }
    };

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    const debouncedHandleSearchChange = debounce((searchValue) => {
        setSearchData({ searchString: searchValue });
        setCurrentPage(1);
    }, 300);

    const handleSearchChange = (e) => {
        const searchValue = e?.target?.value.trim();
        // setCurrentPage(1);
        // setSearchData({searchString:searchValue});

        debouncedHandleSearchChange(searchValue);
        // let filteredData = companyScoreReportData;
        // if (searchValue.trim() != "") {
        //     filteredData = companyData.filter(c => c.stakeholderCompany.includes(searchValue.toLowerCase()));
        //     setCompanyData(filteredData);
        // }
        // else {
        //     setCompanyData(companyScoreReportData);
        // }

    };

    useEffect(() => {

        if ((loggedInMemberRole === "superadmin" && isSuperAdminView) || selectedOption.includes("$$$")) {
            getCompanyScoreReport(selectedOption ? selectedOption : loggedInMemberOrgId);
        } else {
            getCompanyScoreReport("", selectedOption || loggedInMemberDetails.userId);
        }
        adminList.length === 0 && fetchAdminMemberList();
        memberList.length === 0 && fetchMemberList();
    }, [loggedInMemberOrgId, currentPage, itemsPerPage, searchData]);

    const setFilteredData = (filteredData) => {
        setCompanyData(filteredData);
    }

    const exportToExcel = () => {
        const filterValue: any = filterRef.current;

        const obj = { user: filterValue.user, currentPage: 1, itemsPerPage: totalDataCount, searchData, userId: filterValue.userId }
        reportService.getCompanyScoreReport(obj)
            .then((response) => {


                if (response?.data?.length == 0)
                    return;

                const exportData = response?.data?.map(a => ({
                    accountName: a.stakeholderCompany,
                    userId: a.userId?.map(m => m).join(" , "),
                    accountAnalysis: a.accountAnalysis,
                    scoreChange: a.scoreChange == "same" ? "" : a.scoreChange,
                    scoreChangeValue: a.scoreChangeValue,
                    accountScore: a.accountScore
                }))

                const headers = ['Account Name', 'Owner', 'Account Analysis', 'Score Change', 'Score Value Change', 'Account Score'];
                const ws = XLSX.utils.json_to_sheet([], { header: headers });
                XLSX.utils.sheet_add_json(ws, exportData, { skipHeader: true, origin: 'A2' });
                const colWidths = headers.map((header) => ({ wch: Math.max(20, header.length) }));
                ws['!cols'] = colWidths;

                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Account Report');
                XLSX.writeFile(wb, "account-report.xlsx");
                setLoading(false);
            })


    }

    return (
        <>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            <Box>
                {loading && <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                    open={true}
                >
                    <Icon name="loadingIcon" />
                </Backdrop>}
                <div>
                    <Flex alignItems={"center"} marginBottom={"20px"}>
                        <Flex width={"90%"} justifyContent={"space-between"} alignItems={"center"}>
                            <Flex >
                                {/* <Text fontWeight="400" fontSize="32px">Framework/Context</Text> */}
                                <SearchBar placeholder="Search via account name" onChange={handleSearchChange} />
                            </Flex>
                            {(loggedInMemberRole === "superadmin" && isSuperAdminView === "yes") && <Flex alignContent={"flex-end"}>
                                <Text fontSize={"12px"} marginRight={"15px"} alignSelf={"center"}>Filter By : </Text>
                                <Select
                                    className="single-select"
                                    placeholder="Admin List"
                                    name="memberList"
                                    options={adminList}
                                    onChange={(admin) => {
                                        setCurrentPage(1);
                                        getCompanyScoreReport(admin.value);
                                        setSelectedOption(admin.value);
                                    }}
                                />
                            </Flex>}

                            {(loggedInMemberRole == "admin" || (loggedInMemberRole === "superadmin" && !isSuperAdminView)) && <Flex alignContent={"flex-end"}>

                                <Text fontSize={"12px"} marginRight={"15px"} alignSelf={"center"}>Filter By : </Text>
                                <Select
                                    className="single-select"
                                    placeholder="Admin List"
                                    name="memberList"
                                    options={memberList}
                                    defaultValue={{
                                        value: loggedInMemberDetails?.userId,
                                        label: `${loggedInMemberDetails?.firstName} ${loggedInMemberDetails?.lastName} < ${loggedInMemberDetails?.email} > `
                                    }}
                                    onChange={(admin) => {
                                        setCurrentPage(1);
                                        if (admin.label.toLowerCase() === "all members") {
                                            getCompanyScoreReport(admin.value);
                                            setSelectedOption(admin.value);
                                        } else {
                                            setSelectedOption(admin.value);
                                            getCompanyScoreReport("", admin.value);
                                        }
                                    }}
                                />

                            </Flex>}

                        </Flex>
                        <Flex >
                            <Button size="s" variant="primary" borderRadius={"15px"} marginLeft={"10px"} onClick={exportToExcel}>
                                Export
                            </Button>
                        </Flex>
                    </Flex>
                </div>

                <CompanyScoreReportTableHeader />
                <CompanyScoreReportTableBody filteredData={companyData} currentPage={currentPage} itemsPerPage={itemsPerPage} />
                <Box borderBottomLeftRadius={"8px"} borderBottomRightRadius={"8px"} background={"#fff"} padding={"10px 16px"}>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        onItemsPerPageChange={handleItemsPerPageChange}
                        itemPerPageOptions={itemPerPageOptions}
                    />
                </Box>
            </Box>
        </>
    );
}

export default CompanyScoreReportTable;
