import React, { useEffect, useState } from 'react';
import { Flex } from '@evabot/eva-react-core-library/dist/atoms/Flex/Flex';
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Tag } from '@evabot/eva-react-core-library/dist/molecules/Tag/Tag';
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Backdrop } from "@mui/material";
import ProfileEditIcon from 'assets/icons/ProfileEditIcon';
import { setEditFormVisibility, setEditFormData } from './store/emailTemplateReducer';
import { useDispatch } from "react-redux";
import { Button } from '@evabot/eva-react-core-library/dist/atoms/Button/Button';
import emailTemplateService from 'services/emailTemplateService';

const showMoreLessLinkStyle = {
    color: "#DD015B",
    cursor: "pointer",
    textDecoration: "underline",
    marginLeft: "10px",
};

function EmailTemplateTableBodyField({ index, item, reFetch }) {
    const [loading, setLoading] = useState(false);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');

    const [showMore, setShowMore] = useState(false);
    const maxLength = 50;

    const dispatch = useDispatch();

    const content = item.type === "framework" ? item?.frameworkValue : item?.contextValue;
    const displayContent = showMore ? content : content?.slice(0, maxLength);

    const editForm = (values) => {
        dispatch(setEditFormData(values));
        dispatch(setEditFormVisibility(true));
    }
    const handleCopyDetails = (link:string) => {
        navigator.clipboard.writeText(link);
        setSnackbarMessage('Link copied successfully!');
        setShowSnackBar(true);
        // setCopied(true);
        // setCopiedPassword(password);
    };

    return (
        <>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-center"}
                type={snackbarType}
            />
            {loading && <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                open={true}
            >
                <Icon name="loadingIcon" />
            </Backdrop>}
            <Flex
                padding={"15px"}
                fontSize="12px"
                fontWeight={500}
                position="sticky"
                backgroundColor={index % 2 == 0 ? "#FFF" : "rgb(249, 249, 249)"}
                alignItems={"center"}
            >
                <Flex padding={"0 5px"} textWrap="balance" width="5%" >
                    {index + 1}
                </Flex>

                {/* <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="30%" flexDirection={"column"}>
                    {item?.templateId || ''}
                </Flex> */}
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="25%">
                    {/* <Tag
                        color={item.type == "framework" ? "#22C96F" : "#04A0D2"}
                        bgColor={item.type == "framework" ? "rgba(34, 201, 111, 0.1)" : "rgba(173, 216, 230, 0.5)"}
                        text={item.type}
                    /> */}
                    {item?.templateName || ''}
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="25%" >
                    {item?.subject || ''}
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="25%" >
                    <div dangerouslySetInnerHTML={{ __html: item?.html || '' }} />

                </Flex>
                <Flex flexDirection={"column"} gap='10px' padding={"0 5px"} width="25%" >
                    <Flex><b>to:</b>&nbsp;{item?.to || ''}</Flex>
                    <Flex><b>cc:</b>&nbsp;{item?.cc || ''}</Flex>
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="20%" >
                    <Button fontSize={"12px"} padding={"16px"} paddingTop={"10px"} paddingBottom={"10px"} margin={"5px"} onClick={() => editForm(item)} size='sm' variant='secondary'>Edit</Button>
                    <Button fontSize={"12px"} padding={"16px"} paddingTop={"10px"} paddingBottom={"10px"} margin={"5px"} onClick={() => handleCopyDetails(item.link)} size='sm' variant='secondary'>Copy Link</Button> 
                </Flex>
            </Flex>
        </>
    );
}

export default EmailTemplateTableBodyField;
