import React, { FC, useCallback } from "react";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { EmailEditField } from "@evabot/eva-react-core-library/dist/molecules/EmailEditField/EmailEditField";
import { FormCheckbox } from "@evabot/eva-react-core-library/dist/atoms/FormikCheckBox/FormikCheckBox";
import DemoImage from "../../assets/images/DemoPic.png";
import { ImageTag } from "@evabot/eva-react-core-library/dist/molecules/ImageTag/ImageTag";
import { Formik, Form } from "formik";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import * as yup from "yup";
import { validateRequiredEmail } from "utils/validators";
import { RapportText } from "components/RapportText/RapportText";
import { RaportLocaleString } from "locales";

const ListOpportunityValidationSchema = yup.object().shape({
  email: validateRequiredEmail(),
});

export interface ListOpportunityTbodyFieldProps {
  tableField: any;
}

export const ListOpportunityTbodyField: FC<ListOpportunityTbodyFieldProps> = ({
  tableField,
}) => {
  const onSubmitHandler = useCallback(async (values: any) => {
    console.log(values, "values");
  }, []);

  return (
    <div>
      <Formik
        initialValues={{
          email: tableField.email || "",
          isStakeholder: true,
        }}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validationSchema={ListOpportunityValidationSchema}
      >
        {({ values, errors, initialValues, touched }) => {
          console.log(errors, "error", values, initialValues, touched);
          return (
            <Form>
              <tr style={{ alignItems: "center" }}>
                <Box width="20%">
                  <td>
                    <Flex alignItems={"center"} gridColumnGap={"8px"}>
                      <ImageTag
                        image={tableField.image || DemoImage}
                        width="32px"
                        height="32px"
                      />
                      <Text variant="bodyRegular" color="textColor.primary">
                        {tableField.name}
                      </Text>
                    </Flex>
                  </td>
                </Box>
                <Box width="20%">
                  <td>
                    <Text variant="bodyRegular" color="textColor.primary">
                      <EmailEditField
                        name="email"
                        width="min-content"
                        height="20px"
                        fillColor="#000"
                        leftIcon={{ iconName: "mail" }}
                      />
                      {errors["email"] && (
                        <RapportText
                          textId={errors["email"] as RaportLocaleString}
                          variant="inputHint"
                          color="error"
                          ml="5px"
                        />
                      )}
                    </Text>
                  </td>
                </Box>
                <Box width="20%">
                  <td>
                    <Text variant="bodyRegular" color="textColor.primary">
                      {tableField.designation}
                    </Text>
                  </td>
                </Box>
                <Flex width="25%" justifyContent={"left"}>
                  <Flex>
                    <td>
                      <FormCheckbox name="isStakeholder" variant="secondary" />
                    </td>
                  </Flex>
                </Flex>
              </tr>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
