import { useEffect, useState } from "react";
import { AeReturnJourneyLayout } from "@evabot/eva-react-core-library/dist/templates/AeReturnJourneyLayout/AeReturnJourneyLayout";
import { AeSidebarSection } from "components/AeSidebar/AeSidebarSection";
import { AeSidebarSectionCollapse } from "components/AeSidebar/AeSidebarSectionCollapse";
import ProfileModalData from "containers/AEReturnDashboard/ProfileModalData";
import { useAppSelector, useAppDispatch } from "hooks";
import {
  setGlobalStateRole,
  setstakeholderpageNo,
} from "store/globalStateReducer";
import AeReturnStakeRightSection from "./AeReturnStakeRightSection";
import AeReturnStakeRightSectionMobileView from "./AeReturnStakeRightSectionMobileView";
import AeReturnStakeHolderSearch from "./AeReturnStakeHolderSearch";
import { mapStakeholdersToSearchbarFormat } from "utils/mapNudgesToSearchbarFormat";
import { compare } from "utils/constants";

function AeReturnStakeholders() {
  const dispatch = useAppDispatch();
  const profileData = useAppSelector((state) => state.profileState.profileData);

  useEffect(() => {
    dispatch(setGlobalStateRole({ journeyName: "ae", journeyPath: "1" }));
    //@ts-ignore
    dispatch(setstakeholderpageNo(0));
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [searchValues, setSearchValues] = useState({
    name: "",
    email: "",
  });

  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);

  const stakeholders = useAppSelector(
    (state) => state.globalState.stakeholders
  );

  useEffect(() => {
    if (searchQuery === "" || searchQuery?.length === 0) {
      setSearchValues({
        name: "",
        email: "",
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    if (stakeholders.length > 0) {
      setOptions(
        mapStakeholdersToSearchbarFormat([...stakeholders].sort(compare))
      );
    }
  }, [stakeholders]);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  return (
    <>
      <AeReturnJourneyLayout
        as="div"
        left={20}
        right={82}
        leftChildren={<AeSidebarSection activeTab="stakeholders" />}
        leftCollapsedChildren={
          <AeSidebarSectionCollapse activeTab="stakeholders" />
        }
        rightChildren={
          screenSize.dynamicWidth > 576 ? (
            <AeReturnStakeRightSection
              setSearchQuery={setValue}
              setOpenSearchModal={setOpenModal}
              searchValues={value && value.value ? value.value : ""}
            />
          ) : (
            <AeReturnStakeRightSectionMobileView
              searchValues={value && value.value ? value.value : ""}
              setSearchQuery={setValue}
              setOpenSearchModal={setOpenModal}
            />
          )
        }
        profileModalChild={<ProfileModalData />}
        imageUrl={profileData?.imageFile}
        // searchQuery={searchQuery}
        // setSearchQuery={setSearchQuery}
        value={value}
        options={options}
        setValue={setValue}
        newHeaderSearch
        // searchModalChild={
        //   <AeReturnStakeHolderSearch
        //     setOpenSearchModal={setOpenModal}
        //     searchQuery={searchQuery}
        //     setSearchQuery={setSearchQuery}
        //     searchValues={searchValues}
        //     setSearchValues={setSearchValues}
        //     stakeholders={stakeholders}
        //   />
        // }
        openSearchModal={openModal}
        setOpenSearchModal={setOpenModal}
      />
    </>
  );
}

export default AeReturnStakeholders;
