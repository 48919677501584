import ProspectReportTableBodyField from "./ProspectReportTableBodyField";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";

function ProspectReportTableBody({ data,currentPage,itemsPerPage,reFetch }) {
  if (!Array.isArray(data) || data.length === 0) {
    console.log("Array not found", data);
    return (
      <Box background="#fff" width="100%">
        <Flex justifyContent="center" alignItems="center" height="100px">
          <span>No data available</span>
        </Flex>
      </Box>
    );
  }

  return (
    <Box background="#fff" className="table-data-tm">
      {data.map((item, index) => (
        <ProspectReportTableBodyField key={index} ProspectData={item} index={(currentPage - 1) * itemsPerPage + (index + 1)} reFetch={reFetch} />
      ))}
    </Box>
  );
}

export default ProspectReportTableBody;
