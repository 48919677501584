import { useEffect, useState } from "react";
import { AeReturnJourneyLayout } from "@evabot/eva-react-core-library/dist/templates/AeReturnJourneyLayout/AeReturnJourneyLayout";
import { AeSidebarSection } from "components/AeSidebar/AeSidebarSection";
import { AeSidebarSectionCollapse } from "components/AeSidebar/AeSidebarSectionCollapse";
import ProfileModalData from "containers/AEReturnDashboard/ProfileModalData";
import { useAppSelector, useAppDispatch } from "hooks";
import { setGlobalStateRole } from "store/globalStateReducer";
import AeReturnAnalyticsRightSection from "./AeReturnAnalyticsRightSection";
import { mapAnalyticsToSearchbarFormat } from "utils/mapNudgesToSearchbarFormat";
import { compare } from "utils/constants";

function AeReturnAnalytics() {
  const dispatch = useAppDispatch();
  const profileData = useAppSelector((state) => state.profileState.profileData);
  const [openModal, setOpenModal] = useState(false);
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);

  const allAnalyticsData = useAppSelector(
    (state) => state.analyticsState.analyticsData
  );

  useEffect(() => {
    dispatch(setGlobalStateRole({ journeyName: "ae", journeyPath: "1" }));
  }, []);

  useEffect(() => {
    if (allAnalyticsData.length > 0) {
      setOptions(
        mapAnalyticsToSearchbarFormat([...allAnalyticsData].sort(compare))
      );
    }
  }, [allAnalyticsData]);

  return (
    <>
      <AeReturnJourneyLayout
        as="div"
        left={20}
        right={82}
        leftChildren={<AeSidebarSection activeTab="analytics" />}
        leftCollapsedChildren={
          <AeSidebarSectionCollapse activeTab="analytics" />
        }
        rightChildren={
          <AeReturnAnalyticsRightSection
            searchValues={value && value.value ? value.value : ""}
          />
        }
        profileModalChild={<ProfileModalData />}
        imageUrl={profileData?.imageFile}
        
        value={value}
        options={options}
        setValue={setValue}
        openSearchModal={openModal}
        setOpenSearchModal={setOpenModal}
        // newHeaderSearchPlaceholder='Search via email or name'
        pageHeading="Weekly Usage View"
      />
    </>
  );
}

export default AeReturnAnalytics;
