import { useEffect, useState } from "react";
import FrameworkAndContextTable from "./FrameworkAndContextTable";
import stakeHolderTagService from "services/stakeholderTagService";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { useAppSelector } from "hooks";
import { useDispatch } from "react-redux";

function FrameworkAndContextContent() {

    return (

        <Flex flexDirection={"column"} padding="25px 32px" overflowY={"scroll"} >
            <FrameworkAndContextTable tabView={false}/>
        </Flex>
    );
}

export default FrameworkAndContextContent;
