import Button from 'components/Elements/Button';
import { useEffect, useState } from "react";
import Select from 'react-select';
import { axiosHelperCore } from "utils";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Backdrop } from "@mui/material";
import { TextField, InputAdornment, MenuItem, FormControl, InputLabel, Autocomplete, Tooltip, IconButton } from "@mui/material";

function HubspotForm({ setShowHubspotForm, setHubspotText }) {

    const [showMessage, setShowMessage] = useState(false);
    const [listData, setListData] = useState([]);
    const [fields, setFields] = useState([]);
    const [hubspotData, setHubspotData] = useState({ listId: "", firstName: "", lastName: "", email: "", jobTitle: "", linkedinUrl: "", callPrep:"" });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getList();
        getData();
    }, [])

    const getList = async () => {
        setLoading(true);
        const [result, result2]: any[] = await Promise.all([
            axiosHelperCore({
                url: `hubspot/fetchList`,
                method: "POST",
            }),
            axiosHelperCore({
                url: `hubspot/fetchFieldNames`,
                method: "POST",
            })
        ]);

        setListData(result.data.lists);
        setFields(result2.data.fields);
        setLoading(false);
    }

    const getData = async () => {

        const result: any = await axiosHelperCore({
            url: `hubspot/getData`,
            method: "POST",
        });
        if (result?.data?.hubspotData) {
            setHubspotData(result?.data?.hubspotData);
            if (result?.data?.hubspotData && Object.keys(result?.data?.hubspotData).length > 0) {
                setHubspotText("connected");
            }
        }
        else {
            setHubspotText("pending");
            setShowHubspotForm(true)
        }



    }

    const saveData = async () => {
        setShowHubspotForm(true)
        const result: any = await axiosHelperCore({
            url: `hubspot/saveData`,
            method: "POST",
            JSONData: { hubspotData: hubspotData }
        });
        setHubspotText("connected");
        setShowHubspotForm(false);
    }


    return (
        <>
            {loading && <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                open={true}>
                <Icon name="loadingIcon" />
            </Backdrop>}

            <div className='text-left w-12/12 flex flex-col gap-[10px] w-[98%] justify-center  items-center'>
                <div>
                    <div>
                        <Autocomplete
                            className="text-sm  w-[230px]"
                            options={listData?.map(f => ({ name: f.name, value: f.listId }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Select list" variant="outlined" />}
                            value={listData?.filter(f => f.listId == hubspotData.listId)?.map(f => ({ name: f.name, value: f.listId }))[0] || null}
                            onChange={(event, newValue) => {
                                setHubspotData((prevHubspotData) => ({
                                    ...prevHubspotData,
                                    listId: newValue?.value
                                }));
                            }}
                        />
                    </div>
                </div>

                <div>
                    <div>
                        <Autocomplete
                            className="text-sm  w-[230px]"
                            options={fields?.map(f => ({ name: f.label, value: f.name }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Select first name" variant="outlined" />}
                            value={fields?.filter(f => f.name == hubspotData.firstName)?.map(f => ({ name: f.label, value: f.name }))[0] || null}
                            onChange={(event, newValue) => {
                                setHubspotData((prevHubspotData) => ({
                                    ...prevHubspotData,
                                    firstName: newValue?.value
                                }));
                            }}
                        />

                    </div>
                </div>
                <div>
                    <div >

                        <Autocomplete
                            className="text-sm  w-[230px]"
                            options={fields?.map(f => ({ name: f.label, value: f.name }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Select last name" variant="outlined" />}
                            value={fields?.filter(f => f.name == hubspotData.lastName)?.map(f => ({ name: f.label, value: f.name }))[0] || null}
                            onChange={(event, newValue) => {
                                setHubspotData((prevHubspotData) => ({
                                    ...prevHubspotData,
                                    lastName: newValue?.value
                                }));
                            }}
                        />


                    </div>
                </div>
                <div>
                    <div>

                        <Autocomplete
                            className="text-sm  w-[230px]"
                            options={fields?.map(f => ({ name: f.label, value: f.name }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Select email" variant="outlined" />}
                            value={fields?.filter(f => f.name == hubspotData.email)?.map(f => ({ name: f.label, value: f.name }))[0] || null}
                            onChange={(event, newValue) => {
                                setHubspotData((prevHubspotData) => ({
                                    ...prevHubspotData,
                                    email: newValue?.value
                                }));
                            }}
                        />

                    </div>
                </div>
                <div>
                    <div>


                        <Autocomplete
                            className="text-sm  w-[230px]"
                            options={fields?.map(f => ({ name: f.label, value: f.name }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Select job title" variant="outlined" />}
                            value={fields?.filter(f => f.name == hubspotData.jobTitle)?.map(f => ({ name: f.label, value: f.name }))[0] || null}
                            onChange={(event, newValue) => {
                                setHubspotData((prevHubspotData) => ({
                                    ...prevHubspotData,
                                    jobTitle: newValue?.value
                                }));
                            }}
                        />


                    </div>
                </div>
                <div>
                    <div>

                        <Autocomplete
                            className="text-sm  w-[230px]"
                            options={fields?.map(f => ({ name: f.label, value: f.name }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Select linkedin url" variant="outlined" />}
                            value={fields?.filter(f => f.name == hubspotData.linkedinUrl)?.map(f => ({ name: f.label, value: f.name }))[0] || null}
                            onChange={(event, newValue) => {
                                setHubspotData((prevHubspotData) => ({
                                    ...prevHubspotData,
                                    linkedinUrl: newValue?.value
                                }));
                            }}
                        />

                    </div>
                </div>
                <div>
                    <div>

                        <Autocomplete
                            className="text-sm  w-[230px]"
                            options={fields?.map(f => ({ name: f.label, value: f.name }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Select callprep" variant="outlined" />}
                            value={fields?.filter(f => f.name == hubspotData.callPrep)?.map(f => ({ name: f.label, value: f.name }))[0] || null}
                            onChange={(event, newValue) => {
                                setHubspotData((prevHubspotData) => ({
                                    ...prevHubspotData,
                                    callPrep: newValue?.value
                                }));
                            }}
                        />

                    </div>
                </div>

                {showMessage && <div className='m-3 text-[#38C088] text-sm'>
                    Settings successfully saved!
                </div>}
                <div className='mt-2 text-center flex flex-wrap space-x-2 justify-center'>
                    <Button variant={"salesforce"} text={hubspotData.listId && hubspotData.firstName && hubspotData.lastName && hubspotData.email ? "Save" : "Save"} onClick={saveData} disabled={!(hubspotData.listId && hubspotData.firstName && hubspotData.lastName && hubspotData.email)} />
                    {hubspotData.listId && hubspotData.firstName && hubspotData.lastName && hubspotData.email && (<Button variant={"secondary"} text={"Cancel"} onClick={() => { setShowHubspotForm(false) }} />)}
                </div>
            </div>
        </>
    )
}

export default HubspotForm;