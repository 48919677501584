import { FC  } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { ToolTip } from "@evabot/eva-react-core-library/dist/molecules/ToolTip/ToolTip";
import { Tag } from "@evabot/eva-react-core-library/dist/molecules/Tag/Tag";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
interface OpportunityNameFilterViewProps {
    selectedNudge: any;
    getUniqueFilterNumber : any;
    filteredNudges: any;
    recommendedNudges: any;
    setSearchTerm: any;
    setSearchType: any;
    setSelectedNudge: any;
    setFilteredNudges : any;
    setFilter: any;
    setSearch: any;
    getNudgeBtn: any;
    nudgeCategoryMap: any;
    thumsUpIds : any;
    updateNudgeFeedback : any;
    thumsDownIds : any;
    dispatch: any;
    setSelectedNudgeForUnlike: any;
    setHide : any;
    hide: any;
    undoNudgeAction : any;
}

const OpportunityNameFilterView : FC<OpportunityNameFilterViewProps> = ({  selectedNudge , getUniqueFilterNumber , filteredNudges , recommendedNudges , setSearchTerm , setSearchType , setSelectedNudge , setFilteredNudges , setFilter , setSearch , getNudgeBtn , nudgeCategoryMap , thumsUpIds , updateNudgeFeedback , thumsDownIds , dispatch , setSelectedNudgeForUnlike , setHide , hide , undoNudgeAction }) => {
    return (
        <>
        <Box width={"100%"}>
                    <Box
                      style={{ boxSizing: "border-box" }}
                      background="#FFFFFF"
                      borderRadius="12px"
                      padding="25px"
                      width="100%"
                    >
                      <Flex
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                       

                        <Flex
                          flexDirection={"column"}
                          gridRowGap={"4px"}
                          justifyContent={"flex-start"}
                          borderRight={"1px solid rgba(0, 0, 0, 0.1)"}
                          paddingRight={"30px"}
                        >
                          <Text
                            color={"#57585C"}
                            fontWeight={"400"}
                            fontSize={"16px"}
                          >
                            Opportunity name
                           
                          </Text>
                          <Flex
                            flexDirection={"row"}
                            gridColumnGap={"4px"}
                            alignItems={"center"}
                          >
                            <Icon name="target" />
                              <Text 
                              fontWeight={"400"}
                              fontSize={"20px"}
                              color={"#050505"}
                            >
                              {" "}
                              {selectedNudge?.opportunityName}{" "}
                            </Text>
                          </Flex>
                        </Flex>
                        <Flex
                          flexDirection={"column"}
                          gridRowGap={"4px"}
                          justifyContent={"flex-start"}
                          borderRight={"1px solid rgba(0, 0, 0, 0.1)"}
                          paddingRight={"30px"}
                        >
                          <Text
                            color={"#57585C"}
                            fontWeight={"400"}
                            fontSize={"16px"}
                          >
                            {" "}
                            Opportunity stage{" "}
                          </Text>
                          <Flex
                            flexDirection={"row"}
                            gridColumnGap={"4px"}
                            alignItems={"center"}
                          >
                            <Icon name="flag" />

                            <Text
                              fontWeight={"400"}
                              fontSize={"20px"}
                              color={"#050505"}
                            >
                              {selectedNudge?.salesforceStage}
                           
                            </Text>
                          </Flex>
                        </Flex>
                        <Flex
                          flexDirection={"column"}
                          gridRowGap={"4px"}
                          justifyContent={"flex-start"}
                          borderRight={"1px solid rgba(0, 0, 0, 0.1)"}
                          paddingRight={"30px"}
                        >
                          <Text
                            color={"#57585C"}
                            fontWeight={"400"}
                            fontSize={"16px"}
                          >
                            {" "}
                            Company{" "}
                          </Text>
                          <Flex
                            flexDirection={"row"}
                            gridColumnGap={"4px"}
                            alignItems={"center"}
                          >
                            <Icon name="orgIcon" />

                            <Text
                              fontWeight={"400"}
                              fontSize={"20px"}
                              color={"#050505"}
                            >
                              {selectedNudge?.accountName}
                           
                            </Text>
                          </Flex>
                        </Flex>
                        <Flex
                          flexDirection={"column"}
                          gridRowGap={"4px"}
                          justifyContent={"flex-start"}
                          borderRight={"1px solid rgba(0, 0, 0, 0.1)"}
                          paddingRight={"30px"}
                        >
                          <Text
                            color={"#57585C"}
                            fontWeight={"400"}
                            fontSize={"16px"}
                          >
                            {" "}
                            #Stakeholders{" "}
                          </Text>
                          <Flex
                            flexDirection={"row"}
                            gridColumnGap={"4px"}
                            alignItems={"center"}
                          >
                            <Icon name="user" />

                            <Text
                              fontWeight={"400"}
                              fontSize={"20px"}
                              color={"#050505"}
                            >
                             
                              {getUniqueFilterNumber("stakeholderId")}
                            </Text>
                          </Flex>
                        </Flex>
                      
                        <Flex
                          flexDirection={"column"}
                          gridRowGap={"4px"}
                          justifyContent={"flex-start"}
                        >
                          <Text
                            color={"#57585C"}
                            fontWeight={"400"}
                            fontSize={"16px"}
                          >
                            Total opportunity value{" "}
                          </Text>
                          <Flex
                            flexDirection={"row"}
                            gridColumnGap={"4px"}
                            alignItems={"center"}
                          >
                            <Icon name="coin" />

                            <Text
                              fontWeight={"400"}
                              fontSize={"20px"}
                              color={"#050505"}
                            >
                              {`$${getUniqueFilterNumber("oppValue") / 1000}k`}{" "}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Box>
                  </Box>
                  {filteredNudges.map((nudge) => {
                    return !thumsDownIds.includes(nudge?.nudgeId) ? (
                      <Box width={"100%"}>
                        <Box
                          style={{ boxSizing: "border-box" }}
                          background="#FFFFFF"
                          borderTopLeftRadius="12px"
                          borderTopRightRadius="12px"
                          borderBottom="1px solid rgba(0, 0, 0, 0.1)"
                          padding="25px"
                          width="100%"
                        >
                          <Flex
                            borderBottom="1px dashed rgba(0, 0, 0, 0.1)"
                            marginBottom="10px"
                            justifyContent="space-between"
                            padding="0px 0px 20px 0px"
                            height="20px"
                            alignItems="center"
                          >
                            <Flex gap="8px" alignItems="center">
                              <Box
                                borderRadius="36px"
                                background="#E6F6FF"
                                height="36px"
                                width="36px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Text fontSize="12px" fontWeight="400">
                                  {nudge?.stakeholderName[0]}
                                </Text>
                              </Box>
                              <Text
                                color="#0094E3"
                                fontSize="14px"
                                fontWeight="400"
                                borderRight="1px solid rgba(0, 0, 0, 0.1)"
                                paddingRight="10px"
                                cursor="pointer"
                                onClick={() => {
                                  console.log("name filter");
                                  let filteredNudges = recommendedNudges.filter(
                                    (nd) =>
                                      nd?.nudgeHeadlinePayload?.name ==
                                      nudge?.stakeholderName
                                  );
                                  console.log(
                                    "filtered Nudges",
                                    filteredNudges
                                  );

                                  setSearchTerm(
                                    nudge?.stakeholderName
                                  );
                                  setSearchType("name");
                                  setSelectedNudge(nudge);
                                  setFilteredNudges(filteredNudges);
                                  setFilter(true);
                                  setSearch(false);
                                }}
                              >
                                {" "}
                                {nudge?.stakeholderName}{" "}
                              </Text>
                              <Text
                                color="#57585C"
                                fontSize="14px"
                                fontWeight="400"
                              >
                                {" "}
                                {nudge.stakeholderDesignation}{" "}
                              </Text>
                            </Flex>
                            
                          </Flex>
                          <Flex justifyContent="space-between" >
                            <Flex gap="16px" alignItems="center">
                            
                              <Box width="40vw">
                              <Flex flexDirection={"column"} gridRowGap={"12px"} marginTop={"32px"}  >
                         <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"8px"} >
                           <Icon name="nudgeIndexFinger" />
                           <Text color={"#57585C"} fontWeight={"300"}  fontSize={"12px"}> { nudge?.context } </Text>
                         </Flex>
                         <Text color={"#050505"} fontWeight={"400"} fontSize={"20px"} lineHeight={"160%"} > {nudge?.nudgeHeadline} </Text>
                      </Flex>
                                {/* <Text fontSize="16px" fontWeight="500">
                                  {" "}
                                  {nudge?.nudgeHeadline}{" "}
                                </Text>
                                <br />
                                <Text fontSize={"10px"} fontWeight="400">
                                  Context: {nudge?.context}
                                </Text> */}
                                <br />
                                <Flex gap="12px">
                                  <Tag
                                    text={nudgeCategoryMap[nudge.nudgeCategory]}
                                    color="white"
                                    bgColor="#3064f6"
                                  />
                                  {nudge?.nudgeCategory == "googlePlaces" &&
                                    nudge?.crawlerData?.maps_url && (
                                      <Box
                                        cursor="pointer"
                                        onClick={() =>
                                          window.open(
                                            nudge?.crawlerData?.maps_url,
                                            "_blank"
                                          )
                                        }
                                      >
                                        <Tag
                                          text={"Source: Google Map"}
                                          color="#0094E3"
                                          bgColor="#F4F4F4"
                                        />
                                      </Box>
                                    )}
                                  {nudge?.nudgeCategory == "news" &&
                                    nudge?.crawlerData?.url && (
                                      <Box
                                        cursor="pointer"
                                        onClick={() =>
                                          window.open(
                                            nudge?.crawlerData?.url,
                                            "_blank"
                                          )
                                        }
                                      >
                                        <Tag
                                          text={"Source: News"}
                                          color="#0094E3"
                                          bgColor="#F4F4F4"
                                        />
                                      </Box>
                                    )}
                                  {nudge?.nudgeCategory == "music" &&
                                    nudge?.crawlerData?.link && (
                                      <Box
                                        cursor="pointer"
                                        onClick={() =>
                                          window.open(
                                            nudge?.crawlerData?.link,
                                            "_blank"
                                          )
                                        }
                                      >
                                        <Tag
                                          text={"Source: Spotify"}
                                          color="#0094E3"
                                          bgColor="#F4F4F4"
                                        />
                                      </Box>
                                    )}
                                  {nudge?.nudgeCategory == "concertTickets" &&
                                    nudge?.crawlerData?.eventTickets && (
                                      <Box
                                        cursor="pointer"
                                        onClick={() =>
                                          window.open(
                                            nudge?.crawlerData?.eventTickets,
                                            "_blank"
                                          )
                                        }
                                      >
                                        <Tag
                                          text={"Source: Ticket Master"}
                                          color="#0094E3"
                                          bgColor="#F4F4F4"
                                        />
                                      </Box>
                                    )}
                                  {nudge?.nudgeCategory == "sports" &&
                                    nudge?.crawlerData?.news_url && (
                                      <Box
                                        cursor="pointer"
                                        onClick={() =>
                                          window.open(
                                            nudge?.crawlerData?.news_url,
                                            "_blank"
                                          )
                                        }
                                      >
                                        <Tag
                                          text={"Source:News"}
                                          color="#0094E3"
                                          bgColor="#F4F4F4"
                                        />
                                      </Box>
                                    )}
                                 
                                </Flex>
                              </Box>
                            </Flex>
                            <Flex
                              flexDirection="column"
                              alignItems="flex-end"
                              gap="10px"
                              justifyContent="center"
                            >
                              {getNudgeBtn(nudge)}
                              <ToolTip
                                color="black"
                                bgColor="#FFFFFF"
                                boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
                                direction="right-end"
                                title={
                                  <>
                                    <Box
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                        alignItems: "flex-start",
                                        // width: "max-contents",
                                        // width : "400px",
                                        borderRadius: "12px",
                                        padding: "20px 25px",
                                      }}
                                    >
                                      <Flex
                                        flexDirection={"column"}
                                        gridRowGap={"16px"}
                                      >
                                        <Flex
                                          flexDirection={"row"}
                                          gridColumnGap={"12px"}
                                          alignItems={"center"}
                                        >
                                          <Icon name="nudgeEssential" />
                                          <Text
                                            color={"#050505"}
                                            fontSize={"16px"}
                                            fontWeight={"500"}
                                          >
                                            {" "}
                                            How it works?
                                          </Text>
                                        </Flex>
                                        <Flex
                                          flexDirection={"column"}
                                          gridRowGap={"16px"}
                                        >
                                          <Flex
                                            flexDirection={"row"}
                                            gridColumnGap={"10px"}
                                            alignItems={"center"}
                                          >
                                            <Icon name="handleft" />
                                            <Text
                                              color={"#57585C"}
                                              fontWeight={"400"}
                                              fontSize={"14px"}
                                            >
                                              {" "}
                                              Eva concierge team will receive
                                              the order{" "}
                                            </Text>
                                          </Flex>
                                          <Flex
                                            flexDirection={"row"}
                                            gridColumnGap={"10px"}
                                            alignItems={"center"}
                                          >
                                            <Icon name="handleft" />
                                            <Text
                                              color={"#57585C"}
                                              fontWeight={"400"}
                                              fontSize={"14px"}
                                            >
                                              {" "}
                                              Eva concierge team will receive
                                              the order{" "}
                                            </Text>
                                          </Flex>
                                          <Flex
                                            flexDirection={"row"}
                                            gridColumnGap={"10px"}
                                            alignItems={"center"}
                                          >
                                            <Icon name="handleft" />
                                            <Text
                                              color={"#57585C"}
                                              fontWeight={"400"}
                                              fontSize={"14px"}
                                            >
                                              {" "}
                                              Eva concierge team will receive
                                              the order{" "}
                                            </Text>
                                          </Flex>
                                          <Flex
                                            flexDirection={"row"}
                                            gridColumnGap={"10px"}
                                            alignItems={"center"}
                                          >
                                            <Icon name="handleft" />
                                            <Text
                                              color={"#57585C"}
                                              fontWeight={"400"}
                                              fontSize={"14px"}
                                            >
                                              {" "}
                                              Eva concierge team will receive
                                              the order{" "}
                                            </Text>
                                          </Flex>
                                        </Flex>
                                      </Flex>
                                   
                                    </Box>
                                  </>
                                }
                              >
                               
                                <Text
                                  id="how.it.works"
                                  color="#0094E3"
                                  fontSize="12px"
                                  fontWeight="400"
                                />
                              </ToolTip>
                              
                              
                            </Flex>
                          </Flex>
                        </Box>

                        <Box
                          style={{ boxSizing: "border-box" }}
                          background="#FFFFFF"
                          borderBottomRightRadius="12px"
                          borderBottomLeftRadius="12px"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          height="50px"
                          width="100%"
                          padding="10px 25px"
                        >
                          <Flex>
                            <Flex
                              gap="5px"
                              padding="0px 15px"
                              borderRight="1px solid rgba(0, 0, 0, 0.1)"
                            >
                              <Icon name="orgIcon" />
                              <Text
                                color="#0094E3"
                                fontSize="12px"
                                fontWeight="400"
                                borderRight="1px solid rgba(0, 0, 0, 0.1)"
                                cursor="pointer"
                                onClick={() => {
                                  console.log("account name filter");
                                  let filteredNudges = recommendedNudges.filter(
                                    (nd) =>
                                      nd?.accountName == nudge?.accountName
                                  );
                                  console.log(
                                    "filtered Nudges",
                                    filteredNudges
                                  );
                                  setSearchType("accountName");
                                  setSearchTerm(nudge?.accountName);
                                  setSelectedNudge(nudge);
                                  setFilteredNudges(filteredNudges);
                                  setFilter(true);
                                  setSearch(false);
                                }}
                              >
                                {" "}
                                {nudge?.accountName}{" "}
                              </Text>
                             
                            </Flex>
                            <Flex
                              gap="5px"
                              padding="0px 15px"
                              borderRight="1px solid rgba(0, 0, 0, 0.1)"
                            >
                              <Icon name="target" />
                              <Text
                                color="black"
                                fontSize="12px"
                                fontWeight="400"
                              >
                                Opportunity name:
                              </Text>
                              <Text
                                color="#0094E3"
                                fontSize="12px"
                                fontWeight="400"
                                borderRight="1px solid rgba(0, 0, 0, 0.1)"
                                cursor="pointer"
                                onClick={() => {
                                  console.log("opportunity name filter");
                                  let filteredNudges = recommendedNudges.filter(
                                    (nd) =>
                                      nd?.opportunityName ==
                                      nudge?.opportunityName
                                  );
                                  console.log(
                                    "filtered Nudges",
                                    filteredNudges
                                  );
                                  setSearchType("opportunityName");
                                  setSearchTerm(nudge?.opportunityName);
                                  setSelectedNudge(nudge);
                                  setFilteredNudges(filteredNudges);
                                  setFilter(true);
                                  setSearch(false);
                                }}
                              >
                                {" "}
                                {nudge?.opportunityName}{" "}
                              </Text>
                              
                            </Flex>
                            <Flex
                              gap="5px"
                              padding="0px 15px"
                              borderRight="1px solid rgba(0, 0, 0, 0.1)"
                            >
                              <Icon name="flag" />
                              <Text
                                color="#0094E3"
                                fontSize="12px"
                                fontWeight="400"
                                borderRight="1px solid rgba(0, 0, 0, 0.1)"
                                cursor="pointer"
                                onClick={() => {
                                  console.log("stage filter");
                                  let filteredNudges = recommendedNudges.filter(
                                    (nd) =>
                                      nd?.salesforceStage ==
                                      nudge?.salesforceStage
                                  );
                                  console.log(
                                    "filtered Nudges",
                                    filteredNudges
                                  );
                                  setSearchType("salesforceStage");
                                  setSearchTerm(nudge?.salesforceStage);
                                  setSelectedNudge(nudge);
                                  setFilteredNudges(filteredNudges);
                                  setFilter(true);
                                  setSearch(false);
                                }}
                              >
                                {" "}
                                {nudge?.salesforceStage}{" "}
                              </Text>
                              
                            </Flex>
                            <Flex gap="5px" padding="0px 15px">
                              <Icon name="coin" />
                              <Text
                                color="#57585C"
                                fontSize="12px"
                                fontWeight="400"
                              >
                                Value: ${nudge.opportunityValue}
                              </Text>
                            </Flex>
                          </Flex>
                          { thumsUpIds.includes( nudge?.nudgeId ) ? (
                <Flex flexDirection={"row"} gridColumnGap={"8px"} alignItems={"center"} >
                 <Icon name="stakeholderDone" fill={"#1CB43D"}  />
                 <Text color={"#24B300"} fontWeight={"400"} fontSize={"12px"} > Thank you for your feedback </Text>
                 <Button variant="undo" onClick={ () => {
                undoNudgeAction(nudge?.nudgeId,"up");
               } } > Undo  </Button>
                </Flex>
                ) : (
<Flex gap="8px">
                  <Text
                    id="did.you.find.this.helpful"
                    fontSize="12px"
                    fontWeight="400"
                  />
                  <Icon
                    name="thumbsUp"
                   
                    onClick={() => {
                      setSelectedNudge(nudge);
                      updateNudgeFeedback(nudge, "up", "");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <Icon
                    name="thumbsDown"
                    
                    onClick={() => {
                      dispatch(setSelectedNudgeForUnlike(nudge));
                      setHide(!hide);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Flex>
                ) }
                        </Box>
                      </Box>
                    ):  (
                      <Box width={"100%"}>
                      <Box
                             style={{ boxSizing: "border-box" }}
                             background="#FFFFFF"
                             borderTopLeftRadius="12px"
                             borderTopRightRadius="12px"
                             borderBottom="1px solid rgba(0, 0, 0, 0.1)"
                             padding="40px"
                             width="100%"
                           >
                           <Flex flexDirection={"row"} gridColumnGap={"52px"} justifyContent={"center"} alignItems={"center"} >
                            <Text color={"#9F9F9F"} lineHeight={"140%"} fontWeight={"500"} fontSize={"16px"} > We won't recommend this nudge to you again </Text>
                            <Button variant="undo" onClick={ () => {
                undoNudgeAction(nudge?.nudgeId,"down");
               } } > Undo  </Button>
                           </Flex>
                           </Box>
                     </Box>
                    )
                  })}
                  </>
    )
}
export default OpportunityNameFilterView;