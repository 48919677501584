import * as React from "react";

function RightIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M6.23 20.23L8 22L18 12L8 2L6.23 3.77L14.46 12L6.23 20.23Z"
        style={props?.style}
        fill={props?.fill}
      />
    </svg>
  );
}

export default RightIcon;
