import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import AeReturnActivityTag from "./AeReturnActivityTag";
import AeReturnActivityTextTag from "./AeReturnActivityTextTag";
import { getDigitalNudgeIcon, getNudgeIcon } from "utils/methods";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { getDateString } from "utils/constants";

interface AeReturnActivityCardProps {
  row?: boolean;
  largeRow?: boolean;
  entry: any;
}

const AeReturnActivityCard: React.FC<AeReturnActivityCardProps> = ({
  row,
  largeRow,
  entry,
}) => {
  const navigate = useNavigate();
  return (
    <Flex mt="8px" flexDirection={"column"} ml="10px">
      <Flex alignItems={"center"}>
        <Flex mr="22px">
          {entry?.nudgeCategoryType
            ? getNudgeIcon(entry?.nudgeCategoryType, false, true)
            : getDigitalNudgeIcon(entry?.orderType, true)}
        </Flex>
        <Text color="#9F9F9F" fontSize={"12px"} fontWeight={300} as="span">
          {format(
            new Date(entry?.activityDate),
            "h:mm a"
          )}
        </Text>
        <AeReturnActivityTextTag text1={entry?.activityHeadline} />
      </Flex>
      <Flex>
        <Flex width={"32px"} justifyContent="center" mt="10px" mr="22px">
          {row && (
            <Flex
              border="1px solid rgba(0, 0, 0, 0.1)"
              height={largeRow ? "50px" : "40px"}
            />
          )}
        </Flex>
        <Flex top={row && largeRow ? "-15px" : row ? "-10px" : "0px"}>
          <Flex
            gridGap={"4px"}
            fontSize={"11px"}
            fontWeight={300}
            color="#57585C"
            mt="5px"
            alignItems="center"
          >
            <Text
              color="#0094E3"
              as="span"
              cursor="pointer"
              onClick={() => {
                navigate(`/aeReturnActivityHistory/${entry?.stakeholderId}`);
              }}
            >
              {entry?.stakeholderName}
            </Text>{" "}
            {entry?.nudgeType !== "digital" ? (
              <Flex mx="8px" gridGap={"12px"} alignItems="center">
                {entry?.stakeholderName && (
                  <>
                    <Flex
                      border="1px solid rgba(0, 0, 0, 0.1)"
                      height={"12px"}
                    />{" "}
                  </>
                )}
                <Text fontSize={"11px"} fontWeight={400} color="#57585C">
                  Order#{" "}
                  <Text fontWeight={300} color="#9F9F9F" as="span">
                    {entry?.orderId || " -"}
                  </Text>
                </Text>
                <Flex border="1px solid rgba(0, 0, 0, 0.1)" height={"12px"} />
                <Text fontSize={"11px"} fontWeight={400} color="#57585C">
                  {" "}
                  Price{" "}
                  <Text fontWeight={300} color="#9F9F9F" as="span">
                    {`$${entry?.orderPrice}` || " -"}
                  </Text>
                </Text>
                <Flex border="1px solid rgba(0, 0, 0, 0.1)" height={"12px"} />
                <Text fontSize={"11px"} fontWeight={400} color="#57585C">
                  {" "}
                  Schedule Date{" "}
                  <Text fontWeight={300} color="#9F9F9F" as="span">
                    {`${getDateString(entry?.orderSchedule, true)}` || " -"}
                  </Text>
                </Text>
                <AeReturnActivityTag
                  tagBgColor="rgba(36, 179, 0, 0.1)"
                  tagColor="#24B300"
                  tagText={entry?.orderStatus}
                  smallTag
                />
              </Flex>
            ) : (
              <Text>{entry?.context.replace(entry?.stakeholderName, "")}</Text>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AeReturnActivityCard;
