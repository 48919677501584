
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import CompanyScoreReportTable from "./CompanyScoreReportTable";
import DataTable from "components/Elements/DataTable";


function CompanyScoreReportContent() {
    // const loggedInMemberDetails = useAppSelector((state) => state.profileState.profileData);
    // useEffect(() => {

    // }, [loggedInMemberDetails.organizationId]);

    return (

        <Flex flexDirection={"column"} padding="25px 32px" overflowY={"scroll"}>
            {/* <DataTable /> */}
            <CompanyScoreReportTable></CompanyScoreReportTable>
        </Flex>
    );
}

export default CompanyScoreReportContent;
