import { FC, useState } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import DeloitteLogoIcon from "assets/icons/DeloitteLogo";
import MetaLogoIcon from "assets/icons/MetaIcon";
import AmerifirstLogoIcon from "assets/icons/AmerifirstLogo";
import ZendeskLogoIcon from "assets/icons/ZendeskLogo";
import SalesforceButton from "components/SalesforceButton/SalesforceButton";
import GoogleButton from "components/GoogleButton/Googlebutton";
// import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import AELoginForm from "./AELoginForm";
import { axiosHelper } from "utils";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
interface AEMobileSignUp01Props {
    handleSnackbarError: (e) => any ;
    setShowSnackBar: (e) => any;
  showSnackBar: boolean,
  snackbarMessage: string
  }
  
  const AEMobileSignUp01: FC<AEMobileSignUp01Props> = ({handleSnackbarError , setShowSnackBar , showSnackBar , snackbarMessage}) => {
    const journey = useAppSelector((state) => state.globalState.journey);

    const triggerSalesforceCall = async () => {
      window.open(
        `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${
          process.env.REACT_APP_SALESFORCE_CLIENT_ID
        }&redirect_uri=${
          process.env.REACT_APP_SALESFORCE_REDIRECT_URL
        }&state=${encodeURIComponent(JSON.stringify(journey))}`,
        "_self"
      );
    };
  
    const triggerGoogleCall = async () => {
      const result = await axiosHelper({
        url: `google/auth/${encodeURIComponent(JSON.stringify(journey))}`
      });
      if(result.data.success) {
        window.open(
          `${result.data.url}`,
          "_self"
        );
      }
    }
  
    const bookDemo = async () => {
      //@ts-ignore
      // ChiliPiper.submit("evabot", "rapport-intelligence-router", { map: true, domElement: "#root", lead: { firstName: 'Abhishek', email: 'abhishek@evabot.ai' } });
      window.open(
        "https://evabot.chilipiper.com/book/rapport-intelligence-queue",
        "targetWindow",
        `toolbar=no,
       location=no,
       status=no,
       menubar=no,
       scrollbars=yes,
       resizable=yes,
       width=800,
       height=550`
      );
    };
    return (
        <>
        <SnackBar
        iconName={"info"}
        message={"Login Error"}
        setShowSnackBar={setShowSnackBar}
        show={showSnackBar}
        subHeading={snackbarMessage}
        timeout={4000}
        anchor={"top-right"}
        type='error'
      />
        <Flex flexDirection="column" alignItems={"center"} width="100%" >

        <Grid mt={"48px"} width={"calc(100vw - 32px)"} >
         <Box textAlign={"center"} width={"100%"} mb={"35px"} >
        <svg width="106" height="32" viewBox="0 0 106 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16 28.4996C15.7327 28.5046 15.463 28.4653 15.2032 28.3799C11.1552 27.0492 8.05694 24.6451 5.90657 22.3819C3.79495 20.1594 2.44055 17.9042 2.00157 16.6518C1.62384 15.5741 0.984161 13.0787 1.0003 10.6069C1.00836 9.37226 1.17938 7.9681 1.76909 6.71316C2.38955 5.39277 3.50439 4.19795 5.28453 3.68273C7.60346 3.01156 9.57562 4.31474 10.8229 5.55523C12.1607 6.88576 13.3614 8.78904 14.3762 10.9077C14.9642 12.1352 15.5117 13.4816 16 14.9041C16.4883 13.4816 17.0358 12.1352 17.6238 10.9077C18.6386 8.78904 19.8393 6.88576 21.1771 5.55523C22.4244 4.31474 24.3965 3.01156 26.7155 3.68273C28.4956 4.19795 29.6104 5.39277 30.2309 6.71316C30.8206 7.9681 30.9916 9.37226 30.9997 10.6069C31.0158 13.0787 30.3762 15.5741 29.9984 16.6518C29.5595 17.9042 28.205 20.1594 26.0934 22.3819C23.9431 24.6451 20.8448 27.0492 16.7968 28.3799C16.537 28.4653 16.2673 28.5046 16 28.4996ZM13.2357 22.631C11.6932 21.6897 10.3851 20.5676 9.32117 19.4478C7.49453 17.5253 6.54793 15.7828 6.36741 15.2678C6.10085 14.5073 5.59003 12.5 5.60221 10.6341C5.60831 9.69991 5.74616 8.97645 5.97065 8.49871C6.14614 8.12526 6.33061 7.9728 6.55358 7.89246C6.65902 7.92056 6.96157 8.0475 7.49679 8.5798C8.33274 9.41119 9.27816 10.8218 10.1882 12.7219C11.5231 15.5087 12.6411 19.05 13.2357 22.631ZM6.51057 7.88533C6.51051 7.88479 6.51612 7.88469 6.52758 7.88665C6.51637 7.88685 6.51063 7.88587 6.51057 7.88533ZM18.7643 22.631C20.3068 21.6897 21.6149 20.5676 22.6788 19.4478C24.5055 17.5253 25.4521 15.7828 25.6326 15.2678C25.8992 14.5073 26.41 12.5 26.3978 10.6341C26.3917 9.69991 26.2538 8.97645 26.0293 8.49871C25.8539 8.12526 25.6694 7.9728 25.4464 7.89246C25.341 7.92056 25.0384 8.0475 24.5032 8.5798C23.6673 9.41119 22.7218 10.8218 21.8118 12.7219C20.4769 15.5087 19.3589 19.05 18.7643 22.631ZM25.4894 7.88533C25.4894 7.88587 25.4836 7.88684 25.4724 7.88665C25.4839 7.88469 25.4895 7.88479 25.4894 7.88533Z" fill="#DD015B"/>
<path d="M47.02 17.5298C47.02 17.9298 46.9933 18.2898 46.94 18.6098H38.84C38.9067 19.4098 39.1867 20.0364 39.68 20.4898C40.1733 20.9431 40.78 21.1698 41.5 21.1698C42.54 21.1698 43.28 20.7231 43.72 19.8298H46.74C46.42 20.8964 45.8067 21.7764 44.9 22.4698C43.9933 23.1498 42.88 23.4898 41.56 23.4898C40.4933 23.4898 39.5333 23.2564 38.68 22.7898C37.84 22.3098 37.18 21.6364 36.7 20.7698C36.2333 19.9031 36 18.9031 36 17.7698C36 16.6231 36.2333 15.6164 36.7 14.7498C37.1667 13.8831 37.82 13.2164 38.66 12.7498C39.5 12.2831 40.4667 12.0498 41.56 12.0498C42.6133 12.0498 43.5533 12.2764 44.38 12.7298C45.22 13.1831 45.8667 13.8298 46.32 14.6698C46.7867 15.4964 47.02 16.4498 47.02 17.5298ZM44.12 16.7298C44.1067 16.0098 43.8467 15.4364 43.34 15.0098C42.8333 14.5698 42.2133 14.3498 41.48 14.3498C40.7867 14.3498 40.2 14.5631 39.72 14.9898C39.2533 15.4031 38.9667 15.9831 38.86 16.7298H44.12Z" fill="black"/>
<path d="M53.6838 20.7298L56.4837 12.2298H59.4638L55.3638 23.3098H51.9637L47.8837 12.2298H50.8837L53.6838 20.7298Z" fill="black"/>
<path d="M60.3164 17.7298C60.3164 16.6098 60.5364 15.6164 60.9764 14.7498C61.4297 13.8831 62.0364 13.2164 62.7964 12.7498C63.5697 12.2831 64.4297 12.0498 65.3764 12.0498C66.2031 12.0498 66.9231 12.2164 67.5364 12.5498C68.1631 12.8831 68.6631 13.3031 69.0364 13.8098V12.2298H71.8564V23.3098H69.0364V21.6898C68.6764 22.2098 68.1764 22.6431 67.5364 22.9898C66.9097 23.3231 66.1831 23.4898 65.3564 23.4898C64.4231 23.4898 63.5697 23.2498 62.7964 22.7698C62.0364 22.2898 61.4297 21.6164 60.9764 20.7498C60.5364 19.8698 60.3164 18.8631 60.3164 17.7298ZM69.0364 17.7698C69.0364 17.0898 68.9031 16.5098 68.6364 16.0298C68.3697 15.5364 68.0097 15.1631 67.5564 14.9098C67.1031 14.6431 66.6164 14.5098 66.0964 14.5098C65.5764 14.5098 65.0964 14.6364 64.6564 14.8898C64.2164 15.1431 63.8564 15.5164 63.5764 16.0098C63.3097 16.4898 63.1764 17.0631 63.1764 17.7298C63.1764 18.3964 63.3097 18.9831 63.5764 19.4898C63.8564 19.9831 64.2164 20.3631 64.6564 20.6298C65.1097 20.8964 65.5897 21.0298 66.0964 21.0298C66.6164 21.0298 67.1031 20.9031 67.5564 20.6498C68.0097 20.3831 68.3697 20.0098 68.6364 19.5298C68.9031 19.0364 69.0364 18.4498 69.0364 17.7698Z" fill="black"/>
<path d="M76.1911 14.8098C76.5511 14.0498 77.1044 13.4298 77.8511 12.9498C78.6111 12.4698 79.5044 12.2298 80.5311 12.2298C81.5311 12.2298 82.4244 12.4631 83.2111 12.9298C83.9978 13.3831 84.6111 14.0364 85.0511 14.8898C85.5044 15.7298 85.7311 16.7098 85.7311 17.8298C85.7311 18.9498 85.5044 19.9364 85.0511 20.7898C84.6111 21.6431 83.9911 22.3031 83.1911 22.7698C82.4044 23.2364 81.5178 23.4698 80.5311 23.4698C79.4911 23.4698 78.5911 23.2364 77.8311 22.7698C77.0844 22.2898 76.5378 21.6698 76.1911 20.9098V23.3098H74.8111V8.50977H76.1911V14.8098ZM84.3111 17.8298C84.3111 16.9231 84.1311 16.1431 83.7711 15.4898C83.4244 14.8231 82.9444 14.3164 82.3311 13.9698C81.7178 13.6231 81.0244 13.4498 80.2511 13.4498C79.5044 13.4498 78.8178 13.6298 78.1911 13.9898C77.5778 14.3498 77.0911 14.8631 76.7311 15.5298C76.3711 16.1964 76.1911 16.9698 76.1911 17.8498C76.1911 18.7298 76.3711 19.5031 76.7311 20.1698C77.0911 20.8364 77.5778 21.3498 78.1911 21.7098C78.8178 22.0698 79.5044 22.2498 80.2511 22.2498C81.0244 22.2498 81.7178 22.0764 82.3311 21.7298C82.9444 21.3698 83.4244 20.8564 83.7711 20.1898C84.1311 19.5098 84.3111 18.7231 84.3111 17.8298Z" fill="black"/>
<path d="M93.0872 23.4698C92.0605 23.4698 91.1339 23.2431 90.3072 22.7898C89.4939 22.3231 88.8472 21.6698 88.3672 20.8298C87.9005 19.9764 87.6672 18.9831 87.6672 17.8498C87.6672 16.7164 87.9072 15.7298 88.3872 14.8898C88.8672 14.0364 89.5205 13.3831 90.3472 12.9298C91.1739 12.4631 92.1005 12.2298 93.1272 12.2298C94.1539 12.2298 95.0805 12.4631 95.9072 12.9298C96.7472 13.3831 97.4005 14.0364 97.8672 14.8898C98.3472 15.7298 98.5872 16.7164 98.5872 17.8498C98.5872 18.9698 98.3472 19.9564 97.8672 20.8098C97.3872 21.6631 96.7272 22.3231 95.8872 22.7898C95.0472 23.2431 94.1139 23.4698 93.0872 23.4698ZM93.0872 22.2498C93.8072 22.2498 94.4739 22.0898 95.0872 21.7698C95.7005 21.4364 96.1939 20.9431 96.5672 20.2898C96.9539 19.6231 97.1472 18.8098 97.1472 17.8498C97.1472 16.8898 96.9605 16.0831 96.5872 15.4298C96.2139 14.7631 95.7205 14.2698 95.1072 13.9498C94.4939 13.6164 93.8272 13.4498 93.1072 13.4498C92.3872 13.4498 91.7205 13.6164 91.1072 13.9498C90.4939 14.2698 90.0005 14.7631 89.6272 15.4298C89.2672 16.0831 89.0872 16.8898 89.0872 17.8498C89.0872 18.8098 89.2672 19.6231 89.6272 20.2898C90.0005 20.9431 90.4872 21.4364 91.0872 21.7698C91.7005 22.0898 92.3672 22.2498 93.0872 22.2498Z" fill="black"/>
<path d="M102.959 13.5698V20.3498C102.959 21.0164 103.086 21.4764 103.339 21.7298C103.593 21.9831 104.039 22.1098 104.679 22.1098H105.959V23.3098H104.459C103.473 23.3098 102.739 23.0831 102.259 22.6298C101.779 22.1631 101.539 21.4031 101.539 20.3498V13.5698H100.019V12.3898H101.539V9.64977H102.959V12.3898H105.959V13.5698H102.959Z" fill="black"/>
</svg>
</Box>
          <Text
            marginBottom={24}
            fontSize="20px"
            fontWeight={300}
            color={"#050505"}
            lineHeight={"140%"}
            textAlign="center"
          >
            Get Started
          </Text>

          {/* <Box width="320px" mb="16px">
            <SalesforceButton onClick={triggerSalesforceCall} />
          </Box> */}
          <Box width="100%" mb="16px" border={"1px solid #0094E3"} borderRadius={"8px"} >
            <GoogleButton onClick={triggerGoogleCall} />
          </Box>

          <Text color="#818181" variant="bodyRegular" mb="14px" mt="24px">
            Or use your email to continue
          </Text>
          <AELoginForm handleSnackbarError={handleSnackbarError}/>
        </Grid>
      </Flex>
      <Flex flexDirection={"column"}>
        <Flex justifyContent={"center"}>
          {
            //@ts-ignore
            <Text
              id="trustedbyteams"
              fontSize={12}
              color={"white"}
              height={"auto"}
            />
          }
        </Flex>
        <Flex justifyContent={"space-evenly"} mt={34} mb={50}>
          <DeloitteLogoIcon />
          <ZendeskLogoIcon />
          <MetaLogoIcon />
          <AmerifirstLogoIcon />
        </Flex>
      </Flex>
      </>
    )
};

export default AEMobileSignUp01;