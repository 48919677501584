import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

const initialState = {
  journey: {
    journeyName: "admin",
    journeyPath: "1",
  },
  progress: 0,
  name: "",
  recommendedNudges: [],
  stakeholders: [],
  stakeholderLoading: true,
  stakeholderDataChange: false,
  stakeholderPerPage: 10,
  stakeholderPageNo: 0,
  selectedNudge: null,
  stakeholderSnackbar: {
    show: false,
    message: "",
  },
  isLoggedIn: false,
  menuItems: [],
  prospectCount: "",
  accountCount: "",
  accessMenu: []
};

interface GlobalStateJourneyReducerType {
  journeyName: string;
  journeyPath: string;
}

const globalStateReducerSlice = createSlice({
  name: "globalState",
  initialState: initialState,
  reducers: {
    setGlobalStateRole: (
      state,
      action: PayloadAction<GlobalStateJourneyReducerType>
    ) => {
      state.journey = action.payload;
    },
    setGlobalProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    setLoggedInUserName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setRecommendedNudgesData: (state, action: PayloadAction<[]>) => {
      state.recommendedNudges = action.payload;
    },
    setSelectedNudge: (state, action: PayloadAction<[]>) => {
      state.selectedNudge = action.payload;
    },
    setStakeholdersData: (state, action: PayloadAction<[]>) => {
      state.stakeholders = action.payload;
    },
    setStakeholdersLoadingData: (state, action: PayloadAction<[]>) => {
      //@ts-ignore
      state.stakeholderLoading = action.payload;
    },
    setStakeholderDataChange: (state, action: PayloadAction<[]>) => {
      //@ts-ignore
      state.stakeholderDataChange = action.payload;
    },
    setStakeholderPerPage: (state, action: PayloadAction<[]>) => {
      //@ts-ignore
      state.stakeholderPerPage = action.payload;
    },
    setstakeholderSnackbar: (state, action: PayloadAction<[]>) => {
      //@ts-ignore
      state.stakeholderSnackbar = action.payload;
    },
    setstakeholderpageNo: (state, action: PayloadAction<[]>) => {
      //@ts-ignore
      state.stakeholderPageNo = action.payload;
    },
    setLoggedInState: (state, action: { type: string; payload: boolean }) => {
      state.isLoggedIn = action.payload;
    },

    setMenuItems: (state, action) => {
      state.menuItems = action.payload;
    },

    setProspectCount: (state, action: PayloadAction<string>) => {
      state.prospectCount = action.payload;
    },

    setAccountCount: (state, action: PayloadAction<string>) => {
      state.accountCount = action.payload;
    },

    setUserMenuAccess: (state, action) => {
      state.accessMenu = action.payload;
    }
  },
});

export const {
  setGlobalStateRole,
  setGlobalProgress,
  setLoggedInUserName,
  setRecommendedNudgesData,
  setSelectedNudge,
  setStakeholdersData,
  setStakeholdersLoadingData,
  setStakeholderDataChange,
  setStakeholderPerPage,
  setstakeholderSnackbar,
  setstakeholderpageNo,
  setLoggedInState,
  setMenuItems,
  setProspectCount,
  setAccountCount,
  setUserMenuAccess
} = globalStateReducerSlice.actions;

export const selectGlobalState = (state: RootState) => state.globalState;

export default globalStateReducerSlice.reducer;
