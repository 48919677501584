import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

const addNewField: AddFieldType[] = [];

const defaultState = {
  opportunity: {
    opportunityName: "",
    opportunityValue: "",
    opportunityStage: "",
    opportunityID: "",
    assignedDate: "",
    assignedTo: "",
    expectedCloseDate: "",
  },
  accounts: {
    companyName: "",
    industry: "",
    website: "",
    phone: "",
    accountID: "",
  },
  leads: {
    leadName: "",
    emailAddress: "",
    phoneNumber: "",
    designation: "",
    activities: "",
    lastAssignedDate: "",
    leadID: "",
  },
  contact: {
    contactID: "",
    name: "",
    emailAddress: "",
    phone: "",
    designation: "",
  },
  users: {
    name: "",
    emailAddress: "",
    phone: "",
    designation: "",
    userId: "",
  },
  opportunityStage: {
    early: [],
    mid: [],
    late: [],
  },
  activity: {
    whoId: "",
    whatId: "",
    activityName: "",
    activityDescription: "",
    activityType: "",
  },
};

const initialState = {
  opportunity: {
    opportunityName: "",
    opportunityValue: "",
    opportunityStage: "",
    opportunityID: "",
    assignedDate: "",
    assignedTo: "",
    expectedCloseDate: "",
  },
  accounts: {
    companyName: "",
    industry: "",
    website: "",
    phone: "",
    accountID: "",
  },
  leads: {
    leadName: "",
    emailAddress: "",
    phoneNumber: "",
    designation: "",
    activities: "",
    lastAssignedDate: "",
    leadID: "",
  },
  contact: {
    contactID: "",
    name: "",
    emailAddress: "",
    phone: "",
    designation: "",
  },
  users: {
    name: "",
    emailAddress: "",
    phone: "",
    designation: "",
    userId: "",
  },
  opportunityStage: {
    early: [],
    mid: [],
    late: [],
  },
  activity: {
    whoId: "",
    whatId: "",
    activityName: "",
    activityDescription: "",
    activityType: "",
  },

  defaultState: defaultState,

  addNewFields: addNewField,
};

export interface AddFieldType {
  addFieldName: string;
  addFieldValue: string;
}

export interface FieldMappingOpportunityState {
  opportunityName: string;
  opportunityValue: string;
  opportunityStage: string;
  opportunityID: string;
  assignedDate: string;
  assignedTo: string;
  expectedCloseDate: string;
}

export interface FieldMappingAccountState {
  companyName: string;
  industry: string;
  website: string;
  phone: string;
  accountID: string;
}

export interface FieldMappingLeadsState {
  leadName: string;
  emailAddress: string;
  phoneNumber: string;
  designation: string;
  activities: string;
  lastAssignedDate: string;
  leadID: string;
}

export interface FieldMappingContactState {
  contactID: string;
  name: string;
  emailAddress: string;
  phone: string;
  designation: string;
}

export interface FieldMappingUsersState {
  name: string;
  emailAddress: string;
  phone: string;
  designation: string;
  userId: string;
}

export interface FieldMappingOpportunityStageState {
  early: string[];
  mid: string[];
  late: string[];
}

export interface FieldMappingActivityState {
  whoId: string;
  whatId: string;
  activityName: string;
  activityDescription: string;
  activityType: string;
}

const fieldMappingReducerSlice = createSlice({
  name: "fieldMapping",
  initialState: initialState,
  reducers: {
    setAddField: (state, action: PayloadAction<AddFieldType>) => {
      // @ts-ignore
      state.addNewFields = [...state.addNewFields, action.payload];
    },
    setEditField: (state, action: PayloadAction<AddFieldType>) => {
      // @ts-ignore
      state.addNewFields = [...action.payload];
    },
    setFieldMappingOpportunityDefault: (
      state,
      action: PayloadAction<FieldMappingOpportunityState>
    ) => {
      state.defaultState.opportunity = action.payload;
    },
    setFieldMappingOpportunity: (
      state,
      action: PayloadAction<FieldMappingOpportunityState>
    ) => {
      state.opportunity = action.payload;
    },
    setFieldMappingAccountsDefault: (
      state,
      action: PayloadAction<FieldMappingAccountState>
    ) => {
      state.defaultState.accounts = action.payload;
    },
    setFieldMappingAccounts: (
      state,
      action: PayloadAction<FieldMappingAccountState>
    ) => {
      state.accounts = action.payload;
    },
    setFieldMappingLeadsDefault: (
      state,
      action: PayloadAction<FieldMappingLeadsState>
    ) => {
      state.defaultState.leads = action.payload;
    },
    setFieldMappingLeads: (
      state,
      action: PayloadAction<FieldMappingLeadsState>
    ) => {
      state.leads = action.payload;
    },
    setFieldMappingContactDefault: (
      state,
      action: PayloadAction<FieldMappingContactState>
    ) => {
      state.defaultState.contact = action.payload;
    },
    setFieldMappingContact: (
      state,
      action: PayloadAction<FieldMappingContactState>
    ) => {
      state.contact = action.payload;
    },
    setFieldMappingUsersDefault: (
      state,
      action: PayloadAction<FieldMappingUsersState>
    ) => {
      state.defaultState.users = action.payload;
    },
    setFieldMappingUsers: (
      state,
      action: PayloadAction<FieldMappingUsersState>
    ) => {
      state.users = action.payload;
    },
    setFieldMappingOpportunityStageDefault: (
      state,
      action: PayloadAction<FieldMappingOpportunityStageState>
    ) => {
      state.defaultState.opportunityStage = action.payload;
    },
    setFieldMappingOpportunityStage: (
      state,
      action: PayloadAction<FieldMappingOpportunityStageState>
    ) => {
      state.opportunityStage = action.payload;
    },
    setFieldMappingActivityDefault: (
      state,
      action: PayloadAction<FieldMappingActivityState>
    ) => {
      state.defaultState.activity = action.payload;
    },
    setFieldMappingActivity: (
      state,
      action: PayloadAction<FieldMappingActivityState>
    ) => {
      state.activity = action.payload;
    },
  },
});

export const {
  setFieldMappingOpportunityDefault,
  setFieldMappingOpportunity,
  setFieldMappingAccountsDefault,
  setFieldMappingAccounts,
  setFieldMappingLeadsDefault,
  setFieldMappingLeads,
  setFieldMappingContactDefault,
  setFieldMappingContact,
  setFieldMappingUsersDefault,
  setFieldMappingUsers,
  setFieldMappingOpportunityStageDefault,
  setFieldMappingOpportunityStage,
  setFieldMappingActivityDefault,
  setFieldMappingActivity,
  setAddField,
  setEditField,
} = fieldMappingReducerSlice.actions;

export const selectFieldMappingAllFields = (state: RootState) =>
  state.fieldMapping;

export default fieldMappingReducerSlice.reducer;
