import * as React from "react";

function FilterListIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M6.667 12h2.666v-1.333H6.667V12zM2 4v1.333h12V4H2zm2 4.667h8V7.333H4v1.334z"
        fill="#A8B0B9"
      />
    </svg>
  );
}

export default FilterListIcon;
