function AdminReportHeader() {
    return (
        <thead>
            <tr>
                <th className="fixed-header">Dates</th>
                <th className="fixed-header" style={{textAlign:"center", width:"16%", borderWidth:"1px"}}  >Total Prospects</th>
                <th className="fixed-header" style={{textAlign:"center", width:"16%"}} >Total Accounts</th>
                <th className="fixed-header" style={{textAlign:"center", width:"16%"}}>Total Emails</th>
                <th className="fixed-header" style={{textAlign:"center", width:"16%"}}>Total Call Preps</th>
                <th className="fixed-header" style={{ backgroundColor: "lightgrey", textAlign:"center",width:"16%" }}>Total</th>
            </tr>
        </thead>
    )
}
export default AdminReportHeader;