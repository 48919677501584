import { AeReturnJourneyLayout } from "@evabot/eva-react-core-library/dist/templates/AeReturnJourneyLayout/AeReturnJourneyLayout";
import { AeSidebarAdminSection } from "components/AeSidebar/AeSidebarAdminSection";
import { AeSidebarAdminSectionCollapse } from "components/AeSidebar/AeSidebarAdminSectionCollapse";
import ProfileModalData from "containers/AEReturnDashboard/ProfileModalData";
import { AdminDashboardRightSection } from "./AdminDashboardRightSection";

function AdminDashboard() {
  return (
    <>
      <AeReturnJourneyLayout
        as="div"
        left={20}
        right={82}
        leftChildren={<AeSidebarAdminSection activeTab="nudges" />}
        leftCollapsedChildren={
          <AeSidebarAdminSectionCollapse activeTab="nudges" />
        }
        rightChildren={<AdminDashboardRightSection />}
        profileModalChild={<ProfileModalData />}
      />
    </>
  );
}

export default AdminDashboard;
