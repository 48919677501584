import React, { useCallback } from "react";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import {
  ModalWrapper,
  modalWrapperProps,
} from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { FormCheckbox } from "@evabot/eva-react-core-library/dist/atoms/FormikCheckBox/FormikCheckBox";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { aeReturnUnlikeModalData } from "./store/aeReturnUnlikeModalReducer";
import { useAppDispatch, useAppSelector } from "hooks";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { TextArea } from "@evabot/eva-react-core-library/dist/atoms/TextArea/TextArea";
import { FormikTextArea } from "@evabot/eva-react-core-library/dist/atoms/FormikTextArea/FormikTextArea";
export interface AeReturnUnlikeModalProps extends modalWrapperProps {
  action?: boolean;
  nudge?: any;
  closeModal: any;
  screenSize : any;
  updateNudgeFeedback?: (
    nudge: any,
    feedback: any,
    comment: any
  ) => Promise<void>;
}

export const AeReturnUnlikeModal: React.FC<AeReturnUnlikeModalProps> = ({
  width,
  height,
  hideModal,
  setHideModal,
  nudge,
  updateNudgeFeedback,
  closeModal,
  screenSize ,
}) => {
  const dispatch = useAppDispatch();

  const selectedNudge = useAppSelector(
    (state) => state.aeReturnSelectedNudge.selectedNudge
  );

  const onSubmitHandler = async (values: any) => {
    console.log(values, selectedNudge);
    dispatch(aeReturnUnlikeModalData(values));
    let comment = "";
    if (values.nudgeBoring) {
      comment += "boring,";
    }
    if (values.nudgeImpactful) {
      comment += "not impactful,";
    }
    if (values.nudgeRepetitive) {
      comment += "repetitive,";
    }
    if (values.nudgeHide) {
      comment += "hide,";
    }
    if (values.others) {
      comment = values.otherText;
    }
    await updateNudgeFeedback(selectedNudge, "down", comment);
    closeModal();
  };

  const opportunitySchema = yup.object().shape({
    others: yup.bool(),
    otherText: yup.string().when("others", {
      is: true,
      then: yup
        .string()
        .nullable()
        .required("required.field")
        .min(3, "aeReturn.unlikeModal.min.valid"),
    }),
  });
  const defaultValues = {
    nudgeRepetitive: false,
    nudgeImpactful: false,
    nudgeBoring: false,
    nudgeHide: false,
    others: false,
    otherText: "",
  };
  return (
    <ModalWrapper
      width={width}
      height={height}
      hideModal={hideModal}
      setHideModal={setHideModal}
      bgcolor={"rgba(0, 0, 0, 0.8)"}
      padding="48px"
      
    >
      <Grid  position={"relative"} padding={ screenSize.dynamicWidth <= 576 ?  "16px":  "0px"} marginTop={ screenSize.dynamicWidth <= 576 ? "14px": "0px" } >
        {/* <Box position={"absolute"} zIndex={1000} right={"10px"} top={"10px"} cursor={"pointer"} height={"10px"} width={"20px"} onClick={ () => closeModal() } >
        <Icon name="closeIcon" fill="black" height={"100%"} width={"100%"}  />
  </Box> */}
        <Flex flexDirection={"column"} >
        <Text
          color={"#050505"}
          fontWeight={ screenSize.dynamicWidth > 576 ? "400" : "300" }
          fontSize={ screenSize.dynamicWidth > 576 ? "24px":  "20px" }
          lineHeight={  "140%" } 
          
          // variant="pageHeadingMedium"
          // color="textColor.primary"
          // fontWeight={"bold"}
          // lineHeight={"33px"}
        >
          Tell us why you don't like this nudge
        </Text>
        <Text
          color={"#050505"}
          fontWeight={"300"}
          fontSize={"14px"}
          lineHeight={"160%"}
          // variant="imputText"
          // color="textColor.primary"
          mt="5px"
        >
          Your feedback will help us improve your nudge feed
        </Text>
        <Formik
          initialValues={defaultValues}
          onSubmit={onSubmitHandler}
          enableReinitialize
          validationSchema={opportunitySchema}
        >
          {({
            values,
            submitForm,
            setValues,
            setFieldValue,
            errors,
            dirty,
            isValid,
          }) => {
            return (
              <Form >
                <>{console.log(values, "changed values", errors)}</>
                <Grid mt={ screenSize.dynamicWidth <= 576 ? "8px" :"0px" } height={"100%"} >
                  
                  
                  <Flex mt={"25px"}  alignItems={"center"}>
                    <span>
                      <FormCheckbox
                        name="nudgeRepetitive"
                        variant="secondary"
                        checked={values?.nudgeRepetitive}
                      />
                    </span>
                    <Text
                      variant="bodyRegular"
                      color="textColor.primary"
                      ml="10px"
                    >
                      This recommendation is repetitive
                    </Text>
                  </Flex>
                  <Flex mt={"25px"} alignItems={"center"}>
                    <span>
                      <FormCheckbox name="nudgeImpactful" variant="secondary" />
                    </span>
                    <Text
                      variant="bodyRegular"
                      color="textColor.primary"
                      ml="10px"
                    >
                      This recommendation isn't very impactful
                    </Text>
                  </Flex>
                  <Flex mt={"25px"}  alignItems={"center"}>
                    <span>
                      <FormCheckbox name="nudgeBoring" variant="secondary" />
                    </span>
                    <Text
                      variant="bodyRegular"
                      color="textColor.primary"
                      ml="10px"
                    >
                      This recommendation doesn't make sense
                    </Text>
                  </Flex>
                  <Flex mt="25px" alignItems={"center"} mb="15px">
                    <span>
                      <FormCheckbox name="others" variant="secondary" />
                    </span>
                    <Text
                      variant="bodyRegular"
                      color="textColor.primary"
                      ml="10px"
                    >
                      Other
                    </Text>
                  </Flex>
                  {values?.others && (
                    <Box my="10px">
                      <FormikTextArea
                        name="otherText"
                        placeholder="typeHere"
                        width={ screenSize.dynamicWidth > 576 ? "406px" : "80%" }
                        height={"111px"}
                      />
                    </Box>
                  )}
                 
                  
                 
                 <Flex alignItems={  "baseline" } >
                    <Box width={"200px"} height="46px">
                      <Button
                        fontWeight={"bold"}
                        fontSize="16px"
                        fullSize
                        variant="primary"
                        borderRadius={"12px"}
                        mt="5px"
                        onClick={submitForm}
                        disabled={!dirty || !isValid}
                      >
                        Submit
                      </Button>
                    </Box>
                    <Flex
                      width={"200px"}
                      height="46px"
                      justifyContent="center"
                      alignItems={"center"}
                      cursor="pointer"
                      onClick={() => {
                        closeModal();
                        // setFieldValue("nudgeRepetitive", false);
                        // setFieldValue("nudgeImpactful", false);
                        // setFieldValue("nudgeBoring", false);
                        // setFieldValue("nudgeHide",false);
                        // setFieldValue("others", false);
                        // setFieldValue("otherText", "");
                      }}
                      color="#DD015B"
                      fontWeight={"bold"}
                      fontSize="16px"
                    >
                      {/* Undo */}
                      Cancel
                    </Flex>
                  </Flex>
                  
                   
                </Grid>
              </Form>
            );
          }}
        </Formik>
</Flex>
      </Grid>
    </ModalWrapper>
  );
};
