import { Button } from '@evabot/eva-react-core-library/dist/atoms/Button/Button'
import { Flex } from '@evabot/eva-react-core-library/dist/atoms/Flex/Flex'
import { Icon } from '@evabot/eva-react-core-library/dist/atoms/Icon/Icon'
import React, { FC ,useState} from 'react';
import { useNavigate } from "react-router-dom";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text"
import ConfirmationModal from "@evabot/eva-react-core-library/dist/molecules/ConfirmationModal/ConfirmationModal"

interface SendOpportunityFooterProps { }

const SendOpportunityFooter: FC<SendOpportunityFooterProps> = () => {
    const navigate = useNavigate();
    const [hide,setHide]=useState(true);

    const confirmationModalHandler = () => {
        setHide(!hide);
      };
    return (
        <>
          <ConfirmationModal
        width="400px"
        height="150px"
        open={hide}
        onClose={confirmationModalHandler}
      >
        <Flex flexDirection="column" gap="20px">
          <Text id="not.important" fontSize="20px" fontWeight="500" />
          <Text id="popup.message" fontSize="16px" fontWeight="400" />
          <Flex justifyContent="space-between">
            <Button
              title="confirm.yes"
              variant="primary"
              onClick={() => confirmationModalHandler()}
            />
            <Button
              title="confirm.no"
              variant="primary"
              onClick={() => confirmationModalHandler()}
            />
          </Flex>
        </Flex>
      </ConfirmationModal>
        <Flex 
            justifyContent={"space-between"} 
            width="100%" borderTop="1px solid rgba(0, 0, 0, 0.1)" 
            padding="24px 0px 0px 0px" 
            alignItems="center" 
        >
            <Flex gap="10px" alignItems="center">
                <Icon name="lightBulb" />
                <Flex flexDirection="column" gap="10px">
                    <Text variant="imputLabel">
                        0/5 first email sent
                    </Text>
                    <Text fontSize="12px" fontWeight="500" color="#57585C">
                        Send first email to as many stakeholders as possible to increase the chances of closing the deal
                    </Text>
                </Flex>

            </Flex>
            <Button
               
                onClick={() => setHide(!hide)}
                size="m"
                padding="12px"
                variant="primary"
                iconName="arrowRight"
                iconAlign="right"
                paddingBottom="12px"
                paddingLeft="32px"
                paddingRight="32px"
                paddingTop="12px"
            >
                Next step: Send first email
            </Button>
        </Flex>
        </>
    )
}

export default SendOpportunityFooter