import * as React from "react";

function ProfileEditIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_21_6142)">
        <path
          d="M15.053 3.698l-2.609-2.622a.92.92 0 00-1.297 0l-9.25 9.235-.844 3.645a.915.915 0 00.89 1.11.954.954 0 00.19 0l3.685-.844 9.235-9.226a.92.92 0 000-1.298zm-9.68 9.724l-3.453.725.787-3.387 6.92-6.893 2.666 2.666-6.92 6.89zM12.89 5.89l-2.667-2.667 1.547-1.538 2.622 2.667L12.89 5.89z"
          fill="#0094E3"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_21_6142">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ProfileEditIcon;
