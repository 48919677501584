import {
  c1PrefMap,
  coffeePrefArray,
  teaPrefArray,
  otherDrinksPrefArray,
  alcoholPrefArray,
  c3PrefMap,
  cuisinePrefArray,
  petsArray
} from "../constants";

export const capitalizeString = (string) =>
  string
    .split(" ")
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join(" ");

export const getCoffeeString = (string) => {
  if (Object.keys(c1PrefMap).includes(string)) {
    return c1PrefMap[string];
  } else if (coffeePrefArray.includes(string) || teaPrefArray.includes(string)) {
    return string;
  } else if (!otherDrinksPrefArray.includes(string)) {
    return capitalizeString(string);
  }
};

export const getOtherC1String = (string) => {
  if (otherDrinksPrefArray.includes(string)) {
    return string;
  } else if (
    !coffeePrefArray.includes(string) &&
    !Object.keys(c1PrefMap).includes(string) &&
    !teaPrefArray.includes(string)
  ) {
    return capitalizeString(string);
  }
};

export const getAlcoholString = (string) => {
  if (Object.keys(c3PrefMap).includes(string)) {
    return c3PrefMap[string];
  }

  if (alcoholPrefArray.includes(string)) {
    return string;
  }
  return capitalizeString(string);
};

export const getC4MappedString = (string, property) => {
    try {
        let newStr = string.replaceAll('\'', '\"');
        let obj = JSON.parse(newStr);
        if(obj[property]) {
            return obj[property].join(', ')
        }
        return '';
    }
    catch(err) {
        return '';
    }
};

export const getCuisineString = (string) => {
    if(cuisinePrefArray.includes(string)) {
        return string;
    }
    return capitalizeString(string);
};

export const getPetsString = (string) => {
    if(petsArray.includes(string)) {
        return string;
    }
    return string;
}
