import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

const initialState = {
  orderStatus: {
    delivered: false,
    shipped: false,
    packaging: false,
    scheduled: false,
    cancelled: false,
  },
  filters: {
    birthday: false,
    weather: false,
    music: false,
    sports: false,
    places: false,
    treatTea: false,
    alcohol: false,
    gifts: false,
    flowers: false,
    meals: false,
    cake: false,
    fanGearTickets: false,
    copied: false,
    liked: false,
  },
};

export interface aeReturnActivityOrderReducerType {
  delivered: boolean;
  shipped: boolean;
  packaging: boolean;
  scheduled: boolean;
  cancelled: boolean;
}

export interface aeReturnActivityFilterReducerType {
  birthday: boolean;
  weather: boolean;
  music: boolean;
  sports: boolean;
  places: boolean;
  treatTea: boolean;
  alcohol: boolean;
  gifts: boolean;
  flowers: boolean;
  meals: boolean;
  cake: boolean;
  fanGearTickets: boolean;
  copied: boolean;
  liked: boolean;
}

const aeReturnActivityReducerSlice = createSlice({
  name: "activityState",
  initialState: initialState,
  reducers: {
    aeReturnActivityOrderData: (
      state,
      action: PayloadAction<aeReturnActivityOrderReducerType>
    ) => {
      state.orderStatus = action.payload;
    },
    aeReturnActivityFilterData: (
      state,
      action: PayloadAction<aeReturnActivityFilterReducerType>
    ) => {
      state.filters = action.payload;
    },
  },
});

export const { aeReturnActivityOrderData, aeReturnActivityFilterData } =
  aeReturnActivityReducerSlice.actions;

export const aeReturnActivityState = (state: RootState) =>
  state.aeReturnActivity;

export default aeReturnActivityReducerSlice.reducer;
