import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

const initialState = {
  memberListingData: [],
};

export interface memberListingReducerType {}
const memberListingReducerSlice = createSlice({
  name: "memberListingState",
  initialState: initialState,
  reducers: {
    setMemberListingData: (
      state,
      action: PayloadAction<memberListingReducerType>
    ) => {
      //@ts-ignore
      state.memberListingData = action.payload;
    },
  },
});

export const {
  setMemberListingData,
} = memberListingReducerSlice.actions;

export const memberListingState = (state: RootState) => state.memberListingState;

export default memberListingReducerSlice.reducer;
