import SettingDataHeader from "./SettingDataHeader";
import SettingDataBody from "./SettingDataBody";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";

const SettingDataTable = ({ sequenceList, getSavedSequenceList, handleSequenceEdit }) => {
    return (
        <>
            <div className="py-5 px-8">
                <SettingDataHeader />
                {sequenceList.map((item, index) => (<SettingDataBody key={index} index={index} item={item}  getSavedSequenceList={getSavedSequenceList} handleEdit={handleSequenceEdit} />))}
                <Box borderBottomLeftRadius={"8px"} 
                borderBottomRightRadius={"8px"} background={"#fff"} padding={"5px 5px"}></Box>
            </div>
        </>
    )
}

export default SettingDataTable;