import { useEffect, useState, useRef } from "react";
import AESignupLeftSectionO1 from "./AESignupLeftSectionO1";
import { Backdrop } from "@mui/material";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { SignupLayout } from "@evabot/eva-react-core-library/dist/templates/SignupLayout/SignupLayout";
import { useLocation, useNavigate } from "react-router-dom";
import userService from "services/userService";
import { setLoggedInState } from "store/globalStateReducer";
import { useAppDispatch } from "hooks";
import menuDetails from "utils/menuBuilder";
import { deleteCookie, getCookie } from "utils/cookie";
import AESignupRightSection from "./AESignupRightSection";

const AESsoLogin = () => { 
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [ssoScreen, setSsoScreen] = useState(false);
    const [checkLogin, setCheckLogin] = useState(true);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const hasCheckedLogin = useRef(false);

    const handleSnackbarError = (message) => {
        setShowSnackBar(true);
        setSnackbarMessage(message);
    };

    const setLoginCookie = (token, name) => {
        const expires = new Date();
        expires.setTime(expires.getTime() + (30 * 24 * 60 * 60 * 1000));
        const cookie = `${name}=${token};expires=${expires.toUTCString()};path=/;`;
        document.cookie = cookie;
    };

    const getSsoDetails = async (code) => {
        try {
            setCheckLogin(false);
            const ssoLoginEmail = getCookie('ssologinemail');
            if (!ssoLoginEmail) {
                return;
            }
            const ssoInfo = await userService.getSsoCallbackWorkOS({ code, ssoLoginEmail });
            console.log("ssoInfo", ssoInfo);
            if (ssoInfo?.success) {
                const existingToken = getCookie('evaAuthToken');
                if (existingToken) {
                    deleteCookie('evaAuthToken');
                }
                localStorage.setItem("Authorization", ssoInfo.token);
                localStorage.setItem("organizationId", ssoInfo.organizationId);
                setLoginCookie(ssoInfo.token, "evaAuthToken");
                setLoginCookie(ssoInfo.token, "token");
                dispatch(setLoggedInState(true));
                const firstMenuValue = ssoInfo?.navigateOption?.[0];
                const menuMatch = menuDetails.find(menu => menu.dbValue === firstMenuValue);
                hasCheckedLogin.current = true;
            } else {
                if (ssoInfo?.status === 'email mismatch') {
                    handleSnackbarError(ssoInfo?.message);
                    hasCheckedLogin.current = true;
                } 
                else {
                    if(!hasCheckedLogin.current){
                        handleSnackbarError(ssoInfo?.message);
                    }
                }
                setCheckLogin(false);
                setTimeout(() => {
                    navigate('/aeLogin1');
                }, 6000);
            }
        } catch (error) {
            handleSnackbarError("Unexpected error");
            setTimeout(() => {
                navigate('/aeLogin1');
            }, 3000);
        }
    };

    useEffect(() => {
        if (location.pathname === '/workos/callbackWorkOS' && location?.search && !hasCheckedLogin.current) {
            const params = new URLSearchParams(location.search);
            const code = params.get('code');
            if (code) {
                getSsoDetails(code);
            }else{
                handleSnackbarError("Please check the sso connection/setup");
                setCheckLogin(false);
                setTimeout(() => {
                    navigate('/aeLogin1');
                }, 3000);
            }
        }

    }, []);

    return (
        <SignupLayout
            as="div"
            left={30}
            right={70}
            leftChildren={<AESignupLeftSectionO1 handleSnackbarError={handleSnackbarError} handleSsoScreen={setSsoScreen} />}
            rightChildren={checkLogin ? (
                <Backdrop
                    sx={{ color: "#fff", justifyContent: "center", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                    open={true}
                >
                    <Icon name="loadingIcon" />
                </Backdrop>
            ) : (
                <AESignupRightSection
                    showSnackBar={showSnackBar}
                    setShowSnackBar={setShowSnackBar}
                    snackbarMessage={snackbarMessage}
                    showSsoLayout={ssoScreen}
                />
            )}
        />
    );
};

export default AESsoLogin;
