import EmailTemplateTableBodyField from "./EmailTemplateTableBodyField";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import {  useState } from "react";
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';
import React from "react";

function EmailTemplateTableBody({ data, fetchData}) {
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('success');


  if (!Array.isArray(data) || data.length === 0) {
    console.log("Array not found", data);
    return (
      <Box background="#fff" width="100%">
        <Flex justifyContent="center" alignItems="center" height="100px">
          <span>No data available</span>
        </Flex>
      </Box>
    );
  }

  const refetchData = () => {
    fetchData();
  }

  return (
    <>
      <SnackBar
        iconName={"info"}
        message={snackbarMessage}
        setShowSnackBar={setShowSnackBar}
        show={showSnackBar}
        timeout={4000}
        anchor={"top-right"}
        type={snackbarType}
        // bgColorr="#F44545"
      />
      <Box background="#fff" width="100%">
      
      {data.map((item, index) => {    
      return (
        <EmailTemplateTableBodyField index={index} item={item} reFetch={refetchData}/>
      );
    })}

      </Box>
    </>
  );
}

export default EmailTemplateTableBody;
