import * as React from "react";

function AeSideOppoIcon(props) {
  return (
    <svg
      width={props?.size || "18px"}
      height={props?.size || "18px"}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_6761_27403)" fill="#fff">
        <path d="M8.317 7.934l-1.402.272a1.015 1.015 0 00-.55 1.692l.974 1.045c.03.032.044.075.039.118l-.174 1.417a1.015 1.015 0 001.44 1.046l1.293-.604a.147.147 0 01.125 0l1.294.604a1.016 1.016 0 001.439-1.046l-.174-1.417a.147.147 0 01.038-.118l.974-1.045a1.015 1.015 0 00-.55-1.692l-1.401-.272a.147.147 0 01-.101-.073l-.692-1.249a1.015 1.015 0 00-1.78 0l-.691 1.249a.147.147 0 01-.101.073zm.861.348l.692-1.249a.143.143 0 01.13-.076c.032 0 .092.01.129.076l.691 1.25c.147.263.4.447.696.505l1.402.272a.143.143 0 01.112.1c.01.03.019.09-.033.145l-.973 1.044c-.206.22-.303.519-.266.818l.174 1.417a.143.143 0 01-.06.138.143.143 0 01-.149.014l-1.294-.604a1.015 1.015 0 00-.86 0l-1.293.604a.142.142 0 01-.15-.014.143.143 0 01-.06-.138l.175-1.417c.037-.3-.06-.597-.266-.818l-.974-1.044a.142.142 0 01-.032-.146.143.143 0 01.112-.1l1.402-.271c.296-.058.55-.242.695-.506zM2.271 8.685a.437.437 0 00.52-.327 7.419 7.419 0 015.566-5.566.435.435 0 10-.193-.848A8.238 8.238 0 004.158 4.16a8.239 8.239 0 00-2.215 4.006.435.435 0 00.328.52zM8.357 17.209a7.419 7.419 0 01-5.566-5.566.435.435 0 10-.848.193 8.239 8.239 0 002.215 4.006 8.238 8.238 0 004.006 2.215.433.433 0 00.52-.328.435.435 0 00-.327-.52zM17.728 11.316a.435.435 0 00-.52.327 7.419 7.419 0 01-5.566 5.566.435.435 0 10.193.848 8.238 8.238 0 004.006-2.215 8.238 8.238 0 002.215-4.006.435.435 0 00-.328-.52zM11.642 2.792a7.419 7.419 0 015.566 5.566.435.435 0 10.848-.193A8.24 8.24 0 0015.84 4.16a8.239 8.239 0 00-4.006-2.215.435.435 0 10-.193.848zM10 4.348c.24 0 .435-.195.435-.435V.435a.435.435 0 00-.87 0v3.478c0 .24.195.435.435.435zM10 15.652a.435.435 0 00-.435.435v3.479a.435.435 0 00.87 0v-3.479a.435.435 0 00-.435-.435zM19.566 9.564h-3.479a.435.435 0 000 .87h3.479a.435.435 0 000-.87zM4.348 10a.435.435 0 00-.435-.436H.435a.435.435 0 100 .87h3.478c.24 0 .435-.195.435-.435z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_6761_27403">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AeSideOppoIcon;
