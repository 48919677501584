import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { LocaleString } from "@evabot/eva-react-core-library/dist/locales";
import { RaportLocaleString } from "locales";
import React, { FC } from "react";
import {
  Text,
  TextProps,
} from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { FormattedMessage } from "react-intl";

export interface RapportTextProps extends TextProps {
  textId?: RaportLocaleString;
  children?: any;
  id?: LocaleString;
}

export const RapportText: FC<RapportTextProps> = ({
  textId,
  children,
  id,
  ...props
}) => {
  if (textId) {
    return (
      <FormattedMessage id={textId}>
        {(text) => <Box {...props}>{text}</Box>}
      </FormattedMessage>
    );
  }
  return (
    <Text id={id} {...props}>
      {children}
    </Text>
  );
};
