import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import AeReturnAnalyticsTableBodyField from "./AeReturnAnalyticsTableBodyField";

interface AeReturnStakeTableBodyProps {
  opportunitiesData: any[];
  page: number;
}

const AeReturnAnalyticsTableBody: React.FC<AeReturnStakeTableBodyProps> = ({
  opportunitiesData,
  page,
}) => {
  return (
    <Grid background="#fff" padding="16px" gridGap="25px" className="table-data-tm">
      {opportunitiesData?.map((data: any, index) => (
        <AeReturnAnalyticsTableBodyField key={index} index={index} data={data} />
      ))}
    </Grid>
  );
};

export default AeReturnAnalyticsTableBody;
