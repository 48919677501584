import * as React from "react";

function DeleteIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M9 9h1.5v9H9V9zm4.5 0H15v9h-1.5V9z"
        fill={props?.fill || "#0094E3"}
      />
      <path
        d="M3 4.5V6h1.5v15A1.5 1.5 0 006 22.5h12a1.5 1.5 0 001.5-1.5V6H21V4.5H3zM6 21V6h12v15H6zM9 1.5h6V3H9V1.5z"
        fill={props?.fill || "#0094E3"}
      />
    </svg>
  );
}

export default DeleteIcon;
