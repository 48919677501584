import { FC } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Card } from "@evabot/eva-react-core-library/dist/atoms/Card/Card";
import { Badge } from "@evabot/eva-react-core-library/dist/molecules/Badge/Badge";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { useNavigate } from "react-router-dom";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import SmartText from "components/SmartText/SmartText";
import SuccessTag from "components/SuccessTag/SuccessTag";

export interface EmailTemplateCardProps {
  badgeType: string;
  mailHeader: string;
  textContent: string;
  textLength?: number;
  isBadge?: boolean;
  onClick:any;
}
export const EmailTemplateCard: FC<EmailTemplateCardProps> = ({
  badgeType,
  mailHeader,
  textContent,
  textLength,
  isBadge,
  onClick 
}) => {
  const navigate = useNavigate();

  return (
    <Card>
      <Grid gridRowGap={"26px"}>
        <Flex justifyContent={"space-between"} alignItems="center">
          <Flex alignItems={"center"}>
            <Badge
              height={"36px"}
              width={"98px"}
              bg={
                badgeType === "early"
                  ? "#F9B229"
                  : badgeType === "mid"
                  ? "#FC7D22"
                  : "#2297C9"
              }
              text={
                badgeType === "early"
                  ? "Early stage"
                  : badgeType === "mid"
                  ? "Mid stage"
                  : "Late stage"
              }
              textColor={"#FFFFFF"}
            />
            {isBadge && <SuccessTag text={"Email copy updated"} />}
          </Flex>
          <Flex
            alignItems={"center"}
            cursor="pointer"
            onClick={onClick}
            // onClick={() => navigate("/slateEditor")}
            variant="bodyRegular"
            fontWeight={"bold"}
          >
            <Text color="eva.evaRed" mr="10px">
              Review & confirm
            </Text>
            <Icon name={"arrowRight"} fill={"#DD015B"} />
          </Flex>
        </Flex>
        <Grid gridRowGap={"10px"} justifyContent={"flex-start"}>
          <Flex gridColumnGap={"10px"} alignItems={"center"}>
            <Icon name={"mail"} fill="#050505" />
            <Text
              fontSize={"14px"}
              fontWeight={"600"}
              color="textColor.primary"
            >
              {mailHeader}
            </Text>
          </Flex>
          <SmartText text={textContent} length={textLength || 350} />
        </Grid>
      </Grid>
    </Card>
  );
};
