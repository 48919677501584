import { FC , useState, useEffect } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Tag } from "@evabot/eva-react-core-library/dist/molecules/Tag/Tag";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { ToolTip } from "@evabot/eva-react-core-library/dist/molecules/ToolTip/ToolTip";
import LikeBirthday from "assets/icons/LikeBirthday";
import { Timer } from "./Timer";
import TimerIcon from "./TimerIcon";
import { format, differenceInDays, differenceInSeconds } from "date-fns";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { useAppSelector } from "hooks";
import { insertActivityIntoActivityHistory } from "utils/activityHistoryHelper";
import { axiosHelper } from "utils";
interface BirthdayCardProps {
  setShowTreatModal : any;
  loadEmail:any;
  name : any;
  days: number;
  birthday : string;
  mobile?:boolean;
  setOpenhowwork? : any;
  stakeholderId? : string;
  stakeholderName?: string;
  }

  const BirthdayCard : FC<BirthdayCardProps> = ({ setShowTreatModal , loadEmail , name, days , birthday , mobile , setOpenhowwork, stakeholderId, stakeholderName }) => {
    
    const loggedInMember = useAppSelector(state => state.profileState.profileData);

    const [ load , setLoad  ] = useState(false);
    const [sentBirthday , setSentBirthday] = useState(false);
    const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarState, setSnackbarState] = useState("success");

  const getActiveCakeOrder = async () => {
    try {
      const result = await axiosHelper({
        url: `orders/getActiveCakeOrder/${stakeholderId}`,
        method: "GET",
      });
      if (result.data.success) {
        setSentBirthday(true);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const cancelOrder = async () => {
    try {
      const result = await axiosHelper({
        url: `orders/cancelOrder?orderType=birthdayCake&stakeholderId=${stakeholderId}`,
        method: "DELETE",
      });
      if (result.data.success) {
        setSnackbarState("success");
        setSnackbarMessage("Order is successfully cancelled.");
        setShowSnackBar(true);
        setSentBirthday(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const submitTreatOrder = async () => {
    try {
      const result = await axiosHelper({
        url: "orders/createOrder",
        method: "POST",
        JSONData: {
          stakeholderId: stakeholderId,
          foodType: "treatCake",
          orderSchedule: new Date(`${birthday}/2023`),
          orderInstructions: "",
          orderMessage: "",
          orderPrice: 50,
          
          orderType: "treat",
          isBirthdayCake: true
        },
      });
      if (result.data.success) {
        insertActivityIntoActivityHistory({
          stakeholderId: stakeholderId,
          stakeholderName: stakeholderName
        }, {
          id: result.data.id,
          price: 50,
          date: new Date(`${birthday}/2023`),
          type: "treatCake",
          status: 'Scheduled'
        }, loggedInMember, 'treat');
          setSentBirthday(true);
        console.log("order created");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if(stakeholderId && stakeholderId!== '') {
      getActiveCakeOrder();
    }
  }, [stakeholderId])
  
    return (
      <>
       <SnackBar
          iconName={"info"}
          message={snackbarState === "success" ? "Great!" : "Oops"}
          setShowSnackBar={setShowSnackBar}
          show={showSnackBar}
          subHeading={snackbarMessage}
          timeout={4000}
          anchor={"top-right"}
          type={snackbarState}
          bgColorr={snackbarState === "success" && "#24B300"}
        />
        <Box width={"100%"} >
        <Box
          style={{ boxSizing: "border-box" }}
          background="linear-gradient(90.99deg, #401463 30.22%, #16124C 100.15%)"
          borderRadius="12px"
          width="100%"
          padding="32px 42px"
         >
            <Flex flexDirection={ !mobile ? "row":  "column" } alignItems={ !mobile ? "center": "baseline" } justifyContent={"space-between"} gridRowGap={"16px"} >
              <Flex flexDirection={"column"} justifyContent={"flex-start"} gridRowGap={"8px"} >
                { !sentBirthday && <Tag text="Upcoming" color={"#fff"} bgColor={"#1CB43D"}  /> }
                
                 { !sentBirthday ? (
                <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"10px"} >
                  <Icon name="stakeholderParty" />
                  <Text color={"#fff"} fontSize={ !mobile ? "20px" : "16px" } fontWeight={"400"} lineHeight={"140%"} > {name}'s birthday is {Number(days)>1 ? `in ${days} days` : Number(days)===1 ? 'in 1 day' : 'today'} </Text>
                 </Flex>
                 ): (
                  <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"10px"} >
                   <LikeBirthday />
                   <Text color={"#fff"} fontSize={ !mobile ? "20px" : "16px" } fontWeight={"400"} lineHeight={"140%"} > Good choice! </Text>
                   </Flex>
                 ) } 
                
               { !sentBirthday ? (
                <Text color={"#fff"} fontWeight={"400"} fontSize={!mobile ? "16px":  "12px"} lineHeight={"140%"} > 96% of the stakeholders react extremely positive upon receiving a gift or a cake </Text>
               ) : (
<Text color={"#fff"} fontWeight={"400"} fontSize={!mobile ? "16px":  "12px"} lineHeight={"140%"} >  Birthday cake is scheduled for {name}'s birthday ({format(new Date(birthday), "do MMMM")}) </Text>
               ) } 
              </Flex>
              { !sentBirthday ? (
 <Flex flexDirection={"column"} alignItems={"center"} gridRowGap={"8px"}  >
 {(differenceInSeconds( new Date(`${birthday}/2023`) , new Date() )) > 172800 && (<>{ !mobile ? (
  <Button variant="primary" iconAlign="left" iconName="birthdayOrder"  onClick={ () => {submitTreatOrder(); } } > Send a cake in one click </Button>
 ) : (
<Button variant="primary" fontSize={"12px"}  onClick={ () => setSentBirthday(true) } > Send a cake in one click </Button>
 ) }
 { !mobile ? (
  <ToolTip
        color="black"
        bgColor="#FFFFFF"
        boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
        borderRadius="12px"
        
        direction="bottom-start"
        title={
          <>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "flex-start",
                width : "300px",
                // borderRadius: "12px",
               
                padding: "20px 25px",
              }}
            >
              <Flex
                flexDirection={"column"}
                gridRowGap={"12px"}
                justifyContent={"flex-start"}
                alignItems={"baseline"}
              >
               <Flex flexDirection={"row"} gridColumnGap={"8px"} alignItems={"center"} >
               <Icon name="birthdayOrderBulb" fill="white" />
               <Text color={"#050505"} fontSize={"16px"} fontWeight={"500"} lineHeight={"28px"} > How it works? </Text>
               </Flex>
                <Flex
                  flexDirection={"column"}
                  gridRowGap={"30px"}
                  alignItems={"flex-start"}
                >
                  <Flex
                    flexDirection={"row"}
                    gridColumnGap={"10px"}
                    alignItems={"center"}
                  >
                    <Icon name="stepOne" />
                    {/* <Flex
                      flexDirection={"column"}
                      gridRowGap={"10px"}
                    > */}
                      <Text
                        color={"#000000"}
                        fontWeight={"500"}
                        fontSize={"12px"}
                        textAlign={"start"}
                      >
                        {" "}
                        Immediately places a cake order
                      </Text>
                     
                    {/* </Flex> */}
                  </Flex>
                  <Flex
                    flexDirection={"row"}
                    gridColumnGap={"10px"}
                    alignItems={"center"}
                  >
                    <Icon name="stepTwo" />
                    {/* <Flex
                      flexDirection={"column"}
                      gridRowGap={"10px"}
                    > */}
                      <Text
                        color={"#000000"}
                        fontWeight={"500"}
                        fontSize={"12px"}
                        textAlign={"start"}
                      >
                        {" "}
                        Our concierge team will process the order{" "}
                      </Text>
                      
                    {/* </Flex> */}
                  </Flex>
                  <Flex
                    flexDirection={"row"}
                    gridColumnGap={"10px"}
                    alignItems={"center"}
                  >
                    <Icon name="stepThree" />
                    {/* <Flex
                      flexDirection={"column"}
                      gridRowGap={"10px"}
                    > */}
                      <Text
                        color={"#000000"}
                        fontWeight={"500"}
                        fontSize={"12px"}
                        textAlign={"start"}
                      >
                        {" "}
                        The cake is delivered on their birthday{" "}
                      </Text>
                      
                    {/* </Flex> */}
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </>
        }
      >
        <Text
          id="how.it.works"
          color="#fff"
          fontSize="12px"
          fontWeight="400"
        />
      </ToolTip>
 ): (
   <Text cursor="pointer" color={"#fff"} alignSelf={"flex-start"} fontSize={"12px"} fontWeight={"400"} lineHeight={"140%"} onClick={ () => {
    setOpenhowwork(true);
   } }>
    How it works?
   </Text>
 ) }</>)}
 

</Flex>
              ) : (
                <>
                { differenceInSeconds(
                new Date(`${birthday}/2023`),
                new Date()
              ) > 172800 && (<Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"8px"} >
              <Text
                 fontSize={!mobile ? "16px" : "12px" }
                                            color={"#fff"}
                                            cursor="pointer"
                                            // ml="24px"
                                            // mr="10px"
                                            onClick={() => {
                                              cancelOrder();
                                            }}
                                          >
                                            Cancel
                                          </Text>
                                          <Flex gridGap={"4px"}>
                                            <TimerIcon   />
                                            <Timer timerValue={ differenceInSeconds( new Date(`${birthday}/2023`) , new Date() ) - 172800 }  />
                                          </Flex>
                </Flex>
              ) }

</>
              ) }
             
            </Flex>

            </Box>
            </Box>
            </>
    )
  }

export default   BirthdayCard;