import { commonEmailDomains } from "../constants";

export const getDomainFromEmail = (email: string) => {
    if(email.split("@").length === 2) {
        let domain = email.split("@")[1];
        if(!commonEmailDomains.includes(domain)) {
            return domain;
        }
        else return '';
    }
}