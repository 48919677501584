import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import React, { FC } from "react";
import { StakeHolderTable } from "./StakeHolderTable";

export interface StakeHolderAccordionSubSectionProps {}

export const StakeHolderAccordionSubSection: FC<
  StakeHolderAccordionSubSectionProps
> = () => {
  return (
    <Box>
      <StakeHolderTable />
    </Box>
  );
};
