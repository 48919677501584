import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

const initialState = {
  modalData: {
    nudgeRepetitive: false,
    nudgeImpactful: false,
    nudgeBoring: false,
    others: false,
    otherText: "",
  },
};

interface aeReturnUnlikeModalReducerType {
  nudgeBoring: boolean;
  nudgeImpactful: boolean;
  nudgeRepetitive: boolean;
  otherText: string;
  others: boolean;
}

const aeReturnUnlikeModalReducerSlice = createSlice({
  name: "globalState",
  initialState: initialState,
  reducers: {
    aeReturnUnlikeModalData: (
      state,
      action: PayloadAction<aeReturnUnlikeModalReducerType>
    ) => {
      state.modalData = action.payload;
    },
  },
});

export const { aeReturnUnlikeModalData } =
  aeReturnUnlikeModalReducerSlice.actions;

export const aeReturnUnlikeModalState = (state: RootState) => state.globalState;

export default aeReturnUnlikeModalReducerSlice.reducer;
