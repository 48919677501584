import * as React from "react";

function SuccessTickIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M1.75 10.25l2.5 2.5m3.5-4l2.5-2.5m-4.5 4l2.5 2.5 6-6.5"
        stroke="#24B300"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SuccessTickIcon;
