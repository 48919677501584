import { format, isToday, isYesterday } from "date-fns";
import dayjs from "dayjs";

export const valueChange = (
  stateValue,
  prevValue,
  StateValues,
  setStateValues
) => {
  let filterValues = StateValues?.filter((value) => {
    return value !== stateValue;
  });
  if (prevValue?.length > 0 && !filterValues.includes(prevValue)) {
    filterValues = [...filterValues, prevValue].sort();
  }
  setStateValues(filterValues);
};

export const setInitialValue = (initialValue, StateValues, setStateValues) => {
  const initialObjectArray = Object?.values(initialValue).filter(
    (value) => value !== ""
  );
  const test = StateValues.filter(function (el) {
    return initialObjectArray.indexOf(el) < 0;
  });
  setStateValues(test);
};

export const setInitialValueMultiple = (
  initialValue,
  StateValues,
  setStateValues
) => {
  const arr = [];
  Object.values(initialValue).map((value: string[]) => {
    return value
      ?.filter((v) => v !== "")
      ?.map((a) => {
        if (a.length > 0) {
          arr.push(a);
          return a;
        } else return [];
      });
  });
  const test = StateValues.filter(function (el) {
    return arr.indexOf(el) < 0;
  });
  setStateValues(test);
};

export const valueChangeMultiple = (
  stateValue,
  prevValue,
  StateValues,
  setStateValues
) => {
  console.log(stateValue, prevValue, StateValues, "oppod before");
  let filterValues = StateValues?.filter((value) => {
    return value !== stateValue;
  });

  if (prevValue.includes(stateValue) && !filterValues?.includes(stateValue)) {
    filterValues = [...filterValues, stateValue];
  }
  if (stateValue === undefined || "") {
    prevValue?.map((val) => {
      if (!filterValues.includes(val)) {
        filterValues.push(val);
      }
      return filterValues;
    });
    // filterValues = [...filterValues, ...prevValue];
  }
  console.log(stateValue, prevValue, filterValues, "oppod after");
  setStateValues(filterValues);
};

export const checkObjectArray = (arr, isArrayValue?: boolean) => {
  if (isArrayValue) {
    return Object.values(arr).filter((v: string[]) => v.length > 0).length > 0;
  }
  return Object.values(arr).filter((v) => v !== "").length > 0;
};

export const getDateString = (dateStr: string, getSmallDate?: boolean) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthAbreviations = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = dayjs(dateStr).format("D");
  const mon = monthAbreviations[dayjs(dateStr).month()];

  const month = monthNames[dayjs(dateStr).month()];

  const shortDate = `${mon} ${date}, ${dayjs(dateStr).format("YYYY")}`;
  const finalDate = `${date}${date === "1" || date === "21"
    ? "st"
    : date === "2" || date === "22"
      ? "nd"
      : date === "3"
        ? "rd"
        : "th"
    } ${month}`;

  return getSmallDate
    ? shortDate
    : dayjs(dateStr).format("MM/DD/YYYY") === dayjs(today).format("MM/DD/YYYY")
      ? "today"
      : dayjs(dateStr).format("MM/DD/YYYY") ===
        dayjs(yesterday).format("MM/DD/YYYY")
        ? "yesterday"
        : finalDate;
};

export const reverseCompare = (a, b) => {
  if (a.identity?.firstname > b.identity?.firstname) {
    return -1;
  }
  if (a.identity?.firstname < b.identity?.firstname) {
    return 1;
  }
  return 0;
};

export const compare = (a, b) => {
  if (a.identity?.firstname < b.identity?.firstname) {
    return -1;
  }
  if (a.identity?.firstname > b.identity?.firstname) {
    return 1;
  }
  return 0;
};

export const recencySort = (a, b) => {
  const keyA = new Date(a.createdAt);
  const keyB = new Date(b.createdAt);
  if (keyA > keyB) return -1;
  if (keyA < keyB) return 1;
  return 0;
};

export const checkUnauthorized = (err, journeyData, navigate) => {
  if (err.response?.status === 403 || err.response?.status === 401) {
    if (journeyData.journeyName === "ae" && journeyData.journeyPath === "1") {
      navigate("/aeLogin1");
    } else if (
      journeyData.journeyName === "ae" &&
      journeyData.journeyPath === "2"
    ) {
      navigate("/aeLogin2");
    } else navigate("/aeLogin1");
  }
};

export const getDateText = (date: Date): string => {
  let dateToCheck = new Date(date);
  if (isToday(dateToCheck)) {
    return "Today";
  } else if (isYesterday(dateToCheck)) {
    return "Yesterday";
  } else {
    return format(dateToCheck, "do LLLL, yyyy");
  }
};

export const getDatesBetweenDates = (startDate, endDate) => {
  const datesWithDays = [];
  const datePartStartDate = startDate.split('-').map(Number);
  const datePartLastDate = endDate.split('-').map(Number);
  const currentDate = new Date(datePartStartDate[0], datePartStartDate[1] - 1, datePartStartDate[2], 12, 0, 0);
  const lastDate = new Date(datePartLastDate[0], datePartLastDate[1] - 1, datePartLastDate[2], 12, 0, 0);

  const daysOfWeekShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthsShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  while (currentDate <= lastDate) {
    const dayOfWeekShort = daysOfWeekShort[currentDate.getDay()];
    const monthShort = monthsShort[currentDate.getMonth()];
    const dayOfMonth = currentDate.getDate();

    const displayDate = `${dayOfMonth} ${monthShort}`;

    const dateObj = {
      date: format(currentDate, "yyyy-MM-dd"),
      dateString: `${currentDate?.toDateString()?.split(" ")[0]} ${currentDate?.toDateString()?.split(" ")[2]
        } ${currentDate?.toDateString()?.split(" ")[1]}`,
      dateStringUS: `${currentDate?.toDateString()?.split(" ")[1]} ${currentDate?.toDateString()?.split(" ")[2]}, ${currentDate?.toDateString()?.split(" ")[3]}`,
      day: dayOfWeekShort,
      displayDate: displayDate,
    };

    datesWithDays.push(dateObj);

    currentDate.setDate(currentDate.getDate() + 1);
  }
  datesWithDays.push({ date: "total", day: "Total", displayDate: "total" });

  return datesWithDays;
};

// export const getDatesBetweenDatesNew = (startDate, endDate) => {
//   console.log(startDate, endDate, '"dateValues----" between');
//   const datesWithDays = [];
//   // // const currentDate = new Date(startDate);

//   const daysOfWeekShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
//   const monthsShort = [
//     "Jan",
//     "Feb",
//     "Mar",
//     "Apr",
//     "May",
//     "Jun",
//     "Jul",
//     "Aug",
//     "Sep",
//     "Oct",
//     "Nov",
//     "Dec",
//   ];

//   while (startDate <= endDate) {
//     // const dayOfWeekShort = daysOfWeekShort[startDate.getDay()];
//     // const monthShort = monthsShort[startDate.getMonth()];
//     // const dayOfMonth = startDate.getDate();

//     // const displayDate = `${dayOfMonth} ${monthShort}`;

//     // const dateObj = {
//     //   date: startDate.toISOString().split("T")[0],
//     // };

//     // datesWithDays.push(dateObj);

//     console.log(startDate)

//     startDate.setDate(startDate.getDate() + 1);
//   }
//   // datesWithDays.push({ date: "total", day: "Total", displayDate: "total" });

//   return "datesWithDays";
// };

export function convertDate(date) {
  var yyyy = date.getFullYear().toString();
  var mm = (date.getMonth() + 1).toString();
  var dd = date.getDate().toString();

  var mmChars = mm.split("");
  var ddChars = dd.split("");

  return (
    yyyy +
    "-" +
    (mmChars[1] ? mm : "0" + mmChars[0]) +
    "-" +
    (ddChars[1] ? dd : "0" + ddChars[0])
  );
}
