import { FC, useEffect, useState } from "react";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import EvaLogo from "./EvaLogo";
import { useParams } from "react-router-dom";
import { axiosHelper } from "utils";

const AeChangePasswordLeftSection: FC = () => {

  const { userHash } = useParams();

  const [username, setUsername] = useState('');

  const getUsername = async () => {
    try {
      const result = await axiosHelper({
        url: `salesforce/getEvaUsername/${userHash}`
      });
      if(result.data.success) {
        setUsername(result.data.data);
      }
      else {
        console.log(result.data);
      }
    }
    catch(err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if(userHash.length > 0) {
      getUsername();
    }
  }, [userHash])

  return (
    <Grid
      borderRadius="12px"
      width={"470px"}
      height={"670px"}
      style={{
        backgroundBlendMode: "soft-light",
        backdropFilter: "blur(45px)",
        background: "rgba(255, 255, 255, 0.05)",
      }}
      justifyContent={"center"}
      alignItems={"center"}
      position="relative"
    >
      <Grid>
        <EvaLogo />
        <Text
          color="white"
          opacity={1}
          zIndex="-1"
          variant="heading1"
          mt="40px"
        >
          Hey {username},
        </Text>
        <Text color="white" opacity={1} zIndex="-1" variant="heading1">
          welcome to Eva
        </Text>
      </Grid>
    </Grid>
  );
};

export default AeChangePasswordLeftSection;
