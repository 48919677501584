import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { capitalizeString } from "utils/preferenceMapper";
import parse from "html-react-parser";
import { Tag } from "@evabot/eva-react-core-library/dist/molecules/Tag/Tag";
import TimeOutline from "containers/AEReturnDashboard/TimeOutline";
import { getDateString } from "utils/constants";
import { ToolTip } from "@evabot/eva-react-core-library/dist/molecules/ToolTip/ToolTip";
import { setSelectedNudge } from "store/globalStateReducer";
import { setSelectedNudgeForUnlike } from "containers/AEReturnDashboard/store/aeDashboardReducer";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CompanyDomainIcon from "containers/AeReturnStakeholderDashboard/CompanyDomainIcon";
import { getDomainFromEmail } from "utils/getDomainFromEmail";
import HideIcon from "./HideIcon";
interface SearchViewNudgeCardProps {
  nudge: any;
  getNudgeIcon: any;
  thumsUpIds: any;
  undoNudgeAction: any;
  updateNudgeFeedback: any;
  hide: boolean;
  setHide: (e: any) => void;
  getDomainName: any;
}

const SearchViewNudgeCard: React.FC<SearchViewNudgeCardProps> = ({
  nudge,
  getNudgeIcon,
  thumsUpIds,
  undoNudgeAction,
  updateNudgeFeedback,
  hide,
  setHide,
  getDomainName,
}) => {
  const [hover, setHover] = useState(false);
  const [copiedIds, setCopiedIds] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getHeadlineStr = (nudge) => {
    let text = nudge?.nudgeHeadline;

    try {
      let substrings = nudge?.recHighlight;
      substrings = substrings.filter((sub) => sub.trim() != "");
      substrings.forEach((substr, i) => {
        console.log("index", i);
        console.log("substring" + i, substr);
        console.log("text" + i, text);
        let pattern = new RegExp(substr, "gi");

        text = text.replace(
          pattern,
          `<span style="font-family:'Inter-Bold'" >${capitalizeString(
            substr
          )}</span>`
        );
        console.log("text", text);
      });

      return (
        <Text
          color={"#050505"}
          fontWeight={"300"}
          fontSize={"20px"}
          lineHeight={"140%"}
        >
          {parse(text)}
        </Text>
      );
    } catch (err) {
      return (
        <Text
          color={"#050505"}
          fontWeight={"300"}
          fontSize={"20px"}
          lineHeight={"140%"}
        >
          {text}
        </Text>
      );
    }
  };

  return (
    <Box
      width={"100%"}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      boxShadow={hover && "0px 0px 15px 1px rgba(0, 0, 0, 0.07)"}
    >
      <Box
        style={{ boxSizing: "border-box" }}
        background="#FFFFFF"
        borderTopLeftRadius="12px"
        borderTopRightRadius="12px"
        borderBottom="1px solid rgba(0, 0, 0, 0.1)"
        padding="25px"
        width="100%"
      >
        <Flex
          borderBottom="1px dashed rgba(0, 0, 0, 0.1)"
          marginBottom="10px"
          justifyContent="space-between"
          padding="0px 0px 20px 0px"
          height="20px"
          alignItems="center"
        >
          <Flex gap="8px" alignItems="center">
            <Box
              borderRadius="36px"
              background="#E6F6FF"
              height="36px"
              width="36px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Text fontSize="12px" fontWeight="400">
                {nudge?.stakeholderName
                  .split(" ")
                  .map((t) => t[0].toUpperCase())
                  .join("")}
              </Text>
            </Box>
            <Text
              color="#0094E3"
              fontSize="14px"
              fontWeight="400"
              paddingRight="10px"
              cursor="pointer"
              onClick={() => {
                navigate(
                  `/aeReturnDashboard/stakeholder/${nudge?.stakeholderId}`
                );
              }}
            >
              {" "}
              {nudge?.stakeholderName}{" "}
            </Text>
            {hover && (
              <Flex>
                <Text color="#DFDFDF">|</Text>
                <Text
                  ml="10px"
                  color="#57585C"
                  fontSize={"14px"}
                  fontWeight={400}
                >
                  {nudge?.email}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex
          flexDirection={"row"}
          alignItems={"center"}
          gridColumnGap={"92px"}
          justifyContent={"space-between"}
        >
          <Flex marginTop={"16px"} gridColumnGap={"16px"} alignItems={"center"}>
            {getNudgeIcon(nudge, hover)}
            <Flex flexDirection={"column"} gridRowGap={"12px"}>
              <Flex
                flexDirection={"row"}
                alignItems={"center"}
                gridColumnGap={"8px"}
              >
                <Icon name="nudgeIndexFinger" />
                <Text color={"#57585C"} fontWeight={"300"} fontSize={"14px"}>
                  {" "}
                  {nudge?.context}{" "}
                </Text>
              </Flex>
              {getHeadlineStr(nudge)}
              <Flex alignItems={"center"}>
                {nudge?.nudgeCategory == "podcasts" &&
                  nudge?.crawlerData?.link && (
                    <Box
                      alignSelf={"flex-start"}
                      cursor="pointer"
                      onClick={() => {
                        window.open(nudge?.crawlerData?.link, "_blank");
                      }}
                    >
                      <Tag
                        text={`Source: ${getDomainName(
                          nudge?.crawlerData?.link
                        )}`}
                        color="#0094E3"
                        bgColor="#F2FBFF"
                      />
                    </Box>
                  )}
                {nudge?.nudgeCategory == "books" &&
                  nudge?.crawlerData?.link && (
                    <Box
                      alignSelf={"flex-start"}
                      cursor="pointer"
                      onClick={() => {
                        window.open(nudge?.crawlerData?.link, "_blank");
                      }}
                    >
                      <Tag
                        text={`Source: ${getDomainName(
                          nudge?.crawlerData?.link
                        )}`}
                        color="#0094E3"
                        bgColor="#F2FBFF"
                      />
                    </Box>
                  )}
                {nudge?.nudgeCategory == "movies" &&
                  nudge?.crawlerData?.url && (
                    <Box
                      alignSelf={"flex-start"}
                      cursor="pointer"
                      onClick={() => {
                        window.open(nudge?.crawlerData?.url, "_blank");
                      }}
                    >
                      <Tag
                        text={`Source: ${getDomainName(
                          nudge?.crawlerData?.url
                        )}`}
                        color="#0094E3"
                        bgColor="#F2FBFF"
                      />
                    </Box>
                  )}
                {nudge?.nudgeCategory == "weather" &&
                  nudge?.crawlerData?.weatherUrl && (
                    <Box
                      alignSelf={"flex-start"}
                      cursor="pointer"
                      onClick={() =>
                        window.open(nudge?.crawlerData?.weatherUrl, "_blank")
                      }
                    >
                      <Tag
                        text={`Source: ${getDomainName(
                          nudge?.crawlerData?.weatherUrl
                        )}`}
                        color="#0094E3"
                        bgColor="#F2FBFF"
                      />
                    </Box>
                  )}
                {nudge?.nudgeCategory == "googlePlaces" &&
                  nudge?.crawlerData?.maps_url && (
                    <Box
                      alignSelf={"flex-start"}
                      cursor="pointer"
                      onClick={() =>
                        window.open(nudge?.crawlerData?.maps_url, "_blank")
                      }
                    >
                      <Tag
                        text={`Source: ${getDomainName(
                          nudge?.crawlerData?.maps_url
                        )}`}
                        color="#0094E3"
                        bgColor="#F2FBFF"
                      />
                    </Box>
                  )}
                {nudge?.nudgeCategory == "news" && nudge?.crawlerData?.url && (
                  <Box
                    alignSelf={"flex-start"}
                    cursor="pointer"
                    onClick={() =>
                      window.open(nudge?.crawlerData?.url, "_blank")
                    }
                  >
                    <Tag
                      text={`Source: ${getDomainName(nudge?.crawlerData?.url)}`}
                      color="#0094E3"
                      bgColor="#F2FBFF"
                    />
                  </Box>
                )}
                {nudge?.nudgeCategory == "music" &&
                  nudge?.crawlerData?.link && (
                    <Box
                      alignSelf={"flex-start"}
                      cursor="pointer"
                      onClick={() =>
                        window.open(nudge?.crawlerData?.link, "_blank")
                      }
                    >
                      <Tag
                        text={`Source: ${getDomainName(
                          nudge?.crawlerData?.link
                        )}`}
                        color="#0094E3"
                        bgColor="#F2FBFF"
                      />
                    </Box>
                  )}
                {nudge?.nudgeCategory == "concertTickets" &&
                  nudge?.crawlerData?.eventTickets && (
                    <Box
                      alignSelf={"flex-start"}
                      cursor="pointer"
                      onClick={() =>
                        window.open(nudge?.crawlerData?.eventTickets, "_blank")
                      }
                    >
                      <Tag
                        text={`Source: ${getDomainName(
                          nudge?.crawlerData?.eventTickets
                        )}`}
                        color="#0094E3"
                        bgColor="#F2FBFF"
                      />
                    </Box>
                  )}
                {nudge?.nudgeCategory == "sports" && (
                  <Flex
                    flexDirection={"row"}
                    alignItems={"center"}
                    gridColumnGap={"4px"}
                  >
                    {nudge?.crawlerData?.schedule_url &&
                      nudge?.uniqueId.indexOf("30") > -1 && (
                        <Box
                          alignSelf={"flex-start"}
                          cursor="pointer"
                          onClick={() =>
                            window.open(
                              nudge?.crawlerData?.schedule_url,
                              "_blank"
                            )
                          }
                        >
                          <Tag
                            text={`Source: ${getDomainName(
                              nudge?.crawlerData?.schedule_url
                            )}`}
                            color="#0094E3"
                            bgColor="#F2FBFF"
                          />
                        </Box>
                      )}
                    {nudge?.crawlerData?.news_url &&
                      nudge?.uniqueId.indexOf("16") > -1 && (
                        <Box
                          alignSelf={"flex-start"}
                          cursor="pointer"
                          onClick={() =>
                            window.open(nudge?.crawlerData?.news_url, "_blank")
                          }
                        >
                          <Tag
                            text={`Source: ${getDomainName(
                              nudge?.crawlerData?.news_url
                            )}`}
                            color="#0094E3"
                            bgColor="#F2FBFF"
                          />
                        </Box>
                      )}
                    {nudge?.crawlerData?.game_id_url &&
                      nudge?.uniqueId.indexOf("17") > -1 && (
                        <Box
                          alignSelf={"flex-start"}
                          cursor="pointer"
                          onClick={() =>
                            window.open(
                              nudge?.crawlerData?.game_id_url,
                              "_blank"
                            )
                          }
                        >
                          <Tag
                            text={`Source: ${getDomainName(
                              nudge?.crawlerData?.game_id_url
                            )}`}
                            color="#0094E3"
                            bgColor="#F2FBFF"
                          />
                        </Box>
                      )}
                  </Flex>
                )}

                <Flex alignItems={"center"}>
                  <TimeOutline />
                  <Text
                    color="#9F9F9F"
                    fontSize={"10px"}
                    fontWeight={400}
                    ml="5px"
                  >
                    Added {getDateString(nudge?.time)}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          {!copiedIds.includes(nudge?.uniqueId) ? (
            <ToolTip
              color="#EEEEEE"
              bgColor="#0094E3"
              boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
              borderRadius="8px"
              direction="top-start"
              title={
                <>
                  <Text
                    padding={"12px 16px"}
                    fontSize={"14px"}
                    fontWeight={"400"}
                  >
                    {" "}
                    Description and the source link will be copied{" "}
                  </Text>
                </>
              }
            >
              <Box>
                <Button
                  variant="copy"
                  iconName="copy"
                  iconAlign="left"
                  onClick={() => {
                    setCopiedIds([...copiedIds, nudge?.uniqueId]);
                    let content = nudge?.nudgeHeadline;
                    let links = [];
                    if (
                      nudge?.nudgeCategory == "movies" &&
                      nudge?.crawlerData?.url
                    ) {
                      links.push(nudge?.crawlerData?.url);
                    } else if (
                      nudge?.nudgeCategory == "books" &&
                      nudge?.crawlerData?.link
                    ) {
                      links.push(nudge?.crawlerData?.link);
                    } else if (
                      nudge?.nudgeCategory == "podcasts" &&
                      nudge?.crawlerData?.link
                    ) {
                      links.push(nudge?.crawlerData?.link);
                    } else if (
                      nudge?.nudgeCategory == "googlePlaces" &&
                      nudge?.crawlerData?.maps_url
                    ) {
                      links.push(nudge?.crawlerData?.maps_url);
                    } else if (
                      nudge?.nudgeCategory == "news" &&
                      nudge?.crawlerData?.url
                    ) {
                      links.push(nudge?.crawlerData?.url);
                    } else if (
                      nudge?.nudgeCategory == "music" &&
                      nudge?.crawlerData?.link
                    ) {
                      links.push(nudge?.crawlerData?.link);
                    } else if (
                      nudge?.nudgeCategory == "concertTickets" &&
                      nudge?.crawlerData?.eventTickets
                    ) {
                      links.push(nudge?.crawlerData?.eventTickets);
                    } else if (
                      nudge?.nudgeCategory == "sports" &&
                      nudge?.crawlerData?.news_url &&
                      nudge?.uniqueId.indexOf("16") > -1
                    ) {
                      links.push(nudge?.crawlerData?.news_url);
                    } else if (
                      nudge?.nudgeCategory == "sports" &&
                      nudge?.crawlerData?.game_id_url &&
                      nudge?.uniqueId.indexOf("17") > -1
                    ) {
                      links.push(nudge?.crawlerData?.game_id_url);
                    } else if (
                      nudge?.nudgeCategory == "sports" &&
                      nudge?.crawlerData?.schedule_url &&
                      nudge?.uniqueId.indexOf("30") > -1
                    ) {
                      links.push(nudge?.crawlerData?.schedule_url);
                    } else if (
                      nudge?.nudgeCategory == "weather" &&
                      nudge?.crawlerData?.weatherUrl
                    ) {
                      links.push(nudge?.crawlerData?.weatherUrl);
                    }
                    content = content + `\nSource: ${links.join(" ")}`;
                    navigator.clipboard.writeText(content);
                    setTimeout(() => {
                      let copiedId = copiedIds.filter(
                        (id) => id != nudge?.uniqueId
                      );
                      setCopiedIds(copiedId);
                    }, 1000);
                  }}
                >
                  {" "}
                  Copy{" "}
                </Button>
              </Box>
            </ToolTip>
          ) : (
            <Box
              bg={"#F4FFF1"}
              padding={"12px 32px"}
              borderRadius={"8px"}
              width={"67px"}
              height={"22px"}
            >
              <Flex
                flexDirection={"row"}
                alignItems={"center"}
                gridColumnGap={"8px"}
                justifyContent={"center"}
                height={"100%"}
              >
                <Icon name="doubleTick" stroke="#24B300" />
                <Text
                  color={"#24B300"}
                  fontWeight={"500"}
                  fontSize={"16px"}
                  lineHeight={"140%"}
                >
                  {" "}
                  Copied{" "}
                </Text>
              </Flex>
            </Box>
          )}
        </Flex>
      </Box>

      <Box
        style={{ boxSizing: "border-box" }}
        background="#FFFFFF"
        borderBottomRightRadius="12px"
        borderBottomLeftRadius="12px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="50px"
        width="100%"
        padding="10px 25px"
      >
        {nudge?.email && getDomainFromEmail(nudge?.email) !== "" && (
          <Flex alignItems={"center"}>
            <CompanyDomainIcon />{" "}
            <Text ml="10px" fontSize={"12px"} fontWeight={400} color="#0094E3">
              {" "}
              {/* {nudge?.accountName} */}
              {getDomainFromEmail(nudge?.email)}
            </Text>
          </Flex>
        )}
        <Text fontSize="14px" fontWeight="400">
          {" "}
          {nudge?.ownerName}{" "}
        </Text>
        {thumsUpIds.includes(nudge?.nudgeId) ? (
          <Flex
            flexDirection={"row"}
            gridColumnGap={"8px"}
            alignItems={"center"}
          >
            <Icon name="stakeholderDone" fill={"#1CB43D"} />
            <Text color={"#24B300"} fontWeight={"400"} fontSize={"12px"}>
              {" "}
              Thank you for your feedback{" "}
            </Text>
            <Button
              variant="undo"
              onClick={() => {
                undoNudgeAction(nudge?.nudgeId, "up");
              }}
            >
              {" "}
              Undo{" "}
            </Button>
          </Flex>
        ) : (
          <Flex gap="8px">
            <Box
              height={"25px"}
              borderRadius={"4px"}
              cursor={"pointer"}
              border={"1px solid rgba(0, 0, 0, 0.1)"}
              onClick={() => {
                setSelectedNudge(nudge);
                updateNudgeFeedback(nudge, "up", "");
              }}
            >
              <Flex
                flexDirection={"row"}
                alignItems={"center"}
                gridColumnGap={"4px"}
                padding={"4px 8px"}
              >
                <Text
                  color={"#9F9F9F"}
                  lineHeight={"140%"}
                  fontWeight={"400"}
                  fontSize={"12px"}
                >
                  {" "}
                  I{" "}
                </Text>
                <Icon name="heart" fill="#FF005D" />
                <Text
                  color={"#9F9F9F"}
                  lineHeight={"140%"}
                  fontWeight={"400"}
                  fontSize={"12px"}
                >
                  {" "}
                  this{" "}
                </Text>
              </Flex>
            </Box>
            <Box
              height={"25px"}
              borderRadius={"4px"}
              cursor={"pointer"}
              border={"1px solid rgba(0, 0, 0, 0.1)"}
              onClick={() => {
                dispatch(setSelectedNudgeForUnlike(nudge));
                setHide(!hide);
              }}
            >
              <Flex
                flexDirection={"row"}
                alignItems={"center"}
                gridColumnGap={"4px"}
                padding={"4px 8px"}
              >
                <HideIcon />
              </Flex>
            </Box>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default SearchViewNudgeCard;
