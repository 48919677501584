import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

const initialState = {
  forgotPasswordData: {
    emailAddress: "",
  },
  changePasswordData: {
    password: "",
    confirmPassword: "",
  },
};

export interface AeForgotFormReducerType {
  emailAddress: string;
}

export interface AeChangeFormReducerType {
  password: string;
  confirmPassword: string;
}

const AeFormReducerSlice = createSlice({
  name: "aeFormState",
  initialState: initialState,
  reducers: {
    aeForgotPasswordData: (
      state,
      action: PayloadAction<AeForgotFormReducerType>
    ) => {
      state.forgotPasswordData = action.payload;
    },
    aeChangePasswordData: (
      state,
      action: PayloadAction<AeChangeFormReducerType>
    ) => {
      state.changePasswordData = action.payload;
    },
  },
});

export const { aeForgotPasswordData, aeChangePasswordData } =
  AeFormReducerSlice.actions;

export const aeReturnProfileState = (state: RootState) => state.aeFormState;

export default AeFormReducerSlice.reducer;
