import * as React from "react";

function DisableEye(props) {
  return (
    <svg
      width={props?.size || "62px"}
      height={props?.size || "62px"}
      viewBox="0 0 62 62"
      fill="none"
      {...props}
    >
      <path
        d="M10.908 42.954a1.454 1.454 0 00.824-2.654c-4.07-2.79-6.404-6.18-6.404-9.3 0-6.85 11.218-14.812 25.672-14.812a36.945 36.945 0 015.938.475 1.473 1.473 0 00.485-2.907A39.641 39.641 0 0031 13.282C16.043 13.282 2.422 21.729 2.422 31c0 4.088 2.79 8.35 7.663 11.703.244.161.53.248.823.251z"
        fill="#FFBF84"
      />
      <path
        d="M51.915 19.297a31.453 31.453 0 00-4.592-2.567l6.869-6.858a1.463 1.463 0 00-2.064-2.064l-44.32 44.32a1.463 1.463 0 002.064 2.064l7.75-7.75a37.53 37.53 0 006.975 1.763c2.12.345 4.265.517 6.413.513 14.957 0 28.578-8.447 28.578-17.718-.01-4.088-2.8-8.35-7.673-11.703zM39.05 25.003a9.94 9.94 0 011.86 4.399.193.193 0 000 .067c.08.507.122 1.018.126 1.531a10.046 10.046 0 01-11.567 9.92h-.058a9.871 9.871 0 01-4.408-1.86L39.05 25.003z"
        fill="url(#prefix__paint0_linear_1_3276)"
      />
      <path
        d="M32.54 21.08a1.47 1.47 0 10.446-2.906A12.962 12.962 0 0018.058 31c.001.649.053 1.297.154 1.938a1.443 1.443 0 001.434 1.23h.223a1.453 1.453 0 001.211-1.657A10.036 10.036 0 0132.54 21.08z"
        fill="#FFBF84"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_1_3276"
          x1={55.859}
          y1={7.451}
          x2={4.89}
          y2={46.402}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FD9739" />
          <stop offset={1} stopColor="#FF813A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default DisableEye;
