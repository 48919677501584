import React, { useEffect, useState } from "react";
import { Backdrop, TextField, Autocomplete } from "@mui/material";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { axiosHelperCore } from "utils";
import { useAppSelector } from "hooks";
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import SequenceStepList from "./components/SequenceStepList";
import SettingDataTable from "./components/SettingDataTable";
import SavedSequenceStepList from "./components/SavedSequenceStepList";

function AutomatedEmailSequenceRightSection2() {
    const [loading, setLoading] = useState(false);
    const [frameworkList, setFrameworkList] = useState([]);
    const [contextList, setContextList] = useState([]);
    const [toneList, setToneList] = useState([]);
    const [wordCountList, setWordCountList] = useState([]);
    const [sequenceMasterList, setSequenceMasterList] = useState([]);
    const [sequenceStepList, setSequenceStepList] = useState([]);
    const [sequenceDropDown, setSequenceDropDown] = useState([]);
    const [hidePopup, setHidePopup] = useState(true);
    const [selectedSequence, setSelectedSequence] = useState(null);
    const [sequenceId, setSequenceId] = useState(null);
    const [crmOwnerId, setCrmOwnerId] = useState("");
    const [crmSequenceName, setCrmSequenceName] = useState("");
    const [savedSequenceList, setSavedSequenceList] = useState([]);
    const [savedSequenceStepList, setSavedSequenceStepList] = useState([]);
    const [defaultEmailSetting, setDefaultEmailSetting] = useState({});
    const [defaultLinkedinSetting, setDefaultLinkedinSetting] = useState({});
    const [integrationErrorMsg, setIntegrationErrorMsg] = useState("");

    const loggedInUserDetail = useAppSelector((state) => state.profileState.profileData);

    useEffect(() => {
        if(loggedInUserDetail?.userId){
            fetchData();
        }else{
            setLoading(true);
        }
    }, [loggedInUserDetail]);

    const fetchData = async () => {
        setLoading(true);
        try {
            await getSequenceMasterList();
            await Promise.all([getSaveSequenceList(), getFrameworkContextList()]);
        } catch (error) {
            console.error("Error fetching data", error);
        }
        setLoading(false);
    };

    const getSequenceMasterList = async () => {
        setLoading(true);
        try {
            const result = await axiosHelperCore({
                url: `crm/getSequenceMasterList`,
                method: "POST",
                JSONData: { userId: loggedInUserDetail?.userId, email: "", query: "" }
            });

            if (result?.data?.data) {
                let sequencedropdownlist = result?.data?.data?.map((data) => ({
                    value: data?.crmSequenceId,
                    label: data?.sequenceName
                }));
                setSequenceDropDown(sequencedropdownlist);
                setSequenceMasterList(result?.data?.data);
            }

            if (!result?.data?.success) {
                setIntegrationErrorMsg(result?.data?.message);
            }

        } catch (error) {
            console.error("Error fetching data", error);
        }
        setLoading(false);
    };

    const getFrameworkContextList = async () => {
        const result = await axiosHelperCore({
            url: `rapport/user/getFrameworkContextList`,
            method: "POST"
        });
        setFrameworkList([{
            "frameworkId": "",
            "name": "None",
            "framework": "",
        }, ...result?.data?.data?.prefrenceSettings?.frameworks.filter(item => item.type === 'email'), ...result?.data?.data?.frameworks.filter(item => item.type === 'email')]);
        setContextList([{
            "contextId": "",
            "name": "None",
            "context": "",
        }, ...result?.data?.data?.contexts]);
        setToneList([...result?.data?.data?.prefrenceSettings.tones]);
        setWordCountList([...result?.data?.data?.prefrenceSettings.wordCount]);
        setDefaultEmailSetting(result?.data?.data?.defaultEmail);
        setDefaultLinkedinSetting(result?.data?.data?.defaultLinkedin);
        console.log(frameworkList);
        console.log(contextList);
    };

    const getSaveSequenceList = async () => {
        setLoading(true);
        try {
            const result = await axiosHelperCore({
                url: `rapport/sequence/getSaveSequenceList`,
                method: "POST",
                JSONData: { userId: loggedInUserDetail?.userId }
            });

            setSavedSequenceList(result?.data);
        } catch (error) {
            console.error("Error fetching data", error);
        }
        setLoading(false);
    }

    const handleSequenceStepsEdit = async (sequenceId) => {
        setLoading(true);
        try {
            const result = await axiosHelperCore({
                url: `rapport/sequence/getSavedSequenceSteps`,
                method: "POST",
                JSONData: { sequenceId: sequenceId }
            });

            setSavedSequenceStepList(result?.data?.data);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }

    const handleAddSequenceClick = () => {
        setHidePopup(false);
    };

    const handleConfigureSteps = async () => {
        setLoading(true);
        try {
            const result = await axiosHelperCore({
                url: `crm/getSequenceSteps`,
                method: "POST",
                JSONData: { userId: loggedInUserDetail?.userId, sequenceId: selectedSequence?.value }
            });

            console.log(result?.data);
            setSequenceStepList(result?.data?.data?.steps);
            setSequenceId(result?.data?.data?.sequenceId);
            const listData = sequenceMasterList.find(list => result?.data?.data?.sequenceId === list.crmSequenceId);
            setCrmOwnerId(listData?.ownerId);
            setCrmSequenceName(listData?.sequenceName);
        } catch (error) {
            console.error("Error fetching data", error);
        }
        setLoading(false);
        setHidePopup(true);
    };

    const handleSaveSequence = async (data) => {
        setLoading(true);
        try {

            const formatSequenceData = {
                userId: loggedInUserDetail?.userId,
                crmSequenceId: sequenceId,
                crmOwnerId: crmOwnerId,
                sequenceName: crmSequenceName,
                integrationType: "outreach",
                numberOfSteps: data?.length,
            }

            const result = await axiosHelperCore({
                url: `rapport/sequence/saveSequence`,
                method: "POST",
                JSONData: { sequenceData: formatSequenceData, sequenceStepData: data }
            });

            if (result?.data?.success) {
                handleBack();
                await getSaveSequenceList();
            }
        } catch (error) {
            console.error("Error fetching data", error);
        }
        setLoading(false);
    }

    const handleUpdateSequenceSteps = async (data) => {
        setLoading(true);
        try {

            const result = await axiosHelperCore({
                url: `rapport/sequence/updateSavedSequence`,
                method: "POST",
                JSONData: { sequenceStepData: data, userId: loggedInUserDetail?.userId }
            });

            if (result?.data?.data?.success) {
                handleBack();
                await getSaveSequenceList();
            }


        } catch (error) {
            console.error("Error fetching data", error);
        }
        setLoading(false);
    }

    const handleRefreshSequenceClick = () => {
        setLoading(true);
        try {

        } catch (error) {
            console.error("Error fetching data", error);
        }
        setLoading(false);
    };

    const handleBack = () => {
        setSequenceStepList([]);
        setSavedSequenceStepList([]);
    };

    useEffect(() => {
        if (savedSequenceList.length > 0 && savedSequenceList && sequenceMasterList && sequenceMasterList.length > 0) {
            const filteredSequences = sequenceMasterList?.filter((data) => {
                return !savedSequenceList.some(
                    (savedSequence) => parseInt(savedSequence.crmSequenceId) === data.crmSequenceId
                );
            });

            let sequencedropdownlist = filteredSequences?.map((data) => ({
                value: data?.crmSequenceId,
                label: data?.sequenceName
            }));
            setSequenceDropDown(sequencedropdownlist);
        }
    }, [savedSequenceList])

    return (
        <div className="overflow-y-scroll">
            <div className="py-3">
                {loading && (
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                        open={true}
                    >
                        <Icon name="loadingIcon" />
                    </Backdrop>
                )}
                {!sequenceStepList.length && !savedSequenceStepList.length && (
                    <div>
                        <div className="flex col gap-4 items-center justify-end mt-2 px-10">
                            {/* <div>
                                    <Button iconName="add" onClick={handleRefreshSequenceClick} variant="secondary">
                                        Refresh Sequence
                                    </Button>
                                </div> */}
                            <div>
                                <Button iconName="add" onClick={handleAddSequenceClick} variant="secondary" disabled={integrationErrorMsg.length > 0 && true}>
                                    Add Sequence
                                </Button>
                            </div>
                        </div>
                        <div>
                            {!integrationErrorMsg && savedSequenceList.length > 0 && <SettingDataTable sequenceList={savedSequenceList} getSavedSequenceList={getSaveSequenceList} handleSequenceEdit={handleSequenceStepsEdit} />}
                            {integrationErrorMsg && <div style={{ height: "100%", width: "90%", justifyContent: "center", fontWeight: "500", fontSize: "20px", color: "#050505", textAlign: "center", background: "#fff", padding: "50px", marginLeft: "75px" }}>{integrationErrorMsg}</div>}
                            {!integrationErrorMsg && !loading && (savedSequenceList.length === 0) && <div style={{ height: "100%", width: "92%", justifyContent: "center", fontWeight: "500", fontSize: "20px", color: "#050505", textAlign: "center", background: "#fff", padding: "50px", marginLeft: "50px", marginTop: "20px", borderRadius: "8px" }}>No Sequence Found</div>}
                        </div>  
                    </div>
                )}
            </div>

            <ModalWrapper
                width="max-content"
                height={"auto"}
                hideModal={hidePopup}
                setHideModal={() => {
                    setHidePopup(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="2%"
            >
                <div className="modal-content">
                    <h2>Add Sequence</h2>
                    <Autocomplete
                        className="w-[300px] mt-6 text-[12px]"
                        options={sequenceDropDown}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, value) => setSelectedSequence(value)}
                        renderInput={(params) => <TextField {...params} label="Search Sequence" />}
                        renderOption={(props, option) => (
                            <li {...props} key={option.value}>
                                {option.label}
                            </li>
                        )}
                    />
                    <Button fontSize={"14px"} marginTop={"20px"} onClick={handleConfigureSteps} size="small" variant="primary" disabled={!selectedSequence}>
                        Configure Steps
                    </Button>
                </div>
            </ModalWrapper>

            {sequenceStepList.length > 0 && (
                <SequenceStepList
                    sequenceStepList={sequenceStepList}
                    sequenceName={crmSequenceName}
                    frameworkList={frameworkList}
                    defaultEmail={defaultEmailSetting}
                    defaultLinkedin={defaultLinkedinSetting}
                    toneList={toneList}
                    wordCountList={wordCountList}
                    contextList={contextList}
                    sequenceId={sequenceId}
                    onBack={handleBack}
                    handleSequenceSteps={handleSaveSequence}
                />
            )}

            {savedSequenceStepList.length > 0 && (
                <SavedSequenceStepList
                    sequenceStepList={savedSequenceStepList}
                    sequenceName={crmSequenceName}
                    frameworkList={frameworkList}
                    defaultEmail={defaultEmailSetting}
                    defaultLinkedin={defaultLinkedinSetting}
                    toneList={toneList}
                    wordCountList={wordCountList}
                    contextList={contextList}
                    onBack={handleBack}
                    handleSequenceSteps={handleUpdateSequenceSteps}
                />
            )}
        </div>
    );
}

export default AutomatedEmailSequenceRightSection2;
