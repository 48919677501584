import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import {
  Icon,
  icons,
} from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import AeReturnActivityTag from "./AeReturnActivityTag";
import AeReturnActivityTextTag from "./AeReturnActivityTextTag";
import { getNudgeIcon } from "utils/methods";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import BackArrowIcon from "./BackArrowIcon";
import { useNavigate, useParams } from "react-router";
import { axiosHelper } from "utils";
import { useEffect, useState } from "react";
import AeReturnActivityCard from "./AeReturnActivityCard";
import { getDateText } from "utils/constants";

interface AeReturnActHistoryDetailProps {}

const AeReturnActHistoryDetail: React.FC<
  AeReturnActHistoryDetailProps
> = ({}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [historyDetail, setHistoryDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const fetchHistoryDetail = async () => {
    try {
      const result = await axiosHelper({
        url: `activity/getActivityHistoryByStakeholder/${id}`,
      });

      setHistoryDetail(result.data.data);
      setFirstName(result.data.firstName);
      setLastName(result.data.lastName);
      setEmail(result.data.email);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchHistoryDetail();
  }, []);

  return (
    <>
      {loading ? (
        <Icon name="loadingIcon" />
      ) : (
        <>
          <Flex mt="20px" mb="16px">
            <Flex alignItems={"center"}>
              <Flex
                cursor="pointer"
                mr="16px"
                onClick={() => {
                  navigate(`/aeReturnActivityHistory`);
                }}
              >
                <BackArrowIcon />
              </Flex>
            </Flex>
            <Text fontSize={"24px"} fontWeight={300}>
              {firstName}'s history
            </Text>
          </Flex>

          <Flex
            my="10px"
            flexDirection={"column"}
            backgroundColor="#fff"
            borderRadius={"12px"}
          >
            <Flex
              p="24px 42px"
              borderBottom={"1px solid rgba(0, 0, 0, 0.1)"}
              justifyContent="space-between"
            >
              <Flex>
                {firstName !== "" && lastName !== "" && (
                  <Flex
                    width={"42px"}
                    height="42px"
                    borderRadius={"50%"}
                    background="#E5F6FF"
                    fontSize={"14px"}
                    fontWeight={400}
                    justifyContent="center"
                    alignItems={"center"}
                    mr="12px"
                  >
                    {firstName[0].toUpperCase()}
                    {lastName[0].toUpperCase()}
                  </Flex>
                )}
                <Grid>
                  <Text color="#0094E3" fontSize={"14px"} fontWeight={400}>
                    {firstName} {lastName}
                  </Text>
                  <Text color="#57585C" fontSize={"11px"} fontWeight={300}>
                    {email}
                  </Text>
                </Grid>
              </Flex>
              {/* <Grid>
                <Text color="#050505" fontSize={"16px"} fontWeight={300}>
                  $1,200
                </Text>
                <Text fontSize={"12px"} fontWeight={300}>
                  Credits used for Lincoln
                </Text>
              </Grid> */}
            </Flex>
            <Flex p="32px 42px" flexDirection={"column"}>
              {historyDetail?.length > 0 &&
                historyDetail?.map((day, dayId) => {
                  return (
                    <>
                      <AeReturnActivityTag
                        key={dayId}
                        tagBgColor="#1B9788"
                        tagColor="#FFFFFF"
                        tagText={getDateText(day.date)}
                      />
                      {day?.entries.map((entry, entryid) => {
                        return (
                          <>
                            <AeReturnActivityCard
                              row={
                                !(
                                  historyDetail?.length === dayId + 1 &&
                                  day?.entries?.length === entryid + 1
                                )
                              }
                              largeRow={day?.entries?.length === entryid + 1}
                              entry={entry}
                            />
                          </>
                        );
                      })}
                    </>
                  );
                })}
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};

export default AeReturnActHistoryDetail;
