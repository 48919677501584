import { FC, useEffect, useState } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";

interface SearchModalProps {
  setOpenSearchModal: (e: any) => void;
  searchQuery?: string;
  setSearchQuery?: (e: any) => void;
  searchValues?: {};
  setSearchValues?: (e: any) => void;
  nudges: any[];
  nudgePage?: boolean;
}

const SearchModal: FC<SearchModalProps> = ({
  setOpenSearchModal,
  searchQuery,
  setSearchQuery,
  searchValues,
  setSearchValues,
  nudges,
  nudgePage,
}) => {
  let nudgeStakeholderName = [];
  let nudgeOpportunityName = [];
  let nudgeemail = [];

  let [data, setData] = useState({
    stakeholderName: false,
    opportunityName: false,
    email: false,
  });

  nudges?.forEach((nudge) => {
    if (!nudgeStakeholderName.includes?.(nudge?.stakeholderName)) {
      nudgeStakeholderName.push(nudge?.stakeholderName);
    }
    if (!nudgeOpportunityName.includes?.(nudge?.opportunityName)) {
      nudgeOpportunityName.push(nudge?.opportunityName);
    }
    if (!nudgeemail.includes?.(nudge?.email)) {
      nudgeemail.push(nudge?.email.trim());
    }
  });

  useEffect(() => {
    let tempData = {
      stakeholderName: false,
      opportunityName: false,
      email: false,
    };
    nudges?.forEach(({ stakeholderName, opportunityName, email }) => {
      if (
        stakeholderName
          .toLowerCase()
          ?.includes?.(searchQuery.toLowerCase() || "")
      ) {
        tempData = { ...tempData, stakeholderName: true };
      }
      if (
        opportunityName
          .toLowerCase()
          ?.includes?.(searchQuery.toLowerCase() || "")
      )
        tempData = { ...tempData, opportunityName: true };
      if (
        email
          .toLowerCase()
          ?.trim()
          .includes?.(searchQuery.toLowerCase()?.trim() || "")
      )
        tempData = { ...tempData, email: true };
    });
    setData({ ...tempData });
  }, [nudges, searchQuery]);
  const [nudgeHover, setNudgeHover] = useState({ hover: false, nudge: "" });

  return (
    <Box
      zIndex={1000}
      cursor="pointer"
      background={"white"}
      padding={"16px 0"}
      boxShadow={"0px 25px 35px rgba(0, 0, 0, 0.1)"}
      borderRadius={"12px"}
      overflowY={"auto"}
      maxHeight={"50vh"}
    >
      {searchQuery?.length > 0 && (
        <Grid>
          {data?.stakeholderName && (
            <>
              <Text
                fontSize={"12px"}
                fontWeight={400}
                color="#57585C"
                mb="10px"
                mx="8px"
                px="24px"
              >
                Stakeholder name
              </Text>

              {nudgeStakeholderName?.length > 0 &&
                nudgeStakeholderName?.map((nudge) => {
                  const matches = match(nudge, searchQuery ? searchQuery : "", {
                    insideWords: true,
                  });
                  const parts = parse(nudge, matches);

                  return (
                    matches?.length > 0 && (
                      <Box
                        onMouseEnter={() => {
                          setNudgeHover({ hover: true, nudge: nudge });
                        }}
                        onMouseLeave={() =>
                          setNudgeHover({ hover: false, nudge: "" })
                        }
                        backgroundColor={
                          nudgeHover?.hover &&
                          nudgeHover?.nudge === nudge &&
                          "#F5FAFF"
                        }
                        onClick={(e) => {
                          setOpenSearchModal(false);
                          if (searchQuery && setSearchQuery) {
                            setSearchQuery(nudge.trim().toLowerCase());
                            if (!nudgePage) {
                              setSearchValues({
                                ...searchValues,
                                stakeholderName: nudge,
                              });
                            } else {
                              setSearchValues({
                                stakeholderName: nudge,
                              });
                            }
                          }
                        }}
                        py={matches?.length > 0 && "8px"}
                        px="32px"
                      >
                        {matches?.length > 0 &&
                          parts.map((part, index) => {
                            return (
                              <span
                                key={index}
                                style={{
                                  color: "#050505",
                                  fontWeight: part?.highlight ? "600" : "400",
                                  fontSize: "14px",
                                }}
                              >
                                {part.text}
                              </span>
                            );
                          })}{" "}
                      </Box>
                    )
                  );
                })}
              {/* {data?.opportunityName && (
                <Flex
                  width={"100%"}
                  border={"1px solid rgba(0, 0, 0, 0.1)"}
                  my="10px"
                />
              )} */}
              {data?.email && (
                <Box
                  border={"1px solid rgba(0, 0, 0, 0.1)"}
                  my="10px"
                  mt="20px"
                  mx="24px"
                />
              )}
            </>
          )}
          {/* {data?.opportunityName && (
            <>
              <Text
                fontSize={"12px"}
                fontWeight={400}
                color="#57585C"
                mb="10px"
              >
                Opportunity name
              </Text>

              {nudgeOpportunityName?.length > 0 &&
                nudgeOpportunityName?.map((nudge) => {
                  const matches = match(nudge, searchQuery ? searchQuery : "");
                  const parts = parse(nudge, matches);

                  return (
                    <Box
                      onClick={(e) => {
                        setOpenSearchModal(false);
                        if (searchQuery && setSearchQuery)
                          setSearchQuery(nudge);
                        setSearchValues({
                          ...searchValues,
                          opportunityName: nudge,
                        });
                      }}
                    >
                      {matches?.length > 0 &&
                        parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              color: "#050505",
                              fontWeight: part?.highlight ? "600" : "400",
                              fontSize: "14px",
                            }}
                          >
                            {part.text}
                          </span>
                        ))}{" "}
                    </Box>
                  );
                })}
              {data?.email && (
                <Flex
                  width={"100%"}
                  border={"1px solid rgba(0, 0, 0, 0.1)"}
                  my="10px"
                />
              )}
            </>
          )} */}
          {data?.email && (
            <>
              <Text
                fontSize={"12px"}
                fontWeight={400}
                color="#57585C"
                mb="10px"
                mx="8px"
                px="24px"
              >
                Email
              </Text>

              {nudgeemail?.length > 0 &&
                nudgeemail?.map((nudge) => {
                  const matches = match(nudge, searchQuery ? searchQuery : "", {
                    insideWords: true,
                  });
                  const parts = parse(nudge, matches);

                  return (
                    matches?.length > 0 && (
                      <Box
                        onMouseEnter={() => {
                          setNudgeHover({ hover: true, nudge: nudge });
                        }}
                        onMouseLeave={() =>
                          setNudgeHover({ hover: false, nudge: "" })
                        }
                        backgroundColor={
                          nudgeHover?.hover &&
                          nudgeHover?.nudge === nudge &&
                          "#F5FAFF"
                        }
                        onClick={(e) => {
                          setOpenSearchModal(false);
                          if (searchQuery && setSearchQuery)
                            setSearchQuery(nudge.trim().toLowerCase());
                          if (!nudgePage) {
                            setSearchValues({
                              ...searchValues,
                              email: nudge,
                            });
                          } else {
                            setSearchValues({ email: nudge });
                          }
                        }}
                        py={matches?.length > 0 && "8px"}
                        px="32px"
                      >
                        {matches?.length > 0 &&
                          parts.map((part, index) => (
                            <span
                              key={index}
                              style={{
                                color: "#050505",
                                fontWeight: part?.highlight ? "600" : "400",
                                fontSize: "14px",
                              }}
                            >
                              {part.text}
                            </span>
                          ))}{" "}
                      </Box>
                    )
                  );
                })}
            </>
          )}
          {!data?.stakeholderName && !data.email && (
            <Text
              fontSize={"12px"}
              fontWeight={400}
              color="#57585C"
              mx="8px"
              px="24px"
            >
              No results found
            </Text>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default SearchModal;
