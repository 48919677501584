import { FC, useState , useCallback , useEffect } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import ConfirmationModal from "@evabot/eva-react-core-library/dist/molecules/ConfirmationModal/ConfirmationModal";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";

import { AeReturnJourneyLayout } from "@evabot/eva-react-core-library/dist/templates/AeReturnJourneyLayout/AeReturnJourneyLayout";
import MemoLayoutOneImage from "@evabot/eva-react-core-library/dist/assets/icons/LayoutOneImage";
import { SidebarLeftChild } from "@evabot/eva-react-core-library/dist/templates/AeReturnJourneyLayout/SidebarLeftChild";
import { ToolTip } from "@evabot/eva-react-core-library/dist/molecules/ToolTip/ToolTip";
import { axiosHelper  } from "../../utils";
interface NudgeFeedProps {

}
const AddCustomerValidationSchema = yup.object().shape({
    selectedId : yup.string().required("required.field"),
    selectedOtherOption : yup.string()
  });

  const LeftCollapsedChildren: FC = () => {
    return <>Coll</>;
  }; 

export const NudgeFeedLayout: FC = () => {
  return (
    <>
      <AeReturnJourneyLayout
        as="div"
        left={20}
        right={82}
        leftChildren={<SidebarLeftChild />}
        leftCollapsedChildren={<LeftCollapsedChildren />}
        rightChildren={<NudgeFeed />}
        headerSearch
      />
    </>
  );
};  
  
 const NudgeFeed: FC<NudgeFeedProps> = ({ }) => {
  const [ recommendedNudges , setRecommendedNudges ] = useState([]);
    const [trendingNudge]=useState(true);
  const [lastOrder]=useState(true);
    const [  showConfirmationModal , setShowConfirmationModal] = useState(true);
    const [selectedFeedBackId , setSelectedFeedBackId] = useState(null);
    
    useEffect(   () => {
      // if ( aeValues.length == 0 || accountNameValues.length == 0 || opportunityStateValues.length == 0 ) {
      //  await triggerFilterValuesCall();
       axiosHelper({
        url:'salesforce/getNudgeData', 
        method:'GET', 
        JSONData:{}, 
        urlOverride: false 
      }).then( result => {
        // console.log("result data",result?.data?.data?.feedMetaData);
        // setRecommendedNudges(result?.data?.data?.feedMetaData);
        let data = result?.data?.data;
        data.unshift(
          {
            "nudgeText": "send latest weather alerts to Rabi who lives in Houston",
            "nudgeHeadline": "send latest weather alerts to Rabi who lives in Houston",
            "uniqueId": "9_3256",
            "nudgeCategory": "weather",
            "nudgeType": "digital",
            "url": "https://weather-crawler-v2.herokuapp.com/alerts",
            "payload": {
                "city": "Houston"
            },
            "opportunityId": "5764",
            "opportunityStage": "mid",
            "opportunityValue": 600000,
            "opportunityCloseDate": "03/10/2023",
            "stakeholderId": "3256",
            "stakeholderName": "Rabi",
            "stakeholderPosition": "primary",
            "nudgeRank": 1,
            "email": "abc@xyz.com",
            "gptUrl": "https://rapport-intelligence-v1.herokuapp.com/email/weatherAlert",
            "gptPayload": {
                "name": "Rabi",
                "tone": "professional",
                "cityLocation": "Houston",
                "weather": "$weatherAlert"
            },
            "nudgeHeadlineKey": "send latest weather alerts",
            "nudgeHeadlinePayload": {
                "name": "Rabi",
                "city": "Houston",
                "weatherAlert": "$weatherAlert"
            },
            "visited": 0,
            "opportunityRank": 1,
            "stakeholderRank": 1
        }
        );
        setRecommendedNudges(data);

           
      } ).catch( err => {
        console.log("error",err);
      } ) 
      // }
    },[] );

    let feedBackOptions = [ { id : "1" , text : "This kind of nudge is repetitive"  , value : false } , { 
        id : "2" , text:  "This kind of nudge is not impactful for this stakeholder",value : false 
     } , {
        id : "3" , text:  "This kind of nudge is boring",value : false
     } , {
        id : "4" , text:  "Other",value : false
     } ];


     const onSubmitHandler = useCallback(async (values: any) => {
        // setLoadingButton(true);
        // setTimeout(() => {
        //   setLoadingButton(false);
        // }, 1000);
        console.log(values, "values");
      }, []);
    
    return (
        <Box background="#EAF2FF"  overflow="auto" height="100vh" overflowY={"scroll"}>
            <Flex flexDirection={"column"} paddingLeft={"70px"} paddingRight={"70px"} gridRowGap={"24px"} alignItems={"flex-start"} height={"100%"} >
              <Text color={"#050505"} fontWeight={"400"} fontSize={"24px"} marginTop={"24px"} > Welcome back, John! </Text>
              <ConfirmationModal
              top={"40%"}
              left={"50%"}
        width="40%"
        height="50%"
        
        open={showConfirmationModal}
        onClose={ () => setShowConfirmationModal(true) }
      >
        {/* <Box padding={"48px"} > */}
         <Flex flexDirection={"column"} gridRowGap={"26px"} height={"100%"}  >
          <Flex flexDirection={"column"} gridRowGap={"4px"} >
            <Text fontWeight={"500"} color={"#050505"} fontSize={"24px"} > Tell us why you don't like this nudge </Text>
            <Text fontWeight={"400"} color={"#050505"} fontSize={"16px"} > Your feedback will help us improve your nudge feed </Text>
          </Flex>
          <Formik
          initialValues={{
            selectedId : selectedFeedBackId ,
            selectedOtherOption : ""
          }}
          onSubmit={onSubmitHandler}
          enableReinitialize
          validationSchema={AddCustomerValidationSchema}
        >
              {({
            values,
            errors,
            dirty,
            isValid,
            setFieldValue,
            initialValues,
          }) => {
            console.log(errors, "error", values, initialValues);
            return (
              <Form style={{ height : "100%" }}>
   <Flex flexDirection={"column"} alignItems={"space-between"} height={"100%"} justifyContent={"space-between"} >
<Flex flexDirection={"column"} gridRowGap={"18px"} >
    { feedBackOptions.map( option => {
        return (
            <Flex flexDirection={"row"} gridColumnGap={"8px"} alignItems={"center"} >
                    <input type="checkbox" checked={selectedFeedBackId == option.id} onChange={ (evt) => {
                    //  console.log("target",evt.target.checked);
                     if ( evt.target.checked ) {
                        // @ts-ignore
                       setSelectedFeedBackId(option.id);
                     }
                    } } />
                   <Text color={"#0A0A0A"} fontWeight={"400"} fontSize={"14px"} >{ option.text }  </Text>
                </Flex>
        )
    } ) }
    { selectedFeedBackId == "4" && (
        <FormikInput
        width={"100%"}
        name="selectedOtherOption"
        placeholder="typeHere"
        />
    ) }

    

          </Flex>
          <Flex flexDirection={"row"} gridColumnGap={"16px"} alignItems={"center"} marginTop={"10px"} >
          <Button variant="primary" > Submit </Button>
          <Button variant="reviewConfirm"> Undo  </Button>
          </Flex>
           </Flex>
               </Form>
               );
             }}
              </Formik>

         
          
         </Flex>
        {/* </Box> */}
      </ConfirmationModal>
     { recommendedNudges.map( nudge => {
      return (
        <Box width={"100%"}>
          <Box
        background="#FFFFFF"
        borderTopLeftRadius="12px"
        borderTopRightRadius="12px"
        borderBottom="1px solid rgba(0, 0, 0, 0.1)"
        padding="25px"
        width="100%"
      >
        <Flex
          borderBottom="1px dashed rgba(0, 0, 0, 0.1)"
          marginBottom="10px"
          justifyContent="space-between"
          padding="0px 0px 20px 0px"
          height="20px"
          alignItems="center"
        >
          <Flex gap="8px" alignItems="center" >
            <Box borderRadius="36px" background="#E6F6FF" height="36px" width="36px" display="flex" justifyContent="center" alignItems="center">
              <Text fontSize="12px" fontWeight="400">GS</Text>
            </Box>
            <Text color="#0094E3" fontSize="14px" fontWeight="400" borderRight="1px solid rgba(0, 0, 0, 0.1)" paddingRight="10px">Justin Donaca</Text>
            <Text color="#57585C" fontSize="14px" fontWeight="400">VP Sales</Text>
          </Flex>
          { nudge?.nudgeRank == 1 ?<Flex 
              width="max-content" 
              gap="8px" 
              alignItems="center" 
              background="#FFF1F1" 
              padding="10px 10px"
              borderRadius="6px">
              <Icon name="power"/>
              <Text fontWeight="500" fontSize="14px" lineHeight="140%">Power Nudge: </Text>
              <Text fontWeight="500" fontSize="12px" lineHeight="140%">We see very high engagement and quicker responses on such nudges</Text>
          </Flex>:null}
        </Flex>
        <Flex justifyContent="space-between" height="120px" >
          <Flex gap="16px" alignItems="center">
            <Icon name="weather" />
            <Box width="40vw">
              <Text fontSize="16px" fontWeight="500"> { nudge?.nudgeHeadlineKey } </Text>
            </Box>
          </Flex>
          <Flex flexDirection="column" alignItems="flex-end" gap="10px" justifyContent="center">
            <Button variant="primary"> Send email</Button>
            <ToolTip  color="black"  bgColor="#FFFFFF" boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)" direction="right-end" title={<>
                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                        alignItems: "flex-start",
                                        width: "max-contents",
                                        
                                        borderRadius:"12px",
                                        padding: "20px 25px"

                                    }}
                                >
                                  <Flex flexDirection={"column"} gridRowGap={"16px"}  >
                                     <Flex flexDirection={"row"} gridColumnGap={"12px"} alignItems={"center"} >
                                      <Icon name="tick"  />
                                      <Text color={"#050505"} fontSize={"16px"} fontWeight={"500"}  >  How it works?</Text>
                                       </Flex>
                                      <Flex flexDirection={"column"} gridRowGap={"16px"} > 
                                        <Flex flexDirection={"row"} gridColumnGap={"10px"} alignItems={"center"} > 
                                          <Icon name="tick" />
                                          <Text color={"#57585C"} fontWeight={"400"} fontSize={"14px"} > Eva concierge team will receive the order </Text>
                                           </Flex>
                                           <Flex flexDirection={"row"} gridColumnGap={"10px"} alignItems={"center"} > 
                                          <Icon name="tick" />
                                          <Text color={"#57585C"} fontWeight={"400"} fontSize={"14px"} > Eva concierge team will receive the order </Text>
                                           </Flex>
                                           <Flex flexDirection={"row"} gridColumnGap={"10px"} alignItems={"center"} > 
                                          <Icon name="tick" />
                                          <Text color={"#57585C"} fontWeight={"400"} fontSize={"14px"} > Eva concierge team will receive the order </Text>
                                           </Flex>
                                           <Flex flexDirection={"row"} gridColumnGap={"10px"} alignItems={"center"} > 
                                          <Icon name="tick" />
                                          <Text color={"#57585C"} fontWeight={"400"} fontSize={"14px"} > Eva concierge team will receive the order </Text>
                                           </Flex>
                                        </Flex> 
                                    </Flex>
                                    {/* <Text fontWeight="500" fontSize="14px">CrossCountry Mortgage</Text>
                                    <span style={{ border: "1px solid rgba(0, 0, 0, 0.1)", width: "170px" }}></span>
                                    <Text fontWeight="500" fontSize="14px">Country Mortgage</Text>
                                    <span style={{ border: "1px solid rgba(0, 0, 0, 0.1)", width: "170px" }}></span>
                                    <Text fontWeight="500" fontSize="14px">Country Mortgage</Text> */}
                                </Box>
                            </>}
                            >
                                {/* <Text
                                    fontWeight="400"
                                    color="#050505"
                                    fontSize="14px"
                                    textAlign="justify"
                                >
                                    {d.opportunity}
                                </Text> */}
                                <Text id="how.it.works" color="#0094E3" fontSize="12px" fontWeight="400" />
                            </ToolTip>
            {/* <Text id="how.it.works" color="#0094E3" fontSize="12px" fontWeight="400" />
             */}

            {lastOrder?
            <Flex gap="8px">
              <Icon name="lastOrder" fill='white'
      stroke="black"/>
              <Text fontSize="12px" fontWeight="400" color="#57585C">Sent a cake 3 days ago</Text>
            </Flex>:null}
          </Flex>
        </Flex>

      </Box>
        </Box>
      )
     }  ) }
      <Box width={"100%"}>
      <Box
        background="#FFFFFF"
        borderTopLeftRadius="12px"
        borderTopRightRadius="12px"
        borderBottom="1px solid rgba(0, 0, 0, 0.1)"
        padding="25px"
        width="100%"
      >
        <Flex
          borderBottom="1px dashed rgba(0, 0, 0, 0.1)"
          marginBottom="10px"
          justifyContent="space-between"
          padding="0px 0px 20px 0px"
          height="20px"
          alignItems="center"
        >
          <Flex gap="8px" alignItems="center" >
            <Box borderRadius="36px" background="#E6F6FF" height="36px" width="36px" display="flex" justifyContent="center" alignItems="center">
              <Text fontSize="12px" fontWeight="400">GS</Text>
            </Box>
            <Text color="#0094E3" fontSize="14px" fontWeight="400" borderRight="1px solid rgba(0, 0, 0, 0.1)" paddingRight="10px">Justin Donaca</Text>
            <Text color="#57585C" fontSize="14px" fontWeight="400">VP Sales</Text>
          </Flex>
          {trendingNudge?<Flex 
              width="max-content" 
              gap="8px" 
              alignItems="center" 
              background="#FFF1F1" 
              padding="10px 10px"
              borderRadius="6px">
              <Icon name="power"/>
              <Text fontWeight="500" fontSize="14px" lineHeight="140%">Power Nudge: </Text>
              <Text fontWeight="500" fontSize="12px" lineHeight="140%">We see very high engagement and quicker responses on such nudges</Text>
          </Flex>:null}
        </Flex>
        <Flex justifyContent="space-between" height="120px" >
          <Flex gap="16px" alignItems="center">
            <Icon name="weather" />
            <Box width="40vw">
              <Text fontSize="16px" fontWeight="500">Send Grace Cordsen, who resides in San Francisco,
                the newest weather report</Text>
            </Box>
          </Flex>
          <Flex flexDirection="column" alignItems="flex-end" gap="10px" justifyContent="center">
            <Button variant="primary"> Send email</Button>
            <ToolTip  color="black"  bgColor="#FFFFFF" boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)" direction="right-end" title={<>
                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                        alignItems: "flex-start",
                                        width: "max-contents",
                                        
                                        borderRadius:"12px",
                                        padding: "20px 25px"

                                    }}
                                >
                                  <Flex flexDirection={"column"} gridRowGap={"16px"}  >
                                     <Flex flexDirection={"row"} gridColumnGap={"12px"} alignItems={"center"} >
                                      <Icon name="tick"  />
                                      <Text color={"#050505"} fontSize={"16px"} fontWeight={"500"}  >  How it works?</Text>
                                       </Flex>
                                      <Flex flexDirection={"column"} gridRowGap={"16px"} > 
                                        <Flex flexDirection={"row"} gridColumnGap={"10px"} alignItems={"center"} > 
                                          <Icon name="tick" />
                                          <Text color={"#57585C"} fontWeight={"400"} fontSize={"14px"} > Eva concierge team will receive the order </Text>
                                           </Flex>
                                           <Flex flexDirection={"row"} gridColumnGap={"10px"} alignItems={"center"} > 
                                          <Icon name="tick" />
                                          <Text color={"#57585C"} fontWeight={"400"} fontSize={"14px"} > Eva concierge team will receive the order </Text>
                                           </Flex>
                                           <Flex flexDirection={"row"} gridColumnGap={"10px"} alignItems={"center"} > 
                                          <Icon name="tick" />
                                          <Text color={"#57585C"} fontWeight={"400"} fontSize={"14px"} > Eva concierge team will receive the order </Text>
                                           </Flex>
                                           <Flex flexDirection={"row"} gridColumnGap={"10px"} alignItems={"center"} > 
                                          <Icon name="tick" />
                                          <Text color={"#57585C"} fontWeight={"400"} fontSize={"14px"} > Eva concierge team will receive the order </Text>
                                           </Flex>
                                        </Flex> 
                                    </Flex>
                                    {/* <Text fontWeight="500" fontSize="14px">CrossCountry Mortgage</Text>
                                    <span style={{ border: "1px solid rgba(0, 0, 0, 0.1)", width: "170px" }}></span>
                                    <Text fontWeight="500" fontSize="14px">Country Mortgage</Text>
                                    <span style={{ border: "1px solid rgba(0, 0, 0, 0.1)", width: "170px" }}></span>
                                    <Text fontWeight="500" fontSize="14px">Country Mortgage</Text> */}
                                </Box>
                            </>}
                            >
                                {/* <Text
                                    fontWeight="400"
                                    color="#050505"
                                    fontSize="14px"
                                    textAlign="justify"
                                >
                                    {d.opportunity}
                                </Text> */}
                                <Text id="how.it.works" color="#0094E3" fontSize="12px" fontWeight="400" />
                            </ToolTip>
            {/* <Text id="how.it.works" color="#0094E3" fontSize="12px" fontWeight="400" />
             */}

            {lastOrder?
            <Flex gap="8px">
              <Icon name="lastOrder" fill='white'
      stroke="black"/>
              <Text fontSize="12px" fontWeight="400" color="#57585C">Sent a cake 3 days ago</Text>
            </Flex>:null}
          </Flex>
        </Flex>

      </Box>
      <Box
        background="#FFFFFF"
        borderBottomRightRadius="12px"
        borderBottomLeftRadius="12px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="50px"
        width="100%"
        padding="10px 25px"
      >
        <Flex>
          <Flex gap="5px" cursor="pointer" padding="0px 15px" borderRight="1px solid rgba(0, 0, 0, 0.1)">
            <Icon name="orgIcon" />
            <Text color="#0094E3" fontSize="12px" fontWeight="400">Slack inc.</Text>
          </Flex>
          <Flex gap="5px" cursor="pointer" padding="0px 15px" borderRight="1px solid rgba(0, 0, 0, 0.1)">
            <Icon name="target" />
            <Text color="#050505" fontSize="12px" fontWeight="400">Opportunity name:</Text>
            <Text color="#0094E3" fontSize="12px" fontWeight="400">Enterprise cloud solutions</Text>
          </Flex>
          <Flex gap="5px" cursor="pointer" padding="0px 15px" borderRight="1px solid rgba(0, 0, 0, 0.1)">
            <Icon name="flag" />
            <Text color="#0094E3" fontSize="12px" fontWeight="400">Verbal stage</Text>
          </Flex>
          <Flex gap="5px" padding="0px 15px">
            <Icon name="coin" />
            <Text color="#57585C" fontSize="12px" fontWeight="400">Value: $500K</Text>
          </Flex>
        </Flex>
        <Flex gap="8px">
          <Text id="did.you.find.this.helpful" fontSize="12px" fontWeight="400" />
          <Icon name="thumbsUp" style={{ cursor: "pointer" }} />
          <Icon name="thumbsDown" onClick={ () => setShowConfirmationModal(false) } style={{ cursor: "pointer" }} />
        </Flex>
      </Box>
      </Box>

      {/* <Box width={"100%"}>
      <Box
        background="#FFFFFF"
        borderTopLeftRadius="12px"
        borderTopRightRadius="12px"
        borderBottom="1px solid rgba(0, 0, 0, 0.1)"
        padding="25px"
        width="100%"
      >
        <Flex
          borderBottom="1px dashed rgba(0, 0, 0, 0.1)"
          marginBottom="10px"
          justifyContent="space-between"
          padding="0px 0px 20px 0px"
          height="20px"
          alignItems="center"
        >
          <Flex gap="8px" alignItems="center" >
            <Box borderRadius="36px" background="#E6F6FF" height="36px" width="36px" display="flex" justifyContent="center" alignItems="center">
              <Text fontSize="12px" fontWeight="400">GS</Text>
            </Box>
            <Text color="#0094E3" fontSize="14px" fontWeight="400" borderRight="1px solid rgba(0, 0, 0, 0.1)" paddingRight="10px">Justin Donaca</Text>
            <Text color="#57585C" fontSize="14px" fontWeight="400">VP Sales</Text>
          </Flex>
          {trendingNudge?<Flex 
              width="max-content" 
              gap="8px" 
              alignItems="center" 
              background="#FFF1F1" 
              padding="10px 10px"
              borderRadius="6px">
              <Icon name="power"/>
              <Text fontWeight="500" fontSize="14px" lineHeight="140%">Power Nudge: </Text>
              <Text fontWeight="500" fontSize="12px" lineHeight="140%">We see very high engagement and quicker responses on such nudges</Text>
          </Flex>:null}
        </Flex>
        <Flex justifyContent="space-between" height="120px" >
          <Flex gap="16px" alignItems="center">
            <Icon name="weather" />
            <Box width="40vw">
              <Text fontSize="16px" fontWeight="500">Send Grace Cordsen, who resides in San Francisco,
                the newest weather report</Text>
            </Box>
          </Flex>
          <Flex flexDirection="column" alignItems="flex-end" gap="10px" justifyContent="center">
            <Button variant="primary"> Send email</Button>
            <Text id="how.it.works" color="#0094E3" fontSize="12px" fontWeight="400" />
            {lastOrder?
            <Flex gap="8px">
              <Icon name="lastOrder" fill='white'
      stroke="black"/>
              <Text fontSize="12px" fontWeight="400" color="#57585C">Sent a cake 3 days ago</Text>
            </Flex>:null}
          </Flex>
        </Flex>

      </Box>
      <Box
        background="#FFFFFF"
        borderBottomRightRadius="12px"
        borderBottomLeftRadius="12px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="50px"
        padding="10px 25px"
        width="100%"
      >
        <Flex>
          <Flex gap="5px" cursor="pointer" padding="0px 15px" borderRight="1px solid rgba(0, 0, 0, 0.1)">
            <Icon name="orgIcon" />
            <Text color="#0094E3" fontSize="12px" fontWeight="400">Slack inc.</Text>
          </Flex>
          <Flex gap="5px" cursor="pointer" padding="0px 15px" borderRight="1px solid rgba(0, 0, 0, 0.1)">
            <Icon name="target" />
            <Text color="#050505" fontSize="12px" fontWeight="400">Opportunity name:</Text>
            <Text color="#0094E3" fontSize="12px" fontWeight="400">Enterprise cloud solutions</Text>
          </Flex>
          <Flex gap="5px" cursor="pointer" padding="0px 15px" borderRight="1px solid rgba(0, 0, 0, 0.1)">
            <Icon name="flag" />
            <Text color="#0094E3" fontSize="12px" fontWeight="400">Verbal stage</Text>
          </Flex>
          <Flex gap="5px" padding="0px 15px">
            <Icon name="coin" />
            <Text color="#57585C" fontSize="12px" fontWeight="400">Value: $500K</Text>
          </Flex>
        </Flex>
        <Flex gap="8px">
          <Text id="did.you.find.this.helpful" fontSize="12px" fontWeight="400" />
          <Icon name="thumbsUp" style={{ cursor: "pointer" }} />
          <Icon name="thumbsDown" onClick={ () => setShowConfirmationModal(false) } style={{ cursor: "pointer" }} />
        </Flex>
      </Box>
      </Box> */}

      {/* <Box width={"100%"}>
      <Box
        background="#FFFFFF"
        borderTopLeftRadius="12px"
        borderTopRightRadius="12px"
        borderBottom="1px solid rgba(0, 0, 0, 0.1)"
        padding="25px"
        width="100%"
      >
         <Flex justifyContent={"center"} alignItems={"center"} flexDirection={"row"} gridColumnGap={"52px"} >
            <Text color={"#9F9F9F"} fontSize={"16px"} fontWeight={"500"} > We won't recommend this nudge to you again </Text>
            <Text color={"#DD015B"} fontWeight={"500"} fontSize={"14px"}  > Undo  </Text>
         </Flex>
        </Box>
        </Box> */}
      
      {/* <Box width={"100%"}>
      <Box
        background="#FFFFFF"
        borderTopLeftRadius="12px"
        borderTopRightRadius="12px"
        borderBottom="1px solid rgba(0, 0, 0, 0.1)"
        padding="25px"
        width="100%"
      >
        <Flex
          borderBottom="1px dashed rgba(0, 0, 0, 0.1)"
          marginBottom="10px"
          justifyContent="space-between"
          padding="0px 0px 20px 0px"
          height="20px"
          alignItems="center"
        >
          <Flex gap="8px" alignItems="center" >
            <Box borderRadius="36px" background="#E6F6FF" height="36px" width="36px" display="flex" justifyContent="center" alignItems="center">
              <Text fontSize="12px" fontWeight="400">GS</Text>
            </Box>
            <Text color="#0094E3" fontSize="14px" fontWeight="400" borderRight="1px solid rgba(0, 0, 0, 0.1)" paddingRight="10px">Justin Donaca</Text>
            <Text color="#57585C" fontSize="14px" fontWeight="400">VP Sales</Text>
          </Flex>
          {trendingNudge?<Flex 
              width="max-content" 
              gap="8px" 
              alignItems="center" 
              background="#FFF1F1" 
              padding="10px 10px"
              borderRadius="6px">
              <Icon name="power"/>
              <Text fontWeight="500" fontSize="14px" lineHeight="140%">Power Nudge: </Text>
              <Text fontWeight="500" fontSize="12px" lineHeight="140%">We see very high engagement and quicker responses on such nudges</Text>
          </Flex>:null}
        </Flex>
        <Flex justifyContent="space-between" height="120px" >
          <Flex gap="16px" alignItems="center">
            <Icon name="weather" />
            <Box width="40vw">
              <Text fontSize="16px" fontWeight="500">Send Grace Cordsen, who resides in San Francisco,
                the newest weather report</Text>
            </Box>
          </Flex>
          <Flex flexDirection="column" alignItems="flex-end" gap="10px" justifyContent="center">
            <Button variant="primary"> Send email</Button>
            <Text id="how.it.works" color="#0094E3" fontSize="12px" fontWeight="400" />
            {lastOrder?
            <Flex gap="8px">
              <Icon name="lastOrder" fill='white'
      stroke="black"/>
              <Text fontSize="12px" fontWeight="400" color="#57585C">Sent a cake 3 days ago</Text>
            </Flex>:null}
          </Flex>
        </Flex>

      </Box>
      <Box
        background="#FFFFFF"
        borderBottomRightRadius="12px"
        borderBottomLeftRadius="12px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="50px"
        padding="10px 25px"
        width="100%"
      >
        <Flex>
          <Flex gap="5px" cursor="pointer" padding="0px 15px" borderRight="1px solid rgba(0, 0, 0, 0.1)">
            <Icon name="orgIcon" />
            <Text color="#0094E3" fontSize="12px" fontWeight="400">Slack inc.</Text>
          </Flex>
          <Flex gap="5px" cursor="pointer" padding="0px 15px" borderRight="1px solid rgba(0, 0, 0, 0.1)">
            <Icon name="target" />
            <Text color="#050505" fontSize="12px" fontWeight="400">Opportunity name:</Text>
            <Text color="#0094E3" fontSize="12px" fontWeight="400">Enterprise cloud solutions</Text>
          </Flex>
          <Flex gap="5px" cursor="pointer" padding="0px 15px" borderRight="1px solid rgba(0, 0, 0, 0.1)">
            <Icon name="flag" />
            <Text color="#0094E3" fontSize="12px" fontWeight="400">Verbal stage</Text>
          </Flex>
          <Flex gap="5px" padding="0px 15px">
            <Icon name="coin" />
            <Text color="#57585C" fontSize="12px" fontWeight="400">Value: $500K</Text>
          </Flex>
        </Flex>
        <Flex gap="8px">
          <Text id="did.you.find.this.helpful" fontSize="12px" fontWeight="400" />
          <Icon name="thumbsUp" style={{ cursor: "pointer" }} />
          <Icon name="thumbsDown" onClick={ () => setShowConfirmationModal(false) } style={{ cursor: "pointer" }} />
        </Flex>
      </Box>
      </Box> */}
      

            </Flex>
            </Box>
    )
}