import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { FormCheckbox } from "@evabot/eva-react-core-library/dist/atoms/FormikCheckBox/FormikCheckBox";
import { useCallback } from "react";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { useDispatch } from "react-redux";
import {
  aeReturnActivityOrderData,
  aeReturnActivityOrderReducerType,
} from "./store/aeReturnActivityReducer";

interface AeReturnActivityOrderStatusProps {
  openOrderModal: boolean;
  setOpenOrderModal: (e: any) => void;
  initialValues: aeReturnActivityOrderReducerType;
}

const AeReturnActivityOrderStatus: React.FC<
  AeReturnActivityOrderStatusProps
> = ({ setOpenOrderModal, initialValues, openOrderModal }) => {
  const dispatch = useDispatch();
  const defaultValues = {
    delivered: false,
    shipped: false,
    packaging: false,
    scheduled: false,
    cancelled: false,
  };

  const onSubmitHandler = useCallback(
    async (values: any) => {
      dispatch(aeReturnActivityOrderData(values));
      setOpenOrderModal(false);
    },
    [dispatch, setOpenOrderModal]
  );

  const opportunitySchema = yup.object().shape({});

  return (
    <Flex
      flexDirection={"column"}
      width="300px"
      background="#fff"
      boxShadow={"0px 25px 35px rgba(0, 0, 0, 0.1)"}
      borderRadius="8px"
      zIndex={"1000"}
      position="absolute"
      right={-110}
      style={{ display: openOrderModal ? "flex" : "none" }}
    >
      <Formik
        initialValues={initialValues ? initialValues : defaultValues}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validationSchema={opportunitySchema}
      >
        {({ values, setValues, isValid, dirty, submitForm }) => {
          return (
            <Form>
              <Flex
                p="16px 20px"
                borderBottom={"1px solid #EFEFEF"}
                justifyContent="space-between"
                alignItems={"center"}
                height={"max-content"}
              >
                <Text fontSize={"16px"} fontWeight={400} color="#050505">
                  Order status
                </Text>
                <Flex justifyContent={"space-between"} alignItems="center">
                  <Text
                    fontSize={"12px"}
                    fontWeight={400}
                    mr="16px"
                    cursor="pointer"
                    onClick={() => {
                      setValues(defaultValues);
                      setOpenOrderModal(false);
                      dispatch(aeReturnActivityOrderData(defaultValues));
                    }}
                  >
                    Reset
                  </Text>
                  <Flex
                    p="4px 8px"
                    border={
                      !dirty || !isValid
                        ? "1px solid #DFDFDF"
                        : "1px solid #DD015B"
                    }
                    borderRadius="4px"
                    justifyContent={"center"}
                    alignItems="center"
                    fontSize={"11px"}
                    color={!dirty || !isValid ? "#DFDFDF" : "#DD015B"}
                    fontWeight={400}
                    cursor={!dirty || !isValid ? "not-allowed" : "pointer"}
                    onClick={submitForm}
                  >
                    Apply
                  </Flex>
                </Flex>
              </Flex>
              <Flex flexDirection={"column"} p="10px 20px">
                <Grid gridGap={"2px"}>
                  <FormCheckbox name="delivered" label="Delivered" />
                  <FormCheckbox name="shipped" label="In-transit" />{" "}
                  <FormCheckbox name="packaging" label="Packaging" />{" "}
                  <FormCheckbox name="scheduled" label="Scheduled" />
                  <FormCheckbox name="cancelled" label="Cancelled" />
                </Grid>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Flex>
  );
};

export default AeReturnActivityOrderStatus;
