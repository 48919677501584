import { Icon } from '@evabot/eva-react-core-library/dist/atoms/Icon/Icon';
import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateRangePicker = ({ startDate, endDate, setStartDate, setEndDate }) => {

  const datePickerRef = useRef<DatePicker>(null);
  const [error, setError] = useState<string | null>(null);


  const handleDateChange = (dates: [Date, Date] | null) => {
    if (dates) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
      const [start, end] = dates;
      if (start && end) {
        let rangeInDays = (Math.round((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24))) + 1;
        if (rangeInDays >= 1 && rangeInDays <= 30) {
          setError(null);
          if (datePickerRef.current) {
            datePickerRef.current.setOpen(false); // Close the DatePicker
          }
        } else {
          setError("Date range should be less than or equal to 30")
          setStartDate(null);
          setEndDate(null);
          // alert('Please select a date range between 7 to 30 days.');
        }
      }

    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  return (
    <div className="relative z-50">
      <div onClick={() => { datePickerRef.current.setOpen(true) }} style={{ position: "absolute", right: "6%", zIndex: 1, top: "35%" }}><Icon name="calender"></Icon></div>
      <DatePicker
        ref={datePickerRef}
        id="date_range"
        selected={startDate}
        onChange={handleDateChange}
        selectsRange
        startDate={startDate}
        endDate={endDate}
        maxDate={new Date()}
        className="w-[250px] h-[40px] px-4 py-6 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none placeholder-gray-400 text-[14px]"
        placeholderText="Select start and end dates"
        dateFormat="MMM dd, yyyy"
        shouldCloseOnSelect={false}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex flex-col justify-between items-center mb-2">
            <div className='flex justify-between items-center mb-2 mt-2 gap-3 text-[14px]'>
              <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                {"<"}
              </button>
              <span>{date.toLocaleString('default', { month: 'long' })} {date.getFullYear()}</span>
              <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                {">"}
              </button>
            </div>
            {error && <div className="text-red-500 mt-2">{error}</div>}
          </div>
        )}
      />
    </div>
  );
};

export default DateRangePicker;
