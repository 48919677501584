import { useEffect, useState } from "react";
import ProspectReportTable from "./ProspectReportTable";
import stakeHolderTagService from "services/stakeholderTagService";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { useAppSelector } from "hooks";
import { useDispatch } from "react-redux";

function ProspectReportContent() {

    return (

        <Flex flexDirection={"column"} padding="25px 32px" overflowY={"scroll"}>
            <ProspectReportTable />
        </Flex>
    );
}

export default ProspectReportContent;
