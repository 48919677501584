import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { FC, useState } from "react";

export interface SmartTextProps {
  text: string;
  length?: number;
}

const SmartText: FC<SmartTextProps> = ({ text, length = 20 }) => {
  const [showLess, setShowLess] = useState(true);

  if (text.length < length) {
    return (
      <Text color={"textColor.secondary"} variant="bodyRegular">
        {text}
      </Text>
    );
  }

  return (
    <>
      <Text as="span" color={"textColor.secondary"} variant="bodyRegular">
        {showLess ? `${text.slice(0, length)}...` : text}
        <Text
          color={"basicColors.basicBlue"}
          variant="bodyRegular"
          cursor="pointer"
          onClick={() => setShowLess(!showLess)}
          as="span"
        >
          &nbsp;view {showLess ? "more" : "less"}
        </Text>
      </Text>
    </>
  );
};

export default SmartText;
