import * as React from "react";

function ProfileLogoutIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M4.167 2.5h5a2.5 2.5 0 012.5 2.5v3.333h-.834V5a1.667 1.667 0 00-1.666-1.667h-5A1.667 1.667 0 002.5 5v10.833A1.666 1.666 0 004.167 17.5h5a1.666 1.666 0 001.666-1.667V12.5h.834v3.333a2.5 2.5 0 01-2.5 2.5h-5a2.5 2.5 0 01-2.5-2.5V5a2.5 2.5 0 012.5-2.5zm2.5 7.5h9.375l-2.709-2.708.554-.625 3.75 3.75-3.75 3.75-.554-.625 2.709-2.709H6.667V10z"
        fill="#000"
      />
    </svg>
  );
}

export default ProfileLogoutIcon;
