import * as React from "react";

function AeSideStakeIcon(props) {
  return (
    <svg
      width={props?.size || "18px"}
      height={props?.size || "18px"}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M17.497 15.107l-.077-.045c-.952-.566-2.197-.603-3.202.215-1.232 1.002-1.428 2.73-.434 3.947.444.544 1.429 1.43 2.143 2.052 0 0 0 0 0 0l-.099.113.054.048.018.015 1.597-6.345zm0 0l.077-.045c.95-.564 2.183-.592 3.195.208 1.267 1.003 1.428 2.746.442 3.954h0c-.444.544-1.429 1.43-2.143 2.052l.099.113-1.67-6.281zm-2.396 3.042c.334.41 1.196 1.194 1.943 1.846h0c.183.159.283.245.361.303l.002.002a.197.197 0 00.011.007l.08.05.08-.05a6.813 6.813 0 00.374-.313h0c.746-.65 1.609-1.436 1.942-1.845.391-.48.336-1.137-.18-1.546-.494-.39-1.143-.314-1.584.177a.85.85 0 01-1.265 0c-.448-.499-1.106-.565-1.574-.184l.094.116-.094-.116c-.524.426-.573 1.084-.19 1.553zm0 0l.115-.093-.115.093s0 0 0 0zM4.935 19.975l-.03.175H11a.85.85 0 110 1.7H4a.85.85 0 01-.85-.85A7.85 7.85 0 0111 13.15a.85.85 0 110 1.7 6.152 6.152 0 00-6.065 5.125zM11 3.85a3.15 3.15 0 100 6.3 3.15 3.15 0 000-6.3zM6.15 7a4.85 4.85 0 119.7 0 4.85 4.85 0 01-9.7 0z"
        fill="#fff"
        stroke="#1E1351"
        strokeWidth={0.3}
      />
    </svg>
  );
}

export default AeSideStakeIcon;
