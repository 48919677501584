import { useState, useEffect } from "react";
import { AeReturnJourneyLayout } from "@evabot/eva-react-core-library/dist/templates/AeReturnJourneyLayout/AeReturnJourneyLayout";
import { AeSidebarSection } from "components/AeSidebar/AeSidebarSection";
import { AeSidebarSectionCollapse } from "components/AeSidebar/AeSidebarSectionCollapse";
import NudgeFeeds from "./NudgeFeeds";
import ProfileModalData from "./ProfileModalData";
import { useAppSelector, useAppDispatch } from "hooks";
import { setGlobalStateRole, setStakeholdersData, setStakeholdersLoadingData } from "store/globalStateReducer";
import { mapStakeholdersToSearchbarFormat } from "utils/mapNudgesToSearchbarFormat";
import { axiosHelper } from "utils";
import { checkUnauthorized, compare } from "utils/constants";
import { useNavigate } from "react-router-dom";
// import {
//   aeReturnProfileData,
//   aeReturnProfileDataLoading,
// } from "containers/AEReturnProfile/store/aeReturnProfileReducer";
// import { axiosHelper } from "utils";

function AEReturnDashboard() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const [searchQuery, setSearchQuery] = useState("");

  const [value, setValue] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  // const [searchValues, setSearchValues] = useState({
  //   stakeholderName: "",
  //   opportunityName: "",
  //   email: "",
  // });
  const [options, setOptions] = useState([]);

  const stakeholders = useAppSelector((state) => state.globalState.stakeholders);
  const profileData = useAppSelector((state) => state.profileState.profileData);
  const journeyData = useAppSelector((state) => state.globalState.journey);

  const fetchDataStakeholders = async () => {
    try {
      const result = await axiosHelper({
        url: `salesforce/getAllStakeholdersForAE`,
      });
      //@ts-ignore
      dispatch(setStakeholdersData([...result.data?.allData]));
      //@ts-ignore
      dispatch(setStakeholdersLoadingData(false));
    } catch (err) {
      console.log(err);
      checkUnauthorized(err, journeyData, navigate);
    }
  };

  useEffect(() => {
    if(stakeholders.length > 0 ) {
      setOptions(mapStakeholdersToSearchbarFormat([...stakeholders].sort(compare)))
    }
  },[stakeholders]);


  useEffect(() => {
    dispatch(setGlobalStateRole({ journeyName: "ae", journeyPath: "1" }));
    fetchDataStakeholders();
  }, []);

  return (
    <>
      <AeReturnJourneyLayout
        as="div"
        left={20}
        right={82}
        leftChildren={<AeSidebarSection activeTab="nudges" />}
        leftCollapsedChildren={<AeSidebarSectionCollapse activeTab="nudges" />}
        rightChildren={
          <NudgeFeeds searchQuery={value && value?.value ? value?.value : ''} searchh={value?.value ? value?.value : ''} />
        }
        newHeaderSearch={true}
        // searchQuery={value}
        options={options}
        // setSearchQuery={setValue}
        value={value}
        setValue={setValue}
        profileModalChild={<ProfileModalData />}
        // searchModalChild={
        //   <SearchModal
        //     nudgePage={true}
        //     setOpenSearchModal={setOpenModal}
        //     searchQuery={searchQuery}
        //     setSearchQuery={setSearchQuery}
        //     searchValues={searchValues}
        //     setSearchValues={setSearchValues}
        //     nudges={nudges}
        //   />
        // }
        openSearchModal={openModal}
        setOpenSearchModal={setOpenModal}
        imageUrl={profileData?.imageFile}
      />
    </>
  );
}

export default AEReturnDashboard;
