import React, { FC, useEffect, useState } from "react";
import { SignupLayout } from "@evabot/eva-react-core-library/dist/templates/SignupLayout/SignupLayout";
import AdminLeftSectionNav from "components/AdminLeftSectionNav/AdminLeftSectionNav";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Info } from "@evabot/eva-react-core-library/dist/molecules/Info/Info";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { ReminderTemplateCard } from "./ReminderTemplateCard";
import { axiosHelper  } from "../../utils";
import { setSlateElements , setSelectedCategory , setNudgeMode } from "../../containers/SlateEdtitor/store/SlateEditorReducer";
import { useAppDispatch } from "../../hooks";
import { jsx } from 'slate-hyperscript';

const deserialize = (el, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent)
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null
  }

  const nodeAttributes = { ...markAttributes }

  // define attributes for text nodes
  switch (el.nodeName) {
    case 'strong':
      // @ts-ignore
      nodeAttributes.bold = true
  }

  const children = Array.from(el.childNodes)
    .map(node => deserialize(node, nodeAttributes))
    .flat()

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''))
  }
  console.log("el nodename",el.nodeName  );
  switch (el.nodeName) {
    
    case 'BODY':
      return jsx('fragment', {}, children)
    // case 'BR':
    //   return '\n'
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'blockquote' }, children)
    case 'P':
      return jsx('element', { type: 'paragraph' }, children)
    case 'H1':
      return jsx('element',{ type : "heading-one" } ,children)
    case 'H2':
      return jsx('element',{ type : "heading-two" } , children )
    case 'H3':
      return jsx('element',{ type : "heading-three" }, children )
    case 'H4':
      return jsx('element',{ type : "heading-four" }, children )
    case 'OL':
      return jsx('element',{ type : "numbered-list" },children)
    case 'UL':
      return jsx('element',{ type : "bulleted-list" },children)
    case 'LI':
      return jsx('element',{ type : "list-item" },children);               
    case 'A':
      return jsx(
        'element',
        { type: 'link', url: el.getAttribute('href') },
        children
      )
    default:
      return children
  }
}

const ReminderTemplate: FC = () => {
  const { email } = useParams();
  
  const [showSnackBar, setShowSnackBar] = useState(false);
  useEffect(() => {
    if (email) {
      setShowSnackBar(true);
    }
  }, []);
  
  return (
    <>
      <SnackBar
        iconName={"paperPlane"}
        subHeading={"Email sent successfully updated."}
        setShowSnackBar={setShowSnackBar}
        show={showSnackBar}
        message="Great!"
        timeout={3000}
        anchor={"top-right"}
        type={"success"}
        bgColorr="#24B300"
      />
      {
        //@ts-ignore
        <SignupLayout
          left={25}
          right={75}
          leftChildren={<AdminLeftSectionNav selectedTab={4.2} />}
          rightChildren={<ReminderTemplateLayout editorRoute="--" />}
        />
      }
    </>
  );
};

export interface ReminderTemplateLayoutProps {
  editorRoute: any;
}
export const ReminderTemplateLayout: FC<ReminderTemplateLayoutProps> = ({
  editorRoute,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [ templates , setTemplates ] = useState([]);
  const textContent = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et
                  massa mi. Aliquam in hendrerit urna. Pellentesque sit amet
                  sapien fringilla, mattis ligula consectetur, ultrices mauris.
                  Maecenas vitae mattis tellus. Nullam quis imperdiet augue.
                  Vestibulum auctor ornare leo, non suscipit magna interdum eu.
                  Curabitur pellentesque nibh nibh, at maximus ante fermentum
                  sit amet.`;
  const sampleData = [
    {
      id: 1,
      content: "Template will be sent on Day 1",
      text: textContent,
      header: "A quick moment on MLK Jr. Day.",
    },
    {
      id: 2,
      content: "Template will be sent on Day 2",
      text: textContent,
      header: "A quick moment on MLK Jr. Day.",
    },
    {
      id: 3,
      content: "Template will be sent on Day 3",
      text: textContent,
      header: "A quick moment on MLK Jr. Day.",
    },
    {
      id: 1,
      content: "Template will be sent on Day 4",
      text: textContent,
      header: "A quick moment on MLK Jr. Day.",
    },
  ];
  useEffect( () => {
    axiosHelper({
      url:'salesforce/getTemplates', 
      method:'GET', 
      JSONData:{}, 
      urlOverride: false 
    }).then( result => {
     console.log("dataaa",result.data.data.templates);
     setTemplates(result.data.data.templates.filter( temp => temp.category != "default" ));
    } ).catch( err => {
      console.log("error",err);
    } ) 
  } ,[]);
  return (
    <>
      <Box  position={"relative"} padding={"32px 42px"}>
        <Box>
          <Flex
            flexDirection={"row"}
            justifyContent={"space-between"}
            marginBottom={"20px"}
          >
            <Flex flexDirection={"column"} gridRowGap={"10px"}>
              <Text
                fontSize={"24px"}
                color={"#050505"}
                fontWeight={"400"}
                id="remindertemplate.heading"
              />
              <Info
                locale="emailtemplate.chatgpt.info.message"
                color="#1FA1FF"
              />
            </Flex>
          </Flex>
          <Flex
            flexDirection={"column"}
            gridRowGap={"20px"}
            justifyContent={"space-around"}
            alignItems={"stretch"}
          >
            {templates?.map((template) => (

              <ReminderTemplateCard
              onClick={ () => {
                let body = template.email.body;
                console.log("body",template.email.body.replace(/\/n/g, ''));
                let desealized =  deserialize(new DOMParser().parseFromString(template.email.body.replace(/\/n/g, ''), 'text/html').body);
                console.log("desealized",desealized);
               
                localStorage.setItem("content", JSON.stringify(desealized) );
                console.log("parsed", JSON.parse( JSON.stringify(desealized) ) );
        //         let paragraphsArray = [];
        //         paragraphsArray.push( {
        //           type: "paragraph",
        //           children: [
        //             {
        //               text: template.email.subject,
        //             },
        //           ]
        //         } )
        //         let   bodyparagraphsArray = body.split("/n").map( txt => {
        //           // console.log("text",txt);
        //           return txt.substring(txt.indexOf("p>") + 2, txt.indexOf("</p>"))
        //         } ).map( d => {
        //           return {
        //             type: "paragraph",
        // children: [
        //   {
        //     text: d,
        //   },
        // ],
        //           }
        //         } );
        //         paragraphsArray.push(...bodyparagraphsArray);
        //         console.log("paragraphs",paragraphsArray);
        //         // @ts-ignore
        //         dispatch( setSlateElements(paragraphsArray) );
                dispatch( setSelectedCategory(template.category) );
                dispatch( setNudgeMode(false) );
                localStorage.setItem("type","reminder");
                localStorage.setItem("category",template.category);
                setTimeout( () => {
                  navigate("/slateEditor");
                } ,500);
              } }
                badgeContent={template.stage}
                textContent={template.email.body.replace(/<[^>]+>/g, '').replace(/\/n/g, '')}
                mailHeader={template.email.subject}
                textLength={450}
                id={template?.id}
              />
            ))}
          </Flex>
        </Box>
        <Box
          width={"100%"}
          position={"fixed"}
          zIndex={"200"}
          bottom={"0px"}
          right={"0px"}
        >
          <Flex
            flexDirection={"row"}
            justifyContent={"flex-end"}
            gridColumnGap={"16px"}
            padding={"42px"}
          >
            <Button
              iconAlign="left"
              iconName="arrowLeft"
              variant="secondary"
              onClick={() => navigate("/emailTemplateSetup")}
            >
              Previous step
            </Button>
            <Button
              iconAlign="right"
              iconName="arrowRight"
              onClick={() => navigate("/inviteUsersLayout/:email")}
            >
              Next
            </Button>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default ReminderTemplate;
