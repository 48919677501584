import { FC  } from "react";
import { RightSideModal } from "@evabot/eva-react-core-library/dist/molecules/RightSideModal/RightSideModal";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { ToolTip } from "@evabot/eva-react-core-library/dist/molecules/ToolTip/ToolTip";
import { Form, Formik } from "formik";
import { SelectComponent } from "@evabot/eva-react-core-library/dist/atoms/SelectComponent/SelectComponent";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Avatar } from "@evabot/eva-react-core-library/dist/atoms/Avatar/Avatar";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { useAppSelector } from "hooks";
interface OrderFoodModalProps {
    showModal:  any;
    setShowModal : any;
    setContinueView:  any;
    onSubmitHandlerr : any;
    CustomerValidationSchema : any;
    continueView : any;
    foodTypes : any;
    activeFoodTypeId : any;
    setActiveFoodTypeId: any;
}

const OrderFoodModal : FC<OrderFoodModalProps> = ({ showModal , setShowModal , setContinueView , onSubmitHandlerr , CustomerValidationSchema , continueView , foodTypes , activeFoodTypeId , setActiveFoodTypeId }) => {
  
  const name = useAppSelector(state => state.globalState.name);
  
  return (
        <RightSideModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setContinueView(false);
        }}
      >
        <Formik
          initialValues={{
            selectTime: ["Within 48 hrs"],
            instruction: "",
            message: "",
          }}
          onSubmit={onSubmitHandlerr}
          enableReinitialize
          validationSchema={CustomerValidationSchema}
        >
          {({
            values,
            errors,
            dirty,
            isValid,
            setFieldValue,
            initialValues,
            submitForm,
          }) => {
            console.log(errors, "error", values, initialValues);
            return !continueView ? (
              <Form>
                <Box overflowY="scroll" height="100vh">
                  <Flex
                    flexDirection={"row"}
                    gridColumnGap={"12px"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    padding={"20px 42px"}
                  >
                    <Icon name="ubereats" />
                    <Text
                      color={"#050505"}
                      fontSize={"20px"}
                      fontWeight={"400"}
                    >
                      {" "}
                      Let's delight Valeria's taste buds!{" "}
                    </Text>
                  </Flex>
                  <Box
                    border={"1px solid rgba(0, 0, 0, 0.1)"}
                    width="100%"
                    marginBottom={"24px"}
                  >
                    {" "}
                  </Box>
                  <Flex
                    flexDirection={"column"}
                    gridRowGap={"24px"}
                    padding={"20px 42px"}
                  >
                    <Text
                      color={"#57585C"}
                      fontWeight={"400"}
                      fontSize={"16px"}
                    >
                      {" "}
                      Hello {name}!{" "}
                    </Text>
                    <Flex
                      flexDirection={"row"}
                      gridColumnGap={"16px"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <Avatar
                        imageUrl={"https://picsum.photos/52/52"}
                        height={"52px"}
                        width={"52px"}
                      />
                      <Flex
                        flexDirection={"column"}
                        height={"100%"}
                        justifyContent={"space-between"}
                        gridRowGap={"10px"}
                      >
                        <Text
                          color={"#050505"}
                          fontSize={"20px"}
                          fontWeight={"300"}
                        >
                          {" "}
                          I’m Eva,{" "}
                        </Text>
                        <Text
                          color={"#57585C"}
                          fontSize={"16px"}
                          fontWeight={"400"}
                        >
                          {" "}
                          Your assistant at Eva Concierge{" "}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex
                    flexDirection={"column"}
                    gridRowGap={"24px"}
                    padding={"20px 42px"}
                    paddingTop={"32px"}
                  >
                    <Flex flexDirection={"column"} gridRowGap={"8px"}>
                      <Text color={"#000000"}>
                        {" "}
                        What would you like to send to Valeria?{" "}
                      </Text>
                      <Flex
                        flexDirection={"row"}
                        alignItems={"center"}
                        gridColumnGap={"8px"}
                      >
                        {foodTypes.map((type) => {
                          return (
                            <Box
                              border={
                                activeFoodTypeId != type.id &&
                                "1px solid rgba(0, 0, 0, 0.2)"
                              }
                              background={
                                activeFoodTypeId != type.id
                                  ? "#FFFFFF"
                                  : "#0094E3"
                              }
                              padding={"8px 16px"}
                              borderRadius={"110px"}
                              onClick={() => setActiveFoodTypeId(type.id)}
                              cursor={"pointer"}
                            >
                              <Flex
                                flexDirection={"row"}
                                alignItems={"center"}
                                gridColumnGap={"8px"}
                              >
                                <Icon
                                  // @ts-ignore
                                  name={type.iconName}
                                  fill={
                                    activeFoodTypeId != type.id
                                      ? "black"
                                      : "white"
                                  }
                                />
                                <Text
                                  color={
                                    activeFoodTypeId != type.id
                                      ? "#050505"
                                      : "#FFFFFF"
                                  }
                                >
                                  {" "}
                                  {type.text}{" "}
                                </Text>
                              </Flex>
                            </Box>
                          );
                        })}
                      </Flex>
                      <Flex
                        flexDirection={"row"}
                        gridColumnGap={"24px"}
                        alignItems={"center"}
                        justifyContent={"flex-start"}
                      >
                        <Text
                          color={"#24B300"}
                          fontSize={"11px"}
                          fontWeight={"400"}
                        >
                          {" "}
                          Approved budget: $20{" "}
                        </Text>
                        <Text
                          color={"#050505"}
                          fontSize={"11px"}
                          fontWeight={"300"}
                        >
                          {" "}
                          Available budget: $450{" "}
                        </Text>
                      </Flex>
                    </Flex>
                    <SelectComponent
                      // disabled
                      variantType="secondary"
                      name="selectTime"
                      label="food.schedule"
                      placeholder="food.schedule"
                      options={[
                        "Within 48 hrs",
                        "Within 7 days",
                        "Within 14 days",
                      ]}
                      width={"100%"}
                     
                    />
                    <FormikInput
                      label="instruct.food.label"
                      name="instruction"
                      placeholder="instruct.food.placeholder"
                      width={"100%"}
                      fullSize
                    />
                    <Flex flexDirection={"column"} gridRowGap={"8px"}>
                      <Text
                        color={"#000000"}
                        fontSize={"14px"}
                        fontWeight={"400"}
                      >
                        {" "}
                        Share a thoughtful message for the recipient (optional){" "}
                      </Text>

                      <FormikInput
                        name="message"
                       
                        width={"100%"}
                        height={"84px"}
                      />
                    </Flex>
                   
                    <Flex
                      flexDirection={"row"}
                      gridColumnGap={"24px"}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                    >
                      <Button
                        variant="primary"
                        onClick={() => {
                          submitForm();
                        }}
                      >
                        {" "}
                        Continue{" "}
                      </Button>
                      <ToolTip
                        color="black"
                        bgColor="#FFFFFF"
                        boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
                        direction="top-start"
                        title={
                          <>
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                alignItems: "flex-start",
                                
                                borderRadius: "12px",
                                padding: "20px 25px",
                              }}
                            >
                              <Flex
                                flexDirection={"column"}
                                gridRowGap={"12px"}
                              >
                                <Text
                                  color={"#000000"}
                                  fontWeight={"500"}
                                  fontSize={"12px"}
                                >
                                  {" "}
                                  Next steps:{" "}
                                </Text>
                                <Flex
                                  flexDirection={"column"}
                                  gridRowGap={"30px"}
                                >
                                  <Flex
                                    flexDirection={"row"}
                                    gridColumnGap={"10px"}
                                    alignItems={"flex-start"}
                                  >
                                    <Icon name="stepOne" />
                                    <Flex
                                      flexDirection={"column"}
                                      gridRowGap={"10px"}
                                    >
                                      <Text
                                        color={"#000000"}
                                        fontWeight={"500"}
                                        fontSize={"12px"}
                                      >
                                        {" "}
                                        Follow up
                                      </Text>
                                      <Text
                                        color={"#000000"}
                                        fontSize={"11px"}
                                        fontWeight={"400"}
                                      >
                                        {" "}
                                        We'll followup with the recipient via
                                        text/email (up to 3 times) to ensure
                                        they are available at the address{" "}
                                      </Text>
                                    </Flex>
                                  </Flex>
                                  <Flex
                                    flexDirection={"row"}
                                    gridColumnGap={"10px"}
                                    alignItems={"flex-start"}
                                  >
                                    <Icon name="stepTwo" />
                                    <Flex
                                      flexDirection={"column"}
                                      gridRowGap={"10px"}
                                    >
                                      <Text
                                        color={"#000000"}
                                        fontWeight={"500"}
                                        fontSize={"12px"}
                                      >
                                        {" "}
                                        Status{" "}
                                      </Text>
                                      <Text
                                        color={"#000000"}
                                        fontSize={"11px"}
                                        fontWeight={"400"}
                                      >
                                        {" "}
                                        We'll inform you via email the order
                                        details, order status, and recipient's
                                        comments (if any){" "}
                                      </Text>
                                    </Flex>
                                  </Flex>
                                  <Flex
                                    flexDirection={"row"}
                                    gridColumnGap={"10px"}
                                    alignItems={"flex-start"}
                                  >
                                    <Icon name="stepThree" />
                                    <Flex
                                      flexDirection={"column"}
                                      gridRowGap={"10px"}
                                    >
                                      <Text
                                        color={"#000000"}
                                        fontWeight={"500"}
                                        fontSize={"12px"}
                                      >
                                        {" "}
                                        Request change{" "}
                                      </Text>
                                      <Text
                                        color={"#000000"}
                                        fontSize={"11px"}
                                        fontWeight={"400"}
                                      >
                                        {" "}
                                        You can cancel this request within 30
                                        minutes of confirmation{" "}
                                      </Text>
                                    </Flex>
                                  </Flex>
                                </Flex>
                              </Flex>
                            
                            </Box>
                          </>
                        }
                      >
                       
                        <Text
                          id="how.i.work"
                          color="#0094E3"
                          fontSize="12px"
                          fontWeight="400"
                        />
                      </ToolTip>
                    </Flex>
                  </Flex>
                </Box>
              </Form>
            ) : (
              <Box overflowY="scroll" height="100vh">
                <Flex
                  flexDirection={"column"}
                  gridRowGap={"52px"}
                  paddingLeft={"107px"}
                  paddingRight={"107px"}
                  paddingTop={"60px"}
                >
                  <Flex flexDirection={"column"} gridRowGap={"8px"}>
                    <Icon name="orderTick" />
                    <Text
                      color={"#24B300"}
                      fontSize={"24px"}
                      fontWeight={"400"}
                    >
                      {" "}
                      Great!{" "}
                    </Text>
                    <Text
                      color={"#050505"}
                      fontSize={"14px"}
                      fontWeight={"400"}
                      lineHeight={"140%"}
                    >
                      {" "}
                      We have received your request successfully. Eva concierge
                      team will determine the best possible option within your
                      approved budget and get it delivered.{" "}
                    </Text>
                  </Flex>
                  <Flex
                    flexDirection={"column"}
                    gridRowGap={"20px"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                  >
                    <Text
                      color={"#000000"}
                      fontWeight={"500"}
                      fontSize={"16px"}
                    >
                      {" "}
                      Next steps:{" "}
                    </Text>
                    <Flex
                      flexDirection={"row"}
                      gridColumnGap={"10px"}
                      alignItems={"flex-start"}
                    >
                      <Icon name="stepOne" />
                      <Flex flexDirection={"column"} gridRowGap={"10px"}>
                        <Text
                          color={"#000000"}
                          fontWeight={"500"}
                          fontSize={"12px"}
                        >
                          {" "}
                          Follow up
                        </Text>
                        <Text
                          color={"#000000"}
                          fontSize={"14px"}
                          fontWeight={"400"}
                        >
                          {" "}
                          We'll followup with the recipient via text/email (up
                          to 3 times) to ensure they are available at the
                          address{" "}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex
                      flexDirection={"row"}
                      gridColumnGap={"10px"}
                      alignItems={"flex-start"}
                    >
                      <Icon name="stepTwo" />
                      <Flex flexDirection={"column"} gridRowGap={"10px"}>
                        <Text
                          color={"#000000"}
                          fontWeight={"500"}
                          fontSize={"12px"}
                        >
                          {" "}
                          Status{" "}
                        </Text>
                        <Text
                          color={"#000000"}
                          fontSize={"14px"}
                          fontWeight={"400"}
                        >
                          {" "}
                          We'll inform you via email the order details, order
                          status, and recipient's comments (if any){" "}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex
                      flexDirection={"row"}
                      gridColumnGap={"10px"}
                      alignItems={"flex-start"}
                    >
                      <Icon name="stepThree" />
                      <Flex flexDirection={"column"} gridRowGap={"10px"}>
                        <Text
                          color={"#000000"}
                          fontWeight={"500"}
                          fontSize={"12px"}
                        >
                          {" "}
                          Request change{" "}
                        </Text>
                        <Text
                          color={"#000000"}
                          fontSize={"14px"}
                          fontWeight={"400"}
                        >
                          {" "}
                          You can cancel this request within 30 minutes of
                          confirmation{" "}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex
                      flexDirection={"row"}
                      gridColumnGap={"24px"}
                      alignItems={"center"}
                    >
                      <Button
                        variant="primary"
                        onClick={() => {
                          setShowModal(false);
                          setContinueView(false);
                        }}
                      >
                        {" "}
                        Ok, got it{" "}
                      </Button>
                      <Flex flexDirection={"row"} gridColumnGap={"12px"}>
                        
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
            );
          }}
        </Formik>
      </RightSideModal>
    )
}
export default OrderFoodModal;