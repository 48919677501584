import * as React from "react";

function AeSideHistoryIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" >
    <g clip-path="url(#clip0_5617_12999)">
    <path d="M1.60348 0.781252V3.85418H4.67641M0.78125 10V13.0729C0.78125 16.4672 3.53285 19.2188 6.92711 19.2188H13.0729C16.4672 19.2188 19.2187 16.4672 19.2187 13.0729V6.92707C19.2187 3.53281 16.4672 0.781252 13.0729 0.781252H6.92711C4.65227 0.781252 2.66613 2.01715 1.60348 3.85414M13.0729 13.0729L10 10V5.39063" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_5617_12999">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  );
}

export default AeSideHistoryIcon;
