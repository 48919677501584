import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import ProspectProcessTableBodyField from "./ProspectProcessTableBodyField";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";

function ProspectProcessTableBody({tagList, fetchList}) {
  const refetch = () => {
    fetchList();
  }
  return (
    <Box background="#fff" width="100%">
      {(!Array.isArray(tagList) || tagList.length === 0) ? (
        <Flex justifyContent="center" alignItems="center" height="100px">
          <span>No data available</span>
        </Flex>
      ) : (
        tagList.map((item, index) => (
          <ProspectProcessTableBodyField data={item} index={index} key={index} reFetch={refetch} />
        ))
      )}
    </Box>
  );
}

export default ProspectProcessTableBody;
