import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import AccordionArrowDown from "assets/icons/AccordionArrowDown";
import AccordionArrowUp from "assets/icons/AccordionArrowUp";
import React, { FC, useState } from "react";

export interface AccordionCardProps {
  headerSection?: any;
  subSection?: any;
}

export const AccordionCard: FC<AccordionCardProps> = ({
  headerSection,
  subSection,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Grid
      borderRadius={"12px"}
      border={"border2"}
      bg={isOpen ? "#F2FCFF" : "basic.white"}
    >
      <Box
        px="32px"
        borderBottom={isOpen && "border2"}
        py={"24px"}
        onClick={() => setIsOpen(!isOpen)}
        cursor={"pointer"}
      >
        <Flex>
          <Flex flexGrow={1}>
            <Box width={"100%"} mr="30px">
              {headerSection && headerSection()}
            </Box>
          </Flex>
          <Flex alignItems={"center"} justifyContent={"flex-end"}>
            {isOpen ? <AccordionArrowUp /> : <AccordionArrowDown />}
          </Flex>
        </Flex>
      </Box>
      {isOpen && (
        <Grid
          bg={"basic.white"}
          py={isOpen && "24px"}
          px="32px"
          borderRadius={"0px 0px 12px 12px"}
        >
          {subSection && subSection()}
        </Grid>
      )}
    </Grid>
  );
};
