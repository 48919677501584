import { FC, useEffect, useState } from "react";
import { SignupLayout } from "@evabot/eva-react-core-library/dist/templates/SignupLayout/SignupLayout";
import AESignupRightSection from "./AESignupRightSection";
import AESignupLeftSectionO2 from "./AESignupLeftSectionO2";
import { useAppDispatch } from "../../hooks";
import { setGlobalStateRole } from "store/globalStateReducer";

const AESignupLayoutO1: FC = () => {
  const dispatch = useAppDispatch();

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarError = (message) => {
    setShowSnackBar(true);
    setSnackbarMessage(message);
  };

  useEffect(() => {
    dispatch(setGlobalStateRole({ journeyName: "ae", journeyPath: "2" }));
    localStorage.setItem("journeyPath", "2");
  }, [dispatch]);

  return (
    <>
      {
        //@ts-ignore
        <SignupLayout
          as="div"
          left={30}
          right={70}
          leftChildren={<AESignupLeftSectionO2 handleSnackbarError={handleSnackbarError}/>}
          rightChildren={
            <AESignupRightSection
              showSnackBar={showSnackBar}
              setShowSnackBar={setShowSnackBar}
              snackbarMessage={snackbarMessage}
            />
          }
        />
      }
    </>
  );
};

export default AESignupLayoutO1;
