export const mapNudgesToSearchbarFormat = (nudges) => {
  let options = [];
  const tempArray = [];

  for (let i = 0; i < nudges.length; i++) {
    if (!tempArray.includes(nudges[i].email)) {
      tempArray.push(nudges[i].email);
      options.push({ key: "Name", value: nudges[i].stakeholderName });
    }
  }
  for (let i = 0; i < tempArray.length; i++) {
    options.push({ key: "Email", value: tempArray[i] });
  }
  return options;
};

export const mapStakeholdersToSearchbarFormat = (stakeholders) => {
  let options = [];
  const tempArray = [];

  for (let i = 0; i < stakeholders.length; i++) {
    if (!tempArray.includes(stakeholders[i].identity.email)) {
      tempArray.push(stakeholders[i].identity.email);
      options.push({
        key: "Name",
        value: `${stakeholders[i].identity.firstname} ${stakeholders[i].identity.lastname}`,
      });
    }
  }
  for (let i = 0; i < tempArray.length; i++) {
    options.push({ key: "Email", value: tempArray[i] });
  }
  return options;
};

export const mapAnalyticsToSearchbarFormat = (analytics) => {
  let options = [];
  const tempArray = [];

  for (let i = 0; i < analytics.length; i++) {
    if (!tempArray.includes(analytics[i].email)) {
      tempArray.push(analytics[i].email);
      options.push({
        key: "Name",
        value: `${analytics[i].firstName} ${analytics[i].lastName}`,
      });
    }
  }
  for (let i = 0; i < tempArray.length; i++) {
    options.push({ key: "Email", value: tempArray[i] });
  }
  return options;
};
