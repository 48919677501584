import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SignupLayout } from "@evabot/eva-react-core-library/dist/templates/SignupLayout/SignupLayout";
import MemoLayoutOneImage from "assets/icons/LayoutOneImage";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import SignupLeftSection from "./SignupLeftSection";
import { useAppDispatch } from "../../hooks";
import { setGlobalStateRole } from "store/globalStateReducer";

const RightSection: FC = () => {
  return (
    <Flex height="100%" alignItems="center" justifyContent="center">
      <MemoLayoutOneImage />
    </Flex>
  );
};

const SignupComponent: FC = () => {

  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (location?.pathname === "/admin2") {
      console.log('admin2journey disp');
      dispatch(setGlobalStateRole({ journeyName: "admin", journeyPath: "2" }));
    }
  }, [dispatch, location?.pathname]);

  return (
    <>
      {
        //@ts-ignore
        <SignupLayout
          as="div"
          left={30}
          right={70}
          leftChildren={<SignupLeftSection />}
          rightChildren={<RightSection />}
        />
      }
    </>
  );
};

export default SignupComponent;
