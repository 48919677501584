import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { BoxProps } from "@mui/system";
import React, { ReactNode } from "react";
import styled from "styled-components";

export interface dialogConfirmationProps {
  height: string;
  width: string;
  hideModal?: boolean;
  setHideModal?: () => void;
  children: ReactNode;
  bgcolor?: string;
  padding?: string;
  hideBackground?: boolean;
  overflow?: string;
  top?: string;
  left?: string;
}

interface WrapperProps extends BoxProps {
  bgcolor?: string;
}

export const Wrapper = styled(Box)<WrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => {
    return props?.bgcolor ? props?.bgcolor : "#00000030";
  }};
`;

export const WrapperBody = styled.div<dialogConfirmationProps>`
  position: fixed;
  top: ${(p) => p.top};
  left: ${(p) => p.left};
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  transform: translate(-50%, -50%);
  z-index: 1;
  background: #ffffff;
  border-radius: 12px;
  overflow: ${(props) => {
    return props?.overflow ? props?.overflow : "hidden";
  }};
  padding: ${(props) => {
    return props?.padding ? props?.padding : "72px";
  }};
`;

export const ConfirmationDialog: React.FC<dialogConfirmationProps> = ({
  height,
  width,
  children,
  setHideModal,
  hideModal,
  bgcolor,
  padding,
  hideBackground,
  overflow,
  top="50vh",
  left="50vw",
}) => {
  return (
    <>
      {!hideModal ? (
        <Wrapper
          onClick={!!hideBackground ? () => {} : setHideModal}
          bgcolor={bgcolor}
        >
          <WrapperBody
            width={width}
            height={height}
            onClick={(e) => e.stopPropagation()}
            padding={padding}
            overflow={overflow}
            top={top}
            left={left}
          >
            {children}
          </WrapperBody>
        </Wrapper>
      ) : null}
    </>
  );
};
