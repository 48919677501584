import * as React from "react";

function SortByArrow(props) {
  return (
    <svg
      width={props?.size || "1em"}
      height={props?.size || "1em"}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path d="M4.667 6.666L8 9.999l3.334-3.333H4.667z" fill="#000000" />
    </svg>
  );
}

export default SortByArrow;
