import React, { useState, useEffect } from "react";
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import AccessDeniedLogo from "assets/icons/AccessDenied";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks";
import { setLoggedInState } from "store/globalStateReducer";

export interface AccessDeniedModalProps {
  hideModal?: boolean;
  setHideModal?: () => void;
}

const AccessDeniedModal: React.FC<AccessDeniedModalProps> = ({
  hideModal,
  setHideModal,
}) => {
  const dispatch = useAppDispatch();
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  const navigate = useNavigate();
  return (
    <ModalWrapper
      width={screenSize.dynamicWidth > 576 ? "550px" : `${(0.6 * screenSize.dynamicWidth).toString()}px`}
      height={"auto"}
      hideModal={hideModal}
      hideBackground={true}
      setHideModal={setHideModal}
      bgcolor={"rgba(0, 0, 0, 0.9)"}
      padding="48px"
    >
      <Flex
        justifyContent={"center"}
        gap="20px"
        flexDirection={"column"}
        alignItems={"center"}
      >
        <AccessDeniedLogo />
        <Text
          textAlign={"center"}

          fontSize={screenSize.dynamicWidth > 576 ? "32px" : "18px"}
          marginTop="5px"
          fontFamily="Inter-Light"
          fontWeight={300}
        >
          Access Denied
        </Text>
        <Text fontSize={"14px"} color="#57585C" textAlign={"center"}>
          You are not allowed to view the profile and nudges for this person.
        </Text>
        <Button
          variant="primary"
          iconName="arrowLeft"
          onClick={() => {
            navigate(-1)
          }}
          iconAlign="left"
          marginTop={"5px"}
        >
          Go back
        </Button>
      </Flex>
    </ModalWrapper>
  );
};

export default AccessDeniedModal;
