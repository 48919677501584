import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";

export const getNudgeIcon = (nudgeType, isHover?: boolean, small?: boolean) => {
  if (nudgeType.toLowerCase() === "podcastEpisode".toLocaleLowerCase()) {
    return (
      <Icon size="32px" name={isHover ? "nudgePodcastHover" : "nudgePodcast"} />
    );
  } else if (nudgeType.toLowerCase() === "books".toLocaleLowerCase()) {
    return (
      <Icon 
        size="32px" 
        name={isHover ? "nudgeBookHover" : "nudgeBook"} 
      />
    );
  } else if (nudgeType.toLowerCase() === "movies".toLocaleLowerCase()) {
    return (
      <Icon 
        size="32px" 
        name={isHover ? "nudgeMovieHover" : "nudgeMovie"} 
      />
    );
  } else if (nudgeType.toLowerCase() === "birthdayWish".toLocaleLowerCase()) {
    return (
      <Icon
        name={isHover ? "nudgeBirthdayHover" : "nudgeBirthday"}
        size={small && "32px"}
      />
    );
  } else if (
    nudgeType.toLowerCase() === "googlePlacesKids".toLocaleLowerCase()
  ) {
    return (
      <Icon
        name={isHover ? "nudgeGooglePlacesKidsHover" : "nudgeGooglePlacesKids"}
        size={small && "32px"}
      />
    );
  } else if (
    nudgeType.toLowerCase() === "googlePlacesPets".toLocaleLowerCase()
  ) {
    return (
      <Icon
        name={isHover ? "nudgeGooglePlacesPetsHover" : "nudgeGooglePlacesPets"}
        size={small && "32px"}
      />
    );
  } else if (
    nudgeType.toLowerCase() === "googlePlacesCafe".toLocaleLowerCase()
  ) {
    return (
      <Icon
        name={isHover ? "nudgeGooglePlacesCafeHover" : "nudgeGooglePlacesCafe"}
        size={small && "32px"}
      />
    );
  } else if (
    nudgeType.toLowerCase() === "googlePlacesRestaurant".toLocaleLowerCase()
  ) {
    return (
      <Icon
        name={
          isHover
            ? "nudgeGooglePlacesRestaurantHover"
            : "nudgeGooglePlacesRestaurant"
        }
        size={small && "32px"}
      />
    );
  } else if (
    nudgeType.toLowerCase() === "googlePlacesOutdoor".toLocaleLowerCase()
  ) {
    return (
      <Icon
        name={
          isHover ? "nudgeGooglePlacesOutdoorHover" : "nudgeGooglePlacesOutdoor"
        }
        size={small && "32px"}
      />
    );
  } else if (nudgeType.toLowerCase() === "weatherAlert".toLocaleLowerCase()) {
    return (
      <Icon
        name={isHover ? "nudgeWeatherAlertHover" : "nudgeWeatherAlert"}
        size={small && "32px"}
      />
    );
  } else if (
    nudgeType.toLowerCase() === "weatherPatternChange".toLocaleLowerCase()
  ) {
    return (
      <Icon
        name={
          isHover
            ? "nudgeWeatherPatternChangeHover"
            : "nudgeWeatherPatternChange"
        }
        size={small && "32px"}
      />
    );
  } else if (nudgeType.toLowerCase() === "espnNews".toLocaleLowerCase()) {
    return (
      <Icon
        name={isHover ? "nudgeSportNewsHover" : "nudgeSportNews"}
        size={small && "32px"}
      />
    );
  } else if (nudgeType.toLowerCase() === "espnScore".toLocaleLowerCase()) {
    return (
      <Icon
        name={isHover ? "nudgeSportsScoreHover" : "nudgeSportsScore"}
        size={small && "32px"}
      />
    );
  } else if (nudgeType.toLowerCase() === "espnSchedule".toLocaleLowerCase()) {
    return (
      <Icon
        name={isHover ? "nudgeSportsScheduleHover" : "nudgeSportsSchedule"}
        size={small && "32px"}
      />
    );
  } else if (nudgeType.toLowerCase() === "spotifyMusic".toLocaleLowerCase()) {
    return (
      <Icon
        name={isHover ? "nudgeSpotifyMusicHover" : "nudgeSpotifyMusic"}
        size={small && "32px"}
      />
    );
  } else if (
    nudgeType.toLowerCase() === "ticketMasterConcert".toLocaleLowerCase()
  ) {
    return (
      <Icon
        name={isHover ? "nudgeConcertTicketsHover" : "nudgeConcertTickets"}
        size={small && "32px"}
      />
    );
  }
};

export const getDigitalNudgeIcon = (orderType, small?: boolean) => {
  if (orderType.toLowerCase() === "treatTea".toLocaleLowerCase()) {
    return <Icon name={"nudgeDigitalTeaTreat"} size={small && "32px"} />;
  } else if (orderType.toLowerCase() === "treatLiquor".toLocaleLowerCase()) {
    return <Icon name={"nudgeDigitalAlcohol"} size={small && "32px"} />;
  } else if (orderType.toLowerCase() === "treatPizza".toLocaleLowerCase()) {
    return <Icon name={"nudgeDigitalMeals"} size={small && "32px"} />;
  } else if (orderType.toLowerCase() === "treatGift".toLocaleLowerCase()) {
    return <Icon name={"nudgeDigitalGift"} size={small && "32px"} />;
  } else if (orderType.toLowerCase() === "treatRose".toLocaleLowerCase()) {
    return <Icon name={"nudgeDigitalFlowers"} size={small && "32px"} />;
  } else if (orderType.toLowerCase() === "treatCake".toLocaleLowerCase()) {
    return <Icon name={"nudgeDigitalCake"} size={small && "32px"} />;
  } else if (
    orderType.toLowerCase() === "ticketSportsIcon".toLocaleLowerCase() ||
    orderType.toLowerCase() === "gearSportsIcon".toLocaleLowerCase()
  ) {
    return <Icon name={"nudgeDigitalFanGear"} size={small && "32px"} />;
  }
};
