import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import React from "react";
import Countdown from "react-countdown";

interface TimerProps {
  timerValue?: number;
}

// Random component
const Finished = () => (
  <Text as="span" fontSize={"12px"} fontWeight={400} color="#fff">
    00m : 00s
  </Text>
);

// Renderer callback
const renderer = ({ total, hours, minutes, seconds , days  }) => {
  if (total) {
    // Render a countdown
    return (
      <Text as="span" fontSize={"12px"} fontWeight={400} color="#fff">
    {days}d : {hours}hr : {minutes}m : {seconds}s
      </Text>
    );
  } else {
    // Render a finished state
    return <Finished />;
  }
};

export const Timer: React.FC<TimerProps> = ({ timerValue }) => (
  <Countdown
    date={Date.now() + (timerValue ? timerValue * 1000 : 1800000)}
    renderer={renderer}
    daysInHours={true}
  />
);
