export const userData = [
  {
    name: "CrossCountry Mortgage",
    stage: "Proposal",
    value: "$500K",
    stakeholders: 7,
    approved_budget: "$1500",
    available_budget: "$1500",
  },
  {
    name: "CrossCountry Mortgage",
    stage: "Proposal",
    value: "$500K",
    stakeholders: 7,
    approved_budget: "$1500",
    available_budget: "$1500",
  },
  {
    name: "CrossCountry Mortgage",
    stage: "Proposal",
    value: "$500K",
    stakeholders: 7,
    approved_budget: "$1500",
    available_budget: "$1500",
  },
  {
    name: "CrossCountry Mortgage",
    stage: "Proposal",
    value: "$500K",
    stakeholders: 7,
    approved_budget: "$1500",
    available_budget: "$1500",
  },
  {
    name: "CrossCountry Mortgage",
    stage: "Proposal",
    value: "$500K",
    stakeholders: 7,
    approved_budget: "$1500",
    available_budget: "$1500",
  },
  {
    name: "CrossCountry Mortgage",
    stage: "Proposal",
    value: "$500K",
    stakeholders: 7,
    approved_budget: "$1500",
    available_budget: "$1500",
  },
  {
    name: "CrossCountry Mortgage",
    stage: "Proposal",
    value: "$500K",
    stakeholders: 7,
    approved_budget: "$1500",
    available_budget: "$1500",
  },
  {
    name: "CrossCountry Mortgage",
    stage: "Proposal",
    value: "$500K",
    stakeholders: 7,
    approved_budget: "$1500",
    available_budget: "$1500",
  },
  {
    name: "CrossCountry Mortgage",
    stage: "Proposal",
    value: "$500K",
    stakeholders: 7,
    approved_budget: "$1500",
    available_budget: "$1500",
  },
  {
    name: "CrossCountry Mortgage",
    stage: "Proposal",
    value: "$500K",
    stakeholders: 7,
    approved_budget: "$1500",
    available_budget: "$1500",
  },
  {
    name: "CrossCountry Mortgage",
    stage: "Proposal",
    value: "$500K",
    stakeholders: 7,
    approved_budget: "$1500",
    available_budget: "$1500",
  },
  {
    name: "CrossCountry Mortgage",
    stage: "Proposal",
    value: "$500K",
    stakeholders: 7,
    approved_budget: "$1500",
    available_budget: "$1500",
  },
  {
    name: "CrossCountry Mortgage",
    stage: "Proposal",
    value: "$500K",
    stakeholders: 7,
    approved_budget: "$1500",
    available_budget: "$1500",
  },
  {
    name: "CrossCountry Mortgage",
    stage: "Proposal",
    value: "$500K",
    stakeholders: 7,
    approved_budget: "$1500",
    available_budget: "$1500",
  },
  {
    name: "CrossCountry Mortgage",
    stage: "Proposal",
    value: "$500K",
    stakeholders: 7,
    approved_budget: "$1500",
    available_budget: "$1500",
  },
  {
    name: "CrossCountry Mortgage",
    stage: "Proposal",
    value: "$500K",
    stakeholders: 7,
    approved_budget: "$1500",
    available_budget: "$1500",
  },
  {
    name: "CrossCountry Mortgage",
    stage: "Proposal",
    value: "$500K",
    stakeholders: 7,
    approved_budget: "$1500",
    available_budget: "$1500",
  },
  {
    name: "CrossCountry Mortgage",
    stage: "Proposal",
    value: "$500K",
    stakeholders: 7,
    approved_budget: "$1500",
    available_budget: "$1500",
  },
];
