import ProspectProcessTable from "./ProspectProcessTable";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";

function ProspectProcessContent() {

    return (

        <Flex flexDirection={"column"} padding="25px 32px" overflowY={"scroll"}>
            <ProspectProcessTable />
        </Flex>
    );
}

export default ProspectProcessContent;
