import * as React from "react";

function AdminSideFieldIcon(props) {
  return (
    <svg
      width={props?.size || "18px"}
      height={props?.size || "18px"}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0_5559_10751)"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9.96 14.646H3.126a2.344 2.344 0 01-2.344-2.343v-4.61A2.344 2.344 0 013.125 5.35h6.836M16.172 5.35h.703a2.344 2.344 0 012.344 2.343v4.61a2.344 2.344 0 01-2.344 2.343h-.703" />
        <path d="M9.922.781c1.726 0 3.125 1.4 3.125 3.125v12.188c0 1.726-1.4 3.125-3.125 3.125M16.172.781a3.125 3.125 0 00-3.125 3.125v12.188c0 1.726 1.399 3.125 3.125 3.125" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_5559_10751">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AdminSideFieldIcon;
