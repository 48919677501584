import { FC, useState  } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { ToolTip } from "@evabot/eva-react-core-library/dist/molecules/ToolTip/ToolTip";
import { Tag } from "@evabot/eva-react-core-library/dist/molecules/Tag/Tag";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import {  setSelectedNudge as sn  } from "store/globalStateReducer";
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import { getCoffeeString, getAlcoholString, getOtherC1String, getCuisineString, capitalizeString, getC4MappedString } from "utils/preferenceMapper";
import TimeOutline from "./TimeOutline";
import { getDateString } from "utils/constants";
import CompanyDomainIcon from "containers/AeReturnStakeholderDashboard/CompanyDomainIcon";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { useAppSelector } from "hooks";
import { insertActivityIntoActivityHistory } from "utils/activityHistoryHelper";
import { getDomainFromEmail } from "utils/getDomainFromEmail";

interface RecommendMobileNudgeViewProps {
    nudge: any;
    recommendedNudges: any;
    setSearchTerm: any;
    setSearchType: any;
    setSelectedNudge : any;
    setFilteredNudges: any;
    setFilter : any;
    setSearch : any;
    nudgeCategoryMap: any;
    getNudgeBtn : any;
    thumsUpIds : any;
    updateNudgeFeedback : any;
    thumsDownIds : any;
    setHide : any;
    hide : any;
    setSelectedNudgeForUnlike : any;
    dispatch: any;
    undoNudgeAction : any;
    nudgesLoaded : any;
    getNudgeIcon : any;

}

const RecommendMobileNudgeView : FC<RecommendMobileNudgeViewProps> = ({  nudge , recommendedNudges , setSearchTerm , setSearchType , setSelectedNudge , setFilteredNudges , setFilter , setSearch , nudgeCategoryMap , getNudgeBtn , thumsUpIds , updateNudgeFeedback , thumsDownIds , setHide , hide , setSelectedNudgeForUnlike , dispatch , undoNudgeAction , nudgesLoaded , getNudgeIcon }) => {
  const [hover, setHover] = useState(false);
  const [ showMobileSnackBar , setShowMobileSnackbar ] = useState(false);
  const [ nudgeFeedBack , setNudgeFeedBack ] = useState(null);
  const navigate = useNavigate();
  const [copiedIds , setCopiedIds ] = useState([]);

  const loggedInMember = useAppSelector(
    (state) => state.profileState.profileData
  );

  const getDomainName = (url) => {
    try {
      return new URL(url).hostname.replace("www.", "");
    }
    catch(err) {
      console.log(url, err);
      return '';
    }
  }
  
  const getHeadlineStr = (nudge) => {
    let text = nudge?.nudgeHeadline;

    try {
      let substrings = nudge?.recHighlight;
      substrings = substrings.filter((sub) => sub.trim() != "");
      substrings.forEach((substr, i) => {        
        let pattern = new RegExp(substr, "gi");

        text = text.replace(
          pattern,
          `<span style="font-family:'Inter-Bold'" >${capitalizeString(
            substr
          )}</span>`
        );
        console.log("text", text);
      });

      return (
        <Text
          color={"#050505"}
          fontWeight={"300"}
          fontSize={"20px"}
          lineHeight={"140%"}
        >
          {parse(text)}
        </Text>
      );
    } catch (err) {
      return (
        <Text
          color={"#050505"}
          fontWeight={"300"}
          fontSize={"20px"}
          lineHeight={"140%"}
        >
          {text}
        </Text>
      );
    }
  };
    return (
      <>
       <SnackBar
        iconName={"stakeholderDone"}
        bgColorr={"#24B300"}
        closeText="Undo"
        closeFunc={ () => {
          console.log("undo clicked");
          // undoNudgeAction(  );
          // undoNudgeAction(nudge?.nudgeId,"up");
          undoNudgeAction(nudgeFeedBack?.nudge?.nudgeId, nudgeFeedBack?.action );
          setShowMobileSnackbar(false);
        } }
        // subHeading={"Email sent successfully to Kianna."}
        setShowSnackBar={setShowMobileSnackbar}
        show={showMobileSnackBar}
        message="Thank you for your feedback"
        timeout={5000}
        anchor={"bottom-left"}
        type={"success"}
        // bgColorr="red"
      />
      { !thumsDownIds.includes(nudge?.nudgeId) ? (
<Box width={"100%"}
 
boxShadow={hover && "0px 0px 15px 1px rgba(0, 0, 0, 0.07)"}
>
<Box
                style={{ boxSizing: "border-box" }}
                background="#FFFFFF"
                borderTopLeftRadius="8px"
                borderTopRightRadius="8px"
                borderBottom="1px solid rgba(0, 0, 0, 0.1)"
                padding="12px"
                width="100%"
              >
                 <Flex
                  // marginTop={"12px"}
                  
                  borderBottom="1px dashed rgba(0, 0, 0, 0.1)"
                  marginBottom="16px"
                  paddingBottom={"12px"}
                  // justifyContent="space-between"
                  justifyContent={"flex-start"}
                  gridColumnGap={"10px"}
                  padding="0px 0px 12px 0px"
                  // height="16px"
                  alignItems="center"
                >
                    <Flex flexDirection={"column"} gridRowGap={"4px"} >
                    <Text
                      color="#0094E3"
                      fontSize="14px"
                      fontWeight="300"
                      
                    //   paddingRight="10px"
                      cursor="pointer"
                      onClick={() => {
                        dispatch( sn(nudge) );
                        navigate(`/aeReturnDashboard/stakeholder/${nudge?.stakeholderId}`);
                      }}
                    >
                      {" "}
                      {nudge?.stakeholderName}{" "}
                    </Text>
                    <Text color={"#57585C"} fontSize={"11px"} fontWeight={"400"} lineHeight={"160%"} >
                      { nudge?.email }
                    </Text>
                    </Flex>
                   
                    {/* {hover && (
              <Flex>
                <Flex
                  color="#DFDFDF"
                  flexDirection={"column"}
                  width={"100%"}
                  border="1px solid rgba(0, 0, 0, 0.1)"
                />
                <Text
                  ml="10px"
                  color="#57585C"
                  fontSize={"14px"}
                  fontWeight={300}
                >
                  {nudge?.email}
                </Text>
              </Flex>
            )} */}
                </Flex>
                <Flex flexDirection={"column"} gridRowGap={"8px"}  >
                 <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"12px"} >
                 {/* { getNudgeIcon(nudge) } */}
                 {getNudgeIcon(nudge, hover)}
                 <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"8px"} >
                 {/* <Icon name="nudgeIndexFinger" /> */}
                 <Text color={"#57585C"} fontWeight={"300"}  fontSize={"12px"}> { nudge?.context } </Text>
                 </Flex>
                 </Flex>
                 { getHeadlineStr(nudge) }
                 <Flex alignItems={"center"} justifyContent={"space-between"} >
                  <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"10px"} > 
                  { nudge?.nudgeCategory == "podcasts" && nudge?.crawlerData?.link && (
                   <Box
                   alignSelf={"flex-start"}
                   cursor="pointer"
                   onClick={() => {
                    insertActivityIntoActivityHistory(
                      nudge,
                      {},
                      loggedInMember,
                      "source"
                    );
                    
                     window.open(nudge?.crawlerData?.link, "_blank");
                   }}
                 >
                   <Tag
                     text={`Source: ${getDomainName(
                       nudge?.crawlerData?.link
                     )}`}
                     color="#0094E3"
                     bgColor="#F2FBFF"
                   />
                 </Box>
                ) }
                  { nudge?.nudgeCategory == "books" && nudge?.crawlerData?.link && (
                   <Box
                   alignSelf={"flex-start"}
                   cursor="pointer"
                   onClick={() => {
                    insertActivityIntoActivityHistory(
                      nudge,
                      {},
                      loggedInMember,
                      "source"
                    );
                   
                     window.open(nudge?.crawlerData?.link, "_blank");
                   }}
                 >
                   <Tag
                     text={`Source: ${getDomainName(
                       nudge?.crawlerData?.link
                     )}`}
                     color="#0094E3"
                     bgColor="#F2FBFF"
                   />
                 </Box>
                ) }
                  { nudge?.nudgeCategory == "movies" && nudge?.crawlerData?.url && (
                   <Box
                   alignSelf={"flex-start"}
                   cursor="pointer"
                   onClick={() => {
                    insertActivityIntoActivityHistory(
                      nudge,
                      {},
                      loggedInMember,
                      "source"
                    );
                   
                     window.open(nudge?.crawlerData?.url, "_blank");
                   }}
                 >
                   <Tag
                     text={`Source: ${getDomainName(
                       nudge?.crawlerData?.url
                     )}`}
                     color="#0094E3"
                     bgColor="#F2FBFF"
                   />
                 </Box>
                ) }
                 {nudge?.nudgeCategory == "weather" &&
                          nudge?.crawlerData?.weatherUrl && (
                            <Box
                              alignSelf={"flex-start"}
                              cursor="pointer"
                              onClick={() => {
                                insertActivityIntoActivityHistory(
                                  nudge,
                                  {},
                                  loggedInMember,
                                  "source"
                                );
                                window.open(
                                  nudge?.crawlerData?.weatherUrl,
                                  "_blank"
                                )
                              }}
                            >
                              <Tag
                                
                                text={`Source: ${getDomainName(nudge?.crawlerData?.weatherUrl)}`}
                                color="#0094E3"
                                bgColor="#F2FBFF"
                              />
                            </Box>
                          )}
                         {nudge?.nudgeCategory == "googlePlaces" &&
                          nudge?.crawlerData?.maps_url && (
                            <Box
                              alignSelf={"flex-start"}
                              cursor="pointer"
                              onClick={() => {
                                insertActivityIntoActivityHistory(
                                  nudge,
                                  {},
                                  loggedInMember,
                                  "source"
                                );
                                window.open(
                                  nudge?.crawlerData?.maps_url,
                                  "_blank"
                                )
                              }}
                            >
                              <Tag
                                text={`Source: ${getDomainName(nudge?.crawlerData?.maps_url)}`}
                                color="#0094E3"
                                bgColor="#F2FBFF"
                              />
                            </Box>
                          )}
                           {nudge?.nudgeCategory == "news" &&
                          nudge?.crawlerData?.url && (
                            <Box
                            alignSelf={"flex-start"}
                              cursor="pointer"
                              onClick={() => {
                                insertActivityIntoActivityHistory(
                                  nudge,
                                  {},
                                  loggedInMember,
                                  "source"
                                );
                                window.open(
                                  nudge?.crawlerData?.url,
                                  "_blank"
                                )
                              }}
                            >
                              <Tag
                                text={`Source: ${getDomainName(nudge?.crawlerData?.url)}`}
                                color="#0094E3"
                                bgColor="#F2FBFF"
                              />
                            </Box>
                          )}
                        {nudge?.nudgeCategory == "music" &&
                          nudge?.crawlerData?.link && (
                            <Box
                            alignSelf={"flex-start"}
                              cursor="pointer"
                              onClick={() => {
                                insertActivityIntoActivityHistory(
                                  nudge,
                                  {},
                                  loggedInMember,
                                  "source"
                                );
                                window.open(
                                  nudge?.crawlerData?.link,
                                  "_blank"
                                )
                              }}
                            >
                              <Tag
                                text={`Source: ${getDomainName(nudge?.crawlerData?.link)}`}
                                color="#0094E3"
                                bgColor="#F2FBFF"
                              />
                            </Box>
                          )}
                        {nudge?.nudgeCategory == "concertTickets" &&
                          nudge?.crawlerData?.eventTickets && (
                            <Box
                            alignSelf={"flex-start"}
                              cursor="pointer"
                              onClick={() => {
                                insertActivityIntoActivityHistory(
                                  nudge,
                                  {},
                                  loggedInMember,
                                  "source"
                                );
                                window.open(
                                  nudge?.crawlerData?.eventTickets,
                                  "_blank"
                                )
                              }}
                            >
                              <Tag
                                text={`Source: ${getDomainName(nudge?.crawlerData?.eventTickets)}`}
                                color="#0094E3"
                                bgColor="#F2FBFF"
                              />
                            </Box>
                          )}
                           {nudge?.nudgeCategory == "sports" &&(
                          <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"4px"} >
                              {nudge?.crawlerData?.schedule_url && nudge?.uniqueId.indexOf('30')>-1 && (
                            <Box
                            alignSelf={"flex-start"}
                              cursor="pointer"
                              onClick={() => {
                                insertActivityIntoActivityHistory(
                                  nudge,
                                  {},
                                  loggedInMember,
                                  "source"
                                );
                                window.open(
                                  nudge?.crawlerData?.schedule_url,
                                  "_blank"
                                )
                              }}
                            >
                              <Tag
                                text={`Source: ${getDomainName(nudge?.crawlerData?.schedule_url)}`}
                                color="#0094E3"
                                bgColor="#F2FBFF"
                              />
                            </Box>
                          )}
                            {nudge?.crawlerData?.news_url && nudge?.uniqueId.indexOf('16')>-1 && (
                            <Box
                            alignSelf={"flex-start"}
                              cursor="pointer"
                              onClick={() => {
                                insertActivityIntoActivityHistory(
                                  nudge,
                                  {},
                                  loggedInMember,
                                  "source"
                                );
                                window.open(
                                  nudge?.crawlerData?.news_url,
                                  "_blank"
                                )
                              }}
                            >
                              <Tag
                                text={`Source: ${getDomainName(nudge?.crawlerData?.news_url)}`}
                                color="#0094E3"
                                bgColor="#F2FBFF"
                              />
                            </Box>
                          )}
                          {nudge?.crawlerData?.game_id_url && nudge?.uniqueId.indexOf('17')>-1 && (
                            <Box
                            alignSelf={"flex-start"}
                              cursor="pointer"
                              onClick={() => {
                                insertActivityIntoActivityHistory(
                                  nudge,
                                  {},
                                  loggedInMember,
                                  "source"
                                );
                                window.open(
                                  nudge?.crawlerData?.game_id_url,
                                  "_blank"
                                )
                              }}
                            >
                              <Tag
                                text={`Source: ${getDomainName(nudge?.crawlerData?.game_id_url)}`}
                                color="#0094E3"
                                bgColor="#F2FBFF"
                              />
                            </Box>
                          )}

                          </Flex>
                           )
                            
                          }
                          <Flex alignItems={"center"}>
                  <TimeOutline />
                  <Text
                    color="#9F9F9F"
                    fontSize={"10px"}
                    fontWeight={400}
                    ml="5px"
                  >
                    Added {getDateString(nudge?.time)}
                  </Text>
                </Flex>
                </Flex>
                { !copiedIds.includes(nudge?.uniqueId) ? <ToolTip
                                        
                                        color="#EEEEEE"
                                        bgColor="#0094E3"
                                        boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
                                        borderRadius="8px"
                                        direction="top-start"
                                        title={
                                          <>
                                          <Text padding={"12px 16px"} fontSize={"14px"} fontWeight={"400"}> Description and the source link will be copied </Text>
                                          </>
                                        }
                                      >
        <Box background={"rgba(221, 1, 91, 0.05)"} borderRadius={"8px"} padding={"8px"} cursor="pointer" onClick={ () => {
            setCopiedIds([...copiedIds , nudge?.uniqueId]);
            let content = nudge?.nudgeHeadline;
            let links = [];
            if ( nudge?.nudgeCategory == "googlePlaces" &&
            nudge?.crawlerData?.maps_url ) {
                links.push(nudge?.crawlerData?.maps_url);
            } else if ( nudge?.nudgeCategory == "news" &&
            nudge?.crawlerData?.url ) {
             links.push(nudge?.crawlerData?.url);
            } else if ( nudge?.nudgeCategory == "music" &&
            nudge?.crawlerData?.link  ) {
             links.push(nudge?.crawlerData?.link);
            } else if ( nudge?.nudgeCategory == "concertTickets" &&
            nudge?.crawlerData?.eventTickets ) {
           links.push(nudge?.crawlerData?.eventTickets);
            } else if ( nudge?.nudgeCategory == "sports" && nudge?.crawlerData?.news_url && nudge?.uniqueId.indexOf('16')>-1 ) {
             links.push(nudge?.crawlerData?.news_url);
            } else if ( nudge?.nudgeCategory == "sports" && nudge?.crawlerData?.game_id_url && nudge?.uniqueId.indexOf('17')>-1 ) {
            links.push(nudge?.crawlerData?.game_id_url);
            } else if ( nudge?.nudgeCategory == "sports" && nudge?.crawlerData?.schedule_url &&
            nudge?.uniqueId.indexOf("30") > -1 ) {
links.push(nudge?.crawlerData?.schedule_url);
          } else if ( nudge?.nudgeCategory == "weather" && nudge?.crawlerData?.weatherUrl  ) {
              links.push(nudge?.crawlerData?.weatherUrl);
            }
            content = content + `\nSource: ${links.join(" ")}`
            navigator.clipboard.writeText(content);
            setTimeout( () => {
              let copiedId = copiedIds.filter( id => id != nudge?.uniqueId );
              setCopiedIds(copiedId);
            } , 1000 )
            insertActivityIntoActivityHistory(
              nudge,
              {},
              loggedInMember,
              "copy"
            );
        } } >                           
        <Icon name="copy" size="16" fill={"#DD015B"} />
        </Box>
        </ToolTip> : (
            <Box background={"#F4FFF1"} borderRadius={"8px"} padding={"8px"} >
                <Icon name="doubleTick" size="16" stroke="#24B300" />
                </Box>
        )}
                          </Flex>
                </Flex>
                </Box>
                <Box
                style={{ boxSizing: "border-box" }}
                background="#FFFFFF"
                borderBottomRightRadius="8px"
                borderBottomLeftRadius="8px"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                height="50px"
                width="100%"
                padding="8px 12px"
              >
               <Flex flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} width="100%"  >
               {nudge && nudge.email && getDomainFromEmail(nudge?.email) !== "" ? (
                                 <Flex alignItems={"center"}>
                                  <CompanyDomainIcon />{" "}
                                  <Text ml="10px" fontSize={"12px"} fontWeight={400} color="#0094E3">
                                    {" "}
                                    {getDomainFromEmail(nudge?.email)}
                                  </Text>
                                </Flex>): <Flex></Flex>}
                <Flex alignItems={"center"} gridRowGap={"10px"} >
               { thumsUpIds.includes( nudge?.nudgeId ) ? (
                <>
                </>
              //   <Flex flexDirection={"row"} gridColumnGap={"8px"} alignItems={"center"} >
              //    <Icon name="stakeholderDone" fill={"#1CB43D"}  />
              //    <Text color={"#24B300"} fontWeight={"400"} fontSize={"12px"} > Thank you for your feedback </Text>
              //    <Button variant="undo" onClick={ () => {
              //   undoNudgeAction(nudge?.nudgeId,"up");
              //  } } > Undo  </Button>
              //   </Flex>
                ) : (
<Flex gap="8px">
                 
                   <Box height={"25px"}  borderRadius={"4px"} cursor={"pointer"} border={"1px solid rgba(0, 0, 0, 0.1)"}   onClick={() => {
                      setSelectedNudge(nudge);
                      // updateNudgeFeedback(nudge, "up", "");
                      updateNudgeFeedback(nudge, "up", "").then( res => {
                        insertActivityIntoActivityHistory(nudge, {}, loggedInMember, 'like');
                       
                        setNudgeFeedBack({ nudge : nudge , action : "up" });
                        setShowMobileSnackbar(true);
                      } ).catch( err => {
                        console.log("error",err);
                      } )
                    }} >
                    <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"4px"} padding={"4px 8px"} >
                      {/* <Text color={"#9F9F9F"} lineHeight={"140%"} fontWeight={"400"} fontSize={"12px"} > I </Text> */}
                      <Icon name="heart" fill="#FF005D" />
                      {/* <Text color={"#9F9F9F"} lineHeight={"140%"} fontWeight={"400"} fontSize={"12px"} > this </Text> */}
                    </Flex>
                   </Box>
                   <Box height={"25px"}  borderRadius={"4px"} cursor={"pointer"} border={"1px solid rgba(0, 0, 0, 0.1)"}   onClick={() => {
                       dispatch(setSelectedNudgeForUnlike(nudge));
                       setHide(!hide);
                    }} >
                    <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"4px"} padding={"4px 8px"} >
                      
                      <Icon name="thumbsDown" fill={"#9F9F9F"} />
                     
                    </Flex>
                   </Box>
                
                </Flex>
                ) }
                 {/* { !copiedIds.includes(nudge?.uniqueId) ? <ToolTip
                                        
                                        color="#EEEEEE"
                                        bgColor="#0094E3"
                                        boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
                                        borderRadius="8px"
                                        direction="top-start"
                                        title={
                                          <>
                                          <Text padding={"12px 16px"} fontSize={"14px"} fontWeight={"400"}> Description and the source link will be copied </Text>
                                          </>
                                        }
                                      >
        <Box background={"rgba(221, 1, 91, 0.05)"} borderRadius={"8px"} padding={"8px"} cursor="pointer" onClick={ () => {
            setCopiedIds([...copiedIds , nudge?.uniqueId]);
            let content = nudge?.nudgeHeadline;
            let links = [];
            if ( nudge?.nudgeCategory == "googlePlaces" &&
            nudge?.crawlerData?.maps_url ) {
                links.push(nudge?.crawlerData?.maps_url);
            } else if ( nudge?.nudgeCategory == "news" &&
            nudge?.crawlerData?.url ) {
             links.push(nudge?.crawlerData?.url);
            } else if ( nudge?.nudgeCategory == "music" &&
            nudge?.crawlerData?.link  ) {
             links.push(nudge?.crawlerData?.link);
            } else if ( nudge?.nudgeCategory == "concertTickets" &&
            nudge?.crawlerData?.eventTickets ) {
           links.push(nudge?.crawlerData?.eventTickets);
            } else if ( nudge?.nudgeCategory == "sports" && nudge?.crawlerData?.news_url && nudge?.uniqueId.indexOf('16')>-1 ) {
             links.push(nudge?.crawlerData?.news_url);
            } else if ( nudge?.nudgeCategory == "sports" && nudge?.crawlerData?.game_id_url && nudge?.uniqueId.indexOf('17')>-1 ) {
            links.push(nudge?.crawlerData?.game_id_url);
            } else if ( nudge?.nudgeCategory == "weather" && nudge?.crawlerData?.weatherUrl  ) {
              links.push(nudge?.crawlerData?.weatherUrl);
            }
            content = content + `\nSource: ${links.join(" ")}`
            navigator.clipboard.writeText(content);
            setTimeout( () => {
              let copiedId = copiedIds.filter( id => id != nudge?.uniqueId );
              setCopiedIds(copiedId);
            } , 1000 )
        } } >                           
        <Icon name="copy" size="16" fill={"#DD015B"} />
        </Box>
        </ToolTip> : (
            <Box background={"#F4FFF1"} borderRadius={"8px"} padding={"8px"} >
                <Icon name="doubleTick" size="16" stroke="#24B300" />
                </Box>
        )} */}
        </Flex>
        </Flex>

              </Box>
</Box>
    ): (
<Box width={"100%"}>
         <Box
                style={{ boxSizing: "border-box" }}
                background="#FFFFFF"
                borderTopLeftRadius="8px"
                borderTopRightRadius="8px"
                borderBottom="1px solid rgba(0, 0, 0, 0.1)"
                padding="20px 12px"
                width="100%"
              >
              <Flex  flexDirection={"row"} gridColumnGap={"40px"} justifyContent={"space-betwen"} alignItems={"center"} >
               <Text color={"#050505"} lineHeight={"150%"} fontWeight={"300"} fontSize={"14px"} > We won't recommend this nudge to you again </Text>
               <Button variant="undo" onClick={ () => {
                undoNudgeAction(nudge?.nudgeId,"down");
               } } > Undo  </Button>
              </Flex>
              </Box>
        </Box>
    ) }
      </>
    )
  
}
export default RecommendMobileNudgeView;