import * as React from 'react';

function ZendeskLogo() {
    return (
        <svg width="58" height="12" viewBox="0 0 58 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.65274 3.14819V11.5865H0.720215L7.65274 3.14819Z" fill="white" />
            <path d="M7.65274 0.120117C7.65274 2.05004 6.10127 3.61528 4.18594 3.61528C2.27062 3.61528 0.720215 2.05004 0.720215 0.120117H7.65274Z" fill="white" />
            <path d="M8.79443 11.5877C8.79443 9.65777 10.3459 8.09253 12.2612 8.09253C14.1766 8.09253 15.728 9.65669 15.728 11.5877H8.79443Z" fill="white" />
            <path d="M8.79443 8.55949V0.120117H15.7291L8.79443 8.55842V8.55949Z" fill="white" />
            <path d="M44.6521 3.74438C44.6223 3.74438 44.5913 3.74438 44.5614 3.74438C43.1476 3.77235 42.024 4.95139 42.0518 6.37678C42.0518 7.88931 43.1327 9.0081 44.8047 9.0081C45.78 9.0081 46.4074 8.65955 46.8598 8.19052L46.1748 7.44502C45.8024 7.80109 45.3062 7.99473 44.793 7.9829L44.7898 7.98613C43.8967 7.98613 43.3845 7.51817 43.2458 6.7845H47.1703C47.2536 5.01809 46.3124 3.74438 44.6521 3.74438ZM43.2458 5.94217C43.3813 5.18268 43.9073 4.75238 44.6617 4.75238C45.4727 4.75238 45.8782 5.25691 45.9849 5.94217H43.2458Z" fill="white" />
            <path d="M19.0254 7.92557L22.0451 4.83167H19.0969V3.86133H23.4461V4.85103L20.4264 7.94386H23.4888V8.91527H19.0254V7.92557Z" fill="white" />
            <path d="M26.7371 3.74316C26.7072 3.74316 26.6763 3.74316 26.6464 3.74316C25.2326 3.77113 24.109 4.95017 24.1367 6.37556C24.1367 7.88809 25.2176 9.00688 26.8897 9.00688C27.865 9.00688 28.4924 8.65833 28.9448 8.1893L28.2598 7.4438C27.8874 7.79987 27.3912 7.99351 26.8779 7.98168V7.98491C25.9838 7.98491 25.4716 7.51695 25.3329 6.78328H29.2553C29.3385 5.01687 28.3974 3.74316 26.7371 3.74316ZM25.3329 5.94095C25.4684 5.18146 25.9944 4.75116 26.7488 4.75116C27.5598 4.75116 27.9653 5.25569 28.072 5.94095H25.3329Z" fill="white" />
            <path d="M39.969 1.52148V4.50351C39.5497 4.01403 38.9383 3.73648 38.297 3.74509C36.9771 3.74509 35.7788 4.76061 35.7788 6.37426H35.7799C35.7799 7.98145 37.007 9.00343 38.2874 9.00343C38.9394 9.00881 39.5582 8.71513 39.969 8.20521V8.90123H41.0361V1.52148H39.969ZM40.0277 6.36458C40.0277 7.27468 39.3555 8.01265 38.4379 8.01265C37.5746 8.01265 36.8693 7.31986 36.8458 6.45064C36.8224 5.56421 37.5159 4.82731 38.3941 4.80364C39.2733 4.77998 40.0043 5.47922 40.0277 6.36458H40.0267H40.0277Z" fill="white" />
            <path d="M47.8691 7.82773L48.8359 7.32212C49.0962 7.79008 49.5924 8.07408 50.1248 8.0601C50.7288 8.0601 51.0425 7.74813 51.0425 7.39312C51.0425 6.98864 50.4588 6.89827 49.8239 6.76595C48.9703 6.58307 48.0815 6.30014 48.0815 5.25988C48.0815 4.46059 48.8359 3.72154 50.0235 3.73229C50.9593 3.73229 51.656 4.10666 52.0508 4.71124L51.1545 5.20609C50.9027 4.83065 50.4759 4.61227 50.0267 4.62948C49.4526 4.62948 49.1603 4.91241 49.1603 5.23621C49.1603 5.59982 49.6233 5.70094 50.3489 5.86338C51.1748 6.04626 52.1095 6.31843 52.1095 7.36946C52.1095 8.06655 51.5056 9.00677 50.0758 8.99709C49.0301 8.99709 48.2938 8.57216 47.8702 7.8245" fill="white" />
            <path d="M55.0089 6.65509L54.1627 7.58563V8.89806H53.0957V1.51831H54.1627V6.35172L56.4281 3.84519H57.7277L55.7644 6.00748L57.7789 8.89913H56.571L55.01 6.65509H55.0089Z" fill="white" />
            <path d="M32.5556 3.74438C31.2858 3.74438 30.2241 4.57272 30.2241 5.9497V8.90268H31.3125V6.08632C31.3125 5.25799 31.7852 4.76098 32.5929 4.76098C33.4007 4.76098 33.8019 5.25583 33.8019 6.08632V8.90268H34.8839V5.9497C34.8839 4.57488 33.8168 3.74438 32.5524 3.74438" fill="white" />
        </svg>
    )
}

const ZendeskLogoIcon = React.memo(ZendeskLogo);
export default ZendeskLogoIcon;