import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

const initialState = {
  companyScoreReportData: [],
};

export interface companyScoreReportReducerType {}
const companyScoreReportReducerSlice = createSlice({
  name: "companyScoreReportState",
  initialState: initialState,
  reducers: {
    setcompanyScoreReportData: (
      state,
      action: PayloadAction<companyScoreReportReducerType>
    ) => {
      //@ts-ignore
      state.companyScoreReportData = action.payload;
    },
  },
});

export const {
  setcompanyScoreReportData,
} = companyScoreReportReducerSlice.actions;

export const companyScoreReportState = (state: RootState) => state.companyScoreState;

export default companyScoreReportReducerSlice.reducer;
