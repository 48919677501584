import React, { useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import * as XLSX from "xlsx";
import DeleteIcon from "assets/icons/DeleteIcon";
import { Backdrop } from "@mui/material";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { UploadFile } from "@mui/icons-material";
import Select from 'react-select';
import { ErrorMessage, Formik, Form } from "formik";
import * as yup from "yup";
import userService from "services/userService";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import TextField from '@mui/material/TextField';
import { overflow } from "styled-system";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";

const columnHeadings = ["Email Address", "LinkedIn URL"];

const validationSchema = yup.object().shape({
  user: yup.string().required("user is required"),
  tagName: yup.string().required('Please enter a tag name'),
});

const UploadModal = ({ isOpen, onClose, handleListUpload }) => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [showDataPopup, setShowDataPopup] = useState(false);
  const [userOption, setUserOption] = useState([]);

  const inputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files?.[0] || null;
    setSelectedFile(file);
  };

  const fetchMemberList = async () => {
    try {
        userService.getAllMember()
            .then((response) => {
                const adminMemberList = response.data
                    .filter(item => item.organizationId)
                    .map(({ userId, firstName, lastName, email }) => ({
                        value: userId,
                        label: `${firstName} ${lastName} <${email}>`,
                    }));
                setUserOption(adminMemberList);


            })
    }
    catch (error) {
        console.error("Error fetching company list", error);
    }
};

  useEffect(() => {
    if (selectedFile) {
      handleUpload();
    }
  }, [selectedFile]);

  useEffect(() => {
    fetchMemberList();
  }, [])

  const handleUpload = () => {
    setLoading(true);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result?.toString() || "";
        const extension = selectedFile.name.split(".").pop()?.toLowerCase();
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\r)?$/;
        // const linkedinUrlRegex= /^https:\/\/www\.linkedin\.com\/(in|pub)\/[^\/]+$/;
        if (extension === "csv") {
          // Parse CSV
          const rows = content.split("\n");
          const data = rows.map((row) => row.split(","));
          console.log(data);
          setFileData(data.slice(1).filter(row => emailRegex.test(row[0])));
          //setFileData(data);
        } else if (extension === "xlsx" || extension === "xls") {
          const workbook = XLSX.read(content, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          console.log(data);
          setFileData(data.slice(1).filter(row => emailRegex.test(row[0])));
        } else {
          console.error("Unsupported file type");
        }

        setShowDataPopup(true);
        setSelectedFile(null);
        onClose();
      };

      if (
        selectedFile.name.endsWith(".xlsx") ||
        selectedFile.name.endsWith(".xls")
      ) {
        reader.readAsBinaryString(selectedFile);
      } else {
        reader.readAsText(selectedFile);
      }
    }
    setLoading(false);
  };

  const handleDeleteRow = (index) => {
    const updatedData = [...fileData];
    updatedData.splice(index, 1);
    setFileData(updatedData);
  };

  const handleDataPopupClose = () => {
    setFileData([]);
    setShowDataPopup(false);
  };

  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
          open={true}
        >
          <Icon name="loadingIcon" />
        </Backdrop>
      )}
      <ModalWrapper
        width="max-content"
        height="max-content"
        hideModal={!isOpen}
        setHideModal={onClose}
        bgcolor={"rgba(0, 0, 0, 0.8)"}
        padding="18px"
      >
        <Flex flexDirection="column" gap="10px">
          <Text fontSize="20px" fontWeight="700" textAlign={"center"}>
            Upload the List
          </Text>

          <Flex
            gap="20px"
            alignItems={"center"}
            justifyContent={"center"}
            marginTop={"20px"}
          >
            <Button
              size="s"
              variant="primary"
              borderRadius={"15px"}
              onClick={() => inputRef.current.click()}
            >
              Upload
            </Button>
            <input
              type="file"
              accept=".xlsx, .xls, .csv"
              onChange={handleFileChange}
              style={{ display: "none" }}
              ref={inputRef}
            />

            <Button
              size="s"
              variant="secondary"
              borderRadius={"15px"}
              onClick={onClose}
            >
              Close
            </Button>
          </Flex>
          <Text fontSize="12px" fontWeight="300" textAlign={"center"}>
            {selectedFile ? (
              <p>Selected file: {selectedFile.name}</p>
            ) : (
              <p>No file selected</p>
            )}
          </Text>
        </Flex>
      </ModalWrapper>

      {showDataPopup && (
        <ModalWrapper
          width="auto"
          height="auto"
          hideModal={!showDataPopup}
          setHideModal={handleDataPopupClose}
          bgcolor={"rgba(0, 0, 0, 0.8)"}
          padding="48px"
        >
          <Formik
            enableReinitialize
            initialValues={{user : "", tagName:""}}
            onSubmit={(values) => {
              const selectedUser = userOption.find((user) => user.value === values.user);
              const parts = selectedUser.label.split(/<|>/);
              const ownerName = parts[0].trim();
              const ownerEmail = parts[1].trim();
                handleListUpload(values, fileData, ownerName, ownerEmail);
                setShowDataPopup(false);
            }}
            validationSchema={validationSchema}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <Flex flexDirection="column" gap="15px">
                  <Flex alignItems={"left"}>
                      <Box width={"auto"}>
                          <Select
                              className="single-select"
                              placeholder="Select User"
                              name="user"
                              options={userOption}
                              onChange={(user) => setFieldValue("user", user ? user.value : null)}
                          />
                          <ErrorMessage
                              name="user"
                              component="div"
                              className="custom-error-message"
                          />
                      </Box>
                      <Box marginLeft={"10px"} width={"auto"}>
                          <FormikInput
                              height={"50px"}
                              padding={"0 12px"}
                              placeholder="Enter a tag name"
                              name="tagName"
                              id="tagName"
                          />
                      </Box>
                  </Flex>
                  <Flex alignItems="center" justifyContent={"space-between"} width={"100%"}>
                    <Flex width={"10%"}>
                      <Text>#</Text>
                    </Flex>
                    <Flex width={"80%"} justifyContent={"space-between"}>
                    {columnHeadings.map((heading, columnIndex) => (
                     <Flex
                     padding={"0 5px"}
                     textWrap="balance"
                     width="50%"
                     key={columnIndex}
                     position={'sticky'}
                     >   
                        <Text
                            key={columnIndex}
                            fontWeight={400}
                            marginRight={"20px"}
                        >
                            {heading}
                        </Text>
                     </Flex>   
                    ))}
                    </Flex>
                    <Flex width={"10%"}>
                      <Text>Action</Text>
                    </Flex>
                  </Flex>
                  <div style={{overflow : 'auto', maxHeight : '35vh', border : '1px solid lightblue', borderRadius : '10px'}} >    
                    {fileData.map((rowData, rowIndex) => (
                      <Flex
                        key={rowIndex}
                        alignItems="left"
                        justifyContent={"space-between"}
                        padding={"15px"}
                        fontSize="12px"
                        fontWeight={500}
                        width={"100%"}
                        position="sticky"
                        backgroundColor={
                          rowIndex % 2 === 0 ? "#FFF" : "rgb(249, 249, 249)"
                        }
                      > 
                          <Flex width={"10%"}>
                            <Text>{rowIndex + 1}</Text>
                          </Flex>
                          <Flex width={"80%"} justifyContent={"space-between"}>
                            {rowData.map((data, columnIndex) => (
                                <Flex
                                padding={"0 5px"}
                                textWrap="balance"
                                width="50%"
                                alignItems={"center"}
                                key={columnIndex}
                                >
                                    <Text>{data}</Text>
                                </Flex>
                            ))}
                          </Flex>  
                          <Flex width={"10%"}>
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={() => handleDeleteRow(rowIndex)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Flex>  
                      </Flex>
                    ))}
                  </div>

                  <Flex justifyContent={"right"} alignItems={"center"} marginTop={3} position={'sticky'}>
                      <Button
                        size="s"
                        variant="primary"
                        borderRadius={"15px"}
                        type="submit"
                        marginRight={2}
                      >
                        Upload list
                      </Button>
                      <Button
                        size="s"
                        variant="secondary"
                        borderRadius={"15px"}
                        onClick={handleDataPopupClose}
                      >
                        Cancel
                      </Button>
                  </Flex>
                </Flex>
              </Form>
            )}
          </Formik>
        </ModalWrapper>
      )}
    </>
  );
};

export default UploadModal;
