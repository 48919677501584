import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";

interface AeReturnOppoTableBodyProps {
  opportunitiesData: any[];
}

const AeReturnOppoTableBody: React.FC<AeReturnOppoTableBodyProps> = ({
  opportunitiesData,
}) => {
  return (
    <Grid>
      {opportunitiesData &&
        opportunitiesData?.map((user: any, index) => {
          return (
            <Grid
              fontSize="14px"
              fontWeight={400}
              color="#050505"
              textAlign={"center"}
            >
              <tr>
                <Box width={"5%"}>
                  <td>{index + 1}</td>
                </Box>
                <Box width={"20%"}>
                  <td>
                    <Text mr="10px">{user?.name}</Text>
                  </td>
                </Box>
                <td>
                  {" "}
                  <Flex justifyContent={"center"} marginLeft="15px">
                    <Box
                      padding={"3px 8px"}
                      backgroundColor={"rgba(34, 71, 201, 0.1)"}
                      borderRadius="6px"
                      color="#2247C9"
                    >
                      {user?.stage}
                    </Box>
                  </Flex>
                </td>
                <td>
                  <Text mr="10px">{user?.value}</Text>
                </td>

                <td>
                  {" "}
                  <Text>{user?.stakeholders}</Text>
                </td>

                <td style={{ marginRight: "5px" }}>
                  {" "}
                  <Text>{user?.approved_budget}</Text>
                </td>
                <td style={{ marginRight: "10px" }}>
                  {" "}
                  <Text>{user?.available_budget}</Text>
                </td>
              </tr>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default AeReturnOppoTableBody;
