import React, { FC, useMemo, useState, useCallback } from "react";
// import { Slate, Editable, withReact , useSlate  } from 'slate-react';
// import { Editor, createEditor, Transforms, Element as SlateElement } from 'slate';
// import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
// import { Button as Btn } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
// import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
// import { Icon as Ic } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
// import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";

import imageExtensions from "image-extensions";
import isUrl from "is-url";
import isHotkey from "is-hotkey";
import {
  createEditor,
  BaseEditor,
  Descendant,
  Editor,
  Transforms,
  Element as SlateElement,
  Text as TextSlate,
} from "slate";
import escapeHtml from "escape-html";
import { jsx } from "slate-hyperscript";
import { withHistory } from "slate-history";
import {
  Slate,
  Editable,
  withReact,
  useSlateStatic,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  useSlate,
  useSelected,
  useFocused,
} from "slate-react";

import { css } from "@emotion/css";
import { Button, Icon, Toolbar } from "./EmailEditorComponents";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { TextArea } from "@evabot/eva-react-core-library/dist/atoms/TextArea/TextArea";
import { Button as CoreBtn } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Icon as Ic } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Button as Btn } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { SelectComponent } from "@evabot/eva-react-core-library/dist/atoms/SelectComponent/SelectComponent";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks";
import { axiosHelper } from "../../utils";
import { useAppDispatch } from "../../hooks";
// import { setSlateElements , setEditor , setSelectedStage} from "./store/SlateEditorReducer";
import { capitalizeFirstLetter } from "../../utils";

// import EditorToolbar from './EditorToolBar';

// const Toolbar = ({ editor }) => {
//     const toggleMark = useCallback(
//       (format) => {
//         editor.execCommand({
//           type: 'toggle_mark',
//           format,
//         });
//       },
//       [editor]
//     );

//     return (
//       <div>
//         <button
//           onMouseDown={(e) => {
//             e.preventDefault();
//             toggleMark('bold');
//           }}
//         >
//           Bold
//         </button>
//         <button
//           onMouseDown={(e) => {
//             e.preventDefault();
//             toggleMark('italic');
//           }}
//         >
//           Italic
//         </button>
//         <button
//           onMouseDown={(e) => {
//             e.preventDefault();
//             toggleMark('underline');
//           }}
//         >
//           Underline
//         </button>
//       </div>
//     );
//   };
// const withToolbar = (editor) => {
//     const { execCommand } = editor;
//     editor.execCommand = (command) => {
//       if (command.type === 'toggle_mark') {
//         const { format } = command;
//         const isActive = Editor.marks(editor)[format];
//         if (isActive) {
//           Editor.removeMark(editor, format);
//         } else {
//           Editor.addMark(editor, format, true);
//         }
//       } else {
//         execCommand(command);
//       }
//     };
//     return editor;
//   };
const HOTKEYS: any = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const EmailEditor: FC = () => {
  //@ts-ignore
  const withImages = (editor) => {
    const { insertData, isVoid } = editor;
    //@ts-ignore
    editor.isVoid = (element) => {
      return element.type === "image" ? true : isVoid(element);
    };
    //@ts-ignore
    editor.insertData = (data) => {
      const text = data.getData("text/plain");
      const { files } = data;

      if (files && files.length > 0) {
        for (const file of files) {
          const reader = new FileReader();
          const [mime] = file.type.split("/");

          if (mime === "image") {
            reader.addEventListener("load", () => {
              const url = reader.result;
              insertImage(editor, url);
            });

            reader.readAsDataURL(file);
          }
        }
      } else if (isImageUrl(text)) {
        insertImage(editor, text);
      } else {
        insertData(data);
      }
    };

    return editor;
  };
  // @ts-ignore
  const editor1 = withImages(withReact(withHistory(createEditor())));
  const editor2 = withImages(withReact(withHistory(createEditor())));
  const [activeEditor, setActiveEditor] = useState(1);
  const [subject, setSubject] = useState([
    { type: "paragraph", children: [{ text: "" }] },
  ]);

  const [body, setBody] = useState([
    { type: "paragraph", children: [{ text: "" }] },
  ]);

  //   const editor = useMemo(() => withReact(createEditor()), []);

  const handleSubjectChange = useCallback((newValue) => {
    setActiveEditor(1);
    setSubject(newValue);
  }, []);

  const handleBodyChange = useCallback((newValue) => {
    setActiveEditor(2);
    setBody(newValue);
  }, []);

  const isMarkActive = (editor: any, format: any) => {
    const marks: any = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };
  const isBlockActive = (
    editor: Editor,
    format: string,
    blockType = "type"
  ) => {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          //@ts-ignore
          n[blockType] === format,
      })
    );

    return !!match;
  };
  const toggleBlock = (editor: Editor, format: string) => {
    const isActive = isBlockActive(
      editor,
      format,
      TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
    );
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type) &&
        !TEXT_ALIGN_TYPES.includes(format),
      split: true,
    });
    let newProperties: Partial<SlateElement>;
    if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = {
        align: isActive ? undefined : format,
      };
    } else {
      newProperties = {
        type: isActive ? "paragraph" : isList ? "list-item" : format,
      };
    }
    Transforms.setNodes<SlateElement>(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };

  const toggleMark = (editor: any, format: any) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const Element = ({ attributes, children, element }) => {
    switch (element.type) {
      case "heading-one":
        return <h1 {...attributes}>{children}</h1>;
      case "heading-two":
        return <h2 {...attributes}>{children}</h2>;
      case "heading-three":
        return <h3 {...attributes}>{children}</h3>;
      case "heading-four":
        return <h4 {...attributes}> {children} </h4>;
      case "block-quote":
        return <pre {...attributes}>{children}</pre>;
      case "bulleted-list":
        return <ul {...attributes}>{children}</ul>;
      case "numbered-list":
        return <ol {...attributes}>{children}</ol>;
      case "list-item":
        return <li {...attributes}>{children}</li>;
      case "image":
        return <img src={element.url} alt={element.alt} {...attributes} />;
      case "alignleft":
        return (
          <div style={{ textAlign: "left" }} {...attributes}>
            {children}
          </div>
        );
      case "alignright":
        return (
          <div style={{ textAlign: "right" }} {...attributes}>
            {children}
          </div>
        );
      case "aligncenter":
        return (
          <div style={{ textAlign: "center" }} {...attributes}>
            {children}
          </div>
        );
      case "paragraph":
      default:
        return <p {...attributes}>{children}</p>;
    }
  };

  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }

    if (leaf.italic) {
      children = <em>{children}</em>;
    }

    if (leaf.strikethrough) {
      children = <del>{children}</del>;
    }

    return <span {...attributes}>{children}</span>;
  };
  //@ts-ignore
  const BlockButton = ({ format, icon }) => {
    const editor = useSlate();
    return (
      <Button
        active={isBlockActive(
          editor,
          format,
          TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
        )}
        //@ts-ignore
        onMouseDown={(event) => {
          event.preventDefault();
          toggleBlock(editor, format);
        }}
      >
        <Icon>{icon}</Icon>
      </Button>
    );
  };
  //@ts-ignore
  const UndoRedoButton = ({ format, icon }) => {
    const editor = useSlate();
    return (
      <Button
        active={isMarkActive(editor, format)}
        //@ts-ignore
        onMouseDown={(event) => {
          event.preventDefault();
          if (format === "undo") {
            //@ts-ignore
            editor.undo();
          } else {
            //@ts-ignore
            editor.redo();
          }
          // toggleMark(editor, format)
        }}
      >
        <Icon>{icon}</Icon>
      </Button>
    );
  };
  //@ts-ignore
  const MarkButton = ({ format, icon }) => {
    const editor = useSlate();
    return (
      <Button
        active={isMarkActive(editor, format)}
        //@ts-ignore
        onMouseDown={(event) => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
      >
        <Icon>{icon}</Icon>
      </Button>
    );
  };
  //@ts-ignore
  const isImageUrl = (url) => {
    if (!url) return false;
    if (!isUrl(url)) return false;
    const ext = new URL(url).pathname.split(".").pop();
    //@ts-ignore
    return imageExtensions.includes(ext);
  };
  //@ts-ignore
  const insertImage = (editor, url) => {
    const text = { text: "" };
    const image = { type: "image", url, children: [text] };
    Transforms.insertNodes(editor, image);
  };
  const InsertImageButton = () => {
    const editor = useSlateStatic();
    return (
      <Button
        //@ts-ignore
        onMouseDown={(event) => {
          event.preventDefault();
          const url = window.prompt("Enter the URL of the image:");
          if (url && !isImageUrl(url)) {
            alert("URL is not an image");
            return;
          }
          url && insertImage(editor, url);
        }}
      >
        <Icon>image</Icon>
      </Button>
    );
  };

  //@ts-ignore
  // const UndoRedo = (type) => {
  // const editor = useSlate();
  // if ( type == "undo" ) {
  // //@ts-ignore
  // editor.undo();
  // } else {
  // //@ts-ignore
  // editor.redo();
  // }
  // }
  //@ts-ignore
  const Image = ({ attributes, children, element }) => {
    const editor = useSlateStatic();
    const path = ReactEditor.findPath(editor, element);

    const selected = useSelected();
    const focused = useFocused();
    return (
      <div {...attributes}>
        {children}
        <div
          contentEditable={false}
          className={css`
            position: relative;
          `}
        >
          <img
            src={element.url}
            alt="element url"
            className={css`
              display: block;
              max-width: 100%;
              max-height: 20em;
              box-shadow: ${selected && focused ? "0 0 0 3px #B4D5FF" : "none"};
            `}
          />
          <Button
            active
            onClick={() => Transforms.removeNodes(editor, { at: path })}
            className={css`
              display: ${selected && focused ? "inline" : "none"};
              position: absolute;
              top: 0.5em;
              left: 0.5em;
              background-color: white;
            `}
          >
            <Icon>delete</Icon>
          </Button>
        </div>
      </div>
    );
  };

  //     const [value1, setValue1] = useState([
  //         {
  //           type: 'paragraph',
  //           children: [{ text: 'Editor 1' }],
  //         },
  //       ]);

  //       const [value2, setValue2] = useState([
  //         {
  //           type: 'paragraph',
  //           children: [{ text: 'Editor 2' }],
  //         },
  //       ]);
  //       const renderElement = useCallback((props) => <Element {...props} />, []);
  //   const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  //   const editor1 = useMemo(() => withToolbar(withReact(createEditor())), []);
  //   const editor2 = useMemo(() => withToolbar(withReact(createEditor())), []);

  return (
    // <Slate
    //   editor={activeEditor == 1 ? editor1 : editor2}
    //   value={activeEditor == 2 ? body : subject}
    // >
    //   <Box width={"100vw"} height={"100vh"}>
    //     <Flex
    //       padding={"17px 32px"}
    //       justifyContent={"space-between"}
    //       alignItems={"center"}
    //     >
    //       <Ic name="evaLogo" fill="black" />
    //       <Ic name="videoIcon" fill={"#0094E3"} />
    //     </Flex>
    //     <Box
    //       width={"100%"}
    //       boxShadow={"inset 0px -1px 0px #D6D6D6"}
    //       height={"2px"}
    //     />
    //     <Flex
    //       width={"100%"}
    //       height={"40px"}
    //       justifyContent={"center"}
    //       alignItems={"center"}
    //     >
    //       <Toolbar
    //         style={{
    //           margin: "10px 0px 0px 0px",

    //           // margin:  "0 auto"
    //         }}
    //       >
    //         <MarkButton format="bold" icon="format_bold" />
    //         <MarkButton format="italic" icon="format_italic" />
    //         <MarkButton format="underline" icon="format_underlined" />
    //         <MarkButton format="code" icon="code" />
    //         <BlockButton format="heading-one" icon="looks_one" />
    //         <BlockButton format="heading-two" icon="looks_two" />
    //         <BlockButton format="heading-three" icon="looks_3" />
    //         <BlockButton format="heading-four" icon="looks_4" />
    //         <BlockButton format="block-quote" icon="format_quote" />
    //         <BlockButton format="numbered-list" icon="format_list_numbered" />
    //         <BlockButton format="bulleted-list" icon="format_list_bulleted" />
    //         <BlockButton format="left" icon="format_align_left" />
    //         <BlockButton format="center" icon="format_align_center" />
    //         <BlockButton format="right" icon="format_align_right" />
    //         <BlockButton format="justify" icon="format_align_justify" />
    //         <UndoRedoButton format="undo" icon="undo" />
    //         <UndoRedoButton format="redo" icon="redo" />
    //         <InsertImageButton />
    //       </Toolbar>
    //       {/* </Slate> */}
    //       {/* <Toolbar editor={editor1} /> */}
    //     </Flex>
    //     <Box
    //       width={"100%"}
    //       boxShadow={"inset 0px -1px 0px #D6D6D6"}
    //       height={"2px"}
    //     />
    //     <Flex
    //       flexDirection={"row"}
    //       justifyContent={"flex-start"}
    //       gridColumnGap={"20px"}
    //       marginLeft={"70px"}
    //       marginTop={"26px"}
    //       marginRight={"70px"}
    //     >
    //       <Flex
    //         alignSelf={"flex-start"}
    //         flexDirection={"row"}
    //         gridColumnGap={"10px"}
    //         justifyContent={"center"}
    //         alignItems={"center"}
    //         width={"30%"}
    //       >
    //         <Ic name="arrowLeft" fill="#0094E3" />
    //         <Text color="#0094E3" fontSize={"12px"} fontWeight={"400"}>
    //           {" "}
    //           Go back{" "}
    //         </Text>
    //       </Flex>
    //       <Flex
    //         flexDirection={"column"}
    //         gridRowGap={"30px"}
    //         justifyContent={"flex-start"}
    //         alignItems={"flex-start"}
    //         width={"70%"}
    //       >
    //         <Flex flexDirection={"column"} gridRowGap={"18px"} width={"100%"}>
    //           <Flex
    //             flexDirection={"row"}
    //             gridColumnGap={"10px"}
    //             alignItems={"center"}
    //             justifyContent={"flex-start"}
    //           >
    //             <Ic name="mail" fill="#57585C" />
    //             <Text fontWeight={"500"} fontSize={"14px"} color={"#050505"}>
    //               {" "}
    //               Subject{" "}
    //             </Text>
    //           </Flex>
    //           <Flex
    //             flexDirection={"row"}
    //             alignItems={"flex-end"}
    //             gridColumnGap={"20px"}
    //           >
    //             {/* <Slate editor={editor} value={subject} onChange={handleSubjectChange}> */}
    //             <Editable
    //               style={{
    //                 borderRadius: "4px",
    //                 border: "1px solid rgba(0, 0, 0, 0.1)",
    //                 height: "49px",
    //                 padding: "10px 16px",
    //                 width: "60%",
    //               }}
    //             />
    //             {/* </Slate> */}
    //             {/* <Slate editor={editor1} value={value1}

    //                   // @ts-ignore
    //                   onChange={(value) => setValue1(value)}>
    //    <Editable style={{  borderRadius : "4px" , border : "1px solid rgba(0, 0, 0, 0.1)" , height : "49px" , padding:"10px 16px" , width : "60%"  }} renderElement={renderElement} renderLeaf={renderLeaf} />
    //  </Slate> */}

    //             <Flex
    //               flexDirection={"row"}
    //               gridColumnGap={"36px"}
    //               alignItems={"center"}
    //               width={"40%"}
    //             >
    //               <Box
    //                 background={"#FFFCF3"}
    //                 border={"1px solid rgba(226, 95, 0, 0.2)"}
    //                 borderRadius={"8px"}
    //                 padding={"6px 12px"}
    //                 cursor={"pointer"}
    //               >
    //                 <Flex
    //                   flexDirection={"row"}
    //                   gridColumnGap={"4px"}
    //                   alignItems={"center"}
    //                 >
    //                   {/* <Ic name="refresh" /> */}
    //                   <Text
    //                     color="rgba(226, 95, 0, 0.9)"
    //                     fontWeight={"400"}
    //                     fontSize={"14px"}
    //                   >
    //                     {" "}
    //                     Generate new copy using AI{" "}
    //                   </Text>
    //                 </Flex>
    //               </Box>
    //               <Text
    //                 color={"#DD015B"}
    //                 fontSize={"12px"}
    //                 fontWeight={"500"}
    //                 cursor={"pointer"}
    //               >
    //                 {" "}
    //                 Undo{" "}
    //               </Text>
    //             </Flex>
    //           </Flex>
    //         </Flex>
    //         <Flex flexDirection={"column"} gridRowGap={"18px"} width={"100%"}>
    //           <Flex
    //             flexDirection={"row"}
    //             gridColumnGap={"10px"}
    //             alignItems={"center"}
    //             justifyContent={"flex-start"}
    //           >
    //             <Ic name="mail" fill="#57585C" />
    //             <Text fontWeight={"500"} fontSize={"14px"} color={"#050505"}>
    //               {" "}
    //               Body text{" "}
    //             </Text>
    //           </Flex>
    //           <Flex
    //             flexDirection={"row"}
    //             alignItems={"flex-end"}
    //             gridColumnGap={"20px"}
    //           >
    //             {/* <Slate editor={editor} value={body} onChange={handleBodyChange}> */}
    //             <Editable
    //               style={{
    //                 borderRadius: "4px",
    //                 border: "1px solid rgba(0, 0, 0, 0.1)",
    //                 height: "235px",
    //                 padding: "10px 16px",
    //                 width: "60%",
    //               }}
    //             />
    //             {/* </Slate> */}
    //             {/* <Slate editor={editor2} value={value2}
    //                 // @ts-ignore
    //                 onChange={(value) => setValue2(value)}>
    //     <Editable style={{  borderRadius : "4px" , border : "1px solid rgba(0, 0, 0, 0.1)" , height : "235px" , padding:"10px 16px" , width : "60%"  }} renderElement={renderElement} renderLeaf={renderLeaf} />
    //   </Slate> */}

    //             <Flex
    //               flexDirection={"row"}
    //               gridColumnGap={"36px"}
    //               alignItems={"center"}
    //               width={"40%"}
    //             >
    //               <Box
    //                 background={"#FFFCF3"}
    //                 border={"1px solid rgba(226, 95, 0, 0.2)"}
    //                 borderRadius={"8px"}
    //                 padding={"6px 12px"}
    //                 cursor={"pointer"}
    //               >
    //                 <Flex
    //                   flexDirection={"row"}
    //                   gridColumnGap={"4px"}
    //                   alignItems={"center"}
    //                 >
    //                   {/* <Ic name="refresh" /> */}
    //                   <Text
    //                     color="rgba(226, 95, 0, 0.9)"
    //                     fontWeight={"400"}
    //                     fontSize={"14px"}
    //                   >
    //                     {" "}
    //                     Generate new copy using AI{" "}
    //                   </Text>
    //                 </Flex>
    //               </Box>
    //               <Text
    //                 color={"#DD015B"}
    //                 fontSize={"12px"}
    //                 fontWeight={"500"}
    //                 cursor={"pointer"}
    //               >
    //                 {" "}
    //                 Undo{" "}
    //               </Text>
    //             </Flex>
    //           </Flex>

    //           <Box
    //             borderRadius={"6px"}
    //             background={"#F3FCFF"}
    //             padding={"8px 18px"}
    //           >
    //             <Text fontWeight={"500"} fontSize={"12px"} color={"#050505"}>
    //               {" "}
    //               <Text
    //                 fontWeight={"500"}
    //                 fontSize={"12px"}
    //                 color={"#246E96"}
    //                 display={"inline-block"}
    //               >
    //                 Note:
    //               </Text>{" "}
    //               Your default signature configured on your work email will be
    //               automatically inserted. Try 'Send test email' below to preview
    //               this email.{" "}
    //             </Text>
    //           </Box>
    //         </Flex>
    //         <Flex
    //           flexDirection={"row"}
    //           alignItems={"center"}
    //           justifyContent={"flex-start"}
    //           gridColumnGap={"16px"}
    //         >
    //           <Btn variant="secondary"> Send test email </Btn>
    //           <Btn> Send email </Btn>
    //         </Flex>
    //       </Flex>
    //     </Flex>
    //   </Box>
    // </Slate>
    <></>
  );
};
export default EmailEditor;
