import * as React from "react";

function AccordionArrowUp(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 10 7" fill="none" {...props}>
      <path
        d="M.6 4.4L4.067.933C4.2.8 4.344.7 4.5.633c.156-.066.322-.1.5-.1s.344.034.5.1c.156.067.3.167.433.3L9.4 4.4c.422.422.516.905.283 1.45-.233.544-.65.817-1.25.817H1.567c-.6 0-1.017-.273-1.25-.818C.084 5.305.177 4.822.6 4.4z"
        fill={props?.fill || "#0094E3"}
      />
    </svg>
  );
}

export default AccordionArrowUp;
