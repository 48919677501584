import React from 'react'

const WarningIcon = ({ width = 22, height = 22 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 22" fill="none">
            <path d="M11 0C17.0753 0 22 4.9258 22 11C22 17.0742 17.0753 22 11 22C4.9247 22 0 17.0742 0 11C0 4.9258 4.9247 0 11 0ZM11.0022 14.3044C10.855 14.2997 10.7084 14.3247 10.5711 14.3778C10.4338 14.4309 10.3085 14.5111 10.2028 14.6135C10.097 14.716 10.013 14.8386 9.95554 14.9742C9.89812 15.1098 9.86854 15.2555 9.86854 15.4028C9.86854 15.55 9.89812 15.6957 9.95554 15.8313C10.013 15.9669 10.097 16.0895 10.2028 16.192C10.3085 16.2944 10.4338 16.3746 10.5711 16.4277C10.7084 16.4808 10.855 16.5058 11.0022 16.5011C11.2875 16.4921 11.5581 16.3724 11.7567 16.1674C11.9553 15.9624 12.0663 15.6882 12.0663 15.4028C12.0663 15.1173 11.9553 14.8431 11.7567 14.6381C11.5581 14.4331 11.2875 14.3134 11.0022 14.3044ZM11 5.5C10.7304 5.50007 10.4702 5.59914 10.2689 5.7784C10.0675 5.95766 9.93897 6.20463 9.9077 6.4724L9.9 6.6L9.9022 12.1011L9.9099 12.2298C9.9417 12.4972 10.0705 12.7436 10.2718 12.9223C10.4731 13.1011 10.733 13.1999 11.0022 13.1999C11.2714 13.1999 11.5313 13.1011 11.7326 12.9223C11.9339 12.7436 12.0627 12.4972 12.0945 12.2298L12.1022 12.1011L12.1 6.6L12.0923 6.4713C12.0608 6.20372 11.9321 5.95702 11.7308 5.77799C11.5295 5.59895 11.2694 5.50004 11 5.5Z" fill="#F7A300" />
        </svg>
    )
}

export default WarningIcon