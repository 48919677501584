import * as React from "react";

function BackArrowIcon(props) {
  return (
    <svg width="32px" height="32px" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 16a1 1 0 00-1-1H5.414l6.294-6.292a1 1 0 10-1.416-1.416l-8 8a1 1 0 000 1.416l8 8a1 1 0 001.416-1.416L5.414 17H29a1 1 0 001-1z"
        fill="#050505"
      />
    </svg>
  );
}

export default BackArrowIcon;
