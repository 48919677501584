import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { fontSize, margin } from 'styled-system';

function Chart({ memberData, accountData, dateRange }) {
    const chartRef = useRef(null);

    useEffect(() => {
        if (memberData.length === 0 || Object.keys(accountData).length === 0 || dateRange.length === 0) return;

        const initChart = (chartRef, dateRange, data) => {
            const chartInstance = echarts.init(chartRef.current);
            const option = {
                title: {
                    text: 'Statistics Overview',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: ['Total Prospects', 'Total Accounts', 'Total Emails', 'Total Call Prep']
                },
                grid: {
                    left: 60,
                    right: 60,
                    top: 70,
                    bottom: 50
                },
                xAxis: {
                    type: 'category',
                    data: dateRange.map(item => item.dateStringUS),
                    axisLabel: {
                        rotate: 35,
                        interval: 0,
                    }
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: 'Total Prospects',
                        // label: { show: true},
                        type: 'bar',
                        stack: 'total',
                        barWidth: '60%',
                        data: data.totalProspects
                    },
                    {
                        name: 'Total Accounts',
                        // label: { show: true },
                        type: 'bar',
                        stack: 'total',
                        barWidth: '60%',
                        data: data.totalAccounts
                    },
                    {
                        name: 'Total Emails',
                        // label: { show: true },
                        type: 'bar',
                        stack: 'total',
                        barWidth: '60%',
                        data: data.totalEmails
                    },
                    {
                        name: 'Total Call Prep',
                        // label: { show: true },
                        type: 'bar',
                        stack: 'total',
                        barWidth: '60%',
                        data: data.totalCallPrep
                    }
                ]
            };
            chartInstance.setOption(option);
        };

        const stats = dateRange.map(date => {
            const totalProspects = accountData.totalProspect.dateWiseCount.find(item => item.date === date.date)?.count || 0;
            const totalAccounts = accountData.totalAccount.dateWiseCount.find(item => item.date === date.date)?.count || 0;
            const totalEmails = accountData.totalEmail.dateWiseCount.find(item => item.date === date.date)?.count || 0;
            const totalCallPrep = accountData.totalCallPrep.dateWiseCount.find(item => item.date === date.date)?.count || 0;

            return {
                dateString: date.dateStringUS,
                totalProspects,
                totalAccounts,
                totalEmails,
                totalCallPrep
            };
        });

        const data = {
            totalProspects: stats.map(item => item.totalProspects),
            totalAccounts: stats.map(item => item.totalAccounts),
            totalEmails: stats.map(item => item.totalEmails),
            totalCallPrep: stats.map(item => item.totalCallPrep)
        };

        initChart(chartRef, dateRange, data);
    }, [memberData, accountData, dateRange]);

    return (
        <div style={{ borderRadius: "10px", overflow: "none" }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <div style={{ background: '#5470c6', color: '#fff', padding: '20px', borderRadius: '10px', flex: '1', margin: '0 10px' }}>
                    <div style={{ fontSize: '24px', fontWeight: 'bold' }}>{accountData?.totalProspect?.totalStakeholderCount || "0"}</div>
                    <div>Total Prospects</div>
                </div>
                <div style={{ background: '#92cc76', color: '#fff', padding: '20px', borderRadius: '10px', flex: '1', margin: '0 10px' }}>
                    <div style={{ fontSize: '24px', fontWeight: 'bold' }}>{accountData?.totalAccount?.totalAccountCount || "0"}</div>
                    <div>Total Accounts</div>
                </div>
                <div style={{ background: '#fac858', color: '#fff', padding: '20px', borderRadius: '10px', flex: '1', margin: '0 10px' }}>
                    <div style={{ fontSize: '24px', fontWeight: 'bold' }}>{accountData?.totalEmail?.totalEmailCount || "0"}</div>
                    <div>Total Emails</div>
                </div>
                <div style={{ background: '#ee6767', color: '#fff', padding: '20px', borderRadius: '10px', flex: '1', margin: '0 10px' }}>
                    <div style={{ fontSize: '24px', fontWeight: 'bold' }}>{accountData?.totalCallPrep?.totalCallPrepCount || "0"}</div>
                    <div>Total Call Prep</div>
                </div>
            </div>
            <div ref={chartRef} style={{ borderRadius:"10px",  height: '600px', marginTop: '50px', backgroundColor: "#fff", padding: "40px 10px 40px 10px" }}></div>
        </div>
    );
}

export default Chart;
