import { BoxProps } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import AdminSideFieldIcon from "assets/icons/AdminSideFieldIcon";
import AdminSidePlanIcon from "assets/icons/AdminSidePlanIcon";
import AdminSideTeamIcon from "assets/icons/AdminSideTeamIcon";
import AeSideAnalyticsIcon from "assets/icons/AeSideAnalyticsIcon";
import AeSideEmailIcon from "assets/icons/AeSideEmailIcon";
import AeSideHelpIcon from "assets/icons/AeSideHelpIcon";
import AeSideHistoryIcon from "assets/icons/AeSideHistoryIcon";
import AeSideHomeIcon from "assets/icons/AeSideHomeIcon";
import AeSideOppoIcon from "assets/icons/AeSideOppoIcon";
import AeSideStakeIcon from "assets/icons/AeSideStakeIcon";
import TagIcon from "assets/icons/Tag";
import IntegrationIcon from "assets/icons/Integration";
import AnalyticsIcon from "assets/icons/AnalyticsIcon";

import { FC, useMemo } from "react";
import TeamManagementIcon from "assets/icons/TeamManagementIcon";
import ProspectReportIcon from "assets/icons/ProspectReportIcon";
import MemberListingIcon from "assets/icons/MemberListingIcon";
import ExcelUploadIcon from "assets/icons/ExcelUploadIcon";
import CompanyScoreIcon from "assets/icons/CompanyScoreIcon";
import EmailTemplateIcon from "assets/icons/EmailTemplateIcon";
import FrameworkAndContextIcon from "assets/icons/FrameworkAndContextIcon";

export const icons = {
  aeSideHomeIcon: AeSideHomeIcon,
  aeSideOppoIcon: AeSideOppoIcon,
  aeSideStakeIcon: AeSideStakeIcon,
  aeSideEmailIcon: AeSideEmailIcon,
  aeSideHistoryIcon: AeSideHistoryIcon,
  aeSideAnalyticsIcon: AeSideAnalyticsIcon,
  aeSideHelpIcon: AeSideHelpIcon,
  adminSideFieldIcon: AdminSideFieldIcon,
  adminSideTeamIcon: AdminSideTeamIcon,
  adminSidePlanIcon: AdminSidePlanIcon,
  integrationIcon: IntegrationIcon,
  tagIcon:TagIcon,
  analyticsIcon: AnalyticsIcon,
  teammanagementIcon: TeamManagementIcon,
  prospectreportIcon: ProspectReportIcon,
  memberlistingIcon: MemberListingIcon,
  exceluploadIcon: ExcelUploadIcon,
  companyscoreIcon: CompanyScoreIcon,
  emailtemplateIcon: EmailTemplateIcon,
  frameworkAndContextIcon: FrameworkAndContextIcon,
};

export interface AeSidebarIconProps extends BoxProps {
  name: keyof typeof icons;
  fill?: string;
  stroke?: string;
  onClick?: () => void;
  style?: any;
  height?: string;
  width?: string;
  size?: string;
}

export const AeSidebarIcon: FC<AeSidebarIconProps> = ({
  size,
  name,
  height,
  width,
  style,
  fill,
  stroke,
  onClick,
}: AeSidebarIconProps) => {
  const IconSVG: any = useMemo(() => icons[name], [name]) as any;

  return (
    <Grid height={height} width={width} onClick={onClick}>
      <IconSVG
        key={name}
        opacity="1"
        style={style}
        fill={fill}
        stroke={stroke}
        size={size}
      />
    </Grid>
  );
};

export type AeSidebarIconName = keyof typeof icons;
