import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { AeSidebarIcon, AeSidebarIconName } from "./AeSidebarIcon";

export interface AeSidebarTagProps {
  active?: boolean;
  text: string;
  iconName: string;
  onTagClick?: () => void;
}

export const AeSidebarTag: React.FC<AeSidebarTagProps> = ({
  active,
  text,
  iconName,
  onTagClick,
}) => {
  return (
    <Flex alignItems={"center"} cursor="pointer" onClick={onTagClick}>
      <Flex
        width="32px"
        height={"32px"}
        borderRadius="10px"
        background={active ? "#DD015B" : "none"}
        alignItems="center"
        justifyContent={"center"}
      >
        <AeSidebarIcon name={iconName as AeSidebarIconName} />
      </Flex>
      {/* <Text ml="10px" fontSize={"14px"} fontWeight={400}>
        {text}
      </Text> */}
      <div  style={{fontSize:"14px", fontWeight:400, marginLeft:"10px" }} id={text}>{text}</div>
    </Flex>
  );
};
