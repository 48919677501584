import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { useState } from "react";
import { useAppSelector } from "hooks";

const StatusValues = ["All", "Processed", "Error", "Queued"];

function ProspectReportTableHeader({ filterDataFunc }) {

  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchOwnerName, setSearchOwnerName] = useState("");
  const [filterStatus, setFilterStatus] = useState("All");

  const allReportData = useAppSelector(
    (state) => state.prospectReportState.prospectReportData
  );

  const profileData = useAppSelector(
    (state) => state.profileState.profileData
  );

  const isSuperAdminView = localStorage.getItem("superadminview");

  // useEffect(() => {

  //   let filteredData = allReportData;

  //   if (filterStatus !== "All") {
  //     filteredData = filteredData.filter(item => item.status.toLowerCase() === filterStatus.toLowerCase());
  //   }

  //   if (searchName.trim() !== "") {
  //     const searchTermLowerCase = searchName.toLowerCase().trim();
  //     filteredData = filteredData.filter(item =>
  //       item?.name?.toLowerCase().includes(searchTermLowerCase)
  //     );
  //   }

  //   if (searchEmail.trim() !== "") {
  //     const searchTermLowerCase = searchEmail.toLowerCase().trim();
  //     filteredData = filteredData.filter(item =>
  //       item?.email?.toLowerCase().includes(searchTermLowerCase)
  //     );
  //   }

  //   if (searchOwnerName.trim() !== "") {
  //     const searchTermLowerCase = searchOwnerName.toLowerCase().trim();
  //     filteredData = filteredData.filter(item =>
  //       item?.ownerName?.toLowerCase().includes(searchTermLowerCase)
  //     );
  //   }

  //   filterDataFunc(filteredData)

  // }, [searchName, searchEmail, searchOwnerName, filterStatus])

  return (
    <>
      <Flex
        padding={"10px 15px"}
        fontSize="12px"
        fontWeight={500}
        position="sticky"
        backgroundColor={"#F8F8F8"}
        alignItems={"center"}
        borderTopLeftRadius={"8px"}
        borderTopRightRadius={"8px"}
        className="table-data-tm"
      >
        <Flex padding={"0 5px"} width="5%" alignItems={"center"} >
          #
        </Flex>
        <Flex padding={"0 5px"} width="20%" alignItems={"center"}>
          Prospect
        </Flex>
        <Flex width={"5%"}></Flex>
        <Flex padding={"0 5px"} width="15%" alignItems={"center"}>
          Rep Name
        </Flex>
        {
          (profileData.role === "superadmin" && isSuperAdminView) && (
            <>
              <Flex padding={"0 5px"} width="10%" alignItems={"center"}>
                Insight Status
              </Flex>
            </>
          )
        }
        {
          (profileData.role !== "superadmin" || (profileData.role === "superadmin" && !isSuperAdminView)) && (
            <>
              <Flex padding={"0 5px"} width="35%" alignItems={"center"} justifyContent={"center"}>
                Date
              </Flex>
            </>
          )
        }
        <Flex width={"5%"}></Flex>
        {profileData.isExaSummary && <Flex padding={"0 5px"} width="15%" alignItems={"center"} justifyContent={"center"}>
          Summary
        </Flex>}

        <Flex padding={"0 5px"} width="10%" alignItems={"center"} justifyContent={"center"}>
          Status
        </Flex>
        <Flex padding={"0 5px"} width="10%" alignItems={"center"} justifyContent={"center"}>
          Actions
        </Flex>


      </Flex>
      {/* <Flex 
      padding={"10px 15px"}
      fontSize="12px"
      fontWeight={500}
      position="sticky"
      backgroundColor={"#F8F8F8"}
      alignItems={"center"}
    >
      <Flex padding={"0 5px"} width="2%" alignItems={"center"}>
        
      </Flex>
      <Flex padding={"0 5px"} width="15%" alignItems={"center"}>
        <TextField
            placeholder=""
            variant="outlined"
            style={{ width: "100%" }}
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            InputProps={{
              style: {
                justifyContent: "space-between",
                alignItems: "center",
                padding: "5px",
                background: "#FFFFFF",
                borderRadius: "6px",
                height: "43px",
                position: "relative",
                color: "#ffffff",
              }
            }}
          />
      </Flex>
      <Flex padding={"0 5px"} width="20%" alignItems={"center"}>
        <TextField
            placeholder=""
            variant="outlined"
            style={{ width: "100%" }}
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
            InputProps={{
              style: {
                justifyContent: "space-between",
                alignItems: "center",
                padding: "5px",
                background: "#FFFFFF",
                borderRadius: "6px",
                height: "43px",
                position: "relative",
                color: "#ffffff",
              }
            }}
          />
      </Flex>
      <Flex padding={"0 5px"} width="10%" alignItems={"center"}>
        
      </Flex>
      <Flex padding={"0 5px"} width="15%" alignItems={"center"}>
        <TextField
            placeholder=""
            variant="outlined"
            style={{ width: "100%" }}
            value={searchOwnerName}
            onChange={(e) => setSearchOwnerName(e.target.value)}
            InputProps={{
              style: {
                justifyContent: "space-between",
                alignItems: "center",
                padding: "5px",
                background: "#FFFFFF",
                borderRadius: "6px",
                height: "43px",
                position: "relative",
                color: "#ffffff",
              }
            }}
          />
      </Flex>
      <Flex padding={"0 5px"} width="10%" alignItems={"center"}>
        
      </Flex>
      <Flex padding={"0 5px"} width="10%" alignItems={"center"}>
        
      </Flex>
      <Flex padding={"0 5px"} width="10%" alignItems={"center"}>
        <Select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            variant="outlined"
            size="medium"
            fullWidth
            style={{
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                background: "#FFFFFF",
                borderRadius: "6px",
                height: "43px",
                position: "relative",
                color: "#ffffff",
            }}

        >
            {StatusValues.map((status) => (
                <MenuItem key={status} value={status}>
                    Status:&nbsp;{status}
                </MenuItem>
            ))}
        </Select>
      </Flex>


    </Flex> */}
    </>
  );
}

export default ProspectReportTableHeader;
