import { useState, useEffect, useCallback } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import AeReturnStakeTable from "./AeReturnStakeTable/AeReturnStakeTable";
import { axiosHelper } from "utils";
import SortByArrow from "./AeReturnStakeTable/SortByArrow";
import SortByDropArrow from "./AeReturnStakeTable/SortByDropArrow";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { ToggleButton } from "@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton";
import { useNavigate } from "react-router";
import {
  setStakeholdersData,
  setStakeholdersLoadingData,
  setStakeholderDataChange,
  setstakeholderpageNo,
} from "store/globalStateReducer";
import { useDispatch } from "react-redux";
import { useAppSelector } from "hooks";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { AeReturnStakeModal } from "./AeReturnStakeTable/AeReturnStakeModal";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import AeReturnMobileViewRow from "./AeReturnMobileViewRow";
import {
  checkUnauthorized,
  compare,
  recencySort,
  reverseCompare,
} from "utils/constants";
interface AeReturnStakeRightSectionMobileProps {
  setOpenSearchModal: (e: any) => void;
  setSearchQuery: (e: any) => void;
  searchValues?: any;
}
const ListOpportunityValidationSchema = yup.object().shape({});
const AeReturnStakeRightSectionMobileView : React.FC<AeReturnStakeRightSectionMobileProps> = ({
  searchValues,
  setOpenSearchModal,
  setSearchQuery,
}) => {
  const stakeholders = useAppSelector(
    (state) => state.globalState.stakeholders
  );
  const stakeholderLoading = useAppSelector(
    (state) => state.globalState.stakeholderLoading
  );
  const stakeholderDataChange = useAppSelector(
    (state) => state.globalState.stakeholderDataChange
  );
  const stakeholderSnackbar = useAppSelector(
    (state) => state.globalState.stakeholderSnackbar
  );
  const [openSortModal, setOpenStartModal] = useState(false);
  const [openStakeFilterModal, setOpenStakeFilterModal] = useState(false);
  const [sortValue, setSortValue] = useState("a-z");
  const [stakeFilterValue, setStakeFilterValue] = useState("All");
  const [allStakeholders, setAllStakeholders] = useState(stakeholders);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const journeyData = useAppSelector((state) => state.globalState.journey);
  const profileData = useAppSelector((state) => state.profileState.profileData);
  const profileName = `${profileData.firstName} ${profileData.lastName}`;
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const fetchDataStakeholders = async () => {
    try {
      const result = await axiosHelper({
        url: `salesforce/getAllStakeholdersForAE`,
      });
      setAllStakeholders([...result.data?.allData].sort(compare));
      setIsAdmin(result?.data?.isAdmin);
      //@ts-ignore
      dispatch(setStakeholdersData([...result.data?.allData]));
      //@ts-ignore
      dispatch(setStakeholdersLoadingData(false));
    } catch (err) {
      console.log(err);
      checkUnauthorized(err, journeyData, navigate);
    }
  };

  useEffect(() => {
    if (
      [...new Set(stakeholders?.map((stake) => stake?.memberName))]?.length > 1
    ) {
      setStakeFilterValue("All");
    } else {
      setStakeFilterValue("Yourself");
    }
  }, [stakeholders]);

  useEffect(() => {
    fetchDataStakeholders();
  }, []);

  const calcStakeData = useCallback(
    (sortValue) => {
      //@ts-ignore
      // dispatch(setStakeholdersLoadingData(true));
      setLoading(true);
      if (sortValue === "z-a") {
        allStakeholders.sort(reverseCompare);
      } else if (sortValue === "a-z") {
        allStakeholders.sort(compare);
      } else {
        allStakeholders?.sort(recencySort);
      }

      return setTimeout(() => {
        setLoading(false);
      }, 1000);
    },
    [allStakeholders]
  );

  const filterData = useCallback(
    (filterValue) => {
      setLoading(true);
      if (filterValue === "All") {
        setAllStakeholders(
          [...stakeholders].sort(
            sortValue === "a-z"
              ? compare
              : sortValue === "z-a"
              ? reverseCompare
              : recencySort
          )
        );
        setSearchQuery(null);
        //@ts-ignore
        dispatch(setstakeholderpageNo(0));
      } else {
        if (searchValues && searchValues.length > 0) {
          setAllStakeholders(
            [
              ...stakeholders
                ?.filter((stake) => {
                  return (
                    `${stake?.identity?.firstname} ${stake?.identity?.lastname}` ===
                      searchValues || stake?.identity?.email === searchValues
                  );
                })
                .filter((stake) => stake.memberName === filterValue),
            ].sort(
              sortValue === "a-z"
                ? compare
                : sortValue === "z-a"
                ? reverseCompare
                : recencySort
            )
          );
        } else {
          setAllStakeholders(
            [
              ...stakeholders.filter(
                (stake) => stake.memberName === filterValue
              ),
            ].sort(
              sortValue === "a-z"
                ? compare
                : sortValue === "z-a"
                ? reverseCompare
                : recencySort
            )
          );
          //@ts-ignore
          dispatch(setstakeholderpageNo(0));
        }
      }
      return setTimeout(() => {
        setLoading(false);
      }, 1000);
    },
    [allStakeholders, searchValues, stakeholders]
  );

  useEffect(() => {
    if (searchValues && searchValues.length > 0) {
      console.log("ideally should apply here--", searchValues);
      //@ts-ignore
      dispatch(setStakeholdersLoadingData(true));
      setAllStakeholders(
        [
          ...stakeholders?.filter((stake) => {
            return (
              `${stake?.identity?.firstname} ${stake?.identity?.lastname}` ===
                searchValues || stake?.identity?.email === searchValues
            );
          }),
        ].sort(
          sortValue === "a-z"
            ? compare
            : sortValue === "z-a"
            ? reverseCompare
            : recencySort
        )
      );
      setStakeFilterValue("All");
      setSortValue(sortValue);
      //@ts-ignore
      dispatch(setstakeholderpageNo(0));
      setTimeout(() => {
        //@ts-ignore
        dispatch(setStakeholdersLoadingData(false));
      }, 1000);
    } else {
      setAllStakeholders(
        [...stakeholders].sort(
          sortValue === "a-z"
            ? compare
            : sortValue === "z-a"
            ? reverseCompare
            : recencySort
        )
      );
      setStakeFilterValue("All");
      setSortValue(sortValue);
    }
  }, [searchValues]);

  useEffect(() => {
    if (stakeholderDataChange) {
      setAllStakeholders(
        [...stakeholders].sort(
          sortValue === "a-z"
            ? compare
            : sortValue === "z-a"
            ? reverseCompare
            : recencySort
        )
      );
      setStakeFilterValue(stakeFilterValue);
      setSortValue(sortValue);
      //@ts-ignore
      dispatch(setStakeholderDataChange(false));
    }
  }, [dispatch, sortValue, stakeholderDataChange]);
   return (
    <>
    <div style={{ position:  "fixed" , display: openSortModal ? "block" : "none", top:  0 , left:  0 , backgroundColor:  "rgba(0,0,0,0.8)" , zIndex:  1 , height : "100vh" , width : "100vw" }} >
    
    </div>
     <div style={{overflowY: 'scroll' , paddingTop:  "16px" , paddingBottom:"32px", boxSizing:  "border-box" , position : "relative" }}  >
      {stakeholderLoading || loading ? (
        <Icon name="loadingIcon" />
      ) : (
        <Flex flexDirection={"column"}  gridRowGap={"8px"} marginLeft={"16px"} marginRight={"16px"} >
            <Flex
              alignItems={"center"}
              fontSize={"14px"}
              fontWeight={400}
              color="#050505"
            >
              Stakeholders{" "}
              <Text
                ml="16px"
                as="span"
                color="#050505"
                fontSize={"12px"}
                fontWeight={400}
                backgroundColor={"#fff"}
                borderRadius={"8px"}
                p="4px 8px"
              >
                {allStakeholders?.length}
              </Text>
            </Flex>
            <Box borderRadius={"8px"} background={"#FFFFFF"}  width={"100%"} >
              { allStakeholders.length > 0 && allStakeholders.map( (user , index) => {
                 
                return <AeReturnMobileViewRow user={user} index={index} />
              }  ) }


            </Box>
            
        </Flex>    
      )}
      <div style={{ position : "fixed" , bottom : 0 , right : 0 , height: "auto", width:"100%", zIndex:2 , background :"#fff" , padding : "15px" , boxSizing:  "border-box" , borderRadius : "12px 12px 0px 0px" }} >
       <Flex width={"100%"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} gridRowGap={"26px"} >
       
            <Flex
            // width={"100%"}
            alignItems={"center"}
            cursor="pointer"
            onClick={() => setOpenStartModal(!openSortModal)}
            
          >
            <Text color={"#050505"} fontWeight={"400"} fontSize={"14px"} >
              Sort By:{" "}
              {sortValue === "a-z"
                ? "A-Z"
                : sortValue === "z-a"
                ? "Z-A"
                : sortValue === "recency" ? "Recency" : "All" }
            </Text>

           { !openSortModal ? <SortByArrow /> : <SortByDropArrow /> }
         
          </Flex>
          { openSortModal && (
            <Flex flexDirection={"column"}  gridRowGap={"16px"} >
                <Flex
                  cursor="pointer"
                      onClick={() => {
                        setSortValue("recency");
                        setOpenStartModal(false);
                        calcStakeData("recency");
                      }}
                    >
                      Recency
                    </Flex>
                    <Flex
                    cursor="pointer"
                      onClick={() => {
                        setSortValue("a-z");
                        setOpenStartModal(false);
                        calcStakeData("a-z");
                      }}
                    >
                      Name: A-Z
                    </Flex>
                    <Flex
                    cursor="pointer"
                      onClick={() => {
                        setSortValue("z-a");
                        setOpenStartModal(false);
                        calcStakeData("z-a");
                      }}
                    >
                      Name: Z-A
                    </Flex>
                </Flex>
           ) } 
       
       </Flex>
      </div>
    </div>
    </>
  );
};

export default AeReturnStakeRightSectionMobileView;