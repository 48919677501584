import { Avatar } from "@evabot/eva-react-core-library/dist/atoms/Avatar/Avatar";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import DeliverIcon from "./DeliverIcon";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useCallback } from "react";
import { FormikTextArea } from "@evabot/eva-react-core-library/dist/atoms/FormikTextArea/FormikTextArea";
import { SelectIconComponent } from "@evabot/eva-react-core-library/dist/atoms/SelectIconComponent/SelectIconComponent";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { icons } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { setBirthdayNudge } from "../store/aeDashboardReducer";
import { useDispatch } from "react-redux";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";

export interface BirthdayNudgeProps {
  setShowBirthdaySuccessModal: (e: any) => void;
  setShowBirthdayModal: (e: any) => void;
}

const BirthdayNudgeSchema = yup.object().shape({
  category: yup.string().required("required.field"),
});

export interface IconOptionType {
  iconName: keyof typeof icons;
  iconValue: string;
  subText?: string;
}

// please dont change the iconName here, as they are used as keys for validation in the backend.
const iconCategoryOptions: IconOptionType[] = [
  {
    iconName: "birthDayPresent",
    iconValue: "Birthday gift",
    subText: "(Upto $50)",
  },
  {
    iconName: "birthdayCake",
    iconValue: "Order a cake",
    subText: "(Upto $30)",
  },
  {
    iconName: "birthdayBouquet",
    iconValue: "Send flowers",
  },
];

const defaultValues = {
  category: JSON.stringify(iconCategoryOptions[0]),
  addInstruction: "",
  notes: "",
};

export const BirthdayNudge: React.FC<BirthdayNudgeProps> = ({
  setShowBirthdaySuccessModal,
  setShowBirthdayModal,
}) => {
  const dispatch = useDispatch();
  const onSubmitHandler = useCallback(
    async (values: any) => {
      const formData = {
        category: JSON?.parse(values?.category)["iconName"],
        addInstruction: values?.addInstruction,
        notes: values?.notes,
      };
      dispatch(setBirthdayNudge(formData));
      setShowBirthdayModal(false);
      setShowBirthdaySuccessModal(true);
    },
    [dispatch, setShowBirthdayModal, setShowBirthdaySuccessModal]
  );

  return (
    <Grid>
      <Formik
        initialValues={defaultValues}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validationSchema={BirthdayNudgeSchema}
      >
        {({ dirty, isValid, submitForm, values, errors }) => {
          return (
            <Form>
              <Grid my="24px" ml="42px">
                <Text variant="title" color={"#050505"} fontWeight={300}>
                  {`Send ${
                    JSON?.parse(values?.category)["iconValue"] ===
                    "Birthday gift"
                      ? "a gift"
                      : JSON?.parse(values?.category)["iconValue"] ===
                        "Order a cake"
                      ? "a cake"
                      : "flowers"
                  } on Lincoln’s birthday`}
                </Text>
                <Flex alignItems={"center"}>
                  <DeliverIcon />
                  <Text
                    mt="3px"
                    ml="10px"
                    color="#57585C"
                    variant="inputHint"
                    fontWeight={300}
                  >
                    Delivered within 12 days
                  </Text>
                </Flex>
              </Grid>
              <Flex border="1px solid rgba(0, 0, 0, 0.1)" />
              <Grid margin={"42px 61px 42px 42px"}>
                <Flex
                  background={"#EAF5FF"}
                  borderRadius="12px"
                  padding={"8px 20px"}
                  alignItems="center"
                >
                  <Avatar
                    width="32px"
                    height="32px"
                    borderRadius="12px"
                    imageUrl={
                      "https://www.newsbtc.com/wp-content/uploads/2020/06/mesut-kaya-LcCdl__-kO0-unsplash-scaled.jpg"
                    }
                  />
                  <Text color={"#050505"} variant="inputHint" ml="12px">
                    <b>Smart!</b> Gifts leave a long lasting impression. I'll
                    get this personalized gift delivered once you schedule it.
                  </Text>
                </Flex>

                <Grid my="42px">
                  <Box width={"250px"}>
                    <SelectIconComponent
                      name="category"
                      iconOptions={iconCategoryOptions}
                    />
                  </Box>
                  <Text
                    color="#050505"
                    fontSize={"11px"}
                    fontWeight={300}
                    mt="-14px"
                  >
                    These items will be delivered as per the approved budget by
                    your admin.
                  </Text>
                  <Text
                    mt="26px"
                    mb="6px"
                    color="#000000"
                    fontSize={"14px"}
                    fontWeight={500}
                  >
                    Any specific instructions you’d like to share with me?
                    <Text
                      color="#57585C"
                      as="span"
                      fontSize={"13px"}
                      fontWeight={400}
                      ml="3px"
                    >
                      (optional)
                    </Text>
                  </Text>
                  <FormikInput
                    width="480px"
                    name="addInstruction"
                    placeholder="e.g. add a cookie with the coffee"
                  />
                  <Text
                    color="#000000"
                    fontSize={"14px"}
                    fontWeight={500}
                    mb="6px"
                  >
                    Add a personalized note{" "}
                    <Text
                      color="#57585C"
                      fontSize={"13px"}
                      fontWeight={400}
                      as="span"
                      ml="3px"
                    >
                      (optional)
                    </Text>
                  </Text>
                  <FormikTextArea
                    width="480px"
                    name="notes"
                    placeholder="e.g. Following up on my promise to treat you for all the support you've extended through this closure process."
                    height={"50px"}
                  />{" "}
                  <Box mt="24px">
                    <Button
                      padding="12px 32px"
                      borderRadius={"12px"}
                      onClick={submitForm}
                      disabled={!isValid}
                    >
                      Schedule a gift now
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};
