import * as React from "react";

function HideIcon(props) {
  return (
    <svg
      width={props?.size || "16px"}
      height={props?.size || "16px"}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M8 6.41c.047-.003.093-.003.14 0l.09-.75a2.342 2.342 0 00-2.558 2.573l.75-.096a1.232 1.232 0 010-.137A1.59 1.59 0 018 6.41z"
        fill="#57585C"
      />
      <path
        d="M3.857 10.297A13.786 13.786 0 011.478 8C2.172 7.145 4.867 4.107 8 4.107c.673.008 1.34.135 1.967.378l.263-.703A6.355 6.355 0 008 3.357c-4 0-7.17 4.25-7.302 4.42L.535 8l.163.222a14.311 14.311 0 002.71 2.676l.45-.6zM15.303 7.777a14.308 14.308 0 00-2.71-2.675 11.664 11.664 0 00-.693-.472l1.365-1.365-.53-.53-10 10 .53.53 1.5-1.5c.324.173.658.326 1 .457.714.273 1.47.415 2.235.42 4 0 7.17-4.25 7.303-4.42L15.465 8l-.162-.223zm-5.72.085c.002.046.002.092 0 .138A1.59 1.59 0 018 9.59h-.15a1.618 1.618 0 01-.675-.228l2.18-2.177c.126.206.203.437.228.677zM8 11.892a5.658 5.658 0 01-1.965-.374 8.305 8.305 0 01-.71-.313l1.308-1.308c.333.243.726.391 1.137.43.077.005.153.005.23 0A2.343 2.343 0 0010.34 8a1.436 1.436 0 000-.218 2.325 2.325 0 00-.43-1.15l1.457-1.457c.268.165.533.337.788.527.878.669 1.673 1.44 2.368 2.298-.695.855-3.39 3.893-6.523 3.893z"
        fill="#57585C"
      />
    </svg>
  );
}

export default HideIcon;
