import { useEffect, useState } from "react";
import StakeholderTagAddTag from "./StakeholderTagAddTag";
import StakeholderTagTable from "./StakeholderTagTable";
import stakeHolderTagService from "services/stakeholderTagService";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { useAppSelector } from "hooks";
import { setStakeholderTagData, setStakeholderTagDataLoading } from "./store/stakeholdertagReducer";
import { useDispatch } from "react-redux";

function StakeHolderContent() {
    const [tagList, setTagList] = useState([]);
    const profileData = useAppSelector((state) => state.profileState.profileData);

    const allStakeholderTagData = useAppSelector(
        (state) => state.stakeholderTagState?.stakeholderTagData
    );
    const allStakeholderTagDataLoading = useAppSelector(
        (state) => state.stakeholderTagState?.loading
    );
    const stakeholderTagSnackbar = useAppSelector(
        (state) => state.stakeholderTagState?.stakeholderTagSnackbar
    );
    const dispatch = useDispatch();


    const saveNewTag = (tag) => {
        setTagList((prevList) => {
            return [...prevList, tag];
        });
    };

    useEffect(() => {
        if (profileData?.userId) {
            dispatch(setStakeholderTagDataLoading(true));
            const obj = { userId: profileData.userId, organizationId: profileData.organizationId, userType: profileData.role }
            stakeHolderTagService.getTagList(obj).then((apiResponse) => {
                if (apiResponse?.tagList) {
                    dispatch(setStakeholderTagData(apiResponse?.tagList));
                }
            });
        }
    }, [profileData]);

    return (

        <Flex flexDirection={"column"} padding="25px 32px" overflowY={"scroll"}>
            <StakeholderTagAddTag addNewTagToList={saveNewTag} />
            <StakeholderTagTable />
        </Flex>
    );
}

export default StakeHolderContent;
