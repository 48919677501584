import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { useFormikContext } from 'formik';
import { ErrorMessage, Form, Formik } from "formik";
import { useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select, { StylesConfig } from 'react-select';
import stakeHolderTagService from "services/stakeholderTagService";
import userService from "services/userService";
import * as Yup from 'yup';
import { setStakeholderTagData } from "./store/stakeholdertagReducer";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Backdrop } from "@mui/material";


const initialValues = {
    customTag: '',
    selectedOption: '',
};

const validationSchema = Yup.object().shape({
    selectedOption: Yup.string().required('Please select a user'),
    customTag: Yup.string().required('Please enter a custom tag'),
});



function StakeholderTagAddTag(data) {
    const [selectedUser, setSelectedUser] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const profileData = useAppSelector((state) => state.profileState.profileData);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);


    const dispatch = useDispatch();

    const allStakeholderTagData = useAppSelector(
        (state) => state.stakeholderTagState?.stakeholderTagData
    );


    useEffect(() => {
        if (profileData?.userId) {
            try {
                userService.getUserList(profileData.refreshTokenType, profileData.userId).then((userList) => {
                    if (userList) {
                        const userOptions = userList.map((user) => ({
                            value: user.id,
                            label: user.name,
                            guid: user.guid,
                            email: user.email,
                        }));

                        setOptions(userOptions);
                        const currentUserOutreach = userOptions.find(u => u.email === profileData.email);
                        setSelectedUser(currentUserOutreach);

                        initialValues.selectedOption = currentUserOutreach?.value;
                    }
                    setLoading(false);
                });
            } catch (err) {
                setLoading(false);
            }

        }
    }, [profileData]);

    const customStyles: StylesConfig = {
        control: (provided: Record<string, unknown>, state: any) => ({
            ...provided,
            border: !selectedUser && formSubmitted ? '1px solid red' : '',
        }),
    };


    const handleSubmit = async (values, { resetForm }) => {
        const payload = {
            userId: profileData.userId,
            tag: values.customTag,
            ownerId: selectedUser.value,
            ownerName: selectedUser.label,
            ownerGuid: selectedUser.guid,
            ownerEmail: selectedUser.email,
            status: "queued",
            type: profileData.refreshTokenType
        };

        try {
            const response = await stakeHolderTagService.saveTag(payload);
            let stakeholderTagData = [response.data, ...allStakeholderTagData];
            dispatch(setStakeholderTagData(stakeholderTagData));
            resetForm();
            profileData?.role === "adimin" && setSelectedUser(null);
            setFormSubmitted(false);
        } catch (error) {

        }
    };


    return (
        <Box paddingTop={"35px"} paddingLeft={"20px"}>
            {loading && <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                open={true}
            >
                <Icon name="loadingIcon" />
            </Backdrop>}
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, values }) => (
                    <Form>
                        <Flex flexDirection="row" gap="20px">
                            <Box marginBottom={"8px"}>
                                <FormikInput
                                    placeholder="Enter a custom tag"
                                    name="customTag"
                                    id="customTag"
                                />
                            </Box>
                            {profileData?.role === "admin" && <Box marginBottom={"8px"}>
                                <Select
                                    className="single-select"
                                    placeholder="Select user"
                                    styles={customStyles}
                                    name="selectedOption"
                                    options={options}
                                    value={selectedUser}

                                    onChange={(selectedOption) => {
                                        setFieldValue('selectedOption', selectedOption ? selectedOption.value : null);
                                        setSelectedUser(selectedOption);
                                    }}
                                    isSearchable
                                />
                                <ErrorMessage
                                    name="selectedOption"
                                    component="div"
                                    className="custom-error-message"
                                />
                            </Box>}
                            <Button onClick={() => {
                                setFormSubmitted(true)
                            }} type="submit">Save</Button>
                        </Flex>
                    </Form>
                )}
            </Formik>

        </Box>
    );
}

export default StakeholderTagAddTag;
