import React, { useState } from 'react';
import {  Form, Formik } from 'formik';
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Flex } from '@evabot/eva-react-core-library/dist/atoms/Flex/Flex';
import { Button } from '@evabot/eva-react-core-library/dist/atoms/Button/Button';
import { ToggleButton } from '@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton';
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Tag } from '@evabot/eva-react-core-library/dist/molecules/Tag/Tag';
import userService from 'services/userService';
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';
import * as yup from 'yup';
import { ModalWrapper } from '@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper';
import Select from 'react-select';
import { useAppSelector } from 'hooks';
import NameFirstAlphabetBox from './NameFirstAlphabetBox';
import './KebabMenu.css';

const AddMemberSchema = yup.object().shape({
    firstname: yup.string().required("First Name is required."),
    email: yup
        .string()
        .trim()
        .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, "Invalid email address.")
        .required("Email is required."),
});

const usertypeOptions = [
    { value: "admin", label: "Admin" },
    { value: "sdr", label: "SDR" },
    { value: "ae", label: "AE" }
];

function TeamManagementTableBodyField({ UserData, index, reFetch }) {


    const [hideModal, setHideModal] = useState(true);
    const [isActive, setIsActive] = useState(UserData?.isActive);
    const [usertype, setUserType] = useState(UserData?.userType);
    const [showSnackBarModal, setShowSnackBarModal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const [hideMemberActivateConfirmationBox, setHideMemberActivateConfirmationBox] = useState(true);

    const teamManagementData = useAppSelector(
        (state) => state.memberListingState.memberListingData
    );

    const loggedInUserId = useAppSelector(
        (state) => state.profileState.profileData.userId
    );


    const openEditFormpopup = () => {
        setHideModal(false);
    }

    const resendInvitation = async () => {
        await userService.resendInvitation(UserData);
        setSnackbarMessage('Invitation sent successfully.');
        setSnackbarType('success');
        setShowSnackBar(true);
    }

    const handleActivation = async (value: any, userId: any) => {

        setIsActive(value);
        const dataToUpdate = { "userId": userId, "key": "activated", "value": value };

        try {
            const updateActivationStatus = await userService.updateActivationStatus(dataToUpdate);
            if (updateActivationStatus?.success) {
                reFetch();
                setSnackbarMessage('Member Activation been updated successfully.');
                setShowSnackBar(true);
                setSnackbarType('success');
                setHideModal(true);
                setHideMemberActivateConfirmationBox(true);
            }
        } catch (error) {
            console.error("Error updating member", error);
        }
    }

    const handleUpdateUser = async (updatedUserData) => {

        const userId = updatedUserData.userId;

        if (!userId) {
            console.error("user id not found");
            return;
        }
        try {
            const updateMember = await userService.updateTeamMember(updatedUserData);
            if (updateMember?.success === true) {
                reFetch();
                setSnackbarMessage('Member has been updated successfully.');
                setShowSnackBar(true);
                setSnackbarType('success');
                setHideModal(true);

            } else {
                setSnackbarMessage('Error updating Member.');
                setShowSnackBar(true);
                setSnackbarType('error');
                setHideModal(true);
            }
        } catch (error) {
            console.error("Error updating member", error);
        }

    };

    return (
        <>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            <Flex
                padding={"15px"}
                fontSize="12px"
                fontWeight={500}
                position="sticky"
                // backgroundColor={index % 2 == 0 ? (isActive ? "#FFF" : "rgb(0, 0, 0, 0.2)") : (isActive ? "rgb(249, 249, 249)" : "rgb(0, 0, 0, 0.1)")}
                backgroundColor={index % 2 != 0 ? "#FFF" : "rgb(249, 249, 249)"}
                alignItems={"center"}
                borderRadius={index % 2 == 0 && "12px"}
                margin="0 16px"
                // disabled={UserData?.isActive === false}
                // style={UserData?.isActive === false && UserData?.isDeleted == true ? { opacity: 0.5 } : {}}
            >
                <Flex padding={"0 5px"} textWrap="balance" width="5%" >
                    {index}
                </Flex>
                <Flex padding={"0 5px"} textWrap="balance" width="5%" >
                    <NameFirstAlphabetBox name={(UserData?.firstName || UserData?.lastName)} />
                </Flex>
                <Flex style={{ wordBreak: "break-word" }} padding={"0 5px"} width="45%">
                    <Flex flexDirection={"column"} textWrap="wrap">
                        <Flex style={{ "fontWeight": "500", "color" : "#050505" }}>{(UserData?.firstName + ' ' + UserData?.lastName + ' ') || '-'}{loggedInUserId === UserData?.userId && `(You)`}</Flex>
                        <Flex style={{ "fontWeight": "400" }}>{UserData?.email || '-'}</Flex>
                    </Flex>
                    <Flex>
                        {/* {loggedInUserId === UserData?.userId && <Tag
                            color="#fff"
                            bgColor="rgba(0, 0, 0, 0.7)"
                            text="You"
                        />} */}
                        {/* {loggedInUserId === UserData?.userId && `(You)`} */}
                    </Flex>
                    <Flex marginLeft={"15px"}>
                        {UserData?.isActive === false && UserData?.isDeleted === false && (
                            <Tag
                                color="#FFFFFF"
                                bgColor="rgba(255, 171, 55, 1)"
                                text="Invitation Pending"
                            />
                        )}

                        {UserData?.isActive === true && UserData?.isDeleted === false && (
                            <Tag
                                color="#60C678"
                                bgColor="#EFF9F1"
                                text="Active"
                            />
                        )}

                        {UserData?.isActive === false && UserData?.isDeleted === true && (
                            <Tag
                                color="#FFFFFF"
                                bgColor="rgba(159, 159, 159, 1)"
                                text="Inactive"
                            />
                        )}
                    </Flex>
                </Flex>
                <Flex style={{ wordBreak: "break-word" }} padding={"0 5px"} width="15%">
                    {UserData?.userType === 'admin' && <Tag
                        color="#0094E3"
                        bgColor="rgba(220, 249, 255, 1)"
                        text="Admin"
                    />}
                    {UserData?.userType === 'sdr' && <Tag
                        color="#8D68F6"
                        bgColor="rgba(238, 241, 251, 1)"
                        text="SDR/BDR"
                    />}
                    {UserData?.userType === 'ae' && <Tag
                        color="#20960A"
                        bgColor="rgba(217, 242, 232, 1)"
                        text="AE"
                    />}
                </Flex>
              
                <Flex padding={"0 5px"} width="15%" >
                    {/* {loggedInUserId !== UserData?.userId && UserData?.isActive && <KebabMenu openEditForm={openEditFormpopup} />} */}
                    {((UserData?.isActive == true && UserData?.isDeleted==false) || (UserData?.isActive == false && UserData?.isDeleted == true)) &&(
                        <Button
                            fontWeight={"bold"}
                            fontSize="13px"
                            padding={"4px 5px"}
                            size="small"
                            variant="secondary"
                            borderRadius={"4px"}
                            border={"border: 0.6px solid #0094E3"}
                            background={"#FFF"}
                            mt="5px"
                            onClick={openEditFormpopup}
                        >
                            Edit Member
                        </Button>
                    )}

                    {UserData?.isActive == false && UserData?.isDeleted == false && (<Button
                        fontWeight={"bold"}
                        fontSize="13px"
                        padding={"4px 5px"}
                        size="small"
                        variant="profileSendTreat"
                        borderRadius={"4px"}
                        mt="5px"
                        onClick={resendInvitation}
                    // onClick={submitForm}
                    >
                        Resend Invitation
                    </Button>)}
                </Flex>
                <Flex padding={"0 5px"} width="15%">
                    {loggedInUserId !== UserData?.userId &&
                    (!(UserData?.isActive == false && UserData?.isDeleted == false) ? <ToggleButton
                        value={UserData?.isActive}
                        name='isActive'
                        variant="tertiary"
                        onChange={(value) => setHideMemberActivateConfirmationBox(false)}
                        // disabled={loggedInUserId === UserData?.userId}
                    /> : "")}
                </Flex>
            </Flex>

            <ModalWrapper
                width="max-content"
                height="max-content"

                hideModal={hideModal}
                setHideModal={() => {
                    setHideModal(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="24px"
            >
                <SnackBar
                    iconName={"info"}
                    message={snackbarMessage}
                    setShowSnackBar={setShowSnackBarModal}
                    show={showSnackBarModal}
                    timeout={4000}
                    anchor={"top-right"}
                    type={snackbarType}
                />

                <>
                    <Text fontWeight="400" fontSize="24px" marginBottom={4}>Update User</Text>
                    <Formik
                        key={isModalOpen.toString()}
                        initialValues={{
                            firstname: UserData?.firstName || '',
                            lastname: UserData?.lastName || '',
                            email: UserData?.email || '',
                            userType: UserData?.userType || '',
                            userId: UserData?.userId,
                        }}
                        onSubmit={(values) => {
                            console.log('Submitting edited data:', values);
                            handleUpdateUser(values);
                        }}
                        validationSchema={AddMemberSchema}

                    >
                        {({ setFieldValue, values }) => (
                            <Form>
                                <Flex flexDirection="column">
                                    <Flex padding={"5px 10px"} marginBottom={3} flexDirection="column">
                                        <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Select Role</Text>
                                        <Select
                                            className="single-select"
                                            placeholder="Select Role"
                                            name="userType"
                                            options={usertypeOptions}
                                            value={usertypeOptions.find(option => option.value === UserData?.userType)}
                                            onChange={(selectedOption) => {
                                                if (selectedOption && 'value' in selectedOption) {
                                                    setUserType(selectedOption.value);
                                                    setFieldValue('userType', selectedOption.value);
                                                }
                                            }}

                                            styles={{
                                                control: (provided: Record<string, unknown>, state: any) => ({
                                                    ...provided,
                                                    width: '360px',
                                                }),
                                            }}

                                        />
                                    </Flex>
                                    <Flex padding={"5px 10px"} flexDirection="column">
                                        <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>First Name</Text>
                                        <FormikInput
                                            name="firstname"
                                            placeholder="aereturn.profile.firstname"
                                        />
                                    </Flex>
                                    <Flex padding={"5px 10px"} flexDirection="column">
                                        <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Last Name</Text>
                                        <FormikInput
                                            name="lastname"
                                            placeholder="aereturn.profile.firstname"
                                        />
                                    </Flex>
                                    <Flex padding={"5px 10px"} flexDirection="column">
                                        <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Email Address</Text>
                                        <FormikInput
                                            name="email"
                                            type="email"
                                            placeholder="aereturn.profile.email"
                                        />
                                    </Flex>
                                    <Flex marginRight={1} marginTop={3} padding={"5px 10px"}>
                                        <Flex justifyContent={"left"}>
                                            <Button type="submit" borderRadius={"6px"} padding={"10px 36px"} fontSize={"14px"} fontWeight={"500"} lineHeight={"140%"}>Update</Button>
                                        </Flex>
                                        <Flex justifyContent={"right"}>
                                            <Button variant="secondary" type="button" onClick={() => { setHideModal(true); }} marginLeft={2} borderRadius={"6px"} padding={"10px 36px"} fontSize={"14px"} fontWeight={"500"} lineHeight={"140%"}>
                                                Cancel
                                            </Button>
                                        </Flex>
                                    </Flex>

                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </>
            </ModalWrapper>


            <ModalWrapper
                width="max-content"
                height="max-content"
                hideModal={hideMemberActivateConfirmationBox}
                setHideModal={() => {
                    setHideMemberActivateConfirmationBox(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="24px"
            >
                <Flex
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Text mt="8px" mb={"16px"} fontSize={"16px"} fontWeight={300}>
                        {" "}
                        Are you sure you want to {UserData.isActive == true ? "deactivate" : "activate"} {UserData.firstName}'s account?
                    </Text>
                    <Flex width={"60%"} mt="15px" justifyContent={"space-between"}>
                        <Flex
                            background={"#DD015B"}
                            color={"#fff"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => handleActivation(!UserData.isActive, UserData.userId)}

                        >
                            Yes
                        </Flex>
                        <Flex
                            ml="16px"
                            border="1px solid #0094E3"
                            background={"#fff"}
                            color={"#0094E3"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => setHideMemberActivateConfirmationBox(true)}
                        >
                            Cancel
                        </Flex>
                    </Flex>
                </Flex>
            </ModalWrapper>
        </>
    );
}

export default TeamManagementTableBodyField;
