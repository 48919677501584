import { FC, useState } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { AeReturnUnlikeModal } from "./AeReturnUnlikeModal";
import { useAppSelector } from "hooks";

export interface AeReturnUnlikeModalContainerProps {}

export const AeReturnUnlikeModalContainer: FC<
  AeReturnUnlikeModalContainerProps
> = () => {
  const modalData = useAppSelector((state) => state);
  console.log(modalData);

  const [hide, setHide] = useState(true);
  const modalHandler = () => {
    setHide(true);
  };

  return (
    <>
      <Box
        m="30px"
        padding={"10px"}
        background="teal"
        width={"max-content"}
        borderRadius="6px"
        cursor="pointer"
        onClick={() => setHide(!hide)}
      >
        Unlike Modal
      </Box>
      {
        //@ts-ignore
        <AeReturnUnlikeModal
          width="670px"
          height="max-content"
          hideModal={hide}
          setHideModal={modalHandler}
          action={true}
        ></AeReturnUnlikeModal>
      }
    </>
  );
};
