import * as React from "react";

function CompanyScoreIcon(props) {
    return (
       
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 14.2H18.2V14V2V1.8H18H6H5.8V2V14V14.2H6H18ZM1 4.2C1.44183 4.2 1.8 4.55817 1.8 5V17.8C1.8 18.0209 1.97909 18.2 2.2 18.2H15C15.4418 18.2 15.8 18.5582 15.8 19C15.8 19.4418 15.4418 19.8 15 19.8H2C1.0098 19.8 0.2 18.9989 0.2 18V5C0.2 4.55817 0.558172 4.2 1 4.2ZM12 10.2C12.7251 10.2 13.8249 10.3828 14.7382 10.7482C15.1947 10.9308 15.5921 11.1541 15.8722 11.4126C16.1508 11.6698 16.3 11.9484 16.3 12.25V12.8H7.7V12.25C7.7 11.9484 7.84921 11.6698 8.12784 11.4126C8.40788 11.1541 8.80525 10.9308 9.26178 10.7482C10.1751 10.3828 11.2749 10.2 12 10.2ZM14.05 6C14.05 7.12954 13.1295 8.05 12 8.05C10.8705 8.05 9.95 7.12954 9.95 6C9.95 4.87046 10.8705 3.95 12 3.95C13.1295 3.95 14.05 4.87046 14.05 6ZM6 0.2H18C18.9902 0.2 19.8 1.00111 19.8 2V14C19.8 14.9995 18.9995 15.8 18 15.8H6C5.00981 15.8 4.2 14.9989 4.2 14V2C4.2 1.01046 5.01046 0.2 6 0.2Z" fill="white" stroke="#1D1350" stroke-width="0.4"/>
</svg>


    );
}

export default CompanyScoreIcon;
