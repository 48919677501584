import { FC } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import OpportunityTableOption1 from "./OpportunityTableOption1"
import OpportunityTableFooter from "./OpportunityTableFooter";

interface AEListOpportunityAccordionSubSection { }

const AEListOpportunityAccordianSubSectionOption1: FC<AEListOpportunityAccordionSubSection> = () => {
  return <Box>
   <OpportunityTableOption1/>
   <OpportunityTableFooter/>
  </Box>;
}

export default AEListOpportunityAccordianSubSectionOption1;