import React, { FC } from "react";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
// import { MemoInfoIcon } from "@evabot/eva-react-core-library/dist/assets/icons/InfoIcon";

export interface StakeHolderHeaderProps {}

export const StakeHolderHeader: FC<StakeHolderHeaderProps> = () => {
  return (
    <tr style={{ textAlign: "left" }}>
      <th>
        <Text fontWeight="bold" color="textColor.secondary" fontSize="12px">
          Stakeholder name
        </Text>
      </th>
      <th>
        <Text
          fontWeight="bold"
          color="textColor.secondary"
          fontSize="12px"
          marginLeft={"50px"}
        >
          Email address
        </Text>
      </th>
      <th>
        <Text
          fontWeight="bold"
          color="textColor.secondary"
          fontSize="12px"
          marginLeft={"80px"}
        >
          Designation
        </Text>
      </th>
      <th>
        <Flex
          style={{ width: "max-content" }}
          alignItems={"center"}
          marginLeft={"50px"}
        >
          <Text
            fontWeight="bold"
            color="textColor.secondary"
            fontSize="12px"
            marginRight="10px"
          >
            Important stakeholder?
          </Text>
          {/* <MemoInfoIcon fill="#1FA1FF" /> */}
          <Icon name="info" fill="#1FA1FF" />
        </Flex>
      </th>
    </tr>
  );
};
