import * as React from "react";

function AdminSidePlanIcon(props) {
  return (
    <svg
      width={props?.size || "18px"}
      height={props?.size || "18px"}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M4.211 17.5h11.58a2.023 2.023 0 001.917-1.57l1.593-6.209a2.218 2.218 0 00-.76-2.317 1.863 1.863 0 00-2.071-.16l-1.553.865a.6.6 0 01-.476.056.758.758 0 01-.449-.384l-2.271-4.215a1.922 1.922 0 00-3.44 0L6.009 7.78a.759.759 0 01-.45.384.597.597 0 01-.475-.056L3.53 7.244a1.864 1.864 0 00-2.07.16A2.216 2.216 0 00.7 9.72l1.592 6.208A2.022 2.022 0 004.211 17.5zM2.225 8.393a.646.646 0 01.39-.142c.108.002.214.031.308.086l1.553.864a1.848 1.848 0 001.434.164 2.002 2.002 0 001.2-.99l2.272-4.216a.686.686 0 011.237 0l2.272 4.216a2.002 2.002 0 001.2.99 1.861 1.861 0 001.435-.164l1.552-.864a.608.608 0 01.699.056.968.968 0 01.313 1.017l-1.593 6.209a.778.778 0 01-.707.631H4.211a.778.778 0 01-.707-.631l-1.593-6.21a.967.967 0 01.314-1.016z"
        fill="#fff"
      />
    </svg>
  );
}

export default AdminSidePlanIcon;
