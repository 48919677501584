import React, { FC, useState, useCallback, useEffect } from "react";

import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Info } from "@evabot/eva-react-core-library/dist/molecules/Info/Info";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Anchor } from "@evabot/eva-react-core-library/dist/atoms/Anchor/Anchor";
import { Dropdown } from "@evabot/eva-react-core-library/dist/atoms/Dropdown/Dropdown";
import { Table } from "@evabot/eva-react-core-library/dist/atoms/Table/Table";
// import { FormCheckbox } from "@evabot/eva-react-core-library/dist/atoms/FormikCheckBox/FormikCheckBox";
import { Form, Formik } from "formik";
// import { ImageTag } from "@evabot/eva-react-core-library/dist/molecules/ImageTag/ImageTag";
import { Tag } from "@evabot/eva-react-core-library/dist/molecules/Tag/Tag";
import * as yup from "yup";
import { SignupLayout } from "@evabot/eva-react-core-library/dist/templates/SignupLayout/SignupLayout";
import AdminLeftSectionNav from "components/AdminLeftSectionNav/AdminLeftSectionNav";
import { useNavigate } from "react-router-dom";
import { axiosHelper } from "../../utils";
import { format } from "date-fns";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setGlobalProgress } from "store/globalStateReducer";
import { setImportOpportunities , setFilteredOpps , setFilteredValues } from "./store/OpportunitySelectionReducer";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";

export interface OpportunitySelectionLayoutProps { }

// const CustomerValidationSchema = yup.object().shape({
//   value : yup.bool()
// });

const AeCustomerValidationSchema = yup.object().shape({
  ae: yup.array(),
  accountName: yup.array(),
  oppstage: yup.array(),
});

export const OpportunitySelectionLayout: FC<
  OpportunitySelectionLayoutProps
> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const globalProgress = useAppSelector((state) => state.globalState.progress);
  const opportunityState = useAppSelector( (state) => state.opportunitySelection );
  const [expanded, setExpanded] = useState(true);
  const [aeValues, setAeValues] = useState([]);
  const [stonlyId, setStonlyId] = useState(null);
  const [accountNameValues, setAccountNameValues] = useState([]);
  const [selectedAccountNameValues, setSelectedAccountNameValues] = useState(
    []
  );
  const [opportunityStateValues, setOpportunityStateValues] = useState([]);
  const [selectedAeValues, setSelectedAeValues] = useState([]);

  const [selectedOpportunityIds, setSelectedOpportunityIds] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [opportunitiesLoading, setOpportunitiesLoading] = useState(false);

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [importLoading, setImportLoading] = useState(false);

  useEffect(() => {
    const items = localStorage.getItem('jwt_token');
    if (items) {
      setStonlyId(items);
      console.log("stonlyId :", items)
    }
    if ( opportunities.length == 0 && opportunityState.filteredOpps?.length > 0 ) {
      setOpportunities(opportunityState.filteredOpps);
    }
  }, []);
  useEffect(() => {
    // if ( aeValues.length == 0 || accountNameValues.length == 0 || opportunityStateValues.length == 0 ) {
    //  await triggerFilterValuesCall();
    axiosHelper({
      url: "salesforce/getOpportunityFieldValues",
      method: "GET",
      JSONData: {},
      urlOverride: false,
    })
      .then((result) => {
        console.log("result data", result.data);
        console.log("userData", result.data.data.userData);
        console.log("account Data", result.data.data.accountData);
        console.log("oppdata", result.data.data.oppData);
        setAeValues(result.data.data.userData);
        // setAccountNameValues(result.data.data.userData);
        setAccountNameValues(result.data.data.accountData.map((v) => v.trim()));
        setOpportunityStateValues(result.data.data.oppData);
      })
      .catch((err) => {
        console.log("error", err);
      });
    // }
  }, []);

  const tableTitle: string[] = [
    "Opportunity Name",
    "Opportunity value",
    "Assigned to",
    "Assigned date",
    "Expected close date",
    "Opportunity stage",
  ];

  // const onSubmitCheckBoxHandler = useCallback(async (values: any) => {
  //   console.log("valuess",values);
  // }, []);
  const onSubmitHandler = useCallback(async (values: any) => {
    console.log(values, "values called", values["ae"]);

    setOpportunitiesLoading(true);
    axiosHelper({
      url: `salesforce/getFilteredOpportunityData/${values["ae"].length > 0 ? values["ae"] : "empty"
        }/${values["accountName"].length > 0 ? values["accountName"] : "empty"}/${values["oppstage"].length > 0 ? values["oppstage"] : "empty"
        }`,
      method: "GET",
      JSONData: {},
      urlOverride: false,
    })
      .then((result) => {
        console.log("result data", result.data);
        // setAeValues( values["ae"] );
        // setAccountNameValues(values["accountName"]  );
        // setOpportunityStateValues( values["oppstage"] );
        setSelectedAeValues(values["ae"]);
        setOpportunities(result.data.data.records);
        setOpportunitiesLoading(false);
        setExpanded(false);
        dispatch( setFilteredOpps(result.data.data.records) );
        dispatch( setFilteredValues(values) );
        // console.log("userData",result.data.data.userData);
        // console.log("account Data",result.data.data.accountData);
        // console.log("oppdata",result.data.data.oppData);
        // setAeValues(result.data.data.userData);
        // // setAccountNameValues(result.data.data.userData);
        // setAccountNameValues(result.data.data.accountData.map(v => v.trim()));
        // setOpportunityStateValues(result.data.data.oppData);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);
  const tableBody = () => {
    return opportunities.map((d) => {
      return (
        <tr>
          <td>
            <Flex
              flexDirection={"row"}
              alignItems={"center"}
              gridColumnGap={"8px"}
            >
              <input
                type="checkbox"
                checked={selectedOpportunityIds.includes(d.Id)}
                onChange={(event) => {
                  // console.log("event target check",values);
                  console.log("checked", event.target.checked);
                  if (
                    event.target.checked &&
                    !selectedOpportunityIds.includes(d.Id)
                  ) {
                    let selectedIds = [...selectedOpportunityIds];
                    selectedIds.push(d.Id);
                    console.log("selectedIds", selectedIds);
                    setSelectedOpportunityIds(selectedIds);
                    // let selectedIds = selectedOpportunityIds.filter( id => id != d.Id );
                    // setSelectedOpportunityIds(selectedIds);
                    // console.log("selectedIds",selectedIds);
                  } else if (
                    !event.target.checked &&
                    selectedOpportunityIds.includes(d.Id)
                  ) {
                    let selectedIds = selectedOpportunityIds.filter(
                      (id) => id !== d.Id
                    );
                    setSelectedOpportunityIds(selectedIds);
                    console.log("selectedIds", selectedIds);
                  }
                  // } else {
                  //   let selectedIds = [...selectedOpportunityIds];
                  //   selectedIds.push(d.Id);
                  //   console.log("selectedIds",selectedIds);
                  //   setSelectedOpportunityIds(selectedIds);
                  // }
                }}
              />
              {/* <Formik
                initialValues={{
                  value: false,
                }}
                onSubmit={onSubmitCheckBoxHandler}
                enableReinitialize
                validationSchema={CustomerValidationSchema}
              >
                {({ values, errors, dirty, isValid, setFieldValue  }) => {
                  console.log(errors, "error", values);
                  return (
                    <Form>
                      <FormCheckbox name="value" variant="secondary" 
                      // checked={ selectedOpportunityIds.includes(d.Id) } 
                      onChange ={ (event) => {
                        console.log("event target check",values);
                        // if ( event.target.checked && selectedOpportunityIds.includes(d.Id) ) {
                        //   let selectedIds = selectedOpportunityIds.filter( id => id != d.Id );
                        //   setSelectedOpportunityIds(selectedIds); 
                        //   console.log("selectedIds",selectedIds);
                        // } else {
                        //   let selectedIds = [...selectedOpportunityIds];
                        //   selectedIds.push(d.Id);
                        //   console.log("selectedIds",selectedIds);
                        //   setSelectedOpportunityIds(selectedIds);
                        // }
                        
                      } }  
                      />
                    </Form>
                  );
                }}
              </Formik> */}
              <Text color="#050505" fontSize={"14px"} fontWeight={"400"}>
                {" "}
                {d.Name}{" "}
              </Text>
            </Flex>
          </td>
          <td>
            <Text color="#050505" fontSize={"14px"} fontWeight={"400"}>
              {" "}
              {d.Amount}{" "}
            </Text>
          </td>
          <td>
            <Flex
              flexDirection={"row"}
              alignItems={"center"}
              gridColumnGap={"8px"}
            >
              {/* <ImageTag image={d.imgUrl} width="32px" height="32px" /> */}
              <Text color="#050505" fontSize={"14px"} fontWeight={"400"}>
                {" "}
                {aeValues.filter((ae) => ae.Id === d.OwnerId).length > 0
                  ? aeValues.filter((ae) => ae.Id === d.OwnerId)[0].Name
                  : ""}{" "}
              </Text>
            </Flex>
          </td>
          <td>
            <Text color="#050505" fontSize={"14px"} fontWeight={"400"}>
              {" "}
              {format(new Date(d.CreatedDate), "do MMMM, yyyy")}{" "}
            </Text>
          </td>
          <td>
            <Text color="#050505" fontSize={"14px"} fontWeight={"400"}>
              {" "}
              {d.CloseDate}{" "}
            </Text>
          </td>
          <td>
            <Tag
              color="#2247C9"
              bgColor="rgba(34, 71, 201, 0.1)"
              text={d.StageName}
            />
          </td>
        </tr>
      );
    });
  };

  // const header = () => {
  //   return (
  //     <tr>
  //       <th>Hello</th>
  //       <th>Hello</th>
  //     </tr>
  //   );
  // };
  // const handleFieldChange = useCallback(
  //   (stateValue, prevValue) => {
  //     console.log("check target change",stateValue,prevValue);
  //     // valueChange(stateValue, prevValue, StateValues, setStateValues);
  //   },
  //   []
  // );

  return (
    <>
      <SnackBar
        iconName={"info"}
        subHeading={""}
        setShowSnackBar={setShowSnackBar}
        show={showSnackBar}
        message={snackbarMessage}
        timeout={5000}
        anchor={"top-right"}
        type={"success"}
        bgColorr="#F44545"
      />
      <Box height={"100vh"} padding={"32px 42px"}>
        <Flex
          flexDirection={"row"}
          justifyContent={"space-between"}
          marginBottom={"20px"}
        >
          <Flex flexDirection={"column"} gridRowGap={"10px"}>
            <Text
              fontSize={"24px"}
              color={"#050505"}
              fontWeight={"400"}
              id="opportunityselection.heading"
            />
            <Info
              locale="opportunityselection.chatgpt.info.message"
              color="#1FA1FF"
            />
          </Flex>
          <Flex alignItems="baseline">
            <Text fontSize="12px" fontWeight="400">
              Having troubles?
            </Text>
            <Anchor text="Get Help" link="" id={stonlyId} />
          </Flex>
        </Flex>
        <Flex flexDirection={"column"} gridRowGap={"16px"}>
          <Formik
            // initialValues={
            //   selectedAeValues.length > 0
            //     ? {
            //       ae: selectedAeValues,
            //       accountName: [],
            //       oppstage: [],
            //     }
            //     : { ae: [], accountName: [], oppstage: [] }
            // }
            initialValues={
              opportunityState.filteredValues != null 
                ? {
                  ae: opportunityState.filteredValues["ae"],
                  accountName: opportunityState.filteredValues["accountName"],
                  oppstage : opportunityState.filteredValues["oppstage"]
                }
                : { ae: [], accountName: [], oppstage: [] }
            }
            onSubmit={onSubmitHandler}
            validationSchema={AeCustomerValidationSchema}
          >
            {({
              values,
              errors,
              dirty,
              isValid,
              setFieldValue,
              submitForm,
            }) => {
              console.log(errors, "error", values.accountName);

              return (
                <Form>
                  <Grid
                    backgroundColor={"#FFFFFF"}
                    borderRadius={"12px"}
                    boxShadow={"0px 4px 15px rgba(0, 0, 0, 0.05)"}
                    padding={"24px"}
                  >
                    <Flex
                      justifyContent={"flex-start"}
                      flexDirection={"column"}
                      gridRowGap={"24px"}
                    >
                      <Flex
                        justifyContent="space-between"
                        alignItems={"center"}
                      >
                        <Flex
                          flexDirection={"row"}
                          gridColumnGap={"8px"}
                          alignItems={"center"}
                        >
                          <Icon name={"filter"} fill="#050505" />
                          <Text
                            color="#050505"
                            fontSize={"24px"}
                            fontWeight={"400"}
                          >
                            {" "}
                            Filters{" "}
                          </Text>
                        </Flex>
                        <Flex
                          flexDirection={"row"}
                          gridColumnGap={"27px"}
                          alignItems={"center"}
                        >
                          {!expanded && (
                            <Flex
                              flexDirection={"row"}
                              alignItems={"center"}
                              gridColumnGap={"10px"}
                            >
                              <Text
                                color="#57585C"
                                fontSize={"14px"}
                                fontWeight={"400"}
                              >
                                {" "}
                                {values["ae"].length} AE |{" "}
                              </Text>
                              <Text
                                color="#57585C"
                                fontSize={"14px"}
                                fontWeight={"400"}
                              >
                                {" "}
                                {opportunities.length} Opportunities |{" "}
                              </Text>
                              <Text
                                color="#57585C"
                                fontSize={"14px"}
                                fontWeight={"400"}
                              >
                                {" "}
                                {values["oppstage"].join(", ")}
                              </Text>
                            </Flex>
                          )}
                          {!expanded ? (
                            <Icon
                              name={"edit"}
                              fill="#0094E3"
                              style={{ cursor: "pointer" }}
                              onClick={() => setExpanded(!expanded)}
                            />
                          ) : (
                            <Flex
                              flexDirection={"row"}
                              gridColumnGap={"8px"}
                              alignItems={"center"}
                            >
                              <Button
                                variant="secondary"
                                onClick={() => {
                                  console.log("clear called");
                                  setExpanded(!expanded);
                                  setFieldValue("ae", []);
                                  setFieldValue("accountName", []);
                                  setFieldValue("oppstage", []);
                                }}
                              >
                                {" "}
                                Clear filter{" "}
                              </Button>
                              <Button
                                loading={opportunitiesLoading}
                                disabled={
                                  values["ae"].length === 0 &&
                                  values["accountName"].length === 0 &&
                                  values["oppstage"].length === 0
                                }
                                onClick={submitForm}
                              // onClick={() => {
                              //   console.log("apply called");
                              //   setExpanded(!expanded);
                              //   onSubmitHandler(values);
                              // }}
                              >
                                {" "}
                                Apply{" "}
                              </Button>
                            </Flex>
                          )}
                        </Flex>
                      </Flex>
                      {expanded && (
                        <>
                          <Box
                            style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
                          />
                          <Flex
                            alignItems={"center"}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                          >
                            <Box width="100%" mr="10px">
                              <Dropdown
                                isMultiple
                                options={aeValues.map((ae) => ae.Name)}
                                label="ae"
                                placeholder="ae"
                                name="ae"
                                checkboxLabel
                                defaultValue={values?.ae}
                              />
                            </Box>
                            <Box width="100%" mr="10px">
                              <Dropdown
                                isMultiple
                                options={selectedAccountNameValues}
                                // options={accountNameValues &&  accountNameValues.length>0 ? accountNameValues : [] }
                                label="accountName"
                                name="accountName"
                                checkboxLabel
                                onInputChange={(e) => {
                                  console.log("valll", e.target.value);
                                  if (
                                    e.target.value &&
                                    e.target.value.length >= 3
                                  ) {
                                    let accountNames = accountNameValues.filter(
                                      (name) =>
                                        name
                                          .toLowerCase()
                                          .includes(
                                            e.target.value.toLowerCase()
                                          )
                                    );
                                    setSelectedAccountNameValues(accountNames);
                                  }
                                }}
                                defaultValue={values?.accountName}
                                placeholder="accountName"
                              />
                            </Box>
                            <Box width="100%">
                              <Dropdown
                                isMultiple
                                options={opportunityStateValues}
                                label="oppstage"
                                name="oppstage"
                                placeholder="oppstage"
                                checkboxLabel
                                defaultValue={values?.oppstage}
                              />
                            </Box>
                          </Flex>
                        </>
                      )}
                    </Flex>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
          <Table
            selectAllCheckBox={() => <input type="checkbox" onChange={(evt) => {
              console.log("eeee", evt?.target?.checked);
              if (evt?.target?.checked) {
                let selectedIds = opportunities.map(op => op.Id);
                setSelectedOpportunityIds(selectedIds);
              } else {
                setSelectedOpportunityIds([]);
              }
            }} />}
            bodyHeight="400px"
            caption=""
            width="100%"
            titles={tableTitle}
            rows={tableBody}
            footer={null}
          // borderLess
          // headerSection={header}
          />
          <Flex
            flexDirection={"row"}
            gridColumnGap="16px"
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Button
              variant="secondary"
              iconAlign="left"
              iconName="arrowLeft"
              onClick={() => navigate("/fieldMappingSection/previous")}
            >
              {" "}
              Previous step{" "}
            </Button>
            <Button
              iconAlign="right"
              iconName="arrowRight"
              loading={importLoading}
              onClick={() => {
                setImportLoading(true);
                let selected = opportunities.filter((op) =>
                  selectedOpportunityIds.includes(op.Id)
                );
                dispatch(setImportOpportunities(selected));
                axiosHelper({
                  url: "salesforce/createAccountsAndOpportunities",
                  method: "POST",
                  JSONData: {
                    opportunity: selected,
                  },
                  urlOverride: false,
                })
                  .then((result) => {
                    setImportLoading(false);
                    if (result.data.success) {
                      if (globalProgress < 40) {
                        dispatch(setGlobalProgress(40));
                      }
                      navigate("/budgetSetupLayout");
                    }
                    else {
                      setShowSnackBar(true);
                      setSnackbarMessage(result.data.message);
                    }
                  })
                  .catch((err) => {
                    setImportLoading(false);
                    console.log("error", err);
                  });
              }}
            >
              {" "}
              Import{" "}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

const OpportunitySelectionLayoutComponent: FC = () => {
  return (
    <>
      {
        //@ts-ignore
        <SignupLayout
          left={25}
          right={75}
          leftChildren={<AdminLeftSectionNav selectedTab={2} />}
          rightChildren={<OpportunitySelectionLayout />}
        />
      }
    </>
  );
};

export default OpportunitySelectionLayoutComponent;
