import axios, { AxiosInstance } from 'axios';
import store from 'store';
import { CORE_API_URL } from 'utils';

class ApiService {
  private api: AxiosInstance;
  constructor() {
    this.api = axios.create({
      baseURL: CORE_API_URL,
    });
    this.api.interceptors.request.use((config) => {
      // Add headers here
      config.headers['Content-Type'] = 'application/json';
      const authorizationToken = localStorage.getItem('Authorization');
      if (authorizationToken) {
        config.headers['Authorization'] = `Bearer ${authorizationToken}`;
      }

      const memberId = store.getState().profileState.profileData.userId;
      if (memberId) {
        config.headers['X-MemberId'] = memberId;
      }
      return config;
    });
  }

  get(endpoint: string) {
    return this.api.get(endpoint)
      .then(response => response.data)
      .catch(error => {
        throw new Error(`Error in GET request: ${error.message}`);
      });
  }

  async post(endpoint: string, data: any) {
    try {

      const response = await this.api.post(endpoint, data);
      return response.data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
}

const apiService = new ApiService();
export default apiService;