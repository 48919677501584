import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";

interface AeReturnActivityTextTagProps {
  text1: string;
  text2?: string;
  text3?: string;
  boldText1?: string;
  boldText2?: string;
}

const AeReturnActivityTextTag: React.FC<AeReturnActivityTextTagProps> = ({
  text1,
  text2,
  text3,
  boldText1,
  boldText2,
}) => {
  return (
    <Text
      as="span"
      color="#050505"
      fontSize={"14px"}
      fontWeight={300}
      ml="10px"
    >
      {" "}
      {text1}{" "}
      {boldText1 && (
        <Text as="span" fontWeight={500}>
          {" "}
          {boldText1}{" "}
        </Text>
      )}{" "}
      {text2 && text2}{" "}
      {boldText2 && (
        <Text as="span" fontWeight={500}>
          {" "}
          {boldText2}{" "}
        </Text>
      )}{" "}
      {text3 && text3}{" "}
    </Text>
  );
};

export default AeReturnActivityTextTag;
