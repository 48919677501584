import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import LikeIcon from "assets/icons/LikeIcon";
import { FC } from "react";

export interface SuccessTagProps {
  text: string;
}

const SuccessTag: FC<SuccessTagProps> = ({ text }) => {
  return (
    <Flex
      ml="10px"
      background={"#F5FFF7"}
      padding="6px 12px"
      borderRadius={"8px"}
    >
      <LikeIcon />
      <Text ml="10px" color="basicColors.basicGreen">
        {text}
      </Text>
    </Flex>
  );
};

export default SuccessTag;
