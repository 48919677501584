import React, { useCallback, FC, useEffect, useState } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { ExpandableCard } from "@evabot/eva-react-core-library/dist/molecules/ExpandableCard/ExpandableCard";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { useAppDispatch } from "../../hooks";
import {
  setFieldMappingLeads,
  FieldMappingLeadsState,
  setFieldMappingLeadsDefault,
} from "./store/fieldMappingReducer";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Dropdown } from "@evabot/eva-react-core-library/dist/atoms/Dropdown/Dropdown";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { fieldMappingSalesforceNameMapper } from "utils";
import { setInitialValue, valueChange } from "utils/constants";

const leadsSchema = yup.object().shape({
  leadID: yup.string().required("fieldmapping.lead.id.valid"),
  leadName: yup.string().required("fieldmapping.lead.name.valid"),
  emailAddress: yup.string().required("fieldmapping.lead.email.valid"),
  phoneNumber: yup.string().required("fieldmapping.lead.phoneNo.valid"),
  designation: yup.string().required("fieldmapping.lead.title.valid"),
});

interface FieldMappingLeadsSectionProps {
  leadsExpandedFlag: boolean;
  isLeadsCompleteFlag: boolean;
  toggleIsLeadsCompleteFlag: (e: any) => void;
  toggleLeadsExpandedFlag: (e: any) => void;
  onSubmit?: () => void;
  allSalesforceFields: any;
  initialValue?: FieldMappingLeadsState;
}

const defaultValues = {
  leadName: "",
  emailAddress: "",
  phoneNumber: "",
  designation: "",
  leadID: "",
};

const FieldMappingLeadsSection: FC<FieldMappingLeadsSectionProps> = ({
  leadsExpandedFlag,
  toggleLeadsExpandedFlag,
  isLeadsCompleteFlag,
  toggleIsLeadsCompleteFlag,
  onSubmit,
  allSalesforceFields,
  initialValue,
}) => {
  const salesforceFieldLabels = allSalesforceFields.map((x) => {
    return {
      name: x.name,
      label: x.label,
    };
  });

  const state = salesforceFieldLabels.map((e) => e.label);
  const [StateValues, setStateValues] = useState(state);

  useEffect(() => {
    if (initialValue) {
      setInitialValue(initialValue, StateValues, setStateValues);
    }
  }, []);

  const handleFieldChange = useCallback(
    (stateValue, prevValue) => {
      valueChange(stateValue, prevValue, StateValues, setStateValues);
    },
    [StateValues]
  );

  const dispatch = useAppDispatch();

  const onSubmitHandler = useCallback(
    async (values: any) => {
      dispatch(
        setFieldMappingLeads(
          // @ts-ignore
          fieldMappingSalesforceNameMapper(values, allSalesforceFields)
        )
      );
      dispatch(setFieldMappingLeadsDefault(values));
      toggleIsLeadsCompleteFlag(true);
      // toggleLeadsExpandedFlag(!leadsExpandedFlag);
      onSubmit();
    },
    [dispatch, onSubmit, toggleIsLeadsCompleteFlag, allSalesforceFields]
  );

  return (
    <>
      {
        //@ts-ignore
        <ExpandableCard
          headerText={"Leads"}
          expanded={leadsExpandedFlag}
          loaded={isLeadsCompleteFlag}
          toggleCard={() => {
            toggleLeadsExpandedFlag(!leadsExpandedFlag);
          }}
        >
          <Flex flexDirection="column">
            <Flex>
              <Formik
                initialValues={initialValue ? initialValue : defaultValues}
                onSubmit={onSubmitHandler}
                enableReinitialize
                validationSchema={leadsSchema}
              >
                {({
                  values,
                  errors,
                  dirty,
                  isValid,
                  setFieldValue,
                  submitForm,
                  initialValues,
                }) => {
                  return (
                    <>
                      {
                        //@ts-ignore
                        <Form>
                          <Flex alignItems="center" marginBottom={"-14px"}>
                            <Box width={"25vw"}>
                              <Text
                                id="fieldmapping.opportunity.fieldsineva"
                                variant="bodyRegular"
                                fontWeight={"500"}
                                marginBottom="12px"
                                color="textColor.primary"
                              />
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="leadIDField"
                                placeholder="fieldmapping.leads.leadID"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-10px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Text
                                id="fieldmapping.opportunity.fieldsinsalesforce"
                                variant="bodyRegular"
                                fontWeight={"500"}
                                marginBottom="2px"
                                color="textColor.primary"
                              />
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="leadID"
                                placeholder="fieldmapping.leads.leadID"
                                variantType="primary"
                                options={
                                  values?.leadID?.length > 0
                                    ? [...StateValues, values?.leadID].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.leadID ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsLeadsCompleteFlag(true);
                                    } else {
                                      toggleIsLeadsCompleteFlag(false);
                                    }
                                  }
                                  handleFieldChange(valueField, values?.leadID);
                                }}
                                defaultValue={values?.leadID}
                              />
                            </Box>
                          </Flex>
                          <Flex alignItems="center" marginBottom={"-14px"}>
                            <Box width={"25vw"}>
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="leadNameField"
                                placeholder="fieldmapping.leads.leadname"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-25px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="leadName"
                                placeholder="fieldmapping.leads.leadname"
                                variantType="primary"
                                options={
                                  values?.leadName?.length > 0
                                    ? [...StateValues, values?.leadName].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.leadName ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsLeadsCompleteFlag(true);
                                    } else {
                                      toggleIsLeadsCompleteFlag(false);
                                    }
                                  }
                                  handleFieldChange(
                                    valueField,
                                    values?.leadName
                                  );
                                }}
                                defaultValue={values?.leadName}
                              />
                            </Box>
                          </Flex>
                          <Flex alignItems="center" marginBottom={"-14px"}>
                            <Box width={"25vw"}>
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="emailAddressField"
                                placeholder="fieldmapping.leads.emailAddress"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-25px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="emailAddress"
                                placeholder="fieldmapping.leads.emailAddress"
                                variantType="primary"
                                options={
                                  values?.emailAddress?.length > 0
                                    ? [
                                        ...StateValues,
                                        values?.emailAddress,
                                      ].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.emailAddress ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsLeadsCompleteFlag(true);
                                    } else {
                                      toggleIsLeadsCompleteFlag(false);
                                    }
                                  }
                                  handleFieldChange(
                                    valueField,
                                    values?.emailAddress
                                  );
                                }}
                                defaultValue={values?.emailAddress}
                              />
                            </Box>
                          </Flex>
                          <Flex alignItems="center" marginBottom={"-14px"}>
                            <Box width={"25vw"}>
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="phoneNumberField"
                                placeholder="fieldmapping.leads.phoneNumber"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-25px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="phoneNumber"
                                placeholder="fieldmapping.leads.phoneNumber"
                                variantType="primary"
                                options={
                                  values?.phoneNumber?.length > 0
                                    ? [
                                        ...StateValues,
                                        values?.phoneNumber,
                                      ].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.phoneNumber ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsLeadsCompleteFlag(true);
                                    } else {
                                      toggleIsLeadsCompleteFlag(false);
                                    }
                                  }
                                  handleFieldChange(
                                    valueField,
                                    values?.phoneNumber
                                  );
                                }}
                                defaultValue={values?.phoneNumber}
                              />
                            </Box>
                          </Flex>
                          <Flex alignItems="center" marginBottom={"-14px"}>
                            <Box width={"25vw"}>
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="designationField"
                                placeholder="fieldmapping.leads.designation"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-25px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="designation"
                                placeholder="fieldmapping.leads.designation"
                                variantType="primary"
                                options={
                                  values?.designation?.length > 0
                                    ? [
                                        ...StateValues,
                                        values?.designation,
                                      ].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.designation ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsLeadsCompleteFlag(true);
                                    } else {
                                      toggleIsLeadsCompleteFlag(false);
                                    }
                                  }
                                  handleFieldChange(
                                    valueField,
                                    values?.designation
                                  );
                                }}
                                defaultValue={values?.designation}
                              />
                            </Box>
                          </Flex>
                          <Flex marginTop={15} alignItems="center">
                            {/* {
                              //@ts-ignore
                              <Text
                                id="addnewfield"
                                cursor="pointer"
                                marginRight={16}
                                color={"#DD015B"}
                              />
                            } */}
                            <Button
                              title="saveandcontinue"
                              onClick={submitForm}
                              disabled={!dirty || !isValid}
                            />
                          </Flex>
                        </Form>
                      }
                    </>
                  );
                }}
              </Formik>
            </Flex>
          </Flex>
        </ExpandableCard>
      }
    </>
  );
};

export default FieldMappingLeadsSection;
