import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import CompanyScoreReportTableBodyField from "./CompanyScoreReportTableBodyField";

function CompanyScoreReportTableBody({filteredData,currentPage,itemsPerPage}) {
 
  return (
    <Box background="#fff" className="table-data-account">
      {(!Array.isArray(filteredData) || filteredData.length === 0) ? (
        <Flex justifyContent="center" alignItems="center" height="100px">
          <span>No data available</span>
        </Flex>
      ) : (
        filteredData.map((item, index) => (
          <CompanyScoreReportTableBodyField  data={item} index={(currentPage - 1) * itemsPerPage + (index + 1)} key={index} />
        ))
      )}
    </Box>
  );
}

export default CompanyScoreReportTableBody;
