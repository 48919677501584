import { Avatar } from "@evabot/eva-react-core-library/dist/atoms/Avatar/Avatar";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import ProfileAccountLogo from "assets/icons/ProfileAccountLogo";
import ProfileLogoutIcon from "assets/icons/ProfileLogoutIcon";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks";
import { setLoggedInState, setMenuItems } from "store/globalStateReducer";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

interface ProfileModalDataProps { }

const ProfileModalData: React.FC<ProfileModalDataProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedInUser = useAppSelector((state) => state.globalState.name);
  const journey = useAppSelector((state) => state.globalState.journey);
  const profileData = useAppSelector((state) => state.profileState.profileData);
  const isSuperAdmin = profileData.role === "superadmin";
  const isSuperAdminView = localStorage.getItem("superadminview")

  function clearAuthenticationCookies() {
    const cookieNames = ["user", "evaAuthToken", "token", "ssologinemail"];
    cookieNames.forEach((cookieName) => {
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
  }

  const logoutUser = async () => {
    localStorage.removeItem("Authorization");
    localStorage.removeItem("organizationId");
    clearAuthenticationCookies();
    dispatch(setMenuItems([]));
    if (journey.journeyName === "admin") {
      navigate("/aeLogin1");
    } else if (journey.journeyName === "ae" && journey.journeyPath === "1") {
      navigate("/aeLogin1");
    } else {
      navigate("/aeLogin2");
    }
    const channel = new BroadcastChannel('user-logout');
    channel.postMessage('logout');
  };

  const switchToSuperAdminView = async (value) => {
    value == 1 ? localStorage.setItem("superadminview", "yes") : localStorage.removeItem("superadminview");
    window.location.href="/";
  }

  useEffect(() => {
    const channel = new BroadcastChannel('user-logout');

    channel.onmessage = (event) => {
      if (event.data === 'logout') {
        navigate("/aeLogin1");
        window.location.reload();
      }
    };

    return () => {
      channel.close();
    };
  }, []);

  return (
    <Grid>
      <Flex
        p="24px 24px 16px 24px"
        borderBottom={"1px solid rgba(0, 0, 0, 0.1)"}
        alignItems="center"
      >
        <Avatar
          width="42px"
          height="42px"
          borderRadius="50%"
          imageUrl={
            profileData?.imageFile
              ? profileData?.imageFile
              : "https://www.newsbtc.com/wp-content/uploads/2020/06/mesut-kaya-LcCdl__-kO0-unsplash-scaled.jpg"
          }
        />
        <Grid ml="8px">
          <Text color="textColor.primary" variant="bodyRegular">
            {loggedInUser}
          </Text>
          {/* <Text color="textColor.secondary" variant="inputHint">
            Ekstromb@evabot.ai
          </Text> */}
        </Grid>
      </Flex>
      <Grid p="12px" pb={"6px"} gridGap={isSuperAdmin ? "16px" : "24px"}>
        <Flex cursor="pointer" onClick={() => navigate("/aeReturnProfile")}>
          <ProfileAccountLogo />

          <Text color="textColor.primary" variant="bodyRegular" ml="10px">
            My Profile
          </Text>
        </Flex>

        {isSuperAdmin && (<Flex cursor="pointer" onClick={() => navigate("/aeReturnProfile")}>
          <ProfileAccountLogo />
          { isSuperAdminView? <Text onClick={()=> switchToSuperAdminView(0)} color="textColor.primary" variant="bodyRegular" ml="10px">
            Switch to Admin
          </Text>:<Text onClick={()=> switchToSuperAdminView(1)} color="textColor.primary" variant="bodyRegular" ml="10px">
            Switch to Super Admin
          </Text>}
        </Flex>)}
        <Flex
          cursor="pointer"
          onClick={() => {
            logoutUser();
            dispatch(setLoggedInState(false));
          }}
        >
          <ProfileLogoutIcon cursor="pointer" />
          <Text color="textColor.primary" variant="bodyRegular" ml="10px">
            Sign out
          </Text>
        </Flex>
      </Grid>
    </Grid>
  );
};

export default ProfileModalData;
