import { FC, useState, useCallback, useEffect } from "react";
import { RightSideModal } from "@evabot/eva-react-core-library/dist/molecules/RightSideModal/RightSideModal";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { ToolTip } from "@evabot/eva-react-core-library/dist/molecules/ToolTip/ToolTip";
import { Form, Formik } from "formik";
import { SelectComponent } from "@evabot/eva-react-core-library/dist/atoms/SelectComponent/SelectComponent";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Avatar } from "@evabot/eva-react-core-library/dist/atoms/Avatar/Avatar";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { icons } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { FormikDatePicker } from "@evabot/eva-react-core-library/dist/atoms/FormikDatePicker/FormikDatePicker";
import { SelectIconComponent } from "@evabot/eva-react-core-library/dist/atoms/SelectIconComponent/SelectIconComponent";
import DeliverIcon from "./BirthdayNudge/DeliverIcon";
import SendTreatDefaultImage from "../../assets/images/send_treat_default.jpg";
import * as yup from "yup";
import TimerIcon from "./BirthdayNudge/TimerIcon";
import { Timer } from "components/Timer/Timer";
import { useAppSelector } from "hooks";
import { axiosHelper } from "utils";
import { insertActivityIntoActivityHistory } from "utils/activityHistoryHelper";
import dayjs from "dayjs";
import { FormikTextArea } from "@evabot/eva-react-core-library/dist/atoms/FormikTextArea/FormikTextArea";
interface SendSportsModalProps {
  showSportsModal: any;
  setShowSportsModal: any;
  // selectedNudge?: any;
  stakeholderId: any;
  stakeholderName: string;
  name: any;
  cancelOrder: (e) => any;
  fetchCurrentOrderAPI: () => any;
}
export interface IconOptionType {
  iconName: keyof typeof icons;
  iconValue: string;
  subText?: string;
  deliveryEstimate: string;
  disabled?: boolean;
}
const iconCategoryOptions: IconOptionType[] = [
  {
    iconName: "ticketSportsIcon",
    iconValue: "Send tickets",
    subText: "(coming soon)",
    deliveryEstimate: "2",
    disabled: true,
  },
  {
    iconName: "gearSportsIcon",
    iconValue: "Send fan gear",
    subText: "",
    deliveryEstimate: "5",
    disabled: false,
  },
];
const defaultValues = {
  category: JSON.stringify(iconCategoryOptions[1]),
  date: "",
  price: ["$50"],
  instruction: "",
  additionalInfo: "",
};
const SendTreatSchema = yup.object().shape({
  category: yup.string().required("required.field"),
  date: yup.date().required("required.field"),
  price: yup.array().required("required.field").min(1, "required.field"),
  instruction: yup.string(),
  additionalInfo: yup.string(),
});
const SendSportsModal: FC<SendSportsModalProps> = ({
  showSportsModal,
  setShowSportsModal,
  stakeholderId,
  name,
  stakeholderName,
  cancelOrder,
  fetchCurrentOrderAPI,
}) => {
  const loggedInMember = useAppSelector(state => state.profileState.profileData);
  const [continueView, setContinueView] = useState(false);
  const [treatLoading, toggleTreatLoading] = useState(false);
  const [view, setView] = useState(1);
  const [formData, setFormData] = useState(null);
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  const submitTreatOrder = async () => {
    toggleTreatLoading(true);
    try {
      const result = await axiosHelper({
        url: "orders/createOrder",
        method: "POST",
        JSONData: {
          stakeholderId: stakeholderId,
          foodType: formData.category,
          orderSchedule: formData.date || new Date(),
          orderInstructions: formData.instruction,
          orderMessage: formData.additionalInfo,
          orderPrice: formData.price.substr(1, 2),
          orderType: "fanGear",
        },
      });
      toggleTreatLoading(false);
      if (result.data.success) {
        insertActivityIntoActivityHistory({
          stakeholderName: stakeholderName,
          stakeholderId: stakeholderId
        }, {
          id: result.data.id,
          price: formData.price.substr(1, 2),
          type: formData.category,
          date: formData.date,
          status: 'Scheduled'
        }, loggedInMember, 'fangear');
        if (
          ["treatTea", "treatLiquor", "treatPizza"].includes(formData.category)
        ) {
          setView(1);
          setContinueView(true);
        } else {
          setView(2);
          setContinueView(true);
        }
        console.log("order created");
      }
    } catch (err) {
      toggleTreatLoading(false);
    }
  };

  const onSubmitHandler = useCallback(async (values: any) => {
    const formData = {
      category: JSON?.parse(values?.category)["iconName"],
      date: values?.date,
      price: values?.price[0],
      instruction: values?.instruction,
      additionalInfo: values?.additionalInfo,
    };
    console.log("form data", formData);
    setFormData(formData);
  }, []);

  useEffect(() => {
    if (formData) {
      submitTreatOrder();
    }
  }, [formData]);

  return (
    <RightSideModal
      width={
        screenSize.dynamicWidth > 576 ? "570px" : `${screenSize.dynamicWidth}px`
      }
      open={showSportsModal}
      onClose={() => {
        fetchCurrentOrderAPI();
        setShowSportsModal(false);
        setContinueView(false);
      }}
      closeIconCenter
    >
      <Formik
        initialValues={defaultValues}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validationSchema={SendTreatSchema}
      >
        {({
          values,
          errors,
          dirty,
          isValid,
          setFieldValue,
          initialValues,
          submitForm,
        }) => {
          return !continueView ? (
            <Form>
              <Box overflowY="scroll" height="100vh">
                <Flex
                  flexDirection={
                    screenSize.dynamicWidth > 576 ? "column" : "row"
                  }
                  alignItems={
                    screenSize.dynamicWidth <= 576 ? "center" : "flex-start"
                  }
                  justifyContent={
                    screenSize.dynamicWidth > 576
                      ? "flex-start"
                      : "space-between"
                  }
                  padding={
                    screenSize.dynamicWidth > 576 ? "20px 42px" : "16px 21px"
                  }
                >
                  <Flex
                    flexDirection={"row"}
                    alignItems={"center"}
                    gridColumnGap={"10px"}
                  >
                    {screenSize.dynamicWidth <= 576 && (
                      <Box
                        cursor="pointer"
                        onClick={() => setShowSportsModal(false)}
                      >
                        <Icon name="closeIcon" fill="#050505" />
                      </Box>
                    )}
                    <Text
                      color={"#050505"}
                      fontWeight={"300"}
                      fontSize={screenSize.dynamicWidth > 576 ? "20px" : "14px"}
                      lineHeight={"140%"}
                    >
                      {" "}
                      Let's get started{" "}
                    </Text>
                  </Flex>
                  {screenSize.dynamicWidth <= 576 && (
                    <ToolTip
                      color="black"
                      bgColor="#FFFFFF"
                      boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
                      direction="top-start"
                      title={
                        <>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                              alignItems: "flex-start",

                              borderRadius: "12px",
                              padding: "20px 25px",
                            }}
                          >
                            <Flex flexDirection={"column"} gridRowGap={"12px"}>
                              <Text
                                color={"#000000"}
                                fontWeight={"500"}
                                fontSize={"12px"}
                              >
                                {" "}
                                Next steps:{" "}
                              </Text>
                              <Flex
                                flexDirection={"column"}
                                gridRowGap={"30px"}
                              >
                                <Flex
                                  flexDirection={"row"}
                                  gridColumnGap={"10px"}
                                  alignItems={"flex-start"}
                                >
                                  <Icon name="stepOne" />
                                  <Flex
                                    flexDirection={"column"}
                                    gridRowGap={"10px"}
                                  >
                                    <Text
                                      color={"#000000"}
                                      fontWeight={"500"}
                                      fontSize={"12px"}
                                    >
                                      {" "}
                                      Follow up
                                    </Text>
                                    <Text
                                      color={"#000000"}
                                      fontSize={"11px"}
                                      fontWeight={"400"}
                                    >
                                      {" "}
                                      We'll followup with the recipient via
                                      text/email (up to 3 times) to ensure they
                                      are available at the address{" "}
                                    </Text>
                                  </Flex>
                                </Flex>
                                <Flex
                                  flexDirection={"row"}
                                  gridColumnGap={"10px"}
                                  alignItems={"flex-start"}
                                >
                                  <Icon name="stepTwo" />
                                  <Flex
                                    flexDirection={"column"}
                                    gridRowGap={"10px"}
                                  >
                                    <Text
                                      color={"#000000"}
                                      fontWeight={"500"}
                                      fontSize={"12px"}
                                    >
                                      {" "}
                                      Status{" "}
                                    </Text>
                                    <Text
                                      color={"#000000"}
                                      fontSize={"11px"}
                                      fontWeight={"400"}
                                    >
                                      {" "}
                                      We'll inform you via email the order
                                      details, order status, and recipient's
                                      comments (if any){" "}
                                    </Text>
                                  </Flex>
                                </Flex>
                                <Flex
                                  flexDirection={"row"}
                                  gridColumnGap={"10px"}
                                  alignItems={"flex-start"}
                                >
                                  <Icon name="stepThree" />
                                  <Flex
                                    flexDirection={"column"}
                                    gridRowGap={"10px"}
                                  >
                                    <Text
                                      color={"#000000"}
                                      fontWeight={"500"}
                                      fontSize={"12px"}
                                    >
                                      {" "}
                                      Request change{" "}
                                    </Text>
                                    <Text
                                      color={"#000000"}
                                      fontSize={"11px"}
                                      fontWeight={"400"}
                                    >
                                      {" "}
                                      You can cancel this request within 30
                                      minutes of confirmation{" "}
                                    </Text>
                                  </Flex>
                                </Flex>
                              </Flex>
                            </Flex>
                          </Box>
                        </>
                      }
                    >
                      <Text
                        id="how.i.work"
                        color="#0094E3"
                        fontSize="12px"
                        fontWeight="400"
                      />
                    </ToolTip>
                  )}
                </Flex>
                <Box
                  border={"1px solid rgba(0, 0, 0, 0.1)"}
                  width="100%"
                  // marginBottom={"32px"}
                >
                  {" "}
                </Box>
                <Flex
                  flexDirection={"column"}
                  gridRowGap={screenSize.dynamicWidth > 576 ? "24px" : "8px"}
                  padding={
                    screenSize.dynamicWidth > 576 ? "20px 42px" : "16px 21px"
                  }
                >
                  <Text color={"#57585C"} fontWeight={"400"} fontSize={"16px"}>
                    {" "}
                    Hello {name}!{" "}
                  </Text>
                  <Flex
                    flexDirection={"row"}
                    gridColumnGap={"16px"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                  >
                    <Avatar
                      objectFit="cover"
                      imageUrl={SendTreatDefaultImage}
                      height={"64px"}
                      width={"64px"}
                    />
                    <Flex
                      flexDirection={"column"}
                      height={"100%"}
                      justifyContent={"space-between"}
                      gridRowGap={"10px"}
                    >
                      <Text
                        color={"#050505"}
                        fontSize={"20px"}
                        fontWeight={"300"}
                      >
                        {" "}
                        I’m Eva,{" "}
                      </Text>
                      <Text
                        color={"#57585C"}
                        fontSize={"16px"}
                        fontWeight={"400"}
                      >
                        {" "}
                        Your assistant at Eva Concierge{" "}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex
                  flexDirection={"column"}
                  gridRowGap={"16px"}
                  padding={
                    screenSize.dynamicWidth > 576 ? "20px 42px" : "16px 21px"
                  }
                  paddingTop={"32px"}
                >
                  <Flex flexDirection={"column"}>
                    <Text
                      color={"#000000"}
                      fontWeight={"300"}
                      fontSize={"14px"}
                      lineHeight={"140%"}
                    >
                      {" "}
                      What would you like to send?{" "}
                    </Text>
                    <Box width={ screenSize.dynamicWidth > 576 ? "270px" : ""}>
                      <SelectIconComponent
                        name="category"
                        iconOptions={iconCategoryOptions}
                      />
                    </Box>
                    <Flex alignItems={"center"}>
                      <DeliverIcon />
                      <Text
                        mt="3px"
                        ml="10px"
                        color="#57585C"
                        variant="inputHint"
                        fontWeight={300}
                      >
                        Delivered within{" "}
                        {JSON?.parse(values?.category)["deliveryEstimate"]} days
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex flexDirection={"column"}>
                    <Text
                      color={"#000000"}
                      fontWeight={"300"}
                      fontSize={"14px"}
                      lineHeight={"140%"}
                      marginBottom="8px"
                    >
                      When would you like to schedule?
                    </Text>
                    <Box width={270}>
                      <FormikDatePicker
                        placeholder="Select date"
                        name="date"
                        mindate={dayjs(new Date()).add(
                          JSON?.parse(values?.category)["deliveryEstimate"],
                          "day"
                        )}
                      />
                    </Box>
                  </Flex>
                  <Box width={"270px"}>
                    <SelectComponent
                      // disabled
                      variantType="primary"
                      name="price"
                      label="treat.price.label"
                      placeholder="treat.price.label"
                      options={[
                        "$25",
                        "$30",
                        "$40",
                        "$50",
                        "$75",
                        "$100",
                        "$150",
                        "$200",
                        "$250",
                        "$500",
                        "$750",
                        "$1000",
                      ]}
                      width={"100%"}
                    />
                  </Box>
                  { screenSize.dynamicWidth > 576 ? (
 <FormikInput
 label="treat.instructions.label"
 name="instruction"
 placeholder="treat.instructions.placeholder"
 width={"97.5%"}
 fullSize
/>
                  ) : (
<Flex flexDirection={"column"} gridRowGap={"10px"} width={"100%"} >
                      <Text variant="bodyRegular" color="#0A0A0A" > Any specific instructions you’d like to share with me? (optional) </Text>
                    <FormikTextArea
                    
                    width={ `${ screenSize.dynamicWidth - 72 }px` }
                    
                    name="instruction"
                    placeholder="treat.instructions.placeholder"
                    />
                  </Flex>
                  ) }
                 { screenSize.dynamicWidth > 576 ? (
 <FormikInput
 label="treat.info.label"
 name="additionalInfo"
 placeholder="treat.info.placeholder"
 width={"97.5%"}
 fullSize
/>
                 ) : (
                  <Flex flexDirection={"column"} gridRowGap={"10px"} width={"100%"} >
                  <Text variant="bodyRegular" color="#0A0A0A" > Describe why you are sending this surprise (optional)</Text>
<FormikTextArea
                width={ `${ screenSize.dynamicWidth - 72 }px` }
               
                name="additionalInfo"
                placeholder="treat.info.placeholder"
                />
                </Flex>
                 ) }
                 
                  <Flex
                    flexDirection={"row"}
                    gridColumnGap={"24px"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                  >
                    <Button
                       marginBottom={ screenSize.dynamicWidth <= 576 ? "10px" : "" }
                      fullSize={screenSize.dynamicWidth > 576 ? false : true}
                      loading={treatLoading}
                      disabled={!isValid}
                      variant="primary"
                      onClick={submitForm}
                      type="button"
                    >
                      {" "}
                      {/* Continue{" "} */}
                      {screenSize.dynamicWidth > 576 ? "Continue" : "Confirm"}
                    </Button>
                    {screenSize.dynamicWidth > 576 && (
                      <ToolTip
                        color="black"
                        bgColor="#FFFFFF"
                        boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
                        direction="top-start"
                        title={
                          <>
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                alignItems: "flex-start",

                                borderRadius: "12px",
                                padding: "20px 25px",
                              }}
                            >
                              <Flex
                                flexDirection={"column"}
                                gridRowGap={"12px"}
                              >
                                <Text
                                  color={"#000000"}
                                  fontWeight={"500"}
                                  fontSize={"12px"}
                                >
                                  {" "}
                                  Next steps:{" "}
                                </Text>
                                <Flex
                                  flexDirection={"column"}
                                  gridRowGap={"30px"}
                                >
                                  <Flex
                                    flexDirection={"row"}
                                    gridColumnGap={"10px"}
                                    alignItems={"flex-start"}
                                  >
                                    <Icon name="stepOne" />
                                    <Flex
                                      flexDirection={"column"}
                                      gridRowGap={"10px"}
                                    >
                                      <Text
                                        color={"#000000"}
                                        fontWeight={"500"}
                                        fontSize={"12px"}
                                      >
                                        {" "}
                                        Follow up
                                      </Text>
                                      <Text
                                        color={"#000000"}
                                        fontSize={"11px"}
                                        fontWeight={"400"}
                                      >
                                        {" "}
                                        We'll followup with the recipient via
                                        text/email (up to 3 times) to ensure
                                        they are available at the address{" "}
                                      </Text>
                                    </Flex>
                                  </Flex>
                                  <Flex
                                    flexDirection={"row"}
                                    gridColumnGap={"10px"}
                                    alignItems={"flex-start"}
                                  >
                                    <Icon name="stepTwo" />
                                    <Flex
                                      flexDirection={"column"}
                                      gridRowGap={"10px"}
                                    >
                                      <Text
                                        color={"#000000"}
                                        fontWeight={"500"}
                                        fontSize={"12px"}
                                      >
                                        {" "}
                                        Status{" "}
                                      </Text>
                                      <Text
                                        color={"#000000"}
                                        fontSize={"11px"}
                                        fontWeight={"400"}
                                      >
                                        {" "}
                                        We'll inform you via email the order
                                        details, order status, and recipient's
                                        comments (if any){" "}
                                      </Text>
                                    </Flex>
                                  </Flex>
                                  <Flex
                                    flexDirection={"row"}
                                    gridColumnGap={"10px"}
                                    alignItems={"flex-start"}
                                  >
                                    <Icon name="stepThree" />
                                    <Flex
                                      flexDirection={"column"}
                                      gridRowGap={"10px"}
                                    >
                                      <Text
                                        color={"#000000"}
                                        fontWeight={"500"}
                                        fontSize={"12px"}
                                      >
                                        {" "}
                                        Request change{" "}
                                      </Text>
                                      <Text
                                        color={"#000000"}
                                        fontSize={"11px"}
                                        fontWeight={"400"}
                                      >
                                        {" "}
                                        You can cancel this request within 30
                                        minutes of confirmation{" "}
                                      </Text>
                                    </Flex>
                                  </Flex>
                                </Flex>
                              </Flex>
                            </Box>
                          </>
                        }
                      >
                        <Text
                          id="how.i.work"
                          color="#0094E3"
                          fontSize="12px"
                          fontWeight="400"
                        />
                      </ToolTip>
                    )}
                  </Flex>
                </Flex>
              </Box>
            </Form>
          ) : (
            <Box overflowY="scroll" height="100vh">
              {view == 1 ? (
                <Flex
                  flexDirection={"column"}
                  gridRowGap={screenSize.dynamicWidth > 576 ? "52px" : "40px"}
                  paddingLeft={screenSize.dynamicWidth > 576 ? "107px" : "16px"}
                  paddingRight={
                    screenSize.dynamicWidth > 576 ? "107px" : "16px"
                  }
                  paddingTop={"60px"}
                >
                  <Flex flexDirection={"column"} gridRowGap={"8px"}>
                    <Icon name="orderTick" />
                    <Text
                      color={"#24B300"}
                      fontSize={screenSize.dynamicWidth > 576 ? "24px" : "16px"}
                      fontWeight={"400"}
                    >
                      {" "}
                      Great!{" "}
                    </Text>
                    <Text
                      color={"#050505"}
                      fontSize={screenSize.dynamicWidth > 576 ? "14px" : "12px"}
                      fontWeight={"400"}
                      lineHeight={
                        screenSize.dynamicWidth > 576 ? "140%" : "17px"
                      }
                    >
                      {" "}
                      We have received your request successfully. Eva concierge
                      team will determine the best possible option within your
                      approved budget and get it delivered.{" "}
                    </Text>
                  </Flex>
                  <Flex
                    flexDirection={"column"}
                    gridRowGap={"20px"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                  >
                    <Text
                      color={"#000000"}
                      fontWeight={"500"}
                      fontSize={"16px"}
                    >
                      {" "}
                      Next steps:{" "}
                    </Text>
                    <Flex
                      flexDirection={"row"}
                      gridColumnGap={"10px"}
                      alignItems={"flex-start"}
                    >
                      <Icon name="stepOne" />
                      <Flex flexDirection={"column"} gridRowGap={"10px"}>
                        <Text
                          color={"#000000"}
                          fontWeight={"500"}
                          fontSize={"12px"}
                        >
                          {" "}
                          Follow up
                        </Text>
                        <Text
                          color={"#000000"}
                          fontSize={"14px"}
                          fontWeight={"400"}
                        >
                          {" "}
                          We'll followup with the recipient via text/email (up
                          to 3 times) to ensure they are available at the
                          address{" "}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex
                      flexDirection={"row"}
                      gridColumnGap={"10px"}
                      alignItems={"flex-start"}
                    >
                      <Icon name="stepTwo" />
                      <Flex flexDirection={"column"} gridRowGap={"10px"}>
                        <Text
                          color={"#000000"}
                          fontWeight={"500"}
                          fontSize={"12px"}
                        >
                          {" "}
                          Status{" "}
                        </Text>
                        <Text
                          color={"#000000"}
                          fontSize={"14px"}
                          fontWeight={"400"}
                        >
                          {" "}
                          We'll inform you via email the order details, order
                          status, and recipient's comments (if any){" "}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex
                      flexDirection={"row"}
                      gridColumnGap={"10px"}
                      alignItems={"flex-start"}
                    >
                      <Icon name="stepThree" />
                      <Flex flexDirection={"column"} gridRowGap={"10px"}>
                        <Text
                          color={"#000000"}
                          fontWeight={"500"}
                          fontSize={"12px"}
                        >
                          {" "}
                          Request change{" "}
                        </Text>
                        <Text
                          color={"#000000"}
                          fontSize={"14px"}
                          fontWeight={"400"}
                        >
                          {" "}
                          You can cancel this request within 30 minutes of
                          confirmation{" "}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex
                      flexDirection={
                        screenSize.dynamicWidth > 576 ? "row" : "column"
                      }
                      gridColumnGap={"24px"}
                      alignItems={"center"}
                      gridRowGap={"16px"}
                      width={screenSize.dynamicWidth <= 576 ? "100%" : "auto"}
                    >
                      {screenSize.dynamicWidth <= 576 && (
                        <Flex
                          flexDirection={"row"}
                          alignItems={"center"}
                          width={"100%"}
                          justifyContent={"center"}
                          gridColumnGap={"10px"}
                        >
                          <Text
                            color={"#DD015B"}
                            cursor="pointer"
                            // ml="24px"
                            // mr="10px"
                            onClick={() => {
                              setContinueView(false);
                              setShowSportsModal(false);
                            }}
                          >
                            Cancel
                          </Text>
                          <Flex gridGap={"4px"}>
                            <TimerIcon />
                            <Timer />
                          </Flex>
                        </Flex>
                      )}
                      <Button
                        variant="primary"
                        fullSize={screenSize.dynamicWidth > 576 ? false : true}
                        onClick={() => {
                          fetchCurrentOrderAPI();
                          setShowSportsModal(false);
                          setContinueView(false);
                        }}
                      >
                        {" "}
                        Ok, got it{" "}
                      </Button>

                      {screenSize.dynamicWidth > 576 && (
                        <>
                          <Flex
                            flexDirection={"row"}
                            alignItems={"center"}
                            gridColumnGap={"16px"}
                          >
                            <Text
                              color={"#DD015B"}
                              cursor="pointer"
                              // ml="24px"
                              // mr="10px"
                              onClick={() => {
                                cancelOrder("fanGear");
                                setContinueView(false);
                                setShowSportsModal(false);
                              }}
                            >
                              Cancel
                            </Text>
                            <Flex gridGap={"4px"}>
                              <TimerIcon />
                              <Timer />
                            </Flex>
                          </Flex>
                        </>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  flexDirection={"column"}
                  gridRowGap={screenSize.dynamicWidth > 576 ? "52px" : "40px"}
                  paddingLeft={screenSize.dynamicWidth > 576 ? "107px" : "16px"}
                  paddingRight={
                    screenSize.dynamicWidth > 576 ? "107px" : "16px"
                  }
                  paddingTop={"60px"}
                >
                  <Flex flexDirection={"column"} gridRowGap={"8px"}>
                    <Icon name="orderTick" />
                    <Text
                      color={"#24B300"}
                      fontSize={screenSize.dynamicWidth > 576 ? "24px" : "16px"}
                      fontWeight={"400"}
                    >
                      {" "}
                      Great!{" "}
                    </Text>
                    <Text
                      color={"#050505"}
                      fontSize={screenSize.dynamicWidth > 576 ? "14px" : "12px"}
                      fontWeight={"400"}
                      lineHeight={
                        screenSize.dynamicWidth > 576 ? "140%" : "17px"
                      }
                    >
                      {" "}
                      We have received your request successfully. Eva concierge
                      team will determine the best possible option within your
                      approved budget and get it delivered.{" "}
                    </Text>
                  </Flex>
                  <Flex
                    flexDirection={"column"}
                    gridRowGap={"20px"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                  >
                    <Text
                      color={"#000000"}
                      fontWeight={"500"}
                      fontSize={"16px"}
                    >
                      {" "}
                      Next steps:{" "}
                    </Text>
                    <Flex
                      flexDirection={"row"}
                      gridColumnGap={"10px"}
                      alignItems={"flex-start"}
                    >
                      <Icon name="stepOne" />
                      <Text
                        color={"#000000"}
                        fontSize={"14px"}
                        fontWeight={"400"}
                      >
                        {" "}
                        AI will curate a gift box based on their preferences{" "}
                      </Text>
                    </Flex>
                    <Flex
                      flexDirection={"row"}
                      gridColumnGap={"10px"}
                      alignItems={"flex-start"}
                    >
                      <Icon name="stepTwo" />
                      <Text
                        color={"#000000"}
                        fontSize={"14px"}
                        fontWeight={"400"}
                      >
                        {" "}
                        We’ll send you emails with the order details, order
                        status and recipient comments (if any){" "}
                      </Text>
                    </Flex>
                    <Flex
                      flexDirection={"row"}
                      gridColumnGap={"10px"}
                      alignItems={"flex-start"}
                    >
                      <Icon name="stepThree" />
                      <Text
                        color={"#000000"}
                        fontSize={"14px"}
                        fontWeight={"400"}
                      >
                        {" "}
                        You can cancel this request within 30 minutes of
                        confirmation
                      </Text>
                    </Flex>
                    <Flex
                      flexDirection={
                        screenSize.dynamicWidth > 576 ? "row" : "column"
                      }
                      gridColumnGap={"24px"}
                      alignItems={"center"}
                      gridRowGap={"16px"}
                      width={screenSize.dynamicWidth <= 576 ? "100%" : "auto"}
                    >
                      {screenSize.dynamicWidth <= 576 && (
                        <Flex
                          flexDirection={"row"}
                          alignItems={"center"}
                          width={"100%"}
                          justifyContent={"center"}
                          gridColumnGap={"10px"}
                        >
                          <Text
                            color={"#DD015B"}
                            cursor="pointer"
                            // ml="24px"
                            // mr="10px"
                            onClick={() => {
                              setContinueView(false);
                              setShowSportsModal(false);
                            }}
                          >
                            Cancel
                          </Text>
                          <Flex gridGap={"4px"}>
                            <TimerIcon />
                            <Timer />
                          </Flex>
                        </Flex>
                      )}
                      <Button
                        variant="primary"
                        fullSize={screenSize.dynamicWidth > 576 ? false : true}
                        onClick={() => {
                          fetchCurrentOrderAPI();
                          setShowSportsModal(false);
                          setContinueView(false);
                        }}
                      >
                        {" "}
                        Ok, got it{" "}
                      </Button>

                      {screenSize.dynamicWidth > 576 && (
                        <>
                          <Flex
                            flexDirection={"row"}
                            alignItems={"center"}
                            gridColumnGap={"16px"}
                          >
                            <Text
                              color={"#DD015B"}
                              cursor="pointer"
                              // ml="24px"
                              // mr="10px"
                              onClick={() => {
                                setContinueView(false);
                                setShowSportsModal(false);
                              }}
                            >
                              Cancel
                            </Text>
                            <Flex gridGap={"4px"}>
                              <TimerIcon />
                              <Timer />
                            </Flex>
                          </Flex>
                        </>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </Box>
          );
        }}
      </Formik>
    </RightSideModal>
  );
};
export default SendSportsModal;
