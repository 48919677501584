import React from "react";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import {
  ModalWrapper,
  modalWrapperProps,
} from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import DisableEye from "./DisableEye";

export interface AeReturnStakeModalProps extends modalWrapperProps {
  userData: any;
  callbackMethod: any;
  mobile?: boolean;
}

export const AeReturnStakeModal: React.FC<AeReturnStakeModalProps> = ({
  width,
  height,
  hideModal,
  setHideModal,
  userData,
  callbackMethod,
  mobile,
}) => {
  return !mobile ? (
    <ModalWrapper
      width={width}
      height={height}
      hideModal={hideModal}
      setHideModal={setHideModal}
      bgcolor={"rgba(0, 0, 0, 0.8)"}
      padding="48px"
    >
      {!userData?.isNudgesEnabled ? (
        <Grid textAlign={"start"}>
          <Flex fontSize={"24px"} fontWeight={300} mb="16px">
            Are you sure you want to{" "}
            {`${userData?.isNudgesEnabled ? "disable" : "enable"}`} nudges for{" "}
            <Text as="span" fontWeight={500} ml="8px">
              {" "}
              {userData?.identity?.firstname}
            </Text>
            ?
          </Flex>
          <Grid ml="-20px">
            {userData?.isNudgesEnabled ? (
              <ul>
                <li>
                  <Text
                    mb="16px"
                    fontWeight={400}
                    fontSize={"16px"}
                    lineHeight={"25.6px"}
                  >
                    You’ll not receive new nudge recommendations from tomorrow
                    onwards
                  </Text>
                </li>
                <li>
                  <Text
                    mb="16px"
                    fontWeight={400}
                    fontSize={"16px"}
                    lineHeight={"25.6px"}
                  >
                    All the past nudge recommendations will still be visible in
                    the Nudge feed
                  </Text>
                </li>
                <li>
                  <Text
                    mb="16px"
                    fontWeight={400}
                    fontSize={"16px"}
                    lineHeight={"25.6px"}
                  >
                    Previous nudge activity for this user will still be visible
                    in the “Activity History” page
                  </Text>
                </li>
              </ul>
            ) : (
              <Text
                ml="20px"
                mb="10px"
                fontWeight={400}
                fontSize={"16px"}
                lineHeight={"25.6px"}
              >
                Great! You can view the nudges for this user in 30 mins.
              </Text>
            )}
            <Flex>
              <Flex
                ml="20px"
                mt="10px"
                background={"#DD015B"}
                color={"#fff"}
                fontSize={"16px"}
                fontWeight={500}
                p="12px 45px"
                borderRadius={"12px"}
                width={"max-content"}
                cursor="pointer"
                onClick={() => callbackMethod()}
              >
                Yes, {`${userData?.isNudgesEnabled ? "disable" : "enable"}`} it
              </Flex>
              <Flex
                ml="16px"
                mt="10px"
                background={"#fff"}
                color={"#DD015B"}
                fontSize={"16px"}
                fontWeight={500}
                p="12px 45px"
                borderRadius={"12px"}
                width={"max-content"}
                cursor="pointer"
                onClick={() => setHideModal()}
              >
                Cancel
              </Flex>
            </Flex>
          </Grid>
        </Grid>
      ) : (
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          // padding={"24px"}
        >
          <DisableEye />
          <Text mt="24px" fontSize={"24px"} fontWeight={300}>
            {" "}
            Are you sure you want to disable
          </Text>
          <Text fontSize={"24px"} fontWeight={300}>
            the nudges for{" "}
            <Text as="span" fontWeight={500}>
              {" "}
              {userData?.identity?.firstname}
            </Text>
            ?
          </Text>
          <Text
            mt="16px"
            color="#57585C"
            fontSize={"16px"}
            fontWeight={300}
            mb="24px"
          >
            All the nudge recommendations will be removed immediately.
          </Text>
          <Flex>
            <Flex
              background={"#DD015B"}
              color={"#fff"}
              fontSize={"16px"}
              fontWeight={500}
              p="10px 32px"
              justifyContent={"center"}
              alignItems={"center"}
              borderRadius={"12px"}
              width={"max-content"}
              cursor="pointer"
              onClick={() => callbackMethod()}
            >
              Yes, {`${userData?.isNudgesEnabled ? "disable" : "enable"}`} it
            </Flex>
            <Flex
              ml="16px"
              border="1px solid #0094E3"
              background={"#fff"}
              color={"#0094E3"}
              fontSize={"16px"}
              fontWeight={500}
              p="10px 32px"
              justifyContent={"center"}
              alignItems={"center"}
              borderRadius={"12px"}
              width={"max-content"}
              cursor="pointer"
              onClick={() => setHideModal()}
            >
              Cancel
            </Flex>
          </Flex>
        </Flex>
      )}
    </ModalWrapper>
  ) : (
    <>
      <div
        style={{
          position: "fixed",
          display: !hideModal ? "block" : "none",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,0.8)",
          zIndex: 1,
          height: "100vh",
          width: "100vw",
        }}
      ></div>
      <div
        style={{
          display: !hideModal ? "block" : "none",
          position: "fixed",
          bottom: 0,
          right: 0,
          height: "auto",
          width: "100%",
          zIndex: 3,
          background: "#fff",
          padding: "24px",
          boxSizing: "border-box",
          borderRadius: "12px 12px 0px 0px",
        }}
      >
        <Grid textAlign={"start"}>
          <Flex fontSize={"16px"} fontWeight={300} mb="16px">
            Are you sure you want to{" "}
            {`${userData?.isNudgesEnabled ? "disable" : "enable"}`} nudges for{" "}
            {userData?.identity?.firstname} ?
          </Flex>
          <Grid ml="-20px">
            {userData?.isNudgesEnabled ? (
              <ul>
                <li>
                  <Text
                    mb="16px"
                    fontWeight={400}
                    fontSize={"12px"}
                    lineHeight={"140%"}
                  >
                    You’ll not receive new nudge recommendations from tomorrow
                    onwards
                  </Text>
                </li>
                <li>
                  <Text
                    mb="16px"
                    fontWeight={400}
                    fontSize={"12px"}
                    lineHeight={"140%"}
                  >
                    All the past nudge recommendations will still be visible in
                    the Nudge feed
                  </Text>
                </li>
                <li>
                  <Text
                    mb="16px"
                    fontWeight={400}
                    fontSize={"12px"}
                    lineHeight={"140%"}
                  >
                    Previous nudge activity for this user will still be visible
                    in the “Activity History” page
                  </Text>
                </li>
              </ul>
            ) : (
              <Text
                ml="20px"
                mb="10px"
                fontWeight={400}
                fontSize={"12px"}
                lineHeight={"140%"}
              >
                Great! You can view the nudges for this user in 30 mins.
              </Text>
            )}
            <Flex>
              <Flex
                ml="20px"
                mt="10px"
                background={"#DD015B"}
                color={"#fff"}
                fontSize={"12px"}
                fontWeight={500}
                p="12px 45px"
                borderRadius={"12px"}
                width={"max-content"}
                cursor="pointer"
                onClick={() => callbackMethod()}
              >
                Yes, {`${userData?.isNudgesEnabled ? "disable" : "enable"}`} it
              </Flex>
              <Flex
                ml="16px"
                mt="10px"
                background={"#fff"}
                color={"#DD015B"}
                fontSize={"12px"}
                fontWeight={500}
                p="12px 45px"
                borderRadius={"12px"}
                width={"max-content"}
                cursor="pointer"
                onClick={() => setHideModal()}
              >
                Cancel
              </Flex>
            </Flex>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
