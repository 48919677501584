import { useState, useEffect } from "react";
import { AeReturnJourneyLayout } from "@evabot/eva-react-core-library/dist/templates/AeReturnJourneyLayout/AeReturnJourneyLayout";
import { AeSidebarSection } from "components/AeSidebar/AeSidebarSection";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { AeSidebarSectionCollapse } from "components/AeSidebar/AeSidebarSectionCollapse";
// import NudgeFeeds from "../AEReturnDashboard/NudgeFeeds";
import StakeholderProfile from "./Stakeholderprofile";
import StakeholderProfileMobileView from "./StakeholderProfileMobileView";
import ProfileModalData from "../AEReturnDashboard/ProfileModalData";
import SearchModal from "../AEReturnDashboard/SearchModal/SearchModal";
import { useAppSelector, useAppDispatch } from "hooks";
import { setGlobalStateRole } from "store/globalStateReducer";

function AEReturnStakeholderDashboard() {
  const dispatch = useAppDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [searchValues, setSearchValues] = useState({
    stakeholderName: "",
    opportunityName: "",
    email: "",
  });
  const nudges = useAppSelector((state) => state.globalState.recommendedNudges);
  const profileData = useAppSelector((state) => state.profileState.profileData);

  useEffect(() => {
    dispatch(setGlobalStateRole({ journeyName: "ae", journeyPath: "1" }));
  }, []);
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  return (
    <>
      <AeReturnJourneyLayout
        as="div"
        left={20}
        right={82}
        leftChildren={<AeSidebarSection activeTab="home" />}
        leftCollapsedChildren={<AeSidebarSectionCollapse activeTab="home" />}
        rightChildren={
          screenSize.dynamicWidth > 576 ? (
            <StakeholderProfile searchQuery={searchQuery} />
          ) : (
            <StakeholderProfileMobileView searchQuery={searchQuery} />
          )
        }
        // headerSearch
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        profileModalChild={<ProfileModalData />}
        searchModalChild={
          <SearchModal
            setOpenSearchModal={setOpenModal}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchValues={searchValues}
            setSearchValues={setSearchValues}
            nudges={nudges}
          />
        }
        openSearchModal={openModal}
        setOpenSearchModal={setOpenModal}
        imageUrl={profileData?.imageFile}
      />
    </>
  );
}

export default AEReturnStakeholderDashboard;
