import { FC, useEffect, useState } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { ToolTip } from "@evabot/eva-react-core-library/dist/molecules/ToolTip/ToolTip";
import { Tag } from "@evabot/eva-react-core-library/dist/molecules/Tag/Tag";
import BirthdayCard from "./StakeholderProfileComps/BirthdayCard";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { axiosHelper } from "utils";
import { format } from "date-fns";
import { Backdrop } from "@mui/material";
interface NameFilterViewProps {
  selectedNudge: any;
  filteredNudges: any;
  setSelectedNudge: any;
  getNudgeBtn: any;
  nudgeCategoryMap: any;
  thumsUpIds: any;
  updateNudgeFeedback: any;
  thumsDownIds: any;
  dispatch: any;
  setSelectedNudgeForUnlike: any;
  setHide: any;
  hide: any;
  undoNudgeAction: any;
  setShowTreatModal:any;
  recommendedNudges:any;
  getNudgeIcon: any;
  setSearchTerm:any;
  setSearchType:any;
  setFilteredNudges:any;
  setFilter:any;
  setSearch:any;
  loadBirthdayEmail : any;
}

const NameFilterView: FC<NameFilterViewProps> = ({
  selectedNudge,
  filteredNudges,
  setSelectedNudge,
  getNudgeBtn,
  nudgeCategoryMap,
  thumsUpIds,
  updateNudgeFeedback,
  thumsDownIds,
  dispatch,
  setSelectedNudgeForUnlike,
  setHide,
  hide,
  undoNudgeAction,
  setShowTreatModal ,
  recommendedNudges ,
  getNudgeIcon ,
  setSearchTerm ,
  setSearchType ,
  setFilteredNudges ,
  setFilter ,
  setSearch ,
  loadBirthdayEmail
}) => {
  const [weather, setWeather] = useState([]);
  const [userInfo , setUserInfo] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const getHeadlineStr = (nudge) => {
    let text = nudge?.nudgeHeadline;
    let substrings = nudge?.recHighlight;
    let result = [text];

 substrings.forEach(substring => {
 const temp = [];

 result.forEach(sentence => {
   const index = sentence.indexOf(substring);
   if (index !== -1) {
     if ( sentence.slice(0, index).trim() != "" ) {
       temp.push( <Text color={"#050505"} fontWeight={"300"} fontSize={"20px"} lineHeight={"140%"} display={"inline"}  > {sentence.slice(0, index)}&nbsp; </Text> );
     } 
     if ( substring.trim() != "" ) {
       temp.push( <Text color={"#050505"} fontWeight={"bold"} fontSize={"20px"} lineHeight={"140%"} fontStyle={"italic"} display={"inline"}  > {substring}&nbsp; </Text> );
     }
     if ( sentence.slice(index + substring.length).trim() != ""  ) {
       temp.push(<Text color={"#050505"} fontWeight={"300"} fontSize={"20px"} lineHeight={"140%"} display={"inline"}  > {sentence.slice(index + substring.length)}&nbsp; </Text>  );
     }
   
    
   } else {
   
     if ( sentence.trim() != "" ) {
       temp.push(<Text color={"#050505"} fontWeight={"300"} fontSize={"20px"} lineHeight={"140%"} display={"inline"}> {sentence}&nbsp;   </Text>);
     }
     

   }
 });

 result = temp;
});
return (
 <Text color={"#050505"} fontWeight={"300"} fontSize={"20px"} lineHeight={"140%"} > 
 { result.map( r => r ) }
 </Text>
)


   }

  const fetchWeatherAPI = async () => {
    try {
      const result = await axiosHelper({
        url: `salesforce/getWeather/${selectedNudge.stakeholderId}`,
      });

      if (result.data == 0) {
        // displayError('Unable to fetch Weather!')
      } else {
        setWeather(result.data);
      }
    } catch (err) {
      // displayError('Error while fetching weather details.')
    }
  };
  const fetchstakeholderinfoAPI = async () => {
    try {
      const result = await axiosHelper({
        url: `salesforce/getStakeholderInfoForStakeholderProfilePage/${selectedNudge.stakeholderId}`,
      });
      
      setUserInfo(result?.data);

    } catch (err) {
      // displayError('Error while fetching weather details.')
    }
  }

  useEffect(  () => {
    console.log("selectedNudge.stakeholderId", selectedNudge.stakeholderId);
    if (selectedNudge.stakeholderId) {
      setShowBackDrop(true);
      Promise.all([fetchWeatherAPI(),fetchstakeholderinfoAPI()]).then( result => {
        setShowBackDrop(false);
       } );
      // fetchWeatherAPI();
      // fetchstakeholderinfoAPI();
    }
  }, [selectedNudge]);

  return (
    <>
    <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
        open={showBackDrop}
      >
        <Icon name="loadingIcon" />
      </Backdrop>
      <Box width={"100%"}>
        <Box
          style={{ boxSizing: "border-box" }}
          background="#FFFFFF"
          borderRadius="12px"
          padding="32px 29px"
          width="100%"
        >
          <Flex
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Flex
              width={"70%"}
              flexDirection={"row"}
              gridColumnGap={"32px"}
              alignItems={"flex-start"}
            >
              <Flex
                flexDirection={"row"}
                gridColumnGap={"16px"}
                alignItems={"flex-start"}
                paddingRight={"20px"}
                borderRight={"1px solid rgba(0, 0, 0, 0.1)"}
              >
                <Box
                  borderRadius="36px"
                  background="#E6F6FF"
                  height="62px"
                  width="62px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text fontSize="15px" fontWeight="400">
                    {selectedNudge?.stakeholderName
                      .split(" ")
                      .map((t) => t[0].toUpperCase())
                      .join("")}
                  </Text>
                </Box>
                <Text color={"#050505"} fontSize={"20px"} fontWeight={"400"}>
                  {" "}
                  {selectedNudge?.stakeholderName}{" "}
                </Text>
              </Flex>
              <Flex flexDirection={"column"} gridRowGap={"4px"}>
                <Flex
                  flexDirection={"row"}
                  alignItems={"center"}
                  gridColumnGap={"12px"}
                >
                  <Icon name="stakeholderMail" fill={"black"} />
                  <Text color={"#57585C"} fontSize={"16px"} fontWeight={"400"}>
                    {" "}
                    Email{" "}
                  </Text>
                </Flex>
                <Text color={"#050505"} fontWeight={"400"} fontSize={"14px"}>
                  {" "}
                  {userInfo && userInfo?.email}{" "}
                </Text>
              </Flex>
              <Flex flexDirection={"column"} gridRowGap={"4px"}>
                <Flex
                  flexDirection={"row"}
                  alignItems={"center"}
                  gridColumnGap={"12px"}
                >
                  <Icon name="stakeholderLocation" fill={"black"} />
                  <Text color={"#57585C"} fontSize={"16px"} fontWeight={"400"}>
                    {" "}
                    Location{" "}
                  </Text>
                </Flex>
                <Text
                  color={"#050505"}
                  fontWeight={"400"}
                  fontSize={"14px"}
                  width={"204px"}
                >
                  {" "}
                  {/* {selectedNudge?.payload?.address}{" "} */}
                  { userInfo && userInfo?.address && `${userInfo?.address?.city},${userInfo?.address?.state},${userInfo?.address?.country}` }
                </Text>
              </Flex>
            </Flex>
            <Flex
              width={"27%"}
              flexDirection={"row"}
              alignItems={"center"}
              gridColumnGap={"8px"}
            >
              <Button variant="primary" onClick={ () => setShowTreatModal(true) } > Send treat/gift </Button>
              {/* <Button variant="primary"> Send a fan gear </Button> */}
            </Flex>
          </Flex>
        </Box>
      </Box>

      <Flex flexDirection={"column"} marginTop={"8px"} gridRowGap={"16px"}>
        <Box width={"100%"}>
          <Box
            style={{ boxSizing: "border-box" }}
            background="#FFFFFF"
            borderRadius="12px"
            width="100%"
          >
            <Flex
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              padding={"25px"}
              borderBottom={"1px solid #E4E4E4"}
            >
              <Flex
                flexDirection={"row"}
                gridColumnGap={"12px"}
                alignItems={"center"}
              >
                <Icon name="stakeholderThermometer" fill={"#000000"} />
                <Text color={"#111827"} fontWeight={"500"} fontSize={"16px"}>
                  {" "}
                  Local weather{" "}
                </Text>
                <Text color={"#57585C"} fontSize={"14px"} fontWeight={"400"}>
                  {" "}
                  Next 7 days forecast{" "}
                </Text>
              </Flex>
              <Flex
                flexDirection={"row"}
                gridColumnGap={"12px"}
                alignItems={"center"}
              >
                <Text color={"#111827"} fontWeight={"500"} fontSize={"14px"}>
                  {" "}
                  Now{" "}
                </Text>
                <Icon name="stakeholderCloudy" fill={"#050505"} />
                <Text
                  color={"#050505"}
                  fontWeight={"400"}
                  fontSize={"12px"}
                  lineHeight={"15px"}
                >
                  {weather && weather.length>0 && ((parseFloat(weather[0].temp.max)+parseFloat(weather[0].temp.min))/2).toFixed(1) + " "}
                  <Text
                    display={"inline-block"}
                    color={"#57585C"}
                    lineHeight={"15px"}
                    fontWeight={"400"}
                    fontSize={"14px"}
                  >
                    {weather && weather.length>0 && weather[0].weather[0].description}
                    {" "}
                  </Text>{" "}
                </Text>
              </Flex>
            </Flex>
            <Flex
              flexDirection={"row"}
              alignItems={"center"}
              padding={"25px"}
              justifyContent={"space-between"}
            >
              {weather.map((entry, id) => {
                if (id <= 6 && id>0) {
                  return (
                    <Flex
                      flexDirection={"row"}
                      alignItems={"center"}
                      gridColumnGap={"8px"}
                      key={id}
                    >
                      <Text
                        color={"#111827"}
                        fontWeight={"500"}
                        fontSize={"14px"}
                        lineHeight={"140%"}
                      >
                        {" "}
                        {format(entry.dt*1000, 'iii')+ " "}
                      </Text>
                      <Icon name="stakeholderCloudy" fill={"#050505"} />
                      <Text
                        color={"#050505"}
                        fontWeight={"400"}
                        fontSize={"12px"}
                        lineHeight={"15px"}
                      >
                        {" "}
                        {entry.temp.max}°{" "}
                      </Text>
                      <Text
                        color={"#6B7280"}
                        lineHeight={"15px"}
                        fontSize={"12px"}
                        fontWeight={"400"}
                      >
                        {" "}
                        {entry.temp.min}°{" "}
                      </Text>
                    </Flex>
                  );
                } else return <></>;
              })}
            </Flex>
          </Box>
        </Box>
        {/* <BirthdayCard setShowTreatModal={setShowTreatModal} loadEmail={ (setload) => loadBirthdayEmail(selectedNudge?.nudgeHeadlinePayload?.name , setload) } name={ selectedNudge?.nudgeHeadlinePayload?.name.split(" ")[0] } /> */}
        <Box width={"100%"}>
          <Box
            style={{ boxSizing: "border-box" }}
            background="#FFFFFF"
            borderRadius="12px"
            width="100%"
          >
            <Flex
              padding={"25px"}
              flexDirection={"row"}
              gridColumnGap={"16px"}
              borderBottom={"1px solid #E4E4E4"}
              alignItems={"center"}
            >
              <Icon name="stakeholderTongue" fill={"#000000"} />
              <Text color={"#111827"} fontWeight={"500"} fontSize={"16px"}>
                {" "}
                Preferences{" "}
              </Text>
            </Flex>
            <Flex
              flexDirection={"row"}
              alignItems={"center"}
              padding={"25px"}
              flexWrap={"wrap"}
              gap={"40px"}
            >
              <Flex
                flexDirection={"row"}
                gridColumnGap={"8px"}
                alignItems={"center"}
              >
                <Box
                  borderRadius={"12px"}
                  background={"#EEF1FB"}
                  padding={"14px 12px"}
                >
                  <Icon name="stakeholderCoffee" />
                </Box>
                <Text
                  color={"#050505"}
                  fontWeight={"400"}
                  fontSize={"12px"}
                  lineHeight={"140%"}
                  width={"171px"}
                >
                  {" "}
                  Coffee: Pour over, latte, cappuccino{" "}
                </Text>
              </Flex>
              <Flex
                flexDirection={"row"}
                gridColumnGap={"8px"}
                alignItems={"center"}
              >
                <Box
                  borderRadius={"12px"}
                  background={"#EEF1FB"}
                  padding={"14px 12px"}
                >
                  <Icon name="stakeholderWine" />
                </Box>
                <Text
                  color={"#050505"}
                  fontWeight={"400"}
                  fontSize={"12px"}
                  lineHeight={"140%"}
                  width={"171px"}
                >
                  {" "}
                  Alcoholic beverages: Beer, red/white wine{" "}
                </Text>
              </Flex>
              <Flex
                flexDirection={"row"}
                gridColumnGap={"8px"}
                alignItems={"center"}
              >
                <Box
                  borderRadius={"12px"}
                  background={"#EEF1FB"}
                  padding={"14px 12px"}
                >
                  <Icon name="stakeholderSoda" />
                </Box>
                <Text
                  color={"#050505"}
                  fontWeight={"400"}
                  fontSize={"12px"}
                  lineHeight={"140%"}
                  width={"171px"}
                >
                  {" "}
                  Nothing/Other: Milkshakes{" "}
                </Text>
              </Flex>
              <Flex
                flexDirection={"row"}
                gridColumnGap={"8px"}
                alignItems={"center"}
              >
                <Box
                  borderRadius={"12px"}
                  background={"#EEF1FB"}
                  padding={"14px 12px"}
                >
                  <Icon name="stakeholderCuisine" />
                </Box>
                <Text
                  color={"#050505"}
                  fontWeight={"400"}
                  fontSize={"12px"}
                  lineHeight={"140%"}
                  width={"171px"}
                >
                  {" "}
                  Favourite cuisines/dishes: Thai, French, Italian{" "}
                </Text>
              </Flex>
              <Flex
                flexDirection={"row"}
                gridColumnGap={"8px"}
                alignItems={"center"}
              >
                <Box
                  borderRadius={"12px"}
                  background={"#EEF1FB"}
                  padding={"14px 12px"}
                >
                  <Icon name="stakeholderDiet" />
                </Box>
                <Text
                  color={"#050505"}
                  fontWeight={"400"}
                  fontSize={"12px"}
                  lineHeight={"140%"}
                  width={"171px"}
                >
                  {" "}
                  Dietary restrictions: Vegan{" "}
                </Text>
              </Flex>
              <Flex
                flexDirection={"row"}
                gridColumnGap={"8px"}
                alignItems={"center"}
              >
                <Box
                  borderRadius={"12px"}
                  background={"#EEF1FB"}
                  padding={"14px 12px"}
                >
                  <Icon name="stakeholderMusic" />
                </Box>
                <Text
                  color={"#050505"}
                  fontWeight={"400"}
                  fontSize={"12px"}
                  lineHeight={"140%"}
                  width={"171px"}
                >
                  {" "}
                  I can’t live without my music: Hip hop{" "}
                </Text>
              </Flex>
              <Flex
                flexDirection={"row"}
                gridColumnGap={"8px"}
                alignItems={"center"}
              >
                <Box
                  borderRadius={"12px"}
                  background={"#EEF1FB"}
                  padding={"14px 12px"}
                >
                  <Icon name="stakeholderSports" />
                </Box>
                <Text
                  color={"#050505"}
                  fontWeight={"400"}
                  fontSize={"12px"}
                  lineHeight={"140%"}
                  width={"171px"}
                >
                  {" "}
                  I follow sports religiously: NBA, fan of Golden warriors{" "}
                </Text>
              </Flex>
              <Flex
                flexDirection={"row"}
                gridColumnGap={"8px"}
                alignItems={"center"}
              >
                <Box
                  borderRadius={"12px"}
                  background={"#EEF1FB"}
                  padding={"14px 12px"}
                >
                  <Icon name="stakeholderBook" />
                </Box>
                <Text
                  color={"#050505"}
                  fontWeight={"400"}
                  fontSize={"12px"}
                  lineHeight={"140%"}
                  width={"171px"}
                >
                  {" "}
                  I like to read: Comic Book or Graphic Novel{" "}
                </Text>
              </Flex>
              <Flex
                flexDirection={"row"}
                gridColumnGap={"8px"}
                alignItems={"center"}
              >
                <Box
                  borderRadius={"12px"}
                  background={"#EEF1FB"}
                  padding={"14px 12px"}
                >
                  <Icon name="stakeholderMonitor" />
                </Box>
                <Text
                  color={"#050505"}
                  fontWeight={"400"}
                  fontSize={"12px"}
                  lineHeight={"140%"}
                  width={"171px"}
                >
                  {" "}
                  I like to watch TV shows and movies: Adventure and drama series{" "}
                </Text>
              </Flex>
              <Flex
                flexDirection={"row"}
                gridColumnGap={"8px"}
                alignItems={"center"}
              >
                <Box
                  borderRadius={"12px"}
                  background={"#EEF1FB"}
                  padding={"14px 12px"}
                >
                  <Icon name="stakeholderMic" />
                </Box>
                <Text
                  color={"#050505"}
                  fontWeight={"400"}
                  fontSize={"12px"}
                  lineHeight={"140%"}
                  width={"171px"}
                >
                  {" "}
                  I like to listen to podcasts: Self-improvement Podcasts{" "}
                </Text>
              </Flex>
              <Flex
                flexDirection={"row"}
                gridColumnGap={"8px"}
                alignItems={"center"}
              >
                <Box
                  borderRadius={"12px"}
                  background={"#EEF1FB"}
                  padding={"14px 12px"}
                >
                  <Icon name="stakeholderOuting" />
                </Box>
                <Text
                  color={"#050505"}
                  fontWeight={"400"}
                  fontSize={"12px"}
                  lineHeight={"140%"}
                  width={"171px"}
                >
                  {" "}
                  Outdoor activities: Gym, running, hiking, skiing{" "}
                </Text>
              </Flex>
              <Flex
                flexDirection={"row"}
                gridColumnGap={"8px"}
                alignItems={"center"}
              >
                <Box
                  borderRadius={"12px"}
                  background={"#EEF1FB"}
                  padding={"14px 12px"}
                >
                  <Icon name="stakeholderIndoor" />
                </Box>
                <Text
                  color={"#050505"}
                  fontWeight={"400"}
                  fontSize={"12px"}
                  lineHeight={"140%"}
                  width={"171px"}
                >
                  {" "}
                  Indoor activities: Meditation and self care{" "}
                </Text>
              </Flex>
              <Flex
                flexDirection={"row"}
                gridColumnGap={"8px"}
                alignItems={"center"}
              >
                <Box
                  borderRadius={"12px"}
                  background={"#EEF1FB"}
                  padding={"14px 12px"}
                >
                  <Icon name="stakeholderPet" />
                </Box>
                <Text
                  color={"#050505"}
                  fontWeight={"400"}
                  fontSize={"12px"}
                  lineHeight={"140%"}
                  width={"171px"}
                >
                  {" "}
                  Spending time with my pet: dog (Theo & Eva){" "}
                </Text>
              </Flex>
              <Flex
                flexDirection={"row"}
                gridColumnGap={"8px"}
                alignItems={"center"}
              >
                <Box
                  borderRadius={"12px"}
                  background={"#EEF1FB"}
                  padding={"14px 12px"}
                >
                  <Icon name="stakeholderKid" />
                </Box>
                <Text
                  color={"#050505"}
                  fontWeight={"400"}
                  fontSize={"12px"}
                  lineHeight={"140%"}
                  width={"171px"}
                >
                  {" "}
                  Spending time with my kid(s){" "}
                </Text>
              </Flex>
              <Flex
                flexDirection={"row"}
                gridColumnGap={"8px"}
                alignItems={"center"}
              >
                <Box
                  borderRadius={"12px"}
                  background={"#EEF1FB"}
                  padding={"14px 12px"}
                >
                  <Icon name="stakeholderHobby" />
                </Box>
                <Text
                  color={"#050505"}
                  fontWeight={"400"}
                  fontSize={"12px"}
                  lineHeight={"140%"}
                  width={"171px"}
                >
                  {" "}
                  Hobbies: Capturing photos and videos{" "}
                </Text>
              </Flex>
              
              
            </Flex>
          </Box>
        </Box>
        <Text
          color={"#050505"}
          fontWeight={"400"}
          fontSize={"20px"}
          lineHeight={"140%"}
          marginTop={"16px"}
        >
          {" "}
          Nudge feed{" "}
        </Text>

        {filteredNudges.map((nudge) => {
          return !thumsDownIds.includes(nudge?.nudgeId) ? (
        
            <Box width={"100%"}>
              <Box
                style={{ boxSizing: "border-box" }}
                background="#FFFFFF"
                borderTopLeftRadius="12px"
                borderTopRightRadius="12px"
                borderBottom="1px solid rgba(0, 0, 0, 0.1)"
                padding="25px"
                width="100%"
              >
                <Flex
                  borderBottom="1px dashed rgba(0, 0, 0, 0.1)"
                  marginBottom="10px"
                  justifyContent="space-between"
                  padding="0px 0px 20px 0px"
                  height="20px"
                  alignItems="center"
                >
                  <Flex gap="8px" alignItems="center">
                    <Box
                      borderRadius="36px"
                      background="#E6F6FF"
                      height="36px"
                      width="36px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Text fontSize="12px" fontWeight="400">
                        {nudge?.stakeholderName.split(" ")
                      .map((t) => t[0].toUpperCase())
                      .join("")}
                      </Text>
                    </Box>
                    <Text
                      color="#0094E3"
                      fontSize="14px"
                      fontWeight="400"
                      // borderRight="1px solid rgba(0, 0, 0, 0.1)"
                      paddingRight="10px"
                      cursor="pointer"
                      onClick={() => {
                        console.log("name filter");
                        let filteredNudges = recommendedNudges.filter(
                          (nd) =>
                            nd?.nudgeHeadlinePayload?.name ==
                            nudge?.stakeholderName
                        );
                        console.log("filtered Nudges", filteredNudges);

                        setSearchTerm(nudge?.stakeholderName);
                        setSearchType("name");
                        setSelectedNudge(nudge);
                        setFilteredNudges(filteredNudges);
                        setFilter(true);
                        setSearch(false);
                      }}
                    >
                      {" "}
                      {nudge?.stakeholderName}{" "}
                    </Text>
                    {/* <Text color="#57585C" fontSize="14px" fontWeight="400">
                      {" "}
                      {nudge.stakeholderDesignation}{" "}
                    </Text> */}
                  </Flex>
                 
                </Flex>
                {/* <Flex flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} > */}
                  <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"92px"} justifyContent={"space-between"} >
                  <Flex marginTop={"16px"} gridColumnGap={"16px"} alignItems={"center"} >
                    { getNudgeIcon(nudge) }
                    <Flex flexDirection={"column"} gridRowGap={"12px"}  >
                    <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"8px"} >
                           <Icon name="nudgeIndexFinger" />
                           <Text color={"#57585C"} fontWeight={"300"}  fontSize={"14px"}> { nudge?.context } </Text>
                         </Flex>
                         {/* <Text color={"#050505"} fontWeight={"300"} fontSize={"20px"} lineHeight={"140%"} > {nudge?.nudgeHeadline} </Text> */}
                         { getHeadlineStr(nudge) }
                         {nudge?.nudgeCategory == "googlePlaces" &&
                          nudge?.crawlerData?.maps_url && (
                            <Box
                              cursor="pointer"
                              onClick={() =>
                                window.open(
                                  nudge?.crawlerData?.maps_url,
                                  "_blank"
                                )
                              }
                            >
                              <Tag
                                
                                text={"Source: Google Map"}
                                color="#0094E3"
                                bgColor="#F2FBFF"
                              />
                            </Box>
                          )}
                           {nudge?.nudgeCategory == "news" &&
                          nudge?.crawlerData?.url && (
                            <Box
                              cursor="pointer"
                              onClick={() =>
                                window.open(
                                  nudge?.crawlerData?.url,
                                  "_blank"
                                )
                              }
                            >
                              <Tag
                                text={"Source: News"}
                                color="#0094E3"
                                bgColor="#F2FBFF"
                              />
                            </Box>
                          )}
                        {nudge?.nudgeCategory == "music" &&
                          nudge?.crawlerData?.link && (
                            <Box
                              cursor="pointer"
                              onClick={() =>
                                window.open(
                                  nudge?.crawlerData?.link,
                                  "_blank"
                                )
                              }
                            >
                              <Tag
                                text={"Source: Spotify"}
                                color="#0094E3"
                                bgColor="#F2FBFF"
                              />
                            </Box>
                          )}
                        {nudge?.nudgeCategory == "concertTickets" &&
                          nudge?.crawlerData?.eventTickets && (
                            <Box
                              cursor="pointer"
                              onClick={() =>
                                window.open(
                                  nudge?.crawlerData?.eventTickets,
                                  "_blank"
                                )
                              }
                            >
                              <Tag
                                text={"Source: Ticket Master"}
                                color="#0094E3"
                                bgColor="#F2FBFF"
                              />
                            </Box>
                          )}
                         {nudge?.nudgeCategory == "sports" &&(
                          <Flex flexDirection={"row"} alignItems={"center"} gridColumnGap={"4px"} >
                            {nudge?.crawlerData?.news_url && nudge?.uniqueId.indexOf('16')>-1 && (
                            <Box
                              cursor="pointer"
                              onClick={() =>
                                window.open(
                                  nudge?.crawlerData?.news_url,
                                  "_blank"
                                )
                              }
                            >
                              <Tag
                                text={"Source:News"}
                                color="#0094E3"
                                bgColor="#F2FBFF"
                              />
                            </Box>
                          )}
                          {nudge?.crawlerData?.game_id_url && nudge?.uniqueId.indexOf('17')>-1 && (
                            <Box
                              cursor="pointer"
                              onClick={() =>
                                window.open(
                                  nudge?.crawlerData?.game_id_url,
                                  "_blank"
                                )
                              }
                            >
                              <Tag
                                text={"Source: Score"}
                                color="#0094E3"
                                bgColor="#F2FBFF"
                              />
                            </Box>
                          )}

                          </Flex>
                           )
                            
                          }
                    </Flex>
                  </Flex>
                  { getNudgeBtn(nudge) }
                </Flex>
            
              </Box>

              <Box
                style={{ boxSizing: "border-box" }}
                background="#FFFFFF"
                borderBottomRightRadius="12px"
                borderBottomLeftRadius="12px"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                height="50px"
                width="100%"
                padding="10px 25px"
              >
               
                <Flex></Flex>
                { thumsUpIds.includes( nudge?.nudgeId ) ? (
                <Flex flexDirection={"row"} gridColumnGap={"8px"} alignItems={"center"} >
                 <Icon name="stakeholderDone" fill={"#1CB43D"}  />
                 <Text color={"#24B300"} fontWeight={"400"} fontSize={"12px"} > Thank you for your feedback </Text>
                 <Button variant="undo" onClick={ () => {
                undoNudgeAction(nudge?.nudgeId,"up");
               } } > Undo  </Button>
                </Flex>
                ) : (
<Flex gap="8px">
                  <Text
                    id="did.you.find.this.helpful"
                    fontSize="12px"
                    fontWeight="400"
                  />
                  <Icon
                    name="thumbsUp"
                   
                    onClick={() => {
                      setSelectedNudge(nudge);
                      updateNudgeFeedback(nudge, "up", "");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <Icon
                    name="thumbsDown"
                    
                    onClick={() => {
                      dispatch(setSelectedNudgeForUnlike(nudge));
                      setHide(!hide);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Flex>
                ) }
                
              </Box>
            </Box>
    ) : (
      <Box width={"100%"}>
         <Box
                style={{ boxSizing: "border-box" }}
                background="#FFFFFF"
                borderTopLeftRadius="12px"
                borderTopRightRadius="12px"
                borderBottom="1px solid rgba(0, 0, 0, 0.1)"
                padding="40px"
                width="100%"
              >
              <Flex flexDirection={"row"} gridColumnGap={"52px"} justifyContent={"center"} alignItems={"center"} >
               <Text color={"#9F9F9F"} lineHeight={"140%"} fontWeight={"500"} fontSize={"16px"} > We won't recommend this nudge to you again </Text>
               <Button variant="undo" onClick={ () => {
                undoNudgeAction(nudge?.nudgeId,"down");
               } } > Undo  </Button>
              </Flex>
              </Box>
        </Box>
    )
        })}
      </Flex>
    </>
  );
};
export default NameFilterView;
