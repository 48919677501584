import { AeReturnJourneyLayout } from "@evabot/eva-react-core-library/dist/templates/AeReturnJourneyLayout/AeReturnJourneyLayout";
import { AeSidebarSection } from "components/AeSidebar/AeSidebarSection";
import { AeSidebarSectionCollapse } from "components/AeSidebar/AeSidebarSectionCollapse";
import ProfileModalData from "containers/AEReturnDashboard/ProfileModalData";
import { useAppSelector } from "hooks";
import AeReturnOppoRightSection from "./AeReturnOppoRightSection";

function AeReturnOpportunities() {
  const profileData = useAppSelector((state) => state.profileState.profileData);

  return (
    <>
      <AeReturnJourneyLayout
        as="div"
        left={20}
        right={82}
        leftChildren={<AeSidebarSection activeTab="opportunities" />}
        leftCollapsedChildren={
          <AeSidebarSectionCollapse activeTab="opportunities" />
        }
        rightChildren={<AeReturnOppoRightSection />}
        profileModalChild={<ProfileModalData />}
        imageUrl={profileData?.imageFile}
      />
    </>
  );
}

export default AeReturnOpportunities;
