import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";

function ProspectProcessTableHeader() {
  return (
    <Flex
      padding={"10px 15px"}
      fontSize="12px"
      fontWeight={500}
      position="sticky"
      backgroundColor={"#F8F8F8"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Flex width="5%" margin="0 0 0 16px">
        #
      </Flex>
      <Flex width="12%">
        Tag Name
      </Flex>
      <Flex width="12%">
        User Name
      </Flex>
      <Flex width="10%">
        Status
      </Flex>
      <Flex width="7%">
        Total Queued
      </Flex>     
      <Flex width="9%">
        Total Processed
      </Flex>
      <Flex width="7%">
        Total Errors
      </Flex>
      <Flex width="10%">
        Processed On
      </Flex>
      <Flex width="10%">
        Requested On
      </Flex>
      <Flex width="18%" margin="0 16px 0 0">
        Actions
      </Flex>
    </Flex>
  );
}

export default ProspectProcessTableHeader;
