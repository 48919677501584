import * as React from 'react';

function AmerifirstLogo() {
    return (
        <svg width="60" height="13" viewBox="0 0 60 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.76248 5.04507C5.62014 5.02799 5.47781 4.99953 5.34686 4.99953C3.3086 4.99953 2.98977 6.70756 2.98977 7.47048C2.98977 8.23341 3.34846 8.71735 3.94057 8.71735C4.38466 8.71735 4.89138 8.51808 5.33547 8.14231L5.76817 5.03938L5.76248 5.04507ZM5.14759 9.44042C4.51561 9.94713 3.94057 10.1578 3.26305 10.1578C1.94217 10.1578 0.97998 9.00772 0.97998 7.368C0.97998 5.47208 2.18699 3.41675 5.70554 3.41675C6.5254 3.41675 7.10044 3.4566 7.90322 3.61602L6.99796 10.0382H5.05649L5.14189 9.43472L5.14759 9.44042Z" fill="white" />
            <path d="M10.8468 4.35584L10.8752 4.3843C11.5072 3.7808 12.1677 3.40503 12.9135 3.40503C13.7732 3.40503 14.3938 3.84912 14.5646 4.60065C15.4812 3.72386 16.1474 3.40503 16.9331 3.40503C18.3564 3.40503 19.0169 4.50956 18.8176 5.98986L18.2596 10.0436H16.3182L16.8477 6.29161C16.9786 5.37496 16.8021 4.83978 16.3296 4.83978C15.8969 4.83978 15.2934 5.22693 14.7468 5.85891L14.1604 10.0379H12.2189L12.7199 6.303C12.8509 5.31234 12.7028 4.83409 12.1563 4.83409C11.7406 4.83409 11.2396 5.16431 10.619 5.85321L10.0269 10.0322H8.08545L9.0021 3.50751H10.9436L10.8297 4.33876L10.8468 4.35584Z" fill="white" />
            <path d="M24.1123 5.933C24.2262 5.22701 23.839 4.73737 23.1786 4.73737C22.5181 4.73737 22.1139 5.11314 21.8292 5.933H24.1123ZM25.2054 9.69637C24.4027 9.99812 23.6113 10.1689 22.9337 10.1689C20.9524 10.1689 19.6885 8.92206 19.6885 6.99198C19.6885 5.0619 21.2542 3.39941 23.2241 3.39941C25.194 3.39941 26.3726 4.96511 25.9114 7.04892H21.6413C21.5388 8.11359 22.205 8.72848 23.133 8.72848C23.7365 8.72848 24.4824 8.50075 25.547 8.06804L25.1997 9.69068L25.2054 9.69637Z" fill="white" />
            <path d="M29.1967 4.48109C29.8002 3.73525 30.3012 3.40503 30.7795 3.40503C31.1666 3.40503 31.4969 3.6043 31.941 4.1224L31.0357 5.91584C30.7055 5.4262 30.4322 5.20985 30.1134 5.20985C29.6522 5.20985 29.1512 5.72796 28.9348 6.16066L28.3882 10.0379H26.4468L27.3634 3.5132H29.3049L29.1626 4.44693L29.191 4.4754L29.1967 4.48109Z" fill="white" />
            <path d="M35.0155 1.70859C35.0155 2.27224 34.5714 2.71633 34.0078 2.71633C33.4441 2.71633 33 2.27224 33 1.70859C33 1.14494 33.4441 0.706543 34.0078 0.706543C34.5714 0.706543 35.0155 1.15063 35.0155 1.70859ZM31.8955 10.0438L32.8122 3.51911H34.7536L33.837 10.0438H31.8955Z" fill="white" />
            <path d="M41.9785 10.0438H40.0655L40.9878 3.51911H42.9008L41.9785 10.0438ZM39.7353 4.98802H38.5567L37.4351 12.9019H35.4937L36.6153 4.98802H35.4937L35.71 3.5248H36.8316C36.9341 2.84728 37.0764 2.11852 37.4465 1.54348C38.2493 0.233986 39.7296 0.120117 40.1908 0.120117C40.9081 0.120117 42.1322 0.393403 42.6902 1.3556C43.009 1.90217 42.9919 2.42027 42.9919 2.75049H41.1529C41.1245 2.55122 41.022 1.81677 40.1623 1.81677C39.1432 1.81677 38.8243 2.88144 38.7674 3.5248H39.9289L39.7296 4.98802H39.7353Z" fill="white" />
            <path d="M46.0094 4.48109C46.6129 3.73525 47.1139 3.40503 47.5922 3.40503C47.9793 3.40503 48.3095 3.6043 48.7536 4.1224L47.8484 5.91584C47.5181 5.4262 47.2449 5.20985 46.9317 5.20985C46.4706 5.20985 45.9695 5.72796 45.7532 6.16066L45.2066 10.0379H43.2651L44.1875 3.5132H46.1289L45.9866 4.44693L46.0151 4.4754L46.0094 4.48109Z" fill="white" />
            <path d="M48.8732 8.11937C49.4198 8.31864 50.0347 8.58054 50.6553 8.58054C51.0424 8.58054 51.2872 8.43821 51.2872 8.22185C51.2872 7.51587 49.118 6.81557 49.118 5.52316C49.118 4.23075 50.1087 3.41089 51.5434 3.41089C52.2324 3.41089 52.8529 3.58169 53.5988 3.99731L53.4394 5.53455C52.7334 5.17586 51.8851 4.98797 51.5548 4.98797C51.2986 4.98797 51.1221 5.10184 51.1221 5.27265C51.1221 5.99002 53.2913 6.53659 53.2913 8.11937C53.2913 9.315 52.2836 10.1576 50.8488 10.1576C50.0745 10.1576 49.4141 9.98682 48.5942 9.5712L48.8675 8.11937H48.8732Z" fill="white" />
            <path d="M57.8177 3.51893L58.091 2.14111H56.1268L55.8933 3.51893H54.5725L54.3276 4.95368H55.6485L54.8799 10.0436H56.8271L57.5615 4.95368H58.9963L59.2582 3.51893H57.8177Z" fill="white" />
            <path d="M58.501 9.04174H58.6547C58.7914 9.04174 58.8995 9.05312 58.8995 9.18407C58.8995 9.31502 58.7743 9.3378 58.6376 9.3378H58.501V9.04174ZM58.3985 8.95633V9.77619H58.501V9.4232H58.5522C58.7572 9.4232 58.7914 9.47444 58.928 9.77619H59.0533C58.9223 9.47444 58.8938 9.44597 58.78 9.40612C58.8426 9.39473 58.8824 9.38904 58.9223 9.36057C58.9849 9.32072 59.0248 9.25239 59.0248 9.17269C59.0248 9.14422 59.0248 8.95064 58.7458 8.95064H58.4042L58.3985 8.95633ZM58.7116 8.67166C59.0874 8.67166 59.4006 8.97341 59.4006 9.36057C59.4006 9.74772 59.0931 10.0438 58.7116 10.0438C58.3302 10.0438 58.0284 9.73634 58.0284 9.36057C58.0284 8.9848 58.3359 8.67166 58.7116 8.67166ZM58.7116 8.58057C58.2846 8.58057 57.9316 8.92217 57.9316 9.36057C57.9316 9.79896 58.2789 10.1406 58.7116 10.1406C59.1443 10.1406 59.4916 9.78758 59.4916 9.36057C59.4916 8.93356 59.1387 8.58057 58.7116 8.58057Z" fill="white" />
        </svg>
    )
}

const AmerifirstLogoIcon = React.memo(AmerifirstLogo);
export default AmerifirstLogoIcon;