import { useEffect, useState } from "react";
import { AeReturnJourneyLayout } from "@evabot/eva-react-core-library/dist/templates/AeReturnJourneyLayout/AeReturnJourneyLayout";
import { AeSidebarSection } from "components/AeSidebar/AeSidebarSection";
import { AeSidebarSectionCollapse } from "components/AeSidebar/AeSidebarSectionCollapse";
import ProfileModalData from "containers/AEReturnDashboard/ProfileModalData";
import { useAppSelector, useAppDispatch } from "hooks";
import { setGlobalStateRole } from "store/globalStateReducer";
import ProspectReportRightSection from "./ProspectReportRightSection";
import stakeHolderTagService from "services/stakeholderTagService";
import IntegrationContent from "containers/AeReturnIntegration/AeReturnIntegrationRightSection";


function ProspectReport() {
    const dispatch = useAppDispatch();
    const profileData = useAppSelector((state) => state.profileState.profileData);
    const [openModal, setOpenModal] = useState(false);
    const [value, setValue] = useState(null);


    // useEffect(() => {
    //     dispatch(setGlobalStateRole({ journeyName: "ae", journeyPath: "1" }));
    //     localStorage.setItem("integrationRoute", "stakeholdertag");
    // }, []);



    return (
        <>
            <AeReturnJourneyLayout
                as="div"
                left={20}
                right={82}
                leftChildren={<AeSidebarSection activeTab="prospectreport" />}
                leftCollapsedChildren={
                    <AeSidebarSectionCollapse activeTab="prospectreport" />
                }
                rightChildren={
                   <ProspectReportRightSection />
                }
                profileModalChild={<ProfileModalData />}
                imageUrl={profileData?.imageFile}
                value={value}
                setValue={setValue}
                openSearchModal={openModal}
                setOpenSearchModal={setOpenModal}
                pageHeading="Prospects"
            />
        </>
    );
}

export default ProspectReport;
