import { Box } from '@evabot/eva-react-core-library/dist/atoms/Box/Box';
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import TextBoxWithLabel from './subcomponent/TextBoxWithLabel';
import { Tag } from '@evabot/eva-react-core-library/dist/molecules/Tag/Tag';
import { fontSize, opacity } from 'styled-system';
import { Label } from '@mui/icons-material';
import userService from 'services/userService';
import { Backdrop } from "@mui/material";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks';
import { Button } from '@evabot/eva-react-core-library/dist/atoms/Button/Button';
import { TextArea } from '@evabot/eva-react-core-library/dist/atoms/TextArea/TextArea';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';
import { axiosHelperCore } from 'utils';

const GeneralInfo = ({ integration, keywordArray, pitch, persona, setKeywords }) => {

    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [trackingKeywordsUpdatedOn, setTrackingKeywordsUpdatedOn] = useState("");
    const [hiringKeywordsUpdatedOn, setHiringKeywordsUpdatedOn] = useState("");
    const [factKeywordsUpdatedOn, setFactKeywordsUpdatedOn] = useState("");


    const uniqueTypesSet = new Set();
    integration.forEach(item => uniqueTypesSet.add(item.type));
    const uniqueTypesArray = Array.from(uniqueTypesSet);
    const label = "Keywords";
    const label2 = "Integration";

    const viewMemberOrgId = useAppSelector((state) => state.memberListingState.tabViewState.isOrgId);

    const fetchCallPrep = (orgId) => {
        setLoading(true);
        const obj = { organizationId: orgId };
        try {
            userService.getCallPrep(obj)
                .then((response) => {
                    setText(response?.customField);
                    setLoading(false);
                })
        }
        catch (error) {
            console.error("Error fetching call prep", error);
        }
    };

    const saveCallPrep = (orgId, text = "") => {
        const obj = { organizationId: orgId, fieldName: text };
        try {
            userService.saveCallPrep(obj)
                .then((response) => {

                })
        } catch (error) {
            console.error("Error fetching call prep", error);
        }
    }

    const changeKeywordLimit = async (type, limit) => {
        const result: any = await axiosHelperCore({ url: `rapport/user/updateKeywordLimit`, method: "POST", JSONData: { type: type, limit: limit, organizationId: viewMemberOrgId } });
        if (result?.data?.success) {
            setKeywords((prevKeywords) => ({
                ...prevKeywords,
                [type + "Limit"]: limit
            }));
            setSnackbarMessage('Keyword limit changed!');
            setSnackbarType('success');
            setShowSnackBar(true);
        }
    }

    useEffect(() => {
        const hiringdate = new Date(parseInt(keywordArray?.hiringKeywordsUpdatedOn));
        const trackingdate = new Date(parseInt(keywordArray?.trackingKeywordsUpdatedOn));
        const factdate = new Date(parseInt(keywordArray?.factKeywordsUpdatedOn));

        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];


        const dayhiring = hiringdate.getDate();
        const daytracking = trackingdate.getDate();
        const dayfact = factdate.getDate();

        const monthhiring = monthNames[hiringdate.getMonth()];
        const monthtracking = monthNames[trackingdate.getMonth()];
        const monthfact = monthNames[factdate.getMonth()];

        const resulthiring = `${dayhiring} ${monthhiring}`;
        const resulttracking = `${daytracking} ${monthtracking}`;
        const resultfact = `${dayfact} ${monthfact}`;

        setTrackingKeywordsUpdatedOn(resulttracking);
        setHiringKeywordsUpdatedOn(resulthiring);
        setFactKeywordsUpdatedOn(resultfact);


    }, [keywordArray])


    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleRemove = () => {
        setText("");
        setIsEditing(false);
        saveCallPrep(viewMemberOrgId, "");
    };

    const handleSave = (newText) => {
        setText(newText);
        setIsEditing(false);
        saveCallPrep(viewMemberOrgId, newText);
    };

    useEffect(() => {
        if (viewMemberOrgId) {
            fetchCallPrep(viewMemberOrgId);
        }
    }, [viewMemberOrgId]);

    return (
        <>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            {loading &&
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                    open={true}
                >
                    <Icon name="loadingIcon" />
                </Backdrop>}
            <Box padding={"40px"} backgroundColor="white" border="1px solid #ddd" boxShadow="0px 0px 8px rgba(0,0,0,0.1)" borderRadius="4px">
                <Flex flexDirection={"column"} gap="35px">
                    <Flex flexDirection={"row"}>
                        <Flex width={"25%"} opacity={0.7}>Integration Type</Flex>
                        <Flex width={"75%"}> {uniqueTypesArray.join(",") || "N/A"}</Flex>
                    </Flex>
                    <Flex flexDirection={"column"} border={"1px solid lightgrey"} borderRadius={"5px"}  padding={"15px"} gap={"15px"}>
                        <Flex flexDirection={"row"} alignItems={"center"}>
                            <Flex width={"25%"} opacity={0.7}>Tracking Keywords Limit</Flex>
                            <Flex width={"75%"}>
                                <select onChange={(e) => { changeKeywordLimit("tracking", e.target.value) }}
                                    value={keywordArray.trackingLimit}
                                    style={{ padding: "10px", border: "1px solid lightgrey", borderRadius: "7px" }}
                                >
                                    {Array.from({ length: 25 }, (_, i) => (
                                        <option key={i + 1} value={i + 1}>
                                            {i + 1}
                                        </option>
                                    ))}
                                </select>
                            </Flex>
                        </Flex>
                        <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                            <Flex width={"25%"} flexDirection={"column"}>
                                <Flex opacity={0.7} >Tracking Keywords</Flex>
                                <Flex><div style={{ fontSize: "12px", fontStyle: "italic", color: "#0094E3" }}>Updated By&nbsp;<span><b>{keywordArray?.trackingKeywordsUpdatedBy || "-"}</b></span>&nbsp;on&nbsp;<span><b>{trackingKeywordsUpdatedOn !== "NaN undefined" ? trackingKeywordsUpdatedOn : "-"}</b></span></div></Flex>
                            </Flex>
                            <Flex width={"75%"} flexWrap={"wrap"}>
                                {keywordArray?.trackingKeywords?.map((item, index) =>
                                    <div>
                                        <Tag
                                            key={index}
                                            color={"#fff"}
                                            bgColor={"#0094E3"}
                                            text={item}
                                        />
                                    </div>
                                )}
                            </Flex>

                        </Flex>
                    </Flex>

                    <Flex flexDirection={"column"} border={"1px solid lightgrey"} borderRadius={"5px"}  padding={"15px"} gap={"15px"}>
                        <Flex flexDirection={"row"} alignItems={"center"}>
                            <Flex width={"25%"} opacity={0.7}>Hiring Keywords Limit</Flex>
                            <Flex width={"75%"}>
                                <select onChange={(e) => { changeKeywordLimit("hiring", e.target.value) }}
                                    value={keywordArray.hiringLimit}
                                    style={{ padding: "10px", border: "1px solid lightgrey", borderRadius: "7px" }}
                                >
                                    {Array.from({ length: 25 }, (_, i) => (
                                        <option key={i + 1} value={i + 1}>
                                            {i + 1}
                                        </option>
                                    ))}
                                </select>
                            </Flex>
                        </Flex>
                        <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                            <Flex width={"25%"} flexDirection={"column"}>
                                <Flex opacity={0.7}>Hiring Keywords</Flex>
                                <Flex><div style={{ fontSize: "12px", opacity: 1, fontStyle: "italic", color: "#0094E3" }}>Updated By&nbsp;<span style={{ fontWeight: 'bold' }}><b>{keywordArray?.hiringKeywordsUpdatedBy || "-"}</b></span>&nbsp;on&nbsp;<span><b>{hiringKeywordsUpdatedOn !== "NaN undefined" ? hiringKeywordsUpdatedOn : "-"}</b></span></div></Flex>
                            </Flex>
                            <Flex width={"75%"} flexWrap={"wrap"}>
                                {keywordArray?.hiringKeywords?.map((item, index) =>
                                    <div>
                                        <Tag
                                            key={index}
                                            color={"#fff"}
                                            bgColor={"#0094E3"}
                                            text={item}
                                        />
                                    </div>
                                )}

                            </Flex>

                        </Flex>
                    </Flex>
                    <Flex flexDirection={"column"} border={"1px solid lightgrey"} borderRadius={"5px"} padding={"15px"} gap={"15px"}>
                        <Flex flexDirection={"row"} alignItems={"center"}>
                            <Flex width={"25%"} opacity={0.7}>Fact Keywords Limit</Flex>
                            <Flex width={"75%"}>
                                <select onChange={(e) => { changeKeywordLimit("fact", e.target.value) }}
                                    value={keywordArray.factLimit}
                                    style={{ padding: "10px", border: "1px solid lightgrey", borderRadius: "7px" }}
                                >
                                    {Array.from({ length: 25 }, (_, i) => (
                                        <option key={i + 1} value={i + 1}>
                                            {i + 1}
                                        </option>
                                    ))}
                                </select>
                            </Flex>
                        </Flex>
                        <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                            <Flex width={"25%"} flexDirection={"column"}>
                                <Flex opacity={0.7}>Fact Keywords</Flex>
                                <Flex><div style={{ fontSize: "12px", opacity: 1, fontStyle: "italic", color: "#0094E3" }}>Updated By&nbsp;<span style={{ fontWeight: 'bold' }}><b>{keywordArray?.factKeywordsUpdatedBy || "-"}</b></span>&nbsp;on&nbsp;<span><b>{factKeywordsUpdatedOn !== "NaN undefined" ? hiringKeywordsUpdatedOn : "-"}</b></span></div></Flex>
                            </Flex>
                            <Flex width={"75%"} flexWrap={"wrap"}>
                                {keywordArray?.factKeywords?.map((item, index) =>
                                    <div>
                                        <Tag
                                            key={index}
                                            color={"#fff"}
                                            bgColor={"#0094E3"}
                                            text={item}
                                        />
                                    </div>
                                )}

                            </Flex>

                        </Flex>
                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} flexDirection={"column"}>
                            <Flex opacity={0.7}>Pitch</Flex>
                        </Flex>
                        <Flex width={"75%"} flexWrap={"wrap"}>
                            {pitch}
                        </Flex>

                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} flexDirection={"column"}>
                            <Flex opacity={0.7}>Persona</Flex>
                        </Flex>
                        <Flex width={"75%"} flexWrap={"wrap"}>
                            {persona}
                        </Flex>

                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={0.7}>Call Prep Custom Field</Flex>
                        {(text && !isEditing) ? (
                            <Flex width={"75%"} alignItems={"center"} justifyContent={"space-between"} style={{ border: "1px solid #d3d3d3", padding: "6px", borderRadius: "10px" }}>
                                <Flex>
                                    <p>{text}</p>
                                </Flex>
                                <Flex gap='7px' alignItems={"center"}>
                                    <EditIcon onClick={handleEdit} style={{ cursor: 'pointer', height: "20px" }} />
                                    <DeleteIcon onClick={handleRemove} style={{ cursor: 'pointer', height: "20px" }} />
                                </Flex>
                            </Flex>
                        ) : (
                            <Flex width={"75%"} alignItems={"center"}>
                                {isEditing ? (
                                    <Flex gap='10px' alignItems={"center"}>
                                        <TextArea
                                            value={text}
                                            onChange={(e) => setText(e.target.value)}
                                            width={"385px"}
                                            height={"80px"}
                                        />
                                        <Button variant="primary" onClick={() => handleSave(text)}>Save</Button>
                                        <Button variant="secondary" onClick={() => setIsEditing(false)}>Cancel</Button>
                                    </Flex>
                                ) : (
                                    <Button onClick={() => setIsEditing(true)}>Add Text</Button>
                                    // <AddIcon onClick={() => setIsEditing(true)} style={{ cursor: 'pointer' }} />
                                )}
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            </Box>
        </>
    );
};

export default GeneralInfo;