import React, { FC, useEffect, useState } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import AdminProgressBar from "components/AdminProgressBar/AdminProgressBar";
import NavSelectedNumber from "components/NavSelectedNumber/NavSelectedNumber";
import { useAppSelector } from "hooks";

interface AdminLeftSectionNavProps {
  selectedTab: number;
}

const AdminLeftSectionNav: FC<AdminLeftSectionNavProps> = ({ selectedTab }) => {
  const journey = useAppSelector((state) => state.globalState.journey);

  const [numberOfRows, setNumberOfRows] = useState([]);
  const [navTextMap, setNavTextMap] = useState({});

  useEffect(() => {
    if (
      journey?.journeyName === "admin" &&
      journey?.journeyPath === "2" &&
      (selectedTab === 4.1 || selectedTab === 4.2)
    ) {
      setNumberOfRows([1, 2, 3, 4, 4.1, 4.2, 5]);
      setNavTextMap({
        1: "pages.fieldmapping",
        2: "pages.opportunityselection",
        3: "pages.budgetsetup",
        4: "appname",
        4.1: "Email templates setup",
        4.2: "Set reminder templates",
        5: "pages.inviteusers",
      });
    } else {
      setNumberOfRows([1, 2, 3, 4, 5]);
      setNavTextMap({
        1: "pages.fieldmapping",
        2: "pages.opportunityselection",
        3: "pages.budgetsetup",
        4: "pages.emailtemplatesetup",
        5: "pages.inviteusers",
      });
    }
  }, []);

  return (
    <>
      <Flex flexDirection="column" marginTop="-164px">
        {numberOfRows.map((tab: number, index: number) => {
          return (
            <Flex
              alignItems="center"
              justifyContent="space-between"
              marginBottom={"24px"}
              key={index}
              mx={"32px"}
            >
              <Flex alignItems="center">
                {!(tab === 4.1 || tab === 4.2) ? (
                  <NavSelectedNumber current={tab} selected={selectedTab} />
                ) : (
                  ""
                )}
                {/* selected tab -> 4.1 and 4.2  tab=4 white and icon */}
                {
                  //@ts-ignore
                  !(tab === 4.1 || tab === 4.2) ? (
                    <Text
                      marginLeft={12}
                      fontSize="14px"
                      fontFamily={"Inter-Light"}
                      id={navTextMap[tab]}
                      color={
                        (selectedTab === 4.1 || selectedTab === 4.2) &&
                        tab === 4
                          ? "white"
                          : tab === selectedTab
                          ? "white"
                          : "#9F9F9F"
                      }
                    />
                  ) : (
                    <Text
                      marginLeft={36}
                      fontSize="14px"
                      fontFamily={"Inter-Light"}
                      color={tab === selectedTab ? "white" : "#9F9F9F"}
                    >
                      {navTextMap[tab]}
                    </Text>
                  )
                }
              </Flex>
              {tab === selectedTab && <Icon name="arrowRight" fill="white" />}
            </Flex>
          );
        })}
      </Flex>
      <AdminProgressBar />
    </>
  );
};

export default AdminLeftSectionNav;
