import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

const initialState = {
    stakeholderTagData: [],
    loading: false,
    stakeholderTagPerPage: 10,
    stakeholderTagPageNo: 0,
    stakeholderTagSnackbar: {
        show: false,
        message: "",
    },
};

export interface stakeholderTagReducerType { }

const stakeholderTagReducerSlice = createSlice({
    name: "stakeholderTagState",
    initialState: initialState,
    reducers: {
        setStakeholderTagData: (
            state,
            action: PayloadAction<stakeholderTagReducerType>
        ) => {
            //@ts-ignore
            state.stakeholderTagData = action.payload;
        },
        setStakeholderTagDataLoading: (
            state,
            action: PayloadAction<stakeholderTagReducerType>
        ) => {
            //@ts-ignore
            state.loading = action.payload;
        },
        setStakeholderTagDataPerPage: (state, action: PayloadAction<[]>) => {
            //@ts-ignore
            state.stakeholderTagPerPage = action.payload;
        },
        setStakeholderTagDataPageNo: (state, action: PayloadAction<[]>) => {
            //@ts-ignore
            state.stakeholderTagPageNo = action.payload;
        },
        setStakeholderTagDataSnackbar: (state, action: PayloadAction<[]>) => {
            //@ts-ignore
            state.stakeholderTagSnackbar = action.payload;
        },
    },
});

export const {
    setStakeholderTagData,
    setStakeholderTagDataLoading,
    setStakeholderTagDataPerPage,
    setStakeholderTagDataPageNo,
    setStakeholderTagDataSnackbar,
} = stakeholderTagReducerSlice.actions;

export const stakeholderTagState = (state: RootState) =>
    state.stakeholderTagState;

export default stakeholderTagReducerSlice.reducer;
