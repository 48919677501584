import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

const initialState = {
  profileData: {
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    designation: "",
    comapnyName: "",
    phoneNo: "",
    imageFile: "",
    refreshTokenType: "",
    refreshToken: "",
    expiredOn: "",
    tokenNumber: "",
    organizationId: "",
    isExaSummary: false,
  },
  loading: false,
};

export interface aeReturnProfileReducerType {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string,
  designation: string;
  comapnyName: string;
  phoneNo: string;
  imageFile: string;
  refreshTokenType: string;
  refreshToken: string;
  expiredOn: string;
  tokenNumber: string;
  organizationId: string;
  isExaSummary: boolean;
}

const aeReturnProfileReducerSlice = createSlice({
  name: "profileState",
  initialState: initialState,
  reducers: {
    aeReturnProfileData: (
      state,
      action: PayloadAction<aeReturnProfileReducerType>
    ) => {
      state.profileData = action.payload;
    },
    aeReturnProfileDataLoading: (
      state,
      action: PayloadAction<aeReturnProfileReducerType>
    ) => {
      //@ts-ignore
      state.loading = action.payload;
    },
  },
});

export const { aeReturnProfileData, aeReturnProfileDataLoading } =
  aeReturnProfileReducerSlice.actions;

export const aeReturnProfileState = (state: RootState) => state.profileState;

export default aeReturnProfileReducerSlice.reducer;
