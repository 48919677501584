import { useEffect, useState } from "react";
import StakeholderTagTableBody from "./StakeholderTagTableBody";
import StakeholderTagTableHeader from "./StakeholderTagTableHeader";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { useAppSelector } from "hooks";
import { useDispatch } from "react-redux";

function StakeholderTagTable() {
    const allStakeholderTagData = useAppSelector(
        (state) => state.stakeholderTagState?.stakeholderTagData
    );

    const allStakeholderTagDataLoading = useAppSelector(
        (state) => state.stakeholderTagState?.loading
    );
    const stakeholderTagSnackbar = useAppSelector(
        (state) => state.stakeholderTagState?.stakeholderTagSnackbar
    );


    const [tagList, setTagList] = useState(allStakeholderTagData);

    useEffect(() => {
        console.log("allStakeholderTagData",allStakeholderTagData);
            setTagList(allStakeholderTagData);
        }, [allStakeholderTagData]
    )


    return (
        <Box>
            <StakeholderTagTableHeader></StakeholderTagTableHeader>
            <StakeholderTagTableBody tagList={tagList}></StakeholderTagTableBody>
        </Box>
    );
}

export default StakeholderTagTable;
