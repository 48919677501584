import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "./Calendar.css";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { differenceInDays } from "date-fns";
import { convertDate } from "utils/constants";
import { useDispatch } from "react-redux";
import { setAnalyticsDateRange } from "../store/aeReturnAnalyticsReducer";
import moment from "moment";
import { current } from "@reduxjs/toolkit";

export default function CalendarComponent({
  date,
  setDate,
  setOpenModal,
  fetchAnalyticsData,
}) {
  const dispatch = useDispatch();
  const [internalDate, setInternalDate] = useState(date);

  const currentDate = new Date();

  const handleDate = (selectedDate) => {
      console.log("event", selectedDate);

      const dateDiff = Math.abs(differenceInDays(currentDate,selectedDate));

      let dateRange = [];

      if(dateDiff < 6){
        const startDate = moment(currentDate).subtract(6,"days");
        const endDate = moment(currentDate);
        dateRange = [startDate._d, endDate._d];
      }else{
        const startDate = moment(selectedDate);
        const endDate = moment(selectedDate).add(6,"days");
        dateRange = [startDate._d, endDate._d];
      }


      setInternalDate(dateRange);
  }

  useEffect(() => {
    setInternalDate(date);
  }, [date]);

  const tileDisabled = ({ date }) => {
    const isCurrentDate = date.getDate() === currentDate.getDate() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear();

    return differenceInDays(currentDate, date) < 6 && !isCurrentDate;
  };

  return (
    <Flex
      flexDirection={"column"}
      p="16px"
      background={"#fff"}
      width={"max-content"}
      borderRadius={"4px"}
      boxShadow={"0px 25px 35px 0px rgba(0, 0, 0, 0.10)"}
      position={"absolute"}
      top="53px"
      right="0"
      zIndex={1}
    >
      <div className="calendar-container">
        <Calendar
          onChange={handleDate}
          value={internalDate}
          returnValue={"start"}
          maxDate={new Date()}
          tileDisabled={tileDisabled}
        
        />
      </div>
      <Flex
        borderTop={"1px solid rgba(0, 0, 0, 0.20)"}
        m="20px 10px 16px 15px"
        flexDirection={"column"}
      >

        <Text my="10px" textAlign={"center"} fontSize={"10px"} fontStyle={"italic"} textWrap="wrap">
          Please select a date range of 7 consecutive days.
        </Text>
        <Button
          fullSize
          borderRadius={"100px"}
          onClick={() => {
            setDate(internalDate);
            setOpenModal(false);
            fetchAnalyticsData(
              convertDate(internalDate[0]),
              convertDate(internalDate[1])
            );
            dispatch(setAnalyticsDateRange(internalDate));
          }}
        >
          Set Date
        </Button>
      </Flex>
    </Flex>
  );
}
