import * as React from "react";

function SortByDropArrow(props) {
  return (
    <svg
      width={props?.size || "16px"}
      height={props?.size || "16px"}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path d="M11.333 9.333L8 6 4.667 9.333h6.666z" fill="#000" />
    </svg>
  );
}

export default SortByDropArrow;
