import TeamManagementTable from "./TeamManagementTable";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";

function TeamManagementContent() {

    return (

        <Flex flexDirection={"column"} padding="25px 32px" overflowY={"scroll"}>
            <TeamManagementTable />
        </Flex>
    );
}

export default TeamManagementContent;
