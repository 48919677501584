import * as React from 'react';

function MetaLogo() {
    return (
        <svg width="60" height="12" viewBox="0 0 60 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.17396 1.9631C4.40283 1.9631 3.74833 2.50407 3.20053 3.33166C2.42568 4.50045 1.95171 6.24185 1.95171 7.9144C1.95171 8.60426 2.10308 9.13406 2.30098 9.45418L0.637129 10.5498C0.209688 9.83943 0 8.907 0 7.84491C0 5.91305 0.530423 3.89993 1.53854 2.34464C2.43312 0.966155 3.72413 0.00146484 5.20498 0.00146484L5.17396 1.9631Z" fill="white"/>
        <path d="M1.9517 7.91355C1.9517 8.60341 2.10307 9.13321 2.30097 9.45333C2.51501 9.82556 2.91267 10.0539 3.34197 10.0508C3.85068 10.0508 4.31658 9.9242 5.21365 8.68344C5.93205 7.68897 6.77887 6.29312 7.349 5.41839L8.31368 3.93568C8.98369 2.90585 9.75979 1.76126 10.6494 0.985162C11.3759 0.351755 12.1588 0 12.9473 0C14.2712 0 15.5318 0.766787 16.4971 2.20545C17.553 3.78121 18.066 5.7658 18.066 7.81367C18.066 9.03147 17.8259 9.92606 17.4177 10.6327C17.0232 11.3163 16.2545 11.9987 14.961 11.9987V10.0501C16.0684 10.0501 16.3451 9.03209 16.3451 7.86764C16.3451 6.20813 15.958 4.3656 15.1056 3.04978C14.5007 2.11611 13.7166 1.54598 12.8542 1.54598C11.9212 1.54598 11.1705 2.24949 10.3268 3.5039C9.87828 4.17019 9.41796 4.98288 8.90118 5.89918L8.3323 6.9073C7.18894 8.93469 6.89922 9.39625 6.32785 10.1581C5.32594 11.4925 4.47044 11.9981 3.34321 11.9981C2.00692 11.9981 1.16196 11.4193 0.638981 10.5477C0.21216 9.83734 0.00247192 8.90492 0.00247192 7.84283L1.9517 7.91355Z" fill="white"/>
        <path d="M1.53912 2.34375C2.43371 0.964648 3.72472 0.000579834 5.20556 0.000579834C6.06354 0.000579834 6.91532 0.254315 7.80557 0.981398C8.77956 1.7761 9.81683 3.08448 11.1122 5.24154L11.5762 6.01577C12.6973 7.88311 13.3344 8.84346 13.7079 9.29633C14.188 9.87825 14.5236 10.0513 14.9604 10.0513C16.0678 10.0513 16.3445 9.03329 16.3445 7.86884L18.066 7.81487C18.066 9.03267 17.8259 9.92726 17.4177 10.6339C17.0232 11.3175 16.2545 11.9999 14.961 11.9999C14.1564 11.9999 13.4442 11.8256 12.6569 11.0824C12.0514 10.5123 11.3436 9.49858 10.7989 8.58786L9.17909 5.88115C8.36639 4.52314 7.62069 3.51006 7.18891 3.0516C6.72487 2.5584 6.12806 1.96284 5.17516 1.96284C4.40403 1.96284 3.74953 2.50381 3.20174 3.33139L1.53912 2.34375Z" fill="white"/>
        <path d="M21.859 0.380371H24.1166L27.9542 7.32365L31.7925 0.380371H34.0011V11.7891H32.1598V3.0449L28.7936 9.09979H27.0658L23.7003 3.0449V11.7885H21.859V0.380371ZM39.7706 4.63369C38.4498 4.63369 37.6545 5.62753 37.4646 6.85836H41.9462C41.8538 5.59093 41.1224 4.63369 39.7706 4.63369ZM35.6389 7.55939C35.6389 4.96993 37.3126 3.08522 39.8028 3.08522C42.2521 3.08522 43.7149 4.94636 43.7149 7.69774V8.20335H37.4646C37.6861 9.54212 38.5745 10.4448 40.0069 10.4448C41.1497 10.4448 41.8637 10.0961 42.5412 9.45837L43.5195 10.6563C42.5976 11.5038 41.4258 11.9926 39.9418 11.9926C37.2456 11.9926 35.6389 10.0273 35.6389 7.55939ZM45.9471 4.79747H44.2522V3.28995H45.9471V0.796645H47.7238V3.28995H50.299V4.79747H47.7238V8.61962C47.7238 9.92428 48.1407 10.3883 49.1662 10.3883C49.5465 10.3889 49.9256 10.3536 50.299 10.2822V11.7736C49.8195 11.9107 49.3225 11.979 48.8238 11.9765C46.9056 11.9765 45.9471 10.928 45.9471 8.83117V4.79747ZM57.7802 6.26342C57.4235 5.36263 56.6275 4.69883 55.4575 4.69883C53.9369 4.69883 52.9642 5.77767 52.9642 7.53458C52.9642 9.24744 53.86 10.3784 55.3849 10.3784C56.5835 10.3784 57.439 9.68109 57.7802 8.8138V6.26342ZM59.5569 11.7885H57.8124V10.5992C57.3248 11.2997 56.4377 11.9926 55.0009 11.9926C52.69 11.9926 51.1458 10.0583 51.1458 7.5352C51.1458 4.98854 52.7272 3.08584 55.0989 3.08584C56.2714 3.08584 57.1908 3.55423 57.8124 4.38181V3.28995H59.5563L59.5569 11.7885Z" fill="white"/>
        </svg>

    )
}

const MetaLogoIcon = React.memo(MetaLogo);
export default MetaLogoIcon;