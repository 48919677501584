import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import AdminDashOpportunity from "./AdminDashOpportunity.png";
import AdminDashStakeholder from "./AdminDashStakeholder.png";
import AdminDashBudget from "./AdminDashBudget.png";
import AdminDashCredit from "./AdminDashCredit.png";

export interface AdminDashboardRightCardProps {
  cardValue: string;
  cardContent: string;
  cardType: "opportunity" | "stakeholder" | "budget" | "credit";
}

export const AdminDashboardRightCard: React.FC<
  AdminDashboardRightCardProps
> = ({ cardValue, cardContent, cardType }) => {
  return (
    <Grid
      padding={"24px"}
      gridGap="5px"
      borderRadius={"12px"}
      background="white"
      height={"max-content"}
      minWidth="260px"
    >
      <Flex justifyContent={"space-between"} alignItems="center">
        <Text variant="heading1" color="#050505" fontWeight={300}>
          {cardValue}
        </Text>
        <img
          src={
            cardType === "opportunity"
              ? AdminDashOpportunity
              : cardType === "stakeholder"
              ? AdminDashStakeholder
              : cardType === "budget"
              ? AdminDashBudget
              : cardType === "credit"
              ? AdminDashCredit
              : ""
          }
          alt=""
          style={{ marginTop: "10px" }}
        />
      </Flex>
      <Text color="#4F5B67" variant="imputText">
        {cardContent}
      </Text>
    </Grid>
  );
};
