import * as React from "react";

function TimerIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_7739_65960)">
        <path
          d="M15.111 7.956c0 .31 0 .622-.045.933a.52.52 0 01-.488.4c-.223 0-.4-.267-.4-.489.044-.311.044-.578.044-.844 0-3.423-2.8-6.223-6.222-6.223-.267 0-.533 0-.845.045-.222.044-.488-.134-.488-.356-.045-.222.133-.489.355-.489 3.911-.533 7.467 2.223 8 6.09.089.31.089.621.089.933z"
          fill="#E40606"
        />
        <path
          d="M5.6 13.689a8.96 8.96 0 01-1.422-.8c-.178-.133-.49-.133-.623.089-.133.178-.133.489.09.622.488.356 1.021.711 1.6.933.221.09.488 0 .577-.222.133-.267.044-.533-.222-.622zM3.911 3.111c.089 0 .178-.044.267-.089.444-.31.889-.622 1.378-.8.222-.089.355-.355.266-.578-.089-.222-.355-.355-.578-.266a5.803 5.803 0 00-1.6.933c-.177.178-.222.445-.088.622a.404.404 0 00.355.178zM2.267 10.356c-.09-.223-.356-.356-.578-.267-.222.089-.356.355-.267.578.223.577.534 1.11.934 1.6a.478.478 0 00.622.089.478.478 0 00.089-.623c-.311-.4-.622-.889-.8-1.377zM1.822 8.8a5.102 5.102 0 010-1.644c.045-.223-.133-.445-.4-.49-.266-.044-.444.134-.489.4a6.567 6.567 0 000 1.867c.045.223.223.4.445.4h.044c.267-.089.445-.31.4-.533zM1.644 5.822c.223.09.49 0 .578-.222a8.96 8.96 0 01.8-1.422c.134-.178.134-.49-.089-.622a.421.421 0 00-.577.088 9.428 9.428 0 00-.934 1.6c-.089.223 0 .49.222.578zM11.778 12.889c-.445.311-.89.622-1.423.8-.222.089-.355.355-.266.578.089.222.355.355.578.266a5.804 5.804 0 001.6-.933c.177-.133.222-.4.088-.622-.088-.178-.355-.222-.577-.09zM8.8 14.133a5.101 5.101 0 01-1.645 0c-.222-.044-.444.134-.489.4-.044.223.134.445.4.49a6.566 6.566 0 001.867 0 .52.52 0 00.4-.49c-.044-.266-.267-.444-.533-.4zM14.311 10.133c-.222-.089-.489 0-.578.223a8.965 8.965 0 01-.8 1.422c-.133.178-.133.489.09.622.221.133.488.133.621-.089.4-.489.711-1.022.934-1.6.089-.222-.045-.489-.267-.578z"
          fill="#FF9E9E"
        />
        <path
          d="M8 4c-.267 0-.444.178-.444.444V8c0 .267.177.444.444.444h3.556c.266 0 .444-.177.444-.444s-.178-.444-.444-.444H8.445V4.444C8.445 4.178 8.267 4 8 4z"
          fill="#E40606"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_7739_65960">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default TimerIcon;
