import AdminReportHeader from "./AdminReportHeader";
import AdminReportTableBody from "./AdminReportTableBody";

function AdminReportTable({ memberData, accountData, dateRange, tableRef }) {
    return (
        <div className="table-container" style={{ borderRadius: "10px", overflow: "none" }}>
            <table ref={tableRef} style={{ width: "100%" }}>
                <AdminReportHeader></AdminReportHeader>
                <AdminReportTableBody memberData={memberData} accountData={accountData} dateRange={dateRange}></AdminReportTableBody>
            </table>
        </div>
    )
}

export default AdminReportTable;