export const c1PrefMap = {
  coffee: "Coffee",
  ground_coffee: "Pour Over Coffee",
  coffee_beans: "Pour Over Coffee",
  "Pour over": "Pour Over Coffee",
  "Green tea": "Green Tea",
  "Chai tea": "Chai Tea",
  instant: "Coffee",
  tea: "Tea",
  decaf: "Tea",
};

export const coffeePrefArray = [
  "Drip brew",
  "Espresso",
  "French press",
  "Cold brew",
  "Pour over",
  "Latte",
  "Mocha",
  "Cappuccino",
  "Americano",
  "Macchiato",
  "Frappuccino",
  "Cortado",
  "Flat White",
  "Breve",
  "Iced Latte",
  "Caramel Macchiato",
  "White Mocha",
  "Red Eye (coffee with a shot of espresso)",
  "Black Eye (coffee with two shots of espresso)",
  "Ristretto (strong and concentrated espresso shot)",
  "Lungo (espresso with added water)",
  "Viennese (coffee with whipped cream)",
  "Irish coffee (coffee with Irish whiskey and cream)",
  "Chai latte (coffee with chai spice tea)",
  "Bicerin (Italian coffee with chocolate and cream)",
  "Siphon",
  "Turkish coffee",
  "Moka pot",
  "Instant coffee",
  "Nitro cold brew",
  "Irish coffee",
  "Iced coffee",
];

export const teaPrefArray = [
  "Black tea",
  "Green tea",
  "Oolong tea",
  "White tea",
  "Pu-erh tea",
  "Herbal tea",
  "Earl Grey",
  "Chai tea (black tea with spices such as ginger, cardamom, and cinnamon)",
  "Earl Grey (black tea with bergamot oil)",
  "Masala chai (spiced tea with milk)",
  "Milk tea (tea with milk and sweetener)",
  "Bubble tea (milk tea with tapioca pearls)",
  "Ice tea",
  "Iced green tea",
  "Sun tea (tea brewed by sun)",
  "Cold brew tea",
  "Kombucha (fermented tea)",
  "Matcha (powdered green tea)",
  "Golden milk (milk with turmeric and spices)",
  "Yerba Mate (caffeinated tea from South America)",
  "Lemon tea (tea with lemon juice)",
];

export const c3PrefMap = {
  "hardliquor&wine": "Hard liquor and Wine",
};

export const alcoholPrefArray = [
  "Beer",
  "Wine (red)",
  "Wine (white)",
  "Whiskey",
  "Vodka",
  "Tequila",
  "Rum",
  "Gin",
  "Margarita",
  "Martini",
  "Old Fashioned",
  "Manhattan",
  "Bloody Mary",
  "Long Island Iced Tea",
  "Mai Tai",
  "Cosmopolitan",
  "Negroni",
  "Sazerac",
  "Hurricane",
  "Jägerbomb",
  "White Russian",
  "Cognac",
  "Bourbon",
  "Scotch",
  "Sake",
  "Brandy",
  "Absinthe",
  "Liqueur",
  "Champagne",
  "Prosecco",
  "Cider",
  "Mead",
  "Vermouth",
  "Margarita ",
  "007 Martini",
  "Blue Lagoon",
  "Apple Martini",
  "Metropolis",
  "Serena",
  "A Midsummer Nights Dream",
  "Berry Me in the Sand",
  "360 Degrees",
  "Vodka Sunrise",
  "A Southern Screw",
  "Mind Eraser",
  "Sea Breeze",
  "Lime Rickey",
  "Black Russian",
  "Screwdriver",
  "Kremlin Colonel",
  "Sex on the Beach",
  "Mudslide",
  "Autumn in New York",
  "Blue Hawaii",
  "Napa Sun",
  "One Night Stand",
  "Greyhound",
  "Black Bison",
  "Pomegranate Martini",
  "Samantha",
  "Spider’s Kiss",
  "Tuxedo",
  "Caipiroska",
  "Ice Pick",
  "Tropical Itch",
  "Rum Swizzle",
  "Piña Colada",
  "Daiquiri",
  "Macau",
  "Bahama Mama",
  "Strawberry Basil Mojito",
  "A Clockwork Tangerine",
  "Malibu Breeze",
  "Devil’s Poison",
  "A Day at the Beach",
  "Pink Frost",
  "Egg Nog",
  "Nude Beach",
  "Pineapple Mai Tai",
  "Creole Splash",
  "Creepy Crawler Punch",
  "Quicky",
  "Jamaican Winter",
  "Banana Daiquiri",
  "Spice Night",
  "Long Beach Ice Tea",
  "Zombie",
  "Watermelon Granita",
  "The Crimson Tide",
  "Tom and Jerry",
  "Boston Sidecar",
  "Cuba Libre",
  "Hairy Virgin",
  "Painkiller",
  "Orange Bomber",
  "Cool as a Cucumber",
  "Heatwave",
  "Pirate Drink",
  "Geisha Girl",
  "Pink Lady",
  "Mickey Slim",
  "Punch",
  "Blue Motorcycle",
  "Paradise",
  "Tom Collins",
  "Gimlet",
  "Salty Dog",
  "Vesper Martini",
  "Newport Cooler",
  "Dragonfly",
  "Nightmare",
  "Deep Sea",
  "Napoleon",
  "Watermelon and Cucumber Tonic",
  "White Lady",
  "Hanky Panky",
  "Gin and Tonic",
  "Pink Gin",
  "Snowball",
  "Fallen Angel",
  "Emerald Forest",
  "Pink Panther",
  "Orange Blossom",
  "Earthquake",
  "Irish Sour",
  "Hawaiian Cocktail",
  "Summer Gin Punch",
  "Royal Arrival",
  "My Fair Lady",
  "Peach Mojito",
  "Red Sangria",
  "Singapore Sling",
  "Between the Sheets",
  "Aphrodisiac",
  "Vanderbilt Cocktail",
  "Depth Bomb",
  "Corpse Reviver",
  "Vanity Fair",
  "Summer Slush",
  "Brandy Alexander",
  "City Slicker",
  "Bloody Black Currant",
  "Sex a’ Peel",
  "Stinger",
  "Panama",
  "Cafe Zurich",
  "Orgasm",
  "Four Score",
  "Aviation",
  "Sparkling Spring Punch",
  "After Dark",
  "Mexican Martini",
  "Apple Bob",
  "Prairie Fire",
  "Navy Seal",
  "Calabatini",
  "Red Hot Mama",
  "Blue Stampede",
  "Citrico Snowflake",
  "Paloma",
  "Grenada",
  "Firecracker",
  "The Orange Turf",
  "Tequila Sunset",
  "The One Cheek Sneak",
  "Joker’s Tea",
  "Icebreaker",
  "Three Amigos",
  "Frostbite",
  "Gold Rush",
  "The Prickly Margarita",
  "Tequila Sunrise",
  "Four Leaf Cloverita",
  "El Diablo",
  "Tequila Tangerine Dream",
  "Massacre",
  "Melange A Trois",
  "Blue Voodoo Doll",
  "Arizona Margarita",
  "Paralyzer",
  "Tequila Slammer",
  "Matador",
  "Tonic and Tequila",
  "Bloody Aztec",
  "Jungle Juice",
  "Choking Hazard",
  "Rusty Nail",
  "Seven and Seven",
  "Godfather",
  "Bourbon Cobbler",
  "Red Death",
  "Quick Kick",
  "Mr. Big",
  "Canadian Club Old Fashioned",
  "Cork Comfort",
  "Renegade",
  "The Dublin Apple",
  "Hiram Walker",
  "Good Night Special",
  "Deadly Nightshade",
  "Jimmy Russel’s Mint Julep",
  "Vancouver Cocktail",
  "The Steeler",
  "Spirited Punch",
  "Smoky Martini",
  "Basil Hayden Wish",
  "Merry Irishman",
  "Admiral",
  "Blood and Sand",
  "Cherries Jubilee",
  "Heart of Fire",
  "Royal Widow",
  "John Collins",
  "Nixon",
  "Flirtini",
  "Mimosa",
  "Champagne Cocktail",
  "White Wine Sangria",
  "Quaker City Cooler",
  "Nelson’s Blood",
  "Bellini",
  "Blood Red Sangria",
  "Kentucky Champagne",
  "Sour Witch",
  "Golden Glamour",
  "The Bubbly Apricot",
  "French 75",
  "Chicago Cocktail",
  "Wine Cooler",
  "Savoy Affair",
  "Prince of Wales",
  "Ruby Dutchess",
  "Half and Half",
  "Spiced Tea Holiday Punch",
  "Chocolate Velvet",
  "Thriller",
  "Artillery Punch",
  "Bambus",
  "Blue Wave",
  "Dancing Dutchman",
  "Buckk’s Fizz",
  "Champagne Flamingo",
  "Elderflower Cocktail",
  "Dutch Tulip",
  "Pomegranate Pineapple Fireworks",
  "Kir Royale",
  "Rouge Noir",
  "Strawberry Flapper",
  "Raspberry Bellini",
  "Waterloo Sunset",
  "Sangria",
  "Spanish Sangria",
  "Absolut Wonder",
  "Strawberry Ginger Martini",
  "Alabama Slammer",
  "Bewitched",
  "Cadillac Margarita",
  "Passion",
  "Blood Orange Freeze",
  "Naughty Holiday Punches",
  "Chocolate Mint",
  "Orange Crush",
  "Night and Day",
  "Leprechaun Martini",
  "Monkey Fizz",
  "Butterscotch Martini",
  "Dirty Mother",
  "Kahlua",
  "Irish Car Bomb",
  "Grasshopper",
  "Lemon Drop",
  "Kamikaze",
  "Golden Dream",
  "Electric Martini",
  "Blue Eyes",
  "Spanish Crown",
  "Melon Ball",
  "Nuclear Martini",
  "Silverlake Slip",
  "Italian Sunset",
  "Godmother",
  "Jellybean",
  "Cranberry Citrus Swizzle",
  "Sidecarriage",
  "Poinsettia Cocktail",
  "Punch in the Stomach",
];

export const otherDrinksPrefArray = [
  "Soda (such as Coca-Cola, Pepsi, Sprite)",
  "Juice (such as orange juice, apple juice, cranberry juice)",
  "Sports drinks (such as Gatorade, Powerade)",
  "Energy drinks (such as Red Bull, Monster)",
  "Lemonade",
  "Iced tea (unsweetened)",
  "Milk",
  "Chocolate milk",
  "Smoothies",
  "Milkshakes",
  "Kool-Aid",
  "Water",
  "Flavored water",
  "Vitamin water",
  "Coconut water",
  "Aloe vera juice",
  "Kombucha",
  "Yerba Mate",
  "Golden milk",
];

export const cuisinePrefArray = [
  "American",
  "Italian",
  "Mexican",
  "Chinese",
  "Japanese",
  "Indian",
  "French",
  "Mediterranean",
  "Thai",
  "Greek",
  "Spanish",
  "Korean",
  "Vietnamese",
  "German",
  "Southern",
  "Barbecue",
  "Cajun",
  "Creole",
  "Tex-Mex",
  "Californian",
  "Seafood",
  "Steakhouse",
  "Fast food",
  "Vegetarian and vegan",
  "Latin American ",
  "Caribbean ",
  "Asian fusion",
  "Middle Eastern ",
  "African American",
  "Polish",
  "Irish",
  "German",
  "Jewish deli",
  "Brazilian",
  "Australian",
  "Polynesian",
  "Southwestern",
  "New England",
  "Pacific Northwest",
  "Farm-to-table, or locavore",
  "Hamburgers",
  "Pizza",
  "Fried chicken",
  "Tacos",
  "Hot dogs",
  "Barbecue ribs",
  "Grilled cheese sandwich",
  "Macaroni and cheese",
  "Spaghetti and meatballs",
  "Chili con carne",
  "Buffalo wings",
  "Sandwich",
  "Clam chowder",
  "Lobster",
  "Sushi",
  "Pad Thai",
  "Fried rice",
  "Curry",
  "Philly cheese steak",
  "Caesar salad",
  "BLT sandwich",
  "Biscuits and gravy",
  "Gumbo",
  "Jambalaya",
  "Po' boys",
  "Reuben sandwich",
  "Club sandwich",
  "Cobb salad",
  "Tuna salad",
  "Bagel and cream cheese",
  "Fried catfish",
  "Pulled pork sandwich",
  "Chicken and waffles",
  "Sloppy Joe",
  "Meatloaf",
  "Fish and chips",
  "Falafel",
  "Greek salad",
  "Shrimp and grits",
  "Beef brisket",
  "Stuffed bell peppers",
  "Egg rolls",
  "Spring rolls",
  "Dumplings",
  "Ramen",
  "Pesto pasta",
  "Fettuccine Alfredo",
  "Lasagna",
  "Chicken Parmesan",
  "Beef stroganoff",
  "Shepherd's pie",
  "Fish tacos",
  "Quesadilla",
  "Enchiladas",
  "Chimichangas",
  "Biryani",
  "Jalebi",
  "Tandoori chicken",
  "Butter chicken",
  "Biscuits and gravy",
  "Ethiopian",
  "Haitian",
];

export const allergyArray = [
  "Gluten-free",
  "Dairy-free",
  "Peanut-free",
  "Soy-free",
  "Egg-free",
  "Tree nut-free",
  "Seafood-free",
  "Vegetarian",
  "Vegan",
  "Low-sugar",
  "Low-carb",
  "Low-fat",
  "Keto",
  "Paleo",
  "Whole30",
  "Fructose-free",
  "Nightshade-free",
  "Sulfite-free",
  "Shellfish-free",
  "MSG-free",
  "Yeast-free",
  "Garlic-free",
  "Onion-free",
  "High-fiber",
  "High-protein",
  "Halal",
  "Kosher",
  "Organic",
  "Non-GMO",
  "Raw",
  "Low-sodium",
  "Low-cholesterol",
  "Low-acid",
  "Low-histamine",
  "Low-FODMAP",
  "Whole food",
  "Flexitarian",
  "Pescatarian",
  "Lacto-vegetarian",
  "Ovo-vegetarian",
  "Fruitarian",
  "Low-calorie",
  "Mediterranean",
  "DASH",
  "Plant-based",
];

export const petsArray = [
  "Dogs",
  "Cats",
  "Fish",
  "Birds",
  "Hamsters",
  "Guinea Pigs",
  "Rabbits",
  "Snakes",
  "Lizards",
  "Turtles",
  "Hermit Crabs",
  "Ferrets",
  "Chinchillas",
  "Rats",
  "Mice",
  "Parrots",
  "Cockatiels",
  "Budgerigars",
  "Canaries",
  "Gerbils",
  "Axolotls",
  "Bearded Dragons",
  "Iguanas",
  "Leopard Geckos",
  "Ball Pythons",
  "Corn Snakes",
  "Boas",
  "Milk Snakes",
  "Kingsnakes",
  "Hognose Snakes",
  "Monitors",
  "Amphibians",
  "Hedgehogs",
  "Sugar Gliders",
  "Squirrels",
  "Degus",
  "Skunks",
  "Raccoons",
  "Opossums",
  "Armadillos",
  "Slow Loris",
  "Marmosets",
  "Tamarins",
  "Capybaras",
  "Miniature Pigs",
  "Miniature Horses",
  "Pygmy Goats",
  "Pot-Bellied Pigs",
  "Alpacas",
  "Llamas",
];

export const commonEmailDomains = [
  "bizmail.yahoo.com",
  "imap.aol.com",
  "mail.am0.yahoodns.net",
  "mail.aol.com",
  "mail.bf2.yahoo.com",
  "mail.g.yahoo.co.jp",
  "mail.g03.yahoodns.net",
  "mail.global.gm0.yahoodns.net",
  "mail.gm0.yahoodns.net",
  "mail.gq1.yahoo.com",
  "mail.ne1.yahoo.com",
  "mail.us.am0.yahoodns.net",
  "mail.vip.bf1.yahoo.com",
  "mail.vip.gq1.yahoo.com",
  "mail.vip.ne1.yahoo.com",
  "mail.yahoo.co.jp",
  "mail.yahoo.co.uk",
  "mail.yahoo.com",
  "mail.yahoo.com.au",
  "mail.yahoo.com.tw",
  "mailyahoo.com",
  "pop.att.yahoo.com",
  "postmaster.yahooinc.com",
  "yahoomail.co.nz",
  "yahoomail.com",
  "ymail.com",
  "gmail.com",
  "orkut.com",
  "hotmail.com",
  "outlook.com",
  "rediff.com",
  "yahoo.in",
  "protonmail.com",
  "zohomail.com",
  "yahoo.co",
  "aol.com",
  "yahoo.com",
];

export const usersToEnableActivityHistory = [
  "rabi gupta",
  "anish saha",
  "chintan reddy",
  "jetin prasanth",
  "satwick saxena",
  "pankit parikh",
  "bill mulligan",
  "sunil prem",
  "adina",
  "abhishek surbhat",
  "piyush gupta",
  "vaishali bhatia",
  "vaishali pillai",
  "ashish kumar"
]
