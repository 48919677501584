
const menuDetails = [
    {
        name: 'Home',
        iconName: "aeSideHomeIcon",
        route: '/analytics',
        dbValue: 'analytics',
        activeTab: 'analytics',
    },
    {
        name: 'Prospects',
        iconName: 'prospectreportIcon',
        route: '/prospectReport',
        dbValue: 'prospectreport',
        activeTab: 'prospectreport',
    },
    {
        name: 'Accounts',
        iconName: 'companyscoreIcon',
        route: '/companyScoreReport',
        dbValue: 'companyscorereport',
        activeTab: 'companyscorereport',
    },
    {
        name: 'Team Management',
        iconName: 'teammanagementIcon',
        route: '/teammanagement',
        dbValue: 'teammanagement',
        activeTab: 'teammanagement',
    },
    {
        name: 'Frameworks / Contexts',
        iconName: 'tagIcon',
        route: '/frameworkandcontext',
        dbValue: 'frameworkandcontext',
        activeTab: 'frameworkandcontext',
    },
    {
        name: 'Integrations',
        iconName: 'integrationIcon',
        route: '/integrations',
        dbValue: 'integration',
        activeTab: 'integration',
    },
    {
        name: 'AI Nudges',
        iconName: 'tagIcon',
        route: '/nudgesAI',
        dbValue: 'nudges',
        activeTab: 'nudges',
    },
    {
        name: 'Stakeholders',
        iconName: 'aeSideStakeIcon',
        route: '/aeReturnStakeholders',
        dbValue: 'stakeholders',
        activeTab: 'stakeholders',
    },
    {
        name: 'Activity History',
        iconName: 'aeSideHistoryIcon',
        route: '/aeReturnActivityHistory',
        dbValue: 'history',
        activeTab: 'history',
    },
    {
        name: 'Tag',
        iconName: 'tagIcon',
        route: '/stakeholdertag',
        dbValue: 'tag',
        activeTab: 'stakeholdertag',
    },
    {
        name: 'Email Template',
        iconName: 'aeSideAnalyticsIcon',
        route: '/emailtemplate',
        dbValue: 'emailtemplate',
        activeTab: 'emailtemplate',
    },
    {
        name: 'Member Listing',
        iconName: 'memberlistingIcon',
        route: '/memberlisting',
        dbValue: 'memberlisting',
        activeTab: 'memberlisting',
    },
    {
        name: 'Excel Upload',
        iconName: 'exceluploadIcon',
        route: '/prospectprocess',
        dbValue: 'prospectprocess',
        activeTab: 'prospectprocess',
    },
    {
        name: 'Email Templates',
        iconName: 'emailtemplateIcon',
        route: '/emailtemplates',
        dbValue: 'emailtemplates',
        activeTab: 'emailtemplates',
    },
    {
        name: 'Automated Sequence',
        iconName: 'emailtemplateIcon',
        route: '/automatedemail',
        dbValue: 'automatedemail',
        activeTab: 'automatedemail',
    },
    {
        name: 'Account Report',
        iconName: 'memberlistingIcon',
        route: '/accountreport',
        dbValue: 'accountreport',
        activeTab: 'accountreport',
    },
    {
        name: 'Admin Report',
        iconName: 'memberlistingIcon',
        route: '/adminreport',
        dbValue: 'adminreport',
        activeTab: 'adminreport',
    },

]


export default menuDetails;