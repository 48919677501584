import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";

function MemberListingTableHeader() {
  return (
    <Flex
      padding={"10px 15px"}
      fontSize="12px"
      fontWeight={600}
      position="sticky"
      backgroundColor={"#F8F8F8"}
      alignItems={"center"}
      borderTopLeftRadius={"8px"}
      borderTopRightRadius={"8px"}
      className="table-data-tm"
    >
      <Flex padding={"0 5px"} width="5%" margin="0 0 0 16px">
        #
      </Flex>
      <Flex padding={"0 5px"} width="50%">
        Name
      </Flex>
      <Flex padding={"0 5px"} width="15%" >
        User Role
      </Flex>
      {/* <Flex padding={"0 5px"} width="15%" >
        No. of Prospects
      </Flex> */}
      <Flex padding={"0 5px"} width="15%" >
        Actions
      </Flex>
      <Flex padding={"0 5px"} width="15%" margin="0 16px 0 0">
        Activate/Deactivate
      </Flex>
    </Flex>
  );
}

export default MemberListingTableHeader;
