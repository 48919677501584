import React, { FC } from "react";
import { SignupLayout } from "@evabot/eva-react-core-library/dist/templates/SignupLayout/SignupLayout";
import AdminLeftSectionNav from "components/AdminLeftSectionNav/AdminLeftSectionNav";
import { StakeHolderLayout } from "./StakeHolderLayout";

const StakeHolder: FC = () => {
  return (
    <>
      {
        //@ts-ignore
        <SignupLayout
          left={25}
          right={75}
          leftChildren={<AdminLeftSectionNav selectedTab={4} />}
          rightChildren={<StakeHolderLayout />}
        />
      }
    </>
  );
};

export default StakeHolder;
