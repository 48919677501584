import React, { FC } from 'react';
import {ProgressBar} from '@evabot/eva-react-core-library/dist/atoms/ProgressBar/ProgressBar';
import { Flex } from '@evabot/eva-react-core-library/dist/atoms/Flex/Flex';
import { Text } from '@evabot/eva-react-core-library/dist/atoms/Text/Text';
import { useAppSelector } from 'hooks';


const AdminProgressBar: FC = () => {

  const progress = useAppSelector(state => state.globalState.progress);

  return (
    <Flex flexDirection='column' marginBottom={'32px'} marginLeft={'32px'}>
      <Flex marginBottom={'8px'}>
          <Text id='setup.completion' color={'white'} fontSize={12}/>
          <Text color={'white'} fontSize={12} marginLeft={'5px'}>{progress}%</Text>
      </Flex>
      <ProgressBar value={progress}/>
    </Flex>
  )
}

export default AdminProgressBar;