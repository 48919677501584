
import { FC ,useCallback} from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Table } from "@evabot/eva-react-core-library/dist/atoms/Table/Table";
import { Tag } from "@evabot/eva-react-core-library/dist/molecules/Tag/Tag";
import { Avatar } from "@evabot/eva-react-core-library/dist/atoms/Avatar/Avatar";
import {Formik,Form} from "formik";
import { EmailEditField } from "@evabot/eva-react-core-library/dist/molecules/EmailEditField/EmailEditField";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";

interface OpportunityProps{}

const OpportunityTable: FC<OpportunityProps> = () => {

    const tableTitle: string[] = [];
    
    const headerSection=()=>{
        return(
            <tr style={{ textAlign: "left" }} >
                <th>
                    <Text variant="imputLabel">Stakeholder name</Text>
                </th>
                <th>
                    <Text variant="imputLabel">Email address</Text>
                </th>
                <th>
                    <Text variant="imputLabel">Designation</Text>
                </th>
                <th>
                    <Flex gap="10px">
                    <Text variant="imputLabel">Status</Text>
                    <Icon name="info" fill="#0094E3"/>
                    </Flex>
                   
                </th>
            </tr>
        )
    }  
    
      const sampleData = [
        {
          name: "Jakob Gouse",
          email: "demo@demo.com",
          designation: "Director",
          status: "Eva profile created",
          image: "https://www.newsbtc.com/wp-content/uploads/2020/06/mesut-kaya-LcCdl__-kO0-unsplash-scaled.jpg"
        },
        {
          name: "Jakob Gouse",
          email: "demo@demo.com",
          designation: "Director",
          status: "Eva profile created",
          image: "https://www.newsbtc.com/wp-content/uploads/2020/06/mesut-kaya-LcCdl__-kO0-unsplash-scaled.jpg"
        },
        {
          name: "Jakob Gouse",
          email: "demo@demo.com",
          designation: "VP",
          status: "Eva profile created",
          image: "https://www.newsbtc.com/wp-content/uploads/2020/06/mesut-kaya-LcCdl__-kO0-unsplash-scaled.jpg"
        },
        {
          name: "Jakob Gouse",
          email: "demo@demo.com",
          designation: "Director",
          status: "Eva profile created",
          image: "https://www.newsbtc.com/wp-content/uploads/2020/06/mesut-kaya-LcCdl__-kO0-unsplash-scaled.jpg"
        },
        {
          name: "Jakob Gouse",
          email: "demo@demo.com",
          designation: "VP",
          status: "Eva profile created",
          image: "https://www.newsbtc.com/wp-content/uploads/2020/06/mesut-kaya-LcCdl__-kO0-unsplash-scaled.jpg"
        },
        {
          name: "Jakob Gouse",
          email: "demo@demo.com",
          designation: "Director",
          status: "Eva profile created",
          image: "https://www.newsbtc.com/wp-content/uploads/2020/06/mesut-kaya-LcCdl__-kO0-unsplash-scaled.jpg"
        },
      ];

    const tableBody = () => {
        return sampleData.map((d) => {
          return (
            <tr>
              <td>
                <Flex gap="10px" alignItems="center">
                  <Avatar width="50px" height="50px" imageUrl={d.image} borderRadius="20px" />
                  <Flex gap="3px">
                    <Text
                      variant="imputLabel"
                    >
                      {d.name}
                    </Text>
                  </Flex>
                </Flex>
              </td>
              <td style={{display:"flex",alignItems:"center"}}>
              <Formik
              initialValues={{
                email: d.email,
              }}
              onSubmit={onSubmitHandler}
              enableReinitialize
            >
              {({ values, errors, initialValues }) => {
                console.log(errors, "error", values, initialValues);
                return (
                  <Form>
                    <EmailEditField
                      name="email"
                      width="min-content"
                      fillColor="#000"
                      leftIcon={{ iconName: "mail" }}
                    />
                  </Form>
                );
              }}
            </Formik>
              </td>
              <td style={{display:"flex",alignItems:"center"}}>
                <Text
                variant="imputLabel"
                
              >
                {d.designation}
              </Text>
              </td>
              <td style={{display:"flex",alignItems:"center"}}>
              <Box ml="-25px"> 
              <Tag color="#DD015B" bgColor="rgba(221, 1, 91, 0.1)" text={d.status} />
              </Box>
              </td>
            </tr>
    
          );
        });
      };

      const onSubmitHandler = useCallback(async (values: any) => {
        console.log(values, "values");
      }, []);
    return(
        <Table
        bodyHeight="400px"
        caption=""
        width="100%"
        headerSection={headerSection}
        titles={tableTitle}
        rows={tableBody}
        footer={null}
        borderLess
      />
    )
}

export default OpportunityTable