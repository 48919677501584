import { FC } from "react";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import EvaLogo from "./EvaLogo";

const AeForgotPasswordLeftSection: FC = () => {
  return (
    <Grid
      borderRadius="12px"
      width={"470px"}
      height={"670px"}
      style={{
        backgroundBlendMode: "soft-light",
        backdropFilter: "blur(45px)",
        background: "rgba(255, 255, 255, 0.05)",
      }}
      justifyContent={"center"}
      alignItems={"center"}
      position="relative"
    >
      <Grid>
        <EvaLogo />
        <Text
          color="white"
          opacity={1}
          zIndex="-1"
          variant="heading1"
          mt="40px"
        >
          Reset your
        </Text>
        <Text color="white" opacity={1} zIndex="-1" variant="heading1">
          password
        </Text>
        <Text mt="16px" color="white">
          Don't worry, We'll send you a password
        </Text>
        <Text color="white">rest link to change your password.</Text>
      </Grid>
    </Grid>
  );
};

export default AeForgotPasswordLeftSection;
