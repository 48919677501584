import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import React, { useState } from "react";
import { Backdrop } from "@mui/material";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Tooltip from '@mui/material/Tooltip';
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { axiosHelperCore } from "utils";



function CompanyScoreReportTableBodyField({ data, index }) {

    const [loading, setLoading] = useState(false);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [isShowMoreText, setIsShowMoreText] = useState(false); // Corrected the order
    const [hideDeleteModel, setHideDeleteModel] = useState(true);


    const deleteCompany = async () => {
        const response: any = await axiosHelperCore({
            url: "rapport/user/deleteCompany",
            method: "POST",
            JSONData: {
                companyDataId: data.companyDataId,
            },
            urlOverride: false,
        });

        console.log("responseresponseresponse", response);

        if (response?.data?.success) {
            setSnackbarMessage('Account has been removed');
            setSnackbarType('success');
            setShowSnackBar(true);
            //reFetch();
        } else {
            setSnackbarMessage('Error removing account');
            setSnackbarType('error');
            setShowSnackBar(true);
        }
        setHideDeleteModel(true);

    }

    const accountScore = data?.accountScore;

    const backgroundColor =
        accountScore >= 90 ? "#185217" :
            accountScore >= 80 ? "#20960A" :
                accountScore >= 60 ? "#40C900" :
                    '#A7B500';


    return (
        <>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            {loading && <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                open={true}
            >
                <Icon name="loadingIcon" />
            </Backdrop>}

            <ModalWrapper
                width="max-content"
                height="max-content"
                hideModal={hideDeleteModel}
                setHideModal={() => {
                    setHideDeleteModel(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="40px"
            >
                <Flex
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Text fontSize={"20px"} fontWeight={300}>
                        {" "}
                        Are you sure you want to remove this account ?
                    </Text>
                    <Flex mt="15px">
                        <Flex
                            background={"#DD015B"}
                            color={"#fff"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={deleteCompany}
                        >
                            Yes
                        </Flex>
                        <Flex
                            ml="16px"
                            border="1px solid #0094E3"
                            background={"#fff"}
                            color={"#0094E3"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => setHideDeleteModel(true)}
                        >
                            Cancel
                        </Flex>
                    </Flex>
                </Flex>
            </ModalWrapper>

            <Flex
                padding={"15px"}
                fontSize="12px"
                fontWeight={500}
                position="sticky"
                backgroundColor={index % 2 != 0 ? "#FFF" : "rgb(249, 249, 249)"}
                alignItems={"center"}
                borderRadius={index % 2 == 0 && "12px"}
                margin="0 16px"
            >
                <Flex width="2%" >
                    {index}
                </Flex>
                <Flex width="2%" >
                </Flex>

                <Flex style={{ "wordBreak": "break-word" }} width="20%" flexDirection={"column"}>
                    <Flex style={{ "textTransform": "capitalize" }} mb={"5px"}>
                        {data?.stakeholderCompany || "-"}
                    </Flex>
                </Flex>
                <Flex width="2%" >
                </Flex>
                <Flex width="20%" textTransform={"capitalize"}>
                    {data?.userId?.map((m, index) => (
                        <React.Fragment key={index}>
                            {m}
                            <br />
                        </React.Fragment>
                    ))}
                </Flex>
                <Flex style={{ "wordBreak": "break-word", "textAlign": "justify" }} width="35%" flexDirection={"column"}>
                    <Box width={"95%"}>
                        {data?.accountAnalysis?.length > 200 ? (
                            <>
                                {isShowMoreText ? data?.accountAnalysis : data?.accountAnalysis?.substring(0, 150) + "..."}
                                <br></br>
                                <a href="#" onClick={(event) => {
                                    event.preventDefault();
                                    setIsShowMoreText(!isShowMoreText);
                                }}
                                    style={{ color: '#0094E3' }}
                                >
                                    {!isShowMoreText ? "view more" : "view less"}
                                </a>
                            </>
                        ) : (
                            data?.accountAnalysis || "-"
                        )}
                    </Box>
                </Flex>
                <Flex width="10%" justifyContent={"flex-end"}>


                    {data?.scoreChange == "up" ? (<Tooltip title={"score went up by " + data?.scoreChangeValue + " points"}><div><ArrowUpwardIcon style={{ color: "green" }} /></div></Tooltip>) : (data?.scoreChange == "down" ? (<Tooltip title={"score went down by " + data?.scoreChangeValue + " points"}><div><ArrowDownwardIcon style={{ color: "red" }} /></div></Tooltip>) : "")}

                </Flex>
                <Flex width="10%" flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                    <Box style={{
                        color: '#FFF',
                        backgroundColor: backgroundColor,
                        borderRadius: '47px',
                        padding: '3px 4px',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '16px',
                        fontWeight: 500,
                        marginLeft: '16px'
                    }}>
                        {data?.accountScore || "-"}
                    </Box>
                </Flex>
               
            </Flex>
        </>
    );
}

export default CompanyScoreReportTableBodyField;
