import * as React from "react";

function TimeOutline(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" {...props}>
      <path
        d="M6.282 3.2H5.72a.094.094 0 00-.094.093v3.226c0 .03.014.059.039.076L7.6 8.01c.042.03.1.022.131-.02l.335-.457a.092.092 0 00-.02-.131l-1.67-1.209v-2.9a.094.094 0 00-.095-.094zm2.576.88l1.838.45a.094.094 0 00.116-.09l.01-1.894a.093.093 0 00-.152-.073L8.823 3.915a.094.094 0 00.035.165zm1.965 3.529l-.664-.229a.094.094 0 00-.118.057 4.118 4.118 0 01-.96 1.495 4.137 4.137 0 01-4.546.89 4.156 4.156 0 01-2.208-2.208A4.142 4.142 0 012.004 6a4.118 4.118 0 011.215-2.933 4.137 4.137 0 014.545-.89 4.137 4.137 0 011.653 1.268l.701-.548A5.025 5.025 0 006.148.96a5.03 5.03 0 00-5.035 5.09 5.038 5.038 0 009.768 1.68.096.096 0 00-.058-.122z"
        fill="#9F9F9F"
      />
    </svg>
  );
}

export default TimeOutline;
