import EmailTemplateTable from "./EmailTemplateTable";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";

function EmailTemplateContent() {

    return (

        <Flex flexDirection={"column"} padding="25px 32px" overflowY={"scroll"}>
            <EmailTemplateTable />
        </Flex>
    );
}

export default EmailTemplateContent;
