import React from 'react';

const NameFirstAlphabetBox = ({ name }) => {
  const getFirstLetter = () => {
    return name?.charAt(0).toUpperCase() || '-';
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const firstLetter = getFirstLetter();
  
  const boxStyle = {
    backgroundColor: '#CCCCCC',
    padding: '15px 20px',
    borderRadius: '10px',
    display: 'inline-block',
  };

  return (
    <div style={boxStyle}>
      <span style={{fontWeight: 'bold' }}>{firstLetter}</span>
    </div>
  );
};

export default NameFirstAlphabetBox;
