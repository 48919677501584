
import { FC ,useCallback} from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Table } from "@evabot/eva-react-core-library/dist/atoms/Table/Table";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { ListOpportunityTbodyField } from "./ListOpportunityTbodyField";

interface OpportunityProps{}

const OpportunityTableOption1: FC<OpportunityProps> = () => {

    const tableTitle: string[] = [];
    
    const headerSection=()=>{
        return(
            <tr style={{ textAlign: "left" }} >
                <th>
                    <Text variant="imputLabel">Stakeholder name</Text>
                </th>
                <th>
                    <Text variant="imputLabel">Email address</Text>
                </th>
                <th>
                    <Text variant="imputLabel">Designation</Text>
                </th>
                <th>
                    <Flex gap="10px">
                    <Text variant="imputLabel">Important stakeholder?</Text>
                    <Icon name="info" fill="#0094E3"/>
                    </Flex>
                   
                </th>
            </tr>
        )
    }  
    
      const sampleData = [
        {
          name: "Jakob Gouse",
          isStakeholder: "true",
          email: "demo@demo.com",
          designation: "Director",
          status: "Eva profile created",
          image: "https://www.newsbtc.com/wp-content/uploads/2020/06/mesut-kaya-LcCdl__-kO0-unsplash-scaled.jpg"
        },
        {
          name: "Jakob Gouse",
          isStakeholder: "true",
          email: "demo@demo.com",
          designation: "Director",
          status: "Eva profile created",
          image: "https://www.newsbtc.com/wp-content/uploads/2020/06/mesut-kaya-LcCdl__-kO0-unsplash-scaled.jpg"
        },
        {
          name: "Jakob Gouse",
          isStakeholder: "true",
          email: "demo@demo.com",
          designation: "VP",
          status: "Eva profile created",
          image: "https://www.newsbtc.com/wp-content/uploads/2020/06/mesut-kaya-LcCdl__-kO0-unsplash-scaled.jpg"
        }
      ];

      const tableBody = () => {
        return sampleData.map((data) => {
          return <ListOpportunityTbodyField tableField={data} />;
        });
      };
    
    return(
        <Table
        bodyHeight={sampleData?.length > 3 ? "300px" : " max-content"}
        caption=""
        width="100%"
        headerSection={headerSection}
        titles={tableTitle}
        rows={tableBody}
        footer={null}
        borderLess
      />
    )
}

export default OpportunityTableOption1