import { useEffect, useState } from "react";
import MemberListingTable from "./MemberListingTable";
import stakeHolderTagService from "services/stakeholderTagService";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { useAppSelector } from "hooks";
import { useDispatch } from "react-redux";
import { setTagViewVisibility } from "./store/memberListingReducer";
import TabView from "./TabView";


function MemberListingContent() {
    const isTagViewVisible = useAppSelector(
      (state) => state.memberListingState?.tabViewState.isTagViewVisible
    );
  
    const dispatch = useDispatch();
  
    useEffect(() => {
      return () => {
        dispatch(setTagViewVisibility(false));
      };
    }, [dispatch]);
  
    return (
      <Flex flexDirection={"column"} padding="25px 32px" overflowY={"scroll"}>
        {isTagViewVisible ? (
            <TabView />
        ) : (
            <MemberListingTable />
        )}
      </Flex>
    );
  }

export default MemberListingContent;
