import React, { useCallback, FC, useEffect, useState } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { ExpandableCard } from "@evabot/eva-react-core-library/dist/molecules/ExpandableCard/ExpandableCard";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { useAppDispatch } from "../../hooks";
import {
  setFieldMappingUsers,
  setFieldMappingUsersDefault,
  FieldMappingUsersState,
} from "./store/fieldMappingReducer";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Dropdown } from "@evabot/eva-react-core-library/dist/atoms/Dropdown/Dropdown";
import { fieldMappingSalesforceNameMapper } from "utils";
import { setInitialValue, valueChange } from "utils/constants";

const usersSchema = yup.object().shape({
  userId: yup.string().required("fieldmapping.user.id.valid"),
  name: yup.string().required("fieldmapping.user.name.valid"),
  emailAddress: yup.string().required("fieldmapping.user.email.valid"),
  phone: yup.string().required("fieldmapping.user.phone.valid"),
  designation: yup.string().required("fieldmapping.user.title.valid"),
});

interface FieldMappingUsersSectionProps {
  usersExpandedFlag: boolean;
  isUsersCompleteFlag: boolean;
  toggleIsUsersCompleteFlag: (e: any) => void;
  toggleUsersExpandedFlag: (e: any) => void;
  onSubmit?: () => void;
  allUserFieldsData: any;
  initialValue?: FieldMappingUsersState;
}

const defaultValues = {
  name: "",
  emailAddress: "",
  phone: "",
  designation: "",
  userId: "",
};

const FieldMappingUsersSection: FC<FieldMappingUsersSectionProps> = ({
  usersExpandedFlag,
  toggleUsersExpandedFlag,
  onSubmit,
  isUsersCompleteFlag,
  toggleIsUsersCompleteFlag,
  allUserFieldsData,
  initialValue,
}) => {
  const salesforceFieldLabels = allUserFieldsData.map((x) => {
    return {
      name: x.name,
      label: x.label,
    };
  });

  const state = salesforceFieldLabels.map((e) => e.label);
  const [StateValues, setStateValues] = useState(state);

  useEffect(() => {
    if (initialValue) {
      setInitialValue(initialValue, StateValues, setStateValues);
    }
  }, []);

  const handleFieldChange = useCallback(
    (stateValue, prevValue) => {
      valueChange(stateValue, prevValue, StateValues, setStateValues);
    },
    [StateValues]
  );

  const dispatch = useAppDispatch();

  const onSubmitHandler = useCallback(
    async (values: any) => {
      // @ts-ignore
      dispatch(
        setFieldMappingUsers(
          // @ts-ignore
          fieldMappingSalesforceNameMapper(values, allUserFieldsData)
        )
      );
      dispatch(setFieldMappingUsersDefault(values));
      toggleIsUsersCompleteFlag(true);
      // toggleUsersExpandedFlag(!usersExpandedFlag);
      onSubmit();
    },
    [dispatch, onSubmit, toggleIsUsersCompleteFlag, allUserFieldsData]
  );

  return (
    <>
      {
        //@ts-ignore
        <ExpandableCard
          headerText={"Users"}
          expanded={usersExpandedFlag}
          loaded={isUsersCompleteFlag}
          toggleCard={() => {
            toggleUsersExpandedFlag(!usersExpandedFlag);
          }}
        >
          <Flex flexDirection="column">
            <Flex>
              <Formik
                initialValues={initialValue ? initialValue : defaultValues}
                onSubmit={onSubmitHandler}
                enableReinitialize
                validationSchema={usersSchema}
              >
                {({
                  values,
                  errors,
                  dirty,
                  isValid,
                  setFieldValue,
                  submitForm,
                  initialValues,
                }) => {
                  return (
                    <>
                      {
                        //@ts-ignore
                        <Form>
                          <Flex alignItems="center" marginBottom={"-10px"}>
                            <Box width={"25vw"}>
                              <Text
                                id="fieldmapping.opportunity.fieldsineva"
                                variant="bodyRegular"
                                fontWeight={"500"}
                                marginBottom="12px"
                                color="textColor.primary"
                              />
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="userIdField"
                                placeholder="fieldmapping.users.userId"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-10px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Text
                                id="fieldmapping.opportunity.fieldsinsalesforce"
                                variant="bodyRegular"
                                fontWeight={"500"}
                                marginBottom="2px"
                                color="textColor.primary"
                              />
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="userId"
                                placeholder="fieldmapping.users.userId"
                                variantType="primary"
                                options={
                                  values?.userId?.length > 0
                                    ? [...StateValues, values?.userId].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.userId ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsUsersCompleteFlag(true);
                                    } else {
                                      toggleIsUsersCompleteFlag(false);
                                    }
                                  }
                                  handleFieldChange(valueField, values?.userId);
                                }}
                                defaultValue={values?.userId}
                              />
                            </Box>
                          </Flex>
                          <Flex alignItems="center" marginBottom={"-10px"}>
                            <Box width={"25vw"}>
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                label="fieldmapping.opportunity.fieldsineva"
                                name="nameField"
                                placeholder="fieldmapping.users.name"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-25px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="name"
                                placeholder="fieldmapping.users.name"
                                variantType="primary"
                                options={
                                  values?.name?.length > 0
                                    ? [...StateValues, values?.name].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.name ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsUsersCompleteFlag(true);
                                    } else {
                                      toggleIsUsersCompleteFlag(false);
                                    }
                                  }
                                  handleFieldChange(valueField, values?.name);
                                }}
                                defaultValue={values?.name}
                              />
                            </Box>
                          </Flex>
                          <Flex alignItems="center" marginBottom={"-10px"}>
                            <Box width={"25vw"}>
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="emailAddressField"
                                placeholder="fieldmapping.users.emailAddress"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-25px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="emailAddress"
                                placeholder="fieldmapping.users.emailAddress"
                                variantType="primary"
                                options={
                                  values?.emailAddress?.length > 0
                                    ? [
                                        ...StateValues,
                                        values?.emailAddress,
                                      ].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.emailAddress ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsUsersCompleteFlag(true);
                                    } else {
                                      toggleIsUsersCompleteFlag(false);
                                    }
                                  }
                                  handleFieldChange(
                                    valueField,
                                    values?.emailAddress
                                  );
                                }}
                                defaultValue={values?.emailAddress}
                              />
                            </Box>
                          </Flex>
                          <Flex alignItems="center" marginBottom={"-10px"}>
                            <Box width={"25vw"}>
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="phoneField"
                                placeholder="fieldmapping.users.phone"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-25px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="phone"
                                placeholder="fieldmapping.users.phone"
                                variantType="primary"
                                options={
                                  values?.phone?.length > 0
                                    ? [...StateValues, values?.phone].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.phone ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsUsersCompleteFlag(true);
                                    } else {
                                      toggleIsUsersCompleteFlag(false);
                                    }
                                  }
                                  handleFieldChange(valueField, values?.phone);
                                }}
                                defaultValue={values?.phone}
                              />
                            </Box>
                          </Flex>
                          <Flex alignItems="center" marginBottom={"-10px"}>
                            <Box width={"25vw"}>
                              <FormikInput
                                width={"100%"}
                                disabled
                                labelHide
                                name="designationField"
                                placeholder="fieldmapping.users.designation"
                              />
                            </Box>
                            <Flex
                              marginLeft={"45px"}
                              marginRight={"20px"}
                              marginTop={"-25px"}
                            >
                              <Icon name="arrowRight" fill="#9F9F9F" />
                            </Flex>
                            <Box width={"25vw"}>
                              <Dropdown
                                padding={"7.5px 0px !important"}
                                name="designation"
                                placeholder="fieldmapping.users.designation"
                                variantType="primary"
                                options={
                                  values?.designation?.length > 0
                                    ? [
                                        ...StateValues,
                                        values?.designation,
                                      ].sort()
                                    : StateValues
                                }
                                //@ts-ignore
                                onChange={(e, valueField) => {
                                  if (
                                    Object.values(initialValues)?.filter(
                                      (v) => v !== ""
                                    ).length
                                  ) {
                                    if (
                                      initialValues?.designation ===
                                      e?.target?.innerHTML
                                    ) {
                                      toggleIsUsersCompleteFlag(true);
                                    } else {
                                      toggleIsUsersCompleteFlag(false);
                                    }
                                  }
                                  handleFieldChange(
                                    valueField,
                                    values?.designation
                                  );
                                }}
                                defaultValue={values?.designation}
                              />
                            </Box>
                          </Flex>
                          <Flex marginTop={15} alignItems="center">
                            {/* {
                              //@ts-ignore
                              <Text
                                id="addnewfield"
                                cursor="pointer"
                                marginRight={16}
                                color={"#DD015B"}
                              />
                            } */}
                            <Button
                              title="saveandcontinue"
                              onClick={submitForm}
                              disabled={!dirty || !isValid}
                            />
                          </Flex>
                        </Form>
                      }
                    </>
                  );
                }}
              </Formik>
            </Flex>
          </Flex>
        </ExpandableCard>
      }
    </>
  );
};

export default FieldMappingUsersSection;
