import * as React from "react";

function ProfileAccountLogo(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M17.984 16.64a9.242 9.242 0 00-5.507-4.265 5.47 5.47 0 10-4.954 0 9.242 9.242 0 00-5.507 4.266.461.461 0 00.172.64.467.467 0 00.64-.172 8.281 8.281 0 0114.344 0 .469.469 0 00.64.172.46.46 0 00.172-.64zM5.47 7.5a4.531 4.531 0 119.062 0 4.531 4.531 0 01-9.062 0z"
        fill="#000"
      />
    </svg>
  );
}

export default ProfileAccountLogo;
