import React, { FC } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Tag } from "@evabot/eva-react-core-library/dist/molecules/Tag/Tag";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { AccordionHeadingLabel } from "./AccordionHeadingLabel";
import { AccordionHeadingValue } from "./AccordionHeadingValue";
import IncompleteMailAlert from "assets/icons/IncompleteMailAlert";
import CompleteMailAlert from "assets/icons/CompleteMailAlert";
import { Image } from "@evabot/eva-react-core-library/dist/atoms/Image/Image";
import PendingMailAlert from "assets/icons/PendingMailAlert";

export interface AccordionHeaderProps {
    headerImage?: any;
    opportunityName: string;
    companyName: string;
    value: any;
    opportunityStage: string;
    totalStakeholders: any;
    emailSended: Number;
    currentEmailStatus?: "active" | "inactive" | "pending";
    type: "AE1" | "AE2";
    emailIconVisible?: boolean;
}

export const AccordionHeader: FC<AccordionHeaderProps> = ({
    headerImage,
    opportunityName,
    companyName,
    value,
    opportunityStage,
    totalStakeholders,
    emailSended,
    currentEmailStatus,
    type,
    emailIconVisible = false
}) => {
    return (
        <Flex justifyContent={"space-between"} width="100%">
            <Flex gap="10px">
                {headerImage ? (<Image src={headerImage} borderRadius="20px" width="59px" height="59px" />) : null}
                <Grid gridGap={"3px"}>
                    <AccordionHeadingLabel value={"Opportunity name"} />
                    {type === "AE1" ? (<AccordionHeadingValue value={opportunityName} />) : (<Box ml="-5px">
                        <Tag color="#C95E22" bgColor="#FFEADE" text={opportunityName} />
                    </Box>)}
                </Grid>
            </Flex>
            <Grid gridGap={"3px"}>
                <AccordionHeadingLabel value={"Company name"} />
                <AccordionHeadingValue value={companyName} />
            </Grid>
            <Grid gridGap={"3px"}>
                <AccordionHeadingLabel value={"Value"} />
                <AccordionHeadingValue value={value} />
            </Grid>
            <Grid gridGap={"3px"}>
                <AccordionHeadingLabel value={"Opportunity stage"} />
                <AccordionHeadingValue value={opportunityStage} />
            </Grid>
            <Grid gridGap={"3px"}>
                <AccordionHeadingLabel value={"Total stakeholders"} />
                <AccordionHeadingValue value={totalStakeholders} />
            </Grid>
            <Flex alignItems="center" gap="6px">
                {emailIconVisible ? (
                    <>
                        {currentEmailStatus === "active" ? <CompleteMailAlert /> : null}
                        {currentEmailStatus === "inactive" ? <IncompleteMailAlert /> : null}
                        {currentEmailStatus === "pending" ? <PendingMailAlert /> : null}
                    </>
                ) : null}

                <Flex>
                    <Text variant="imputLabel" color="basicColors.basicRed">
                        {emailSended}
                    </Text>
                    <Text variant="imputLabel" color="basicColors.basicRed">
                        /
                    </Text>
                    <Text variant="imputLabel" color="basicColors.basicRed">
                        {totalStakeholders}
                    </Text>
                </Flex>
                <Text variant="imputLabel" color="textColor.primary">
                    first emails sent
                </Text>
            </Flex>
        </Flex>
    );
};
