import { Table } from "@evabot/eva-react-core-library/dist/atoms/Table/Table";
import React, { FC } from "react";
import { StakeHolderHeader } from "./StakeHolderHeader";
import { StakeHolderTbodyField } from "./StakeHolderTbodyField";

export interface StakeHolderTableProps {}

export const StakeHolderTable: FC<StakeHolderTableProps> = () => {
  const tableTitle: string[] = [
    "Stakeholder name",
    "Email address",
    "Designation",
    "Important stakeholder?",
  ];

  const sampleData = [
    {
      stakeholderName: "Jordyn ghhhhhh beijfbke sdkjbfsekje",
      email: "",
      imgUrl: "https://picsum.photos/id/1/200/300",
      designation: "VP",
      isStakeholder: "true",
    },
    {
      stakeholderName: "Jordyn Bator",
      email: "demo@demo.com",
      imgUrl: "https://picsum.photos/id/1/200/300",
      designation: "Director",
      isStakeholder: "true",
    },
    {
      stakeholderName: "Jordyn Bator",
      email: "demo@demo.com",
      imgUrl: "https://picsum.photos/id/1/200/300",
      designation: "Sales lead",
      isStakeholder: "true",
    },
    {
      stakeholderName: "Jordyn Bator",
      email: "demo@demo.com",
      imgUrl: null,
      designation: "VP",
      isStakeholder: "true",
    },
  ];

  const tableBody = () => {
    return sampleData.map((data) => {
      return <StakeHolderTbodyField tableField={data} />;
    });
  };

  return (
    <Table
      bodyHeight={sampleData?.length > 3 ? "300px" : " max-content"}
      caption=""
      width="100%"
      titles={tableTitle}
      rows={tableBody}
      footer={null}
      borderLess
      headerSection={() => <StakeHolderHeader />}
    />
  );
};
