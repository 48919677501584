import { FC, useState } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { VideoDemo } from "@evabot/eva-react-core-library/dist/molecules/VideoDemo/VideoDemo";
import { Header } from "@evabot/eva-react-core-library/dist/molecules/Header/Header";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Avatar } from "@evabot/eva-react-core-library/dist/atoms/Avatar/Avatar";
import { AccordionCard } from "../../components/Accordion/AccordionCard";
import AEListOpportunityAccordianSubSectionOption2 from "./AEListOpportunityAccordianSubSectionOption2";
import { AccordionHeader } from "components/Accordion/AccordianHeader";

interface OpportunityProps { }

const AEListOpportunityOption2: FC<OpportunityProps> = () => {
    const [toggle, setToggle] = useState<boolean>(false);
    const handleToggle = () => {
        setToggle(!toggle)
    }
    return (
        <Box>
            <Header>
                <Flex alignItems="center" gap="10px">
                    <VideoDemo locale="seedemo" setToggle={handleToggle} />
                    <Avatar
                        width="44px"
                        height="44px"
                        borderRadius="50%"
                        imageUrl="https://www.newsbtc.com/wp-content/uploads/2020/06/mesut-kaya-LcCdl__-kO0-unsplash-scaled.jpg"
                    />
                </Flex>
            </Header>
            <Box
                height="100vh"
                overflow="auto"
                backgroundColor="#EAEDF7"
                padding="20px 40px"
            >
                <Flex flexDirection="column" gap="10px" margin="15px 0">
                    <Flex gap="15px">
                        <Box
                            backgroundColor="#16124C"
                            borderRadius="8px"
                            padding="10px 15px"
                        >
                            <Text
                                fontWeight="500"
                                fontSize="16px"
                                color="#fff"
                            >Number of opportunities: 3</Text>
                        </Box>
                        <Box
                            backgroundColor="#1B9788"
                            borderRadius="8px"
                            padding="10px 15px"
                        >
                            <Text
                                fontWeight="500"
                                fontSize="16px"
                                color="#fff"
                            >Number of stakeholders: 12</Text>
                        </Box>
                    </Flex>
                    <Text
                        fontWeight="400"
                        fontSize="12px"
                    >
                        Your admin has approved the following opportunities. Let's begin by sending the first email to the important stakeholders.</Text>
                </Flex>
                <Flex flexDirection="column" gap="20px">
                    <AccordionCard
                        headerSection={() =>
                            <AccordionHeader
                                opportunityName="South region"
                               
                                companyName="Compass Press"
                                value="$450K"
                                opportunityStage="Verbal"
                                emailSended={1}
                                totalStakeholders="5"
                                currentEmailStatus="inactive"
                                type="AE2"
                            />}
                        subSection={() => <AEListOpportunityAccordianSubSectionOption2 />}
                    />

                    <AccordionCard
                        headerSection={() =>
                            <AccordionHeader
                                opportunityName="South region"
                               
                                companyName="Compass Press"
                                value="$450K"
                                opportunityStage="Verbal"
                                emailSended={5}
                                totalStakeholders="5"
                                currentEmailStatus="active"
                                type="AE2"
                                emailIconVisible={true}
                            />}
                        subSection={() => <AEListOpportunityAccordianSubSectionOption2 />}
                    />

                    <AccordionCard
                        headerSection={() =>
                            <AccordionHeader
                                opportunityName="South region"
                                
                                companyName="Compass Press"
                                value="$450K"
                                opportunityStage="Verbal"
                                emailSended={4}
                                totalStakeholders="5"
                                currentEmailStatus="pending"
                                type="AE2"
                            />}
                        subSection={() => <AEListOpportunityAccordianSubSectionOption2 />}
                    />
                </Flex>
                <Flex flexDirection="row" justifyContent="flex-end" gap="16px" mt="54px">
                    <Button iconAlign="right" iconName="arrowRight" >Continue to dashboard</Button>
                </Flex>
            </Box>
        </Box>
    )
}

export default AEListOpportunityOption2