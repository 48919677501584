import React, { FC } from "react";
import { SignupLayout } from "@evabot/eva-react-core-library/dist/templates/SignupLayout/SignupLayout";
import AdminLeftSectionNav from "components/AdminLeftSectionNav/AdminLeftSectionNav";
import { EmailTemplateLayout } from "./EmailTemplateLayout";
import { useAppSelector } from "hooks";

const EmailTemplateSetup: FC = () => {
  const journey = useAppSelector((state) => state.globalState.journey);

  return (
    <>
      {
        //@ts-ignore
        <SignupLayout
          left={25}
          right={75}
          leftChildren={
            <AdminLeftSectionNav
              selectedTab={journey?.journeyPath === "2" ? 4.1 : 4}
            />
          }
          rightChildren={<EmailTemplateLayout />}
        />
      }
    </>
  );
};

export default EmailTemplateSetup;
