import * as React from "react";

function AeSideHomeIcon(props) {
  return (
    <svg
      width={props?.size || "18px"}
      height={props?.size || "18px"}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M16.125 19.375H3.875A2.127 2.127 0 011.75 17.25V7.781c0-.625.281-1.218.781-1.625l6.125-5.062a2.08 2.08 0 012.688 0l6.125 5.031c.5.406.781 1 .781 1.625v9.469a2.134 2.134 0 01-2.125 2.156zM10 1.875a.936.936 0 00-.563.188L3.314 7.125A.88.88 0 003 7.781v9.469c0 .469.375.875.875.875h12.25A.873.873 0 0017 17.25V7.781a.88.88 0 00-.313-.656l-6.125-5.063A.936.936 0 0010 1.875z"
        fill={props?.fill || "#fff"}
      />
      <path
        d="M13.75 16.625h-7.5A.627.627 0 015.625 16c0-.344.281-.625.625-.625h7.5c.344 0 .625.281.625.625a.627.627 0 01-.625.625z"
        fill={props?.fill || "#fff"}
      />
    </svg>
  );
}

export default AeSideHomeIcon;
