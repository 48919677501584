import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import StakeholderTagTableBodyField from "./StakeholderTagTableBodyField";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";

function StakeholderTagTableBody(data, deleteTag) {
  return (
    <Box background="#fff" width="100%">
      {data.tagList.map((item, index) => (
        <StakeholderTagTableBodyField data={item} index={index} />
      ))}
    </Box>
  );
}

export default StakeholderTagTableBody;
