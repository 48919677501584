import * as React from "react";

function IncompleteMailAlert(props) {
  return (
    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" {...props}>
      <rect width="43" height="43" rx="21.5" fill="#E0F7FE" />
      <path
        d="M9.89853 13.5037L18.8218 20.7633C18.8514 20.7876 18.8837 20.8088 18.9175 20.8257C19.0365 20.8865 19.1624 20.9172 19.2925 20.9172C19.43 20.9172 19.5676 20.8812 19.7686 20.7596L28.685 13.5042C28.8172 13.3968 28.8907 13.2339 28.8844 13.0641C28.878 12.8943 28.7923 12.7371 28.6527 12.6398C28.2872 12.3849 27.8592 12.25 27.4165 12.25H11.1665C10.7237 12.25 10.2963 12.3844 9.93082 12.6393C9.79118 12.7361 9.70546 12.8932 9.69911 13.0636C9.69276 13.2334 9.76629 13.3963 9.89853 13.5037Z"
        fill="#ACCAD3"
      />
      <path
        d="M22 25.792C22 21.9094 25.159 18.7504 29.0417 18.7504C29.3411 18.7504 29.5834 18.5081 29.5834 18.2087V15.3094C29.5834 15.1004 29.4633 14.91 29.2745 14.8201C29.0856 14.7323 28.8624 14.7582 28.7 14.8895L20.3893 21.6523L20.2941 21.7153C19.6693 22.0988 18.9018 22.1184 18.1967 21.6529L9.88339 14.8888C9.72206 14.7571 9.49882 14.7307 9.3089 14.8195C9.1201 14.9095 9 15.0999 9 15.3089V25.2504C9 26.4453 9.9717 27.417 11.1667 27.417H21.5446C21.7017 27.417 21.8509 27.3488 21.954 27.2303C22.0567 27.1118 22.1032 26.9542 22.081 26.7987C22.0259 26.4135 22 26.0935 22 25.792Z"
        fill="#ACCAD3"
      />
      <path
        d="M29.0418 19.833C25.7564 19.833 23.0835 22.5059 23.0835 25.7913C23.0835 29.0768 25.7564 31.7497 29.0418 31.7497C32.3273 31.7497 35.0001 29.0768 35.0001 25.7914C35.0001 22.5059 32.3273 19.833 29.0418 19.833ZM29.0418 30.1247C28.7427 30.1247 28.5001 29.8822 28.5001 29.583C28.5001 29.2838 28.7427 29.0413 29.0418 29.0413C29.341 29.0413 29.5835 29.2838 29.5835 29.583C29.5834 29.8822 29.3409 30.1247 29.0418 30.1247ZM29.5834 26.8747C29.5834 27.1741 29.3412 27.4164 29.0418 27.4164C28.7424 27.4164 28.5001 27.1741 28.5001 26.8747V22.5414C28.5001 22.242 28.7424 21.9997 29.0418 21.9997C29.3412 21.9997 29.5835 22.242 29.5835 22.5414V26.8747H29.5834Z"
        fill={props?.fill || "#F78720"}
      />
    </svg>
  );
}

export default IncompleteMailAlert;
