import React, {FC} from 'react';
import { SignupLayout } from '@evabot/eva-react-core-library/dist/templates/SignupLayout/SignupLayout';
import AdminLeftSectionNav from 'components/AdminLeftSectionNav/AdminLeftSectionNav';
import { InviteUsersLayout } from './InviteUsersLayout';

const InviteUsersLayoutComponent: FC = () => {
  return (
    <>
    {//@ts-ignore
      <SignupLayout 
        left={25} right={75} 
        leftChildren={<AdminLeftSectionNav selectedTab={5}/>} 
        rightChildren={<InviteUsersLayout />} />}
    </>
  )
}

export default InviteUsersLayoutComponent;