import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";

function MemberListingTableHeader() {
  return (
    <Flex
      padding={"10px 15px"}
      fontSize="12px"
      fontWeight={500}
      position="sticky"
      backgroundColor={"#F8F8F8"}
      alignItems={"center"}
    >
      <Flex padding={"0 5px"} width="5%" >
        #
      </Flex>
      <Flex padding={"0 5px"} width="20%" >
        User
      </Flex>
      <Flex padding={"0 5px"} width="10%" >
        Integration
      </Flex>
      <Flex padding={"0 5px"} width="10%">
        Organization
      </Flex>
      <Flex padding={"0 5px"} width="15%" >
        User Role
      </Flex>
      {/* <Flex padding={"0 5px"} width="15%" alignItems={"center"}>
        Access Menu
      </Flex> */}
      <Flex padding={"0 5px"} width="10%" >
        Enable/Disable
      </Flex>
      <Flex width="10%" >Registered On</Flex>
      <Flex padding={"0 5px"} width="20%" >
        Actions
      </Flex>
    </Flex>
  );
}

export default MemberListingTableHeader;
