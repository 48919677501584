import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import React, { FC } from "react";

export interface AccordionHeadingValueProps {
  value: string;
}

// bodyMedium
// font weight

export const AccordionHeadingValue: FC<AccordionHeadingValueProps> = ({
  value,
}) => {
  return (
    <Text fontSize={"14px"} fontWeight="bold" color="textColor.primary">
      {value}
    </Text>
  );
};
