import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";

interface AeReturnOppoTableHeaderProps {}

const AeReturnOppoTableHeader: React.FC<AeReturnOppoTableHeaderProps> = () => {
  return (
    <Grid margin={"0px 15px"} fontSize="12px" fontWeight={500} color="#57585C">
      <tr>
        <Box width={"5%"}>
          <td>#</td>
        </Box>
        <Box width={"22%"}>
          <td>Opportunity name</td>
        </Box>
        <td>Opportunity stage</td>
        <td>Opportunity value</td>
        {/* <Box width={"10%"}> */}
        <td>#Stakeholders</td>
        {/* </Box> */}
        <td>Approved budget</td>
        <td>Available budget</td>
      </tr>
    </Grid>
  );
};

export default AeReturnOppoTableHeader;
