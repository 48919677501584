import { AeReturnJourneyLayout } from "@evabot/eva-react-core-library/dist/templates/AeReturnJourneyLayout/AeReturnJourneyLayout";
import { AeSidebarSection } from "components/AeSidebar/AeSidebarSection";
import { AeSidebarSectionCollapse } from "components/AeSidebar/AeSidebarSectionCollapse";
import ProfileModalData from "containers/AEReturnDashboard/ProfileModalData";
import { useAppSelector } from "hooks";
import ProfileSection from "./ProfileSection";
import { axiosHelper } from "utils";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  aeReturnProfileData,
  aeReturnProfileDataLoading,
} from "./store/aeReturnProfileReducer";
import { useNavigate } from "react-router";
import { checkUnauthorized } from "utils/constants";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";

function AEReturnProfile() {
  const profileData = useAppSelector((state) => state.profileState.profileData);
  const loading = useAppSelector((state) => state.profileState.loading);
  const dispatch = useDispatch();
  const journeyData = useAppSelector((state) => state.globalState.journey);
  const navigate = useNavigate();

  // const fetchProfile = useCallback(async () => {
  //   try {
  //     //@ts-ignore
  //     dispatch(aeReturnProfileDataLoading(true));
  //     const result = await axiosHelper({
  //       url: `salesforce/getMemberInfoForProfile`,
  //     });
  //     const data = result.data.data;
  //     const formData = {
  //       firstName: data?.identityPersonal?.firstName,
  //       lastName: data?.identityPersonal?.lastName,
  //       email: data?.identityContact?.email,
  //       designation: "",
  //       comapnyName: data?.identityBusiness?.name,
  //       phoneNo: data?.identityContact?.mobile,
  //       imageFile: data?.identityPersonal?.image,
  //     };
  //     dispatch(aeReturnProfileData(formData));
  //     //@ts-ignore
  //     dispatch(aeReturnProfileDataLoading(false));
  //   } catch (err) {
  //     console.log(err);
  //     checkUnauthorized(err, journeyData, navigate);
  //   }
  // }, [dispatch, navigate]);

  // useEffect(() => {
  //   fetchProfile();
  // }, []);
  const [showSnackBar, setShowSnackBar] = useState(false);

  return (
    <>
      <AeReturnJourneyLayout
        as="div"
        left={20}
        right={82}
        leftChildren={<AeSidebarSection activeTab="opportunities" />}
        leftCollapsedChildren={
          <AeSidebarSectionCollapse activeTab="opportunities" />
        }
        rightChildren={
          <>
            <SnackBar
              iconName={"paperPlane"}
              setShowSnackBar={setShowSnackBar}
              show={showSnackBar}
              message={`Profile details are updated successfully`}
              timeout={2000}
              anchor={"top-right"}
              type={"success"}
              bgColorr="#24B300"
            />
            {loading ? (
              <Icon name="loadingIcon" />
            ) : (
              <ProfileSection
                initialValue={profileData}
                setShowSnackBar={setShowSnackBar}
              />
            )}
          </>
        }
        profileModalChild={<ProfileModalData />}
        imageUrl={profileData?.imageFile}
      />
    </>
  );
}

export default AEReturnProfile;
