import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

const initialState = {
  loginFormData: {
    emailAddress: "",
    password: "",
  },
};

export interface aeLoginFormReducerType {
  emailAddress: string;
  password: string;
}

const aeLoginFormReducerSlice = createSlice({
  name: "loginFormState",
  initialState: initialState,
  reducers: {
    aeLoginFormData: (state, action: PayloadAction<aeLoginFormReducerType>) => {
      state.loginFormData = action.payload;
    },
  },
});

export const { aeLoginFormData } = aeLoginFormReducerSlice.actions;

export const aeReturnProfileState = (state: RootState) => state.loginFormState;

export default aeLoginFormReducerSlice.reducer;
