import { FC  } from "react";
import { RightSideModal } from "@evabot/eva-react-core-library/dist/molecules/RightSideModal/RightSideModal";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { ToolTip } from "@evabot/eva-react-core-library/dist/molecules/ToolTip/ToolTip";
interface CreateEmailModalProps {
    showCreateEmailModal : any;
    emailContent:  any;
    setShowCreateEmailModal : any;
    setShowCopiedText : any;
    setEmailContent: any;
    setOriginalEmailContent:any;
    showCopiedText: any;
    originalEmailContent: any;
    loadEmailEditorScreen : any;
    selectedNudge:any;
}

const CreateEmailModal : FC<CreateEmailModalProps> = ({ showCreateEmailModal , emailContent , setShowCreateEmailModal , setShowCopiedText , setEmailContent , setOriginalEmailContent , showCopiedText , originalEmailContent , loadEmailEditorScreen , selectedNudge  }) => {
    return (
<RightSideModal
        open={showCreateEmailModal}
        onClose={() => {
          setShowCreateEmailModal(false);
          setShowCopiedText(false);
          setEmailContent("");
          setOriginalEmailContent("");
        }}
      >
        <Box overflowY="scroll" height="100vh">
          <Flex
            flexDirection={"column"}
            justifyContent={"flex-start"}
            padding={"20px 42px"}
          >
            <Text
              color={"#050505"}
              fontWeight={"300"}
              fontSize={"20px"}
              lineHeight={"140%"}
            >
              {" "}
              Email editor{" "}
            </Text>
          </Flex>
          <Box
            border={"1px solid rgba(0, 0, 0, 0.1)"}
            width="100%"
            marginBottom={"32px"}
          >
            {" "}
          </Box>
          <Flex
            flexDirection={"column"}
            gridRowGap={"4px"}
            padding={"20px 42px"}
          >
            <Flex
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Text color={"#050505"} fontWeight={"400"} fontSize={"14px"}>
                {" "}
                Your email{" "}
              </Text>
              <Flex
                flexDirection={"row"}
                gridColumnGap={"4px"}
                alignItems={"center"}
              >
               
                <ToolTip
                  color="black"
                  bgColor="#FFFFFF"
                  boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
                  direction="top-start"
                  title={
                    <>
                      <Text> Copy </Text>
                    </>
                  }
                >
                  <Box
                    cursor="pointer"
                    position={"relative"}
                    onClick={() => {
                      navigator.clipboard.writeText(emailContent);
                      setShowCopiedText(true);
                      setTimeout( () => {
                       setShowCopiedText(false);
                      } , 1000 );
                    }}
                  >
                    <Flex flexDirection={"row"} gridColumnGap={"4px"} alignItems={"center"} >
                    { showCopiedText && (
                       <Box
                       position={"absolute"}
                       background={"#24B300"}
                       borderRadius={"8px"}
                       zIndex={"2000"}
                       padding={"4px 16px"}
                       right={"0px"}
                       top={"-10px"}
                     >
                       <Flex
                         flexDirection={"row"}
                         alignItems={"center"}
                         gridColumnGap={"4px"}
                       >
                         <Icon name="doubleTick" stroke={"#fff"} />
                         <Text
                           color={"#FFFFFF"}
                           fontWeight={"400"}
                           fontSize={"14px"}
                         >
                           {" "}
                           Copied!{" "}
                         </Text>
                       </Flex>
                     </Box>
                    )  }  
                    { !showCopiedText && <Icon name="editorCopy" /> }
                    </Flex>
                    {/* {showCopiedText && (
                      <Box
                        position={"absolute"}
                        background={"#24B300"}
                        borderRadius={"8px"}
                        zIndex={"2000"}
                        padding={"4px 16px"}
                        right={"0px"}
                        top={"-4px"}
                      >
                        <Flex
                          flexDirection={"row"}
                          alignItems={"center"}
                          gridColumnGap={"4px"}
                        >
                          <Icon name="doubleTick" stroke={"#fff"} />
                          <Text
                            color={"#FFFFFF"}
                            fontWeight={"400"}
                            fontSize={"14px"}
                          >
                            {" "}
                            Copied!{" "}
                          </Text>
                        </Flex>
                      </Box>
                    )}
                    <Icon name="editorCopy" /> */}
                  </Box>
                </ToolTip>

               
                <ToolTip
                  color="black"
                  bgColor="#FFFFFF"
                  boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
                  direction="top-start"
                  title={
                    <>
                      <Text> Regenerate </Text>
                    </>
                  }
                >
                  <Box
                    cursor="pointer"
                    onClick={() => loadEmailEditorScreen(selectedNudge)}
                  >
                    <Icon name="editorLooping" />
                  </Box>
                </ToolTip>

               
                <ToolTip
                  color="black"
                  bgColor="#FFFFFF"
                  boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
                  direction="top-start"
                  title={
                    <>
                      <Text> Undo </Text>
                    </>
                  }
                >
                  <Box
                    cursor="pointer"
                    onClick={() => setEmailContent(originalEmailContent)}
                  >
                    <Icon name="editorUndo" />
                  </Box>
                </ToolTip>

             
              </Flex>
            </Flex>
            <textarea
              style={{
                borderRadius: "4px",
                border: "1px solid rgba(0, 0, 0, 0.1)",
                height: "411px",
                width: "100%",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "180%",
                padding: "24px 16px",
              }}
              onChange={(evt) => setEmailContent(evt?.target?.value)}
              value={emailContent}
            />
            <Text
              color={"#777777"}
              fontWeight={"400"}
              fontSize={"12px"}
              alignSelf={"flex-end"}
            >
              {" "}
              {emailContent.length} chars{" "}
            </Text>
          </Flex>
        </Box>
      </RightSideModal>
    )
}
export default CreateEmailModal;

