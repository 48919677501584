import * as React from "react";

function TimerIcon(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_4515)">
<path d="M15.1112 7.95555C15.1112 8.26666 15.1112 8.57777 15.0668 8.88888C15.0223 9.1111 14.8001 9.28888 14.5779 9.28888C14.3557 9.28888 14.1779 9.02221 14.1779 8.79999C14.2223 8.48888 14.2223 8.22221 14.2223 7.95555C14.2223 4.53332 11.4223 1.73332 8.0001 1.73332C7.73343 1.73332 7.46677 1.73332 7.15566 1.77777C6.93343 1.82221 6.66677 1.64443 6.66677 1.42221C6.62232 1.19999 6.8001 0.933324 7.02232 0.933324C10.9334 0.39999 14.489 3.15555 15.0223 7.02221C15.1112 7.33332 15.1112 7.64443 15.1112 7.95555Z" fill="white"/>
<path d="M5.60004 13.6889C5.11115 13.4667 4.62226 13.2 4.17782 12.8889C4.00004 12.7555 3.68893 12.7555 3.55559 12.9778C3.42226 13.1555 3.42226 13.4667 3.64448 13.6C4.13337 13.9555 4.66671 14.3111 5.24448 14.5333C5.46671 14.6222 5.73337 14.5333 5.82226 14.3111C5.95559 14.0444 5.86671 13.7778 5.60004 13.6889Z" fill="white"/>
<path d="M3.91111 3.11109C4 3.11109 4.08889 3.06665 4.17778 3.0222C4.62222 2.71109 5.06666 2.39998 5.55555 2.2222C5.77778 2.13331 5.91111 1.86665 5.82222 1.64442C5.73333 1.4222 5.46666 1.28887 5.24444 1.37776C4.66666 1.59998 4.13333 1.91109 3.64444 2.31109C3.46666 2.48887 3.42222 2.75554 3.55555 2.93331C3.64444 3.06665 3.77778 3.11109 3.91111 3.11109Z" fill="white"/>
<path d="M2.26682 10.3555C2.17793 10.1333 1.91126 9.99999 1.68904 10.0889C1.46682 10.1778 1.33349 10.4444 1.42237 10.6667C1.6446 11.2444 1.95571 11.7778 2.35571 12.2667C2.48904 12.4444 2.80015 12.4889 2.97793 12.3555C3.15571 12.2222 3.20015 11.9111 3.06682 11.7333C2.75571 11.3333 2.4446 10.8444 2.26682 10.3555Z" fill="white"/>
<path d="M1.82225 8.79999C1.73337 8.26666 1.73337 7.73332 1.82225 7.15555C1.8667 6.93332 1.68892 6.7111 1.42225 6.66666C1.15559 6.62221 0.97781 6.79999 0.933366 7.06666C0.844477 7.68888 0.844477 8.3111 0.933366 8.93332C0.97781 9.15555 1.15559 9.33332 1.37781 9.33332H1.42225C1.68892 9.24444 1.8667 9.02221 1.82225 8.79999Z" fill="white"/>
<path d="M1.64447 5.82222C1.86669 5.91111 2.13336 5.82222 2.22225 5.6C2.44447 5.11111 2.71114 4.62222 3.02225 4.17778C3.15558 4 3.15558 3.68889 2.93336 3.55555C2.75558 3.42222 2.48891 3.46666 2.35558 3.64444C2.00002 4.13333 1.68891 4.66666 1.42225 5.24444C1.33336 5.46666 1.42225 5.73333 1.64447 5.82222Z" fill="white"/>
<path d="M11.7778 12.8889C11.3333 13.2 10.8889 13.5111 10.3555 13.6889C10.1333 13.7778 9.99999 14.0444 10.0889 14.2666C10.1778 14.4889 10.4444 14.6222 10.6667 14.5333C11.2444 14.3111 11.7778 14 12.2667 13.6C12.4444 13.4666 12.4889 13.2 12.3555 12.9778C12.2667 12.8 12 12.7555 11.7778 12.8889Z" fill="white"/>
<path d="M8.80005 14.1333C8.26672 14.2222 7.73339 14.2222 7.15561 14.1333C6.93339 14.0889 6.71116 14.2667 6.66672 14.5333C6.62227 14.7556 6.80005 14.9778 7.06672 15.0222C7.68894 15.1111 8.31116 15.1111 8.93339 15.0222C9.15561 14.9778 9.33338 14.7556 9.33338 14.5333C9.28894 14.2667 9.06672 14.0889 8.80005 14.1333Z" fill="white"/>
<path d="M14.3111 10.1333C14.0888 10.0444 13.8222 10.1333 13.7333 10.3556C13.5111 10.8444 13.2444 11.3333 12.9333 11.7778C12.7999 11.9556 12.7999 12.2667 13.0222 12.4C13.2444 12.5333 13.5111 12.5333 13.6444 12.3111C14.0444 11.8222 14.3555 11.2889 14.5777 10.7111C14.6666 10.4889 14.5333 10.2222 14.3111 10.1333Z" fill="white"/>
<path d="M8.00011 4C7.73344 4 7.55566 4.17778 7.55566 4.44444V8C7.55566 8.26667 7.73344 8.44444 8.00011 8.44444H11.5557C11.8223 8.44444 12.0001 8.26667 12.0001 8C12.0001 7.73333 11.8223 7.55556 11.5557 7.55556H8.44455V4.44444C8.44455 4.17778 8.26678 4 8.00011 4Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_1_4515">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>

  );
}

export default TimerIcon;
