import * as React from "react";

function LikeBirthday(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      
    >
      <g clip-path="url(#clip0_1_4535)">
        <path
          d="M16.1375 11.9596H10.0446C9.72705 11.9596 9.4488 11.7472 9.36508 11.441C9.28136 11.1347 9.41289 10.8103 9.68622 10.6487C12.3409 9.08006 13.7198 7.25025 13.9017 5.05472C13.9651 4.29276 14.6438 3.68414 15.4142 3.69422C16.2003 3.70678 16.9367 4.02187 17.4883 4.58156C18.0403 5.14176 18.3445 5.88361 18.3448 6.67036C18.3448 6.67045 18.3448 6.67054 18.3448 6.67064V6.90731C18.3448 8.82918 17.7755 10.0546 16.7252 11.6436C16.5947 11.8409 16.374 11.9596 16.1375 11.9596Z"
          fill="#FFC380"
        />
        <path
          d="M23.6045 12.758C23.6045 11.5409 22.6143 10.5508 21.3972 10.5508H10.0445C9.91838 10.5508 9.79454 10.5846 9.68602 10.6488C8.8891 11.12 8.08445 11.5079 7.4379 11.8197C7.21238 11.9284 6.99938 12.0311 6.80565 12.1279C6.56701 12.2473 6.41626 12.4911 6.41626 12.758V21.7748C6.41626 22.0417 6.56701 22.2856 6.80565 22.4049C9.65204 23.8279 12.0181 24 14.7772 24C15.2045 24 15.6414 23.9959 16.0908 23.9916C16.5878 23.987 17.1016 23.9821 17.6402 23.9821H19.8672C21.0843 23.9821 22.0745 22.9919 22.0745 21.7748C22.0745 21.3656 21.9626 20.9821 21.7677 20.6532C22.4019 20.2649 22.8259 19.5657 22.8259 18.7692C22.8259 18.36 22.714 17.9765 22.5191 17.6475C23.1533 17.2593 23.5773 16.5601 23.5773 15.7636C23.5773 15.1911 23.3582 14.6689 22.9996 14.2763C23.3902 13.8647 23.6045 13.3279 23.6045 12.758Z"
          fill="#FFCF99"
        />
        <path
          d="M22.1391 6.61484H20.6363C20.2473 6.61484 19.9319 6.29942 19.9319 5.9104C19.9319 5.52139 20.2473 5.20596 20.6363 5.20596H22.1391C22.5282 5.20596 22.8436 5.52139 22.8436 5.9104C22.8436 6.29942 22.5282 6.61484 22.1391 6.61484Z"
          fill="#24B300"
        />
        <path
          d="M11.6196 6.61484H10.1168C9.72778 6.61484 9.41235 6.29942 9.41235 5.9104C9.41235 5.52139 9.72778 5.20596 10.1168 5.20596H11.6196C12.0086 5.20596 12.324 5.52139 12.324 5.9104C12.324 6.29942 12.0087 6.61484 11.6196 6.61484Z"
          fill="#24B300"
        />
        <path
          d="M13.8746 2.71057C13.631 2.71057 13.3943 2.58425 13.2639 2.35826L12.5125 1.05678C12.3179 0.719887 12.4334 0.289012 12.7703 0.0945271C13.1072 -0.100004 13.538 0.015449 13.7325 0.35234L14.4839 1.65382C14.6785 1.99071 14.563 2.42159 14.2261 2.61607C14.1152 2.68015 13.994 2.71057 13.8746 2.71057Z"
          fill="#24B300"
        />
        <path
          d="M18.3816 2.71059C18.2621 2.71059 18.1409 2.68016 18.03 2.61609C17.6932 2.42155 17.5777 1.99073 17.7722 1.65384L18.5236 0.352351C18.7181 0.0154602 19.149 -0.100087 19.4859 0.0945383C19.8228 0.28907 19.9382 0.719898 19.7437 1.05679L18.9923 2.35827C18.8619 2.58426 18.6251 2.71059 18.3816 2.71059Z"
          fill="#24B300"
        />
        <path
          d="M4.81 20.2809V23.991H3.40112V20.2809C3.40112 19.8921 3.71673 19.5765 4.10556 19.5765C4.49439 19.5765 4.81 19.8921 4.81 20.2809Z"
          fill="#73C3FF"
        />
        <path
          d="M22.1391 6.61484H20.6363C20.2473 6.61484 19.9319 6.29942 19.9319 5.9104C19.9319 5.52139 20.2473 5.20596 20.6363 5.20596H22.1391C22.5282 5.20596 22.8436 5.52139 22.8436 5.9104C22.8436 6.29942 22.5282 6.61484 22.1391 6.61484Z"
          fill="white"
        />
        <path
          d="M11.6196 6.61484H10.1168C9.72778 6.61484 9.41235 6.29942 9.41235 5.9104C9.41235 5.52139 9.72778 5.20596 10.1168 5.20596H11.6196C12.0086 5.20596 12.324 5.52139 12.324 5.9104C12.324 6.29942 12.0087 6.61484 11.6196 6.61484Z"
          fill="white"
        />
        <path
          d="M13.8746 2.71057C13.631 2.71057 13.3943 2.58425 13.2639 2.35826L12.5125 1.05678C12.3179 0.719887 12.4334 0.289012 12.7703 0.0945271C13.1072 -0.100004 13.538 0.015449 13.7325 0.35234L14.4839 1.65382C14.6785 1.99071 14.563 2.42159 14.2261 2.61607C14.1152 2.68015 13.994 2.71057 13.8746 2.71057Z"
          fill="white"
        />
        <path
          d="M18.3816 2.71059C18.2621 2.71059 18.1409 2.68016 18.03 2.61609C17.6932 2.42155 17.5777 1.99073 17.7722 1.65384L18.5236 0.352351C18.7181 0.0154602 19.149 -0.100087 19.4859 0.0945383C19.8228 0.28907 19.9382 0.719898 19.7437 1.05679L18.9923 2.35827C18.8619 2.58426 18.6251 2.71059 18.3816 2.71059Z"
          fill="white"
        />
        <path
          d="M4.81 20.2809V23.991H3.40112V20.2809C3.40112 19.8921 3.71673 19.5765 4.10556 19.5765C4.49439 19.5765 4.81 19.8921 4.81 20.2809Z"
          fill="#73C3FF"
        />
        <path
          d="M7.11115 10.5508H1.09995C0.711117 10.5508 0.395508 10.8664 0.395508 11.2552V23.2776C0.395508 23.6669 0.711117 23.982 1.09995 23.9909H5.60834C6.47196 23.982 7.22102 23.4838 7.58313 22.7596C7.73201 22.4632 7.81559 22.1284 7.81559 21.7748V11.2552C7.81559 10.8663 7.49998 10.5508 7.11115 10.5508Z"
          fill="#44A4EC"
        />
        <path
          d="M4.81 20.234V23.991H3.40112V20.234C3.40112 19.8451 3.71673 19.5295 4.10556 19.5295C4.49439 19.5295 4.81 19.8451 4.81 20.234Z"
          fill="#73C3FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_4535">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoLikeBirthday = React.memo(LikeBirthday);
export default MemoLikeBirthday;
