import * as React from "react";

function SuccessIcon(props) {
  return (
    <svg width="80px" height="80px" viewBox="0 0 82 82" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_7739_65911)">
        <path
          d="M59.514 73.063c-5.445 3.155-11.771 4.95-18.514 4.95C20.564 78.012 3.988 61.435 3.988 41 3.988 20.564 20.564 3.988 41 3.988c6.743 0 13.07 1.81 18.514 4.949C48.448 15.327 41 27.29 41 41c0 13.71 7.448 25.657 18.514 32.063z"
          fill="#E5FFDD"
        />
        <path
          d="M32.13 2.518l-.003.001C21.85 4.88 13.105 11.11 7.52 20.061l-.001.002c-5.602 8.95-7.376 19.53-5 29.824L32.13 2.518zm0 0C42.406.142 53.001 1.915 61.953 7.502a39.385 39.385 0 0114.766 16.6M32.13 2.519l45.946 20.945M41.388 75.524h0c-6.602.084-13.049-1.743-18.67-5.239l-.002-.002C14.9 65.396 9.455 57.757 7.396 48.784c-2.076-8.973-.518-18.221 4.37-26.036C21.845 6.622 43.174 1.7 59.3 11.78a34.338 34.338 0 0112.894 14.485 34.495 34.495 0 013.097 18.615M41.388 75.524L73.8 44.716M41.388 75.524a34.595 34.595 0 0018.095-5.373 2.495 2.495 0 013.456.767c.746 1.18.4 2.718-.767 3.456a39.706 39.706 0 01-20.717 6.142h-.487c-7.388 0-14.608-2.082-20.906-6.018m21.326 1.026l-21.326-1.026m55.23-29.617l-.001.003-1.49-.168m1.49.165v-.002l-1.49-.163m1.49.165a2.498 2.498 0 002.21 2.764h.002a2.498 2.498 0 002.766-2.21v-.006a39.671 39.671 0 00-3.55-21.326M73.8 44.716a3.998 3.998 0 003.54 4.42 3.998 3.998 0 004.42-3.54 41.171 41.171 0 00-3.684-22.133m-1.356.64l1.356-.64m-1.356.64v-.001l1.356-.64M20.062 74.499c-8.935-5.586-15.166-14.33-17.543-24.61l17.543 24.61z"
          fill="#EEE"
          stroke="#fff"
          strokeWidth={3}
        />
        <path
          d="M54.23 27.098l-19.3 19.315-7.159-7.159a4.002 4.002 0 00-5.669 0 4.002 4.002 0 000 5.67l9.994 9.994a3.988 3.988 0 002.835 1.169c1.025 0 2.05-.385 2.834-1.17L59.9 32.769a4.002 4.002 0 000-5.67 4.002 4.002 0 00-5.67 0z"
          fill="#24B300"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_7739_65911">
          <path fill="#fff" d="M0 0h82v82H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SuccessIcon;
