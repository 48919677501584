import Overview1 from "./../assets/images/Overview1.png";
import Overview2 from "./../assets/images/Overview2.png";
import Overview3 from "./../assets/images/Overview3.png";
import EmailAE21 from "./../assets/images/EmailAE21.png";
import NextAE22 from "./../assets/images/NextAE22.png";
import NudgeAE23 from "./../assets/images/NudgeAE23.png";


export const AEOverviewContent1: any = () => {
    return {
        description: "overview.description",
        cards: [
            {
                title: "ae.overview.title1",
                label: "ae.overview.label1",
                image: Overview1
            },
            {
                title: "ae.overview.title2",
                label: "ae.overview.label2",
                image: Overview2
            },
            {
                title: "ae.overview.title3",
                label: "ae.overview.label3",
                image: Overview3
            }
        ]
    }
}

export const AEOverviewContent2: any = () => {
    return {
        description: "overview2.description",
        cards: [
            {
                title: "ae2.overview.title1",
                label: "ae2.overview.label1",
                image: EmailAE21
            },
            {
                title: "ae2.overview.title2",
                label: "ae2.overview.label2",
                image: NextAE22
            },
            {
                title: "ae2.overview.title3",
                label: "ae2.overview.label3",
                image: NudgeAE23
            }
        ]
    }
}