import { FC, useCallback } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Info } from "@evabot/eva-react-core-library/dist/molecules/Info/Info";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Dropdown } from "@evabot/eva-react-core-library/dist/atoms/Dropdown/Dropdown";
import { Formik, Form } from "formik";
import { ToolTip } from "@evabot/eva-react-core-library/dist/molecules/ToolTip/ToolTip";
import { AccordionCard } from "../../components/Accordion/AccordionCard";
import { StakeHolderAccordionHeader } from "./StakeHolderAccordionHeader";
import { StakeHolderAccordionSubSection } from "./StakeHolderAccordionSubSection";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { useNavigate } from "react-router";

export interface StakeHolderLayoutProps {}

export const StakeHolderLayout: FC<StakeHolderLayoutProps> = () => {
  const navigate = useNavigate();
  const onSubmitHandler = useCallback(async (values: any) => {
    console.log(values, "values");
  }, []);
  return (
    <>
      <Box
        background={"#F7F7F7"}
        padding={"32px 42px"}
        overflow="auto"
        height={"88vh"}
      >
        <Grid gridRowGap={"10px"} marginBottom={"20px"}>
          <Flex justifyContent={"flex-end"}>
            <Text variant="inputHint" color="textColor.secondary">
              Having troubles?
            </Text>
            <Text variant="inputHint" color="basicColors.basicBlue" ml="4px">
              Get help
            </Text>
          </Flex>
          <Text color="textColor.primary" variant="pageHeadingRegular">
            Send first email to the key stakeholders
          </Text>

          <Box>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Info
                text="Based on the opportunities selected in the previous step, the first emails will be sent to the stakeholders shown below"
                color="#1FA1FF"
              />
              <Text
                variant="bodyRegular"
                fontWeight={"bold"}
                color="textColor.primary"
              >
                Total #opportunities selected - 3
              </Text>
            </Flex>
          </Box>
          <ToolTip
            bgColor="#0094E3"
            color="white"
            direction="right"
            title={
              <Text variant="bodyRegular" m="10px 3px">
                This is the list of org wide email.
              </Text>
            }
          >
            <Flex style={{ width: "max-content" }} alignItems={"center"}>
              <Text color="labelColor" variant="bodyRegular" mr="5px">
                Select sender email
              </Text>
              <Icon name="info" fill="#1FA1FF" />
            </Flex>
          </ToolTip>
          <Formik
            initialValues={{
              searchEmail: "",
            }}
            onSubmit={onSubmitHandler}
            enableReinitialize
          >
            {({ values, errors, initialValues }) => {
              console.log(errors, "error", values, initialValues);
              return (
                <Form>
                  <Box width="360px">
                    <Dropdown
                      // disabled
                      options={["Alabama", "Alaska", "Arizona"]}
                      placeholder="freelancer"
                      name="searchEmail"
                      variantType="primary"
                    />
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Grid>
        <Grid gridGap="16px">
          <AccordionCard
            headerSection={StakeHolderAccordionHeader}
            subSection={StakeHolderAccordionSubSection}
          />
          <AccordionCard
            headerSection={StakeHolderAccordionHeader}
            subSection={StakeHolderAccordionSubSection}
          />
        </Grid>

        <Flex
          flexDirection={"row"}
          justifyContent={"flex-end"}
          gridColumnGap={"16px"}
          mt={"54px"}
        >
          <Button
            iconAlign="left"
            iconName="arrowLeft"
            variant="secondary"
            onClick={() => {
              navigate("/budgetSetupLayout");
            }}
          >
            Previous step
          </Button>
          <Button
            iconAlign="right"
            iconName="arrowRight"
            onClick={() => navigate("/emailTemplateSetup")}
          >
            Review email templates
          </Button>
        </Flex>
      </Box>
    </>
  );
};
