import { FC, ReactElement , useEffect , useState } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { BoxProps } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import AeBackLogo from "./AeBackLogo.png";
import Secured from "./secured.png";
import LockImage from "./LockImage.png";
import AeForgotMobile from "./AeForgotMobile";
export interface AeFormTemplateProps extends BoxProps {
  leftChild: ReactElement;
  rightChild: ReactElement;
  formType: "forgot" | "change";
}

const AeFormTemplate: FC<AeFormTemplateProps> = ({
  leftChild,
  rightChild,
  formType,
}) => {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  return screenSize.dynamicWidth > 576 ? (
    <Flex width={"100%"}>
      <Grid
        width={"38%"}
        background={"linear-gradient(159deg, #16124C 7.63%, #854ECD 114.43%)"}
        height="100vh"
        position="relative"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <img
          src={AeBackLogo}
          alt=""
          style={{ position: "absolute", bottom: "0", width: "100%" }}
        />
        <Grid position={"relative"}>
          {leftChild}
          <img
            src={formType === "forgot" ? LockImage : Secured}
            alt=""
            style={{ position: "absolute", bottom: "-40px", right: "-50px" }}
          />
        </Grid>
      </Grid>
      <Grid justifyContent={"center"} alignItems={"center"} width={"62%"}>
        {rightChild}
      </Grid>
    </Flex>
  ) : (
    <Flex justifyContent={"flex-start"} width={"100%"} height={"100%"} >
     <AeForgotMobile />
    </Flex>
  );
};

export default AeFormTemplate;
