import React from 'react';

interface DateWiseCount {
  date: string;
  count: number;
}

interface AccountData {
  totalAccount2?: { dateWiseCount: DateWiseCount[] };
  totalProspect2?: { dateWiseCount: DateWiseCount[] };
  totalEmail2?: { dateWiseCount: DateWiseCount[] };
  totalCallPrep2?: { dateWiseCount: DateWiseCount[] };
}

interface DateRangeItem {
  dateString: string;
  dateStringUS: string;
  date: string;
}

interface Props {
  memberData?: any;
  dateRange?: DateRangeItem[];
  accountData: AccountData;
}

const getCounts = (dateWiseCount?: DateWiseCount[]) =>
  dateWiseCount?.reduce((acc, curr) => {
    acc[curr.date] = curr.count;
    return acc;
  }, {} as Record<string, number>) || {};

const AdminReportTableBody: React.FC<Props> = ({ memberData, dateRange, accountData }) => {
  const accounts = getCounts(accountData.totalAccount2?.dateWiseCount);
  const prospects = getCounts(accountData.totalProspect2?.dateWiseCount);
  const emails = getCounts(accountData.totalEmail2?.dateWiseCount);
  const callpreps = getCounts(accountData.totalCallPrep2?.dateWiseCount);

  if (!accounts || !prospects || !emails || !callpreps) return null;

  const renderRow = (m: DateRangeItem, index: number) => {
    const backgroundColor = (index + 1) % 2 === 0 ? "rgb(248, 248, 248)" : "#fff";
    return (
      <tr key={m.date}>
        <td style={{ backgroundColor }}><div>{m.dateStringUS}</div></td>
        <td style={{ backgroundColor, textAlign:"center" }}><div>{prospects[m.date]}</div></td>
        <td style={{ backgroundColor, textAlign:"center" }}><div>{accounts[m.date]}</div></td>
        <td style={{ backgroundColor, textAlign:"center" }}><div>{emails[m.date]}</div></td>
        <td style={{ backgroundColor, textAlign:"center" }}><div>{callpreps[m.date]}</div></td>
        <td style={{ backgroundColor: "lightgrey", textAlign:"center" }}><div>{(prospects[m.date] || 0) + (accounts[m.date] || 0) + (callpreps[m.date] || 0) + (emails[m.date] || 0)}</div></td>
      </tr>
    );
  };

  const totalProspects = accountData.totalProspect2?.dateWiseCount.reduce((accumulator, current) => { return accumulator + current.count }, 0);
  const totalAccounts = accountData.totalAccount2?.dateWiseCount.reduce((accumulator, current) => { return accumulator + current.count }, 0);
  const totalEmails = accountData.totalEmail2?.dateWiseCount.reduce((accumulator, current) => { return accumulator + current.count }, 0);
  const totalCallPreps = accountData.totalCallPrep2?.dateWiseCount.reduce((accumulator, current) => { return accumulator + current.count }, 0);
  const totalActivities = totalProspects + totalAccounts + totalEmails + totalCallPreps;

  return (
    <tbody>
      {dateRange?.map(renderRow)}
      <tr>
        <td style={{ backgroundColor: "lightgrey" }}><div>Total</div></td>
        <td style={{ backgroundColor: "lightgrey", textAlign:"center" }}><div>{totalProspects}</div></td>
        <td style={{ backgroundColor: "lightgrey", textAlign:"center" }}><div>{totalAccounts}</div></td>
        <td style={{ backgroundColor: "lightgrey", textAlign:"center" }}><div>{totalEmails}</div></td>
        <td style={{ backgroundColor: "lightgrey", textAlign:"center" }}><div>{totalCallPreps}</div></td>
        <td style={{ backgroundColor: "lightgrey", textAlign:"center" }}><div>{totalActivities}</div></td>
      </tr>
    </tbody>
  );
};

export default AdminReportTableBody;
