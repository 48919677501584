import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

const initialState = {
  analyticsData: [],
  loading: false,
  analyticsPerPage: 10,
  analyticsPageNo: 0,
  analyticsSnackbar: {
    show: false,
    message: "",
  },
  dateRange: [],
  selectedRepNames: [],
};

export interface aeReturnAnalyticsReducerType {}

const aeReturnAnalyticsReducerSlice = createSlice({
  name: "analyticsState",
  initialState: initialState,
  reducers: {
    setAeReturnAnalyticsData: (
      state,
      action: PayloadAction<aeReturnAnalyticsReducerType>
    ) => {
      //@ts-ignore
      state.analyticsData = action.payload;
    },
    setAeReturnAnalyticsDataLoading: (
      state,
      action: PayloadAction<aeReturnAnalyticsReducerType>
    ) => {
      //@ts-ignore
      state.loading = action.payload;
    },
    setAnalyticsPerPage: (state, action: PayloadAction<[]>) => {
      //@ts-ignore
      state.analyticsPerPage = action.payload;
    },
    setAnalyticsPageNo: (state, action: PayloadAction<[]>) => {
      //@ts-ignore
      state.analyticsPageNo = action.payload;
    },
    setAnalyticsSnackbar: (state, action: PayloadAction<[]>) => {
      //@ts-ignore
      state.analyticsSnackbar = action.payload;
    },
    setAnalyticsDateRange: (state, action: PayloadAction<[Date, Date]>) => {
      //@ts-ignore
      state.dateRange = action.payload;
    },
    setSelectedRepNames: (state, action: PayloadAction<any[]>) => {
      //@ts-ignore
      state.selectedRepNames = action.payload;
    },
  },
});

export const {
  setAeReturnAnalyticsData,
  setAeReturnAnalyticsDataLoading,
  setAnalyticsPerPage,
  setAnalyticsPageNo,
  setAnalyticsSnackbar,
  setAnalyticsDateRange,
  setSelectedRepNames,
} = aeReturnAnalyticsReducerSlice.actions;

export const aeReturnAnalyticsState = (state: RootState) =>
  state.analyticsState;

export default aeReturnAnalyticsReducerSlice.reducer;
