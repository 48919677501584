import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";

function StakeholderTagTableHeader() {
  return (
    <Flex
      padding={"10px 15px"}
      fontSize="12px"
      fontWeight={500}
      position="sticky"
      backgroundColor={"#F8F8F8"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Flex width="5%" justifyContent={"center"} alignItems={"center"}>
        #
      </Flex>
      <Flex width="10%" justifyContent={"center"} alignItems={"center"}>
        Tag Name
      </Flex>
      <Flex width="20%" justifyContent={"center"} alignItems={"center"}>
        Owner Name
      </Flex>
      <Flex width="10%" justifyContent={"center"} alignItems={"center"}>
        Status
      </Flex>
     
      <Flex width="15%" justifyContent={"center"} alignItems={"center"}>
        Total Processed
      </Flex>
      <Flex width="15%" justifyContent={"center"} alignItems={"center"}>
        Total Errors
      </Flex>
      <Flex width="15%" justifyContent={"center"} alignItems={"center"}>
        Processed On
      </Flex>
      <Flex width="15%" justifyContent={"center"} alignItems={"center"}>
        Requested On
      </Flex>
      <Flex width="15%" justifyContent={"center"} alignItems={"center"}>
        Actions
      </Flex>
    </Flex>
  );
}

export default StakeholderTagTableHeader;
