import { FC, useState } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Tag } from "@evabot/eva-react-core-library/dist/molecules/Tag/Tag";
import { ToolTip } from "@evabot/eva-react-core-library/dist/molecules/ToolTip/ToolTip";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import parse from "html-react-parser";
import {
  getCoffeeString,
  getAlcoholString,
  getOtherC1String,
  getCuisineString,
  capitalizeString,
  getC4MappedString,
} from "utils/preferenceMapper";
import { useNavigate } from "react-router-dom";
import TimeOutline from "./TimeOutline";
import { getDateString } from "utils/constants";
import SearchViewNudgeCard from "./SearchViewNudgeCard";
interface SearchViewProps {
  searchNudges: any;
  searchQuery: any;
  recommendedNudges: any;
  nudgeCategoryMap: any;
  setSearchType: any;
  setSearchTerm: any;
  setSelectedNudge: any;
  getNudgeBtn: any;
  setFilteredNudges: any;
  setSearch: any;
  setFilter: any;
  thumsUpIds: any;
  updateNudgeFeedback: any;
  thumsDownIds: any;
  setHide: any;
  hide: any;
  getNudgeIcon: any;
  dispatch: any;
  undoNudgeAction: any;
  setSelectedNudgeForUnlike: any;
  getDomainName: any;
}

const SearchView: FC<SearchViewProps> = ({
  searchNudges,
  searchQuery,
  recommendedNudges,
  nudgeCategoryMap,
  setSearchType,
  setSearchTerm,
  setSelectedNudge,
  getNudgeBtn,
  setFilteredNudges,
  setSearch,
  setFilter,
  thumsUpIds,
  updateNudgeFeedback,
  thumsDownIds,
  setHide,
  hide,
  getNudgeIcon,
  dispatch,
  undoNudgeAction,
  setSelectedNudgeForUnlike,
  getDomainName
}) => {
  const [copiedIds, setCopiedIds] = useState([]);
  const navigate = useNavigate();
  const getHeadlineStr = (nudge) => {
    let text = nudge?.nudgeHeadline;

    let substrings = nudge?.recHighlight;
    substrings = substrings.filter((sub) => sub.trim() != "");
    substrings.forEach((substr, i) => {
      console.log("substring",substr);
      substr = substr.replace(/[^\w\s]/gi, ''); 
      let pattern = new RegExp(substr, "gi");

      text = text.replace(
        pattern,
        `<span style="font-family:'Inter-Bold'" > ${capitalizeString(
          substr
        )} </span>`
      );
    });

    return (
      <Text
        color={"#050505"}
        fontWeight={"300"}
        fontSize={"20px"}
        lineHeight={"140%"}
      >
        {parse(text)}
      </Text>
    );
  };
  return (
    <>
      <Text color={"#050505"} fontWeight={"400"} fontSize={"20px"} mt={"32px"}>
        {" "}
        Showing {searchNudges.length} results for{" "}
        <Text
          color={"#0094E3"}
          display={"inline-block"}
          fontWeight={"400"}
          fontSize={"20px"}
        >
          {`"${searchQuery}"`}
          {/* "{searchQuery.stakeholderName != "" ? searchQuery.stakeholderName : ( searchQuery.opportunityName != "" ? searchQuery.opportunityName : searchQuery.email   ) }” */}
        </Text>{" "}
      </Text>
      {searchNudges.map((nudge) => {
        return !thumsDownIds.includes(nudge?.nudgeId) ? (
          <SearchViewNudgeCard
            getDomainName={getDomainName}
            nudge={nudge}
            getNudgeIcon={getNudgeIcon}
            thumsUpIds={thumsUpIds}
            undoNudgeAction={undoNudgeAction}
            updateNudgeFeedback={updateNudgeFeedback}
            hide={hide}
            setHide={setHide}
          />
        ) : (
          <Box width={"100%"}>
            <Box
              style={{ boxSizing: "border-box" }}
              background="#FFFFFF"
              borderTopLeftRadius="12px"
              borderTopRightRadius="12px"
              borderBottom="1px solid rgba(0, 0, 0, 0.1)"
              padding="40px"
              width="100%"
            >
              <Flex
                flexDirection={"row"}
                gridColumnGap={"52px"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Text
                  color={"#9F9F9F"}
                  lineHeight={"140%"}
                  fontWeight={"500"}
                  fontSize={"16px"}
                >
                  {" "}
                  We won't recommend this nudge to you again{" "}
                </Text>
                <Button
                  variant="undo"
                  onClick={() => {
                    undoNudgeAction(nudge?.nudgeId, "down");
                  }}
                >
                  {" "}
                  Undo{" "}
                </Button>
              </Flex>
            </Box>
          </Box>
        );
      })}
    </>
  );
};
export default SearchView;
