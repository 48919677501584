import { FC } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Card } from "@evabot/eva-react-core-library/dist/atoms/Card/Card";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { useNavigate, useParams } from "react-router-dom";
import SmartText from "components/SmartText/SmartText";
import SuccessTag from "components/SuccessTag/SuccessTag";

export interface ReminderTemplateCardProps {
  badgeContent: string;
  mailHeader: string;
  textContent: string;
  textLength?: number;
  isBadge?: boolean;
  id: number;
  onClick:any;
}
export const ReminderTemplateCard: FC<ReminderTemplateCardProps> = ({
  badgeContent,
  mailHeader,
  textContent,
  textLength,
  isBadge,
  id,
  onClick,
}) => {
  const navigate = useNavigate();
  const { email } = useParams();
  console.log(email, "nav remind");

  return (
    <Card>
      <Flex flexDirection={"column"} gridRowGap={"26px"}>
        <Flex
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"baseline"}
        >
          <Flex alignItems={"center"}>
            <Flex
              borderRadius={"6px"}
              padding={"8px 12px"}
              background={"rgba(226, 243, 255, 0.7)"}
              border={"1px solid rgba(53, 104, 233, 0.5)"}
            >
              <Flex
                flexDirection={"row"}
                gridColumnGap={"4px"}
                alignItems={"center"}
              >
                <Icon name={"calender"} fill="#3568E9" />
                <Text color={"#3568E9"} fontSize={"14px"} fontWeight={"400"}>
                  {badgeContent}
                </Text>
              </Flex>
            </Flex>
            {+id === +email && <SuccessTag text={"Email copy updated"} />}
          </Flex>

          <Flex
            alignItems={"center"}
            cursor="pointer"
            onClick={() => navigate(`/slateEditor/${id}`)}
            variant="bodyRegular"
            fontWeight={"bold"}
            mr="30px"
          >
            <Text color="eva.evaRed" mr="10px" onClick={onClick}>
              Review & confirm
            </Text>
            <Icon name={"arrowRight"} fill={"#DD015B"} />
          </Flex>
        </Flex>
        <Flex
          flexDirection={"column"}
          gridRowGap={"10px"}
          justifyContent={"flex-start"}
        >
          <Flex
            flexDirection={"row"}
            gridColumnGap={"10px"}
            alignItems={"center"}
          >
            <Icon name={"mail"} stroke="#050505" />
            <Text fontSize={"14px"} fontWeight={"400"}>
              {mailHeader}
            </Text>
          </Flex>
          <SmartText text={textContent} length={textLength || 350} />
        </Flex>
      </Flex>
    </Card>
  );
};
