import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import {
  Icon,
  icons,
} from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";

export interface SuccessModalTagProps {
  iconName: keyof typeof icons;
  tagValue: string;
}

export const SuccessModalTag: React.FC<SuccessModalTagProps> = ({
  iconName,
  tagValue,
}) => {
  return (
    <Flex
      flexDirection={"row"}
      gridColumnGap={"10px"}
      alignItems={"flex-start"}
    >
      <Icon name={iconName} />
      <Flex flexDirection={"column"} gridRowGap={"10px"}>
        <Text color={"#000000"} fontSize={"14px"} fontWeight={"400"}>
          {tagValue}
        </Text>
      </Flex>
    </Flex>
  );
};
