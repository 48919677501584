import * as React from "react";

function AeSideEmailIcon(props) {
  return (
    <svg
      width={props?.size || "18px"}
      height={props?.size || "18px"}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M.625 12.5A1.877 1.877 0 002.5 14.375h5a.625.625 0 100-1.25h-5a.625.625 0 01-.625-.625V4.838l5.928 3.46a1.922 1.922 0 001.89 0l5.932-3.463V6.25a.625.625 0 101.25 0v-2.5A1.877 1.877 0 0015 1.875H2.5A1.919 1.919 0 00.625 3.75v8.75zM2.5 3.125H15a.617.617 0 01.533.318l-6.47 3.775a.628.628 0 01-.63 0L1.967 3.445a.617.617 0 01.534-.32z"
        fill="#fff"
      />
      <path
        d="M14.375 8.125a5 5 0 100 10 5 5 0 000-10zm0 8.75a3.75 3.75 0 110-7.5 3.75 3.75 0 010 7.5z"
        fill="#fff"
      />
      <path
        d="M15.762 11.485l-2.064 2.58-.756-.757a.625.625 0 00-.884.884l1.25 1.25a.631.631 0 00.93-.051l2.5-3.125a.625.625 0 10-.976-.781z"
        fill="#fff"
      />
    </svg>
  );
}

export default AeSideEmailIcon;
