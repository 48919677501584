import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import SuccessIcon from "../BirthdayNudge/SuccessIcon";
import { SuccessModalTag } from "./SuccessModalTag";
import TimerIcon from "../BirthdayNudge/TimerIcon";
import { Timer } from "components/Timer/Timer";

export interface SuccessModalProps {
  heading: string;
  subText1: string;
  subText2: string;
  subText3: string;
  setShowBirthdaySuccessModal: (e: any) => void;
  setShowBirthdayCancelText: (e: any) => void;
}

export const SuccessModal: React.FC<SuccessModalProps> = ({
  heading,
  subText1,
  subText2,
  subText3,
  setShowBirthdaySuccessModal,
  setShowBirthdayCancelText,
}) => {
  return (
    <Grid alignItems={"center"} justifyContent="center" height="100%">
      <Grid maxWidth={"400px"}>
        <SuccessIcon />
        <Text color={"#24B300"} fontSize={"24px"} fontWeight={"400"} mt="10px">
          Great!
        </Text>
        <Text
          color={"#050505"}
          fontSize={"14px"}
          fontWeight={"400"}
          lineHeight={"140%"}
          mt="10px"
          mb="52px"
        >
          {heading}
        </Text>

        <Text color={"#000000"} fontWeight={"500"} fontSize={"16px"} mb="20px">
          Next steps:
        </Text>
        <Grid gridGap={"24px"}>
          <SuccessModalTag iconName="stepOne" tagValue={subText1} />
          <SuccessModalTag iconName="stepTwo" tagValue={subText2} />
          <SuccessModalTag iconName="stepThree" tagValue={subText3} />
        </Grid>

        <Flex alignItems={"center"} mt="24px">
          <Button
            variant="primary"
            onClick={() => {
              setShowBirthdaySuccessModal(false);
              setShowBirthdayCancelText(true);
            }}
          >
            Okay, got it
          </Button>
          <Text
            color={"#DD015B"}
            cursor="pointer"
            ml="24px"
            mr="10px"
            onClick={() => setShowBirthdaySuccessModal(false)}
          >
            Cancel request
          </Text>
          <Flex gridGap={"4px"}>
            <TimerIcon />
            <Timer />
          </Flex>
        </Flex>
      </Grid>
    </Grid>
  );
};
