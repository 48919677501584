import { useState, useEffect, useCallback } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import AeReturnStakeTable from "./AeReturnStakeTable/AeReturnStakeTable";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { axiosHelper } from "utils";
import SortByArrow from "./AeReturnStakeTable/SortByArrow";
import SortByDropArrow from "./AeReturnStakeTable/SortByDropArrow";
import {
  setStakeholdersData,
  setStakeholdersLoadingData,
  setStakeholderDataChange,
  setstakeholderSnackbar,
  setstakeholderpageNo,
} from "store/globalStateReducer";
import { useDispatch } from "react-redux";
import { useAppSelector } from "hooks";
import {
  checkUnauthorized,
  compare,
  getDateString,
  recencySort,
  reverseCompare,
} from "utils/constants";
import { useNavigate } from "react-router";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import NoStakeholder from "./NoStakeholder.png";

interface AeReturnStakeRightSectionProps {
  setOpenSearchModal: (e: any) => void;
  setSearchQuery: (e: any) => void;
  searchValues?: any;
}

const AeReturnStakeRightSection: React.FC<AeReturnStakeRightSectionProps> = ({
  searchValues,
  setOpenSearchModal,
  setSearchQuery,
}) => {
  const stakeholders = useAppSelector(
    (state) => state.globalState.stakeholders
  );
  const stakeholderLoading = useAppSelector(
    (state) => state.globalState.stakeholderLoading
  );
  const stakeholderDataChange = useAppSelector(
    (state) => state.globalState.stakeholderDataChange
  );
  const stakeholderSnackbar = useAppSelector(
    (state) => state.globalState.stakeholderSnackbar
  );
  const [openSortModal, setOpenSortModal] = useState(false);
  const [openStakeFilterModal, setOpenStakeFilterModal] = useState(false);
  const [sortValue, setSortValue] = useState("recency");
  const [stakeFilterValue, setStakeFilterValue] = useState("All");
  const [allStakeholders, setAllStakeholders] = useState(stakeholders);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const journeyData = useAppSelector((state) => state.globalState.journey);
  const profileData = useAppSelector((state) => state.profileState.profileData);
  const profileName = `${profileData.firstName} ${profileData.lastName}`;
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const fetchDataStakeholders = async () => {
    try {
      const result = await axiosHelper({
        url: `salesforce/getAllStakeholdersForAE`,
      });
      setAllStakeholders(
        [...result.data?.allData].sort(
          sortValue === "a-z"
            ? compare
            : sortValue === "z-a"
            ? reverseCompare
            : recencySort
        )
      );
      setIsAdmin(result?.data?.isAdmin);
      //@ts-ignore
      dispatch(setStakeholdersData([...result.data?.allData]));
      //@ts-ignore
      dispatch(setStakeholdersLoadingData(false));
    } catch (err) {
      console.log(err);
      checkUnauthorized(err, journeyData, navigate);
    }
  };

  useEffect(() => {
    if (
      [...new Set(stakeholders?.map((stake) => stake?.memberName))]?.length > 1
    ) {
      setStakeFilterValue("All");
    } else {
      setStakeFilterValue("Yourself");
    }
  }, [stakeholders]);

  useEffect(() => {
    fetchDataStakeholders();
  }, []);

  const calcStakeData = useCallback(
    (sortValue) => {
      //@ts-ignore
      // dispatch(setStakeholdersLoadingData(true));
      setLoading(true);
      if (sortValue === "z-a") {
        allStakeholders.sort(reverseCompare);
      } else if (sortValue === "a-z") {
        allStakeholders.sort(compare);
      } else {
        allStakeholders?.sort(recencySort);
      }

      return setTimeout(() => {
        setLoading(false);
      }, 1000);
    },
    [allStakeholders]
  );

  const filterData = useCallback(
    (filterValue) => {
      setLoading(true);
      if (filterValue === "All") {
        setAllStakeholders(
          [...stakeholders].sort(
            sortValue === "a-z"
              ? compare
              : sortValue === "z-a"
              ? reverseCompare
              : recencySort
          )
        );
        setSearchQuery(null);
        //@ts-ignore
        dispatch(setstakeholderpageNo(0));
      } else {
        if (searchValues && searchValues.length > 0) {
          setAllStakeholders(
            [
              ...stakeholders
                ?.filter((stake) => {
                  return (
                    `${stake?.identity?.firstname} ${stake?.identity?.lastname}` ===
                      searchValues || stake?.identity?.email === searchValues
                  );
                })
                .filter((stake) => stake.memberName === filterValue),
            ].sort(
              sortValue === "a-z"
                ? compare
                : sortValue === "z-a"
                ? reverseCompare
                : recencySort
            )
          );
        } else {
          setAllStakeholders(
            [
              ...stakeholders.filter(
                (stake) => stake.memberName === filterValue
              ),
            ].sort(
              sortValue === "a-z"
                ? compare
                : sortValue === "z-a"
                ? reverseCompare
                : recencySort
            )
          );
          //@ts-ignore
          dispatch(setstakeholderpageNo(0));
        }
      }
      return setTimeout(() => {
        setLoading(false);
      }, 1000);
    },
    [allStakeholders, searchValues, stakeholders, sortValue]
  );

  useEffect(() => {
    if (searchValues && searchValues.length > 0) {
      //@ts-ignore
      dispatch(setStakeholdersLoadingData(true));
      setAllStakeholders(
        [
          ...stakeholders?.filter((stake) => {
            return (
              `${stake?.identity?.firstname} ${stake?.identity?.lastname}` ===
                searchValues || stake?.identity?.email === searchValues
            );
          }),
        ].sort(
          sortValue === "a-z"
            ? compare
            : sortValue === "z-a"
            ? reverseCompare
            : recencySort
        )
      );
      setStakeFilterValue("All");
      setSortValue(sortValue);
      //@ts-ignore
      dispatch(setstakeholderpageNo(0));
      setTimeout(() => {
        //@ts-ignore
        dispatch(setStakeholdersLoadingData(false));
      }, 1000);
    } else {
      setAllStakeholders(
        [...stakeholders].sort(
          sortValue === "a-z"
            ? compare
            : sortValue === "z-a"
            ? reverseCompare
            : recencySort
        )
      );
      setStakeFilterValue("All");
      setSortValue(sortValue);
    }
  }, [searchValues]);

  useEffect(() => {
    if (stakeholderDataChange) {
      setAllStakeholders(
        [...stakeholders].sort(
          sortValue === "a-z"
            ? compare
            : sortValue === "z-a"
            ? reverseCompare
            : recencySort
        )
      );
      setStakeFilterValue(stakeFilterValue);
      setSortValue(sortValue);
      //@ts-ignore
      dispatch(setStakeholderDataChange(false));
    }
  }, [dispatch, sortValue, stakeholderDataChange]);

  console.log(
    allStakeholders?.map(
      (m) => `${m.identity.firstname} ${getDateString(m.createdAt)}`
    ),
    sortValue,
    "checkkk"
  );
  return (
    <>
      {stakeholderLoading || loading ? (
        <Icon name="loadingIcon" />
      ) : (
        <>
          {stakeholders && stakeholders?.length > 0 ? (
            <Flex
              flexDirection={"column"}
              padding="25px 32px"
              overflowY={"scroll"}
            >
              <SnackBar
                iconName={"paperPlane"}
                setShowSnackBar={() => {
                  dispatch(
                    setstakeholderSnackbar({
                      //@ts-ignore
                      show: false,
                      message: "",
                    })
                  );
                }}
                show={stakeholderSnackbar.show}
                message={stakeholderSnackbar.message}
                timeout={2000}
                anchor={"top-right"}
                type={"success"}
                bgColorr="#24B300"
              />
              {stakeholderLoading || loading ? (
                <Icon name="loadingIcon" />
              ) : (
                <>
                  <Flex
                    justifyContent={"space-between"}
                    // marginTop={openSortModal && "5px"}
                  >
                    <Flex
                      alignItems={"center"}
                      fontSize={"24px"}
                      fontWeight={400}
                      color="#050505"
                    >
                      Stakeholders{" "}
                      <Flex
                        justifyContent={"center"}
                        alignItems={"center"}
                        ml="16px"
                        as="span"
                        color="#050505"
                        fontSize={"12px"}
                        fontWeight={400}
                        backgroundColor={"#fff"}
                        borderRadius={"8px"}
                        // p="4px 8px"
                        width={allStakeholders?.length > 99 ? "32px" : "24px"}
                        height={allStakeholders?.length > 99 ? "32px" : "24px"}
                      >
                        {allStakeholders?.length}
                      </Flex>
                    </Flex>
                    {stakeholders?.length > 0 && (
                      <Flex alignItems={"center"}>
                        {isAdmin && (
                          <>
                            <Text
                              mr="8px"
                              fontWeight={400}
                              fontSize={"14px"}
                              color="#050505"
                            >
                              View stakeholder(s) for
                            </Text>
                            <Flex
                              mr="8px"
                              color="#050505"
                              fontWeight={500}
                              fontSize="14px"
                              alignItems={"center"}
                            >
                              <Grid
                                onMouseEnter={() =>
                                  setOpenStakeFilterModal(true)
                                }
                                onMouseLeave={() =>
                                  setOpenStakeFilterModal(false)
                                }
                              >
                                <Flex
                                  padding={"10px 18px"}
                                  borderRadius="8px"
                                  backgroundColor={"#fff"}
                                  alignItems="center"
                                  cursor="pointer"
                                  position={"relative"}
                                  minWidth={"200px"}
                                  justifyContent={"space-between"}
                                >
                                  <Box mr="8px">
                                    {stakeFilterValue?.toLowerCase() ===
                                    profileName.toLowerCase()
                                      ? "Yourself"
                                      : stakeFilterValue}
                                  </Box>
                                  <SortByArrow />
                                </Flex>
                                <Flex
                                  style={{
                                    display: openStakeFilterModal
                                      ? "flex"
                                      : "none",
                                  }}
                                  flexDirection={"column"}
                                  padding={"20px 24px"}
                                  borderRadius="8px"
                                  backgroundColor={"#fff"}
                                  cursor={"pointer"}
                                  color="#0C1116"
                                  fontSize={"14px"}
                                  fontWeight={500}
                                  gridGap={"24px"}
                                  boxShadow={"0px 4px 15px rgba(0, 0, 0, 0.07)"}
                                  position={"absolute"}
                                  zIndex={"1000"}
                                  width={"200px"}
                                  top="0px"
                                  right="1px"
                                >
                                  {/* <Flex justifyContent={"space-between"}>
                        <Text>Sort by</Text>{" "}
                        <Flex
                          onClick={() => {
                            setOpenSortModal(false);
                          }}
                        >
                          <SortByDropArrow />
                        </Flex>
                      </Flex> */}
                                  <Grid gridGap={"24px"} wordBreak="break-word">
                                    {([
                                      ...new Set(
                                        stakeholders?.map(
                                          (stake) => stake?.memberName
                                        )
                                      ),
                                    ]?.length > 1
                                      ? [
                                          "All",
                                          ...new Set(
                                            stakeholders?.map(
                                              (stake) => stake?.memberName
                                            )
                                          ),
                                        ]
                                      : [
                                          ...new Set(
                                            stakeholders?.map(
                                              (stake) => stake?.memberName
                                            )
                                          ),
                                        ]
                                    ).map((stake, index) => {
                                      return (
                                        <Flex
                                          onClick={() => {
                                            setStakeFilterValue(stake);
                                            setOpenStakeFilterModal(false);
                                            filterData(stake);
                                          }}
                                          justifyContent={"space-between"}
                                        >
                                          {" "}
                                          {stake?.toLowerCase() ===
                                          profileName.toLowerCase()
                                            ? "Yourself"
                                            : stake}
                                          {index === 0 && (
                                            <Flex
                                              onClick={() => {
                                                setOpenStakeFilterModal(false);
                                              }}
                                            >
                                              <SortByDropArrow />
                                            </Flex>
                                          )}
                                        </Flex>
                                      );
                                    })}
                                  </Grid>
                                </Flex>
                              </Grid>
                            </Flex>
                          </>
                        )}
                        <Flex
                          mr="30px"
                          color="#050505"
                          fontWeight={500}
                          fontSize="14px"
                          alignItems={"center"}
                        >
                          <Grid
                            onMouseEnter={() => setOpenSortModal(true)}
                            onMouseLeave={() => setOpenSortModal(false)}
                          >
                            <Flex
                              padding={"10px 18px"}
                              borderRadius="8px"
                              backgroundColor={"#fff"}
                              alignItems="center"
                              cursor="pointer"
                              position={"relative"}
                            >
                              <Box mr="8px">
                                Sort By:{" "}
                                {sortValue === "a-z"
                                  ? "A-Z"
                                  : sortValue === "z-a"
                                  ? "Z-A"
                                  : "Recency"}
                              </Box>

                              <SortByArrow />
                            </Flex>
                            <Flex
                              style={{
                                display: openSortModal ? "flex" : "none",
                              }}
                              flexDirection={"column"}
                              padding={"20px 24px"}
                              borderRadius="8px"
                              backgroundColor={"#fff"}
                              cursor={"pointer"}
                              color="#0C1116"
                              fontSize={"14px"}
                              fontWeight={500}
                              gridGap={"24px"}
                              boxShadow={"0px 4px 15px rgba(0, 0, 0, 0.07)"}
                              position={"absolute"}
                              zIndex={"1000"}
                              width={
                                sortValue === "recency" ? "132px" : "100px"
                              }
                              top="0px"
                              right="-7px"
                            >
                              <Flex justifyContent={"space-between"}>
                                <Text>Sort by</Text>{" "}
                                <Flex
                                  onClick={() => {
                                    setOpenSortModal(false);
                                  }}
                                >
                                  <SortByDropArrow />
                                </Flex>
                              </Flex>
                              <Flex
                                onClick={() => {
                                  setSortValue("recency");
                                  setOpenSortModal(false);
                                  calcStakeData("recency");
                                }}
                              >
                                Recency
                              </Flex>
                              <Flex
                                onClick={() => {
                                  setSortValue("a-z");
                                  setOpenSortModal(false);
                                  calcStakeData("a-z");
                                }}
                              >
                                A-Z
                              </Flex>
                              <Flex
                                onClick={() => {
                                  setSortValue("z-a");
                                  setOpenSortModal(false);
                                  calcStakeData("z-a");
                                }}
                              >
                                Z-A
                              </Flex>
                            </Flex>
                          </Grid>
                        </Flex>
                      </Flex>
                    )}
                  </Flex>

                  <>
                    {allStakeholders?.length > 0 ? (
                      <Grid
                      // my={openSortModal && "3px"}
                      >
                        <AeReturnStakeTable
                          setSearchQuery={setSearchQuery}
                          setOpenSearchModal={setOpenSearchModal}
                          allStakeholders={allStakeholders}
                        />
                      </Grid>
                    ) : (
                      <Grid my="20px">
                        <Text>
                          No Stakeholders are available for this search.
                        </Text>
                      </Grid>
                    )}
                  </>
                </>
              )}
            </Flex>
          ) : (
            <Grid justifyContent={"center"} alignItems={"center"}>
              <Flex
                flexDirection={"column"}
                alignItems={"center"}
                backgroundColor={"#FFFFFF"}
                width={"380px"}
                height={"270px"}
                justifyContent={"center"}
                borderRadius={"12px"}
                gap="24px"
              >
                <img src={NoStakeholder} alt="" />
                <Text
                  fontWeight={500}
                  fontSize={"20px"}
                  textAlign={"center"}
                  color="#050505"
                >
                  You have no stakeholders
                </Text>
              </Flex>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default AeReturnStakeRightSection;
