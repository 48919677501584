import React from 'react';

interface ButtonProps {
    text: string;
    variant?: "primary" | "secondary" | "salesforce";
    disabled?: boolean,
    size?: string,
    onClick?: () => void
}

const Button: React.FC<ButtonProps> = ({ text, variant, disabled, onClick }) => {

    let buttonClass = "font-bold py-2 px-4 rounded-[10px]";
    const disabledClass = "bg-[#EFEFEF] disabled:opacity-50 cursor-not-allowed text-[#8A8A8A] ";
    const bgColors = {
        salesforce: disabled ? disabledClass : "bg-[#0094E3] text-[white] border-[1px] border-color-[#0094E3]",
        primary: disabled ? disabledClass : "bg-blue-500 text-[white] border-[1px] border-color-[blue]",
        secondary: "bg-white-500 text-[#57585C] border-[1px] border-color-black"
    }

    if (variant) {
        buttonClass += ` ${bgColors[variant] || ''}`;
    }

    return (
        <button className={buttonClass} onClick={() => { !disabled && onClick() }}>{text}</button>
    );
};

export default Button;
