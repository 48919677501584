import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { AdminDashboardRightCard } from "./AdminDashboardRightCard";
import AdminDashWel from "./AdminDashWelcome.png";
import AdminDashLeaf from "./AdminDashLeaf.png";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";

export interface AdminDashboardRightSectionProps {}

export const AdminDashboardRightSection: React.FC<
  AdminDashboardRightSectionProps
> = () => {
  return (
    <Grid padding={"24px 40px"}>
      <Grid
        background={"#FFFDD9"}
        width="100%"
        borderRadius={"12px"}
        height="max-content"
      >
        <Flex pt="24px">
          <img src={AdminDashWel} alt="" />
          <Grid ml="50px" mr="90px" pb="24px">
            <Text mb="10px" variant="subTitle">
              Hi, Alena Workman!
            </Text>
            <Text variant="bodyRegular">
              Welcome to Eva. No more paltry win rates or stuck pipelines
              because someone missed a key relationship. We bring the all-in-one
              smart rapport building solution to everyone. EvaBot’s AI unlocks
              their mastery so that every rep can build the rapport that deepens
              relationships and drives results.
            </Text>
          </Grid>
        </Flex>
      </Grid>
      <Flex justifyContent="space-between" gridGap={"5px"}>
        <AdminDashboardRightCard
          cardValue={"20"}
          cardContent="#Opportunities selected"
          cardType="opportunity"
        />
        <AdminDashboardRightCard
          cardValue={"74"}
          cardContent="#Stakeholders engaged"
          cardType="stakeholder"
        />
        <AdminDashboardRightCard
          cardValue={"$30,000"}
          cardContent="Total budget allocated"
          cardType="budget"
        />
        <AdminDashboardRightCard
          cardValue={"$20,000"}
          cardContent="Credits Available"
          cardType="credit"
        />
      </Flex>
      <Flex
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems="center"
        background={"white"}
        borderRadius="12px"
        py="100px"
      >
        <img src={AdminDashLeaf} alt="" />
        <Text color={"#57585C"} variant="title" fontWeight={400} mt="20px">
          As soon as stakeholders engage you’ll be able
        </Text>
        <Text color={"#57585C"} variant="title" fontWeight={400}>
          to see engagement details here
        </Text>
        <Box mt="45px">
          <Button
            padding="13px 31px"
            iconName="arrowRight"
            iconAlign="right"
            // borderRadius={"118px"}
          >
            View and add opportunities
          </Button>
        </Box>
      </Flex>
    </Grid>
  );
};
