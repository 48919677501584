import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import userService from "services/userService";
import { useState } from "react";
import { ConfirmationDialog } from "./ConfirmationDialog";

function DeleteConfirmationModal({onClose, open, userId, onSuccess, checkParent}){
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [showSnackBar, setShowSnackBar] = useState(false);

    const handleDelete = (userId) => {
        try{
            const data = {userId : userId};
            userService.deleteUser(data)
                .then((response) => {
                    if (response?.success) {
                        setSnackbarMessage('User deleted successfully.');
                        setSnackbarType('success');
                        setShowSnackBar(true);
                        onSuccess();
                        
                    } else {
                        setSnackbarMessage('Error deleting user. Please try again.');
                        setSnackbarType('error');
                        setShowSnackBar(true);
                    }
            })
        }catch(error){
            console.error('Error deleting user:', error);
        }
        onClose();
    };

    return (
        <>
        <ConfirmationDialog
            width="max-content"
            height="max-content"
            hideModal={!open}
            setHideModal={onClose}
            bgcolor={"rgba(0, 0, 0, 0.8)"}
            padding="48px"
            left={checkParent === "InnerPopUp" ? "30vw" : "50vw"}
        >
            <Flex flexDirection="column" gap="20px">
                <Text fontSize="20px" fontWeight="500" textAlign={"center"}>Are you sure you want to remove this Member ?</Text>
                <Flex gap="20px" alignItems={"center"} justifyContent={"center"} marginTop={"20px"}>
                    <Button
                        title="confirm.yes"
                        variant="primary"
                        onClick={() => {
                            handleDelete(userId);
                        }}
                    />
                    <Button
                        title="confirm.no"
                        variant="secondary"
                        onClick={() => onClose()}
                    />
                </Flex>
            </Flex>
        </ConfirmationDialog>
    </>
    );
}

export default DeleteConfirmationModal;