import { FC, useState, useCallback, useEffect, useRef } from "react";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import ConfirmationModal from "@evabot/eva-react-core-library/dist/molecules/ConfirmationModal/ConfirmationModal";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { SelectComponent } from "@evabot/eva-react-core-library/dist/atoms/SelectComponent/SelectComponent";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { RightSideModal } from "@evabot/eva-react-core-library/dist/molecules/RightSideModal/RightSideModal";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Avatar } from "@evabot/eva-react-core-library/dist/atoms/Avatar/Avatar";
import { ToolTip } from "@evabot/eva-react-core-library/dist/molecules/ToolTip/ToolTip";
import { axiosHelper } from "../../utils";

import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { AeReturnUnlikeModal } from "./../../components/AeReturnUnlikeModal/AeReturnUnlikeModal";
import { setSelectedNudgeForUnlike } from "./store/aeDashboardReducer";
import {
  setLoggedInUserName,
  setRecommendedNudgesData,
} from "store/globalStateReducer";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";

import { Tag } from "@evabot/eva-react-core-library/dist/molecules/Tag/Tag";
import { BirthdayNudge } from "./BirthdayNudge/BirthdayNudge";
import { SuccessModal } from "./SuccessModal/SuccessModal";
import TimerIcon from "./BirthdayNudge/TimerIcon";
import { Timer } from "components/Timer/Timer";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import SuccessTickIcon from "./BirthdayNudge/SuccessTickIcon";
import { SportsNudge } from "./SportsNudge/SportsNudge";

import BirthdayCard from "./StakeholderProfileComps/BirthdayCard";
import CreateEmailModal from "./CreateEmailModal";
import OrderFoodModal from "./OrderFoodModal";
import RecommendNudgeView from "./RecommendNudgeView";
import RecommendMobileNudgeView from "./RecommendMobileNudgeView";
import OpportunityNameFilterView from "./OpportunityNameFilterView";
import SalesforceStageFilterView from "./SalesforceStageFilterView";

import AccountNameFilterView from "./AccountNameFilterView";
import NameFilterView from "./NameFilterView";
import SearchView from "./SearchView";
import SearchMobileView from "./SearchMobileNudgeView";
import SendTreatModal from "./SendTreatModal";
import { setSelectedNudge } from "store/globalStateReducer";
import { Backdrop } from "@mui/material";
import FilterDownIcon from "./FilterDownIcon";
import SortByArrow from "../AeReturnStakeholders/AeReturnStakeTable/SortByArrow";
import SortByDropArrow from "../AeReturnStakeholders/AeReturnStakeTable/SortByDropArrow";
import { checkUnauthorized } from "utils/constants";
interface NudgeFeedProps {
  searchQuery: any;
  searchh: string;
}

const nudgeCategoryMap = {
  birthday: "Birthday",
  news: "News",
  googlePlaces: "Google Places",
  music: "Music",
  orderFood: "Food",
  airbnbExperience: "AirBnb",
  sendGift: "Send Gift",
  concertTickets: "Concert tickets",
  sports: "Sports",
  weather: "Weather",
};

const foodTypesMap = {
  1: "meals",
  2: "coffee/tea",
  3: "alcohol",
  4: "snacks",
};

const CustomerValidationSchema = yup.object().shape({
  selectTime: yup.array().required("required.field").min(1, "required.field"),
  instruction: yup.string(),
  message: yup.string(),
});

const AddCustomerValidationSchema = yup.object().shape({
  selectedId: yup.string().required("required.field"),
  selectedOtherOption: yup.string(),
});

const foodTypes = [
  { id: 1, text: "Meals", iconName: "cereal" },
  { id: 2, text: "Coffee / Tea", iconName: "coffeeCup" },
  { id: 3, text: "Alcohol", iconName: "wineGlass" },
  { id: 4, text: "Snacks", iconName: "pizzaSlice" },
];
const NudgeFeeds: FC<NudgeFeedProps> = ({ searchQuery, searchh }) => {
  const pageBottomRef = useRef(null);

  const [showBackDrop, setShowBackDrop] = useState(false);
  const [showTreatModal, setShowTreatModal] = useState(false);
  const [showBirthdayModal, setShowBirthdayModal] = useState(false);
  const [showBirthdaySuccessModal, setShowBirthdaySuccessModal] =
    useState(false);
  const [showBirthdayCancelText, setShowBirthdayCancelText] = useState(false);
  const [showSportsModal, setShowSportsModal] = useState(false);
  const [showSportsSuccessModal, setShowSportsSuccessModal] = useState(false);
  const [showSportsCancelText, setShowSportsCancelText] = useState(false);
  const modalData = useAppSelector((state) => state);
  const allStakeholders = useAppSelector(state => state.globalState.stakeholders);
  const journeyData = useAppSelector((state) => state.globalState.journey);
  const navigate = useNavigate();

  const [hide, setHide] = useState(true);
  const modalHandler = () => {
    setHide(true);
  };
  const [thumsUpIds, setThumsUpIds] = useState([]);
  const [thumsDownIds, setThumsDownIds] = useState([]);
  const [loadingResult, setLoadingResult] = useState(false);
  const [selectedNudge, setSelectedNudge] = useState(null);
  const [recommendedNudges, setRecommendedNudges] = useState([]);
  const [filteredNudges, setFilteredNudges] = useState([]);
  const [searchNudges, setSearchNudges] = useState([]);
  const [search, setSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState(null);
  const [filter, setFilter] = useState(false);
  const [personName, setPersonName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showCreateEmailModal, setShowCreateEmailModal] = useState(false);
  const [activeFoodTypeId, setActiveFoodTypeId] = useState(1);
  const [continueView, setContinueView] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(true);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(true);
  const [selectedFeedBackId, setSelectedFeedBackId] = useState(null);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [originalEmailContent, setOriginalEmailContent] = useState("");
  const [emailContent, setEmailContent] = useState("");
  const [showCopiedText, setShowCopiedText] = useState(false);

  const [clickedNudge, setClickedNudge] = useState(null);
  const [clickedNudgeValues, setClickedNudgeValues] = useState(null);
  const [nudgesLoaded, setNudgesLoaded] = useState(false);

  const [openViewFeedModal, setOpenViewFeedModal] = useState(false);
  const [allNudgeUsers, setAllNudgeUsers] = useState([]);
  const [viewFeedMember, setViewFeedMember] = useState("");
  const [userType, setUserType] = useState("");
  const [searchedInput, setSearchedInput] = useState("");

  const [searchStakeholderId, setSearchStakeholderId] = useState('');
  
  const [pageNumber, setPageNumber] = useState(0);
  const [daysOlder, setDaysOlder] = useState(0);

  const dispatch = useAppDispatch();
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const fetchSearchNudges = async () => {
    try {
      const stakeholderId = allStakeholders.find(x => 
        (x.identity.firstname === searchQuery.split(' ')[0] &&
        x.identity.lastname === searchQuery.split(' ')[1]) ||
        (x.identity.email === searchQuery));
      setShowBackDrop(true);
      const result = await axiosHelper({
        url: `salesforce/searchNudges/${stakeholderId?.stakeholderId}`,
      });
      setShowBackDrop(false);
      setSearchStakeholderId(stakeholderId?.stakeholderId);
      if(result.data.data.length > 0) {
        setSearchNudges(result.data.data);
        setSearch(true);
        setFilter(false);
      }
      else {
        setSearchNudges([]);
        setSearch(true);
        setFilter(false);
      }
    }
    catch(err) {
      setShowBackDrop(false);
      console.log(err);
    }
  }

  useEffect(() => {
    getAllNudgeUsers();
  }, []);
  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    const handleScroll = (event) => {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        console.log("page bottom reacheddddd");
        setPageNumber(pageNumber + 1);
      }
    };
    const scrollableDiv = pageBottomRef.current;
    if (scrollableDiv) {
      scrollableDiv.addEventListener("scroll", handleScroll);
    }
    return () => {
      scrollableDiv.removeEventListener("scroll", handleScroll);
    };
  }, [recommendedNudges]);
  useEffect(() => {
    // @ts-ignore
    if (searchh && searchh.length === 0) {
      setSearchNudges([]);
      setSearchedInput("");
      setSearch(false);
      setFilter(false);
    }
  }, [searchh]);

  useEffect(() => {
    let filteredNudges = [];
    if (searchQuery && searchQuery.length > 0) {
      console.log(searchQuery);
      filteredNudges = recommendedNudges.filter(
        (x) =>
          x?.stakeholderName
            ?.toLowerCase().trim()
            .includes(searchQuery.toLowerCase()) ||
          x?.email?.toLowerCase().includes(searchQuery.toLowerCase())
      );

      if(filteredNudges.length > 0) {
        setSearchNudges(filteredNudges);
        setSearch(true);
        setFilter(false);
      }
      else {
        fetchSearchNudges();
      }
    } else if (searchQuery.length === 0 || searchQuery === "") {
      setSearchNudges([]);
      setSearch(false);
      setFilter(false);
    }
  }, [searchQuery]);
  
  const fetchAllNudges = async (calledFromScroll=false) => {
    try {
      setShowBackDrop(true);
      axiosHelper({
        url:
          viewFeedMember !== ""
            ? `salesforce/getNudgeData?pageNumber=${calledFromScroll ? pageNumber+1:pageNumber}&viewFeedMember=${viewFeedMember}&daysOlder=${daysOlder}`
            : `salesforce/getNudgeData?pageNumber=${calledFromScroll ? pageNumber+1:pageNumber}&daysOlder=${daysOlder}`,
        method: "GET",
        JSONData: {},
        urlOverride: false,
      })
        .then((result) => {
          console.log("resss", result?.data);
          let data = result?.data?.data;
          console.log("result data", data);

          if(result.data.daysOlder !== daysOlder) {
            setDaysOlder(result.data.daysOlder);
            setPageNumber(0);
            setRecommendedNudges([...recommendedNudges, ...data]);
            // @ts-ignore
            dispatch(setRecommendedNudgesData([...recommendedNudges, ...data]));
          }

          if (pageNumber > 0 || daysOlder>0) {
            setRecommendedNudges([...recommendedNudges, ...data]);
            // @ts-ignore
            dispatch(setRecommendedNudgesData([...recommendedNudges, ...data]));
          } else {
            setRecommendedNudges([...data]);
            // @ts-ignore
            dispatch(setRecommendedNudgesData([...data]));
          }
          setNudgesLoaded(true);
          setUserType(result.data?.userType);
          setPersonName(result?.data?.name);
          dispatch(setLoggedInUserName(result.data.name));
          // dispatch(setRecommendedNudgesData([...recommendedNudges, ...data]));
          setShowBackDrop(false);
        })
    }
    catch(err) {
      setShowBackDrop(false);
      console.log("error", err);
      checkUnauthorized(err, journeyData, navigate);
    }
  }

  useEffect(() => {
    if (!(search && !filter && searchNudges.length > 0) && !(daysOlder>0 && pageNumber===0)) {
      fetchAllNudges();
    }
  }, [viewFeedMember, pageNumber, daysOlder]);

  const getAllNudgeUsers = async () => {
    try {
      setShowBackDrop(true);
      const result = await axiosHelper({
        url: `salesforce/getAllNudgeUsers`,
        method: "GET",
        urlOverride: false,
      });

      if (result.data.success) {
        if (result.data && !result.data?.doesAdminHaveNudges) {
          setAllNudgeUsers(result.data.data);
          if (result.data?.data.length > 1) {
            setViewFeedMember(result.data?.data[1]?.userId);
          }
          setShowBackDrop(false);
        } else {
          setAllNudgeUsers(result.data.data);
          setShowBackDrop(false);
        }
      }
    } catch (err) {
      console.log(err);
      checkUnauthorized(err, journeyData, navigate);
      setShowBackDrop(false);
    }
  };

  const undoNudgeAction = async (nudgeId, type) => {
    axiosHelper({
      url: `salesforce/updateNudgeVisibility/${nudgeId}/${false}`,
      method: 'PATCH'
    });
    if (type == "up") {
      let updatedThumsUpIds = thumsUpIds.filter((id) => id != nudgeId);
      setThumsUpIds(updatedThumsUpIds);
    } else if (type == "down") {
      let updatedThumsDownIds = thumsDownIds.filter((id) => id != nudgeId);
      setThumsDownIds(updatedThumsDownIds);
    }
  };
  const updateNudgeFeedback = async (nudge, feedback, comment) => {
    console.log(nudge);
    const result = await axiosHelper({
      url: "salesforce/updateNudgeFeedback",
      method: "POST",
      JSONData: {
        stakeholderName: nudge.stakeholderName,
        stakeholderEmail: nudge.email,
        nudgeContext: nudge.context,
        nudgeHeadline: nudge.nudgeHeadline,
        nudgeTypeUniqueId: nudge.uniqueId,
        nudgeId: nudge.nudgeId,
        feedback: feedback,
        comment: comment,
      },
      urlOverride: false,
    });
    if (result.data.success) {
      if (feedback == "up") {
        if (
          !thumsUpIds.includes(nudge?.nudgeId) &&
          !thumsDownIds.includes(nudge?.nudgeId)
        ) {
          setThumsUpIds([nudge?.nudgeId, ...thumsUpIds]);
        } else if (
          !thumsUpIds.includes(nudge?.nudgeId) &&
          thumsDownIds.includes(nudge?.nudgeId)
        ) {
          let updatedthumsDownIds = thumsDownIds.filter(
            (id) => id != nudge?.nudgeId
          );
          setThumsUpIds([nudge?.nudgeId, ...thumsUpIds]);
          setThumsDownIds(updatedthumsDownIds);
        }
      } else if (feedback == "down") {
        if (
          !thumsDownIds.includes(nudge?.nudgeId) &&
          !thumsUpIds.includes(nudge?.nudgeId)
        ) {
          setThumsDownIds([nudge?.nudgeId, ...thumsDownIds]);
        } else if (
          !thumsDownIds.includes(nudge?.nudgeId) &&
          thumsUpIds.includes(nudge?.nudgeId)
        ) {
          let updatedthumsUpIds = thumsUpIds.filter(
            (id) => id != nudge?.nudgeId
          );
          setThumsDownIds([nudge?.nudgeId, ...thumsDownIds]);
          setThumsUpIds(updatedthumsUpIds);
        }
      }
      if (screenSize.dynamicWidth > 576) {
        setShowSnackBar(true);
      }
    }
    console.log(result.data);
  };

  let feedBackOptions = [
    { id: "1", text: "This kind of nudge is repetitive", value: false },
    {
      id: "2",
      text: "This kind of nudge is not impactful for this stakeholder",
      value: false,
    },
    {
      id: "3",
      text: "This kind of nudge is boring",
      value: false,
    },
    {
      id: "4",
      text: "Other",
      value: false,
    },
  ];

  const onSubmitHandler = useCallback(async (values: any) => {
    console.log(values, "values");
  }, []);

  const getDomainName = (url) => {
    try {
      return new URL(url).hostname.replace("www.", "");
    }
    catch(err) {
      console.log(url, err);
      return '';
    }
  };
  const getMobileNudgeIcon = (nudge, isHover?: boolean) => {
    if ( nudge?.nudgeHeadlinePayload?.type === "podcastEpisode" ) {
      return <Icon  size="32px" name={  isHover ? "nudgePodcastHover" : "nudgePodcast"} />
    } else if ( nudge?.nudgeHeadlinePayload?.type === "books" )  {
     return <Icon  size="32px" name={ isHover ? "nudgeBookHover"  : "nudgeBook" } />
    }  else if ( nudge?.nudgeHeadlinePayload?.type === "movies" ) {
    return <Icon  size="32px" name={ isHover ? "nudgeMovieHover"  : "nudgeMovie" } />
    } else if (nudge?.nudgeHeadlinePayload?.type === "birthdayWish") {
      return (
        <Icon
          name={isHover ? "nudgeBirthdayHover" : "nudgeBirthday"}
          size="32px"
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesKids") {
      return (
        <Icon
          size="32px"
          name={
            isHover ? "nudgeGooglePlacesKidsHover" : "nudgeGooglePlacesKids"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesPets") {
      return (
        <Icon
          size="32px"
          name={
            isHover ? "nudgeGooglePlacesPetsHover" : "nudgeGooglePlacesPets"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesCafe") {
      return (
        <Icon
          size="32px"
          name={
            isHover ? "nudgeGooglePlacesCafeHover" : "nudgeGooglePlacesCafe"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesRestaurant") {
      return (
        <Icon
          size="32px"
          name={
            isHover
              ? "nudgeGooglePlacesRestaurantHover"
              : "nudgeGooglePlacesRestaurant"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesOutdoor") {
      return (
        <Icon
          size="32px"
          name={
            isHover
              ? "nudgeGooglePlacesOutdoorHover"
              : "nudgeGooglePlacesOutdoor"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "weatherAlert") {
      return (
        <Icon
          size="32px"
          name={isHover ? "nudgeWeatherAlertHover" : "nudgeWeatherAlert"}
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "weatherPatternChange") {
      return (
        <Icon
          size="32px"
          name={
            isHover
              ? "nudgeWeatherPatternChangeHover"
              : "nudgeWeatherPatternChange"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "espnNews") {
      return (
        <Icon
          size="32px"
          name={isHover ? "nudgeSportNewsHover" : "nudgeSportNews"}
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "espnScore") {
      return (
        <Icon
          size="32px"
          name={isHover ? "nudgeSportsScoreHover" : "nudgeSportsScore"}
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "espnSchedule") {
      return (
        <Icon
          size="32px"
          name={isHover ? "nudgeSportsScheduleHover" : "nudgeSportsSchedule"}
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "spotifyMusic") {
      return (
        <Icon
          size="32px"
          name={isHover ? "nudgeSpotifyMusicHover" : "nudgeSpotifyMusic"}
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "ticketMasterConcert") {
      return (
        <Icon
          size="32px"
          name={isHover ? "nudgeConcertTicketsHover" : "nudgeConcertTickets"}
        />
      );
    }
  };
  // const getMobileNudgeIcon = (nudge) => {
  //   if (nudge?.nudgeCategory == "googlePlaces") {
  //     return <Icon name="nudgeCardPlace" size="32px" />;
  //   } else if (nudge?.nudgeCategory == "news") {
  //     return <Icon name="nudgeCardNews" size="32px" />;
  //   } else if (nudge?.nudgeCategory == "music") {
  //     return <Icon name="nudgeCardMusic" size="32px" />;
  //   } else if (nudge?.nudgeCategory == "concertTickets") {
  //     return <Icon name="nudgeCardTicket" size="32px" />;
  //   } else if (nudge?.nudgeCategory == "sports") {
  //     return <Icon name="nudgeCardSports" size="32px" />;
  //   } else if (nudge?.nudgeCategory == "orderFood") {
  //     return <Icon name="nudgeCardFood" size="32px" />;
  //   } else if (nudge?.nudgeCategory == "weather") {
  //     return <Icon name="nudgeCardWeather" size="32px" />;
  //   } else if (
  //     [
  //       "birthday",
  //       "birthday-alcohol",
  //       "birthday-cuisine",
  //       "birthday-indoor",
  //       "birthday-movie",
  //       "birthday-music",
  //       "birthday-outdoor",
  //       "birthdayAdvanced",
  //       "birthdayBelated",
  //     ].includes(nudge?.nudgeCategory)
  //   ) {
  //     return <Icon name="nudgeCardBirthday" size="32px" />;
  //   }
  // };
  const getNudgeIcon = (nudge, isHover?: boolean) => {
    if ( nudge?.nudgeHeadlinePayload?.type === "podcastEpisode" ) {
      return <Icon name={  isHover ? "nudgePodcastHover" : "nudgePodcast"} />
    } else if ( nudge?.nudgeHeadlinePayload?.type === "books" )  {
     return <Icon name={ isHover ? "nudgeBookHover"  : "nudgeBook" } />
    }  else if ( nudge?.nudgeHeadlinePayload?.type === "movies" ) {
    return <Icon name={ isHover ? "nudgeMovieHover"  : "nudgeMovie" } />
    } else if (nudge?.nudgeHeadlinePayload?.type === "birthdayWish") {
      return <Icon name={isHover ? "nudgeBirthdayHover" : "nudgeBirthday"} />;
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesKids") {
      return (
        <Icon
          name={
            isHover ? "nudgeGooglePlacesKidsHover" : "nudgeGooglePlacesKids"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesPets") {
      return (
        <Icon
          name={
            isHover ? "nudgeGooglePlacesPetsHover" : "nudgeGooglePlacesPets"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesCafe") {
      return (
        <Icon
          name={
            isHover ? "nudgeGooglePlacesCafeHover" : "nudgeGooglePlacesCafe"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesRestaurant") {
      return (
        <Icon
          name={
            isHover
              ? "nudgeGooglePlacesRestaurantHover"
              : "nudgeGooglePlacesRestaurant"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "googlePlacesOutdoor") {
      return (
        <Icon
          name={
            isHover
              ? "nudgeGooglePlacesOutdoorHover"
              : "nudgeGooglePlacesOutdoor"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "weatherAlert") {
      return (
        <Icon name={isHover ? "nudgeWeatherAlertHover" : "nudgeWeatherAlert"} />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "weatherPatternChange") {
      return (
        <Icon
          name={
            isHover
              ? "nudgeWeatherPatternChangeHover"
              : "nudgeWeatherPatternChange"
          }
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "espnNews") {
      return <Icon name={isHover ? "nudgeSportNewsHover" : "nudgeSportNews"} />;
    } else if (nudge?.nudgeHeadlinePayload?.type === "espnScore") {
      return (
        <Icon name={isHover ? "nudgeSportsScoreHover" : "nudgeSportsScore"} />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "espnSchedule") {
      return (
        <Icon
          name={isHover ? "nudgeSportsScheduleHover" : "nudgeSportsSchedule"}
        />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "spotifyMusic") {
      return (
        <Icon name={isHover ? "nudgeSpotifyMusicHover" : "nudgeSpotifyMusic"} />
      );
    } else if (nudge?.nudgeHeadlinePayload?.type === "ticketMasterConcert") {
      return (
        <Icon
          name={isHover ? "nudgeConcertTicketsHover" : "nudgeConcertTickets"}
        />
      );
    }
  };
  const getNudgeBtn = (nudge) => {
    if (nudge?.nudgeCategory == "weather") {
      return (
        <Button
          variant="primary"
          onClick={() => loadEmailEditorScreen(nudge)}
          loading={loadingResult && selectedNudge?.nudgeId === nudge?.nudgeId}
        >
          {" "}
          Create email{" "}
        </Button>
      );
    } else if (
      nudge?.nudgeCategory == "googlePlaces" &&
      nudge?.crawlerData?.maps_url
    ) {
      return (
        <Button
          variant="primary"
          onClick={() => loadEmailEditorScreen(nudge)}
          loading={loadingResult && selectedNudge?.nudgeId === nudge?.nudgeId}
        >
          {" "}
          Create email{" "}
        </Button>
      );
    } else if (nudge?.nudgeCategory == "news" && nudge?.crawlerData?.url) {
      return (
        <Button
          variant="primary"
          onClick={() => loadEmailEditorScreen(nudge)}
          loading={loadingResult && selectedNudge?.nudgeId === nudge?.nudgeId}
        >
          {" "}
          Create email{" "}
        </Button>
      );
    } else if (nudge?.nudgeCategory == "music" && nudge?.crawlerData?.link) {
      return (
        <Button
          variant="primary"
          onClick={() => loadEmailEditorScreen(nudge)}
          loading={loadingResult && selectedNudge?.nudgeId === nudge?.nudgeId}
        >
          {" "}
          Create email{" "}
        </Button>
      );
    } else if (
      nudge?.nudgeCategory == "concertTickets" &&
      nudge?.crawlerData?.eventTickets
    ) {
      return (
        <Button
          variant="primary"
          onClick={() => loadEmailEditorScreen(nudge)}
          loading={loadingResult && selectedNudge?.nudgeId === nudge?.nudgeId}
        >
          {" "}
          Create email{" "}
        </Button>
      );
    } else if (
      nudge?.nudgeCategory == "sports" &&
      nudge?.crawlerData?.news_url
    ) {
      return (
        <Button
          variant="primary"
          onClick={() => loadEmailEditorScreen(nudge)}
          loading={loadingResult && selectedNudge?.nudgeId === nudge?.nudgeId}
        >
          {" "}
          Create email{" "}
        </Button>
      );
    } else if (nudge?.nudgeCategory == "orderFood") {
      return (
        <Button
          variant="primary"
          onClick={() => {
            setShowModal(true);
            setClickedNudge(nudge);
          }}
        >
          {" "}
          Order Food{" "}
        </Button>
      );
    } else if (
      [
        "birthday",
        "birthday-alcohol",
        "birthday-cuisine",
        "birthday-indoor",
        "birthday-movie",
        "birthday-music",
        "birthday-outdoor",
        "birthdayAdvanced",
        "birthdayBelated",
      ].includes(nudge?.nudgeCategory)
    ) {
      if (showBirthdayCancelText) {
        return (
          <Grid>
            <Flex alignItems={"center"} justifyContent={"end"}>
              <SuccessTickIcon />
              <Text ml="4px" color="#24B300" fontSize={"14px"} fontWeight={500}>
                {`Birthday ${
                  modalData.aeReturnSelectedNudge.birthdayNudge.category ===
                  "Birthday gift"
                    ? "gift"
                    : modalData.aeReturnSelectedNudge.birthdayNudge.category ===
                      "Order a cake"
                    ? "cake"
                    : "flowers"
                } scheduled`}
              </Text>
            </Flex>

            <Flex alignItems={"center"} mt="8px">
              <Text
                color={"#DD015B"}
                cursor="pointer"
                mr="10px"
                onClick={() => setShowBirthdayCancelText(false)}
              >
                Cancel request
              </Text>
              <Flex gridGap={"4px"}>
                <TimerIcon />
                <Timer />
              </Flex>
            </Flex>
          </Grid>
        );
      }

      return (
        <Button variant="primary" onClick={() => setShowBirthdayModal(true)}>
          Send a birthday gift
        </Button>
      );
    } else if (["sports"].includes(nudge?.nudgeCategory)) {
      if (showSportsCancelText) {
        return (
          <Grid>
            <Flex alignItems={"center"} justifyContent={"end"}>
              <SuccessTickIcon />
              <Text ml="4px" color="#24B300" fontSize={"14px"} fontWeight={500}>
                {`${
                  modalData.aeReturnSelectedNudge.sportsNudge.category ===
                  "Send tickets"
                    ? "Ticket booked"
                    : "Gear ordered"
                }`}
              </Text>
            </Flex>

            <Flex alignItems={"center"} mt="8px">
              <Text
                color={"#DD015B"}
                cursor="pointer"
                mr="10px"
                onClick={() => setShowSportsCancelText(false)}
              >
                Cancel request
              </Text>
              <Flex gridGap={"4px"}>
                <TimerIcon />
                <Timer />
              </Flex>
            </Flex>
          </Grid>
        );
      }

      return (
        <Button variant="primary" onClick={() => setShowSportsModal(true)}>
          Send a game ticket
        </Button>
      );
    } else {
      return null;
    }
  };
  const loadBirthdayEmail = (name, setLoad) => {
    setLoad(true);
    let data = {
      url: "https://rapport-intelligence-v1.herokuapp.com/email/birthday",
      payload: {
        name,
        tone: "professional",
      },
    };
    axiosHelper({
      url: "salesforce/crawl_url",
      method: "POST",
      JSONData: data,
      urlOverride: false,
    })
      .then((results) => {
        let data = results?.data?.data?.data;
        console.log("dataaaa", data);

        let textAreaString = `Subject: ${data.subject}\n\n${data.greeting}\n\n${data?.body}\n\n${data?.signOff}`;
        setOriginalEmailContent(textAreaString);
        setEmailContent(textAreaString);
        setLoad(false);
        setShowCreateEmailModal(true);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const loadEmailEditorScreen = (nudge: any) => {
    console.log("selecteddd nudge", nudge);
    setSelectedNudge(nudge);
    setLoadingResult(true);
    let pay = { ...nudge.gptPayload };
    let crawlerData = nudge.crawlerData;
    for (let key in pay) {
      if (pay[key].includes("$")) {
        // console.log(pay[key])
        pay[key] = crawlerData[pay[key].substring(1)];
      }
    }
    let data = {
      url: nudge.gptUrl,
      payload: pay,
    };
    axiosHelper({
      url: "salesforce/crawl_url",
      method: "POST",
      JSONData: data,
      urlOverride: false,
    })
      .then((results) => {
        let data = results?.data?.data?.data;
        console.log("dataaaa", data);
        setLoadingResult(false);
        let textAreaString = `Subject: ${data.subject}\n\n${data.greeting}\n\n${data?.body}\n\n${data?.signOff}`;
        setOriginalEmailContent(textAreaString);
        setEmailContent(textAreaString);
        setShowCreateEmailModal(true);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const getUniqueFilterNumber = (type) => {
    let number;
    if (type == "accountName") {
      let accountNames = [];
      for (let i = 0; i < filteredNudges.length; i++) {
        if (!accountNames.includes(filteredNudges[i].accountName)) {
          accountNames.push(filteredNudges[i].accountName);
        }
      }
      number = accountNames.length;
    } else if (type == "opportunityId") {
      let opportunityIds = [];
      for (let i = 0; i < filteredNudges.length; i++) {
        if (!opportunityIds.includes(filteredNudges[i].opportunityId)) {
          opportunityIds.push(filteredNudges[i].opportunityId);
        }
      }
      number = opportunityIds.length;
    } else if (type == "stakeholderId") {
      let stakeHolderIds = [];
      for (let i = 0; i < filteredNudges.length; i++) {
        if (!stakeHolderIds.includes(filteredNudges[i].stakeholderId)) {
          stakeHolderIds.push(filteredNudges[i].stakeholderId);
        }
      }
      number = stakeHolderIds.length;
    } else if (type == "oppValue") {
      let totalVal = 0;
      let opportunityIds = [];
      for (let i = 0; i < filteredNudges.length; i++) {
        if (!opportunityIds.includes(filteredNudges[i].opportunityId)) {
          opportunityIds.push(filteredNudges[i].opportunityId);
          totalVal += filteredNudges[i].opportunityValue;
        }
      }

      number = totalVal;
    }
    console.log("nunber", number);
    return number;
  };

  useEffect(() => {
    if (
      clickedNudge &&
      Object.keys(clickedNudge).length > 0 &&
      clickedNudgeValues &&
      Object.keys(clickedNudgeValues).length > 0
    ) {
      submitFoodOrder();
    }
  }, [clickedNudge, clickedNudgeValues]);

  const submitFoodOrder = async () => {
    const result = await axiosHelper({
      url: "orders/createOrder",
      method: "POST",
      JSONData: {
        nudgeId: clickedNudge.nudgeId,
        stakeholderId: clickedNudge.stakeholderId,
        foodType: foodTypesMap[activeFoodTypeId],
        orderSchedule: clickedNudgeValues.selectTime[0],
        orderInstructions: clickedNudgeValues.instruction,
        orderMessage: clickedNudgeValues.message,
        orderPrice: 20,
        url: clickedNudge.url,
        payload: clickedNudge.payload,
        orderType: "food",
      },
    });
    if (result.data.success) {
      setContinueView(true);
      console.log("order created");
    }
  };

  const onSubmitHandlerr = useCallback(async (values: any) => {
    console.log(values, activeFoodTypeId, "foodvalues");
    setClickedNudgeValues(values);
  }, []);
  return (
    <div
      ref={pageBottomRef}
      style={{ overflowY: "scroll", marginBottom: "32px" }}
    >
      <Flex
        flexDirection={"column"}
        // overflowY={"scroll"}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
          open={showBackDrop}
        >
          <Icon name="loadingIcon" />
        </Backdrop>
        <ConfirmationModal
          top={"40%"}
          left={"50%"}
          width="40%"
          height="auto"
          open={showDeleteConfirmationModal}
          onClose={() => setShowDeleteConfirmationModal(true)}
        >
          <Flex flexDirection={"column"} gridRowGap={"26px"} height={"100%"}>
            <Text color={"#050505"} fontWeight={"400"} fontSize={"24px"}>
              {" "}
              Are you sure you want to cancel this request?{" "}
            </Text>
            <Flex
              flexDirection={"row"}
              gridColumnGap={"60px"}
              alignItems={"center"}
            >
              <Button
                variant="primary"
                onClick={() => setShowDeleteConfirmationModal(true)}
              >
                {" "}
                No, don't cancel it{" "}
              </Button>
              <Button
                variant="reviewConfirm"
                onClick={() => setShowDeleteConfirmationModal(true)}
              >
                {" "}
                Yes, cancel it{" "}
              </Button>
            </Flex>
          </Flex>
        </ConfirmationModal>
        <CreateEmailModal
          showCreateEmailModal={showCreateEmailModal}
          emailContent={emailContent}
          setShowCreateEmailModal={setShowCreateEmailModal}
          setShowCopiedText={setShowCopiedText}
          setEmailContent={setEmailContent}
          setOriginalEmailContent={setOriginalEmailContent}
          showCopiedText={showCopiedText}
          originalEmailContent={originalEmailContent}
          loadEmailEditorScreen={loadEmailEditorScreen}
          selectedNudge={selectedNudge}
        />
        {/* <SendTreatModal
 setShowTreatModal={setShowTreatModal}
 showTreatModal={showTreatModal}
 selectedNudge={selectedNudge}
 /> */}

        <OrderFoodModal
          showModal={showModal}
          setShowModal={setShowModal}
          setContinueView={setContinueView}
          onSubmitHandlerr={onSubmitHandlerr}
          CustomerValidationSchema={CustomerValidationSchema}
          continueView={continueView}
          foodTypes={foodTypes}
          activeFoodTypeId={activeFoodTypeId}
          setActiveFoodTypeId={setActiveFoodTypeId}
        />
        <RightSideModal
          open={showBirthdayModal}
          onClose={() => {
            setShowBirthdayModal(false);
          }}
        >
          <BirthdayNudge
            setShowBirthdaySuccessModal={setShowBirthdaySuccessModal}
            setShowBirthdayModal={setShowBirthdayModal}
          />
        </RightSideModal>
        <RightSideModal
          open={showBirthdaySuccessModal}
          onClose={() => {
            setShowBirthdaySuccessModal(false);
          }}
        >
          <SuccessModal
            heading="We have received your request successfully. Eva concierge team will
 determine the best possible option within your approved budget and get
 it delivered."
            subText1="AI will curate a gift box based on their preferences"
            subText2="We’ll send you emails with the order details, order status and
 recipient comments (if any)"
            subText3="You can cancel this request within 30 minutes of confirmation"
            setShowBirthdaySuccessModal={setShowBirthdaySuccessModal}
            setShowBirthdayCancelText={setShowBirthdayCancelText}
          />
        </RightSideModal>
        <RightSideModal
          open={showSportsModal}
          onClose={() => {
            setShowSportsModal(false);
          }}
        >
          <SportsNudge
            setShowSportsSuccessModal={setShowSportsSuccessModal}
            setShowSportsModal={setShowSportsModal}
          />
        </RightSideModal>
        <RightSideModal
          open={showSportsSuccessModal}
          onClose={() => {
            setShowSportsSuccessModal(false);
          }}
        >
          <SuccessModal
            heading="We have received your request successfully.
Eva concierge team will coordinate with your recipient to deliver the tickets within your approved budget."
            subText1="We'll coordinate with the recipient and get the
tickets delivered soon"
            subText2="You'll receive emails detailing the order status and recipient comments (if any)"
            subText3="You can cancel this request within 30 minutes of confirmation"
            setShowBirthdaySuccessModal={setShowSportsSuccessModal}
            setShowBirthdayCancelText={setShowSportsCancelText}
          />
        </RightSideModal>
        <SnackBar
          iconName={"info"}
          message={"Great!"}
          setShowSnackBar={setShowSnackBar}
          show={showSnackBar}
          subHeading="Thank you for sharing the feedback"
          timeout={4000}
          anchor={"top-right"}
          type={"success"}
          bgColorr="#24B300"
          // bgColorr="#F44545"
        />

        {
          //@ts-ignore
          <AeReturnUnlikeModal
            closeModal={() => setHide(true)}
            // width="670px"
            // width="450px"
            // height="max-content"
            width={screenSize.dynamicWidth > 576 ? "450px" : "100vw"}
            height={screenSize.dynamicWidth > 576 ? "max-content" : "100vh"}
            screenSize={screenSize}
            hideModal={hide}
            setHideModal={modalHandler}
            action={true}
            updateNudgeFeedback={updateNudgeFeedback}
            padding="48px"
          ></AeReturnUnlikeModal>
        }
        <ConfirmationModal
          top={"40%"}
          left={"50%"}
          width="40%"
          height="50%"
          open={showConfirmationModal}
          onClose={() => setShowConfirmationModal(true)}
        >
          <Flex flexDirection={"column"} gridRowGap={"26px"} height={"100%"}>
            <Flex flexDirection={"column"} gridRowGap={"4px"}>
              <Text fontWeight={"500"} color={"#050505"} fontSize={"24px"}>
                {" "}
                Tell us why you don't like this nudge{" "}
              </Text>
              <Text fontWeight={"400"} color={"#050505"} fontSize={"16px"}>
                {" "}
                Your feedback will help us improve your nudge feed{" "}
              </Text>
            </Flex>
            <Formik
              initialValues={{
                selectedId: selectedFeedBackId,
                selectedOtherOption: "",
              }}
              onSubmit={onSubmitHandler}
              enableReinitialize
              validationSchema={AddCustomerValidationSchema}
            >
              {({
                values,
                errors,
                dirty,
                isValid,
                setFieldValue,
                initialValues,
              }) => {
                console.log(errors, "error", values, initialValues);
                return (
                  <Form style={{ height: "100%" }}>
                    <Flex
                      flexDirection={"column"}
                      alignItems={"space-between"}
                      height={"100%"}
                      justifyContent={"space-between"}
                    >
                      <Flex flexDirection={"column"} gridRowGap={"18px"}>
                        {feedBackOptions.map((option) => {
                          return (
                            <Flex
                              flexDirection={"row"}
                              gridColumnGap={"8px"}
                              alignItems={"center"}
                            >
                              <input
                                type="checkbox"
                                checked={selectedFeedBackId == option.id}
                                onChange={(evt) => {
                                  // console.log("target",evt.target.checked);
                                  if (evt.target.checked) {
                                    // @ts-ignore
                                    setSelectedFeedBackId(option.id);
                                  }
                                }}
                              />
                              <Text
                                color={"#0A0A0A"}
                                fontWeight={"400"}
                                fontSize={"14px"}
                              >
                                {option.text}{" "}
                              </Text>
                            </Flex>
                          );
                        })}
                        {selectedFeedBackId == "4" && (
                          <FormikInput
                            width={"100%"}
                            name="selectedOtherOption"
                            placeholder="typeHere"
                          />
                        )}
                      </Flex>
                      <Flex
                        flexDirection={"row"}
                        gridColumnGap={"16px"}
                        alignItems={"center"}
                        marginTop={"10px"}
                      >
                        <Button variant="primary"> Submit </Button>
                        <Button variant="reviewConfirm"> Undo </Button>
                      </Flex>
                    </Flex>
                  </Form>
                );
              }}
            </Formik>
          </Flex>
        </ConfirmationModal>

        <Box
          // background="#EAF2FF"
          // overflow="auto"
          height="100vh"
          // paddingBottom={"40px"}
          // overflowY={"scroll"}
          marginBottom="40px"
        >
          <Flex
            flexDirection={"column"}
            paddingLeft={screenSize.dynamicWidth > 576 ? "70px" : "16px"}
            paddingRight={screenSize.dynamicWidth > 576 ? "70px" : "16px"}
            paddingBottom={screenSize.dynamicWidth > 576 ? "30px" : "12px"}
            gridRowGap={screenSize.dynamicWidth > 576 ? "24px" : "12px"}
            alignItems={"flex-start"}
            height={"100%"}
          >
            {searchType != "name" && nudgesLoaded && !search && (
              <Flex
                justifyContent={"space-between"}
                gridRowGap={"12px"}
                flexDirection={screenSize.dynamicWidth > 576 ? "row" : "column"}
                alignItems={
                  screenSize.dynamicWidth > 576 ? "center" : "flex-start"
                }
                width={"100%"}
                marginTop={screenSize.dynamicWidth > 576 ? "24px" : "16px"}
              >
                <Text
                  color={"#050505"}
                  fontWeight={"400"}
                  fontSize={screenSize.dynamicWidth > 576 ? "24px" : "14px"}
                  // marginTop={screenSize.dynamicWidth > 576 ? "24px" : "16px"}
                >
                  {" "}
                  Welcome back, {personName}!
                </Text>
                <Flex alignItems={"center"}>
                  {userType && userType !== "" && userType === "admin" && (
                    <Text
                      fontSize={"12px"}
                      marginRight={"8px"}
                      fontWeight={400}
                      color={"#050505"}
                    >
                      View the feed for
                    </Text>
                  )}
                  <Grid
                    onMouseEnter={() => setOpenViewFeedModal(true)}
                    onMouseLeave={() => setOpenViewFeedModal(false)}
                  >
                    {userType && userType !== "" && userType === "admin" ? (
                      // !openViewFeedModal ? (
                      <>
                        <Flex
                          // width={"100%"}
                          padding={"10px 18px"}
                          borderRadius="8px"
                          backgroundColor={"#fff"}
                          alignItems="center"
                          cursor="pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            // setOpenViewFeedModal(true);
                          }}
                          position={"relative"}
                        >
                          <Box mr="8px" fontSize={"14px"} fontWeight={500}>
                            {viewFeedMember !== ""
                              ? allNudgeUsers.filter(
                                  (x) => x.userId === viewFeedMember
                                )[0]?.username
                              : "Yourself"}
                          </Box>
                          <SortByArrow />
                        </Flex>
                        <Flex
                          style={{
                            display: openViewFeedModal ? "flex" : "none",
                          }}
                          flexDirection={"column"}
                          padding={"20px 24px"}
                          borderRadius="8px"
                          backgroundColor={"#fff"}
                          cursor={"pointer"}
                          color="#0C1116"
                          fontSize={"14px"}
                          fontWeight={500}
                          gridGap={"24px"}
                          boxShadow={"0px 4px 15px rgba(0, 0, 0, 0.07)"}
                          position={"absolute"}
                          zIndex={"1000"}
                          width={"100px"}
                          // top="-12px"
                          // right="-7px"
                          right={
                            screenSize.dynamicWidth > 576 ? "-7px" : "auto"
                          }
                          wordBreak="break-word"
                        >
                          <Flex
                            justifyContent={"space-between"}
                            onClick={() => {
                              // setOpenViewFeedModal(false);
                              setPageNumber(0);
                              setDaysOlder(0);
                              setViewFeedMember("");
                            }}
                          >
                            <Text>Yourself</Text>
                            <SortByDropArrow />
                          </Flex>
                          {allNudgeUsers.length > 1 &&
                            allNudgeUsers.map((user, id) => {
                              if (id > 0)
                                return (
                                  <Flex
                                    key={id}
                                    onClick={() => {
                                      // setOpenViewFeedModal(false);
                                      setViewFeedMember(user.userId);
                                      setPageNumber(0);
                                      setDaysOlder(0);
                                    }}
                                  >
                                    {user.username}
                                  </Flex>
                                );
                              else return <></>;
                            })}
                        </Flex>
                      </>
                    ) : (
                      // )
                      <></>
                    )}
                  </Grid>
                </Flex>
              </Flex>
            )}

            {!filter &&
              !search &&
              nudgesLoaded &&
              recommendedNudges.length > 0 &&
              screenSize.dynamicWidth > 576 &&
              recommendedNudges.map((nudge, id) => {
                return (
                  <RecommendNudgeView
                    getDomainName={getDomainName}
                    nudgesLoaded={nudgesLoaded}
                    undoNudgeAction={undoNudgeAction}
                    nudge={nudge}
                    key={id}
                    recommendedNudges={recommendedNudges}
                    setSearchTerm={setSearchTerm}
                    setSearchType={setSearchType}
                    setSelectedNudge={setSelectedNudge}
                    setFilteredNudges={setFilteredNudges}
                    setFilter={setFilter}
                    setSearch={setSearch}
                    nudgeCategoryMap={nudgeCategoryMap}
                    getNudgeBtn={getNudgeBtn}
                    getNudgeIcon={getNudgeIcon}
                    thumsUpIds={thumsUpIds}
                    updateNudgeFeedback={updateNudgeFeedback}
                    thumsDownIds={thumsDownIds}
                    setHide={setHide}
                    hide={hide}
                    setSelectedNudgeForUnlike={setSelectedNudgeForUnlike}
                    dispatch={dispatch}
                  />
                );
              })}
            {!filter &&
              !search &&
              nudgesLoaded &&
              recommendedNudges.length > 0 &&
              screenSize.dynamicWidth <= 576 &&
              recommendedNudges.map((nudge) => {
                return (
                  <RecommendMobileNudgeView
                    nudgesLoaded={nudgesLoaded}
                    undoNudgeAction={undoNudgeAction}
                    nudge={nudge}
                    recommendedNudges={recommendedNudges}
                    setSearchTerm={setSearchTerm}
                    setSearchType={setSearchType}
                    setSelectedNudge={setSelectedNudge}
                    setFilteredNudges={setFilteredNudges}
                    setFilter={setFilter}
                    setSearch={setSearch}
                    nudgeCategoryMap={nudgeCategoryMap}
                    getNudgeBtn={getNudgeBtn}
                    getNudgeIcon={getMobileNudgeIcon}
                    thumsUpIds={thumsUpIds}
                    updateNudgeFeedback={updateNudgeFeedback}
                    thumsDownIds={thumsDownIds}
                    setHide={setHide}
                    hide={hide}
                    setSelectedNudgeForUnlike={setSelectedNudgeForUnlike}
                    dispatch={dispatch}
                  />
                );
              })}
            {!filter &&
              !search &&
              nudgesLoaded &&
              recommendedNudges.length == 0 && (
                <Flex
                  alignItems={"center"}
                  justifyContent={"center"}
                  width={"100%"}
                  height={"100%"}
                  flexDirection={"column"}
                  gridRowGap={"32px"}
                >
                  <Icon name="emptyScreen" />
                  <Flex flexDirection={"column"} gridRowGap={"8px"}>
                    <Text
                      alignSelf={"center"}
                      color={"#050505"}
                      fontSize={"20px"}
                      fontWeight={"500"}
                      lineHeight={"140%"}
                      // width={"60%"}
                    >
                      {" "}
                      None of the stakeholders have yet{" "}
                    </Text>
                    <Text
                      alignSelf={"center"}
                      color={"#050505"}
                      fontSize={"20px"}
                      fontWeight={"500"}
                      lineHeight={"140%"}
                    >
                      shared their preferences
                    </Text>
                    <Text
                      alignSelf={"center"}
                      color={"#57585C"}
                      fontWeight={"400"}
                      fontSize={"14px"}
                      lineHeight={"140%"}
                    >
                      {" "}
                      We'll notify you as soon as we have the nudges ready for
                      you.{" "}
                    </Text>
                  </Flex>
                </Flex>
              )}
            {filter && !search && (
              <Flex
                flexDirection={"column"}
                gridRowGap={"16px"}
                width={"100%"}
                marginTop={searchType == "name" ? "24px" : "0px"}
              >
                <Flex
                  flexDirection={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gridColumnGap={"20px"}
                >
                  <Box
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setFilter(false);
                      setFilteredNudges([]);
                      setSearchType(null);
                    }}
                  >
                    <Icon name="arrowLeft" fill="#050505" />
                  </Box>
                  {searchType != "name" ? (
                    <Text
                      color={"#050505"}
                      fontWeight={"400"}
                      fontSize={"20px"}
                    >
                      {" "}
                      Showing {filteredNudges.length} results for{" "}
                      <Text
                        color={"#0094E3"}
                        display={"inline-block"}
                        fontWeight={"400"}
                        fontSize={"20px"}
                        onClick={() => {navigate(`/aeReturnDashboard/stakeholder/${searchStakeholderId}`, {replace: true})}}
                      >
                        "{searchTerm}”
                      </Text>{" "}
                    </Text>
                  ) : (
                    <Text
                      color={"#050505"}
                      fontWeight={"400"}
                      fontSize={"20px"}
                      lineHeight={"140%"}
                      onClick={() => {navigate(`/aeReturnDashboard/stakeholder/${searchStakeholderId}`, {replace: true})}}
                    >
                      {searchTerm}'s profile
                    </Text>
                  )}
                </Flex>
                {searchType == "opportunityName" && (
                  <OpportunityNameFilterView
                    undoNudgeAction={undoNudgeAction}
                    selectedNudge={selectedNudge}
                    getUniqueFilterNumber={getUniqueFilterNumber}
                    filteredNudges={filteredNudges}
                    recommendedNudges={recommendedNudges}
                    setSearchTerm={setSearchTerm}
                    setSearchType={setSearchType}
                    setSelectedNudge={setSelectedNudge}
                    setFilteredNudges={setFilteredNudges}
                    setFilter={setFilter}
                    setSearch={setSearch}
                    getNudgeBtn={getNudgeBtn}
                    nudgeCategoryMap={nudgeCategoryMap}
                    thumsUpIds={thumsUpIds}
                    updateNudgeFeedback={updateNudgeFeedback}
                    thumsDownIds={thumsDownIds}
                    dispatch={dispatch}
                    setSelectedNudgeForUnlike={setSelectedNudgeForUnlike}
                    setHide={setHide}
                    hide={hide}
                  />
                )}
                {searchType == "salesforceStage" && (
                  <SalesforceStageFilterView
                    undoNudgeAction={undoNudgeAction}
                    selectedNudge={selectedNudge}
                    getUniqueFilterNumber={getUniqueFilterNumber}
                    filteredNudges={filteredNudges}
                    recommendedNudges={recommendedNudges}
                    setSearchTerm={setSearchTerm}
                    setSearchType={setSearchType}
                    setSelectedNudge={setSelectedNudge}
                    setFilteredNudges={setFilteredNudges}
                    setFilter={setFilter}
                    setSearch={setSearch}
                    getNudgeBtn={getNudgeBtn}
                    nudgeCategoryMap={nudgeCategoryMap}
                    thumsUpIds={thumsUpIds}
                    updateNudgeFeedback={updateNudgeFeedback}
                    thumsDownIds={thumsDownIds}
                    dispatch={dispatch}
                    setSelectedNudgeForUnlike={setSelectedNudgeForUnlike}
                    setHide={setHide}
                    hide={hide}
                  />
                )}
                {searchType == "accountName" && (
                  <AccountNameFilterView
                    undoNudgeAction={undoNudgeAction}
                    selectedNudge={selectedNudge}
                    getUniqueFilterNumber={getUniqueFilterNumber}
                    filteredNudges={filteredNudges}
                    recommendedNudges={recommendedNudges}
                    setSearchTerm={setSearchTerm}
                    setSearchType={setSearchType}
                    setSelectedNudge={setSelectedNudge}
                    setFilteredNudges={setFilteredNudges}
                    setFilter={setFilter}
                    setSearch={setSearch}
                    getNudgeBtn={getNudgeBtn}
                    nudgeCategoryMap={nudgeCategoryMap}
                    thumsUpIds={thumsUpIds}
                    updateNudgeFeedback={updateNudgeFeedback}
                    thumsDownIds={thumsDownIds}
                    dispatch={dispatch}
                    setSelectedNudgeForUnlike={setSelectedNudgeForUnlike}
                    setHide={setHide}
                    hide={hide}
                  />
                )}
                {searchType == "name" && (
                  <NameFilterView
                    loadBirthdayEmail={loadBirthdayEmail}
                    setSearch={setSearch}
                    setFilter={setFilter}
                    setFilteredNudges={setFilteredNudges}
                    setSearchType={setSearchType}
                    setSearchTerm={setSearchTerm}
                    getNudgeIcon={getNudgeIcon}
                    recommendedNudges={recommendedNudges}
                    setShowTreatModal={setShowTreatModal}
                    undoNudgeAction={undoNudgeAction}
                    selectedNudge={selectedNudge}
                    filteredNudges={filteredNudges}
                    setSelectedNudge={setSelectedNudge}
                    getNudgeBtn={getNudgeBtn}
                    nudgeCategoryMap={nudgeCategoryMap}
                    thumsUpIds={thumsUpIds}
                    updateNudgeFeedback={updateNudgeFeedback}
                    thumsDownIds={thumsDownIds}
                    dispatch={dispatch}
                    setSelectedNudgeForUnlike={setSelectedNudgeForUnlike}
                    setHide={setHide}
                    hide={hide}
                  />
                )}
              </Flex>
            )}
            {search &&
              !filter &&
              searchNudges.length == 0 &&
              screenSize.dynamicWidth > 576 && (
                <Flex
                  alignItems={"center"}
                  justifyContent={"center"}
                  width={"100%"}
                  height={"100%"}
                  flexDirection={"column"}
                  gridRowGap={"32px"}
                >
                  <Icon name="emptyScreen" />
                  <Flex flexDirection={"column"} gridRowGap={"8px"}>
                    <Text
                      alignSelf={"center"}
                      color={"#050505"}
                      fontSize={"20px"}
                      fontWeight={"500"}
                      lineHeight={"140%"}
                      width={"60%"}
                    >
                      {" "}
                      {searchQuery} has not shared their preferences{" "}
                    </Text>
                    <Text
                      alignSelf={"center"}
                      color={"#57585C"}
                      fontWeight={"400"}
                      fontSize={"14px"}
                      lineHeight={"140%"}
                    >
                      {" "}
                      We'll notify you as soon as we have the nudges ready for
                      you.{" "}
                    </Text>
                  </Flex>
                </Flex>
              )}
            {search &&
              !filter &&
              searchNudges.length == 0 &&
              screenSize.dynamicWidth <= 576 && (
                <Flex
                  alignItems={"center"}
                  justifyContent={"center"}
                  width={"100%"}
                  height={"100%"}
                  flexDirection={"column"}
                  gridRowGap={"16px"}
                >
                  <svg
                    width="260"
                    height="141"
                    viewBox="0 0 260 141"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_9362_96670)">
                      <path
                        d="M224.178 0H6.93333C3.10416 0 0 3.10416 0 6.93333V85.5111C0 89.3403 3.10416 92.4445 6.93333 92.4445H224.178C228.007 92.4445 231.111 89.3403 231.111 85.5111V6.93333C231.111 3.10416 228.007 0 224.178 0Z"
                        fill="white"
                      />
                      <path
                        d="M11.556 18.489C11.556 23.5945 15.6949 27.7334 20.8005 27.7334C25.906 27.7334 30.0449 23.5945 30.0449 18.489C30.0449 13.3834 25.906 9.24451 20.8005 9.24451C15.6949 9.24451 11.556 13.3834 11.556 18.489Z"
                        fill="#F4F4F4"
                      />
                      <path
                        d="M24.651 18.4891V16.0817H25.614V18.9705H24.651M24.651 20.8964H25.614V19.9335H24.651M19.8362 18.9705C21.1218 18.9705 23.6881 19.6157 23.6881 20.8964V22.3409H15.9844V20.8964C15.9844 19.6157 18.5507 18.9705 19.8362 18.9705ZM19.8362 14.6372C20.347 14.6372 20.8369 14.8401 21.1981 15.2013C21.5592 15.5625 21.7622 16.0524 21.7622 16.5631C21.7622 17.0739 21.5592 17.5638 21.1981 17.925C20.8369 18.2862 20.347 18.4891 19.8362 18.4891C19.3254 18.4891 18.8356 18.2862 18.4744 17.925C18.1132 17.5638 17.9103 17.0739 17.9103 16.5631C17.9103 16.0524 18.1132 15.5625 18.4744 15.2013C18.8356 14.8401 19.3254 14.6372 19.8362 14.6372ZM19.8362 19.8853C18.4062 19.8853 16.8992 20.5883 16.8992 20.8964V21.4261H22.7733V20.8964C22.7733 20.5883 21.2662 19.8853 19.8362 19.8853ZM19.8362 15.552C19.5681 15.552 19.3109 15.6585 19.1212 15.8482C18.9317 16.0378 18.8251 16.295 18.8251 16.5631C18.8251 16.8313 18.9317 17.0885 19.1212 17.2781C19.3109 17.4677 19.5681 17.5742 19.8362 17.5742C20.1044 17.5742 20.3616 17.4677 20.5512 17.2781C20.7408 17.0885 20.8474 16.8313 20.8474 16.5631C20.8474 16.295 20.7408 16.0378 20.5512 15.8482C20.3616 15.6585 20.1044 15.552 19.8362 15.552Z"
                        fill="#D5D5D5"
                      />
                      <path
                        d="M101.11 15.6001H37.5549C35.9594 15.6001 34.666 16.8935 34.666 18.489C34.666 20.0845 35.9594 21.3779 37.5549 21.3779H101.11C102.706 21.3779 103.999 20.0845 103.999 18.489C103.999 16.8935 102.706 15.6001 101.11 15.6001Z"
                        fill="#F4F4F4"
                      />
                      <path
                        d="M129.423 45.6445H43.912C41.9974 45.6445 40.4453 47.1966 40.4453 49.1112C40.4453 51.0258 41.9974 52.5779 43.912 52.5779H129.423C131.338 52.5779 132.89 51.0258 132.89 49.1112C132.89 47.1966 131.338 45.6445 129.423 45.6445Z"
                        fill="#F2F2F2"
                      />
                      <path
                        d="M83.2009 55.4668H43.912C41.9974 55.4668 40.4453 57.0189 40.4453 58.9335C40.4453 60.8481 41.9974 62.4001 43.912 62.4001H83.2009C85.1155 62.4001 86.6675 60.8481 86.6675 58.9335C86.6675 57.0189 85.1155 55.4668 83.2009 55.4668Z"
                        fill="#F2F2F2"
                      />
                      <path
                        d="M18.4889 65.8667H28.8889C32.7181 65.8667 35.8223 62.7625 35.8223 58.9334V48.5334C35.8223 44.7042 32.7181 41.6 28.8889 41.6H18.4889C14.6598 41.6 11.5556 44.7042 11.5556 48.5334L11.5556 58.9334C11.5556 62.7625 14.6598 65.8667 18.4889 65.8667Z"
                        fill="#FFEFEF"
                      />
                      <path
                        d="M180.267 61.8223L217.245 61.8223C219.798 61.8223 221.867 59.7528 221.867 57.2V50.2667C221.867 47.7139 219.798 45.6445 217.245 45.6445H180.267C177.714 45.6445 175.645 47.7139 175.645 50.2667V57.2C175.645 59.7528 177.714 61.8223 180.267 61.8223Z"
                        fill="#EFEFEF"
                      />
                      <g filter="url(#filter0_d_9362_96670)">
                        <path
                          d="M247.289 57.7778H30.0447C26.2155 57.7778 23.1113 60.882 23.1113 64.7112V131.733C23.1113 135.563 26.2155 138.667 30.0447 138.667H247.289C251.118 138.667 254.222 135.563 254.222 131.733V64.7112C254.222 60.882 251.118 57.7778 247.289 57.7778Z"
                          fill="white"
                        />
                      </g>
                      <path
                        d="M34.6674 76.2668C34.6674 81.3724 38.8062 85.5112 43.9118 85.5112C49.0174 85.5112 53.1562 81.3724 53.1562 76.2668C53.1562 71.1612 49.0174 67.0223 43.9118 67.0223C38.8062 67.0223 34.6674 71.1612 34.6674 76.2668Z"
                        fill="#F4F4F4"
                      />
                      <path
                        d="M47.7624 76.2666V73.859H48.7254V76.7479H47.7624M47.7624 78.6742H48.7254V77.711H47.7624M42.9476 76.7479C44.2331 76.7479 46.7994 77.3933 46.7994 78.6742V80.1186H39.0957V78.6742C39.0957 77.3933 41.662 76.7479 42.9476 76.7479ZM42.9476 72.4146C43.4583 72.4146 43.9482 72.6179 44.3094 72.979C44.6706 73.3401 44.8735 73.8301 44.8735 74.3409C44.8735 74.8516 44.6706 75.3416 44.3094 75.7027C43.9482 76.0638 43.4583 76.2666 42.9476 76.2666C42.4368 76.2666 41.9469 76.0638 41.5858 75.7027C41.2245 75.3416 41.0216 74.8516 41.0216 74.3409C41.0216 73.8301 41.2245 73.3401 41.5858 72.979C41.9469 72.6179 42.4368 72.4146 42.9476 72.4146ZM42.9476 77.6631C41.5176 77.6631 40.0105 78.3657 40.0105 78.6742V79.2034H45.8846V78.6742C45.8846 78.3657 44.3776 77.6631 42.9476 77.6631ZM42.9476 73.3298C42.6794 73.3298 42.4222 73.4361 42.2326 73.6256C42.043 73.8157 41.9365 74.0728 41.9365 74.3409C41.9365 74.609 42.043 74.8661 42.2326 75.0556C42.4222 75.2451 42.6794 75.352 42.9476 75.352C43.2157 75.352 43.4729 75.2451 43.6625 75.0556C43.8521 74.8661 43.9587 74.609 43.9587 74.3409C43.9587 74.0728 43.8521 73.8157 43.6625 73.6256C43.4729 73.4361 43.2157 73.3298 42.9476 73.3298Z"
                        fill="#D5D5D5"
                      />
                      <path
                        d="M124.222 73.3779H60.6662C59.0707 73.3779 57.7773 74.6713 57.7773 76.2668C57.7773 77.8623 59.0707 79.1557 60.6662 79.1557H124.222C125.817 79.1557 127.111 77.8623 127.111 76.2668C127.111 74.6713 125.817 73.3779 124.222 73.3779Z"
                        fill="#F4F4F4"
                      />
                      <path
                        d="M152.532 103.422H67.0214C65.1068 103.422 63.5547 104.974 63.5547 106.889C63.5547 108.804 65.1068 110.356 67.0214 110.356H152.532C154.447 110.356 155.999 108.804 155.999 106.889C155.999 104.974 154.447 103.422 152.532 103.422Z"
                        fill="#F2F2F2"
                      />
                      <path
                        d="M106.31 113.245H67.0214C65.1068 113.245 63.5547 114.797 63.5547 116.711C63.5547 118.626 65.1068 120.178 67.0214 120.178H106.31C108.225 120.178 109.777 118.626 109.777 116.711C109.777 114.797 108.225 113.245 106.31 113.245Z"
                        fill="#F2F2F2"
                      />
                      <path
                        d="M41.6003 123.645H52.0003C55.8294 123.645 58.9336 120.54 58.9336 116.711V106.311C58.9336 102.482 55.8294 99.3779 52.0003 99.3779H41.6003C37.7711 99.3779 34.6669 102.482 34.6669 106.311V116.711C34.6669 120.54 37.7711 123.645 41.6003 123.645Z"
                        fill="#EFF7FF"
                      />
                      <path
                        d="M203.379 119.6H240.356C242.909 119.6 244.979 117.531 244.979 114.978V108.045C244.979 105.492 242.909 103.422 240.356 103.422H203.379C200.826 103.422 198.756 105.492 198.756 108.045V114.978C198.756 117.531 200.826 119.6 203.379 119.6Z"
                        fill="#EFEFEF"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_9362_96670"
                        x="13.1113"
                        y="40.7778"
                        width="251.111"
                        height="100.889"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="-7" />
                        <feGaussianBlur stdDeviation="5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_9362_96670"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_9362_96670"
                          result="shape"
                        />
                      </filter>
                      <clipPath id="clip0_9362_96670">
                        <rect width="260" height="140.4" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <Flex flexDirection={"column"} gridRowGap={"8px"}>
                    <Text
                      alignSelf={"center"}
                      color={"#050505"}
                      fontSize={"16px"}
                      fontWeight={"500"}
                      lineHeight={"140%"}
                      width={"60%"}
                    >
                      {" "}
                      {searchQuery} has not shared their preferences{" "}
                    </Text>
                    <Text
                      alignSelf={"center"}
                      color={"#57585C"}
                      fontWeight={"400"}
                      fontSize={"12px"}
                      lineHeight={"140%"}
                    >
                      {" "}
                      We'll notify you as soon as we have the nudges ready for
                      you.{" "}
                    </Text>
                  </Flex>
                </Flex>
              )}
            {search &&
              !filter &&
              searchNudges.length > 0 &&
              screenSize.dynamicWidth > 576 && (
                <SearchView
                  getDomainName={getDomainName}
                  getNudgeIcon={getNudgeIcon}
                  dispatch={dispatch}
                  undoNudgeAction={undoNudgeAction}
                  setSelectedNudgeForUnlike={setSelectedNudgeForUnlike}
                  searchNudges={searchNudges}
                  // searchQuery={searchQuery}
                  searchQuery={searchh}
                  recommendedNudges={recommendedNudges}
                  nudgeCategoryMap={nudgeCategoryMap}
                  setSearchType={setSearchType}
                  setSearchTerm={setSearchTerm}
                  setSelectedNudge={setSelectedNudge}
                  getNudgeBtn={getNudgeBtn}
                  setFilteredNudges={setFilteredNudges}
                  setSearch={setSearch}
                  setFilter={setFilter}
                  thumsUpIds={thumsUpIds}
                  updateNudgeFeedback={updateNudgeFeedback}
                  thumsDownIds={thumsDownIds}
                  setHide={setHide}
                  hide={hide}
                />
              )}
            {search &&
              !filter &&
              searchNudges.length > 0 &&
              screenSize.dynamicWidth <= 576 && (
                <SearchMobileView
                  getNudgeIcon={getMobileNudgeIcon}
                  dispatch={dispatch}
                  undoNudgeAction={undoNudgeAction}
                  setSelectedNudgeForUnlike={setSelectedNudgeForUnlike}
                  searchNudges={searchNudges}
                  searchQuery={searchQuery}
                  recommendedNudges={recommendedNudges}
                  nudgeCategoryMap={nudgeCategoryMap}
                  setSearchType={setSearchType}
                  setSearchTerm={setSearchTerm}
                  setSelectedNudge={setSelectedNudge}
                  getNudgeBtn={getNudgeBtn}
                  setFilteredNudges={setFilteredNudges}
                  setSearch={setSearch}
                  setFilter={setFilter}
                  thumsUpIds={thumsUpIds}
                  updateNudgeFeedback={updateNudgeFeedback}
                  thumsDownIds={thumsDownIds}
                  setHide={setHide}
                  hide={hide}
                />
              )}
          </Flex>
        </Box>
      </Flex>
      <div style={{ position: "fixed", bottom: 20, right: 20 }}>
        {pageBottomRef && pageBottomRef.current && (
          <Box
            height={"32px"}
            cursor={"pointer"}
            width={"32px"}
            borderRadius={"8px"}
            border={"1px solid rgba(0, 0, 0, 0.1)"}
            background={"#fff"}
            onClick={() => {
              // console.log("cliekdd");
              // @ts-ignore
              pageBottomRef.current.scroll({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              height={"100%"}
              width={"100%"}
            >
              <Icon name="up" fill="black" />
            </Flex>
          </Box>
        )}
      </div>
    </div>
  );
};

export default NudgeFeeds;
